import request from '@/utils/request';

export function getList(params)
{
  return request({
    url: '/Api/table/list',
    method: 'get',
    params
  });
}
export function download(params)
{
  return request({
    url: '/Api/table/download',
    method: 'get',
    params,
    responseType: "blob"
  });
}
export function deleteFile(data)
{
  return request({
    url: '/Api/table/deleteFile',
    method: 'delete',
    params: data
  });
}
export function SetShowToCustomerByList(data)
{
  return request({
    url: '/Api/table/SetShowToCustomerByList',
    method: 'post',
    data
  });
}
export function SetStatusByList(data)
{
  return request({
    url: '/Api/table/SetStatusByList',
    method: 'post',
    data
  });
}


export function GenerateInvoice_SB(data)
{
  return request({
    url: '/Api/packingandinvoice/GenerateInvoice_SB',
    method: 'post',
    data,
    responseType: 'blob'
  });
}