<template>
  <div class="list">
   <div class="button"> <el-button type="primary" v-on:click="showInfo(null)">新建</el-button></div>
       <el-popover
        placement="bottom-end"
        width="auto"
        trigger="click"
        height="auto"
        class="findbutton">
        <el-transfer
        v-model="filterlabels"
        :data="this.labelafterfilter"
        :titles="['可选标签', '已有标签']"
        @change="filterlabelschangge()">
        <el-checkbox-group v-model="labelchecklist"  slot="left-footer" >
        <el-checkbox label="客户" class="labelcheckbox"></el-checkbox>
        <el-checkbox label="故障报错" class="labelcheckbox"></el-checkbox>
        <el-checkbox label="模块型号" class="labelcheckbox"></el-checkbox>
        </el-checkbox-group>
        <el-radio-group v-model="filtermethod" slot="right-footer">
        <el-radio label="and">与条件筛选</el-radio>
        <el-radio label="or">或条件筛选</el-radio>
        </el-radio-group>
      </el-transfer>
        <el-button slot="reference" type="primary">添加搜索标签</el-button>
      </el-popover>
    <el-dialog :visible.sync="showbugInfo" title="故障信息" id="dialog">
      <div class="from">
       <div class="boxout">
          <div class="box">
          <p >涉及客户</p>
           <el-autocomplete
          v-model="bugInfo.company"
          :fetch-suggestions="suggestions_company"
          id="companyid"
          ></el-autocomplete>   
        </div>
        <div class="box">
          <p>优先级</p>
        <div :class="Getclass(this.bugInfo.priority)">
        <el-select v-model="bugInfo.priority" size="small" class="selecticon" ref="select_icon" @change="iconChange" placeholder=''> 
            <el-option v-for="(item,index) in priority" :key="index"
            :label="item"
            :value="item"  
            >
            <div :class="Getclass(item)">
              <svg-icon icon-class="Priority1" />
            </div>
            </el-option>
        </el-select>
         <!-- <div class="cover"></div> -->
         <svg-icon icon-class="Priority1" class-name='svgicon'  />
        </div>
        </div>
       </div>
       <div class="boxLong">
         <p >故障模块</p> 
          <el-autocomplete
          v-model="bugInfo.Module"
          :fetch-suggestions="suggestions_Module"
           id="Moduleid"
          ></el-autocomplete>   
      </div>
      <div class="boxLong">
         <p >重点故障描述</p> 
         <el-input v-model="bugInfo.Description" size="small"  />   
      </div>
       <div v-if="newinfo" class="boxLong">
         <p >当前进展</p> 
         <el-input v-model="bugInfo.newprocess" size="small"  />   
      </div>
      <div class="boxLong">
        <div><el-tag v-for="(tag,index) in bugInfo.labels" :key="index"> {{tag}}</el-tag></div>
      <el-popover
        placement="bottom-end"
        width="auto"
        trigger="click"
        height="auto">
        <el-transfer
        v-model="bugInfo.labels"
        :data="this.labelafterfilter"
        :titles="['可选标签', '已有标签']">
        <el-checkbox-group v-model="labelchecklist"  slot="left-footer" >
        <el-checkbox label="客户" class="labelcheckbox"></el-checkbox>
        <el-checkbox label="故障报错" class="labelcheckbox"></el-checkbox>
        <el-checkbox label="模块型号" class="labelcheckbox"></el-checkbox>
         </el-checkbox-group>
      </el-transfer>
        <el-button slot="reference" type="primary">添加标签</el-button>
      </el-popover>
      </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-on:click="saveInfo">保存</el-button>
        <!-- <el-button type="primary" v-on:click="otherSaveInfo">另存为</el-button> -->
      </span>
    </el-dialog>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      fit
      highlight-current-row
      :cell-class-name="CellClass"
      @sort-change="sortchange"
      @filter-change="filterChange"
      @row-dblclick="rowselect"
    >
      <el-table-column align="center" label="ID" width="100" prop="Num" sortable='custom' >
      <template #header >
          <el-popover
          placement="top"
          width="160"
          v-model="visible_ID">
          <el-input v-model="filternumber"></el-input>
          <div style="text-align: right; margin: 10px 0">
            <el-button type="primary" size="mini" @click="visible_ID = false,filterbyNum()">确定</el-button>
          </div>
          <span slot="reference">ID</span>
         </el-popover>
      </template>
      <template slot-scope="scope">
          {{ scope.row.Num }}
      </template>
      </el-table-column>
      <!-- <el-table-column label="状态" align="center" width="110">
        <template slot-scope="scope">
         <el-button v-on:click="ToShowProcess(scope.row)" type="button"
            >查看</el-button>
        </template>
      </el-table-column> -->
      <el-table-column label="优先级" width="100" align="center"  prop="priority" sortable='custom' > 
        <template #header >
          <el-popover
          placement="top"
          v-model="visible_Per">
            <el-checkbox-group v-model="checkListPriority">
              <el-checkbox label=1> <svg-icon icon-class="Priority1" class-name='Priority11'/></el-checkbox>
              <el-checkbox label=2> <svg-icon icon-class="Priority1" class-name='Priority22'/></el-checkbox>
              <el-checkbox label=3> <svg-icon icon-class="Priority1" class-name='Priority33'/></el-checkbox>
            </el-checkbox-group>
          <div style="text-align: right; margin: 0">
            <el-button type="primary" size="mini" @click="visible_Per=false,filterbypriority()">确定</el-button>
          </div>
          <span slot="reference">优先级</span>
         </el-popover>
          </template>
        <svg-icon icon-class="Priority1"  class-name='custom-class' />
      </el-table-column>
      <el-table-column align="center" label="创建人" width="120" prop="Submitter" sortable='custom'   >
        <template #header >
          <el-popover
          placement="right"
          width="50"
          v-model="visible_Submetter">
            <el-autocomplete
          v-model="filtersubmitter"
          :fetch-suggestions="suggestions_submiter"
          ></el-autocomplete>   
          <div style="text-align: right; margin: 0">
            <el-button type="primary" size="mini" @click="visible_Submetter=false,filterbySubmitter()">确定</el-button>
          </div>
          <span slot="reference">创建人</span>
         </el-popover>
          </template>
        <template slot-scope="scope">
          <span>{{ scope.row.Submitter }}</span>
        </template>
      </el-table-column>
      <el-table-column label="涉及客户" width="160" align="center" prop="company" sortable='custom' >
        <template #header >
          <el-popover
          placement="right"
          width="50"
          v-model="visible_Company">
            <el-autocomplete
          v-model="filtercompany"
          :fetch-suggestions="suggestions_company"
          ></el-autocomplete>   
          <div style="text-align: right; margin: 0">
            <el-button type="primary" size="mini" @click="visible_Company=false,filterbyCompany()">确定</el-button>
          </div>
          <span slot="reference">涉及客户</span>
         </el-popover>
          </template>
        <template slot-scope="scope">
          {{ scope.row.company }}
        </template>
      </el-table-column>
      <el-table-column label="涉及模块" width="120" align="center" prop="Module" sortable='custom'  >
        <template #header >
          <el-popover
          placement="right"
          width="50"
          v-model="visible_Moudle">
            <el-autocomplete
          v-model="filterMoudle"
          :fetch-suggestions="suggestions_Module"
          ></el-autocomplete>   
          <div style="text-align: right; margin: 0">
            <el-button type="primary" size="mini" @click="visible_Moudle=false,filterbyMoudle()">确定</el-button>
          </div>
          <span slot="reference">涉及模块</span>
         </el-popover>
          </template>
        <template slot-scope="scope">
          {{ scope.row.Module }}
        </template>
      </el-table-column>
      <el-table-column label="问题具体描述" width="610" align="center">
        <template slot-scope="scope">
          {{ scope.row.Description }}
        </template>
      </el-table-column>
            <el-table-column label="附件" width="120" align="center">
              <template slot-scope="scope">
          <el-button v-on:click="FShowannex(scope.row,0)" :class="ifhaveannex(scope.row)" 
            ><i class="el-icon-folder-opened"></i></el-button>
            </template>
            </el-table-column>
      <!-- <el-table-column align="center" label="操作" width="190">
        <template slot-scope="scope">
          <el-button v-on:click="showInfo(scope.row)" type="button"
            >编辑</el-button
          >
          <el-button v-on:click="showDeleteInfo(scope.row)" type="button"
            >删除</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>

    <el-dialog :visible.sync="Showannex" >
      <el-upload
      action="''" 
      multiple
      accept=".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PBG, .GIF, .pdf"   
      :http-request="submitList"
      :show-file-list=false>
      <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
      <!-- :on-preview="downloadFile" -->
      <!-- :on-remove="deleteFile" -->
      <!-- :before-remove="beforeRemove" -->
      <el-table
          v-loading="listLoading"
          :data="getfiles(this.annexfathe)"
          class="marginTop"
          element-loading-text="Loading"
          border
          fit
          highlight-current-row
          @cell-click="annexPreview"
        >
          <el-table-column align="center" prop="created_at" label="文件列表" >
            <template slot-scope="scope">
              <span v-for="(file,index) in scope.row" :key="index" >{{file}}</span>
            </template>
          </el-table-column>

          <el-table-column align="center"  label="操作" width="250">
            <template slot-scope="scope">
            <el-button v-on:click="downloadFile(scope.row)"  >下载</el-button> 
            <el-button v-on:click="deleteFile(scope.row)"   type="gray" >删除</el-button> 
            </template>
          </el-table-column>

        </el-table>
      </el-dialog>

    <el-dialog :visible.sync="showDelete" title="删除">
      <h4>确定删除</h4>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-on:click="deleteInfo">确定</el-button>
        <el-button type="normal" v-on:click="showDelete = false"
          >取消</el-button
        >
      </span>
    </el-dialog>
    <el-dialog :visible.sync="showProcess" tittle='项目进度' top="10px">
      <span class="Tittle">
         <p class="left">创建时间</p>
        <p class="right">{{this.bugInfo.addtime}}</p>
      </span>
      <div class="tagline"><el-tag v-for="(tag,index) in bugInfo.labels" :key="index"> {{tag}}</el-tag></div>
       <el-divider></el-divider>
       <div class="timeline" >
            <el-timeline >
                <el-timeline-item
                 v-for="(pro, index) in bugInfo.Process"
                  :key="index"
                  :timestamp="getstamp(pro)">
                  {{pro.event}}
                  <el-button v-on:click="FShowannex(bugInfo,index+1)" :class="ifhaveannexinchildren(bugInfo,index)"  class="annexicon"
            ><i class="el-icon-folder-opened"></i></el-button>
                </el-timeline-item>
          </el-timeline>
       </div>
       <el-divider></el-divider>
       <div v-if="!this.bugInfo.isclosed">
         <el-input class="newevent" v-model="bugInfo.newprocess"></el-input>
         <el-popover placement="top" width="200"  class="closeprogress" v-model="visible" v-if="ifhaveright()">
          <p>是否确认关闭</p>
          <div style="text-align: right; margin: 0;">
          <el-button type="primary" size="small" v-on:click="Ccloseprogress()">确定</el-button>
      </div>
  <el-button slot="reference">关闭项目</el-button>
</el-popover>
         <el-button class="submitevent"  v-on:click="sbutmitnewevent()">提交事件</el-button>
         <div class="clear"></div>
       </div>
       <div v-if="this.bugInfo.isclosed">
         <h3>项目已关闭</h3>
         <el-popover placement="top" width="200"  class="closeprogress" v-model="visible" v-if="ifhaveright()">
          <p>是否确认开启</p>
          <div style="text-align: right; margin: 0;">
          <el-button type="primary" size="small" v-on:click="Copenprogress()">确定</el-button>
      </div>
  <el-button slot="reference">开启项目</el-button>
        </el-popover>
    <div class="clear"></div>
    </div>
      <div class="connectbug">
        <el-carousel indicator-position="outside" height="220px">
          <el-carousel-item v-for="(item,index) in this.connnectionbuglist" :key="index">
            <div class="box1"><p>ID: {{item.Num}}</p></div>
            <div class="box1"><p>优先级：</p><div :class="Getclass(item.priority)" class="icons"><svg-icon icon-class="Priority1"/></div></div>
            <div class="box1"><p>涉及客户：{{item.company}}</p></div>
            <div class="box1"><p>涉及模块：{{item.Module}}</p></div>
            <div class="box2"><p>具体问题描述：{{item.Description}}</p></div>
            <div class="box2"> <el-tag v-for="(label,index) in item.labels" :key="index">{{label}}</el-tag></div>
            <div class="box2"> <el-button type="primary" size="mini" v-on:click="Toanotherbug(item)" >跳转至该bug</el-button></div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </el-dialog>
    <div class="block">
      <el-pagination
        :page-size="10"
        layout="prev, pager, next, jumper"
        :total="this.total"
        :current-page.sync="listQuery.page"
         @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {  Message, MessageBox } from "element-ui";
import Pagination from "@/components/Pagination";
import {
  getbugList,
  createbugList,
  updatebugList,
  deletebugList,
  getLastNum,
} from "@/api/buglist";
import{getUserList} from "@/api/user";
import { uploadExtra ,download,deleteFile} from '@/api/fileTransfer'
import {getCustomerList } from '@/api/article'
import { getToken,getName } from '@/utils/auth'
export default {
  components: { Pagination },
  computed:{
    labels(){return this.$store.state.label.labellist},
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: "success",
        draft: "gray",
        deleted: "danger",
      };
      return statusMap[status];
    },
  },
  data(){
    return {
      list: null,
      showDelete: false,
      showProcess:false,
      showbugInfo:false,
      ID:null,
      visible:false,
      visible_ID:false,
      visible_Per:false,
      visible_Submetter:false,
      visible_Moudle:false,
      visible_Company:false,
      bugInfo: {
        Num:null,
        _id: null,
        priority:null,
        addtime: null,
        company: null,
        Module: null,
        Description: null,
        Process: [{
          time:null,
          event:null,
          people:null
        }],
        Submitter:null,
        Filename:[],
        labels: []
      },
      listLoading: true,
      total: null,
      priority:["1","2","3"],
      newinfo:false,
      newprocess:null,
      changeprocess:null,
      isclosed:false,
      Showannex:false,
      routeFloorId:null,
      Submitterfilter:[],
      companyfilter:[],
      Modulefilter:[{text:"CCP621",value:"CCP621"}],
      listQuery: {
      page: 1,
      limit: 10,
      sort:null,
      filters :{         
          },
      },
      filterlist:{
        Num:[],
        priority:[],
        Submitter:[],
        company:[],
        Module:[],
        labels:[]
      },
      filternumber:null,
      checkListPriority:[],
      filtersubmitter:null,
      filtercompany:null,
      filterMoudle:null,
      alllabels:[],
      labelafterfilter:[],
      connnectionbuglist:[],
      annexfathe:null,
      filterlabels:[],
      labelchecklist:["客户","故障报错","模块型号"],
      filtermethod:"and",
    };
  },
  created() {
    this.fetchData();
    this.getCustomerInfo();//获取所有信息
    this.getuserInfo()//获取用户列表
    this.$store.dispatch("InIcreative");
    this.$store.dispatch("getlabelList").then(val=>{for(var i=0;i<this.labels.length;i++)
    {   this.alllabels.push({
        label:this.labels[i].name,
        key:this.labels[i].name,
        type:this.labels[i].type,})}});
    this.labelafterfilter = this.alllabels;
      },
  methods: {
    async fetchData() {
      this.listLoading = true;
      const { data } = await getbugList(this.listQuery);
      this.list = data.list;
      this.total = data.total;
      setTimeout(() => {
        this.listLoading = false;
      }, 0.5 * 1000);
    },
    async handleCurrentChange(page){
      this.listQuery.page = page;
      const { data } = await getbugList(this.listQuery);
      this.list = data.list;
      this.total = data.total;
    },
    
    async getCustomerInfo() {
      const { data } = await getCustomerList()
      for(var i=0 ; i<data.list.length ; i++)
      { 
        var company  = { text:data.list[i].company,
                         value:data.list[i].company }
        this.companyfilter.push(company);
      }
    },

    async getuserInfo() {
    const { data } = await getUserList()
    for(var i=0;i<data.list.length;i++)
    { 
      var User  ={text:data.list[i].username,
                  value:data.list[i].username
                    }
    this.Submitterfilter.push(User);
    }
    },
    FShowannex(data,father){
      this.bugInfo = data;
      this.annexfathe=father;
      this.Showannex=true;
    },
    rowselect(row){
      this.bugInfo = row;
      this.showProcess=true;
      this.getconnectbug();
    },
    suggestions_company(queryString, cb){
        var restaurants = this.companyfilter;
        var results = queryString ? restaurants.filter(this.createFilter_company(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
    },
    createFilter_company(queryString) {
      return (companyfilter) => {
        return (companyfilter.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
    suggestions_submiter(queryString, cb){
        var restaurants = this.Submitterfilter;
        var results = queryString ? restaurants.filter(this.createFilter_submiter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
    },
    createFilter_submiter(queryString) {
      return (Submitterfilter) => {
        return (Submitterfilter.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
    suggestions_Module(queryString, cb){
        var restaurants = this.Modulefilter;
        var results = queryString ? restaurants.filter(this.createFilter_Module(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
    },
    createFilter_Module(queryString) {
      return (Modulefilter) => {
        return (Modulefilter.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
    async submitList(param){
      var isreaper=false;
      for(var i=0;i<this.bugInfo.Filename.length;i++)
      {   
        if(this.bugInfo.Filename[i].name==param.file.name)
        { isreaper=true;
          break;
        }
      }
      if(!isreaper)
      {
          const formData = new FormData()
          formData.append('ModleName', param.file) // 要提交给后台的文件
          formData.append('projectFolder', this.bugInfo._id) // 这个接口必要的项目id
          formData.append('subFolder', null)
          const {data1} =await uploadExtra(formData,{
              path : this.bugInfo._id
              })
        this.bugInfo.Filename.push({name:param.file.name,father:this.annexfathe});
        const { data } = await updatebugList({
        id: this.bugInfo._id,data: 
        {
        Filename:this.bugInfo.Filename,},});
          }
         },

    async downloadFile(file){
        var path = 'file\\'+this.bugInfo._id+'\\'+file;
        var filename = file;
      const data = await download({
        // "file\60f52112f7ee0b0d28e1d882\bottomimg.bmp"
        path : path,
        filename : filename
      });
     var url = window.URL.createObjectURL(data);
    　var a = document.createElement('a');
    　a.href = url;
    　a.download = filename;
    　a.click();
      window.URL.revokeObjectURL(url);
     },

    Getclass(item){
      if(item=="1")
      {
        return "Priority1";
      }
      if(item=="2")
      {
        return "Priority2";
      }
      if(item=="3")
      {
        return "Priority3";
      }
    },
    filterbypriority()
    { var filterTag1 = [];
      for(var i=0;i<this.checkListPriority.length;i++)
      {
      filterTag1.push(this.checkListPriority[i]);
      }
       var filterTag_priority = {filterTag1};
      this.filterChange(filterTag_priority);
    },
    filterbySubmitter(){
     var filterTag2 = [];
      filterTag2.push(this.filtersubmitter);
      var filterTag_Submitter = {filterTag2};
      this.filterChange(filterTag_Submitter);
    },
    filterbyCompany(){
     var filterTag3 = [];
      filterTag3.push(this.filtercompany);
      var filterTag_Company = {filterTag3};
      this.filterChange(filterTag_Company);
    },
    filterbyMoudle(){
     var filterTag4 = [];
      filterTag4.push(this.filterMoudle);
      var filterTag_Module = {filterTag4};
      this.filterChange(filterTag_Module);
    },    
    filterbyNum()
    { 
      var filterTag0 = [];
      filterTag0.push(this.filternumber);
      var filterTag_Num = {filterTag0};
      this.filterChange(filterTag_Num);
    },
    ifhaveright(){
      var right = getToken();
      if((right=="admin-token"||this.bugInfo.Submitter== getName()))
      return true;
      else
      return false;
    },
    Addfilter(word,list){
      var obj={text:word, value:word};
      if(list.length==0)
      {
        list.push(obj);
        }
      else
      { var ishave=false;
        for(var j=0;j<list.length;j++)
        {
          if(list[j].text==obj.text)
          {
            ishave=true;
            break;
          }
        }
        if(!ishave)
        {
        list.push(obj);
        }
      }
    },
    CellClass({row, column}){
      var name='';
      if(row.isclosed&&column.label!="附件")
      { name = name+'del_line ';}
      if(column.label=='优先级')
      {
        if(row.priority==1)
        {
         name = name+'Priority1'
        }
        if(row.priority==2)
        {
            name = name+'Priority2'
        }
        if(row.priority==3)
        {
            name = name+'Priority3'
        }
      }
      return name;
    },
    ifhaveannex(data){
      if(data.Filename.length!=0)
      {return "haveannex"}
    },
    getfiles(father){
      var arr=[];
    if(this.bugInfo.Filename!=null)
    {
      if(father==0||father==undefined||father==null)
      {
          for(var i=0;i<this.bugInfo.Filename.length;i++)
        {
          arr.push(this.bugInfo.Filename[i].name);
        }
      }
      else
      {
        for(var i=0;i<this.bugInfo.Filename.length;i++)
        {
          if(this.bugInfo.Filename[i].father==father)
          {
            arr.push(this.bugInfo.Filename[i].name)
          }
        }
      }
    }
      return arr;
    },
    async deleteFile(file){
      var path = 'file\\'+this.bugInfo._id+'\\'+file;
      const {data }= await deleteFile({
        id : this.bugInfo._id,
        data : path
      });
      for(var i=0;i<this.bugInfo.Filename.length;i++)
      {
        if(this.bugInfo.Filename[i].name==file)
        {
         this.bugInfo.Filename.splice(i,1);
         break;
        }
      }
      const { data1 } = await updatebugList({
      id: this.bugInfo._id,data: 
      {
      Filename:this.bugInfo.Filename,},});
    },
    showInfo(data) {
      this.showbugInfo = true;
      if (data == null) {
       this.newinfo=true;
        this.bugInfo = {
        Num:null,
        _id: null,
        priority:null,
        addtime: null,
        company: null,
        Module: null,
        Description: null,
        Process: [{
          time:null,
          event:null,
          people:null
        }],
        Submitter:null,
        isclosed:false,
        };
      } else {
        this.bugInfo = data;
        this.newinfo=false;
      }
    },
    async saveInfo() {
      if(this.bugInfo.company==null||this.bugInfo.company==""||this.bugInfo.Module==null||this.bugInfo.Module==""||this.bugInfo.Description==null||this.bugInfo.Description=="")
      { var list=[];
        if(this.bugInfo.company==null||this.bugInfo.company=="")
        {list.push("涉及客户")}
        if(this.bugInfo.Module==null||this.bugInfo.Module=="")
        {list.push("故障模块")}
        if(this.bugInfo.Description==null||this.bugInfo.Description=="")
        {list.push("重点故障描述")}
        var warninginfo;
        for(var i=0;i<list.length;i++)
        {
          if(i!=0)
          {
            warninginfo = warninginfo+"、";
            warninginfo = warninginfo+list[i];
          }
          else
          {
            warninginfo = list[0];
          }
        }
        warninginfo = warninginfo+"不可为空"
        MessageBox.alert(warninginfo,"错误",{type:"warning"});
      }
      else{
        if (this.bugInfo._id == null) {
        const  num  = await getLastNum();
        var number = num.num.item[0];
        var Num;
        if(number==undefined)
        {Num=1}
        else
        {Num= num.num.item[0].Num+1;}
        this.bugInfo.Num = Num;
        this.bugInfo.addtime = this.gettime();
        // this.bugInfo.Submitter =document.cookie.get("user_name");
        this.bugInfo.Submitter = getName()
        let newevent={
          time:null,
          event:null,
          people:getName()
        }
        newevent.time = this.gettime();
        if(this,this.bugInfo.priority==null)
        {
          this,this.bugInfo.priority=3;
        }
        if(this.bugInfo.newprocess!= null)
        {
            newevent.event = this.bugInfo.newprocess;
        }
        else{
          newevent.event = "提交bug";
        }
        this.bugInfo.newprocess = null;
        this.bugInfo.Process.push(newevent);
        for(var i=0;i<this.bugInfo.Process.length;i++)
        {
          if(this.bugInfo.Process[i].time==null)
          {
             this.bugInfo.Process.splice(i,1);
          }
        }
        const { data } = await createbugList(this.bugInfo);
        //   var a =data ;
        if (data) {
          Message({
            message: "创建成功",
            type: "success",
            duration: 5 * 1000,
          });
        }
        } 
      else {
        const { data } = await updatebugList({
          id: this.bugInfo._id,
           data: {
            Num:this.bugInfo.Num,
            priority: this.bugInfo.priority,
            addtime: this.bugInfo.addtime,
            company: this.bugInfo.company,
            Module: this.bugInfo.Module,
            Description: this.bugInfo.Description,
            Submitter:this.bugInfo.Submitter,
            Process:this.bugInfo.Process,
            isclosed:this.bugInfo.isclosed,
            labels:this.bugInfo.label,
          },
        });
        }
      this.showbugInfo = false;
      }
      this.fetchData();
    },
    async otherSaveInfo() {
      const { data } = await createbugList({
       Num:this.bugInfo.Num,
       priority: this.bugInfo.priority,
       addtime: this.bugInfo.addtime,
       company: this.bugInfo.company,
       Module: this.bugInfo.Module,
       Description: this.bugInfo.Description,
       Submitter:this.bugInfo.Submitter,
       Process:this.bugInfo.Process,
       isclosed:this.bugInfo.isclosed,
       Filename:this.bugInfo.Filename
      });
      //   var a =data ;
      if (data) {
        Message({
          message: "创建成功",
          type: "success",
          duration: 5 * 1000,
        });
      }

      this.showbugInfo = false;
      this.fetchData();
    },
    showDeleteInfo(data) {
      this.bugInfo._id = data._id;
      this.showDelete = true;
    },
    async deleteInfo() {
      const { data } = await deletebugList({ id: this.bugInfo._id });
      //   var a =data ;
      if (data) {
        Message({
          message: "删除成功",
          type: "success",
          duration: 5 * 1000,
        });
      }

      this.showDelete = false;
      this.fetchData();
    },
    gettime(){
       var Data = new Date();
       var year= Data.getFullYear();
       var month= Data.getMonth() + 1;
       var date= Data.getDate();
       var hh = Data.getHours();
		   var mf = Data.getMinutes()<10 ? '0'+Data.getMinutes() : Data.getMinutes();
		   var ss = Data.getSeconds()<10 ? '0'+Data.getSeconds() : Data.getSeconds();
       var systemTime = year + '-' +  month + '-' + date+ '-'+hh+':'+mf+':'+ss;
       return systemTime;
    },
    async Ccloseprogress(){
      this.bugInfo.isclosed=true;
      this.visible=false;
      await updatebugList({
            id: this.bugInfo._id,data: {
            isclosed:this.bugInfo.isclosed,
            },
          });
      const { data } = await getbugList(this.listQuery);
      this.list = data.list;
    },
    async Copenprogress(){
      this.bugInfo.isclosed=false;
      this.visible=false;
      await updatebugList({
        id: this.bugInfo._id,data: {
          isclosed:this.bugInfo.isclosed,
          },
        });
      const { data } = await getbugList(this.listQuery);
      this.list = data.list;
    },
    // async Deleteoldprocess(data1){
    //     this.bugInfo.Process.splice(data1,1);
    //       const { data } = await updatebugList({
    //       id: this.bugInfo._id,data: {
    //         Num:this.bugInfo.Num,
    //         priority: this.bugInfo.priority,
    //         addtime: this.bugInfo.addtime,
    //         company: this.bugInfo.company,
    //         Module: this.bugInfo.Module,
    //         Description: this.bugInfo.Description,
    //         Submitter:this.bugInfo.Submitter,
    //         Process:this.bugInfo.Process,
    //         isclosed:this.bugInfo.isclosed,
    //         Filename:this.bugInfo.Filename,
    //         labels:this.bugInfo.label,
    //         },
    //       });
    // },
    async sbutmitnewevent()
      {
        let newevent={time:null, event:null,people:getName()};
        newevent.time = this.gettime();
        if(this.bugInfo.newprocess!=null)
        {
          newevent.event = this.bugInfo.newprocess; 
          this.bugInfo.Process.push(newevent);
          for(var i=0;i<this.bugInfo.Process.length;i++)
          {
            if(this.bugInfo.Process[i].time==null)
            {
              this.bugInfo.Process.splice(i,1);
            }
          }
          this.bugInfo.newprocess = null;
          await updatebugList({
          id: this.bugInfo._id,data: {
          Process:this.bugInfo.Process,
            },
          });
           const { data } = await getbugList(this.listQuery);
          this.list = data.list;
        }
      },
    iconChange (val) {
      // el-select实际上是两层div包裹的input
      this.bugInfo.priority = val;
      // 获取当前el-select标签第一层div
      const dom = this.$refs['select_icon'].$el;
      // 创建需要添加到其中的标签 并填充内容
      const svgDom = document.createElement('div'); // ('<svg-icon ref="iconRef" icon-class="' + val + '" style="float: left;width: 3%;height: 30px;border: 1px solid #dcdfe6;border-right:none;" />');
      svgDom.setAttribute('style', ' position: absolute;background-color:#fff;width:20px;height:20px;left:8%;top:20%;');
      // 将创建的标签添加到父节点(第二层div)
      dom.children[0].appendChild(svgDom);
      // 得到el-select中的input标签
      const inputDom = dom.children[0].children[0];
      // 将添加的标签放到input前面
      dom.children[0].insertBefore(svgDom, inputDom);
    },
    getstamp(data)
    { var stamp = "";
      stamp = data.time+" "+data.people;
      return stamp;
    },
    async annexPreview(row, column, cell, event){
      if(column.label=="文件列表")
      {  var path = 'file\\'+this.bugInfo._id+'\\'+row;
        var filename = row;
      const data = await download({
        // "file\60f52112f7ee0b0d28e1d882\bottomimg.bmp"
        path : path,
        filename : filename
      });
        if(row.split(".")[1]=="jpg"||row.split(".")[1]=="jpeg"||row.split(".")[1]=="png"||row.split(".")[1]=="gif"||row.split(".")[1]=="bmp"||row.split(".")[1]=="JPG"||row.split(".")[1]=="JPEG"||row.split(".")[1]=="PBG"||row.split(".")[1]=="GIF")
        {let blob = new Blob([data], { type: "image/jpg" }); 
         var windowURL = window.URL;
        var url = windowURL.createObjectURL(blob);
         window.open(url);
        }
        if(row.split(".")[1]=="log")
        {
        let blob = new Blob([data], {type:"text/plain"}); 
         var windowURL = window.URL;
        var url = windowURL.createObjectURL(blob);
         window.open(url);
        }
      }
    },
    async sortchange(data1){
      if(data1.order=="descending")
      {this.listQuery.sort='({'+data1.prop+':'+-1+'})';}
      else
      {
      this.listQuery.sort='({'+data1.prop+':'+1+'})';
      }
      
      const { data } = await getbugList(this.listQuery);
      this.list = data.list;
    },
    filterlabelschangge(){
      var filterTag5 = [];
      filterTag5=this.filterlabels;
      var filterTag_Labels = {filterTag5};
      this.filterChange(filterTag_Labels);
    },
    async filterChange(data1)
    { var filterTag_Num = data1.filterTag0;
      var filterTag_priority = data1.filterTag1;
      var filterTag_Submitter = data1.filterTag2;
      var filterTag_company = data1.filterTag3;
      var filterTag_Module = data1.filterTag4;
      var filterTag_Labels = data1.filterTag5;
      if(filterTag_Num!=undefined)
      {
        if(filterTag_Num<=0)
        {
          this.filterlist.Num=[];
        }
        else
        {
           this.filterlist.Num=[];
          for(var i=0;i<filterTag_Num.length;i++)
          { 
            this.filterlist.Num.push(filterTag_Num[i])
          }
        }
      }
      if(filterTag_priority!=undefined)
      {
        if(filterTag_priority.length==0)
        {
          this.filterlist.priority=[];
        }
        else
        {
           this.filterlist.priority=[];
          for(var i=0;i<filterTag_priority.length;i++)
          { 
            this.filterlist.priority.push(filterTag_priority[i])
          }
        }
      }
      if(filterTag_Submitter!=undefined)
      {
        if(filterTag_Submitter[0]=="")
        {
          this.filterlist.Submitter=[];
        }
        else
        { 
           this.filterlist.Submitter=[];
          for(var i=0;i<filterTag_Submitter.length;i++)
          { 
            this.filterlist.Submitter.push(filterTag_Submitter[i])
          }
        }
      }  
      if(filterTag_company!=undefined)
      {
        if(filterTag_company[0]=="")
        {
          this.filterlist.company=[];
        }
        else
        {
           this.filterlist.company=[];
          for(var i=0;i<filterTag_company.length;i++)
          { 
            this.filterlist.company.push(filterTag_company[i])
          }
        }
      }
      if(filterTag_Module!=undefined)
      {
        if(filterTag_Module[0]=="")
        {
          this.filterlist.Module=[];
        }
        else
        {
           this.filterlist.Module=[];
          for(var i=0;i<filterTag_Module.length;i++)
          { 
            this.filterlist.Module.push(filterTag_Module[i])
          }
        }
      }
      if(filterTag_Labels!=undefined)
      {
        if(filterTag_Labels[0]=="")
        {
          this.filterlist.labels=[];
        }
        else
        {
           this.filterlist.labels=[];
          for(var i=0;i<filterTag_Labels.length;i++)
          { 
            this.filterlist.labels.push(filterTag_Labels[i])
          }
        }
      }    
      this.listQuery.filters='({';     
      var ishave=false;
      if(this.filterlist.Num.length>=1)
      { 
        this.listQuery.filters=this.listQuery.filters+"Num:{$in:["
        for(var i=0;i<this.filterlist.Num.length;i++)
        { if(i>0)
          {
            this.listQuery.filters=this.listQuery.filters+",";
          }
          this.listQuery.filters=this.listQuery.filters+"'"+this.filterlist.Num[i]+"'";
        }
        this.listQuery.filters=this.listQuery.filters+"]}";
        ishave=true;
      }
      if(this.filterlist.priority.length>=1)
      { 
        if(ishave)
        {
          this.listQuery.filters=this.listQuery.filters+",";
        }
        this.listQuery.filters=this.listQuery.filters+"priority:{$in:["
        for(var i=0;i<this.filterlist.priority.length;i++)
        { if(i>0)
          {
            this.listQuery.filters=this.listQuery.filters+",";
          }
          this.listQuery.filters=this.listQuery.filters+"'"+this.filterlist.priority[i]+"'";
        }
        this.listQuery.filters=this.listQuery.filters+"]}";
        ishave=true;
      }
      if(this.filterlist.Submitter.length>=1)
      { if(ishave)
        {
          this.listQuery.filters=this.listQuery.filters+",";
        }
        this.listQuery.filters=this.listQuery.filters+"Submitter:{$in:["
        for(var i=0;i<this.filterlist.Submitter.length;i++)
        { if(i>0)
          {
            this.listQuery.filters=this.listQuery.filters+",";
          }
          this.listQuery.filters=this.listQuery.filters+"'"+this.filterlist.Submitter[i]+"'";
        }
        this.listQuery.filters=this.listQuery.filters+"]}";
        ishave=true;
      }
      if(this.filterlist.company.length>=1)
      { if(ishave)
        {
          this.listQuery.filters=this.listQuery.filters+",";
        }
        this.listQuery.filters=this.listQuery.filters+"company:{$in:["
        for(var i=0;i<this.filterlist.company.length;i++)
        { if(i>0)
          {
            this.listQuery.filters=this.listQuery.filters+",";
          }
          this.listQuery.filters=this.listQuery.filters+"'"+this.filterlist.company[i]+"'";
        }
        this.listQuery.filters=this.listQuery.filters+"]}";
        ishave=true;
      } 
      if(this.filterlist.Module.length>=1)
      { if(ishave)
        {
          this.listQuery.filters=this.listQuery.filters+",";
        }
        this.listQuery.filters=this.listQuery.filters+"Module:{$in:["
        for(var i=0;i<this.filterlist.Module.length;i++)
        { if(i>0)
          {
            this.listQuery.filters=this.listQuery.filters+",";
          }
          this.listQuery.filters=this.listQuery.filters+"'"+this.filterlist.Module[i]+"'";
        }
        this.listQuery.filters=this.listQuery.filters+"]}";
        ishave=true;
      }
      if(this.filterlist.labels.length>=1)
      { if(ishave)
        {
          this.listQuery.filters=this.listQuery.filters+",";
        }
        if(this.filtermethod=="and")
        {this.listQuery.filters=this.listQuery.filters+"labels:{$all:["}
        else
        {this.listQuery.filters=this.listQuery.filters+"labels:{$in:["}
        for(var i=0;i<this.filterlist.labels.length;i++)
        { 
          if(i>0)
          {
            this.listQuery.filters=this.listQuery.filters+",";
          }
          this.listQuery.filters=this.listQuery.filters+"'"+this.filterlist.labels[i]+"'";
        }
        this.listQuery.filters=this.listQuery.filters+"]}";
        ishave=true;
      }       
      this.listQuery.filters = this.listQuery.filters+'})';
      const { data } = await getbugList(this.listQuery);
      this.list = data.list;
      this.total = data.total;
    },
    async getconnectbug(){
       var listQuery= {page: 1,limit: 10,filters :{}};
       this.connnectionbuglist=[];
       //获取相关的buglist
      for(var i=0;i<this.bugInfo.labels.length;i++)
      {
        listQuery.filters="({labels:'"+this.bugInfo.labels[i]+"'})";
        const {data} = await getbugList(listQuery);
        var bugs = data.list;
        for(var j=0;j<bugs.length;j++)
          {   if(bugs[j]._id==this.bugInfo._id)
            {
            continue;
            } 
            if(this.connnectionbuglist.length==0)
            {
              this.connnectionbuglist.push(bugs[j]);
            }
            else
            { var ishave=false;
              for(var k=0;k<this.connnectionbuglist.length;k++)
              {
                if(this.connnectionbuglist[k]._id==bugs[j]._id)
                {
                  ishave=true;
                  break;
                }
              }
              if(ishave==false)
              {
                this.connnectionbuglist.push(bugs[j]);
              }
            }
          }
      }
      //计算相关标签数量 count
      for(var i=0;i<this.connnectionbuglist.length;i++)
      { var count=0;
        this.connnectionbuglist[i].samelabel = [];
        for(var j=0;j<this.connnectionbuglist[i].labels.length;j++)
        { 
          for(var k=0;k<this.bugInfo.labels.length;k++)
          {
            if(this.bugInfo.labels[k]==this.connnectionbuglist[i].labels[j])
            {
              count++;
              this.connnectionbuglist[i].samelabel.push(this.bugInfo.labels[k]);
              break;
            }
          }
          this.connnectionbuglist[i].count = count;
        }
      }
      //count降序排序
      this.connnectionbuglist.sort(this.compare('count'));
      if(this.connnectionbuglist.length>5)
      {
        this.connnectionbuglist.splice(5,this.connnectionbuglist.length-5)
      }
    },
    compare(property){
    return function(a,b){
        var value1 = a[property];
        var value2 = b[property];
        return value2 - value1;
    }
    },
    Toanotherbug(data){
      delete data.samelabel;
      delete data.count;
      this.bugInfo=data;
      this.getconnectbug();
    },
    ifhaveannexinchildren(data,index){
      if(data.Filename!=null)
      {
        var fatherindex = index+1;
      var files=[];
      for(var i=0;i<data.Filename.length;i++)
      {
        if(data.Filename[i].father==fatherindex)
        {
          files.push(data.Filename[i].name);
        }
      }
      if(files.length>0)
      {
        return "haveannex"
      }
      }
    },
  },
  watch:{
    labelchecklist(val, oldVal){
      this.labelafterfilter=this.alllabels.filter((item, index,arr)=>{
        return this.labelchecklist.indexOf(item.type)+1;})
    },
    filtermethod(val,oldval){
     this.filterlabelschangge();
    },
  }
};
</script>
<style scoped>
div >>> .el-input {
  float: left;
  width: 100%;
}
div >>> .el-input__inner {
  width: 100%;
}
div >>>.newevent{
  float: left;
  width: 50%;
  margin-left: 30px;
}
div >>>.submitevent
{
   float: right;
   margin-right: 10px;
}
span {
  display: inline-block;
}
.box{
  position: relative;
  display: inline-block;
  width: 45%;
  text-align: center;
  margin: 0 10px;
  vertical-align: text-top;
}
.boxLong{
  width: 90%;
  text-align: center;
  margin: 10px auto 10px;
  padding: 15px;
  vertical-align: text-top;
}
.boxout{
  width: 90%;
  text-align: center;
  margin:0 auto;
  vertical-align: text-top;
}
#filterSelect {
  position: absolute;
  top: 20px;
  left: 30px;
  width: 80%;
  height: 800px;
  background: white;
}
.app-container {
  position: relative;
}
.Tittle{
  height: 30px;
  font-size: 18px;  
  margin:15px 30px;
  display: block;
}
.Tittle .left{
  float: left;
  margin: 10px 10px;
}
.Tittle .right{
  float: right;
  margin: 10px 10px;
}
.clear{clear:both;}
.changeprocess{
  float: right;
}
.changeprocess >>>.deletebutton
{
  margin-left: 5px;
}
div>>>.closeprogress
{
  float: right;
}
div h3{
  font-weight: 400;
  float: left;
  margin-left: 40px;
  margin-top:10px;
}
 div>>>.Priority1 div .svg-icon
{
  fill:#fa1d1d;
  font-size:20px;
}
 div>>>.Priority2 div .svg-icon
{
  fill:#ffdf0c;
  font-size:20px;
}
 div>>>.Priority3 div .svg-icon
{
  fill:#67c23a;
  font-size:20px;
}
.Priority1{
  fill:#fa1d1d;
  color:#fa1d1d;
  font-size:20px;
}
.Priority2{
  fill:#ffdf0c;
  color:#ffdf0c;
  font-size:20px;
}
.Priority3{
  fill:#67c23a;
  color:#67c23a;
  font-size:20px;
}
.svgicon{
    position: absolute;
    left: 45%;
    top: 62%;
    font-size:20px;
    width: 30px;
    height: 30px;
}
.button{
  display: inline-block;
  margin: 10px;
}
.block{
  display: inline-block;
  margin:30px 40%;
  height: 100px;
}
.block>>>.el-input{
  float:none;
}
div>>>#companyid{
  height: 32px;
}
div>>>#Moduleid{
   height: 32px;
   display: block;
}
div>>>.el-autocomplete{
   display: block;
   width:auto ;
}
div>>>.del_line::after {
	content: no-open-quote;
	position: absolute;
	top: 51%;
	left: 0;
	width: 100%;
	border-bottom: 1px solid #000;
}
div>>>.haveannex{
  background-color: rgb(151, 212, 247);
}
.Priority11{
  font-size: 18px;
  fill: red;
  color: red;
}
.Priority22{
  font-size: 18px;
  fill: #ffdf0c;
  color: #ffdf0c;
}
.Priority33{
  font-size: 18px;
  fill: #67c23a;
  color: #67c23a;
}
div>>>.el-tag{
  margin: 5px;
  margin-bottom: 10px;
}
div>>>.el-divider{
  margin-top: 10px;
}
.tagline{
  margin-left: 30px;
}
.connectbug{
  background-color: #fff;
  border:1px solid rgb(233, 240, 233);
  border-radius: 5px;
  margin:10px 5px 0 30px
}
.icons{
   position: absolute;
   top: 20%;
   left: 30%;
}
.box1{
  position: relative;
  display: inline-block;
  width: 45%;
  margin: 0 0 0 20px;
  padding:0 10px;
  text-align: left;
}
.box2{
  text-align: left;
  margin-left:30px;
}
.box2>>>.el-button{
  float: right;
  margin-right: 10px;
}
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
  .annexicon{
    margin-top: 1%;
    padding: 0;
    float: right;
    width: 40px;
    height: 40px;
    text-align: center;
  }
  div .findbutton{
    display: inline-block;
  }
  div>>>.el-transfer__button{
    display: block;
    padding: 5px;
    margin: 0px 0px 10px 0px;
}
div>>>.el-transfer__buttons{
  padding: 15px;
}
div>>>.labelcheckbox{
  margin-right: 0;
  padding-left: 0;
  margin-top: 5%;
}
div>>>.el-transfer-panel{
  width: 300px;
}
div>>>.el-transfer-panel__body{
  overflow:scroll;
  height:300px
}
div>>>.el-transfer-panel__footer
{
  height: 50px;
}
div>>>.el-radio-group{
  margin-top: 2%;
  margin-left: 10%;
}
</style>
