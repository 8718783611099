import {createlabelinfo,getlabelList,updatelabelList,deletelabel}  from "@/api/label"
import{getUserList} from "@/api/user";
import { getCustomerList } from '@/api/article'
const state = {
    addlablevisiable:false,
    type:[{label:"",value:""},{lable:"模块型号",value:"模块型号"},{lable:"故障报错",value:"故障报错"},{lable:"客户",value:"客户"}],
    Moudles:["CCP621"],
    Newlable:{
        name:null,
        type:null,
        description:null,
    },
    labellist:[],
    total:null,
    listQuery: 
    {
        page: 1,
        limit: 10,
        sort:null,
        filters :{},
    }, 
    title:"添加标签",  
    editlabel:false,  
}
const mutations={
    showaddlabel:state=>{
        state.Newlable = {name:null,
            type:null,
            description:null,};
        state.title = "添加标签";
        state.editlabel = false;
        state.addlablevisiable=true;
    },
    closeaddlabel:state=>{
        state.addlablevisiable=false;
    },
    clearlabel:state=>{
        state.Newlable = { 
            name:null,
            type:null,
            description:null,};
    },
    editlabel:(state,payload)=>{
        state.Newlable = payload;
        state.title = "修改标签";
        state.editlabel = true;
        state.addlablevisiable=true;
    },

}
const actions={
    async addlabel(context){
        const { data } = await createlabelinfo(state.Newlable);
        if(data)
        {   context.commit('clearlabel');
            return Promise.resolve(true);
        }
    },
    async getlabelList(context){
        const{items} = await getlabelList(state.listQuery);
        state.labellist = items.list;
        state.total = items.total;

    },
    async updatelabelList(context,payload){
        await updatelabelList({id:payload._id,data:{description:payload.description,name:payload.name,type:payload.type}});
        //return context.dispatch("getlabelList");
    },
    async deletelabel(context,payload){
        const { data } = await deletelabel({ id:payload._id });
        if(data)
        {
            return context.dispatch("getlabelList");
        }
        else
        {
            return Promise.reject("删除失败");
        }
    },
    async InIcreative(context) {
        const  {data}  = await getCustomerList();
        var customers = data;
        for(var i=0;i<customers.list.length;i++)
        {   var name = customers.list[i].company;
            var listQuery=
            {
                limit: 1,
                filters :"({name:'"+name+"'})"
            };
            const {items} = await getlabelList(listQuery);
            if(items.list.length==0)
            {
                state.Newlable={
                    name:customers.list[i].company,
                    type:"客户",
                    description:"客户："+customers.list[i].company,}
                context.dispatch("addlabel");
            }
        }
        for(var i=0;i<state.Moudles.length;i++)
        {
            var listQuery=
            {
                limit: 1,
                filters :"({name:'"+state.Moudles[i]+"'})"
            }; 
            const {items} = await getlabelList(listQuery);
            if(items.list.length==0)
            {
                state.Newlable={
                    name:state.Moudles[i],
                    type:"模块型号",
                    description:"模块型号："+state.Moudles[i],}
                context.dispatch("addlabel");  
            }
        }
        context.dispatch("getlabelList");
    },
}

export default {
    state,mutations,actions,
  }