<template>
  <div class="app-container">

    <el-dialog :visible.sync="ShowFormDialog" title="填写" :close-on-click-modal="false">
      <el-form ref="list" :model="FormDialog" label-width="100px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="序列号">
              <el-input v-model="FormDialog.productInfo.SN" :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产品名称">
              <el-input v-model="FormDialog.productInfo.module" :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产品订货号">
              <el-input v-model="FormDialog.productInfo.articl" :disabled="true" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="故障信息">
          <el-input type="textarea" :autosize="{minRows: 3}" :disabled="true" v-model="FormDialog.failureDescription" />
        </el-form-item>
        <el-form-item label="留言">
          <el-input type="textarea" autosize :disabled="true" v-model="FormDialog.SupplierRelated.Sigmatek_MessageToSupplier" />
        </el-form-item>

        <el-divider></el-divider>

        <el-form-item label="维修信息">
          <el-input type="textarea" :autosize="{minRows: 3}" v-model="FormDialog.analysis" />
        </el-form-item>

        <table border="1" cellspacing="0" style="margin-left: 30px;width:96%;" id="repair-list">
          <tr>
            <td style="width: 5mm;">No.</td>
            <td style="width: 43mm;">物料名称 | Parts</td>
            <td style="width: 28mm;">订货号 | Articl No.</td>
            <td style="width: 40mm;">故障物料序列号| Defect Part No.</td>
            <td style="width: 40mm;">新物料序列号 | New part No.</td>
            <td style="width: 14mm;">费用| Price</td>
          </tr>

          <tr v-for="(item, index) in FormDialog.componentsInfomation" :key="index">

            <td class="delete" v-on:click="remove(index)">
              {{ index + 1 }}

            </td>
            <td>
              <el-input type="textarea" autosize v-model="item.name" class="edit-input" size="small" />
            </td>
            <td>
              <el-input list="componentsList" type="text" v-model="item.number" class="edit-input" size="small" />
            </td>
            <td>
              <el-input type="text" v-model="item.badNr" class="edit-input" size="small" />
            </td>
            <td>
              <el-input type="text" v-model="item.newNr" class="edit-input" size="small" />
            </td>
            <td>
              <el-input @blur="caluete(item)" type="text" v-model="item.price_Supplier" class="edit-input input-right" size="small" />
            </td>
          </tr>

          <tr>
            <td class="create" v-on:click="creatNew"></td>
            <td colspan="3" />
            <td>合计（含税）| Total Price(INC.VAT.)</td>
            <td class="text-right">
              <el-input type="text" v-model="totalPrice" class="edit-input input-right" size="small" />
            </td>
          </tr>

        </table>

        <el-form-item label="顺丰单号" style="margin-top: 20px;">
          <el-input v-model="FormDialog.SupplierRelated.Supplier_TrackingNumber" />
        </el-form-item>

        <el-form-item style="display: flex;justify-content: flex-end">
          <el-button v-if="!FormDialog.SupplierRelated.Supplier_Complete" v-on:click="ConfirmCompletion(FormDialog.SupplierRelated.Supplier_Complete)">
            确认完成
          </el-button>
          <el-button v-if="FormDialog.SupplierRelated.Supplier_Complete" type="primary" v-on:click="ConfirmCompletion(FormDialog.SupplierRelated.Supplier_Complete)">
            已完成
          </el-button>
          <el-button v-on:click="updateForm">保存</el-button>
        </el-form-item>
      </el-form>

    </el-dialog>

    <el-table v-loading="listLoading" :data="list" ref="myTable" @ready="DateLabelRefresh" @after-geature-processed="DateLabelRefresh" element-loading-text="Loading" border fit highlight-current-row>
      <el-table-column align="center" prop="ID" label="ID" width="95">
        <template slot-scope="scope">
          {{ scope.$index + (listQuery.page - 1) * listQuery.limit + 1 }}
        </template>
      </el-table-column>

      <el-table-column align="center" prop="created_at" label="返修日期" width="160">
        <template slot-scope="scope">
          <span>{{ scope.row.productInfo.return }}</span>
        </template>
      </el-table-column>

      <el-table-column label="SN" prop="SN" width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.productInfo.SN }}
        </template>
      </el-table-column>

      <el-table-column label="Module" prop="Module" width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.productInfo.module }}
        </template>
      </el-table-column>

      <el-table-column label="Failure" prop="Failure" align="center">
        <template slot-scope="scope">
          {{ scope.row.failureDescription }}
        </template>
      </el-table-column>

      <el-table-column label="状态" prop="State" width="110" align="center">
        <template slot-scope="scope">
          {{ InfoTransformation(scope.row.SupplierRelated.Supplier_Complete) }}
        </template>
      </el-table-column>

      <el-table-column align="center" prop="operate" label="操作" width="120">
        <template slot-scope="scope">
          <!-- <div>
            <el-button 
              v-if="(EditColorChange(scope.row) === 'normal')" 
              plain
              v-on:click="openDialog(scope.row)" 
            >
              编辑
            </el-button>
            <el-button 
              v-if="(EditColorChange(scope.row) === 'primary')" 
              type="primary" plain
              v-on:click="openDialog(scope.row)" 
            >
              编辑
            </el-button>
            <el-button 
              v-if="(EditColorChange(scope.row) === 'danger')" 
              type="danger" plain
              v-on:click="openDialog(scope.row)" 
            >
              编辑
            </el-button>
          </div> -->
          <div>
            <router-link :to="{
              path: 'form_Supplier',
              query: {
                form_infoid: scope.row._id,
                filters: listQuery,
              },
            }">
              <el-button v-if="(EditColorChange(scope.row) === 'normal')" plain>
                编辑
              </el-button>
              <el-button v-if="(EditColorChange(scope.row) === 'primary')" type="primary" plain>
                编辑
              </el-button>
              <el-button v-if="(EditColorChange(scope.row) === 'danger')" type="danger" plain>
                编辑
              </el-button>
            </router-link>
          </div>
        </template>
      </el-table-column>

    </el-table>
    <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="fetchData" />
  </div>
</template>

<script>
import { updateArticle } from "@/api/article";
import { getList } from "@/api/table";
import { MessageBox, Message } from "element-ui";
import Pagination from "@/components/Pagination";
import { GetAllPlant } from "@/api/mesproductinfo";
import { getTypeGroup, getTotalDoneNumber } from "@/api/mes";
import { getCompanyCode } from "@/utils/auth";

export default {
  components: {
    Pagination,
  },
  data()
  {
    return {
      filterSN: null,
      _id: null,
      list: [],
      tableData: [],
      tableHeader: [],
      showExcelUpload: false,
      ExcelList: [],
      activeName: "CN",
      createdTimes: 0,
      listLoading: true,
      showComment: false,
      downloadLoading: false,
      filename: "",
      autoWidth: true,
      bookType: "xlsx",
      comment: {
        id: null,
        commentText: [
          {
            data: null,
          },
        ],
      },
      total: 40,
      showFilter: false,
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
        filters: {},
      },

      CompanyInfo: null,
      companyChoose: '',
      ShowFormDialog: false,

      FormDialog: {
        id: null,
        customerInfo: {
          company: null,
          contactor: null,
          tel: null,
          fax: null,
          add: null,
          WarrantyDays: null,
        },
        productInfo: {
          SN: null,
          module: null,
          articl: null,
          hardware: null,
          software: null,
          report: null,
          parts: null,
          po: null,
          return: null,
          delivery: null,
          warranty: null,

          Quote: null,
          reportNumber: null,
        },
        failureDescription: null,
        faultclass: null,
        faultSecondclass: null,
        analysis: null,
        componentsInfomation: [
          {
            name: null,
            number: null,
            badNr: null,
            newNr: null,
            price: null,
            price_Supplier: null,
          },
        ],
        back: false,
        SupplierRelated: {
          Sigmatek_MessageToSupplier: null,
          Supplier_Complete: null,
          Supplier_TotalPrice: null,
          Supplier_TrackingNumber: null,
        },
        times: null,
        contactor: "null",
        Date: null,
        sigmatekReport: null,
        ups: {
          sendType: null,
          sendDate: null,
          backType: null,
          backDate: null,
        },
      },

      TodayFlag: false,
      TheDayBeforeYesterdayFlag: false,
      WeekFlag: false,
      EarlierFlag: false,
    };
  },

  watch: {
    activeName(val)
    {
      this.$router.push(`${this.$route.path}?tab=${val}`);
    },
    filterSN(val)
    {
      this.filterbySN();
    },
  },

  computed: {
    totalPrice: function ()
    {
      let total = 0;
      let haveo = false;
      // console.log(this.FormDialog);
      for (let i = 0; i < this.FormDialog.componentsInfomation.length; i++) {
        if (
          (this.FormDialog.componentsInfomation[i].price_Supplier !== "") &
          (this.FormDialog.componentsInfomation[i].price_Supplier !== null) &
          (this.FormDialog.componentsInfomation[i].price_Supplier !== undefined)
        ) {
          total += parseFloat(this.FormDialog.componentsInfomation[i].price_Supplier);
          if (this.FormDialog.componentsInfomation[i].price_Supplier.indexOf("€") != -1) {
            haveo = true;
          }
        }

        //  this.list.componentsInfomation[i].price = parseFloat( this.list.componentsInfomation[i].price).toFixed(2);
      };
      this.FormDialog.SupplierRelated.Supplier_TotalPrice = total;
      if (haveo) {
        return total.toFixed(2) + "€";
      } else {
        return total.toFixed(2);
      };
    },
  },

  mounted: function ()
  {
    if (this.$route.query.filters) {
      // console.log(this.$route.query.filters);
      this.listQuery = this.$route.query.filters;
    }
    this.fetchData();
    this.DateLabelRefresh();
  },

  updated()
  {
    this.DateLabelRefresh();
  },

  created()
  {
    this.activeName = "comment";

    this.TodayFlag = false;
    this.TheDayBeforeYesterdayFlag = false;
    this.WeekFlag = false;
    this.EarlierFlag = false;
  },

  methods: {
    async fetchData()
    {
      this.listLoading = true;
      this.list = [];

      let code = "";
      code = getCompanyCode();
      let CompanyInfo = await GetAllPlant({ companyCode: code });

      this.companyList = CompanyInfo.data.list;
      if (this.companyChoose == '') {
        if (code == undefined || code == "undefined") {
          code = "HaoFang";
        }
      } else {
        code = this.companyChoose;
      }
      this.CompanyInfo = CompanyInfo.data.list[0];
      const typeGroup = await getTypeGroup({ Affiliation: code });
      let typeGroupArray = [];
      let typeGroupDoneArray = [];
      for (let i = 0; i < typeGroup.data.list.length; i++) {
        let doneNum = await getTotalDoneNumber({
          type: [typeGroup.data.list[i]._id],
          code: code
        });
        if (doneNum.data.list.length > 0) {

          typeGroupDoneArray.push({
            type: typeGroup.data.list[i]._id,
            doneNum: doneNum.data.list[0].totalDoneNumber,
            preDoneNum: doneNum.data.list[0].totalNumber - doneNum.data.list[0].totalDoneNumber

          });
        } else {
          typeGroupDoneArray.push({
            type: typeGroup.data.list[i]._id,
            doneNum: 0,
            preDoneNum: 0
          });
        }
        typeGroupArray.push(typeGroup.data.list[i]._id);
      }
      if (typeGroupArray.length == 0) {
        this.MaterialList = [];
        this.listLoading = false;
        return;
      }

      this.listQuery.filters = "({$and:[{$or: [";
      for (let j = 0; j < typeGroupArray.length; j++) {
        this.listQuery.filters += "{'productInfo.module':'" + typeGroupArray[j] + "'},";
      };
      this.listQuery.filters += "]},{'back': true}]})";
      const { data } = await getList(this.listQuery);

      for (var i = 0; i < data.list.length; i++) {
        let obj = {
          customerInfo: {
            company: null,
            contactor: null,
            tel: null,
            fax: null,
            add: null,
          },
          productInfo: {
            SN: null,
            Module: null,
            articl: null,
            hardware: null,
            software: null,
            report: null,
            parts: null,
            po: null,
            return: null,
            delivery: null,
            warranty: null,

            Quote: null,
            reportNumber: null,
          },
          failureDescription: null,
          analysis: null,
          componentsInfomation: [
            {
              name: null,
              number: null,
              badNr: null,
              newNr: null,
              price: null,
              price_Supplier: null,
            },
          ],
          back: false,
          SupplierRelated: {
            Sigmatek_MessageToSupplier: null,
            Supplier_Complete: null,
            Supplier_TotalPrice: null,
            Supplier_TrackingNumber: null,
          },
          times: null,
          contactor: null,
          Date: null,
          Comment: [],
          sigmatekReport: null,
          ups: {
            sendType: null,
            sendDate: null,
            backType: null,
            backDate: null,
            upsOrder: null,
            comment: null,
          },
        };
        this.list.push(obj);
        Object.assign(this.list[i], data.list[i]);
      }
      this.total = data.total;
      setTimeout(() =>
      {
        this.listLoading = false;
      }, 0.5 * 1000);
    },

    DateLabelRefresh()
    {
      this.TodayFlag = false;
      this.TheDayBeforeYesterdayFlag = false;
      this.WeekFlag = false;
      this.EarlierFlag = false;
      // 获取表格元素
      const tableEl = this.$refs.myTable.$el;
      // 获取行集合
      const rowEls = tableEl.querySelectorAll('.el-table__row');
      // 遍历获取每一行左上角在屏幕上的绝对位置
      const today = new Date();
      const oldEls = tableEl.querySelectorAll('.date-label'); //配合删除用
      oldEls.forEach((el) =>
      {
        el.remove();
      });
      rowEls.forEach((rowEl) =>
      {
        const top = rowEl.offsetTop + 40;
        const left = rowEl.offsetLeft + 5;

        const date = new Date(rowEl.cells[1].innerText);
        const diff = Math.floor((today - date) / (24 * 60 * 60 * 1000));
        const divEl = document.createElement('div');
        divEl.setAttribute('class', 'date-label'); //配合删除用
        divEl.style.position = 'absolute';
        divEl.style.top = top + 'px';
        divEl.style.left = left + 'px';
        divEl.style.width = '60px';
        divEl.style.height = '20px';
        divEl.style.backgroundColor = 'white';
        divEl.style.zIndex = '100';
        divEl.style.color = 'blick';
        divEl.style.textAlign = 'center';
        let text;
        if (diff < 1 & this.TodayFlag === false) {
          this.TodayFlag = true;
          text = "今天";
        } else if (diff >= 1 & diff < 3 & this.TheDayBeforeYesterdayFlag === false) {
          this.TheDayBeforeYesterdayFlag = true;
          text = "三天内";
        } else if (diff >= 3 & diff < 7 & this.WeekFlag === false) {
          this.WeekFlag = true;
          text = "本周";
        } else if (diff >= 7 & this.EarlierFlag === false) {
          this.EarlierFlag = true;
          text = "更早";
        } else {
          text = "";
          divEl.style.backgroundColor = 'transparent';
        }
        divEl.innerText = text;
        tableEl.appendChild(divEl);
      });
    },

    openDialog(data)
    {
      // console.log(data);
      this.FormDialog = {
        id: null,
        customerInfo: {
          company: null,
          contactor: null,
          tel: null,
          fax: null,
          add: null,
          WarrantyDays: null,
        },
        productInfo: {
          SN: null,
          module: null,
          articl: null,
          hardware: null,
          software: null,
          report: null,
          parts: null,
          po: null,
          return: null,
          delivery: null,
          warranty: null,

          Quote: null,
          reportNumber: null,
        },
        failureDescription: null,
        faultclass: null,
        faultSecondclass: null,
        analysis: null,
        componentsInfomation: [
          {
            name: null,
            number: null,
            badNr: null,
            newNr: null,
            price: null,
            price_Supplier: null,
          },
        ],
        back: false,
        SupplierRelated: {
          Sigmatek_MessageToSupplier: null,
          Supplier_Complete: null,
          Supplier_TotalPrice: null,
          Supplier_TrackingNumber: null,
        },
        times: null,
        contactor: "null",
        Date: null,
        sigmatekReport: null,
        ups: {
          sendType: null,
          sendDate: null,
          backType: null,
          backDate: null,
        },
      };
      this.FormDialog = data;
      this.ShowFormDialog = true;
    },

    remove(index)
    {
      this.FormDialog.componentsInfomation.splice(index, 1);
    },
    caluete(item)
    {
      if (item.price_Supplier.indexOf("€") != -1) {
        item.price_Supplier = parseFloat(item.price_Supplier).toFixed(2) + "€";
      } else item.price_Supplier = parseFloat(item.price_Supplier).toFixed(2);
    },
    creatNew()
    {
      if (this.FormDialog.componentsInfomation.length < 10) {
        this.FormDialog.componentsInfomation.push({
          name: null,
          number: null,
          badNr: null,
          newNr: null,
          price: null,
          price_Supplier: null,
        });
      }
    },
    InfoTransformation(data)
    {
      if (data === true) {
        return "已完成";
      } else {
        return "待完成";
      }
    },
    ConfirmCompletion(state)
    {
      if (state === undefined) {
        state = false;
      }
      if (state) {
        this.FormDialog.SupplierRelated.Supplier_Complete = false;
      } else {
        this.FormDialog.SupplierRelated.Supplier_Complete = true;
      }
      // console.log(this.FormDialog.SupplierRelated.Supplier_Complete)
      this.$forceUpdate();
    }, //确认完成按钮
    EditColorChange(date)
    {
      const today = new Date();
      const thatday = new Date(date.productInfo.return);
      const diff = Math.floor((today - thatday) / (24 * 60 * 60 * 1000));
      if (date.SupplierRelated.Supplier_Complete === true) {
        return 'normal';
      } else if (diff < 3) {
        return 'primary';
      } else if (diff >= 3) {
        return 'danger';
      }
    },

    async updateForm()
    {

      const updateData = {
        id: this.FormDialog._id,
        data: this.FormDialog,
      };
      const data = await updateArticle(updateData);
      if (data) {
        this.id = data.data;
        Message({
          message: "保存成功",
          type: "success",
          duration: 5 * 1000,
        });
      }
      this.fetchData();
      this.ShowFormDialog = false;
    },

    filterbySN()
    {
      if (this.filterSN != "") {
        this.listQuery.filters =
          "({'productInfo.SN':{$regex:'" + this.filterSN + "'}})";
        this.fetchData();
      } else {
        this.listQuery.filters = "{}";
        this.fetchData();
      }
    },
  },
};
</script>

<style scoped>
span {
  display: inline-block;
}

#filterSelect {
  position: absolute;
  top: 20px;
  left: 30px;
  width: 80%;
  height: 800px;
  background: white;
}

.filter>>>.el-dialog {
  width: 80%;
}

.app-container {
  position: relative;
}

p {
  margin-top: 7px;
  margin-bottom: 7px;
}

.yellow {
  background-color: yellow;
  border-radius: 5px;
}

.red {
  background-color: rgba(243, 129, 158, 0.863);
  border-radius: 5px;
}

.blue {
  background-color: rgb(155, 155, 219);
  border-radius: 5px;
}

.edit-input>>>.el-textarea__inner {
  padding-right: 100px;
  overflow: hidden !important;
  border: 0px;
  resize: none;
  padding: 0;
  width: 98%;
  color: #000;
}

.edit-input>>>.el-input__inner {
  padding-right: 100px;
  border: 0px;
  padding: 0;
  width: 98%;
  color: #000;
}

.delete:hover {
  background-image: url("../../../Img/delete.jpg");
  background-repeat: no-repeat;
  color: transparent;
  background-size: cover;
  cursor: pointer;
}

.inline {
  width: 7px;
}

.create:hover {
  background-image: url("../../../Img/create.jpg");
  background-repeat: no-repeat;
  color: transparent;
  background-size: cover;
  cursor: pointer;
}

div>>>.filterbysn {
  display: inline-block;
  width: 150px;
  margin-left: 10px;
}
</style>