var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list" },
    [
      _c(
        "div",
        { staticClass: "button" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.showInfo(null)
                },
              },
            },
            [_vm._v("新建")]
          ),
        ],
        1
      ),
      _c(
        "el-popover",
        {
          staticClass: "findbutton",
          attrs: {
            placement: "bottom-end",
            width: "auto",
            trigger: "click",
            height: "auto",
          },
        },
        [
          _c(
            "el-transfer",
            {
              attrs: {
                data: this.labelafterfilter,
                titles: ["可选标签", "已有标签"],
              },
              on: {
                change: function ($event) {
                  return _vm.filterlabelschangge()
                },
              },
              model: {
                value: _vm.filterlabels,
                callback: function ($$v) {
                  _vm.filterlabels = $$v
                },
                expression: "filterlabels",
              },
            },
            [
              _c(
                "el-checkbox-group",
                {
                  attrs: { slot: "left-footer" },
                  slot: "left-footer",
                  model: {
                    value: _vm.labelchecklist,
                    callback: function ($$v) {
                      _vm.labelchecklist = $$v
                    },
                    expression: "labelchecklist",
                  },
                },
                [
                  _c("el-checkbox", {
                    staticClass: "labelcheckbox",
                    attrs: { label: "客户" },
                  }),
                  _c("el-checkbox", {
                    staticClass: "labelcheckbox",
                    attrs: { label: "故障报错" },
                  }),
                  _c("el-checkbox", {
                    staticClass: "labelcheckbox",
                    attrs: { label: "模块型号" },
                  }),
                ],
                1
              ),
              _c(
                "el-radio-group",
                {
                  attrs: { slot: "right-footer" },
                  slot: "right-footer",
                  model: {
                    value: _vm.filtermethod,
                    callback: function ($$v) {
                      _vm.filtermethod = $$v
                    },
                    expression: "filtermethod",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "and" } }, [
                    _vm._v("与条件筛选"),
                  ]),
                  _c("el-radio", { attrs: { label: "or" } }, [
                    _vm._v("或条件筛选"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { slot: "reference", type: "primary" },
              slot: "reference",
            },
            [_vm._v("添加搜索标签")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showbugInfo, title: "故障信息", id: "dialog" },
          on: {
            "update:visible": function ($event) {
              _vm.showbugInfo = $event
            },
          },
        },
        [
          _c("div", { staticClass: "from" }, [
            _c("div", { staticClass: "boxout" }, [
              _c(
                "div",
                { staticClass: "box" },
                [
                  _c("p", [_vm._v("涉及客户")]),
                  _c("el-autocomplete", {
                    attrs: {
                      "fetch-suggestions": _vm.suggestions_company,
                      id: "companyid",
                    },
                    model: {
                      value: _vm.bugInfo.company,
                      callback: function ($$v) {
                        _vm.$set(_vm.bugInfo, "company", $$v)
                      },
                      expression: "bugInfo.company",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "box" }, [
                _c("p", [_vm._v("优先级")]),
                _c(
                  "div",
                  { class: _vm.Getclass(this.bugInfo.priority) },
                  [
                    _c(
                      "el-select",
                      {
                        ref: "select_icon",
                        staticClass: "selecticon",
                        attrs: { size: "small", placeholder: "" },
                        on: { change: _vm.iconChange },
                        model: {
                          value: _vm.bugInfo.priority,
                          callback: function ($$v) {
                            _vm.$set(_vm.bugInfo, "priority", $$v)
                          },
                          expression: "bugInfo.priority",
                        },
                      },
                      _vm._l(_vm.priority, function (item, index) {
                        return _c(
                          "el-option",
                          { key: index, attrs: { label: item, value: item } },
                          [
                            _c(
                              "div",
                              { class: _vm.Getclass(item) },
                              [
                                _c("svg-icon", {
                                  attrs: { "icon-class": "Priority1" },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                    _c("svg-icon", {
                      attrs: {
                        "icon-class": "Priority1",
                        "class-name": "svgicon",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "boxLong" },
              [
                _c("p", [_vm._v("故障模块")]),
                _c("el-autocomplete", {
                  attrs: {
                    "fetch-suggestions": _vm.suggestions_Module,
                    id: "Moduleid",
                  },
                  model: {
                    value: _vm.bugInfo.Module,
                    callback: function ($$v) {
                      _vm.$set(_vm.bugInfo, "Module", $$v)
                    },
                    expression: "bugInfo.Module",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "boxLong" },
              [
                _c("p", [_vm._v("重点故障描述")]),
                _c("el-input", {
                  attrs: { size: "small" },
                  model: {
                    value: _vm.bugInfo.Description,
                    callback: function ($$v) {
                      _vm.$set(_vm.bugInfo, "Description", $$v)
                    },
                    expression: "bugInfo.Description",
                  },
                }),
              ],
              1
            ),
            _vm.newinfo
              ? _c(
                  "div",
                  { staticClass: "boxLong" },
                  [
                    _c("p", [_vm._v("当前进展")]),
                    _c("el-input", {
                      attrs: { size: "small" },
                      model: {
                        value: _vm.bugInfo.newprocess,
                        callback: function ($$v) {
                          _vm.$set(_vm.bugInfo, "newprocess", $$v)
                        },
                        expression: "bugInfo.newprocess",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "boxLong" },
              [
                _c(
                  "div",
                  _vm._l(_vm.bugInfo.labels, function (tag, index) {
                    return _c("el-tag", { key: index }, [
                      _vm._v(" " + _vm._s(tag)),
                    ])
                  }),
                  1
                ),
                _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "bottom-end",
                      width: "auto",
                      trigger: "click",
                      height: "auto",
                    },
                  },
                  [
                    _c(
                      "el-transfer",
                      {
                        attrs: {
                          data: this.labelafterfilter,
                          titles: ["可选标签", "已有标签"],
                        },
                        model: {
                          value: _vm.bugInfo.labels,
                          callback: function ($$v) {
                            _vm.$set(_vm.bugInfo, "labels", $$v)
                          },
                          expression: "bugInfo.labels",
                        },
                      },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            attrs: { slot: "left-footer" },
                            slot: "left-footer",
                            model: {
                              value: _vm.labelchecklist,
                              callback: function ($$v) {
                                _vm.labelchecklist = $$v
                              },
                              expression: "labelchecklist",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "labelcheckbox",
                              attrs: { label: "客户" },
                            }),
                            _c("el-checkbox", {
                              staticClass: "labelcheckbox",
                              attrs: { label: "故障报错" },
                            }),
                            _c("el-checkbox", {
                              staticClass: "labelcheckbox",
                              attrs: { label: "模块型号" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { slot: "reference", type: "primary" },
                        slot: "reference",
                      },
                      [_vm._v("添加标签")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveInfo } },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            fit: "",
            "highlight-current-row": "",
            "cell-class-name": _vm.CellClass,
          },
          on: {
            "sort-change": _vm.sortchange,
            "filter-change": _vm.filterChange,
            "row-dblclick": _vm.rowselect,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "ID",
              width: "100",
              prop: "Num",
              sortable: "custom",
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "el-popover",
                      {
                        attrs: { placement: "top", width: "160" },
                        model: {
                          value: _vm.visible_ID,
                          callback: function ($$v) {
                            _vm.visible_ID = $$v
                          },
                          expression: "visible_ID",
                        },
                      },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.filternumber,
                            callback: function ($$v) {
                              _vm.filternumber = $$v
                            },
                            expression: "filternumber",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "right",
                              margin: "10px 0",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    ;(_vm.visible_ID = false), _vm.filterbyNum()
                                  },
                                },
                              },
                              [_vm._v("确定")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          { attrs: { slot: "reference" }, slot: "reference" },
                          [_vm._v("ID")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.Num) + " ")]
                },
              },
            ]),
          }),
          _c(
            "el-table-column",
            {
              attrs: {
                label: "优先级",
                width: "100",
                align: "center",
                prop: "priority",
                sortable: "custom",
              },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "el-popover",
                        {
                          attrs: { placement: "top" },
                          model: {
                            value: _vm.visible_Per,
                            callback: function ($$v) {
                              _vm.visible_Per = $$v
                            },
                            expression: "visible_Per",
                          },
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.checkListPriority,
                                callback: function ($$v) {
                                  _vm.checkListPriority = $$v
                                },
                                expression: "checkListPriority",
                              },
                            },
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: "1" } },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      "icon-class": "Priority1",
                                      "class-name": "Priority11",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-checkbox",
                                { attrs: { label: "2" } },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      "icon-class": "Priority1",
                                      "class-name": "Priority22",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-checkbox",
                                { attrs: { label: "3" } },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      "icon-class": "Priority1",
                                      "class-name": "Priority33",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "right",
                                margin: "0",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      ;(_vm.visible_Per = false),
                                        _vm.filterbypriority()
                                    },
                                  },
                                },
                                [_vm._v("确定")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            { attrs: { slot: "reference" }, slot: "reference" },
                            [_vm._v("优先级")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("svg-icon", {
                attrs: {
                  "icon-class": "Priority1",
                  "class-name": "custom-class",
                },
              }),
            ],
            1
          ),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "创建人",
              width: "120",
              prop: "Submitter",
              sortable: "custom",
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "el-popover",
                      {
                        attrs: { placement: "right", width: "50" },
                        model: {
                          value: _vm.visible_Submetter,
                          callback: function ($$v) {
                            _vm.visible_Submetter = $$v
                          },
                          expression: "visible_Submetter",
                        },
                      },
                      [
                        _c("el-autocomplete", {
                          attrs: {
                            "fetch-suggestions": _vm.suggestions_submiter,
                          },
                          model: {
                            value: _vm.filtersubmitter,
                            callback: function ($$v) {
                              _vm.filtersubmitter = $$v
                            },
                            expression: "filtersubmitter",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: { "text-align": "right", margin: "0" },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    ;(_vm.visible_Submetter = false),
                                      _vm.filterbySubmitter()
                                  },
                                },
                              },
                              [_vm._v("确定")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          { attrs: { slot: "reference" }, slot: "reference" },
                          [_vm._v("创建人")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.Submitter))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "涉及客户",
              width: "160",
              align: "center",
              prop: "company",
              sortable: "custom",
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "el-popover",
                      {
                        attrs: { placement: "right", width: "50" },
                        model: {
                          value: _vm.visible_Company,
                          callback: function ($$v) {
                            _vm.visible_Company = $$v
                          },
                          expression: "visible_Company",
                        },
                      },
                      [
                        _c("el-autocomplete", {
                          attrs: {
                            "fetch-suggestions": _vm.suggestions_company,
                          },
                          model: {
                            value: _vm.filtercompany,
                            callback: function ($$v) {
                              _vm.filtercompany = $$v
                            },
                            expression: "filtercompany",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: { "text-align": "right", margin: "0" },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    ;(_vm.visible_Company = false),
                                      _vm.filterbyCompany()
                                  },
                                },
                              },
                              [_vm._v("确定")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          { attrs: { slot: "reference" }, slot: "reference" },
                          [_vm._v("涉及客户")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.company) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "涉及模块",
              width: "120",
              align: "center",
              prop: "Module",
              sortable: "custom",
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "el-popover",
                      {
                        attrs: { placement: "right", width: "50" },
                        model: {
                          value: _vm.visible_Moudle,
                          callback: function ($$v) {
                            _vm.visible_Moudle = $$v
                          },
                          expression: "visible_Moudle",
                        },
                      },
                      [
                        _c("el-autocomplete", {
                          attrs: {
                            "fetch-suggestions": _vm.suggestions_Module,
                          },
                          model: {
                            value: _vm.filterMoudle,
                            callback: function ($$v) {
                              _vm.filterMoudle = $$v
                            },
                            expression: "filterMoudle",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: { "text-align": "right", margin: "0" },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    ;(_vm.visible_Moudle = false),
                                      _vm.filterbyMoudle()
                                  },
                                },
                              },
                              [_vm._v("确定")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          { attrs: { slot: "reference" }, slot: "reference" },
                          [_vm._v("涉及模块")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.Module) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "问题具体描述", width: "610", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.Description) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "附件", width: "120", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        class: _vm.ifhaveannex(scope.row),
                        on: {
                          click: function ($event) {
                            return _vm.FShowannex(scope.row, 0)
                          },
                        },
                      },
                      [_c("i", { staticClass: "el-icon-folder-opened" })]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.Showannex },
          on: {
            "update:visible": function ($event) {
              _vm.Showannex = $event
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              attrs: {
                action: "''",
                multiple: "",
                accept:
                  ".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PBG, .GIF, .pdf",
                "http-request": _vm.submitList,
                "show-file-list": false,
              },
            },
            [
              _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                _vm._v("点击上传"),
              ]),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "marginTop",
              attrs: {
                data: _vm.getfiles(this.annexfathe),
                "element-loading-text": "Loading",
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
              on: { "cell-click": _vm.annexPreview },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "created_at",
                  label: "文件列表",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row, function (file, index) {
                        return _c("span", { key: index }, [
                          _vm._v(_vm._s(file)),
                        ])
                      })
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "操作", width: "250" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.downloadFile(scope.row)
                              },
                            },
                          },
                          [_vm._v("下载")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "gray" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteFile(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showDelete, title: "删除" },
          on: {
            "update:visible": function ($event) {
              _vm.showDelete = $event
            },
          },
        },
        [
          _c("h4", [_vm._v("确定删除")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.deleteInfo } },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "normal" },
                  on: {
                    click: function ($event) {
                      _vm.showDelete = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showProcess, tittle: "项目进度", top: "10px" },
          on: {
            "update:visible": function ($event) {
              _vm.showProcess = $event
            },
          },
        },
        [
          _c("span", { staticClass: "Tittle" }, [
            _c("p", { staticClass: "left" }, [_vm._v("创建时间")]),
            _c("p", { staticClass: "right" }, [
              _vm._v(_vm._s(this.bugInfo.addtime)),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "tagline" },
            _vm._l(_vm.bugInfo.labels, function (tag, index) {
              return _c("el-tag", { key: index }, [_vm._v(" " + _vm._s(tag))])
            }),
            1
          ),
          _c("el-divider"),
          _c(
            "div",
            { staticClass: "timeline" },
            [
              _c(
                "el-timeline",
                _vm._l(_vm.bugInfo.Process, function (pro, index) {
                  return _c(
                    "el-timeline-item",
                    { key: index, attrs: { timestamp: _vm.getstamp(pro) } },
                    [
                      _vm._v(" " + _vm._s(pro.event) + " "),
                      _c(
                        "el-button",
                        {
                          staticClass: "annexicon",
                          class: _vm.ifhaveannexinchildren(_vm.bugInfo, index),
                          on: {
                            click: function ($event) {
                              return _vm.FShowannex(_vm.bugInfo, index + 1)
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-folder-opened" })]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          !this.bugInfo.isclosed
            ? _c(
                "div",
                [
                  _c("el-input", {
                    staticClass: "newevent",
                    model: {
                      value: _vm.bugInfo.newprocess,
                      callback: function ($$v) {
                        _vm.$set(_vm.bugInfo, "newprocess", $$v)
                      },
                      expression: "bugInfo.newprocess",
                    },
                  }),
                  _vm.ifhaveright()
                    ? _c(
                        "el-popover",
                        {
                          staticClass: "closeprogress",
                          attrs: { placement: "top", width: "200" },
                          model: {
                            value: _vm.visible,
                            callback: function ($$v) {
                              _vm.visible = $$v
                            },
                            expression: "visible",
                          },
                        },
                        [
                          _c("p", [_vm._v("是否确认关闭")]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "right",
                                margin: "0",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.Ccloseprogress()
                                    },
                                  },
                                },
                                [_vm._v("确定")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-button",
                            { attrs: { slot: "reference" }, slot: "reference" },
                            [_vm._v("关闭项目")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      staticClass: "submitevent",
                      on: {
                        click: function ($event) {
                          return _vm.sbutmitnewevent()
                        },
                      },
                    },
                    [_vm._v("提交事件")]
                  ),
                  _c("div", { staticClass: "clear" }),
                ],
                1
              )
            : _vm._e(),
          this.bugInfo.isclosed
            ? _c(
                "div",
                [
                  _c("h3", [_vm._v("项目已关闭")]),
                  _vm.ifhaveright()
                    ? _c(
                        "el-popover",
                        {
                          staticClass: "closeprogress",
                          attrs: { placement: "top", width: "200" },
                          model: {
                            value: _vm.visible,
                            callback: function ($$v) {
                              _vm.visible = $$v
                            },
                            expression: "visible",
                          },
                        },
                        [
                          _c("p", [_vm._v("是否确认开启")]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "right",
                                margin: "0",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.Copenprogress()
                                    },
                                  },
                                },
                                [_vm._v("确定")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-button",
                            { attrs: { slot: "reference" }, slot: "reference" },
                            [_vm._v("开启项目")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "clear" }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "connectbug" },
            [
              _c(
                "el-carousel",
                { attrs: { "indicator-position": "outside", height: "220px" } },
                _vm._l(this.connnectionbuglist, function (item, index) {
                  return _c("el-carousel-item", { key: index }, [
                    _c("div", { staticClass: "box1" }, [
                      _c("p", [_vm._v("ID: " + _vm._s(item.Num))]),
                    ]),
                    _c("div", { staticClass: "box1" }, [
                      _c("p", [_vm._v("优先级：")]),
                      _c(
                        "div",
                        {
                          staticClass: "icons",
                          class: _vm.Getclass(item.priority),
                        },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "Priority1" },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "box1" }, [
                      _c("p", [_vm._v("涉及客户：" + _vm._s(item.company))]),
                    ]),
                    _c("div", { staticClass: "box1" }, [
                      _c("p", [_vm._v("涉及模块：" + _vm._s(item.Module))]),
                    ]),
                    _c("div", { staticClass: "box2" }, [
                      _c("p", [
                        _vm._v("具体问题描述：" + _vm._s(item.Description)),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "box2" },
                      _vm._l(item.labels, function (label, index) {
                        return _c("el-tag", { key: index }, [
                          _vm._v(_vm._s(label)),
                        ])
                      }),
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "box2" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.Toanotherbug(item)
                              },
                            },
                          },
                          [_vm._v("跳转至该bug")]
                        ),
                      ],
                      1
                    ),
                  ])
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              "page-size": 10,
              layout: "prev, pager, next, jumper",
              total: this.total,
              "current-page": _vm.listQuery.page,
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }