import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/Api/user/login',
    method: 'post',
    data
  })
}
export function loginByToken(data) {
  return request({
    url: '/Api/user/loginByToken',
    method: 'post',
    data
  })
}


export function logout() {
  return request({
    url: '/Api/user/logout',
    method: 'post'
  })
}
export function gettokenlist() {
  return request({
    url: '/Api/user/gettokenlist',
    method: 'get',
  })
}
export function getUserList() {
  return request({
    url: '/Api/user/getUserList',
    method: 'get',
  })
}
export function getunregusers() {
  return request({
    url: '/Api/user/getunregusers',
    method: 'get',
  })
}
export function getInfobyname(data) {
  return request({
    url: '/Api/user/getInfobyname',
    method: 'get',
    params:  {data}
  })
}
export function updateuserinfo(data) {
  return request({
    url: '/Api/user/updateuserinfo',
    method: 'post',
    data
  })
}
export function reguser(data) {
  return request({
    url: '/Api/user/reguser',
    method: 'post',
    data
  })
}
export function getInfo(token) {
  return request({
    url: '/Api/user/getInfo',
    method: 'get',
    params: { token }
  })
}
export function deleteuser(data) {
  return request({
    url: '/Api/user/deleteuser',
    method: 'post',
    data
  })
}