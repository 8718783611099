<template>
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="总览" name="first">
        <el-row :gutter="15" style="display: flex; height: 850px;">

          <el-col :span="8">
            
            <div  style="margin-bottom: 15px;">
              <el-card class="NoticeInfo">
                <div slot="header" > <span>操作面板</span> </div>
                <div style="height: 200px;">
                  <el-button @click="showCalendaringOperation"> 日历操作 </el-button>
                </div>
              </el-card>               
            </div>

            <div>
              <el-card class="NoticeInfo">
                <div slot="header" >
                   <span>今日事件</span> 
                </div>
                <div style="height: 510px;">

                  <div v-if="!Calendar_Collection_Today.length" class="NoNotification">
                    <img
                      style="opacity: 0.3"
                      src="../dashboard/icon/NoNotification.png"
                    />
                  </div>

                  <div v-if="Calendar_Collection_Today.length">
                    <el-card 
                      v-for="(One_Event, One_Event_Number) in Calendar_Collection_Today" 
                      :key="One_Event_Number"
                      :class="['current', componentStyle(One_Event)]"
                    >

                      <!-- 加班 -->
                      <div v-if="One_Event.hasOwnProperty('Where')&&One_Event.hasOwnProperty('Why')" style="line-height: 1.5;">
                        <span v-if="One_Event.isAllDay">
                          <span style="font-weight: bold;">
                            {{ One_Event.Personnel }}
                          </span>
                          今天在 {{ One_Event.Where }} 因为 {{One_Event.Why}} 加班了，要加班一整天。
                        </span>
                        <span v-else>
                          <span style="font-weight: bold;">
                            {{ One_Event.Personnel }}
                          </span>
                          今天在 {{ One_Event.Where }} 因为 {{One_Event.Why}} 加班了，
                          <span v-if="One_Event.LeaveTimeS">
                            他是在 {{ ToHHMMSS(One_Event.LeaveTimeS) }} 开始的，
                          </span>
                          <span v-if="One_Event.LeaveTimeE">
                            他能在 {{ ToHHMMSS(One_Event.LeaveTimeE) }} 结束。
                          </span>
                        </span>
                      </div>

                      <!-- 请假 -->
                      <div v-else-if="One_Event.hasOwnProperty('Why')" style="line-height: 1.5;">   
                        <span v-if="One_Event.isAllDay">
                          <span style="font-weight: bold;">
                            {{ One_Event.Personnel }}
                          </span>
                          今天因为 {{ One_Event.Why }} 请假了，请的是{{ One_Event.nature }}，要请假一整天。
                        </span>
                        <span v-else>
                          <span style="font-weight: bold;">
                            {{ One_Event.Personnel }}
                          </span>
                          今天因为 {{ One_Event.Why }} 请假了，请的是{{ One_Event.nature }},
                          <span v-if="One_Event.LeaveTimeS">
                            他是在 {{ ToHHMMSS(One_Event.LeaveTimeS) }} 离开的，
                          </span>
                          <span v-if="One_Event.LeaveTimeE">
                            他能在 {{ ToHHMMSS(One_Event.LeaveTimeE) }} 回来。
                          </span>
                        </span>
                      </div>

                      <!-- 出差 -->
                      <div v-else-if="One_Event.hasOwnProperty('Where')" style="line-height: 1.5;">
                        <span v-if="One_Event.isAllDay">
                          <span style="font-weight: bold;">
                            {{ One_Event.Personnel }}
                          </span>
                          今天去 {{ One_Event.Where }} 出差了，要出差一整天。
                        </span>
                        <span v-else>
                          <span style="font-weight: bold;">
                            {{ One_Event.Personnel }}
                          </span>
                          今天去 {{ One_Event.Where }} 出差了，
                          <span v-if="One_Event.LeaveTimeS">
                            他是在 {{ ToHHMMSS(One_Event.LeaveTimeS) }} 出发的，
                          </span>
                          <span v-if="One_Event.LeaveTimeE">
                            他能在 {{ ToHHMMSS(One_Event.LeaveTimeE) }} 回来。
                          </span>
                        </span>
                      </div>

                    </el-card>
                  </div>

                </div>
              </el-card>                
            </div>

          </el-col>

          <el-col :span="16">
            <el-card class="NoticeInfo">
              <div slot="header" > <span>当月事件</span> </div>
                <div class="Event">

                    <el-card 
                      v-for="(One_Event, One_Event_Number) in Event_Collection" 
                      :key="One_Event_Number"
                      class="EventCard"
                    >

                      <div slot="header" style="display: flex; justify-content: space-between;align-items: center;">
                        <span>{{ One_Event.Personnel }}</span>
                        <span style="font-size: 12px;">
                          {{ ToYYYYMMDD(One_Event.LeaveTimeS) + ' ' + ToHHMMSS(One_Event.LeaveTimeS) }} ~ 
                          {{ ToYYYYMMDD(One_Event.LeaveTimeE) + ' ' + ToHHMMSS(One_Event.LeaveTimeE) }}
                        </span>
                        <span>{{ getStatus(One_Event_Number) }}</span>
                      </div>

                      <div>
                        <ul style="margin: 10px 0px 10px 0px;">
                          <li>
                            <span> {{ One_Event.event }}</span>
                            <span v-if="One_Event.event === '请假'"> 
                              ({{ One_Event.Occur_Leave.nature }})
                            </span>
                            <span style="float: right ; padding-right: 20px;"> 
                              {{ TimeResolved(One_Event)}}
                            </span>
                          </li>
                          <ul style="margin-top: 10px;">
                            <li v-if="One_Event.event === '出差'"><span> 地点：{{ One_Event.Occur_Business.Where }} </span></li>
                            <li v-if="One_Event.event === '请假'"><span> 原因：{{ One_Event.Occur_Leave.Why }} </span></li>
                            <li v-if="One_Event.event === '加班'"><span> 地点：{{ One_Event.Occur_Overtime.Where }} </span></li>
                            <li v-if="One_Event.event === '加班'"><span> 原因：{{ One_Event.Occur_Overtime.Why }} </span></li>
                          </ul>
                        </ul>
                      </div>

                      <el-divider class="card-footer-divider"/>

                      <div class="card-footer">
                        <span> 意见 </span>
                          <div class="Opinions">
                            <el-avatar :size="30" > 顾 </el-avatar>
                            <span style="padding-left: 10px;"> 
                              {{ One_Event.Report.BossOpinions }} 
                            </span>
                          </div>
                          <div v-if="haveManager(One_Event.Personnel)" class="Opinions">
                            <el-avatar :size="30"> 
                              {{haveManager(One_Event.Personnel)}} 
                            </el-avatar>
                            <span style="padding-left: 10px;"> 
                              {{ One_Event.Report.ManagerOpinions }} 
                            </span>
                          </div>
                      </div>

                    </el-card>
                  
                </div>
            </el-card>        
          </el-col>
        </el-row>
      </el-tab-pane>

      <el-tab-pane label="日历汇总" name="second">
        <div class="Table_Head">

          <div class="Table_Head_Left">
            <div>
              <el-date-picker
                v-model="MonthChoose"
                type="month"
                placeholder="选择月"
                size="mini"
                :clearable="false"
                style="margin: 0px 10px 0px 10px;"
                :picker-options="pickerOptions"
                @change="fetchData()"
              />
            </div>
            <el-radio-group 
              @change="fetchData()"
              v-model="DepartmentChoose" 
              size="mini"
            >
              <el-radio-button label="全部"></el-radio-button>
              <el-radio-button label="技术部"></el-radio-button>
            </el-radio-group>

          </div>

          <div class="Table_Head_Middle">
            <div class="Legend_Business" />
            <div style="margin: 0px 15px 0px 5px;"> 出差 </div>
            <div class="Legend_Leave" />
            <div style="margin: 0px 15px 0px 5px;"> 请假 </div>
            <div class="Legend_Overtime" />
            <div style="margin: 0px 15px 0px 5px;"> 加班 </div>
          </div>

          <div class="Table_Head_Right">
            <el-button 
              size="small" 
              type="primary"
              @click="ExcelDownload"
            >
              导出成Excel
            </el-button>
          </div>

        </div>

        <el-table
          :data="Calendar_Collection_Table"
          height="800"
          border
          class="Table"
          :header-cell-style="{
            height: '15mm' ,
            'padding-bottom': '0px',
          }"
          style="width: 100%"
        >
          <el-table-column 
            prop="Name" 
            label="姓名"
            width="140"
            fixed
          ></el-table-column>

          <el-table-column 
            v-for="date in MonthDates" 
            :key="date.Date" 
            BackgroundColorJudgment
            :label-class-name ="TableDate_Remind_CSS(date.Date)"
            :class-name="TableDate_Remind_CSS(date.Date)"
            width="70"
          >
            <template slot="header" slot-scope="scope">
              <div style="display: flex; justify-content: flex-start; align-items: baseline;">
                <div style="font-size: 20px;">
                  {{ date.Date.slice(-2) }}
                </div>
                <div style="font-size: 10px; padding-left: 5px;">
                  {{ TableDate_Remind(date.Date) }}
                </div>
              </div>
              <div style="font-size: 20px; 
                          opacity: 0.15; 
                          padding-top: 12px; 
                          display: flex; 
                          flex-direction: row; 
                          justify-content: flex-end;">
                {{ TableDate_Week(date.Date) }}
              </div>
            </template>

            <template slot-scope="scope">
              <div>

                <div v-if="hasBusiness(scope.row, date.Date)" >
                  <el-tooltip 
                    effect="dark" 
                    placement="top"
                  >
                    <div slot="content">
                      {{getContent_Business_When(scope.row, date.Date)}}<br/>
                      {{getContent_Business_Where(scope.row, date.Date)}}
                    </div>   
                    <div v-if="getContent_Business_Time(scope.row, date.Date) === '起始天'" class="Business_Start" ><div class="EventBar"/></div>
                    <div v-if="getContent_Business_Time(scope.row, date.Date) === '结束天'" class="Business_End" ><div class="EventBar"/></div>
                    <div v-if="getContent_Business_Time(scope.row, date.Date) === '进行中'" class="Business_Between" ><div class="EventBar"/></div>
                    <div v-if="getContent_Business_Time(scope.row, date.Date) === '起始结束同一天'" class="Business_StartEnd" ><div class="EventBar"/></div>
                  </el-tooltip>
                </div>

                <div v-if="!hasBusiness(scope.row, date.Date)" class="Business_None">
                  <div class="EventBar"/>
                </div>



                <div v-if="hasLeave(scope.row, date.Date)" >
                  <el-tooltip 
                    effect="dark" 
                    placement="top"
                  >
                    <div slot="content">
                      {{getContent_Leave_When(scope.row, date.Date)}}<br/>
                      {{getContent_Leave_nature(scope.row, date.Date)}}<br/>
                      {{getContent_Leave_Why(scope.row, date.Date)}}
                    </div>                     
                    <div v-if="getContent_Leave_Time(scope.row, date.Date) === '起始天'" class="Leave_Start" ><div class="EventBar"/></div>
                    <div v-if="getContent_Leave_Time(scope.row, date.Date) === '结束天'" class="Leave_End" ><div class="EventBar"/></div>
                    <div v-if="getContent_Leave_Time(scope.row, date.Date) === '进行中'" class="Leave_Between" ><div class="EventBar"/></div>
                    <div v-if="getContent_Leave_Time(scope.row, date.Date) === '起始结束同一天'" class="Leave_StartEnd" ><div class="EventBar"/></div>
                  </el-tooltip>
                </div>

                <div v-if="!hasLeave(scope.row, date.Date)" class="Business_None">
                  <div class="EventBar"/>
                </div>



                <div v-if="hasOvertime(scope.row, date.Date)" >
                  <el-tooltip 
                    effect="dark" 
                    placement="top"
                  >
                    <div slot="content">
                      {{getContent_Overtime_When(scope.row, date.Date)}}<br/>
                      {{getContent_Overtime_Where(scope.row, date.Date)}}<br/>
                      {{getContent_Overtime_Why(scope.row, date.Date)}}
                    </div>                     
                    <div v-if="getContent_Overtime_Time(scope.row, date.Date) === '起始天'" class="Overtime_Start" ><div class="EventBar"/></div>
                    <div v-if="getContent_Overtime_Time(scope.row, date.Date) === '结束天'" class="Overtime_End" ><div class="EventBar"/></div>
                    <div v-if="getContent_Overtime_Time(scope.row, date.Date) === '进行中'" class="Overtime_Between" ><div class="EventBar"/></div>
                    <div v-if="getContent_Overtime_Time(scope.row, date.Date) === '起始结束同一天'" class="Overtime_StartEnd" ><div class="EventBar"/></div>
                  </el-tooltip>
                </div>

                <div v-if="!hasOvertime(scope.row, date.Date)" class="Business_None">
                  <div class="EventBar"/>
                </div>

              </div>
            </template>
          </el-table-column>

        </el-table>
      </el-tab-pane>
      
        <el-dialog title="日期操作" :visible.sync="CalendaringOperation">
          <el-form label-position = "left" label-width="100px">
            <el-form-item label="选择日期">
              <el-date-picker
                type="dates"
                v-model="CalendaringOperationdata.Date"
                placeholder="选择一个或多个日期"
                value-format="timestamp">
              </el-date-picker>
            </el-form-item>
    
            <el-form-item label="具体变化">
              <el-select 
                v-model="CalendaringOperationdata.Isholiday" 
                placeholder="要怎么变"
                style="margin-right: 30px;"
              >
                <el-option
                  v-for="item in DateOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
    
              <el-select 
                v-if="CalendaringOperationdata.Isholiday === 'true' " 
                v-model="CalendaringOperationdata.HolidayAttribute" 
                placeholder="因为什么"
              >
                <el-option
                  v-for="item in DateOptions_Holiday"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
    
              <el-select 
                v-if="CalendaringOperationdata.Isholiday === 'false' " 
                v-model="CalendaringOperationdata.HolidayAttribute" 
                placeholder="因为什么"
              >
                <el-option
                  v-for="item in DateOptions_Work"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
    
            <el-button @click="UpdateCalendaringHolidays"> 确定 </el-button>
            <el-button @click="ReturnCalendaringOperation"> 取消 </el-button>
          </el-form>
        </el-dialog>
    
    </el-tabs>

</template>


<script>
import { getUserList } from "@/api/user";
import { 
  GetTemporaryOccur,
  GetCalendarScheme,
  UpdateCalendaringHolidays,
} from "@/api/reporting";
const ww = require("chinese-workday");
const isHoliday = ww.isHoliday;                                          //判断是不是节假日
const isAddtionalWorkday = ww.isAddtionalWorkday;                        //判断是不是工作日
const getFestival_OnlyHOLIDAYS = ww.getFestival_OnlyHOLIDAYS;            //输出是什么节假日
export default {
  name : "WorkAttendance",
  data() {
    return {
      firstRowStyles: [], // 用于保存首行样式的数组

      Event_Collection: [],
      Calendar_Collection_All: [],            //前后一年
      Calendar_Collection_NowMouth: [],       //当月
      Calendar_Collection_Today: [],
      Calendar_Collection_Table: [],
      // {
      //   Name : null,
      //   Business_Date : [{
      //     Date : null,
      //     Where: null,                      //去哪
      //     isAllDay: false,                  //是否是全天
      //     LeaveTimeS: null,                 //如果不是全天，则设置开始时间
      //     LeaveTimeE: null,                 //如果不是全天，则设置结束时间
      //   }],
      //   Leave_Date : [{
      //     Date : null,
      //     nature: null,                     //性质，事假病假年假
      //     Why: null,                        //原因
      //     isAllDay: false,                  //是否是全天
      //     LeaveTimeS: null,                 //如果不是全天，则设置开始时间
      //     LeaveTimeE: null,                 //如果不是全天，则设置结束时间
      //   }],
      // }

      EventListQuery_filters: {filters: {}},
      CalendarListQuery_filters: {filters: {}},

      Userlist: [],                        // 公司里的人物数组
      activeName: 'first',

      autoWidth: true,
      bookType: "xlsx",
      
      MonthChoose: null,
      DepartmentChoose: "全部",

      MonthDates: [],
      pickerOptions: {
        disabledDate(time) {
          const currentDate = new Date();
          const currentYear = currentDate.getFullYear();
          const currentMonth = currentDate.getMonth();
          const threeMonthsLater = new Date(currentYear, currentMonth + 3, 0);
          return time.getTime() > threeMonthsLater.getTime();
        },
      },
      listLoading: true,
      
      
      CalendaringOperation: false,
      CalendaringOperationdata:{
        Date:[],
        Isholiday: null,
        HolidayAttribute: null,
      },
      DateOptions: [
        {value: 'true',label: '工作日转假期'}, 
        {value: 'false',label: '假期变工作日'}, 
      ],
      DateOptions_Work: [
        {value: '补春节',   label: '补班-春节'}, 
        {value: '补清明节', label: '补班-清明节'}, 
        {value: '补劳动节', label: '补班-劳动节'}, 
        {value: '补端午节', label: '补班-端午节'}, 
        {value: '补中秋节', label: '补班-中秋节'}, 
        {value: '补国庆节', label: '补班-国庆节'}, 
        {value: '公司安排', label: '公司安排'}, 
        {value: '工作日', label: '普通工作日'}, 
      ],
      DateOptions_Holiday: [
        {value: '春节',   label: '春节'}, 
        {value: '清明节', label: '清明节'}, 
        {value: '劳动节', label: '劳动节'}, 
        {value: '端午节', label: '端午节'}, 
        {value: '中秋节', label: '中秋节'}, 
        {value: '国庆节', label: '国庆节'}, 
        {value: '公司安排', label: '公司安排'}, 
        {value: '周末', label: '普通周末'}, 
      ],

    }
  },
  created() {
    this.getUser();

    this.SetListQuery();
  },

  methods: {
    ToHHMMSS(timestamp) {                             //时间戳转时分秒
      const date = new Date(timestamp);
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    },
    ToYYYYMMDD(timestamp) {                           //时间戳转年月日
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    ToYYYYMM(timestamp) {                           //时间戳转年月
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      return `${year}-${month}`;
    },
    isDateHoliday(date) {
      return isHoliday(date); // 调用isHoliday函数并返回结果
    },

    getJobNumberByUsername(username) {
      const user = this.Userlist.find((user) => user.username === username);
      if (user) {
        return user.JobNumber;
      }
    }, //得知名字查找工号

    componentStyle(One_Event) {
      if (One_Event.Why && One_Event.Where) {
        return 'lightgray';
      } else if (One_Event.Why) {
        return 'lightgreen';
      } else if (One_Event.Where) {
        return 'lightblue';
      }
    },

    async getUser() {
      const { data } = await getUserList();
      this.Userlist = data.list.filter(item => item.company === null || item.company === undefined);
      const Admin = {
        username : "管理员测试",
        token : "Admin-token",
        manager : "张锐",
      }
      this.Userlist.push(Admin);
    },
    getUserManager(name) {         
      const Name = name;
      const userinfo = this.findUserByUsername(this.Userlist, Name);
      if ( userinfo ) {
        if ( userinfo.hasOwnProperty('manager') ) {
          return userinfo.manager.substr(0, 2);;
        }
      }
    },
    findUserByUsername(userList, username) {
      return userList.find(user => user.username === username);
    },

    haveManager(name) {
      let userinfo = this.findUserByUsername(this.Userlist, name)
      if (userinfo.hasOwnProperty('manager')) {
        return userinfo.manager;
      }
    },

    getStatus(One_Event_Number) {                          //当前时间与报备时间对比与显示
      const NowTime = new Date().getTime();
      if (NowTime < this.Event_Collection[One_Event_Number].LeaveTimeS) {
        return '计划中';
      } else if (NowTime < this.Event_Collection[One_Event_Number].LeaveTimeE) {
        return '正在进行';
      } else if (NowTime > this.Event_Collection[One_Event_Number].LeaveTimeE) {
        return '已结束';
      }
    },
    TimeResolved(One_Event) {
      if (One_Event.event === '加班') {
        const regex = /(\d+(\.\d+)?)/;
        const match = One_Event.Occur_Overtime.TimeInterval? One_Event.Occur_Overtime.TimeInterval.match(regex) : false;
        if (match) {
          const number = match[0];
          const suffix = One_Event.Occur_Overtime.TimeInterval.slice(-1); // 提取字符串的最后一个字符作为后缀
          if (suffix === 'h') {
            return number + " 小时";
          } else if (suffix === 'd') {
            return number + " 天";
          }
        }
      } else if (One_Event.event === '出差') {
        const regex = /(\d+(\.\d+)?)[dh]_(\d+(\.\d+)?)/;
        const match = One_Event.Occur_Business.TimeInterval?  One_Event.Occur_Business.TimeInterval.match(regex) : false;
        if (match) {
          const number1 = match[1];
          const number2 = match[3];
          return number1 + " 天（" + number2 + "天节假日）";
        }
      } else if (One_Event.event === '请假') {
        const regex = /(\d+(\.\d+)?)/;
        const match = One_Event.Occur_Leave.TimeInterval? One_Event.Occur_Leave.TimeInterval.match(regex) :false;
        if (match) {
          const number = match[0];
          return number + " 小时";
        }
      }
    },

    SetListQuery() {                                 //通知信息里筛选出事件卡片,日历中筛选出事件
      let currentDate = new Date();
      let firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      this.MonthChoose = firstDayOfMonth;
      firstDayOfMonth.setHours(0, 0, 0, 0);
      let firstDayTimestamp = firstDayOfMonth.getTime();
      let nextMonth = currentDate.getMonth() + 1;
      let firstDayOfNextMonth = new Date(currentDate.getFullYear(), nextMonth, 1);
      firstDayOfNextMonth.setHours(0, 0, 0, 0);
      let lastDayTimestamp = firstDayOfNextMonth.getTime() - 1;

      this.EventListQuery_filters.filters = "({$and:[{'LeaveTimeE':{$gte:" + firstDayTimestamp + "}},{'LeaveTimeS':{$lt:" + lastDayTimestamp + "}}]})";
    
      // 筛选条件:LeaveTimeE设定为大于这个月一号但是LeaveTimeS小于等于这个月最后一天
      
      currentDate.setHours(0, 0, 0, 0); // 将时、分、秒、毫秒部分设置为零
      const currentYear = currentDate.getFullYear();
      const lastYearDate = new Date(currentYear - 1, currentDate.getMonth(), currentDate.getDate());
      const nextYearDate = new Date(currentYear + 1, currentDate.getMonth(), currentDate.getDate());
      const lastYearTimestamp = lastYearDate.getTime();
      const nextYearTimestamp = nextYearDate.getTime();

      this.CalendarListQuery_filters.filters  = "({$and:["
      this.CalendarListQuery_filters.filters += "{'Date':{$gte:" + lastYearTimestamp + "}}";
      this.CalendarListQuery_filters.filters += ",{'Date':{$lt:"  + nextYearTimestamp + "}}";
      this.CalendarListQuery_filters.filters += "]})";

      this.fetchData();
    },

    TableDate_Remind(date) {
      const currentDate = new Date();
      if ( this.ToYYYYMMDD(currentDate) === date ) {
        return "今"
      } else if (isAddtionalWorkday(date)) {
        return "班";
      } else if (isHoliday(date)) {
        return "休";
      } else {
        return ""
      }
    },
    TableDate_Remind_CSS(date) {
      if (this.TableDate_Remind(date).includes('今')) {
        return 'WorkAttendance_Today';
      } else if (this.TableDate_Remind(date).includes('休')) {
        return 'WorkAttendance_Hoilday';
      } else {
        return 'WorkAttendance_Workday';
      }
    },
    TableDate_Arrange_CSS({row,column,rowIndex,columnIndex}) {

    },
    TableDate_Week(date) {
      if (getFestival_OnlyHOLIDAYS(date)) {
        return getFestival_OnlyHOLIDAYS(date);
      } else {
        const weekdays = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
        const dateObj = new Date(date);
        const dayOfWeek = dateObj.getDay();
        return weekdays[dayOfWeek];
      }
    },

    async fetchData() {                                    //刷新显示
      this.listLoading = true;
      this.Event_Collection = [];
      this.Calendar_Collection_All = [];
      this.Calendar_Collection_Today = [];

      const { EventData } = await GetTemporaryOccur(this.EventListQuery_filters);
      for (var i = 0; i < EventData.length; i++) {
        let EventObj = {
          _id: null,                 //事件id
          Personnel: null,           //人员
          event: null,               //性质（出差或请假）
          Occur_Leave: {             //请假
            nature: null,             //性质，事假病假年假
            Why: null,                //原因
          },
          Occur_Business: {          //出差
            Where: null,              //去哪
            BindProject: null,        //绑定项目
          },
          LeaveTimeS: null,          //开始时间
          LeaveTimeE: null,          //结束时间
          ShouldReport: false,       //是否上报
          Report: {
            BossOpinions: null,      //顾总意见
            ManagerOpinions: null,   //项目经理意见
            Passed: false,           //是否同意
          },
        };
        this.Event_Collection.push(EventObj);
        Object.assign(this.Event_Collection[i], EventData[i]);
      };

      const { CalendarData } = await GetCalendarScheme(this.CalendarListQuery_filters);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const currentTimestamp = currentDate.getTime();
      for (var i = 0; i < CalendarData.length; i++) {
        let CalendarObj = {                 //日期结构
          _id: null,                          //事件id
          Date: null,                         //日期
          Isholiday: false,                   //是否是节假日
          Occur_Business: [{                  //当天出差数据
            Personnel: null,                  //人员
            Where: null,                      //去哪
            BindProject: null,                //绑定项目
            isAllDay: false,                  //是否是全天
            LeaveTimeS: null,                 //如果不是全天，则设置开始时间
            LeaveTimeE: null,                 //如果不是全天，则设置结束时间
            Report: {
              isReport: false,                //是否需要上报
              BossOpinions: null,             //顾总意见
              ManagerOpinions: null,          //项目经理意见
              Passed: false,                  //是否同意
            },
          }],
          Occur_Leave: [{                      //当天请假数据
            Personnel: null,                  //人员
            nature: null,                     //性质，事假病假年假
            Why: null,                        //原因
            isAllDay: false,                  //是否是全天
            LeaveTimeS: null,                 //如果不是全天，则设置开始时间
            LeaveTimeE: null,                 //如果不是全天，则设置结束时间
            Report: {
              isReport: false,                //是否需要上报
              BossOpinions: null,             //顾总意见
              ManagerOpinions: null,          //项目经理意见
              Passed: false,                  //是否同意
            },
          }],
          Occur_Overtime: [{                   //当天加班数据
            Personnel: null,                  //人员
            Where: null,                      //哪里加班
            Why: null,                        //原因
            LeaveTimeS: null,                 //则设置开始时间
            LeaveTimeE: null,                 //则设置结束时间
            Report: {
              isReport: false,                //是否需要上报
              BossOpinions: null,            //顾总意见
              ManagerOpinions: null,         //项目经理意见
              Passed: false,                  //是否同意
            },
          }],
        };
        this.Calendar_Collection_All.push(CalendarObj);
        Object.assign(this.Calendar_Collection_All[i], CalendarData[i]);

        if (CalendarData[i].Date === currentTimestamp) {
          this.Calendar_Collection_Today.push(...CalendarData[i].Occur_Business);
          this.Calendar_Collection_Today.push(...CalendarData[i].Occur_Leave);
          this.Calendar_Collection_Today.push(...CalendarData[i].Occur_Overtime);
        }

      };

      this.GetCalendarCollectionNowMouth();

      setTimeout(() => {
        this.listLoading = false;
      }, 0.5 * 1000);
    },

    GetCalendarCollectionNowMouth() {
      const startDate = new Date(this.MonthChoose);
      const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
      const dates = [];
      this.MonthDates = [];
      this.Calendar_Collection_NowMouth = [];
      const currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        const formattedDate = {
          Date : this.ToYYYYMMDD(currentDate)
        };
        dates.push(formattedDate);
        currentDate.setDate(currentDate.getDate() + 1);
      }

      for (const item of this.Calendar_Collection_All) {
        const itemDate = this.ToYYYYMMDD(item.Date); // 获取 item 的日期字符串
        const matchingDate = dates.find(date => date.Date === itemDate); // 在 dates 数组中查找与 itemDate 匹配的对象
        if (matchingDate) { this.Calendar_Collection_NowMouth.push(item); }
      }
      this.MonthDates = dates;
      this.createCalendarCollectionTable();

    },

    createCalendarCollectionTable() {
      this.Calendar_Collection_Table = [];
      this.Userlist.forEach((user) => {
        if (this.DepartmentChoose === '技术部') {
          if (user.token_Sigmatek === 'technology') {
            const userEntry = {
              Name: user.username,
              JobNumber : user.JobNumber,
              MonthDates: [],
            };
            // 将与用户关联的日期添加到 userEntry.MonthDates
            this.MonthDates.forEach((date) => {
              userEntry.MonthDates.push(Object.assign({}, date)); // 使用 Object.assign 复制日期对象
            });
            this.Calendar_Collection_Table.push(userEntry);
          }
        } else {
          const userEntry = {
            Name: user.username,
            JobNumber : user.JobNumber,
            MonthDates: [],
          };
          // 将与用户关联的日期添加到 userEntry.MonthDates
          this.MonthDates.forEach((date) => {
            userEntry.MonthDates.push(Object.assign({}, date)); // 使用 Object.assign 复制日期对象
          });
          this.Calendar_Collection_Table.push(userEntry);
        }
      });


      this.Calendar_Collection_NowMouth.forEach((calendarEntry) => {

        calendarEntry.Occur_Business.forEach((businessEntry) => {
          this.Calendar_Collection_Table.forEach((TableItem) => {
            if (TableItem.Name === businessEntry.Personnel) {
              TableItem.MonthDates.forEach((MonthDatesEntry) => {
                if (MonthDatesEntry.Date === this.ToYYYYMMDD(calendarEntry.Date)) {
                  const { Where, isAllDay, LeaveTimeS, LeaveTimeE, DailyTimeStatistics } = businessEntry;
                  MonthDatesEntry.Occur_Business = [];
                  MonthDatesEntry.Occur_Business.push({ Where, isAllDay, LeaveTimeS, LeaveTimeE, DailyTimeStatistics });
                }
              })
            }
          })
        })

        calendarEntry.Occur_Leave.forEach((leaveEntry) => {
          for (const TableItem of this.Calendar_Collection_Table) {
            if (TableItem.Name === leaveEntry.Personnel) {
              TableItem.MonthDates.forEach((MonthDatesEntry) => {
                if (MonthDatesEntry.Date === this.ToYYYYMMDD(calendarEntry.Date)) {
                  const { nature, Why, isAllDay, LeaveTimeS, LeaveTimeE, DailyTimeStatistics } = leaveEntry;
                  MonthDatesEntry.Occur_Leave = [];
                  MonthDatesEntry.Occur_Leave.push({ nature, Why, isAllDay, LeaveTimeS, LeaveTimeE, DailyTimeStatistics });
                }
              })
            }
          }
        })

        calendarEntry.Occur_Overtime.forEach((OvertimeEntry) => {
          for (const TableItem of this.Calendar_Collection_Table) {
            if (TableItem.Name === OvertimeEntry.Personnel) {
              TableItem.MonthDates.forEach((MonthDatesEntry) => {
                if (MonthDatesEntry.Date === this.ToYYYYMMDD(calendarEntry.Date)) {
                  const { Where, Why, isAllDay, LeaveTimeS, LeaveTimeE, DailyTimeStatistics } = OvertimeEntry;
                  MonthDatesEntry.Occur_Overtime = [];
                  MonthDatesEntry.Occur_Overtime.push({ Where, Why, isAllDay, LeaveTimeS, LeaveTimeE, DailyTimeStatistics });
                }
              })
            }
          }
        })

      });

      this.Calendar_Collection_Table.sort((a, b) => a.JobNumber - b.JobNumber); //表格数据按照工号从小到大排序

      return this.Calendar_Collection_Table;
    },

    getContent_Business_When(row, date) {
      const dateObj = row.MonthDates.find(item => item.Date === date);
      if ( dateObj.Occur_Business[0].LeaveTimeS && 
           dateObj.Occur_Business[0].LeaveTimeE ) 
      {
        return (
          "出差时间：" + this.ToHHMMSS(dateObj.Occur_Business[0].LeaveTimeS) + "~" +  this.ToHHMMSS(dateObj.Occur_Business[0].LeaveTimeE)
        );
      } else if (dateObj.Occur_Business[0].LeaveTimeS) {
        return "出差时间：" + this.ToHHMMSS(dateObj.Occur_Business[0].LeaveTimeS) + "~ 当日结束";
      } else if (dateObj.Occur_Business[0].LeaveTimeE) {
        return "出差时间：当日开始 ~ " + this.ToHHMMSS(dateObj.Occur_Business[0].LeaveTimeE);
      } else if (dateObj.Occur_Business[0].isAllDay) {
        return "出差时间：一整天 ";
      }
    },
    getContent_Business_Where(row, date) {
      const dateObj = row.MonthDates.find(item => item.Date === date);
      if (dateObj && dateObj.Occur_Business && dateObj.Occur_Business.length > 0) {
        return "出差地点：" + dateObj.Occur_Business[0].Where;
      }
      return '';
    },
    getContent_Leave_When(row, date) {
      const dateObj = row.MonthDates.find(item => item.Date === date);
      if ( dateObj.Occur_Leave[0].LeaveTimeS && 
           dateObj.Occur_Leave[0].LeaveTimeE ) 
      {
        return (
          "请假时间：" + this.ToHHMMSS(dateObj.Occur_Leave[0].LeaveTimeS) + "~" +  this.ToHHMMSS(dateObj.Occur_Leave[0].LeaveTimeE)
        );
      } else if (dateObj.Occur_Leave[0].LeaveTimeS) {
        return "请假时间：" + this.ToHHMMSS(dateObj.Occur_Leave[0].LeaveTimeS) + "~ 当日结束";
      } else if (dateObj.Occur_Leave[0].LeaveTimeE) {
        return "请假时间：当日开始 ~ " + this.ToHHMMSS(dateObj.Occur_Leave[0].LeaveTimeE);
      } else if (dateObj.Occur_Leave[0].isAllDay) {
        return "请假时间：一整天 ";
      }
    },
    getContent_Leave_nature(row, date) {
      const dateObj = row.MonthDates.find(item => item.Date === date);
      if (dateObj && dateObj.Occur_Leave && dateObj.Occur_Leave.length > 0) {
        return "请假类型：" + dateObj.Occur_Leave[0].nature;
      }
      return '';
    },
    getContent_Leave_Why(row, date) {
      const dateObj = row.MonthDates.find(item => item.Date === date);
      if (dateObj && dateObj.Occur_Leave && dateObj.Occur_Leave.length > 0) {
        return "请假原因：" + dateObj.Occur_Leave[0].Why;
      }
      return '';
    },
    getContent_Overtime_When(row, date) {
      const dateObj = row.MonthDates.find(item => item.Date === date);
      if ( dateObj.Occur_Overtime[0].LeaveTimeS && 
           dateObj.Occur_Overtime[0].LeaveTimeE ) 
      {
        return (
          "加班时间：" + this.ToHHMMSS(dateObj.Occur_Overtime[0].LeaveTimeS) + "~" +  this.ToHHMMSS(dateObj.Occur_Overtime[0].LeaveTimeE)
        );
      } else if (dateObj.Occur_Overtime[0].LeaveTimeS) {
        return "加班时间：" + this.ToHHMMSS(dateObj.Occur_Overtime[0].LeaveTimeS) + "~ 当日结束";
      } else if (dateObj.Occur_Overtime[0].LeaveTimeE) {
        return "加班时间：当日开始 ~ " + this.ToHHMMSS(dateObj.Occur_Overtime[0].LeaveTimeE);
      } else if (dateObj.Occur_Overtime[0].isAllDay) {
        return "加班时间：一整天 ";
      }
    },    
    getContent_Overtime_Where(row, date) {
      const dateObj = row.MonthDates.find(item => item.Date === date);
      if (dateObj && dateObj.Occur_Overtime && dateObj.Occur_Overtime.length > 0) {
        return "加班地点：" + dateObj.Occur_Overtime[0].Where
      }
      return '';
    },
    getContent_Overtime_Why(row, date) {
      const dateObj = row.MonthDates.find(item => item.Date === date);
      if (dateObj && dateObj.Occur_Overtime && dateObj.Occur_Overtime.length > 0) {
        return "加班原因：" + dateObj.Occur_Overtime[0].Why;
      }
      return '';
    },


    getContent_Business_Time(row, date) {
      const dateObj = row.MonthDates.find(item => item.Date === date);
      if (dateObj.hasOwnProperty('Occur_Business')) {
        if ( (dateObj.Occur_Business[0].LeaveTimeS > 0) && (dateObj.Occur_Business[0].LeaveTimeE === null) ) {
          return "起始天";
        }
        if ( (dateObj.Occur_Business[0].LeaveTimeE > 0) && (dateObj.Occur_Business[0].LeaveTimeS === null) ) {
          return "结束天";
        }
        if ( dateObj.Occur_Business[0].isAllDay === true ) {
          return "进行中";
        }
        if ( (dateObj.Occur_Business[0].LeaveTimeS > 0) && (dateObj.Occur_Business[0].LeaveTimeE  > 0) ) {
          return "起始结束同一天";
        }
      }
      return '';
    },
    getContent_Leave_Time(row, date) {
      const dateObj = row.MonthDates.find(item => item.Date === date);
      if (dateObj.hasOwnProperty('Occur_Leave')) {
        if ( (dateObj.Occur_Leave[0].LeaveTimeS > 0) && (dateObj.Occur_Leave[0].LeaveTimeE === null) ) {
          return "起始天";
        }
        if ( (dateObj.Occur_Leave[0].LeaveTimeE > 0) && (dateObj.Occur_Leave[0].LeaveTimeS === null) ) {
          return "结束天";
        }
        if ( dateObj.Occur_Leave[0].isAllDay === true ) {
          return "进行中";
        }
        if ( (dateObj.Occur_Leave[0].LeaveTimeS > 0) && (dateObj.Occur_Leave[0].LeaveTimeE  > 0) ) {
          return "起始结束同一天";
        }
      }
    },
    getContent_Overtime_Time(row, date) {
      const dateObj = row.MonthDates.find(item => item.Date === date);
      if (dateObj.hasOwnProperty('Occur_Overtime')) {
        if ( (dateObj.Occur_Overtime[0].LeaveTimeS > 0) && (dateObj.Occur_Overtime[0].LeaveTimeE === null) ) {
          return "起始天";
        }
        if ( (dateObj.Occur_Overtime[0].LeaveTimeE > 0) && (dateObj.Occur_Overtime[0].LeaveTimeS === null) ) {
          return "结束天";
        }
        if ( dateObj.Occur_Overtime[0].isAllDay === true ) {
          return "进行中";
        }
        if ( (dateObj.Occur_Overtime[0].LeaveTimeS > 0) && (dateObj.Occur_Overtime[0].LeaveTimeE  > 0) ) {
          return "起始结束同一天";
        }
      }
    },


    hasBusiness(user, date) {
      if (user.MonthDates) {
        const dateObj = user.MonthDates.find(item => item.Date === date);
        if (dateObj.hasOwnProperty('Occur_Business')) {
          return dateObj.Occur_Business[0];
        }
      }
      return false; // 如果属性不存在，返回默认值
    },
    hasLeave(user, date) {
      if (user.MonthDates) {
        const dateObj = user.MonthDates.find(item => item.Date === date);
        if (dateObj.hasOwnProperty('Occur_Leave')) {
          return dateObj.Occur_Leave[0];
        }
      }
      return false; // 如果属性不存在，返回默认值
    },
    hasOvertime(user, date) {
      if (user.MonthDates) {
        const dateObj = user.MonthDates.find(item => item.Date === date);
        if (dateObj.hasOwnProperty('Occur_Overtime')) {
          return dateObj.Occur_Overtime[0];
        }
      }
      return false; // 如果属性不存在，返回默认值
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map((v, index) =>
        filterVal.map((j) => {
          if (j === "id") {
            return index + 1;
          } else if (j.toString().indexOf(".") != -1) {
            let a = j.split(".");
            if (a[0] == "componentsInfomation" || a[0] == "Comment") {
              var string = "";
              var num = 13;
              for (var i = 0; i < v[a[0]].length; i++) {
                if (v[a[0]][i][a[1]] !== null) {
                  string += v[a[0]][i][a[1]];
                  string += ",";
                }
              }
              return string.substring(0, string.length - 1);
            } else {
              return v[a[0]][a[1]];
            }
          } else {
            return v[j];
          }
        })
      );
    }, //导出excel用

    ExcelDownload() {
      this.downloadLoading = true;
      // this.WatchPrintWrite();
      this.ExcelList = [];

      for (
        var i = 0;
        i < this.Calendar_Collection_Table.length;
        i++
      ) {
        for (
          var j = 0;
          j < this.Calendar_Collection_Table[i].MonthDates.length;
          j++
        ) {
          if ( this.Calendar_Collection_Table[i].MonthDates[j].hasOwnProperty('Occur_Business') || 
               this.Calendar_Collection_Table[i].MonthDates[j].hasOwnProperty('Occur_Leave') ||
               this.Calendar_Collection_Table[i].MonthDates[j].hasOwnProperty('Occur_Overtime') ) 
            {
            //   console.log(this.Calendar_Collection_Table[i].MonthDates[j]);
              if ( this.Calendar_Collection_Table[i].MonthDates[j].hasOwnProperty('Occur_Business') ) {
                let obj = {
                  JobNumber: null,
                  username: null,
                  Date: null,
                  event: null,
                  EventTimeoftheday: null,
                  StartingTimeoftheday: null,
                  EndTimeoftheday: null,
                  Comment: null,
                };
                obj.username = this.Calendar_Collection_Table[i].Name;
                obj.JobNumber = this.getJobNumberByUsername(this.Calendar_Collection_Table[i].Name);
                obj.Date = this.Calendar_Collection_Table[i].MonthDates[j].Date;
                obj.event = "出差";
                obj.EventTimeoftheday = this.Calendar_Collection_Table[i].MonthDates[j].Occur_Business[0].DailyTimeStatistics;
                obj.StartingTimeoftheday = 
                  this.Calendar_Collection_Table[i].MonthDates[j].Occur_Business[0].LeaveTimeS ?
                  this.ToHHMMSS(this.Calendar_Collection_Table[i].MonthDates[j].Occur_Business[0].LeaveTimeS) : '';
                obj.EndTimeoftheday = 
                  this.Calendar_Collection_Table[i].MonthDates[j].Occur_Business[0].LeaveTimeE ?
                  this.ToHHMMSS(this.Calendar_Collection_Table[i].MonthDates[j].Occur_Business[0].LeaveTimeE) : '';
                obj.Comment = this.Calendar_Collection_Table[i].MonthDates[j].Occur_Business[0].Where;
                this.ExcelList.push(obj);
                // console.log(obj)
              }

              if ( this.Calendar_Collection_Table[i].MonthDates[j].hasOwnProperty('Occur_Leave') ) {
                let obj = {
                  JobNumber: null,
                  username: null,
                  Date: null,
                  event: null,
                  EventTimeoftheday: null,
                  StartingTimeoftheday: null,
                  EndTimeoftheday: null,
                  Comment: null,
                };
                obj.username = this.Calendar_Collection_Table[i].Name;
                obj.JobNumber = this.getJobNumberByUsername(this.Calendar_Collection_Table[i].Name);
                obj.Date = this.Calendar_Collection_Table[i].MonthDates[j].Date;
                obj.event = "请假(" + this.Calendar_Collection_Table[i].MonthDates[j].Occur_Leave[0].nature + ")";
                obj.EventTimeoftheday = this.Calendar_Collection_Table[i].MonthDates[j].Occur_Leave[0].DailyTimeStatistics;
                obj.StartingTimeoftheday = 
                  this.Calendar_Collection_Table[i].MonthDates[j].Occur_Leave[0].LeaveTimeS ?
                  this.ToHHMMSS(this.Calendar_Collection_Table[i].MonthDates[j].Occur_Leave[0].LeaveTimeS) : '';
                obj.EndTimeoftheday = 
                  this.Calendar_Collection_Table[i].MonthDates[j].Occur_Leave[0].LeaveTimeE ?
                  this.ToHHMMSS(this.Calendar_Collection_Table[i].MonthDates[j].Occur_Leave[0].LeaveTimeE) : '';
                obj.Comment = this.Calendar_Collection_Table[i].MonthDates[j].Occur_Leave[0].Why;
                this.ExcelList.push(obj);
                // console.log(obj)
              }

              if ( this.Calendar_Collection_Table[i].MonthDates[j].hasOwnProperty('Occur_Overtime') ) {
                let obj = {
                  JobNumber: null,
                  username: null,
                  Date: null,
                  event: null,
                  EventTimeoftheday: null,
                  StartingTimeoftheday: null,
                  EndTimeoftheday: null,
                  Comment: null,
                };
                obj.username = this.Calendar_Collection_Table[i].Name;
                obj.JobNumber = this.getJobNumberByUsername(this.Calendar_Collection_Table[i].Name);
                obj.Date = this.Calendar_Collection_Table[i].MonthDates[j].Date;
                obj.event = "加班";
                obj.EventTimeoftheday = this.Calendar_Collection_Table[i].MonthDates[j].Occur_Overtime[0].DailyTimeStatistics;
                obj.StartingTimeoftheday = 
                  this.Calendar_Collection_Table[i].MonthDates[j].Occur_Overtime[0].LeaveTimeS ?
                  this.ToHHMMSS(this.Calendar_Collection_Table[i].MonthDates[j].Occur_Overtime[0].LeaveTimeS) : '';
                obj.EndTimeoftheday = 
                  this.Calendar_Collection_Table[i].MonthDates[j].Occur_Overtime[0].LeaveTimeE ?
                  this.ToHHMMSS(this.Calendar_Collection_Table[i].MonthDates[j].Occur_Overtime[0].LeaveTimeE) : '';
                obj.Comment = this.Calendar_Collection_Table[i].MonthDates[j].Occur_Overtime[0].Where + "/" + this.Calendar_Collection_Table[i].MonthDates[j].Occur_Overtime[0].Why;
                this.ExcelList.push(obj);
                // console.log(obj)
              }
              
            }
        }
      }

      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "登记号码",
          "姓名",
          "日期",
          "在岗情况",
          "当日事件时间",
          "当日开始时间",
          "当日结束时间",
          "备注",
        ];
        const filterVal = [
          "JobNumber",
          "username",
          "Date",
          "event",
          "EventTimeoftheday",
          "StartingTimeoftheday",
          "EndTimeoftheday",
          "Comment",
        ];

        const list = this.ExcelList;

        const data = this.formatJson(filterVal, list);
        let filename = this.ToYYYYMM(this.MonthChoose) + "考勤情况";
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: filename,
          autoWidth: this.autoWidth,
          bookType: this.bookType,
        });
        this.downloadLoading = false;
      });

    }, //零用金报销单数据导出成excel主函数


    showCalendaringOperation(){
      const ClearCalendaringOperationdata = {
        Date:[],
        Isholiday: null,
        HolidayAttribute: null,
      }
      this.CalendaringOperationdata = ClearCalendaringOperationdata;
      this.CalendaringOperation = true;
    }, 

    ReturnCalendaringOperation(){
      const ClearCalendaringOperationdata = {
        Date:[],
        Isholiday: null,
        HolidayAttribute: null,
      }
      this.CalendaringOperationdata = ClearCalendaringOperationdata;
      this.CalendaringOperation = false;
    },

    async UpdateCalendaringHolidays() {         //创建条目
      const { data } = await UpdateCalendaringHolidays({
        data : this.CalendaringOperationdata,
      });
    },


  }   
}
</script>


<style scoped>
.NoticeInfo >>> .el-card__header {
  padding: 10px 20px;
}
.NoticeInfo >>> .el-card__body{
  padding: 10px;
  height: 100%;
}
.NoNotification {
  display: flex;
  justify-content: center;
  align-items: center;
}

.current{
  margin-bottom: 5px;
}
.lightgray {
  background-color: lightgray;
}
.lightgreen {
  background-color: lightgreen;
}
.lightblue {
  background-color: lightblue;
}

.Event{
  height: 787px;
  display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
}
.EventCard {
  margin-bottom: 10px;
  width: 48%;
  margin-right: 10px;
}
.EventCard >>> .el-card__body {
  padding: 0px;
}
.card-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px 5px 20px;
}
.card-footer-divider.el-divider--horizontal{
  margin: 0px;
}
.Opinions {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Table_Head{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.Table_Head_Left {
  display: flex;
  align-items: center;
}
.Table_Head_Middle {
  display: flex;
  align-items: center;
}
.Table_Head_Right {
  margin-right: 10px;
}

.Legend_Business {
  width: 50px;
  height: 10px;
  background-color: lightblue;
  border-radius: 5px 5px 5px 5px;
}
.Legend_Leave {
  width: 50px;
  height: 10px;
  background-color: lightgreen;
  border-radius: 5px 5px 5px 5px;
}
.Legend_Overtime {
  width: 50px;
  height: 10px;
  background-color: lightgray;
  border-radius: 5px 5px 5px 5px;
}

.EventBar {
  margin-left: -10px;
  margin-right: -10px;
  margin-top: 10px;
  height: 10px;
}
.Business_Start .EventBar {
  margin-left: 0px;
  background-color: lightblue;
  border-radius: 5px 0 0 5px;
}
.Business_Between .EventBar {
  background-color: lightblue;
}
.Business_End .EventBar {
  margin-right: 0px;
  background-color: lightblue;
  border-radius: 0 5px 5px 0;
}
.Business_None .EventBar {
  background-color: rgba(0, 0, 0, 0);
}
.Business_StartEnd .EventBar {
  margin-left: 0px;
  margin-right: 0px;
  background-color: lightblue;
  border-radius: 5px 5px 5px 5px;
}
.Leave_Start .EventBar {
  margin-left: 0px;
  background-color: lightgreen;
  border-radius: 5px 0 0 5px;
}
.Leave_Between .EventBar {
  background-color: lightgreen;
}
.Leave_End .EventBar {
  margin-right: 0px;
  background-color: lightgreen;
  border-radius: 0 5px 5px 0;
}
.Leave_StartEnd .EventBar {
  margin-left: 0px;
  margin-right: 0px;
  background-color: lightgreen;
  border-radius: 5px 5px 5px 5px;
}
.Overtime_Start .EventBar {
  margin-left: 0px;
  background-color: lightgray;
  border-radius: 5px 0 0 5px;
}
.Overtime_Between .EventBar {
  background-color: lightgray;
}
.Overtime_End .EventBar {
  margin-right: 0px;
  background-color: lightgray;
  border-radius: 0 5px 5px 0;
}
.Overtime_StartEnd .EventBar {
  margin-left: 0px;
  margin-right: 0px;
  background-color: lightgray;
  border-radius: 5px 5px 5px 5px;
}

.holiday-cell {
  background-color: #f2f2f2; /* 设置背景色为淡灰色 */
}
.Table >>> .el-table__body td {
  height: 50px; /* 根据需要调整单元格的高度 */
}

</style>

<style>
.WorkAttendance_Hoilday{
  background-color: #f7f7f7 !important;
} 
.WorkAttendance_Today{
  background-color: #FFFFE0 !important;
} 
.WorkAttendance_Hoilday.cell{
  padding-right: 0px !important;
} 
.WorkAttendance_Workday.cell{
  padding-right: 0px !important;
} 
.WorkAttendance_Today.cell{
  padding-right: 0px !important;
} 
</style>