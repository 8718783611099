var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.Tittle,
            visible: _vm.$store.state.label.addlablevisiable,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(
                _vm.$store.state.label,
                "addlablevisiable",
                $event
              )
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "Newlable",
              attrs: {
                model: _vm.Newlable,
                "label-width": "80px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标签名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.Newlable.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.Newlable, "name", $$v)
                      },
                      expression: "Newlable.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标签类型", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.Newlable.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.Newlable, "type", $$v)
                        },
                        expression: "Newlable.type",
                      },
                    },
                    _vm._l(_vm.$store.state.label.type, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "具体描述" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.Newlable.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.Newlable, "description", $$v)
                      },
                      expression: "Newlable.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.editlabel
            ? _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.addlabel()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.editlabel
            ? _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.changelabel()
                        },
                      },
                    },
                    [_vm._v("修 改")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }