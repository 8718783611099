var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-button",
        {
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              _vm.showFilter = true
            },
          },
        },
        [_vm._v("筛选")]
      ),
      _c("el-button", { on: { click: _vm.newForm } }, [_vm._v("新建")]),
      _c(
        "el-button",
        {
          staticStyle: { margin: "0 0 20px 20px" },
          attrs: {
            loading: _vm.downloadLoading,
            type: "primary",
            icon: "el-icon-document",
          },
          on: { click: _vm.handleDownload },
        },
        [_vm._v(" Export Excel ")]
      ),
      _c(
        "el-button",
        {
          on: {
            click: function ($event) {
              _vm.showExcelUpload = true
            },
          },
        },
        [_vm._v("导入")]
      ),
      _c("el-button", { on: { click: _vm.UploadFixFile } }, [
        _vm._v("导入返修统计"),
      ]),
      _c("el-button", { on: { click: _vm.UploadUPSFile } }, [
        _vm._v("导入总部回执"),
      ]),
      _c("el-input", {
        staticClass: "filterbysn",
        attrs: { placeholder: "按照序列号筛选" },
        model: {
          value: _vm.filterSN,
          callback: function ($$v) {
            _vm.filterSN = $$v
          },
          expression: "filterSN",
        },
      }),
      _c(
        "el-select",
        {
          staticStyle: { "margin-left": "10px", width: "30%" },
          attrs: {
            multiple: "",
            "allow-create": "",
            filterable: "",
            placeholder: "按模块快速筛选",
          },
          on: { change: _vm.QuickFilter },
          model: {
            value: _vm.QuickFilterItem,
            callback: function ($$v) {
              _vm.QuickFilterItem = $$v
            },
            expression: "QuickFilterItem",
          },
        },
        [
          _c("template", { slot: "prefix" }, [
            _c("span", {
              staticStyle: {
                padding: "5px",
                "line-height": "33px",
                "font-size": "18px",
                color: "#409eff",
              },
            }),
          ]),
          _vm._l(_vm.ProdutInfo, function (item) {
            return _c("el-option", { key: item, attrs: { value: item } }, [
              _c("span", { staticStyle: { float: "left" } }, [
                _vm._v(" " + _vm._s(item) + " "),
              ]),
            ])
          }),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showExcelUpload, title: "导入" },
          on: {
            "update:visible": function ($event) {
              _vm.showExcelUpload = $event
            },
          },
        },
        [
          _c("upload-excel-component", {
            attrs: {
              "on-success": _vm.handleSuccess,
              "before-upload": _vm.beforeUpload,
            },
          }),
          _c(
            "el-table",
            {
              staticStyle: {
                overflow: "scroll",
                height: "1000px",
                width: "100%",
                "margin-top": "20px",
              },
              attrs: {
                data: _vm.tableData,
                border: "",
                "highlight-current-row": "",
              },
            },
            _vm._l(_vm.tableHeader, function (item) {
              return _c("el-table-column", {
                key: item,
                attrs: { prop: item, label: item },
              })
            }),
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.bulkSave } },
                [_vm._v("添加")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.bulkUpdate } },
                [_vm._v("更新")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showDelete, title: "删除" },
          on: {
            "update:visible": function ($event) {
              _vm.showDelete = $event
            },
          },
        },
        [
          _c("h4", [_vm._v("确定删除")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.deleteInfo } },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "normal" },
                  on: {
                    click: function ($event) {
                      _vm.showDelete = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "filter",
          attrs: { visible: _vm.showFilter, title: "filter" },
          on: {
            "update:visible": function ($event) {
              _vm.showFilter = $event
            },
          },
        },
        [
          _c("table", { staticClass: "form" }, [
            _c("tr", [
              _c("td", [_c("p", [_vm._v("公司")])]),
              _c("td", [_c("p", [_vm._v("联系人")])]),
              _c("td", [_c("p", [_vm._v("序列号")])]),
            ]),
            _c("tr", [
              _c(
                "td",
                [
                  _c("el-input", {
                    staticClass: "bottom-input",
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.listInfo.customerInfo.company.data,
                      callback: function ($$v) {
                        _vm.$set(_vm.listInfo.customerInfo.company, "data", $$v)
                      },
                      expression: "listInfo.customerInfo.company.data",
                    },
                  }),
                ],
                1
              ),
              _c(
                "td",
                [
                  _c("el-input", {
                    staticClass: "bottom-input",
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.listInfo.customerInfo.contactor.data,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.listInfo.customerInfo.contactor,
                          "data",
                          $$v
                        )
                      },
                      expression: "listInfo.customerInfo.contactor.data",
                    },
                  }),
                ],
                1
              ),
              _c("td", [
                _c("div", [
                  _c(
                    "span",
                    [
                      _c("el-input", {
                        staticClass: "bottom-input",
                        attrs: { type: "text", size: "small" },
                        model: {
                          value: _vm.listInfo.productInfo.SN.dataS,
                          callback: function ($$v) {
                            _vm.$set(_vm.listInfo.productInfo.SN, "dataS", $$v)
                          },
                          expression: "listInfo.productInfo.SN.dataS",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" ~ "),
                  _c(
                    "span",
                    [
                      _c("el-input", {
                        staticClass: "bottom-input",
                        attrs: { type: "text", size: "small" },
                        model: {
                          value: _vm.listInfo.productInfo.SN.dataE,
                          callback: function ($$v) {
                            _vm.$set(_vm.listInfo.productInfo.SN, "dataE", $$v)
                          },
                          expression: "listInfo.productInfo.SN.dataE",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("table", [
            _c("tr", [
              _c("td", [_c("p", [_vm._v("产品名称")])]),
              _c("td", [_c("p", [_vm._v("产品订货号")])]),
              _c("td", [_c("p", [_vm._v("硬件版本")])]),
              _c("td", [_c("p", [_vm._v("软件版本")])]),
            ]),
            _c("tr", [
              _c(
                "td",
                [
                  _c("el-input", {
                    staticClass: "bottom-input",
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.listInfo.productInfo.module.data,
                      callback: function ($$v) {
                        _vm.$set(_vm.listInfo.productInfo.module, "data", $$v)
                      },
                      expression: "listInfo.productInfo.module.data",
                    },
                  }),
                ],
                1
              ),
              _c(
                "td",
                [
                  _c("el-input", {
                    staticClass: "bottom-input",
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.listInfo.productInfo.articl.data,
                      callback: function ($$v) {
                        _vm.$set(_vm.listInfo.productInfo.articl, "data", $$v)
                      },
                      expression: "listInfo.productInfo.articl.data",
                    },
                  }),
                ],
                1
              ),
              _c(
                "td",
                [
                  _c("el-input", {
                    staticClass: "bottom-input",
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.listInfo.productInfo.hardware.data,
                      callback: function ($$v) {
                        _vm.$set(_vm.listInfo.productInfo.hardware, "data", $$v)
                      },
                      expression: "listInfo.productInfo.hardware.data",
                    },
                  }),
                ],
                1
              ),
              _c(
                "td",
                [
                  _c("el-input", {
                    staticClass: "bottom-input",
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.listInfo.productInfo.software.data,
                      callback: function ($$v) {
                        _vm.$set(_vm.listInfo.productInfo.software, "data", $$v)
                      },
                      expression: "listInfo.productInfo.software.data",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", [_c("p", [_vm._v("退货单号")])]),
              _c("td", [_c("p", [_vm._v("物料号")])]),
              _c("td", [_c("p", [_vm._v("零件编号")])]),
            ]),
            _c("tr", [
              _c(
                "td",
                [
                  _c("el-input", {
                    staticClass: "bottom-input",
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.listInfo.productInfo.report.data,
                      callback: function ($$v) {
                        _vm.$set(_vm.listInfo.productInfo.report, "data", $$v)
                      },
                      expression: "listInfo.productInfo.report.data",
                    },
                  }),
                ],
                1
              ),
              _c(
                "td",
                [
                  _c("el-input", {
                    staticClass: "bottom-input",
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.listInfo.productInfo.parts.data,
                      callback: function ($$v) {
                        _vm.$set(_vm.listInfo.productInfo.parts, "data", $$v)
                      },
                      expression: "listInfo.productInfo.parts.data",
                    },
                  }),
                ],
                1
              ),
              _c(
                "td",
                [
                  _c("el-input", {
                    staticClass: "bottom-input",
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.listInfo.productInfo.po.data,
                      callback: function ($$v) {
                        _vm.$set(_vm.listInfo.productInfo.po, "data", $$v)
                      },
                      expression: "listInfo.productInfo.po.data",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("table", [
            _c("tr", [
              _c("td", [
                _c("p", [_vm._v("返修日期")]),
                _c("div", [
                  _c(
                    "span",
                    [
                      _c("el-input", {
                        staticClass: "bottom-input",
                        attrs: { type: "date", size: "small" },
                        model: {
                          value: _vm.listInfo.productInfo.return.dataS,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.listInfo.productInfo.return,
                              "dataS",
                              $$v
                            )
                          },
                          expression: "listInfo.productInfo.return.dataS",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" ~ "),
                  _c(
                    "span",
                    [
                      _c("el-input", {
                        staticClass: "bottom-input",
                        attrs: { type: "date", size: "small" },
                        model: {
                          value: _vm.listInfo.productInfo.return.dataE,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.listInfo.productInfo.return,
                              "dataE",
                              $$v
                            )
                          },
                          expression: "listInfo.productInfo.return.dataE",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("td", [
                _c("p", [_vm._v("发货日期")]),
                _c("div", [
                  _c(
                    "span",
                    [
                      _c("el-input", {
                        staticClass: "bottom-input",
                        attrs: { type: "date", size: "small" },
                        model: {
                          value: _vm.listInfo.productInfo.delivery.dataS,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.listInfo.productInfo.delivery,
                              "dataS",
                              $$v
                            )
                          },
                          expression: "listInfo.productInfo.delivery.dataS",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" ~ "),
                  _c(
                    "span",
                    [
                      _c("el-input", {
                        staticClass: "bottom-input",
                        attrs: { type: "date", size: "small" },
                        model: {
                          value: _vm.listInfo.productInfo.delivery.dataE,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.listInfo.productInfo.delivery,
                              "dataE",
                              $$v
                            )
                          },
                          expression: "listInfo.productInfo.delivery.dataE",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c("tr", [
              _c("td", [
                _c("p", [_vm._v("质保到期日")]),
                _c("div", [
                  _c(
                    "span",
                    [
                      _c("el-input", {
                        staticClass: "bottom-input",
                        attrs: { type: "date", size: "small" },
                        model: {
                          value: _vm.listInfo.productInfo.warranty.dataS,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.listInfo.productInfo.warranty,
                              "dataS",
                              $$v
                            )
                          },
                          expression: "listInfo.productInfo.warranty.dataS",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" ~ "),
                  _c(
                    "span",
                    [
                      _c("el-input", {
                        staticClass: "bottom-input",
                        attrs: { type: "date", size: "small" },
                        model: {
                          value: _vm.listInfo.productInfo.warranty.dataE,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.listInfo.productInfo.warranty,
                              "dataE",
                              $$v
                            )
                          },
                          expression: "listInfo.productInfo.warranty.dataE",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("td", [
                _c("p", [_vm._v("返回日期")]),
                _c("div", [
                  _c(
                    "span",
                    [
                      _c("el-input", {
                        staticClass: "bottom-input",
                        attrs: { type: "date", size: "small" },
                        model: {
                          value: _vm.listInfo.Date.dataS,
                          callback: function ($$v) {
                            _vm.$set(_vm.listInfo.Date, "dataS", $$v)
                          },
                          expression: "listInfo.Date.dataS",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" ~ "),
                  _c(
                    "span",
                    [
                      _c("el-input", {
                        staticClass: "bottom-input",
                        attrs: { type: "date", size: "small" },
                        model: {
                          value: _vm.listInfo.Date.dataE,
                          callback: function ($$v) {
                            _vm.$set(_vm.listInfo.Date, "dataE", $$v)
                          },
                          expression: "listInfo.Date.dataE",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c("tr", [
              _c(
                "td",
                [
                  _c("p", [_vm._v("故障信息")]),
                  _c("el-input", {
                    staticClass: "bottom-input",
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.listInfo.failureDescription.data,
                      callback: function ($$v) {
                        _vm.$set(_vm.listInfo.failureDescription, "data", $$v)
                      },
                      expression: "listInfo.failureDescription.data",
                    },
                  }),
                ],
                1
              ),
              _c(
                "td",
                [
                  _c("p", [_vm._v("国外报告")]),
                  _c("el-input", {
                    staticClass: "bottom-input",
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.listInfo.sigmatekReport.data,
                      callback: function ($$v) {
                        _vm.$set(_vm.listInfo.sigmatekReport, "data", $$v)
                      },
                      expression: "listInfo.sigmatekReport.data",
                    },
                  }),
                ],
                1
              ),
              _c(
                "td",
                [
                  _c("p", [_vm._v("维修信息")]),
                  _c("el-input", {
                    staticClass: "bottom-input",
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.listInfo.analysis.data,
                      callback: function ($$v) {
                        _vm.$set(_vm.listInfo.analysis, "data", $$v)
                      },
                      expression: "listInfo.analysis.data",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("p", [_vm._v("更换部件信息")]),
          _c("table", [
            _c("tr", [
              _c(
                "td",
                [
                  _c("p", [_vm._v("物料名称")]),
                  _c("el-input", {
                    staticClass: "bottom-input",
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.listInfo.componentsInfomation.name.data,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.listInfo.componentsInfomation.name,
                          "data",
                          $$v
                        )
                      },
                      expression: "listInfo.componentsInfomation.name.data",
                    },
                  }),
                ],
                1
              ),
              _c(
                "td",
                [
                  _c("p", [_vm._v("故障物料序列号")]),
                  _c("el-input", {
                    staticClass: "bottom-input",
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.listInfo.componentsInfomation.badNr.data,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.listInfo.componentsInfomation.badNr,
                          "data",
                          $$v
                        )
                      },
                      expression: "listInfo.componentsInfomation.badNr.data",
                    },
                  }),
                ],
                1
              ),
              _c(
                "td",
                [
                  _c("p", [_vm._v("订货号")]),
                  _c("el-input", {
                    staticClass: "bottom-input",
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.listInfo.componentsInfomation.number.data,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.listInfo.componentsInfomation.number,
                          "data",
                          $$v
                        )
                      },
                      expression: "listInfo.componentsInfomation.number.data",
                    },
                  }),
                ],
                1
              ),
              _c(
                "td",
                [
                  _c("p", [_vm._v("新物料序列号")]),
                  _c("el-input", {
                    staticClass: "bottom-input",
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.listInfo.componentsInfomation.newNr.data,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.listInfo.componentsInfomation.newNr,
                          "data",
                          $$v
                        )
                      },
                      expression: "listInfo.componentsInfomation.newNr.data",
                    },
                  }),
                ],
                1
              ),
              _c(
                "td",
                [
                  _c("p", [_vm._v("注释")]),
                  _c("el-input", {
                    staticClass: "bottom-input",
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.listInfo.Comment.data.data,
                      callback: function ($$v) {
                        _vm.$set(_vm.listInfo.Comment.data, "data", $$v)
                      },
                      expression: "listInfo.Comment.data.data",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("table", [
            _c("tr", [
              _c(
                "td",
                [
                  _c("p", [_vm._v("国外送出方式")]),
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.listInfo.ups.sendType.data,
                        callback: function ($$v) {
                          _vm.$set(_vm.listInfo.ups.sendType, "data", $$v)
                        },
                        expression: "listInfo.ups.sendType.data",
                      },
                    },
                    _vm._l(_vm.upsSend, function (item) {
                      return _c("el-option", {
                        key: item.jobId,
                        attrs: { label: item.sendType, value: item.sendType },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("td", [
                _c("p", [_vm._v("送出时间")]),
                _c("div", [
                  _c(
                    "span",
                    [
                      _c("el-input", {
                        staticClass: "bottom-input",
                        attrs: { type: "date", size: "small" },
                        model: {
                          value: _vm.listInfo.ups.sendDate.dataS,
                          callback: function ($$v) {
                            _vm.$set(_vm.listInfo.ups.sendDate, "dataS", $$v)
                          },
                          expression: "listInfo.ups.sendDate.dataS",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" ~ "),
                  _c(
                    "span",
                    [
                      _c("el-input", {
                        staticClass: "bottom-input",
                        attrs: { type: "date", size: "small" },
                        model: {
                          value: _vm.listInfo.ups.sendDate.dataE,
                          callback: function ($$v) {
                            _vm.$set(_vm.listInfo.ups.sendDate, "dataE", $$v)
                          },
                          expression: "listInfo.ups.sendDate.dataE",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "td",
                [
                  _c("p", [_vm._v("国外返回方式")]),
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.listInfo.ups.backType.data,
                        callback: function ($$v) {
                          _vm.$set(_vm.listInfo.ups.backType, "data", $$v)
                        },
                        expression: "listInfo.ups.backType.data",
                      },
                    },
                    _vm._l(_vm.upsBack, function (item) {
                      return _c("el-option", {
                        key: item.jobId,
                        attrs: { label: item.sendType, value: item.sendType },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("td", [
                _c("p", [_vm._v("返回时间")]),
                _c("div", [
                  _c(
                    "span",
                    [
                      _c("el-input", {
                        staticClass: "bottom-input",
                        attrs: { type: "date", size: "small" },
                        model: {
                          value: _vm.listInfo.ups.backDate.dataS,
                          callback: function ($$v) {
                            _vm.$set(_vm.listInfo.ups.backDate, "dataS", $$v)
                          },
                          expression: "listInfo.ups.backDate.dataS",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" ~ "),
                  _c(
                    "span",
                    [
                      _c("el-input", {
                        staticClass: "bottom-input",
                        attrs: { type: "date", size: "small" },
                        model: {
                          value: _vm.listInfo.ups.backDate.dataE,
                          callback: function ($$v) {
                            _vm.$set(_vm.listInfo.ups.backDate, "dataE", $$v)
                          },
                          expression: "listInfo.ups.backDate.dataE",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "normal" }, on: { click: _vm.clearFilter } },
                [_vm._v("Clear")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmFilter },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showComment, title: "附加" },
          on: {
            "update:visible": function ($event) {
              _vm.showComment = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            {
              staticStyle: { "margin-top": "15px" },
              attrs: { type: "border-card" },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            _vm._l(_vm.tabMapOptions, function (item) {
              return _c(
                "el-tab-pane",
                { key: item.key, attrs: { label: item.label, name: item.key } },
                [
                  _c(
                    "keep-alive",
                    [
                      _vm.activeName == item.key
                        ? _c("tab-pane", {
                            attrs: {
                              comment: _vm.comment,
                              ups: _vm.ups,
                              type: item.key,
                            },
                            on: {
                              create: _vm.showCreatedTimes,
                              saveExtras: _vm.saveExtras,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "normal" }, on: { click: _vm.closeExtra } },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.$index +
                            (_vm.listQuery.page - 1) * _vm.listQuery.limit +
                            1
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Company" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.customerInfo.company)),
                    _vm.checkFile(scope.row)
                      ? _c("span", { staticClass: "blue" }, [
                          _c("i", { staticClass: "el-icon-paperclip" }),
                        ])
                      : _vm._e(),
                    _vm.checkComment(scope.row)
                      ? _c("span", { staticClass: "red" }, [
                          _c("i", { staticClass: "el-icon-tickets" }),
                        ])
                      : _vm._e(),
                    _vm.checkUps(scope.row)
                      ? _c("span", { staticClass: "yellow" }, [
                          _c("i", { staticClass: "el-icon-s-promotion" }),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "SN", width: "110", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.productInfo.SN) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Module", width: "110", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.productInfo.module) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Failure", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.failureDescription) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "created_at",
              label: "返修日期",
              width: "160",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.productInfo.return))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "created_at",
              label: "发货日期",
              width: "160",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.productInfo.delivery)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "created_at",
              label: "返回日期",
              width: "160",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.Date))])]
                },
              },
            ]),
          }),
          _vm._v("--> "),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "250" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            path: "form",
                            query: {
                              key: scope.row._id,
                              filters: _vm.listQuery, // orderNum : this.searchData.orderNo
                            },
                          },
                        },
                      },
                      [
                        _c("el-button", { attrs: { type: "button" } }, [
                          _vm._v("编辑"),
                        ]),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "inline" }),
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.openComment(scope.row)
                          },
                        },
                      },
                      [_vm._v("附加")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "gray" },
                        on: {
                          click: function ($event) {
                            return _vm.openDelete(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }