import Cookies from 'js-cookie'

const TokenKey = 'vue_admin_template_token'
const NameKey = 'user_name'
const DepartmentKey = 'user_department'
const companyCode = "user_companycode"
const VerifyToken = "VerifyToken"
export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}
export function getCompanyCode() {
  return Cookies.get(companyCode) == "undefined" ? undefined : Cookies.get(companyCode)
}

export function setCompanyCode(code) {
  return Cookies.set(companyCode, code)
}
export function removeToken() {
  Cookies.remove(TokenKey)
  localStorage.removeItem(VerifyToken)
  return true;
}

export function setName(user_name) {
  return Cookies.set(NameKey,user_name)
}
export function getName() {
  return Cookies.get(NameKey)
}
export function setDepartment(user_department) {
  return Cookies.set(DepartmentKey,user_department)
}
export function setVerifyToken(code) {
  return localStorage.setItem(VerifyToken,code)
}
export function getVerifyToken() {
  return localStorage.getItem(VerifyToken)
}