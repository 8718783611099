<template>
<div>
    <addlabel ></addlabel>
    <el-button class="addlable" v-on:click="Showaddlable()"><svg-icon icon-class="add" /></el-button>
  <el-input v-model="Search" placeholder="搜索" class="Fuzzy_search" ></el-input>
  <el-button type="primary" v-on:click="FuzzySearch">搜索</el-button>
    <div class="cardlist">
    <el-card shadow="hover" v-for="(label,index) in list" :key="index" :class="classselect(label.type)" > 
      <h2>{{label.name}}</h2>
      <p>类型：{{label.type}}</p>
      <p class="description">{{label.description}} </p>
      <el-popconfirm
        confirm-button-text='删除'
        icon="el-icon-info"
        icon-color="red"
        title="确认删除"
        @confirm="deletelabel(label)">
  <el-button slot="reference" class="deletelable"><svg-icon icon-class="delete"/> </el-button>
</el-popconfirm>
<el-button class=" editlabel" v-on:click="showeditlabel(label)"><svg-icon icon-class="edit"/></el-button>
    </el-card>
    </div>
     <div class="block">
      <el-pagination
        :page-size="10"
        layout="prev, pager, next, jumper"
        :total="total"
        :current-page.sync="listQuery.page"
         @current-change="handleCurrentChange">
      </el-pagination>
    </div>
</div>
</template>
<script>

import {  Message, MessageBox } from "element-ui";
import addlabel from "./components/addlabel.vue"
export default {
    components: { addlabel },
    computed:{
        list(){ return this.$store.state.label.labellist},
        listQuery(){return this.$store.state.label.listQuery},
        total(){return this.$store.state.label.total},
    },
    data(){
        return{
            value:true,
            Search:null,
            labelinfo:{
                name:null,
                type:null,
                description:null
            }
        };
    },
    created(){
    this.$store.dispatch("InIcreative");
    },
    methods:{
    Showaddlable(){this.$store.commit("showaddlabel");},
    handleCurrentChange(page){
    this.listQuery.page = page;
    this.$store.dispatch("getlabelList");},
    switchchange(event,label){
    this.$store.dispatch("updatelabelList",label)
    },
    classselect(data)
    {
        if(data=="模块型号")
        return "Module"
        if(data=="故障报错")
        return "bug"
        if(data=="客户")
        return "company"
    },
    deletelabel(label){
          //  console.log("触发");
            this.$store.dispatch("deletelabel",label).then(
                res=>{
               if(res=="删除失败")
               { Message({
                message: "删除失败",
                type: "error",
                duration: 5 * 1000,});}
            else{
                Message({
                message: "删除成功",
                type: "success",
                duration: 5 * 1000,});
                }
                this.$store.dispatch("getlabelList");})
    },
    // filterchange()
    // {   if(this.select==null||this.select=="")
    //     {
    //         this.listQuery.filters={};
    //     }
    //     else{
    //         this.listQuery.filters='({'+"type:"+"'"+this.select+"'"+"})";
    //     }
    //     this.$store.dispatch("getlabelList");
    // },
    showeditlabel(label)
    {  this.$store.commit("editlabel",label);
    },
    FuzzySearch(){
        if(this.Search==null||this.Search=="")
        {
            this.listQuery.filters={};
        }
        else
        {
            this.listQuery.filters="({$or:[{name:{$regex:'"+this.Search+"'}},{type:{$regex:'"+this.Search+"'}},{description:{$regex:'"+this.Search+"'}}]})";
        }
        this.$store.dispatch("getlabelList");
    }
    }
}
</script>
<style scoped>

div>>>.addlable{
    margin: 10px;
    padding: 10px;
}
div>>>.editlabel{
    float:right;
    position: absolute;
    right: 60px;
    bottom: 10px;
    padding: 10px; 
}
div>>>.deletelable{
    float:right;
    position: absolute;
    right: 10px;
    bottom: 10px;
    padding: 10px;
}
div>>> .el-card
{  
    float: left;
    position: relative;
    width: 300px;
    height: 250px;
    border-radius: 4px;
    margin: 3px;
    margin-left: 10px;
}
.cardlist h2{
    font-size: 18px;
    margin: 10px 10px 10px 0px;
    padding-bottom: 10px;
}
.cardlist p{
    margin: 10px;
    margin-top: 20px;
}
div>>>.el-switch{
    display: inline-block;
}
.block{
  display: inline-block;
  margin:30px 40%;
  height: 100px;
}
div>>> .Module{
    background-color: #B2DFDB;
}
div>>>.company{
background-color:#607D8B
}
div>>>.bug{
background-color: #F8BBD0;
}
div>>>.Fuzzy_search{
    margin-left: 10px;
    margin-right: 5px;
    display: inline-block;
    width: 300px;
}
</style>