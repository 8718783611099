import request from '@/utils/request'

export function getAllErrorSubmitCount(data) {
  return request({
    url: '/WXAPI/WxErrorSubmit/getAllErrorSubmitCount',
    method: 'post',
    data
  })
}
export function getAllErrorSubmit(data) {
    return request({
      url: '/WXAPI/WxErrorSubmit/getAllErrorSubmit',
      method: 'post',
      data
    })
  }
  export function updateSubmit(data) {
    return request({
      url: '/WXAPI/WxErrorSubmit/updateSubmit',
      method: 'post',
      data
    })
  }

  export function getWxCompanys(data) {
    return request({
      url: '/WXAPI/WxCompany/getWxCompanys',
      method: 'post',
      data
    })
  }
  export function getAllProducts(data) {
    return request({
      url: '/WXAPI/WxProduct/getAllProducts',
      method: 'post',
      data
    })
  }
  export function updateWxProduct(data) {
    return request({
      url: '/WXAPI/WxProduct/updateWxProduct',
      method: 'post',
      data
    })
  }
  export function IfHaveDocument(data) {
    return request({
      url: '/WXAPI/WxProduct/IfHaveDocument',
      method: 'post',
      data
    })
  }
  export function createWxProduct(data) {
    return request({
      url: '/WXAPI/WxProduct/createWxProduct',
      method: 'post',
      data
    })
  }
  export function deleteWxProduct(data) {
    return request({
      url: '/WXAPI/WxProduct/deleteWxProduct',
      method: 'post',
      data
    })
  }
  export function createWxCompany(data) {
    return request({
      url: '/WXAPI/WxCompany/createWxCompany',
      method: 'post',
      data
    })
  }
  export function GetAllUser(data) {
    return request({
      url: '/WXAPI/WxUser/GetAllUser',
      method: 'post',
      data
    })
  }
  export function DeleteUser(data) {
    return request({
      url: '/WXAPI/WxUser/DeleteUser',
      method: 'post',
      data
    })
  }
  export function UpdateDepartment(data) {
    return request({
      url: '/WXAPI/WxUser/UpdateDepartment',
      method: 'post',
      data
    })
  }
  export function UpdateRegUser(data) {
    return request({
      url: '/WXAPI/WxUser/UpdateRegUser',
      method: 'post',
      data
    })
  }
  export function updateProductImage(data, file) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('data', data);
    //console.log(formData)
    return request({
      url: '/WXAPI/file/product',
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
  
  export function updateProductPdf(data, file) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('data', data);
    //console.log(formData)
    return request({
      url: '/WXAPI/file/productPdf',
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
  export function updateDepartment(data) {
    return request({
      url: '/WXAPI/WxCompany/updateDepartment',
      method: 'post',
      data
    })
  }