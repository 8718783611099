var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "OutBox" }, [
    _c(
      "div",
      { staticClass: "HeadButtonArea" },
      [
        _c("el-button", {
          directives: [
            {
              name: "print",
              rawName: "v-print",
              value: _vm.printObj_LYJBXD,
              expression: "printObj_LYJBXD",
            },
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
          attrs: { id: "print_LYJBXD" },
        }),
        _c("el-button", {
          directives: [
            {
              name: "print",
              rawName: "v-print",
              value: _vm.printObj_CCBTTJD,
              expression: "printObj_CCBTTJD",
            },
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
          attrs: { id: "print_CCBTTJD" },
        }),
        _c("el-button", { on: { click: _vm.beforePrint } }, [_vm._v("打印")]),
        _c("el-button", { on: { click: _vm.SaveForm } }, [_vm._v("保存")]),
        _c(
          "router-link",
          {
            attrs: {
              to: {
                path: "Project",
                query: { projectID: _vm.task.projectID, actTask: _vm.task },
              },
            },
          },
          [
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "10px", "margin-right": "10px" },
                attrs: { type: "button" },
              },
              [_vm._v("返回")]
            ),
          ],
          1
        ),
        _vm.radio == "零用金报销单"
          ? _c(
              "el-button",
              {
                attrs: { loading: _vm.downloadLoading, type: "primary" },
                on: { click: _vm.ExcelDownload },
              },
              [
                _vm._v(" 导出excel "),
                _c("i", { staticClass: "el-icon-document" }),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticStyle: { "margin-left": "10px", "margin-top": "10px" } },
      [
        _c(
          "el-radio-group",
          {
            model: {
              value: _vm.radio,
              callback: function ($$v) {
                _vm.radio = $$v
              },
              expression: "radio",
            },
          },
          [
            _c("el-radio-button", { attrs: { label: "零用金报销单" } }),
            _c("el-radio-button", { attrs: { label: "出差补贴统计单" } }),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticStyle: { display: "flex" } }, [
      _vm.radio == "零用金报销单"
        ? _c("div", { attrs: { id: "printArea_LYJBXD" } }, [
            _c("div", { attrs: { id: "DataArea" } }, [
              _c(
                "div",
                { staticClass: "Tittle" },
                [
                  _c(
                    "el-container",
                    { staticStyle: { height: "100%" } },
                    [
                      _c(
                        "el-aside",
                        { staticClass: "TitleText", attrs: { width: "50%" } },
                        [_vm._v("零用金报销单")]
                      ),
                      _c(
                        "el-container",
                        [
                          _c(
                            "el-header",
                            {
                              staticClass: "TitleImage",
                              attrs: { height: "80%" },
                            },
                            [
                              _c("img", {
                                attrs: { src: require("../../../Img/log.jpg") },
                              }),
                            ]
                          ),
                          _c(
                            "el-footer",
                            {
                              staticClass: "TitleEdition",
                              attrs: { height: "20%" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.ReimbursementDocumentVersionNumber
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "Split_Line" }),
              _c(
                "div",
                { staticClass: "Date" },
                [
                  _c(
                    "el-container",
                    { staticStyle: { height: "100%" } },
                    [
                      _c(
                        "el-aside",
                        { staticClass: "DateInfo", attrs: { width: "60%" } },
                        [
                          _c("div", { staticClass: "ReportDate" }, [
                            _c("div", { staticClass: "ReportDate_Text" }, [
                              _vm._v("报表日期"),
                            ]),
                            _c("div", { staticClass: "ReportDate_Date" }, [
                              !_vm.PrintStatus
                                ? _c(
                                    "div",
                                    { staticClass: "ReportDate_Date_Write" },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          size: "mini",
                                          type: "date",
                                          placeholder: "选择日期",
                                          "value-format": "timestamp",
                                        },
                                        on: { change: _vm.ChangeReportDate },
                                        model: {
                                          value:
                                            _vm.ReportContentInfo.ReportDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ReportContentInfo,
                                              "ReportDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ReportContentInfo.ReportDate",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.PrintStatus
                                ? _c(
                                    "div",
                                    { staticClass: "ReportDate_Date_Print" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.ReportContentInfo
                                              .ReportDate_Watch
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                          _c("div", { staticClass: "ExpenseCode" }, [
                            _c("div", { staticClass: "ExpenseCode_Text" }, [
                              _vm._v("费用代码"),
                            ]),
                            _c("div", { staticClass: "ExpenseCode_Date" }, [
                              !_vm.PrintStatus
                                ? _c(
                                    "div",
                                    { staticClass: "ExpenseCode_Date_Write" },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入内容",
                                          size: "mini",
                                        },
                                        model: {
                                          value:
                                            _vm.ReportContentInfo.ExpenseCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ReportContentInfo,
                                              "ExpenseCode",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ReportContentInfo.ExpenseCode",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.PrintStatus
                                ? _c(
                                    "div",
                                    { staticClass: "ExpenseCode_Date_Print" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.ReportContentInfo.ExpenseCode
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                          _c("div", { staticClass: "SettlementDate" }, [
                            _c("div", { staticClass: "SettlementDate_Text" }, [
                              _vm._v("结算日期"),
                            ]),
                            _c("div", { staticClass: "SettlementDate_Date" }, [
                              !_vm.PrintStatus
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "SettlementDate_Date_Write",
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "",
                                          size: "mini",
                                          disabled: true,
                                        },
                                        model: {
                                          value:
                                            this.ReportContentInfo
                                              .SettlementDateS_Watch,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              this.ReportContentInfo,
                                              "SettlementDateS_Watch",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "this.ReportContentInfo.SettlementDateS_Watch",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.PrintStatus
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "SettlementDate_Date_Print",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.ReportContentInfo
                                              .SettlementDateS_Watch
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _c(
                              "div",
                              { staticClass: "SettlementDate_Text_to" },
                              [_vm._v("到")]
                            ),
                            _c("div", { staticClass: "SettlementDate_Date" }, [
                              !_vm.PrintStatus
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "SettlementDate_Date_Write",
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "",
                                          size: "mini",
                                          disabled: true,
                                        },
                                        model: {
                                          value:
                                            this.ReportContentInfo
                                              .SettlementDateE_Watch,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              this.ReportContentInfo,
                                              "SettlementDateE_Watch",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "this.ReportContentInfo.SettlementDateE_Watch",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.PrintStatus
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "SettlementDate_Date_Print",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.ReportContentInfo
                                              .SettlementDateE_Watch
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            !_vm.PrintStatus
                              ? _c(
                                  "div",
                                  { staticClass: "SettlementDate_icon" },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content:
                                            "结算日期关联经费明细中的日期，无需自己填写",
                                          placement: "top",
                                          effect: "light",
                                        },
                                      },
                                      [_c("i", { staticClass: "el-icon-info" })]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _c(
                        "el-container",
                        [
                          _c(
                            "el-header",
                            {
                              staticClass: "Sigmatek_Name",
                              attrs: { height: "33.3%" },
                            },
                            [_vm._v(" " + _vm._s(_vm.SigmatekInfo.Name) + " ")]
                          ),
                          _c(
                            "el-main",
                            {
                              staticClass: "Sigmatek_Info",
                              attrs: { height: "66.6%" },
                            },
                            [
                              _c("p", { staticClass: "One_Sigmatek_Info" }, [
                                _vm._v(_vm._s(_vm.SigmatekInfo.Address)),
                              ]),
                              _c("p", { staticClass: "One_Sigmatek_Info" }, [
                                _vm._v(
                                  " T." +
                                    _vm._s(_vm.SigmatekInfo.Telephone) +
                                    "/F." +
                                    _vm._s(_vm.SigmatekInfo.F) +
                                    " "
                                ),
                              ]),
                              _c("p", { staticClass: "One_Sigmatek_Info" }, [
                                _vm._v(_vm._s(_vm.SigmatekInfo.web)),
                              ]),
                              _c("p", { staticClass: "One_Sigmatek_Info" }, [
                                _vm._v(_vm._s(_vm.SigmatekInfo.Email)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "Info" }, [
                _c("div", { staticClass: "Info_RelevantInfo" }, [
                  _c("div", { staticClass: "Info_RelevantInfo_line" }, [
                    _c("div", { staticClass: "Info_RelevantInfo_Text" }, [
                      _vm._v("员工姓名"),
                    ]),
                    !_vm.PrintStatus
                      ? _c(
                          "div",
                          { staticClass: "Info_RelevantInfo_Write_Short" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "", size: "mini" },
                              model: {
                                value:
                                  _vm.ReportContentInfo.RelevantInfo
                                    .EmployeeName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ReportContentInfo.RelevantInfo,
                                    "EmployeeName",
                                    $$v
                                  )
                                },
                                expression:
                                  "ReportContentInfo.RelevantInfo.EmployeeName",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.PrintStatus
                      ? _c(
                          "div",
                          { staticClass: "Info_RelevantInfo_Print_Short" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.ReportContentInfo.RelevantInfo
                                    .EmployeeName
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c("div", {
                      staticClass: "Info_ReimbursementInstructions_Interval",
                    }),
                    _c("div", { staticClass: "Info_RelevantInfo_Text" }, [
                      _vm._v("所任职务"),
                    ]),
                    !_vm.PrintStatus
                      ? _c(
                          "div",
                          { staticClass: "Info_RelevantInfo_Write_Long" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "", size: "mini" },
                              model: {
                                value:
                                  _vm.ReportContentInfo.RelevantInfo.Position,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ReportContentInfo.RelevantInfo,
                                    "Position",
                                    $$v
                                  )
                                },
                                expression:
                                  "ReportContentInfo.RelevantInfo.Position",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.PrintStatus
                      ? _c(
                          "div",
                          { staticClass: "Info_RelevantInfo_Print_Long" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.ReportContentInfo.RelevantInfo.Position
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "Info_RelevantInfo_line" }, [
                    _c("div", { staticClass: "Info_RelevantInfo_Text" }, [
                      _vm._v("所在部门"),
                    ]),
                    !_vm.PrintStatus
                      ? _c(
                          "div",
                          { staticClass: "Info_RelevantInfo_Write_Short" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "", size: "mini" },
                              model: {
                                value:
                                  _vm.ReportContentInfo.RelevantInfo.Department,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ReportContentInfo.RelevantInfo,
                                    "Department",
                                    $$v
                                  )
                                },
                                expression:
                                  "ReportContentInfo.RelevantInfo.Department",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.PrintStatus
                      ? _c(
                          "div",
                          { staticClass: "Info_RelevantInfo_Print_Short" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.ReportContentInfo.RelevantInfo.Department
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c("div", {
                      staticClass: "Info_ReimbursementInstructions_Interval",
                    }),
                    _c("div", { staticClass: "Info_RelevantInfo_Text" }, [
                      _vm._v("电子邮件"),
                    ]),
                    !_vm.PrintStatus
                      ? _c(
                          "div",
                          { staticClass: "Info_RelevantInfo_Write_Long" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "", size: "mini" },
                              model: {
                                value: _vm.ReportContentInfo.RelevantInfo.Email,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ReportContentInfo.RelevantInfo,
                                    "Email",
                                    $$v
                                  )
                                },
                                expression:
                                  "ReportContentInfo.RelevantInfo.Email",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.PrintStatus
                      ? _c(
                          "div",
                          { staticClass: "Info_RelevantInfo_Print_Long" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.ReportContentInfo.RelevantInfo.Email
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "Info_RelevantInfo_line" }, [
                    _c("div", { staticClass: "Info_RelevantInfo_Text" }, [
                      _vm._v("部门经理"),
                    ]),
                    !_vm.PrintStatus
                      ? _c(
                          "div",
                          { staticClass: "Info_RelevantInfo_Write_Short" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "", size: "mini" },
                              model: {
                                value:
                                  _vm.ReportContentInfo.RelevantInfo.Manager,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ReportContentInfo.RelevantInfo,
                                    "Manager",
                                    $$v
                                  )
                                },
                                expression:
                                  "ReportContentInfo.RelevantInfo.Manager",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.PrintStatus
                      ? _c(
                          "div",
                          { staticClass: "Info_RelevantInfo_Print_Short" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.ReportContentInfo.RelevantInfo.Manager
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c("div", {
                      staticClass: "Info_ReimbursementInstructions_Interval",
                    }),
                    _c("div", { staticClass: "Info_RelevantInfo_Text" }, [
                      _vm._v("经理邮件"),
                    ]),
                    !_vm.PrintStatus
                      ? _c(
                          "div",
                          { staticClass: "Info_RelevantInfo_Write_Long" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "", size: "mini" },
                              model: {
                                value:
                                  _vm.ReportContentInfo.RelevantInfo
                                    .ManagerEmail,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ReportContentInfo.RelevantInfo,
                                    "ManagerEmail",
                                    $$v
                                  )
                                },
                                expression:
                                  "ReportContentInfo.RelevantInfo.ManagerEmail",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.PrintStatus
                      ? _c(
                          "div",
                          { staticClass: "Info_RelevantInfo_Print_Long" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.ReportContentInfo.RelevantInfo
                                    .ManagerEmail
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
                _c("div", { staticClass: "Info_ReimbursementInstructions" }, [
                  _c(
                    "div",
                    { staticClass: "Info_ReimbursementInstructions_Text" },
                    [_vm._v("报销说明")]
                  ),
                  !_vm.PrintStatus
                    ? _c(
                        "div",
                        { staticClass: "Info_ReimbursementInstructions_Write" },
                        [
                          _c("el-input", {
                            attrs: { size: "mini", placeholder: "请输入内容" },
                            model: {
                              value:
                                _vm.ReportContentInfo.ReimbursementInstructions,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ReportContentInfo,
                                  "ReimbursementInstructions",
                                  $$v
                                )
                              },
                              expression:
                                "ReportContentInfo.ReimbursementInstructions",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.PrintStatus
                    ? _c(
                        "div",
                        { staticClass: "Info_ReimbursementInstructions_Print" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.ReportContentInfo.ReimbursementInstructions
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "Money" }, [
                _c("div", { staticClass: "Money_Tittle" }, [
                  _c("div", [
                    _vm._v(" 经费明细 "),
                    !_vm.PrintStatus
                      ? _c(
                          "span",
                          { staticClass: "Money_Tittle_Button" },
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content: "增加一行",
                                  "open-delay": 1000,
                                  placement: "top",
                                  effect: "light",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    icon: "el-icon-plus",
                                    size: "mini",
                                    circle: "",
                                  },
                                  on: { click: _vm.addLine },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-style": "italic",
                          "font-size": "17px",
                          color: "black",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.ReportContentInfo.ArchiveLocation
                                .Items_ProjectCode
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]),
                !_vm.PrintStatus
                  ? _c(
                      "div",
                      { staticClass: "Money_Details_Write" },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            ref: "ReimbursementDetails",
                            attrs: {
                              data: _vm.ReportContentInfo.ReimbursementDetails,
                              "highlight-current-row": "",
                              id: "ReimbursementDetails",
                              "header-cell-style": {
                                height: "5mm",
                                fontsize: "3.5mm",
                                fontWeight: "bold",
                                backgroundColor: "#EBF0F9",
                                color: "#000000",
                              },
                              "cell-class-name": "maintable",
                              "cell-style": {
                                "font-size": "10px",
                                color: "#000",
                              },
                            },
                            on: { "current-change": _vm.onRowSelect },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "Date",
                                label: "日期",
                                align: "center",
                                "min-width": "30mm",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-date-picker", {
                                          staticClass: "table_Date",
                                          attrs: {
                                            "prefix-icon": "null",
                                            size: "mini",
                                            type: "date",
                                            placeholder: "选择日期",
                                            "value-format": "timestamp",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.DataChange(scope.row)
                                            },
                                          },
                                          model: {
                                            value: scope.row.Date,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "Date", $$v)
                                            },
                                            expression: "scope.row.Date",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3646739469
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "Type",
                                label: "科目",
                                align: "center",
                                "min-width": "24mm",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              effect: "light",
                                              content:
                                                "与类别绑定，无需自己填写",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass: "table_Type",
                                              attrs: {
                                                placeholder: "",
                                                size: "mini",
                                                disabled: true,
                                              },
                                              model: {
                                                value: scope.row.Type,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "Type",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.Type",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3628392356
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "Explain",
                                label: "说明",
                                align: "center",
                                "min-width": "65mm",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          staticClass: "table_Explain",
                                          attrs: {
                                            placeholder: "",
                                            size: "mini",
                                          },
                                          model: {
                                            value: scope.row.Explain,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "Explain",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.Explain",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1136205617
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "Classification",
                                label: "类别",
                                align: "center",
                                "min-width": "30mm",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              size: "mini",
                                              placeholder: "",
                                              clearable: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.ClassificationChange(
                                                  scope.row
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.Classification,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "Classification",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.Classification",
                                            },
                                          },
                                          _vm._l(
                                            _vm.ReimbursementCategory,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.label,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3771413156
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "Cost",
                                label: "费用",
                                align: "center",
                                "min-width": "26mm",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          staticClass: "table_Cost",
                                          attrs: {
                                            placeholder: "",
                                            size: "mini",
                                            dir: "rtl",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.CostChange(scope.row)
                                            },
                                          },
                                          model: {
                                            value: scope.row.Cost,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "Cost", $$v)
                                            },
                                            expression: "scope.row.Cost",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3956435034
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "",
                                label: "",
                                align: "center",
                                "min-width": "10mm",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-popover",
                                          {
                                            staticStyle: {
                                              "min-width": "15mm",
                                            },
                                            attrs: {
                                              placement: "right",
                                              trigger: "click",
                                              "popper-class": "Editpopper",
                                            },
                                            model: {
                                              value: scope.row.toolvisiable,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "toolvisiable",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.toolvisiable",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "EditButtonGroup",
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticClass: "EditButton",
                                                    attrs: { type: "danger" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.remove(
                                                          scope.$index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 删除此行 ")]
                                                ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticClass: "EditButton",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.MoveDown(
                                                          scope.$index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 下移此行 ")]
                                                ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticClass: "EditButton",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addRowBefore(
                                                          scope.$index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 在上方插入 ")]
                                                ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticClass: "EditButton",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addRowAfter(
                                                          scope.$index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 在下方插入 ")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("el-button", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    scope.row ===
                                                    _vm.selectedRow,
                                                  expression:
                                                    "scope.row === selectedRow",
                                                },
                                              ],
                                              staticClass: "ButtonGroup_Menu",
                                              attrs: {
                                                slot: "reference",
                                                icon: "el-icon-menu",
                                                type: "primary",
                                              },
                                              slot: "reference",
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1978232437
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.PrintStatus
                  ? _c(
                      "div",
                      { staticClass: "Money_Details_Print" },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            ref: "ReimbursementDetails",
                            attrs: {
                              data: _vm.ReportContentInfo.ReimbursementDetails,
                              id: "ReimbursementDetails",
                              "header-row-class-name":
                                _vm.Money_Details_Header_Print,
                              "cell-class-name": "maintable",
                              "cell-style": {
                                "font-size": "10px",
                                color: "#000",
                              },
                            },
                            on: { "current-change": _vm.onRowSelect },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "Date",
                                label: "日期",
                                align: "center",
                                "min-width": "30mm",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "p",
                                          { staticClass: "printcommon" },
                                          [_vm._v(_vm._s(scope.row.Date_Watch))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2067161954
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "Type",
                                label: "科目",
                                align: "center",
                                "min-width": "24mm",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "p",
                                          { staticClass: "printcommon" },
                                          [_vm._v(_vm._s(scope.row.Type))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3837208664
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "Explain",
                                label: "说明",
                                align: "left",
                                "header-align": "center",
                                "min-width": "50mm",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "p",
                                          { staticClass: "printcommon" },
                                          [_vm._v(_vm._s(scope.row.Explain))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2677492615
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "Classification",
                                label: "类别",
                                align: "left",
                                "header-align": "center",
                                "min-width": "30mm",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "p",
                                          { staticClass: "printcommon" },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.Classification)
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                224375350
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "Cost",
                                label: "费用",
                                align: "right",
                                "header-align": "center",
                                "min-width": "26mm",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "p",
                                          { staticClass: "printcommon" },
                                          [_vm._v(_vm._s(scope.row.Cost_Watch))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1454066013
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "Money_Aggregation" }, [
                  _c("div", { staticClass: "Money_Aggregation_Line" }, [
                    _c("div", { staticClass: "Money_Aggregation_Text" }, [
                      _vm._v("小计"),
                    ]),
                    !_vm.PrintStatus
                      ? _c(
                          "div",
                          { staticClass: "Money_Aggregation_Data_Write" },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "",
                                size: "mini",
                                dir: "rtl",
                                disabled: true,
                              },
                              model: {
                                value:
                                  _vm.ReportContentInfo.CostAggregation
                                    .Subtotal,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ReportContentInfo.CostAggregation,
                                    "Subtotal",
                                    $$v
                                  )
                                },
                                expression:
                                  "ReportContentInfo.CostAggregation.Subtotal",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.PrintStatus
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "Money_Aggregation_Data_Subtotal_Print",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.ReportContentInfo.CostAggregation
                                    .Subtotal_Watch
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "Money_Aggregation_Line" }, [
                    _c("div", { staticClass: "Money_Aggregation_Text" }, [
                      _vm._v("预支款"),
                    ]),
                    !_vm.PrintStatus
                      ? _c(
                          "div",
                          { staticClass: "Money_Aggregation_Data_Write" },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "",
                                size: "mini",
                                dir: "rtl",
                              },
                              on: { change: _vm.AdvanceWatchChange },
                              model: {
                                value:
                                  _vm.ReportContentInfo.CostAggregation.Advance,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ReportContentInfo.CostAggregation,
                                    "Advance",
                                    $$v
                                  )
                                },
                                expression:
                                  "ReportContentInfo.CostAggregation.Advance",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.PrintStatus
                      ? _c(
                          "div",
                          { staticClass: "Money_Aggregation_Data_Print" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.ReportContentInfo.CostAggregation
                                    .Advance_Watch
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "Money_Aggregation_Line" }, [
                    _c("div", { staticClass: "Money_Aggregation_Text" }, [
                      _vm._v("报销合计"),
                    ]),
                    !_vm.PrintStatus
                      ? _c(
                          "div",
                          { staticClass: "Money_Aggregation_Data_Write" },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "",
                                size: "mini",
                                disabled: true,
                                dir: "rtl",
                              },
                              model: {
                                value:
                                  _vm.ReportContentInfo.CostAggregation
                                    .TotalReimbursement,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ReportContentInfo.CostAggregation,
                                    "TotalReimbursement",
                                    $$v
                                  )
                                },
                                expression:
                                  "ReportContentInfo.CostAggregation.TotalReimbursement",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.PrintStatus
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "Money_Aggregation_Data_TotalReimbursement_Print",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.ReportContentInfo.CostAggregation
                                    .TotalReimbursement_Watch
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "Sign" }, [
                _c("div", { staticClass: "Sign_remarks" }, [
                  _c("div", { staticClass: "Sign_remarks_Text" }, [
                    _vm._v("备注"),
                  ]),
                  _c("div", { staticClass: "Sign_remarks_Input" }, [
                    !_vm.PrintStatus
                      ? _c(
                          "div",
                          { staticClass: "Sign_remarks_Input_Write" },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                autosize: "",
                                placeholder: "请输入内容",
                              },
                              model: {
                                value: _vm.ReportContentInfo.SignText,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ReportContentInfo,
                                    "SignText",
                                    $$v
                                  )
                                },
                                expression: "ReportContentInfo.SignText",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.PrintStatus
                      ? _c("div", { staticClass: "Sign_remarks_Input_Print" }, [
                          _vm._v(
                            " " + _vm._s(_vm.ReportContentInfo.SignText) + " "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm._m(0),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.radio == "出差补贴统计单"
        ? _c("div", { attrs: { id: "printArea_CCBTTJD" } }, [
            _c("div", { attrs: { id: "CCBTTJD_DataArea" } }, [
              _c("div", { staticClass: "CCBTTJD_Tittle" }, [
                _vm._m(1),
                _c("div", { staticClass: "CCBTTJD_Tittle_ExpenseCode" }, [
                  _c(
                    "div",
                    { staticClass: "CCBTTJD_Tittle_ExpenseCode_Left" },
                    [
                      _c(
                        "div",
                        { staticClass: "CCBTTJD_Tittle_ExpenseCode_Text" },
                        [_vm._v("报销单号：")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "CCBTTJD_Tittle_ExpenseCode_ExpenseCode",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.ReportContentInfo.ExpenseCode) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  ),
                  !_vm.PrintStatus
                    ? _c(
                        "div",
                        { staticClass: "CCBTTJD_Tittle_ExpenseCode_Right" },
                        [
                          _c("div", { staticStyle: { width: "110px" } }, [
                            _vm._v(" 日期快速选择： "),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "CCBTTJD_Tittle_ExpenseCode_QuickDateSelection",
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  size: "mini",
                                  type: "dates",
                                  "value-format": "timestamp",
                                  clearable: false,
                                },
                                on: { change: _vm.AssignDatesToData },
                                model: {
                                  value: _vm.QuickDateSelection,
                                  callback: function ($$v) {
                                    _vm.QuickDateSelection = $$v
                                  },
                                  expression: "QuickDateSelection",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "CCBTTJD_Date" },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      ref: "TravelSubsidyStatistics",
                      staticClass: "CCBTTJD_Date_Table",
                      attrs: {
                        data: _vm.ReportContentInfo.TravelSubsidyStatistics,
                        border: "",
                        id: "TravelSubsidyStatistics",
                        "header-row-class-name": _vm.CCBTTJD_Date_Header_Print,
                        "cell-class-name": "maintable",
                        "cell-style": {
                          height: "7mm",
                          "font-size": "12px",
                          padding: "0px",
                          color: "#000",
                          borderColor: "#C0C0C0",
                        },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "Date",
                          label: "日期",
                          align: "center",
                          width: "105px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  !_vm.PrintStatus
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              scope.row.Date
                                                ? _vm.timestampToYMD(
                                                    scope.row.Date
                                                  )
                                                : null
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.PrintStatus
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "CCBTTJD_Date_Data_Print",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(scope.row.Date_Watch) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          208965645
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "week",
                          label: "星期",
                          align: "center",
                          width: "90px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(" " + _vm._s(scope.row.week) + " "),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2774654465
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "Businesslocation",
                          label: "出差地",
                          align: "center",
                          width: "105px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.hasDate
                                    ? _c("div", [
                                        !_vm.PrintStatus
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticClass:
                                                      "CCBTTJD_Date_Businesslocation_Input_Write",
                                                    attrs: {
                                                      size: "mini",
                                                      placeholder: "",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.BusinesslocationChange(
                                                          scope.row
                                                        )
                                                      },
                                                      input: function ($event) {
                                                        return _vm.$forceUpdate()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row
                                                          .Businesslocation,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "Businesslocation",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.Businesslocation",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.LocalOrNot,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.id,
                                                        attrs: {
                                                          label: item.label,
                                                          value: item.label,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.PrintStatus
                                          ? _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    scope.row.Businesslocation
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2579390131
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "AccommodationOrNot",
                          label: "住宿",
                          align: "center",
                          width: "58px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.isField
                                    ? _c("div", [
                                        !_vm.PrintStatus
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticClass:
                                                      "CCBTTJD_Date_AccommodationOrNot_Input_Write",
                                                    attrs: {
                                                      size: "mini",
                                                      placeholder: "",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.AccommodationOrNotChange(
                                                          scope.row
                                                        )
                                                      },
                                                      input: function ($event) {
                                                        return _vm.$forceUpdate()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row
                                                          .AccommodationOrNot,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "AccommodationOrNot",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.AccommodationOrNot",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.YesOrNot,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.id,
                                                        attrs: {
                                                          label: item.label,
                                                          value: item.label,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.PrintStatus
                                          ? _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    scope.row.AccommodationOrNot
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  !scope.row.isField
                                    ? _c("div", [
                                        !_vm.PrintStatus
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "text-align": "left",
                                                  "margin-left": "5px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      scope.row
                                                        .AccommodationOrNot
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.PrintStatus
                                          ? _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    scope.row.AccommodationOrNot
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1820623722
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "TravelAllowance",
                          label: "补助",
                          align: "right",
                          "header-align": "center",
                          width: "98px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  !_vm.PrintStatus
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(scope.row.TravelAllowance) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.PrintStatus
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              scope.row.TravelAllowance_Watch
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3693531971
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "AssignToReimbursement",
                          type: "number",
                          label: "报销",
                          align: "right",
                          "header-align": "center",
                          width: "98px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.BaoXiaoJiSuan
                                    ? _c("div", [
                                        !_vm.PrintStatus
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-input", {
                                                  staticClass:
                                                    "CCBTTJD_Date_AssignToReimbursement_Input_Write",
                                                  attrs: {
                                                    placeholder: "",
                                                    size: "mini",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.AssignToReimbursementChange(
                                                        scope.row
                                                      )
                                                    },
                                                    input: function ($event) {
                                                      return _vm.$forceUpdate()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row
                                                        .AssignToReimbursement,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "AssignToReimbursement",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.AssignToReimbursement",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _vm.PrintStatus
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              scope.row
                                                .AssignToReimbursement_Watch
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2169748455
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "AssignTosalary",
                          type: "number",
                          label: "工资",
                          align: "right",
                          "header-align": "center",
                          width: "98px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.BaoXiaoJiSuan
                                    ? _c("div", [
                                        !_vm.PrintStatus
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-input", {
                                                  staticClass:
                                                    "CCBTTJD_Date_AssignTosalary_Input_Write",
                                                  attrs: {
                                                    placeholder: "",
                                                    size: "mini",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.ReimbursementAssignment(
                                                        scope.row
                                                      )
                                                    },
                                                    input: function ($event) {
                                                      return _vm.$forceUpdate()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.AssignTosalary,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "AssignTosalary",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.AssignTosalary",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _vm.PrintStatus
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              scope.row.AssignTosalary_Watch
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3330900142
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "CCBTTJD_Subtotal" }, [
                _c(
                  "table",
                  {
                    ref: "normalTable",
                    staticClass: "CCBTTJD_Subtotal_Table",
                    attrs: { border: "1" },
                  },
                  [
                    _c("tr", [
                      _c("td", { attrs: { colspan: "4" } }),
                      _c("td", { staticStyle: { width: "98px" } }, [
                        _vm._v("小计"),
                      ]),
                      _c("td", { staticStyle: { width: "98px" } }, [
                        !_vm.PrintStatus
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.ReportContentInfo
                                      .TravelSubsidySubtotal_AssignToReimbursement
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        _vm.PrintStatus
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.ReportContentInfo
                                      .TravelSubsidySubtotal_AssignToReimbursement_Watch
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c("td", { staticStyle: { width: "98px" } }, [
                        !_vm.PrintStatus
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.ReportContentInfo
                                      .TravelSubsidySubtotal_AssignTosalary
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        _vm.PrintStatus
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.ReportContentInfo
                                      .TravelSubsidySubtotal_AssignTosalary_Watch
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        { attrs: { id: "NewRoles" } },
        [
          _c(
            "el-collapse",
            {
              attrs: { accordion: "" },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-collapse-item",
                { attrs: { name: "1" } },
                [
                  _c(
                    "template",
                    { slot: "title" },
                    [
                      _c(
                        "el-tag",
                        {
                          staticStyle: { "margin-right": "5px" },
                          attrs: { size: "mini" },
                        },
                        [_vm._v(" Top ")]
                      ),
                      _vm._v(" 报销步骤 "),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("img", {
                        staticStyle: {
                          height: "380px",
                          width: "100%",
                          "object-fit": "contain",
                        },
                        attrs: {
                          src: require("../ProjectTemplate/ReimbursementSteps/BaoXiaoStepOnline.jpg"),
                        },
                      }),
                      _c(
                        "el-link",
                        {
                          attrs: {
                            href: "https://www.ilovepdf.com/zh-cn",
                            type: "primary",
                            target: "_blank",
                          },
                        },
                        [_vm._v("线上pdf编辑网址 ")]
                      ),
                      _c("img", {
                        staticStyle: {
                          height: "380px",
                          width: "100%",
                          "object-fit": "contain",
                        },
                        attrs: {
                          src: require("../ProjectTemplate/ReimbursementSteps/BaoXiaoStepOffline.jpg"),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "el-collapse-item",
                { attrs: { name: "2" } },
                [
                  _c(
                    "template",
                    { slot: "title" },
                    [
                      _c(
                        "el-tag",
                        {
                          staticStyle: { "margin-right": "5px" },
                          attrs: { size: "mini" },
                        },
                        [_vm._v(" New ")]
                      ),
                      _vm._v(" 经费明细-类别 各项目解释 "),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "padding-left": "50px" } },
                    [
                      _c("span", { staticStyle: { "line-height": "3" } }, [
                        _vm._v(" 报销类别可能会有争议点的部分在下方列出 "),
                      ]),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.ReimbursementRoles_Travel,
                            border: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "Type",
                              label: "类别（路费相关）",
                              width: "180",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "Describe", label: "解释" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%", "margin-top": "10px" },
                          attrs: {
                            data: _vm.ReimbursementRoles_Other,
                            border: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "Type",
                              label: "类别（其他）",
                              width: "180",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "Describe", label: "解释" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "el-collapse-item",
                { attrs: { title: "出差补贴填写", name: "3" } },
                [
                  _c("div", { staticStyle: { "padding-left": "50px" } }, [
                    _c("img", {
                      staticStyle: {
                        height: "200px",
                        width: "100%",
                        "padding-left": "50px",
                        "object-fit": "contain",
                      },
                      attrs: {
                        src: require("../ProjectTemplate/NewRules/出差报销填写.jpg"),
                      },
                    }),
                    _c("span", { staticStyle: { "line-height": "3" } }, [
                      _vm._v(
                        ' 1."出差补贴"这一栏以作为单独一项，合并在了经费明细中，需要和其他费用一起申报。'
                      ),
                      _c("br"),
                      _vm._v(
                        ' 2.要将"出差补贴"这一项至于经费明细的第一行或者最后一行。'
                      ),
                      _c("br"),
                      _vm._v(
                        " 3.如果是在第一行，日期需要填写出差第一天；如果是最后一行，日期需要填写出差最后一天。 "
                      ),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Sign_name" }, [
      _c("div", { staticClass: "One_Sign_name" }, [
        _c("div", { staticClass: "Sign_name_Text" }, [_vm._v("员工")]),
        _c("div", { staticClass: "Sign_name_Sign" }),
      ]),
      _c("div", { staticClass: "One_Sign_name" }, [
        _c("div", { staticClass: "Sign_name_Text" }, [_vm._v("复核")]),
        _c("div", { staticClass: "Sign_name_Sign" }),
      ]),
      _c("div", { staticClass: "One_Sign_name" }, [
        _c("div", { staticClass: "Sign_name_Text" }, [_vm._v("财务")]),
        _c("div", { staticClass: "Sign_name_Sign" }),
      ]),
      _c("div", { staticClass: "One_Sign_name" }, [
        _c("div", { staticClass: "Sign_name_Text" }, [_vm._v("审批")]),
        _c("div", { staticClass: "Sign_name_Sign" }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "CCBTTJD_Tittle_Logo" }, [
      _c("img", { attrs: { src: require("../../../Img/log.jpg") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }