<template>
  <div>
    <div class="pull-right right_10px top_10px">
      <el-button type="primary" @click="addSuit">添加</el-button>
    </div>
    <div>
      <el-table :data="suits" style="width: 100%" v-loading="loading" :default-sort="{prop: 'SigmatekOrderNumber', order: 'ascending'}">
        <el-table-column type="index" label="序号" width="50" align="left">
        </el-table-column>
        <el-table-column prop="suitName" label="套装名称" align="left">
        </el-table-column>
        <el-table-column prop="suitName_English" label="英文名称" align="left">
        </el-table-column>
        <el-table-column prop="orderNumber" label="客户订货号" width="180" align="left">
        </el-table-column>
        <el-table-column prop="SigmatekOrderNumber" label="Sigmatek订货号" width="180" align="left">
        </el-table-column>
        <!-- 操作按钮栏 -->
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button size="mini" @click="editSuit(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="DeleteSuit(scope.row._id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <el-dialog title="添加套装" :visible.sync="addSuitVisialbe" width="50%">
        <el-form :model="SuitInfo" label-width="150px" ref="addSuitForm">
          <el-form-item prop="suitName" label="套装名" :rules="[
            {required: true, message: '请输入套装名', trigger: 'blur'},
          ]">
            <el-input v-model="SuitInfo.suitName"></el-input>
          </el-form-item>
          <el-form-item prop="suitName_English" label="英文套装名" :rules="[
            {required: true, message: '请输入套装名', trigger: 'blur'},
          ]">
            <el-input v-model="SuitInfo.suitName_English"></el-input>
          </el-form-item>
          <el-form-item prop="orderNumber" label="客户订货号" :rules="[
            {required: true, message: '请输入订货号', trigger: 'blur'},
          ]">
            <el-input v-model="SuitInfo.orderNumber"></el-input>
          </el-form-item>
          <el-form-item prop="SigmatekOrderNumber" label="Sigmatek订货号" :rules="[
            {required: true, message: '请输入订货号', trigger: 'blur'},
          ]">
            <el-input v-model="SuitInfo.SigmatekOrderNumber"></el-input>
          </el-form-item>
          <el-form-item v-for="(product, index) in SuitInfo.products" :label="'产品' + index" :key="index" :prop="product.productid">
            <el-select v-model="product.productid" placeholder="请选择">
              <el-option v-for="item in Allproducts" :key="item._id" :label="item.name" :value="item._id">
              </el-option>
            </el-select>
            <span class="countinputer"><el-input v-model="product.count"></el-input></span>
            <el-button @click="deleteProduct(index)">删除</el-button>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addSuitVisialbe = false">取 消</el-button>
          <el-button @click="addproduct">新增产品</el-button>
          <el-button type="primary" @click="submitForm()">保 存</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { GetAllProducts } from "@/api/mesproductinfo";
import { createsuit, updatesuit, getsuitlist, deletesuit } from "@/api/suit";
export default {
  data()
  {
    return {
      Allproducts: [],
      suits: [],
      addSuitVisialbe: false,
      allsuitcount: 0,
      SuitInfo: {
        products: [],
        suitName: "",
        orderNumber: "",
      },
      loading: false
    };
  },
  mounted()
  {
    this.getProducts();
    this.getsuitlist();
  },
  methods: {
    getProducts()
    {
      GetAllProducts().then((res) =>
      {
        this.Allproducts = res.data.list;
      });
    },
    getsuitlist()
    {
      this.loading = true;
      getsuitlist().then((res) =>
      {
        this.suits = res.data.list;
        this.allsuitcount = res.data.total;
        this.loading = false;
      });
    },
    addSuit()
    {
      this.addSuitVisialbe = true;
      this.SuitInfo = {
        products: [],
        suitName: "",
        orderNumber: "",
      };
    },
    addproduct()
    {
      this.SuitInfo.products.push({
        productid: "",
        count: 1
      });
    },
    deleteProduct(index)
    {
      this.SuitInfo.products.splice(index, 1);
    },
    submitForm()
    {
      //1.验证表单规则
      this.$refs.addSuitForm.validate((valid) =>
      {
        if (valid) {
          //2.提交表单
          if (this.SuitInfo._id) {
            updatesuit(this.SuitInfo).then((res) =>
            {
              this.$message({
                message: "更新成功",
                type: "success",
              });
              this.getsuitlist();
              this.addSuitVisialbe = false;
            });
          } else {
            createsuit(this.SuitInfo).then((res) =>
            {
              this.$message({
                message: "添加成功",
                type: "success",
              });
              this.getsuitlist();
              this.addSuitVisialbe = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    editSuit(row)
    {
      this.addSuitVisialbe = true;
      //深拷贝
      this.SuitInfo = JSON.parse(JSON.stringify(row));
    },
    DeleteSuit(id)
    {
      deletesuit({
        id: id
      }).then((res) =>
      {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.getsuitlist();
      });
    }
  },
};
</script>

<style scoped>
.pull-right {
  float: right;
}

.right_10px {
  margin-right: 10px;
}

.top_10px {
  margin-top: 10px;
}

.countinputer {
  width: 100px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.countinputer>>>.el-input__inner {
  width: 100px !important;
  display: inline-block !important;
}
</style>