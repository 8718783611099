<template>
  <div class="app-container">
    <el-button type="primary" v-on:click="showInfo(null)">新建</el-button>
    <el-button :loading="downloadLoading" style="margin: 0 0 20px 20px" type="primary" icon="el-icon-document" @click="handleDownload">
      Export Excel
    </el-button>
    <el-button v-on:click="showExcelUpload = true">导入</el-button>
    <el-input v-model="Search" placeholder="搜索" class="Fuzzy_search"></el-input>
    <el-button type="primary" v-on:click="FuzzySearch">搜索</el-button>
    <el-dialog :visible.sync="showExcelUpload" title="导入">
      <upload-excel-component :on-success="handleSuccess" :before-upload="beforeUpload" />
      <el-table :data="tableData" border highlight-current-row style="overflow: scroll; height: 1000px; width: 100%; margin-top: 20px">
        <el-table-column v-for="item of tableHeader" :key="item" :prop="item" :label="item" />
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-on:click="bulkSave">添加</el-button>
        <!-- <el-button type="primary" v-on:click="bulkUpdate">更新</el-button> -->
      </span>
    </el-dialog>

    <el-dialog :visible.sync="showComponentsInfo" title="物料信息">
      <el-form :model="componentsInfo" label-width="80px">
        <el-form-item label="订货号">
          <el-input :disabled="TokenConfirm && !add_components" v-model="componentsInfo.number"></el-input>
        </el-form-item>
        <el-form-item label="物料型号">
          <el-input :disabled="TokenConfirm && !add_components" v-model="componentsInfo.parts"></el-input>
        </el-form-item>
        <el-form-item label="物料名称">
          <el-input :disabled="TokenConfirm && !add_components" v-model="componentsInfo.Id"></el-input>
        </el-form-item>
        <el-form-item label="价格">
          <el-input v-model="componentsInfo.price"></el-input>
        </el-form-item>
        <el-form-item label="毛量">
          <el-input v-model="componentsInfo.weight"></el-input>
        </el-form-item>
        <el-form-item label="净量">
          <el-input v-model="componentsInfo.netweight"></el-input>
        </el-form-item>
        <el-form-item label="HScode">
          <el-input v-model="componentsInfo.HScode"></el-input>
        </el-form-item>
        <el-form-item label="英文名">
          <el-input v-model="componentsInfo.EngName"></el-input>
        </el-form-item>
        <el-form-item label="注释">
          <el-input v-model="componentsInfo.Comment" type="textarea" :rows="2"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-on:click="saveInfo">保存</el-button>
        <el-button type="primary" v-on:click="otherSaveInfo">另存为</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="showPreblemInfo" title="问题汇总">
      <el-form :model="ProblemInfo" label-width="80px">
        <el-form-item class="formNum" label="订货号:">
          {{ ProblemInfo.Number }}
        </el-form-item>
        <el-form-item style="border-right:1px" class="formParts" label="产品:">
          {{ ProblemInfo.Name }}
        </el-form-item>
        <el-form-item class="formBtn">
          <el-popover placement="bottom" title="" ref="popover" width="300" trigger="click">

            <el-input style="margin-bottom: 10px;margin-left:10px;display: inline-block;width: 250px;" v-model="newProduce" placeholder="请输入问题"></el-input>

            <el-button type="primary" size="small" class="autogetbutton" round @click="addProduce">确定</el-button>
            <el-button type="primary" size="small" class="autogetbutton" round @click="cancelAdd">取消</el-button>

            <el-button type="primary" slot="reference">增加问题</el-button>
          </el-popover>
          <!-- <el-button type="primary">添加问题</el-button> -->
        </el-form-item>
      </el-form>

      <div v-for="(value, name) of ProblemInfo.Problem" :key="name">
        <div style="position: relative">
          <h4 style="margin-left: 10px;display: inline-block;margin-right: 20px;">{{ name }}</h4>
          <el-button type="primary" size="small" icon="el-icon-plus" circle style="margin-right:10px;" @click="addProblem(name)"></el-button>
          <el-popconfirm title="请确认删除" @confirm="deleteProblem(name)">
            <el-button slot="reference" type="danger" icon="el-icon-delete" circle size="small"></el-button>
          </el-popconfirm>
        </div>
        <el-card shadow="hover" class="classcard">
          <div id="cardclass" v-for="(item, indexs) in value" :key="indexs">
            <el-input v-model="value[indexs]" placeholder="请输入问题" clearable></el-input>
            <el-popconfirm title="请确认删除" @confirm="deleteclass(name, indexs)">
              <el-button slot="reference" type="danger" icon="el-icon-delete" circle size="small"></el-button>
            </el-popconfirm>
          </div>

        </el-card>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hideOderInfo">取 消</el-button>
        <el-button type="primary" @click="updateList">确 定</el-button>
      </span>

    </el-dialog>

    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" border fit highlight-current-row :default-sort="{order: 'descending'}">
      <el-table-column align="left" label="ID" width="95">
        <template slot-scope="scope">
          {{ scope.$index + (listQuery.page - 1) * listQuery.limit + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="物料名称" align="left">
        <template slot-scope="scope">
          {{ scope.row.Id }}
        </template>
      </el-table-column>
      <el-table-column label="物料型号" align="left">
        <template slot-scope="scope">
          {{ scope.row.parts }}
        </template>
      </el-table-column>
      <el-table-column align="left" label="订货号">
        <template slot-scope="scope">
          <span>{{ scope.row.number }}</span>
        </template>
      </el-table-column>
      <el-table-column label="最新硬件版本" align="left">
        <template slot-scope="scope">
          <el-button type="text" @click="ShowHardwareHisotorDialog(scope.row)">{{ scope.row.CurrentVersion }}</el-button>
        </template>
      </el-table-column>
      <el-table-column align="left" label="价格">
        <template slot-scope="scope">
          <span>{{ scope.row.price }}</span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="毛量">
        <template slot-scope="scope">
          <span>{{ scope.row.weight }}</span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="净量">
        <template slot-scope="scope">
          <span>{{ scope.row.netweight }}</span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="HScode">
        <template slot-scope="scope">
          <span>{{ scope.row.HScode }}</span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="英文名">
        <template slot-scope="scope">
          <span>{{ scope.row.EngName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="产品文档" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="showfile(scope.row)">附件</el-button>
        </template>
      </el-table-column>
      <el-table-column label="问题" align="center">
        <template slot-scope="scope">
          <el-button @click="showProblem(scope.row)" type="button">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="200">
        <template slot-scope="scope">
          <el-button v-on:click="showInfo(scope.row)" type="button">编辑</el-button>
          <el-button :disabled="TokenConfirm" v-on:click="showDeleteInfo(scope.row)" type="button">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="showDelete" title="删除">
      <h4>确定删除</h4>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-on:click="deleteInfo">确定</el-button>
        <el-button type="normal" v-on:click="showDelete = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="硬件变更信息" :visible.sync="ShowHardwareHisotory" width="80%">
      <h1>{{ componentsInfo.parts }}</h1>
      <div class="buttongroup"><el-button type="primary" icon="el-icon-plus" circle size="mini" @click="addhistrory"></el-button></div>
      <el-table :data="componentsInfo.historyrecord" style="width: 100%" max-height="600">
        <el-table-column label="版本" align="center" width="120">
          <template slot-scope="scope">
            <div class="version">
              <el-input v-model="scope.row.Version" placeholder="版本号"></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="发布日期" align="center" width="180">
          <template slot-scope="scope">
            <div class="time">
              <el-date-picker v-model="scope.row.Date" type="date" size="small" placeholder="选择日期时间" value-format="timestamp">
              </el-date-picker>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="起始序列号" align="center" width="150">
          <template slot-scope="scope">
            <div class="version">
              <el-input v-model="scope.row.serial" placeholder="序列号"></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="说明">
          <template slot-scope="scope">
            <div class="version">
              <el-input type="textarea" autosize v-model="scope.row.explanation" placeholder="说明"></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="150">
          <template slot-scope="scope">

            <el-button @click.native.prevent="deletehistory(scope.$index)" type="text" size="small">
              移除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="savebutton">
        <el-button type="primary" v-on:click="savehardward">保存 </el-button>
      </div>
      <div style="clear: both; height: 0; overflow: hidden"></div>
    </el-dialog>
    <el-dialog :visible.sync="Showannex">
      <el-upload action="''" multiple accept=".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PBG, .GIF, .pdf" :http-request="submitList" :file-list="componentsInfo.appendix" :show-file-list="true"
        :on-remove="handleRemove" :before-remove="beforeRemove" :on-preview="handlePreview">
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
    </el-dialog>
    <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="fetchData" />
  </div>
</template>

<script>
import { uploadExtra } from "@/api/article";
import { Message } from "element-ui";
import { download, deleteFile } from '@/api/table';
import { getInfobyname } from "@/api/user";
import { getName } from "@/utils/auth";
import { getsummarylist, createsummarylist, deletesummary, updatesummary } from "@/api/summary";
import Pagination from "@/components/Pagination";
import UploadExcelComponent from "@/components/UploadExcel/index.vue";
import
{
  fetchList,
  fetchArticle,
  fetchPv,
  createArticle,
  updateArticle,
  deleteArticle,
  getComponentsList,
  createComponentsList,
  updateComponentsList,
  deleteComponentsList,
  bulkUpdate1,
} from "@/api/article";
export default {
  components: { Pagination, UploadExcelComponent },
  filters: {
    statusFilter(status)
    {
      const statusMap = {
        published: "success",
        draft: "gray",
        deleted: "danger",
      };
      return statusMap[status];
    },
  },
  data()
  {
    return {
      list: null,
      IsaddProduce: false,
      newProduce: null,
      ProblemInfo: {
        Number: null,
        Name: null,
        Problem: {}
      },
      summarySearch: {
        page: 1,
        limit: 1,
        filters: {}
      },
      add_components: false,
      TokenConfirm: true,
      showComponentsInfo: false,
      showPreblemInfo: false,
      showDelete: false,
      componentsInfo: {
        _id: null,
        Id: null,
        number: null,
        badNr: null,
        newNr: null,
        price: null,
        Comment: null,
        model: null,
        parts: null,
        Problem: {},
        CurrentVersion: "1.0",
        historyrecord: [
          {
            Version: "0.0",
            Date: "2022.05.07",
            serial: "111111",
            explanation: "这是说明",
          },
        ],
        appendix: [
          {
            name: "",
            url: "",
          },
        ],
      },
      ExcelList: [],
      listLoading: true,
      total: 40,
      downloadLoading: false,
      showExcelUpload: false,
      tableData: [],
      tableHeader: [],
      total: 40,
      listQuery: {
        page: 1,
        limit: 10,
        sort: null,
        filters: {},
      },
      Search: null,
      ShowHardwareHisotory: false,
      activeNames: ["0"],
      Showannex: false,
      EditFlag: false,
    };
  },
  created()
  {
    this.RelevantInfo_AutoFill();
    this.fetchData();
  },
  watch: {
    showComponentsInfo(newValue)
    {
      if (!newValue) {
        this.EditFlag = false;
        this.add_components = false;
      }
    }
  },
  methods: {
    async fetchData()
    {
      this.listLoading = true;
      const { data } = await getComponentsList(this.listQuery);
      this.list = data.list;
      this.total = data.total;
      this.list.forEach((item) =>
      {
        //没有CurrentVersion的都加上"0.0"
        if (item.CurrentVersion == undefined || item.CurrentVersion == null) {
          item.CurrentVersion = "NoVisionList";
        }
      });
      //     // 对this.list按number的首字符进行排序
      //   this.list.sort((a, b) => {
      //     return a.number.localeCompare(b.number);
      //   });
      setTimeout(() =>
      {
        this.listLoading = false;
      }, 0.5 * 1000);
    },
    showInfo(data)
    {
      this.showComponentsInfo = true;
      if (data == null) {
        this.componentsInfo = {
          _id: null,
          Id: null,
          number: null,
          badNr: null,
          newNr: null,
          price: null,
          Comment: null,
          Problem: {},
          model: null,
          parts: null,
        };
        this.add_components = true;
      } else {
        this.componentsInfo = data;
        this.EditFlag = true;
      //  console.log(this.EditFlag);
      }
    },
    async RelevantInfo_AutoFill()
    {
      let name = getName();
      const { data } = await getInfobyname(name);
      let userinfo = data.list[0];
      if (userinfo.token === "Admin-token" || userinfo.token === "repair") {
        this.TokenConfirm = false;
      } else {
        this.TokenConfirm = true;
      }
    },
    async saveInfo()
    {
      if (this.componentsInfo._id == null) {
        const { data } = await createComponentsList(this.componentsInfo);
        //   var a =data ;
        if (data) {
          Message({
            message: "创建成功",
            type: "success",
            duration: 5 * 1000,
          });
        }
      } else {
        const { data } = await updateComponentsList({
          id: this.componentsInfo._id,
          data: {
            Id: this.componentsInfo.Id,
            number: this.componentsInfo.number,
            badNr: this.componentsInfo.badNr,
            newNr: this.componentsInfo.newNr,
            price: this.componentsInfo.price,
            weight: this.componentsInfo.weight,
            netweight: this.componentsInfo.netweight,
            HScode: this.componentsInfo.HScode,
            Comment: this.componentsInfo.Comment,
            model: this.componentsInfo.model,
            parts: this.componentsInfo.parts,
            CurrentVersion: this.componentsInfo.CurrentVersion,
            historyrecord: this.componentsInfo.historyrecord,
            appendix: this.componentsInfo.appendix,
            EngName: this.componentsInfo.EngName
          },
        });
      }
      this.showComponentsInfo = false;
      this.fetchData();
    },
    async otherSaveInfo()
    {
      const { data } = await createComponentsList({
        Id: this.componentsInfo.Id,
        number: this.componentsInfo.number,
        badNr: this.componentsInfo.badNr,
        newNr: this.componentsInfo.newNr,
        Comment: this.componentsInfo.Comment,
        price: this.componentsInfo.price,
        model: this.componentsInfo.model,
        parts: this.componentsInfo.parts,
        CurrentVersion: this.componentsInfo.CurrentVersion,
        historyrecord: this.componentsInfo.historyrecord,
        appendix: this.componentsInfo.appendix,
        EngName: this.componentsInfo.EngName
      });
      //   var a =data ;
      if (data) {
        Message({
          message: "创建成功",
          type: "success",
          duration: 5 * 1000,
        });
      }

      this.showComponentsInfo = false;
      this.fetchData();
    },
    showDeleteInfo(data)
    {
      this.componentsInfo._id = data._id;
      this.showDelete = true;
    },
    async deleteInfo()
    {
      const { data } = await deleteComponentsList({
        id: this.componentsInfo._id,
      });
      if (data) {
        Message({
          message: "删除成功",
          type: "success",
          duration: 5 * 1000,
        });
      }

      this.showDelete = false;
      this.fetchData();
    },
    showfile(input)
    {
      this.componentsInfo = input;
      this.Showannex = true;
    },
    async handleDownload()
    {
      this.downloadLoading = true;
      this.ExcelList = [];
      const { data } = await getComponentsList(this.listQuery);
      for (var i = 0; i < data.list.length; i++) {
        let obj = {};
        this.ExcelList.push(obj);
        Object.assign(this.ExcelList[i], data.list[i]);
      }
      //console.log(this.ExcelList);
      import("@/vendor/Export2Excel").then((excel) =>
      {
        const tHeader = [
          "Id",
          "number",
          "badNr",
          "newNr",
          "price",
          "Comment",
          "parts",
        ];
        const filterVal = [
          "Id",
          "number",
          "badNr",
          "newNr",
          "price",
          "Comment",
          "parts",
        ];
        const list = this.ExcelList;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.filename,
          autoWidth: this.autoWidth,
          bookType: this.bookType,
        });
        this.downloadLoading = false;
      });
    },
    formatJson(filterVal, jsonData)
    {
      return jsonData.map((v, index) =>
        filterVal.map((j) =>
        {
          if (j === "_id") {
            return index + 1;
          } else if (j.toString().indexOf(".") != -1) {
            let a = j.split(".");
            if (a[1] == "Comment") {
              var string = "";
              var num = 13;
              for (var i = 0; i < v[a[0]].length; i++) {
                if (v[a[0]][i][a[1]] !== null) {
                  string += v[a[0]][i][a[1]];
                  string += ",";
                }
              }
              return string.substring(0, string.length - 1);
            } else {
              return v[a[0]][a[1]];
            }
          } else {
            return v[j];
          }
        })
      );
    },
    handleSuccess({ results, header })
    {
      this.tableData = results;
      this.tableHeader = header;
    },
    beforeUpload(file)
    {
      const isLt1M = file.size / 1024 / 1024 < 10;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning",
      });
      return false;
    },
    ShowHardwareHisotorDialog(input)
    {
      this.componentsInfo = input;
      if (input.historyrecord == undefined || input.historyrecord == null) {
        this.componentsInfo.historyrecord = [
          {
            Version: "",
            explanation: "",
          },
        ];
      } else {
        if (input.historyrecord.length == 0) {
          this.componentsInfo.historyrecord = [
            {
              Version: "",
              explanation: "",
            },
          ];
        }
      }
      this.ShowHardwareHisotory = true;
    },
    async bulkSave()
    {
      var datalist = [];

      for (var i = 0; i < this.tableData.length; i++) {
        datalist.push({
          insertOne: {
            document: {
              Id: this.tableData[i][this.tableHeader[0]],
              number: this.tableData[i][this.tableHeader[1]],
              badNr: this.tableData[i][this.tableHeader[2]],
              newNr: this.tableData[i][this.tableHeader[3]],
              price: this.tableData[i][this.tableHeader[4]],
              Comment: this.tableData[i][this.tableHeader[5]],
              parts: this.tableData[i][this.tableHeader[6]],
            },
          },
        });
      }
      const { data } = await bulkUpdate1({
        data: datalist,
      });
      if (data) {
        Message({
          message: "保存成功",
          type: "success",
          duration: 5 * 1000,
        });
      }
      this.showExcelUpload = false;
      this.fetchData();
    },
    addhistrory()
    {
      let obj = {
        Version: "",
        explanation: "",
      };
      this.componentsInfo.historyrecord.splice(0, 0, obj);
    },
    deletehistory(i)
    {
      if (this.componentsInfo.historyrecord.length == 1) {
        return;
      }
      this.componentsInfo.historyrecord.splice(i, 1);
    },
    savehardward()
    {
      //筛选去掉空的
      this.componentsInfo.historyrecord =
        this.componentsInfo.historyrecord.filter((item) =>
        {
          if (item.Version.length > 0 && item.Date != undefined) {
            return 1;
          }
        });
      //排序
      this.componentsInfo.historyrecord.sort(function (a, b)
      {
        return new Date(parseInt(b.Date)) - new Date(parseInt(a.Date));
      });
      //赋予新的硬件号
      if (this.componentsInfo.historyrecord.length > 0)
        this.componentsInfo.CurrentVersion =
          this.componentsInfo.historyrecord[0].Version;
      this.saveInfo();
      this.ShowHardwareHisotory = false;
    },
    FuzzySearch()
    {
      if (this.Search == null || this.Search == "") {
        this.listQuery.filters = {};
      } else {
        this.listQuery.filters =
          "({$or:[{Id:{$regex:/^.*" +
          this.Search +
          ".*$/,$options: 'i'}},{number:{$regex:/^.*" +
          this.Search +
          ".*$/,$options: 'i'}},{badNr:{$regex:/^.*" +
          this.Search +
          ".*$/,$options: 'i'}},{newNr:{$regex:/^.*" +
          this.Search +
          ".*$/,$options: 'i'}},{price:{$regex:/^.*" +
          this.Search +
          ".*$/,$options: 'i'}},{Comment:{$regex:/^.*" +
          this.Search +
          ".*$/,$options: 'i'}},{parts:{$regex:/^.*" +
          this.Search +
          ".*$/,$options: 'i'}}]})";
      }
      this.fetchData();
    },
    async submitList(param)
    {
      var isreaper = false;
      for (var i = 0; i < this.componentsInfo.appendix.length; i++) {
        if (this.componentsInfo.appendix[i].name == param.file.name) {
          isreaper = true;
          break;
        }
      }
      if (!isreaper) {
        const formData = new FormData();
        formData.append("ModleName", param.file); // 要提交给后台的文件
        formData.append("projectFolder", this.componentsInfo._id); // 这个接口必要的项目id
        formData.append("subFolder", null);
        const { data } = await uploadExtra(formData, {
          path: this.componentsInfo._id,
        });
        if (data == "success") {
          let obj = {
            name: param.file.name,
            url: "file\\" + this.componentsInfo._id + "\\" + param.file.name,
          };
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.componentsInfo.appendix.push(obj);
          this.saveInfo();
        } else {
          this.$message({
            message: "上传失败",
            type: "warning",
          });
        }
      }
      else {
        this.$message({
          message: "已存在该文件",
          type: "warning",
        });
      }
    },
    async handleRemove(file, fileList)
    {
      const { data } = await deleteFile({
        id: this.componentsInfo._id,
        data: file.url
      });
      if (data == "success") {
       // console.log(file);
        for (let i = 0; i < this.componentsInfo.appendix.length; i++) {
          if (this.componentsInfo.appendix[i].name == file.name) {
            this.componentsInfo.appendix.splice(i, 1);
          }
        }
       // console.log(this.componentsInfo.appendix);
        this.saveInfo();
      }
    },
    beforeRemove(file, fileList)
    {
      return this.$confirm(`确定移除 ${file.name}`);
    },
    handlePreview(file)
    {
      this.$confirm(`确定下载 ${file.name}`).then(async res =>
      {
        const data = await download({
          path: file.url,
          filename: file.name
        });
        var url = window.URL.createObjectURL(data);
        var a = document.createElement('a');
        a.href = url;
        a.download = file.name;
        a.click();
        window.URL.revokeObjectURL(url);
      });
    },
    async showProblem(val)
    {
      if (val == null) {
        this.componentsInfo = {
          _id: null,
          Id: null,
          number: null,
          badNr: null,
          newNr: null,
          price: null,
          Comment: null,
          Problem: {},
          model: null,
          parts: null,
        };
      } else {
        let Producename = val.parts;
        let ProduceId = val.number;
        this.summarySearch.filters =
          "({'Name':{$regex:'" + Producename + "'},'Number':{$regex:'" + ProduceId + "'}})";

        // console.log(this.summarySearch);
        const { data } = await getsummarylist(this.summarySearch);
        if (data.total >= 1) {
          this.ProblemInfo = data.list[0];
        } else {
          this.ProblemInfo.Name = val.parts;
          this.ProblemInfo.Number = val.number;
          this.ProblemInfo.Problem = {};
        }

      }

      this.showPreblemInfo = true;
    },
    addProblem(name)
    {
      let ProblemArr = this.ProblemInfo.Problem[name];
      ProblemArr.push(''),
        // this.ProblemInfo.Problem[name].push("");
        this.$set(this.ProblemInfo.Problem, name, ProblemArr);
      //console.log(this.ProblemInfo);
      // this.$forceUpdate();
    },
    deleteProblem(name)
    {
      delete this.ProblemInfo.Problem[name];
      this.$forceUpdate(); //强制刷新显示，防止因嵌套过多出现数据修改但是界面未显示的情况
    },
    deleteclass(name, index)
    {
      this.ProblemInfo.Problem[name].splice(index, 1);
      this.$forceUpdate();
    },
    hideOderInfo()
    {
      this.showPreblemInfo = false;
      // this.getSummaryInfo();
    },
    async updateList()
    {
      const updateData = {
        id: this.ProblemInfo._id,
        data: {
          Name: this.ProblemInfo.Name,
          Number: this.ProblemInfo.Number,
          // Produce: this.oderInfo.Produce,
          Problem: this.ProblemInfo.Problem
        }

      };
      let data;
      if (updateData.id == undefined) {
        data = await createsummarylist(updateData.data);
      } else {
        data = await updatesummary(updateData);
      }

      if (data) {
        this.$message({
          message: "保存成功",
          type: "success",
        });
        // this.getSummaryInfo();
        this.showPreblemInfo = false;
      }
    },
    cancelAdd()
    {
      this.$refs.popover.doClose();
    },
    addProduce()
    {
      if (this.ProblemInfo.Problem == undefined) {
        this.ProblemInfo["Problem"] = {};
      }
      if (this.ProblemInfo.Problem.hasOwnProperty(this.newProduce) == false && this.newProduce !== null && this.newProduce !== '') {
        // this.ProblemInfo.Problem[this.newProduce]=[''];
        this.$set(this.ProblemInfo.Problem, this.newProduce, ['']);
        this.Problems = this.ProblemInfo.Problem;
        this.newProduce = null;
        this.$refs.popover.doClose();
       // console.log(this.ProblemInfo);
        // this.$forceUpdate();
      } else if (this.ProblemInfo.Problem.hasOwnProperty(this.newProduce) == true) {
        this.$message({
          showClose: true,
          message: "已存在此描述！请勿重复操作！",
          type: "warning"
        });
      } else if (this.newProduce == null || this.newProduce == '') {
        this.$message({
          showClose: true,
          message: "请输入具体描述",
          type: "warning"
        });
      }
    },
  },
};
</script>
<style scoped>
span {
  display: inline-block;
}

#filterSelect {
  position: absolute;
  top: 20px;
  left: 30px;
  width: 80%;
  height: 800px;
  background: white;
}

.app-container {
  position: relative;
}

p {
  margin-top: 7px;
  margin-bottom: 7px;
}

div>>>.Fuzzy_search {
  margin-left: 10px;
  margin-right: 5px;
  display: inline-block;
  width: 300px;
}

.savebutton {
  margin-top: 10px;
  margin-right: 10px;
  float: right;
}

.version>>>.el-input__inner {
  border: 0;
  text-align: center;
}

.version>>>.el-textarea__inner {
  border: 0;
}

.time>>>.el-date-editor {
  width: 160px;
}

.buttongroup {
  float: right;
  margin-right: 20px;
}

.title {
  display: inline-block;
}

.formNum {
  display: inline-block !important;
  width: 20%;
}

.formBtn {
  display: inline-block;
  width: 20%;
}

.formParts {
  display: inline-block;
  width: 60%;
}

.autogetbutton {
  display: inline-block;
  margin-left: 10px;
}

.classcard {
  margin-top: 5px;
}

.classcard>>>.el-card__body {
  padding: 0px;
}

#cardclass>>>.el-input__inner {
  border-bottom: 1;
  border-left: 0;
  border-right: 0;
  border-top: 0;
}

#cardclass>>>.el-input {
  display: inline-block;
  width: calc(100% - 60px);
}
</style>
