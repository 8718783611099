var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "button_group underline" },
        [
          _c("el-input", {
            staticClass: "inline _250px",
            attrs: {
              placeholder: "序列号搜索",
              "prefix-icon": "el-icon-search",
            },
            on: {
              change: function ($event) {
                return _vm.SeachChange()
              },
            },
            model: {
              value: _vm.filter.invoiceNumber,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "invoiceNumber", $$v)
              },
              expression: "filter.invoiceNumber",
            },
          }),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.CreateForm } },
            [_vm._v("新建")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.vLoading,
              expression: "vLoading",
            },
          ],
          key: "tableKey",
          attrs: { id: "table" },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.packingandinvoiceList },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "invoiceNumber",
                  label: "单号",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "Desc", label: "备注", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "CreativeTime",
                  label: "创建时间",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.convertToDate(scope.row.CreativeTime))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "UPSNumber", label: "UPS单号", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "UPSSignforTime",
                  label: "UPS签收时间",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.UPSSignforTime
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.convertToDateYMD(scope.row.UPSSignforTime)
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.EditForm(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.download(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 下载 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "small",
                              disabled: !(
                                scope.row.UPSNumber == "" ||
                                scope.row.UPSNumber == null
                              ),
                            },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.deleteFrom(scope.row._id)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "center" },
            [
              _c("el-pagination", {
                attrs: {
                  layout: "prev, pager, next",
                  total: _vm.totalcount,
                  sizes: "10",
                },
                on: { "current-change": _vm.handleCurrentChange },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑表单",
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
            width: "1200px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                "label-position": "left",
                "label-width": "100px",
                model: _vm.SelectForm,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "单号", prop: "invoiceNumber" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    model: {
                      value: _vm.SelectForm.invoiceNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.SelectForm, "invoiceNumber", $$v)
                      },
                      expression: "SelectForm.invoiceNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "UPS单号", prop: "UPSNumber" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    model: {
                      value: _vm.SelectForm.UPSNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.SelectForm, "UPSNumber", $$v)
                      },
                      expression: "SelectForm.UPSNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "UPS签收时间", prop: "UPSSignforTime" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "选择日期",
                      format: "yyyy 年 MM 月 dd 日",
                      "value-format": "timestamp",
                    },
                    model: {
                      value: _vm.SelectForm.UPSSignforTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.SelectForm, "UPSSignforTime", $$v)
                      },
                      expression: "SelectForm.UPSSignforTime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form",
            { attrs: { model: _vm.SelectForm } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "产品", prop: "Products" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right" },
                      attrs: { type: "primary" },
                      on: { click: _vm.AddItemToSelect },
                    },
                    [_vm._v(" 添加一行 ")]
                  ),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.SelectForm.Products },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "ARTICLE",
                          label: "订货号",
                          width: "180",
                          align: "left",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.ARTICLEChange(scope.row)
                                    },
                                  },
                                  model: {
                                    value: scope.row.ARTICLE,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "ARTICLE", $$v)
                                    },
                                    expression: "scope.row.ARTICLE",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "Number",
                          label: "数量",
                          width: "180",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  model: {
                                    value: scope.row.Number,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "Number", $$v)
                                    },
                                    expression: "scope.row.Number",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "name", label: "名称", align: "center" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "parts",
                          label: "型号",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "price",
                          label: "价格",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "weight",
                          label: "毛重",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "netweight",
                          label: "净重",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "HScode",
                          label: "HsCode",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "EngName",
                          label: "英文名",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: "center",
                          "min-width": "200px",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small" },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.deleteProduct(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 删除 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small" },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.ARTICLEChange(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 刷新属性 ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form",
            {
              attrs: {
                "label-position": "left",
                "label-width": "130px",
                model: _vm.SelectForm,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "UPS模板信息" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "SAP单号", prop: "SAPNumber" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        model: {
                          value: _vm.SelectForm.SAPNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.SelectForm, "SAPNumber", $$v)
                          },
                          expression: "SelectForm.SAPNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { label: "Sigmatek单号", prop: "SigmatekNumber" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        model: {
                          value: _vm.SelectForm.SigmatekNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.SelectForm, "SigmatekNumber", $$v)
                          },
                          expression: "SelectForm.SigmatekNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { label: "UPS收货人", prop: "UPSRecipient" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        model: {
                          value: _vm.SelectForm.UPSRecipient,
                          callback: function ($$v) {
                            _vm.$set(_vm.SelectForm, "UPSRecipient", $$v)
                          },
                          expression: "SelectForm.UPSRecipient",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { label: "Volume", prop: "Volume" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        model: {
                          value: _vm.SelectForm.Volume,
                          callback: function ($$v) {
                            _vm.$set(_vm.SelectForm, "Volume", $$v)
                          },
                          expression: "SelectForm.Volume",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { label: "TotalPackage", prop: "Volume" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        model: {
                          value: _vm.SelectForm.TotalPackage,
                          callback: function ($$v) {
                            _vm.$set(_vm.SelectForm, "TotalPackage", $$v)
                          },
                          expression: "SelectForm.TotalPackage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "Desc" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    attrs: { type: "textarea", autosize: { minRows: 2 } },
                    model: {
                      value: _vm.SelectForm.Desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.SelectForm, "Desc", $$v)
                      },
                      expression: "SelectForm.Desc",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.SaveForm } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.FormTypeSelectVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.FormTypeSelectVisible = $event
            },
          },
        },
        [
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.checkList,
                callback: function ($$v) {
                  _vm.checkList = $$v
                },
                expression: "checkList",
              },
            },
            [
              _c("el-checkbox", { attrs: { label: "维修发货单和发票" } }),
              _c("el-checkbox", { attrs: { label: "UPS发货单和发票" } }),
              _c("el-checkbox", { attrs: { label: "SA发货单和发票" } }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.Generateword } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }