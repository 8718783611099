var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "outbox" },
    [
      _c(
        "div",
        { staticClass: "head" },
        [
          _c(
            "el-row",
            {
              staticStyle: { display: "flex", "align-items": "center" },
              attrs: { gutter: 10 },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 21 } },
                [
                  _c("el-button", { on: { click: _vm.showSearch } }, [
                    _vm._v("筛选"),
                  ]),
                  _vm.is_supplier == false
                    ? _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.showExcelUpload = true
                            },
                          },
                        },
                        [_vm._v("导入")]
                      )
                    : _vm._e(),
                  _vm.is_supplier == false
                    ? _c("el-button", { on: { click: _vm.GoshowExport } }, [
                        _vm._v("导出"),
                      ])
                    : _vm._e(),
                  _c(
                    "el-select",
                    {
                      staticStyle: { "margin-left": "10px", width: "40%" },
                      attrs: { multiple: "", placeholder: "选择模块" },
                      on: { change: _vm.QuickFilter },
                      model: {
                        value: _vm.QuickFilterItem,
                        callback: function ($$v) {
                          _vm.QuickFilterItem = $$v
                        },
                        expression: "QuickFilterItem",
                      },
                    },
                    [
                      _c("template", { slot: "prefix" }, [
                        _c("span", {
                          staticStyle: {
                            padding: "5px",
                            "line-height": "33px",
                            "font-size": "18px",
                            color: "#409eff",
                          },
                        }),
                      ]),
                      _vm._l(_vm.ProdutInfo, function (item) {
                        return _c(
                          "el-option",
                          { key: item, attrs: { value: item } },
                          [
                            _c("span", { staticStyle: { float: "left" } }, [
                              _vm._v(" " + _vm._s(item) + " "),
                            ]),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 3 } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      "总数:" + _vm.productsize + "/未出库:" + _vm.unfinished
                    ) +
                    " "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              staticClass: "outboxtable",
              attrs: {
                data: _vm.productdata,
                "element-loading-text": "Loading",
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "ID", width: "95" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.$index + _vm.listQuery.start + 1) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "序列号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.row.SeriNum) + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "入库时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.gettimestamps(scope.row.CreationTime)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.ProcudtVersion) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "生产方" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.getplantname(scope.row.Affiliation)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "生产订单" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getprodcutorder(scope.row.ProductOrderid)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "出库订单" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getprodcutorder(scope.row.StorageOrderid)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "出库箱号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.OutIndex ? scope.row.OutIndex : "-"
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.watchinfo(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看具体信息")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mypagination" },
        [
          _c("el-pagination", {
            attrs: {
              layout: "prev, pager, next",
              total: _vm.productsize,
              "page-size": 15,
              "current-page": _vm.nowPage,
            },
            on: { "current-change": _vm.pagechange },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showExcelUpload, title: "导入" },
          on: {
            "update:visible": function ($event) {
              _vm.showExcelUpload = $event
            },
          },
        },
        [
          _c(
            "el-select",
            {
              ref: "select_icon",
              staticClass: "selecticon",
              attrs: { size: "small", placeholder: "" },
              model: {
                value: _vm.actIdentifierStr,
                callback: function ($$v) {
                  _vm.actIdentifierStr = $$v
                },
                expression: "actIdentifierStr",
              },
            },
            _vm._l(_vm.PlantInfo, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.name, value: item.identifierStr },
              })
            }),
            1
          ),
          _c("upload-excel-component", {
            attrs: {
              "on-success": _vm.handleSuccess,
              "before-upload": _vm.beforeUpload,
            },
          }),
          _c(
            "el-table",
            {
              staticStyle: {
                overflow: "scroll",
                height: "1000px",
                width: "100%",
                "margin-top": "20px",
              },
              attrs: {
                data: _vm.tableData,
                border: "",
                "highlight-current-row": "",
              },
            },
            _vm._l(_vm.tableHeader, function (item) {
              return _c("el-table-column", {
                key: item,
                attrs: { prop: item, label: item },
              })
            }),
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.bulkSave } },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "具体信息",
            visible: _vm.infovisiable,
            width: _vm.HaveImages,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.infovisiable = $event
            },
          },
        },
        [
          _vm.ShowInfoImagesLength == 0
            ? _c(
                "el-form",
                {
                  attrs: {
                    "label-position": "left",
                    "label-width": "120px",
                    model: _vm.ShowInfo,
                  },
                },
                [
                  _c("el-form-item", { attrs: { label: "序列号" } }, [
                    _vm._v(" " + _vm._s(_vm.ShowInfo.SeriNum) + " "),
                  ]),
                  _c("el-divider"),
                  _c(
                    "el-form-item",
                    { attrs: { label: "主板序列号" } },
                    [
                      _vm._v(" " + _vm._s(_vm.ShowInfo.MotherboardId) + " "),
                      _c(
                        "el-button",
                        {
                          staticClass: "right",
                          on: {
                            click: function ($event) {
                              return _vm.changeID("MotherboardId")
                            },
                          },
                        },
                        [_vm._v("修改")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "内存序列号" } },
                    [
                      _vm._v(" " + _vm._s(_vm.ShowInfo.MemorydId) + " "),
                      _c(
                        "el-button",
                        {
                          staticClass: "right",
                          on: {
                            click: function ($event) {
                              return _vm.changeID("MemorydId")
                            },
                          },
                        },
                        [_vm._v("修改")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "硬盘序列号" } },
                    [
                      _vm._v(" " + _vm._s(_vm.ShowInfo.HardDiskId) + " "),
                      _c(
                        "el-button",
                        {
                          staticClass: "right",
                          on: {
                            click: function ($event) {
                              return _vm.changeID("HardDiskId")
                            },
                          },
                        },
                        [_vm._v("修改")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "液晶序列号" } },
                    [
                      _vm._v(" " + _vm._s(_vm.ShowInfo.ScreenId) + " "),
                      _c(
                        "el-button",
                        {
                          staticClass: "right",
                          on: {
                            click: function ($event) {
                              return _vm.changeID("ScreenId")
                            },
                          },
                        },
                        [_vm._v("修改")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "触摸背板序列号" } },
                    [
                      _vm._v(" " + _vm._s(_vm.ShowInfo.ScreenTorchId) + " "),
                      _c(
                        "el-button",
                        {
                          staticClass: "right",
                          on: {
                            click: function ($event) {
                              return _vm.changeID("ScreenTorchId")
                            },
                          },
                        },
                        [_vm._v("修改")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "触摸主控序列号" } },
                    [
                      _vm._v(" " + _vm._s(_vm.ShowInfo.ScreenControlId) + " "),
                      _c(
                        "el-button",
                        {
                          staticClass: "right",
                          on: {
                            click: function ($event) {
                              return _vm.changeID("ScreenControlId")
                            },
                          },
                        },
                        [_vm._v("修改")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "左侧键盘序列号" } },
                    [
                      _vm._v(" " + _vm._s(_vm.ShowInfo.Key1Id) + " "),
                      _c(
                        "el-button",
                        {
                          staticClass: "right",
                          on: {
                            click: function ($event) {
                              return _vm.changeID("Key1Id")
                            },
                          },
                        },
                        [_vm._v("修改")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "底部键盘序列号" } },
                    [
                      _vm._v(" " + _vm._s(_vm.ShowInfo.Key2Id) + " "),
                      _c(
                        "el-button",
                        {
                          staticClass: "right",
                          on: {
                            click: function ($event) {
                              return _vm.changeID("Key2Id")
                            },
                          },
                        },
                        [_vm._v("修改")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "电源序列号" } },
                    [
                      _vm._v(" " + _vm._s(_vm.ShowInfo.PowerId) + " "),
                      _c(
                        "el-button",
                        {
                          staticClass: "right",
                          on: {
                            click: function ($event) {
                              return _vm.changeID("PowerId")
                            },
                          },
                        },
                        [_vm._v("修改")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "硬件版本" } },
                    [
                      _vm._v(" " + _vm._s(_vm.ShowInfo.HardWareVersion) + " "),
                      _c(
                        "el-button",
                        {
                          staticClass: "right",
                          on: {
                            click: function ($event) {
                              return _vm.changeID("HardWareVersion")
                            },
                          },
                        },
                        [_vm._v("修改")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "OS版本" } },
                    [
                      _vm._v(" " + _vm._s(_vm.ShowInfo.OsVersion) + " "),
                      _c(
                        "el-button",
                        {
                          staticClass: "right",
                          on: {
                            click: function ($event) {
                              return _vm.changeID("OsVersion")
                            },
                          },
                        },
                        [_vm._v("修改")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.ShowInfoImagesLength != 0
            ? _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form",
                        {
                          attrs: {
                            "label-position": "left",
                            "label-width": "120px",
                            model: _vm.ShowInfo,
                          },
                        },
                        [
                          _c("el-form-item", { attrs: { label: "序列号" } }, [
                            _vm._v(" " + _vm._s(_vm.ShowInfo.SeriNum) + " "),
                          ]),
                          _c("el-divider"),
                          _c(
                            "el-form-item",
                            { attrs: { label: "主板序列号" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.ShowInfo.MotherboardId) + " "
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "right",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeID("MotherboardId")
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "内存序列号" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.ShowInfo.MemorydId) + " "
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "right",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeID("MemorydId")
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "硬盘序列号" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.ShowInfo.HardDiskId) + " "
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "right",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeID("HardDiskId")
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "液晶序列号" } },
                            [
                              _vm._v(" " + _vm._s(_vm.ShowInfo.ScreenId) + " "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "right",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeID("ScreenId")
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "触摸背板序列号" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.ShowInfo.ScreenTorchId) + " "
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "right",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeID("ScreenTorchId")
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "触摸主控序列号" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.ShowInfo.ScreenControlId) + " "
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "right",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeID("ScreenControlId")
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "左侧键盘序列号" } },
                            [
                              _vm._v(" " + _vm._s(_vm.ShowInfo.Key1Id) + " "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "right",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeID("Key1Id")
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "底部键盘序列号" } },
                            [
                              _vm._v(" " + _vm._s(_vm.ShowInfo.Key2Id) + " "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "right",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeID("Key2Id")
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "电源序列号" } },
                            [
                              _vm._v(" " + _vm._s(_vm.ShowInfo.PowerId) + " "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "right",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeID("PowerId")
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "硬件版本" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.ShowInfo.HardWareVersion) + " "
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "right",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeID("HardWareVersion")
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "OS版本" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.ShowInfo.OsVersion) + " "
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "right",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeID("OsVersion")
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                            ],
                            1
                          ),
                          _vm.ShowInfo.CpuTemp
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "CPU温度" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.ShowInfo.CpuTemp / 10) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.ShowInfo.USV1Temp
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "USV1温度" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.ShowInfo.USV1Temp) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.ShowInfo.USV2Temp
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "USV2温度" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.ShowInfo.USV2Temp) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticClass: "Image_Caed", attrs: { span: 16 } },
                    _vm._l(_vm.ShowInfo.images, function (item, i) {
                      return _c(
                        "el-card",
                        {
                          key: i,
                          staticStyle: { width: "362px", margin: "10px" },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.ShowInfoBlob.images[i].name)),
                              ]),
                            ]
                          ),
                          _c("el-image", {
                            staticStyle: { width: "320px", height: "180px" },
                            attrs: {
                              src: _vm.ShowInfoBlob.images[i].url,
                              fit: "contain",
                              "preview-src-list": [
                                _vm.ShowInfoBlob.images[i].url,
                              ],
                            },
                            on: { click: _vm.clickImage },
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查找",
            visible: _vm.serchshow,
            width: "45%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.serchshow = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                "label-position": "left",
                "label-width": "140px",
                model: _vm.searchData,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单号查找" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "不支持模糊搜索" },
                    model: {
                      value: _vm.searchData.orderNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchData, "orderNum", $$v)
                      },
                      expression: "searchData.orderNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "序列号查找" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容" },
                    model: {
                      value: _vm.searchData.SeriNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchData, "SeriNum", $$v)
                      },
                      expression: "searchData.SeriNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "型号查找" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请输入内容" },
                      model: {
                        value: _vm.searchData.Type,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchData, "Type", $$v)
                        },
                        expression: "searchData.Type",
                      },
                    },
                    _vm._l(_vm.ProdutInfo, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "入库时间查找" } }, [
                _c(
                  "span",
                  [
                    _c("el-date-picker", {
                      attrs: { type: "date", placeholder: "选择日期" },
                      model: {
                        value: _vm.searchData.CreativeTime_dataS,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchData, "CreativeTime_dataS", $$v)
                        },
                        expression: "searchData.CreativeTime_dataS",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" ~ "),
                _c(
                  "span",
                  [
                    _c("el-date-picker", {
                      attrs: { type: "date", placeholder: "选择日期" },
                      model: {
                        value: _vm.searchData.CreativeTime_dataE,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchData, "CreativeTime_dataE", $$v)
                        },
                        expression: "searchData.CreativeTime_dataE",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "子组件序列号查找" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "支持模糊搜索" },
                    model: {
                      value: _vm.searchData.idfuzzy,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchData, "idfuzzy", $$v)
                      },
                      expression: "searchData.idfuzzy",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "缺失项查找" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请输入内容" },
                      model: {
                        value: _vm.searchData.MissingItems,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchData, "MissingItems", $$v)
                        },
                        expression: "searchData.MissingItems",
                      },
                    },
                    _vm._l(_vm.MissingItemsList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.label, value: item.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "normal" }, on: { click: _vm.closefilter } },
                [_vm._v("返回")]
              ),
              _c(
                "el-button",
                { attrs: { type: "normal" }, on: { click: _vm.clearFilter } },
                [_vm._v("清空")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmFilter },
                },
                [_vm._v("开始筛选")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showExport },
          on: {
            "update:visible": function ($event) {
              _vm.showExport = $event
            },
          },
        },
        [
          _c("div", { staticClass: "two_row_box" }, [
            _c(
              "div",
              { staticClass: "center_box" },
              [
                _c("span", { staticClass: "bottom_5px" }, [
                  _vm._v("起始序列号"),
                ]),
                _c("el-input", {
                  attrs: { placeholder: "请输入内容" },
                  on: { change: _vm.getexportcount },
                  model: {
                    value: _vm.export_start_sernum,
                    callback: function ($$v) {
                      _vm.export_start_sernum = $$v
                    },
                    expression: "export_start_sernum",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "center_box" },
              [
                _c("span", { staticClass: "bottom_5px" }, [
                  _vm._v(" 结束序列号"),
                ]),
                _c("el-input", {
                  attrs: { placeholder: "请输入内容" },
                  on: { change: _vm.getexportcount },
                  model: {
                    value: _vm.export_end_sernum,
                    callback: function ($$v) {
                      _vm.export_end_sernum = $$v
                    },
                    expression: "export_end_sernum",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", [
            _c("span", { staticClass: "bottom_5px center_box tiptext" }, [
              _vm._v("导出数量:" + _vm._s(_vm.exportcount)),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "center_box bottom_5px" },
            [
              _c("el-transfer", {
                attrs: { data: _vm.exportitems },
                model: {
                  value: _vm.exportdata,
                  callback: function ($$v) {
                    _vm.exportdata = $$v
                  },
                  expression: "exportdata",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "center_box" }, [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.confirmclick },
                  },
                  [_vm._v("确认")]
                ),
                _c("el-button", { attrs: { type: "primary" } }, [
                  _vm._v("取消"),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }