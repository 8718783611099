<template>
  <div>

    <div id="table">
      <el-badge
        :value="unreguser.length"
        type="warning"
        style="margin-left: 30px; margin-top: 5px"
      >
        <el-button type="primary" @click="showunreg">新用户申请</el-button>
      </el-badge>

      <el-table
        :data="wholeUserlist"
        style="width: 100%"
      >
        <el-table-column 
          type="expand" 
          width="28"
          :show-header="false"
        >
          <template slot-scope="props">
            <el-table 
              :data="props.row.userlist" 
              style="width: 100%"
              :default-sort = "{prop: 'JobNumber', order: 'ascending'}"
            >
              <el-table-column
                sortable
                prop="username"
                label="姓名"
                align="center"
                width="180"
              >
              </el-table-column>
              <el-table-column
                sortable
                prop="JobNumber"
                label="工号"
                align="center"
                width="180"
              >
              </el-table-column>
              <el-table-column
                label="邮箱"
                align="center"
                min-width="180"
                v-if ="props.row.isSigmatek"
              >
                <template slot-scope="scope">
                  {{ scope.row.email +"@sigmatek-automation.cn" }}
                </template>
              </el-table-column>
              <el-table-column
                sortable
                prop="manager"
                label="主管"
                align="center"
                min-width="180"
                v-if ="props.row.isSigmatek"
              >
              </el-table-column>
              <el-table-column
                sortable
                prop="company"
                label="公司"
                align="center"
                min-width="180"
                v-if ="props.row.isSigmatek == false"
              >
              </el-table-column>
              <el-table-column sortable label="权限" align="center"  v-if ="props.row.isSigmatek" min-width="180">
                <template slot-scope="scope">
                  {{ depatment(scope.row.token_Sigmatek) }}
                  <i class="el-icon-s-custom" v-if="scope.row.token === 'Admin-token'"></i>
                </template>
              </el-table-column>
              <el-table-column label="使用状态" align="center" min-width="180">
                <template slot-scope="scope">
                  {{ getstatus(scope.row.token) }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="180">
                <template slot-scope="scope">
                  <el-button type="primary" @click="changeuser(scope.row)"
                    >修改信息</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column prop ="part" >
          <template  slot-scope="scope">
            <div style="font-size:20px">{{ scope.row.part}} </div>
          </template> 
        </el-table-column>
      </el-table>
    </div>

    <el-dialog title="修改信息" :visible.sync="changeinfovisialbe" width="40%" :close-on-click-modal="false">
      <el-form label-position="left">
        <el-form-item label="修改名称：" label-width="100px">
          <el-input placeholder="请输入内容" v-model="selectinfo.username" class="nameinput"/>
        </el-form-item>
        <el-form-item label="修改工号：" label-width="100px">
          <el-input placeholder="请输入内容" v-model="selectinfo.JobNumber" @change="selectinfoJN" class="nameinput"/>
        </el-form-item>
        <el-row>
          <el-col :span="14">
            <el-form-item label="修改部门为：" label-width="100px">
              <div id="partmentselect">
                <el-select v-model="selectinfo.token_Sigmatek" placeholder="请选择">
                  <el-option
                    v-for="item in tokenlist"
                    :key="item.token"
                    :label="depatment(item.token)"
                    :value="item.token"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item>          
          </el-col>
          <el-col :span="10">
            <el-form-item label="设为管理员">
              <el-switch v-model="selectinfo.isAdmin" @change="Refresh"></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <div v-if="selectinfo.token !== 'supplier'">
          <el-form-item label="直属领导：" label-width="100px">
            <div id="partmentselect">
              <el-select v-model="selectinfo.manager" placeholder="请选择">
                <el-option
                  v-for="item in userlist"
                  :key="item.username"
                  :label="item.username"
                  :value="item.username"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="修改邮箱为：" label-width="100px">
            <div id="partmentselect">
              <el-input placeholder="请输入内容" v-model="selectinfo.email">
                <template slot="append">@sigmatek-automation.cn</template>
              </el-input>
            </div>
          </el-form-item>
        </div>
        <div v-if="selectinfo.token === 'supplier'">
          <el-form-item label="所属公司：" label-width="100px">
            <el-select v-model="selectinfo.companycode" placeholder="请选择" @change="getCompanyName">
              <el-option
                v-for="item in companylist"
                :key="item.identifierStr"
                :label="item.name"
                :value="item.identifierStr"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <div class="buttongroup">
          <el-button @click="changeinfovisialbe = false">取 消</el-button>
          <el-button type="primary" @click="confirmchange">确 定</el-button>
        </div>
      </span>
      <!-- <el-form :inline="true" label-position="left">
        <el-form-item label="修改部门为：" label-width="100px">
          <div id="partmentselect">
            <el-select v-model="selectinfo.token" placeholder="请选择">
              <el-option
                v-for="item in tokenlist"
                :key="item.token"
                :label="depatment(item.token)"
                :value="item.token"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="设为管理员">
          <el-switch></el-switch>
        </el-form-item>
      </el-form> -->
      <!-- <el-row>
        <el-col :span="12">
          <div class="note"> <span> 修改名称: </span> </div>
          <el-input placeholder="请输入内容" v-model="selectinfo.username" id="namechange"/>
        </el-col>
        <el-col :span="12">
          <div class="note">修改工号:</div>
          <el-input placeholder="请输入内容" v-model="selectinfo.JobNumber" id="namechange" @change="selectinfoJN"/>
        </el-col>
      </el-row> -->
      <!-- <div class="notes">修改部门为：</div>
      <div id="partmentselect">
        <el-select v-model="selectinfo.token" placeholder="请选择">
          <el-option
            v-for="item in tokenlist"
            :key="item.token"
            :label="depatment(item.token)"
            :value="item.token"
          >
          </el-option>
        </el-select>
      </div> -->
      <!-- <div v-if="selectinfo.token !== 'supplier'">
        <div class="notes">直属领导</div>
        <div id="partmentselect">
          <el-select v-model="selectinfo.manager" placeholder="请选择">
            <el-option
              v-for="item in userlist"
              :key="item.username"
              :label="item.username"
              :value="item.username"
            >
            </el-option>
          </el-select>
        </div>
        <div class="notes">修改邮箱为：</div>
        <div id="partmentselect">
          <el-input placeholder="请输入内容" v-model="selectinfo.email">
            <template slot="append">@sigmatek-automation.cn</template>
          </el-input>
        </div>
        <div></div>
      </div> -->
      <!-- <div v-else>
        <div class="notes">所属公司</div>
        <div id="companyselect">
          <el-select v-model="selectinfo.companycode" placeholder="请选择" @change="getCompanyName">
            <el-option
              v-for="item in companylist"
              :key="item.identifierStr"
              :label="item.name"
              :value="item.identifierStr"
            >
            </el-option>
          </el-select>
        </div>
      </div> -->
      <!-- <span slot="footer" class="dialog-footer">
        <div class="buttongroup">
          <el-button @click="changeinfovisialbe = false">取 消</el-button>
          <el-button type="primary" @click="confirmchange">确 定</el-button>
        </div>
      </span> -->
    </el-dialog>
    
    <el-dialog title="注册申请" :visible.sync="unregvisiual" width="800px">
      <el-form label-position="left" label-width="120px">
        <el-form-item label="微信昵称">
          <el-select v-model="selectunreguser._id" placeholder="请选择">
            <el-option
              v-for="item in unreguser"
              :key="item.username"
              :label="item.username"
              :value="item._id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form :inline="true">
          <el-form-item label="设置用户名" label-width="120px" style="width: 100%; max-width: 322px">
            <el-input v-model="selectunreguser.name"></el-input>
          </el-form-item>
          <el-form-item label="设置工号" label-width="80px" style="width: 100%; max-width: 322px">
            <el-input style="width: 100px" v-model="selectunreguser.JobNumber" @change="selectunreguserJN"></el-input>
          </el-form-item>
        </el-form>
        <el-form :inline="true">
          <el-form-item label="设置部门" label-width="120px">
            <el-select v-model="selectunreguser.token_Sigmatek" placeholder="请选择">
              <el-option
                v-for="item in tokenlist"
                :key="item.token"
                :label="depatment(item.token)"
                :value="item.token"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设为管理员">
            <el-switch v-model="selectunreguser.isAdmin"></el-switch>
          </el-form-item>
        </el-form>
        <el-form-item label="所属公司" v-if="selectunreguser.token == 'supplier'" >
          <el-select v-model="selectunreguser.companycode" placeholder="请选择" @change="getCompanyNameForCreate">
            <el-option
                v-for="item in companylist"
                :key="item.identifierStr"
                :label="item.name"
                :value="item.identifierStr"
              >
              </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="直属领导" v-if="selectunreguser.token !== 'supplier'" >
          <el-select v-model="selectunreguser.manager" placeholder="请选择">
            <el-option
              v-for="item in userlist"
              :key="item.username"
              :label="item.username"
              :value="item.username"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设置邮箱" v-if="selectunreguser.token !== 'supplier'">
          <el-input placeholder="请输入内容" v-model="selectunreguser.email">
            <template slot="append">@sigmatek-automation.cn</template>
          </el-input>
        </el-form-item>
      </el-form>
      <div>
        <span slot="footer" class="dialog-footer">
          <div class="buttongroup">
            <el-popconfirm title="确认删除？" @confirm="rejectnewuser">
              <el-button slot="reference">拒 绝</el-button>
            </el-popconfirm>
            <el-button
              type="primary"
              @click="confirmNewuser"
              style="margin-left: 10px"
              >确 定</el-button
            >
          </div>
        </span>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import {
  getUserList,
  gettokenlist,
  updateuserinfo,
  getunregusers,
  reguser,
  deleteuser,
} from "@/api/user";
import { GetAllPlant } from "@/api/mesproductinfo";
export default {
  name: "UserManager",
  data() {
    return {
      userlist: [],
      changeinfovisialbe: false,

      selectinfo: {
        token: null,
        isAdmin: false,
      },

      tokenlist: [],
      companylist:[],
      wholeUserlist:[],
      unreguser: [],
      unregvisiual: false,

      selectunreguser: {
        _id: null,
        name: null,
        JobNumber: null,
        token: null,
        token_Sigmatek: null,
        isAdmin: false,
      },
    };
  },

  created() {
    //获取所有用户信息。由于用户比较少 到达不了百的数量级不分页直接获取
    this.getusers();
    this.gettokns();
    this.getuserunreg();
    this.getCompanydata();
  },

  methods: {
    Refresh() {
      this.$forceUpdate();
    },
    gettokns() {
      gettokenlist().then((res) => {
        this.tokenlist = res.data;
        this.tokenlist.push({
          token: "disable",
        });
        this.tokenlist = this.tokenlist.filter((elem) => elem.token !== "Admin-token");
        // 将网页权限与公司权限分离开的解决方法
        //   1.所有修改token的操作首先会修改token_Sigmatek字段，去除管理员的选项，管理员的选项通过一个Switch赋予。
        //   2.判断isAdmin，如果是true，则将token设置为“Admin-token”，否则则为token_Sigmatek的值
        //   3.将来所有判断职位（比如报销需要看哪个部门），都有限判断token_Sigmatek。
       // console.log(this.tokenlist);
      });
    },
    getusers() {
      getUserList().then((res) => {
        this.userlist = res.data.list;
        this.wholeUserlist = [{
          part : "sigmatek",
          userlist : [],
          isSigmatek : true
        },{
          part : "供应商",
          userlist : [],
          isSigmatek : false
        }]
        for (var i=0 ; i< this.userlist.length ; i++){
          if (this.userlist[i].token == "supplier"){
            this.wholeUserlist[1].userlist.push(this.userlist[i])
          }else{
            this.wholeUserlist[0].userlist.push(this.userlist[i])
          }
        }
      });
    },
    getuserunreg() {
      getunregusers().then((res) => {
        this.unreguser = res.data.list;
      });
    },
    getCompanydata() {
      GetAllPlant().then((res) => {
        this.companylist= res.data.list;
      });
    },

    selectunreguserJN() {
      if (this.selectunreguser.JobNumber === "" || 
          this.selectunreguser.JobNumber === null || 
          this.selectunreguser.JobNumber === "0" || 
          isNaN(this.selectunreguser.JobNumber) || 
          this.selectunreguser.JobNumber.indexOf(".") !== -1 || 
          this.selectunreguser.JobNumber.length >= 4) {
        this.$message({
          message: '错误的工号格式',
          type: 'warning'
        });
        return "";
      }
      if (this.selectunreguser.JobNumber.length === 1) {
        this.selectunreguser.JobNumber = "00" + this.selectunreguser.JobNumber;
      } else if (this.selectunreguser.JobNumber.length === 2) {
        this.selectunreguser.JobNumber = "0" + this.selectunreguser.JobNumber;
      }
    },//判断工号输入格式
    selectinfoJN() {
      if (this.selectinfo.JobNumber === "" || 
          this.selectinfo.JobNumber === null || 
          this.selectinfo.JobNumber === 0 || 
          isNaN(this.selectinfo.JobNumber) || 
          this.selectinfo.JobNumber.indexOf(".") !== -1 || 
          this.selectinfo.JobNumber.length >= 4) {
        this.$message({
          message: '错误的工号格式',
          type: 'warning'
        });
        return "";
      }
      if (this.selectinfo.JobNumber.length === 1) {
        this.selectinfo.JobNumber = "00" + this.selectinfo.JobNumber;
      } else if (this.selectinfo.JobNumber.length === 2) {
        this.selectinfo.JobNumber = "0" + this.selectinfo.JobNumber;
      }
    },//判断工号输入格式

    depatment(input) {
      if (input == null) return;
      if (input == "Admin-token")        return "管理员";
      if (input == "supplier")           return "供应商";
      if (input == "repair")             return "维修部";
      if (input == "technology")         return "技术部";
      if (input == "Administrative")     return "行政部";
      if (input == "logistics")          return "物流部";

      if (input == "disable")            return "停用";
    },
    getstatus(input) {
      if (input == "disable") return "已停用";
      return "使用中";
    },
    getCompanyName(){
      const selectedItem = this.companylist.find(item => item.identifierStr === this.selectinfo.companycode);
      if (selectedItem) {
      //  console.log(`选中的值为 ${this.selectedValue}，对应的文本为 ${selectedItem.label}`);
        this.selectinfo['company']= selectedItem.name ;
      }
    },
    getCompanyNameForCreate(){
      const selectedItem = this.companylist.find(item => item.identifierStr === this.selectunreguser.companycode);
      if (selectedItem) {
      //  console.log(`选中的值为 ${this.selectedValue}，对应的文本为 ${selectedItem.label}`);
        this.selectunreguser['company']= selectedItem.name ;
      }
    },

    changeuser(input) {
      this.selectinfo = JSON.parse(JSON.stringify(input));
      if (this.selectinfo.token === "Admin-token") {
        this.selectinfo.isAdmin = true;
      } else {
        this.selectinfo.isAdmin = false;
      }
      this.changeinfovisialbe = true; 
      //console.log(this.selectinfo)
    },


    confirmchange() {
      if (this.selectinfo.isAdmin === true) {
        this.selectinfo.token = "Admin-token"
      } else {
        this.selectinfo.token = this.selectinfo.token_Sigmatek;
      }
     // console.log(this.selectinfo);
      updateuserinfo(this.selectinfo).then((res) => {
        this.$message.success("保存成功");
        this.changeinfovisialbe = false;
        this.getusers();
      });
    },

    showunreg() {                                                 //关联新建用户
      this.selectunreguser = {
        _id: null,
        name: null,
        JobNumber: null,
        token: null,
        token_Sigmatek: null,
        isAdmin: false,
        openid: null,
        company :null,
        companycode :null
      };
      if (this.unreguser.length > 0) {
        this.selectunreguser._id = this.unreguser[0]._id;
      }
      this.unregvisiual = true;
    },

    confirmNewuser() {                                            //新建用户确认
      if (this.selectunreguser._id === null) {
        this.$message.error("没有选中用户");
        return;
      }
      if (this.selectunreguser.name === null) {
        this.$message.error("请给定用户名");
        return;
      }
    //   if (this.selectunreguser.token === null) {
    //     this.$message.error("请给定权限");
    //     return;
    //   }
    //   if (this.selectunreguser.isAdmin === true) {
    //     this.selectunreguser.token = "Admin-token"
    //   } else {
    //     this.selectunreguser.token = this.selectunreguser.token_Sigmatek;
    //   }
    
      if (this.selectunreguser.isAdmin === true) {
        this.selectunreguser.token = "Admin-token"
      } else {
        if (this.selectunreguser.token_Sigmatek === null) {
          this.$message.error("请给定权限");
          return;
        } else {
          this.selectunreguser.token = this.selectunreguser.token_Sigmatek;
        }
      }

      let message = {
        _id: this.selectunreguser._id,
        username: this.selectunreguser.name,
        manager: this.selectunreguser.manager,
        JobNumber: this.selectunreguser.JobNumber,
        token: this.selectunreguser.token,
        token_Sigmatek: this.selectunreguser.token_Sigmatek,
        email: this.selectunreguser.email,                                                      
      };

      reguser(message).then((res) => 
        {
          this.$message.success("更新成功");
          this.unregvisiual = false;
          this.getusers();
          this.getuserunreg();
        }
      ).catch((err) => {
          this.$message.error("更新失败");
        }
      );
    },

    rejectnewuser() {
      deleteuser({ _id: this.selectunreguser._id })
        .then((res) => {
          this.$message.success("更新成功");
          this.selectunreguser = {
            _id: null,
            name: null,
            JobNumber: null,
            token: null,
            openid: null,
          };
          this.getuserunreg();
        })
        .catch((err) => {
          this.$message.error("更新失败");
        });
    },
  },
};
</script>

<style>
#table {
  margin-top: 10px;
}
/* .notes {
  margin-bottom: 20px;
} */
/* #partmentselect {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
} */
/* .buttongroup {
  display: flex;
  justify-content: flex-end;
} */
/* #namechange{
  margin-top: 10px;
  margin-bottom: 20px;
  width:50%;
  margin-left:25%;
} */

</style>