import request from '@/utils/request'
export function getsummarylist(params) {
    return request({
      url: '/Api/problemsummary/getsummarylist',
      method: 'get',
      params
    })
  }

  export function createsummarylist(data) {
    return request({
      url: '/Api/problemsummary/createsummarylist',
      method: 'post',
      data
    })
  }

  export function  deletesummary(id) {
    return request({
      url: '/Api/problemsummary/delete',
      method: 'delete',
      params: id
    })
  }

  export function updatesummary(data) {
    return request({
      url: '/Api/problemsummary/update',
      method: 'post',
      data
    })
  }