var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "OutBox" }, [
    _c(
      "div",
      { staticClass: "HeadButtonArea" },
      [
        _c("el-button", {
          directives: [
            {
              name: "print",
              rawName: "v-print",
              value: _vm.printObj,
              expression: "printObj",
            },
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
          attrs: { id: "print" },
        }),
        _c("el-button", { on: { click: _vm.beforePrint } }, [_vm._v("打印")]),
        _c("el-button", { on: { click: _vm.save } }, [_vm._v("保存")]),
        _c(
          "router-link",
          {
            attrs: {
              to: {
                path: "Project",
                query: { projectID: _vm.task.projectID, actTask: _vm.task },
              },
            },
          },
          [
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { type: "button" },
              },
              [_vm._v("返回")]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { attrs: { id: "printArea" } }, [
      _vm._m(0),
      _c("div", { staticClass: "SplitLine" }),
      _c("div", { staticClass: "Body" }, [
        _c("div", { staticClass: "BodyHead" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "Unit" }, [
              _vm._m(1),
              !_vm.PrintState
                ? _c(
                    "div",
                    {
                      staticClass: "NumberInpunt1 HeadNumberInpunt",
                      staticStyle: { width: "39mm" },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.task.finisher,
                          callback: function ($$v) {
                            _vm.$set(_vm.task, "finisher", $$v)
                          },
                          expression: "task.finisher",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.PrintState
                ? _c(
                    "div",
                    {
                      staticClass: "NumberInpunt1 HeadNumberInpunt",
                      staticStyle: { width: "39mm" },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.Temporary_finisher,
                          callback: function ($$v) {
                            _vm.Temporary_finisher = $$v
                          },
                          expression: "Temporary_finisher",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "Unit" }, [
              _vm._m(2),
              _c(
                "div",
                {
                  staticClass: "NumberInpunt1 HeadNumberInpunt",
                  staticStyle: { width: "25mm" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.task.Place,
                      callback: function ($$v) {
                        _vm.$set(_vm.task, "Place", $$v)
                      },
                      expression: "task.Place",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "Unit" }, [
              _vm._m(3),
              _c(
                "div",
                {
                  staticClass: "NumberInpunt1 HeadNumberInpunt",
                  staticStyle: { width: "82mm" },
                },
                [
                  _c("el-input", {
                    attrs: { list: "browsers" },
                    model: {
                      value: _vm.task.customer,
                      callback: function ($$v) {
                        _vm.$set(_vm.task, "customer", $$v)
                      },
                      expression: "task.customer",
                    },
                  }),
                  _c(
                    "datalist",
                    { attrs: { id: "browsers" } },
                    _vm._l(_vm.customerInfo, function (item) {
                      return _c("option", [
                        _vm._v(" " + _vm._s(item["company"]) + " "),
                      ])
                    }),
                    0
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Unit" }, [
              _vm._m(4),
              !_vm.PrintState
                ? _c(
                    "div",
                    {
                      staticClass: "NumberInpunt1 HeadNumberInpunt",
                      staticStyle: { width: "77mm" },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.Worker,
                          callback: function ($$v) {
                            _vm.Worker = $$v
                          },
                          expression: "Worker",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.PrintState
                ? _c(
                    "div",
                    {
                      staticClass: "NumberInpunt1 HeadNumberInpunt",
                      staticStyle: { width: "77mm" },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.Temporary_Worker,
                          callback: function ($$v) {
                            _vm.Temporary_Worker = $$v
                          },
                          expression: "Temporary_Worker",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "log" }, [
        _c("p", { staticClass: "InfoTittle" }, [_vm._v("日志 Activities")]),
        _c(
          "div",
          { staticClass: "logTable", attrs: { id: "DelieveryTable" } },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                ref: "singleTable",
                attrs: {
                  "highlight-current-row": "",
                  data: _vm.task.step,
                  "cell-class-name": "textTop",
                  "show-header": false,
                  "header-row-style": {
                    color: "#000",
                    "font-size": "13px",
                    "font-weight": 400,
                  },
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    align: "center",
                    border: false,
                    width: "50",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "name",
                    width: "160",
                    border: false,
                    align: "center",
                    "min-width": "85px",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "log",
                    border: false,
                    align: "left",
                    "min-width": "85px",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "Result" }, [
        _c("p", { staticClass: "InfoTittle" }, [_vm._v("总结 Result")]),
        _c(
          "div",
          { staticClass: "resultInput", attrs: { id: "Result" } },
          [
            _vm.task.comment !== "" && _vm.task.comment !== null
              ? _c("el-input", {
                  ref: "result1",
                  staticClass: "edit-input",
                  attrs: {
                    autosize: { minRows: 1 },
                    resize: "none",
                    type: "textarea",
                    disabled: "",
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k(
                          $event.keyCode,
                          "enter",
                          13,
                          $event.key,
                          "Enter"
                        ) &&
                        _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                          "Backspace",
                          "Delete",
                          "Del",
                        ])
                      ) {
                        return null
                      }
                      return _vm.resizeHeight($event)
                    },
                  },
                  model: {
                    value: _vm.task.comment,
                    callback: function ($$v) {
                      _vm.$set(_vm.task, "comment", $$v)
                    },
                    expression: "task.comment",
                  },
                })
              : _vm._e(),
            _c("el-input", {
              ref: "result2",
              staticClass: "edit-input",
              attrs: {
                autosize: { minRows: 2 },
                resize: "none",
                type: "textarea",
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter") &&
                    _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                      "Backspace",
                      "Delete",
                      "Del",
                    ])
                  ) {
                    return null
                  }
                  return _vm.resizeHeight($event)
                },
              },
              model: {
                value: _vm.task.reportResult,
                callback: function ($$v) {
                  _vm.$set(_vm.task, "reportResult", $$v)
                },
                expression: "task.reportResult",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "bottomInfo" }, [
        _c("div", { staticClass: "relative" }, [
          _c("div", { staticClass: "area_right top" }, [
            _c("span", { staticClass: "title-right" }, [
              _vm._v("报告日期 Date: "),
            ]),
            _c(
              "div",
              { staticClass: "writeInput" },
              [
                _c("el-date-picker", {
                  staticClass: "my-date-picker",
                  attrs: {
                    disabled: "",
                    align: "right",
                    type: "date",
                    format: "yyyy 年 MM 月 dd 日",
                    "value-format": "timestamp",
                  },
                  model: {
                    value: _vm.task.finishTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.task, "finishTime", $$v)
                    },
                    expression: "task.finishTime",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Tittle" }, [
      _c("h2", [
        _vm._v("工作报告"),
        _c("span", { staticClass: "italic" }, [_vm._v("Job Report")]),
      ]),
      _c("img", { attrs: { src: require("../../../Img/log.jpg") } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Word" }, [
      _c("span", [_vm._v("姓名")]),
      _c("span", [_vm._v("Name")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Word" }, [
      _c("span", [_vm._v("地点")]),
      _c("span", [_vm._v("Place of Action")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Word" }, [
      _c("span", [_vm._v("客户")]),
      _c("span", [_vm._v("Customer")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Word" }, [
      _c("span", [_vm._v("合作者")]),
      _c("span", [_vm._v("Cooperator")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }