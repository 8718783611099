var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tabs",
    {
      attrs: { type: "card" },
      model: {
        value: _vm.activeName,
        callback: function ($$v) {
          _vm.activeName = $$v
        },
        expression: "activeName",
      },
    },
    [
      _c(
        "el-tab-pane",
        { attrs: { label: "总览", name: "first" } },
        [
          _c(
            "el-row",
            {
              staticStyle: { display: "flex", height: "850px" },
              attrs: { gutter: 15 },
            },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c(
                  "div",
                  { staticStyle: { "margin-bottom": "15px" } },
                  [
                    _c("el-card", { staticClass: "NoticeInfo" }, [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _c("span", [_vm._v("操作面板")]),
                      ]),
                      _c(
                        "div",
                        { staticStyle: { height: "200px" } },
                        [
                          _c(
                            "el-button",
                            { on: { click: _vm.showCalendaringOperation } },
                            [_vm._v(" 日历操作 ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("el-card", { staticClass: "NoticeInfo" }, [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _c("span", [_vm._v("今日事件")]),
                      ]),
                      _c("div", { staticStyle: { height: "510px" } }, [
                        !_vm.Calendar_Collection_Today.length
                          ? _c("div", { staticClass: "NoNotification" }, [
                              _c("img", {
                                staticStyle: { opacity: "0.3" },
                                attrs: {
                                  src: require("../dashboard/icon/NoNotification.png"),
                                },
                              }),
                            ])
                          : _vm._e(),
                        _vm.Calendar_Collection_Today.length
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.Calendar_Collection_Today,
                                function (One_Event, One_Event_Number) {
                                  return _c(
                                    "el-card",
                                    {
                                      key: One_Event_Number,
                                      class: [
                                        "current",
                                        _vm.componentStyle(One_Event),
                                      ],
                                    },
                                    [
                                      One_Event.hasOwnProperty("Where") &&
                                      One_Event.hasOwnProperty("Why")
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "line-height": "1.5",
                                              },
                                            },
                                            [
                                              One_Event.isAllDay
                                                ? _c("span", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-weight": "bold",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              One_Event.Personnel
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " 今天在 " +
                                                        _vm._s(
                                                          One_Event.Where
                                                        ) +
                                                        " 因为 " +
                                                        _vm._s(One_Event.Why) +
                                                        " 加班了，要加班一整天。 "
                                                    ),
                                                  ])
                                                : _c("span", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-weight": "bold",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              One_Event.Personnel
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " 今天在 " +
                                                        _vm._s(
                                                          One_Event.Where
                                                        ) +
                                                        " 因为 " +
                                                        _vm._s(One_Event.Why) +
                                                        " 加班了， "
                                                    ),
                                                    One_Event.LeaveTimeS
                                                      ? _c("span", [
                                                          _vm._v(
                                                            " 他是在 " +
                                                              _vm._s(
                                                                _vm.ToHHMMSS(
                                                                  One_Event.LeaveTimeS
                                                                )
                                                              ) +
                                                              " 开始的， "
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    One_Event.LeaveTimeE
                                                      ? _c("span", [
                                                          _vm._v(
                                                            " 他能在 " +
                                                              _vm._s(
                                                                _vm.ToHHMMSS(
                                                                  One_Event.LeaveTimeE
                                                                )
                                                              ) +
                                                              " 结束。 "
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]),
                                            ]
                                          )
                                        : One_Event.hasOwnProperty("Why")
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "line-height": "1.5",
                                              },
                                            },
                                            [
                                              One_Event.isAllDay
                                                ? _c("span", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-weight": "bold",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              One_Event.Personnel
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " 今天因为 " +
                                                        _vm._s(One_Event.Why) +
                                                        " 请假了，请的是" +
                                                        _vm._s(
                                                          One_Event.nature
                                                        ) +
                                                        "，要请假一整天。 "
                                                    ),
                                                  ])
                                                : _c("span", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-weight": "bold",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              One_Event.Personnel
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " 今天因为 " +
                                                        _vm._s(One_Event.Why) +
                                                        " 请假了，请的是" +
                                                        _vm._s(
                                                          One_Event.nature
                                                        ) +
                                                        ", "
                                                    ),
                                                    One_Event.LeaveTimeS
                                                      ? _c("span", [
                                                          _vm._v(
                                                            " 他是在 " +
                                                              _vm._s(
                                                                _vm.ToHHMMSS(
                                                                  One_Event.LeaveTimeS
                                                                )
                                                              ) +
                                                              " 离开的， "
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    One_Event.LeaveTimeE
                                                      ? _c("span", [
                                                          _vm._v(
                                                            " 他能在 " +
                                                              _vm._s(
                                                                _vm.ToHHMMSS(
                                                                  One_Event.LeaveTimeE
                                                                )
                                                              ) +
                                                              " 回来。 "
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]),
                                            ]
                                          )
                                        : One_Event.hasOwnProperty("Where")
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "line-height": "1.5",
                                              },
                                            },
                                            [
                                              One_Event.isAllDay
                                                ? _c("span", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-weight": "bold",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              One_Event.Personnel
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " 今天去 " +
                                                        _vm._s(
                                                          One_Event.Where
                                                        ) +
                                                        " 出差了，要出差一整天。 "
                                                    ),
                                                  ])
                                                : _c("span", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-weight": "bold",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              One_Event.Personnel
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " 今天去 " +
                                                        _vm._s(
                                                          One_Event.Where
                                                        ) +
                                                        " 出差了， "
                                                    ),
                                                    One_Event.LeaveTimeS
                                                      ? _c("span", [
                                                          _vm._v(
                                                            " 他是在 " +
                                                              _vm._s(
                                                                _vm.ToHHMMSS(
                                                                  One_Event.LeaveTimeS
                                                                )
                                                              ) +
                                                              " 出发的， "
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    One_Event.LeaveTimeE
                                                      ? _c("span", [
                                                          _vm._v(
                                                            " 他能在 " +
                                                              _vm._s(
                                                                _vm.ToHHMMSS(
                                                                  One_Event.LeaveTimeE
                                                                )
                                                              ) +
                                                              " 回来。 "
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [
                  _c("el-card", { staticClass: "NoticeInfo" }, [
                    _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                      _c("span", [_vm._v("当月事件")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "Event" },
                      _vm._l(
                        _vm.Event_Collection,
                        function (One_Event, One_Event_Number) {
                          return _c(
                            "el-card",
                            { key: One_Event_Number, staticClass: "EventCard" },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "space-between",
                                    "align-items": "center",
                                  },
                                  attrs: { slot: "header" },
                                  slot: "header",
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(One_Event.Personnel)),
                                  ]),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "12px" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.ToYYYYMMDD(
                                              One_Event.LeaveTimeS
                                            ) +
                                              " " +
                                              _vm.ToHHMMSS(One_Event.LeaveTimeS)
                                          ) +
                                          " ~ " +
                                          _vm._s(
                                            _vm.ToYYYYMMDD(
                                              One_Event.LeaveTimeE
                                            ) +
                                              " " +
                                              _vm.ToHHMMSS(One_Event.LeaveTimeE)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.getStatus(One_Event_Number))
                                    ),
                                  ]),
                                ]
                              ),
                              _c("div", [
                                _c(
                                  "ul",
                                  {
                                    staticStyle: {
                                      margin: "10px 0px 10px 0px",
                                    },
                                  },
                                  [
                                    _c("li", [
                                      _c("span", [
                                        _vm._v(" " + _vm._s(One_Event.event)),
                                      ]),
                                      One_Event.event === "请假"
                                        ? _c("span", [
                                            _vm._v(
                                              " (" +
                                                _vm._s(
                                                  One_Event.Occur_Leave.nature
                                                ) +
                                                ") "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            float: "right",
                                            "padding-right": "20px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.TimeResolved(One_Event)
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c(
                                      "ul",
                                      { staticStyle: { "margin-top": "10px" } },
                                      [
                                        One_Event.event === "出差"
                                          ? _c("li", [
                                              _c("span", [
                                                _vm._v(
                                                  " 地点：" +
                                                    _vm._s(
                                                      One_Event.Occur_Business
                                                        .Where
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ])
                                          : _vm._e(),
                                        One_Event.event === "请假"
                                          ? _c("li", [
                                              _c("span", [
                                                _vm._v(
                                                  " 原因：" +
                                                    _vm._s(
                                                      One_Event.Occur_Leave.Why
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ])
                                          : _vm._e(),
                                        One_Event.event === "加班"
                                          ? _c("li", [
                                              _c("span", [
                                                _vm._v(
                                                  " 地点：" +
                                                    _vm._s(
                                                      One_Event.Occur_Overtime
                                                        .Where
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ])
                                          : _vm._e(),
                                        One_Event.event === "加班"
                                          ? _c("li", [
                                              _c("span", [
                                                _vm._v(
                                                  " 原因：" +
                                                    _vm._s(
                                                      One_Event.Occur_Overtime
                                                        .Why
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _c("el-divider", {
                                staticClass: "card-footer-divider",
                              }),
                              _c("div", { staticClass: "card-footer" }, [
                                _c("span", [_vm._v(" 意见 ")]),
                                _c(
                                  "div",
                                  { staticClass: "Opinions" },
                                  [
                                    _c("el-avatar", { attrs: { size: 30 } }, [
                                      _vm._v(" 顾 "),
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "padding-left": "10px" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              One_Event.Report.BossOpinions
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm.haveManager(One_Event.Personnel)
                                  ? _c(
                                      "div",
                                      { staticClass: "Opinions" },
                                      [
                                        _c(
                                          "el-avatar",
                                          { attrs: { size: 30 } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.haveManager(
                                                    One_Event.Personnel
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "padding-left": "10px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  One_Event.Report
                                                    .ManagerOpinions
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          )
                        }
                      ),
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "日历汇总", name: "second" } },
        [
          _c("div", { staticClass: "Table_Head" }, [
            _c(
              "div",
              { staticClass: "Table_Head_Left" },
              [
                _c(
                  "div",
                  [
                    _c("el-date-picker", {
                      staticStyle: { margin: "0px 10px 0px 10px" },
                      attrs: {
                        type: "month",
                        placeholder: "选择月",
                        size: "mini",
                        clearable: false,
                        "picker-options": _vm.pickerOptions,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.fetchData()
                        },
                      },
                      model: {
                        value: _vm.MonthChoose,
                        callback: function ($$v) {
                          _vm.MonthChoose = $$v
                        },
                        expression: "MonthChoose",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-radio-group",
                  {
                    attrs: { size: "mini" },
                    on: {
                      change: function ($event) {
                        return _vm.fetchData()
                      },
                    },
                    model: {
                      value: _vm.DepartmentChoose,
                      callback: function ($$v) {
                        _vm.DepartmentChoose = $$v
                      },
                      expression: "DepartmentChoose",
                    },
                  },
                  [
                    _c("el-radio-button", { attrs: { label: "全部" } }),
                    _c("el-radio-button", { attrs: { label: "技术部" } }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "Table_Head_Middle" }, [
              _c("div", { staticClass: "Legend_Business" }),
              _c("div", { staticStyle: { margin: "0px 15px 0px 5px" } }, [
                _vm._v(" 出差 "),
              ]),
              _c("div", { staticClass: "Legend_Leave" }),
              _c("div", { staticStyle: { margin: "0px 15px 0px 5px" } }, [
                _vm._v(" 请假 "),
              ]),
              _c("div", { staticClass: "Legend_Overtime" }),
              _c("div", { staticStyle: { margin: "0px 15px 0px 5px" } }, [
                _vm._v(" 加班 "),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "Table_Head_Right" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.ExcelDownload },
                  },
                  [_vm._v(" 导出成Excel ")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-table",
            {
              staticClass: "Table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.Calendar_Collection_Table,
                height: "800",
                border: "",
                "header-cell-style": {
                  height: "15mm",
                  "padding-bottom": "0px",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "Name", label: "姓名", width: "140", fixed: "" },
              }),
              _vm._l(_vm.MonthDates, function (date) {
                return _c("el-table-column", {
                  key: date.Date,
                  attrs: {
                    BackgroundColorJudgment: "",
                    "label-class-name": _vm.TableDate_Remind_CSS(date.Date),
                    "class-name": _vm.TableDate_Remind_CSS(date.Date),
                    width: "70",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "flex-start",
                                  "align-items": "baseline",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { "font-size": "20px" } },
                                  [
                                    _vm._v(
                                      " " + _vm._s(date.Date.slice(-2)) + " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-size": "10px",
                                      "padding-left": "5px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.TableDate_Remind(date.Date)
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "20px",
                                  opacity: "0.15",
                                  "padding-top": "12px",
                                  display: "flex",
                                  "flex-direction": "row",
                                  "justify-content": "flex-end",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.TableDate_Week(date.Date)) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm.hasBusiness(scope.row, date.Date)
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "dark",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "content" },
                                              slot: "content",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getContent_Business_When(
                                                      scope.row,
                                                      date.Date
                                                    )
                                                  )
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getContent_Business_Where(
                                                      scope.row,
                                                      date.Date
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm.getContent_Business_Time(
                                            scope.row,
                                            date.Date
                                          ) === "起始天"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "Business_Start",
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass: "EventBar",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.getContent_Business_Time(
                                            scope.row,
                                            date.Date
                                          ) === "结束天"
                                            ? _c(
                                                "div",
                                                { staticClass: "Business_End" },
                                                [
                                                  _c("div", {
                                                    staticClass: "EventBar",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.getContent_Business_Time(
                                            scope.row,
                                            date.Date
                                          ) === "进行中"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "Business_Between",
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass: "EventBar",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.getContent_Business_Time(
                                            scope.row,
                                            date.Date
                                          ) === "起始结束同一天"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "Business_StartEnd",
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass: "EventBar",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.hasBusiness(scope.row, date.Date)
                                ? _c("div", { staticClass: "Business_None" }, [
                                    _c("div", { staticClass: "EventBar" }),
                                  ])
                                : _vm._e(),
                              _vm.hasLeave(scope.row, date.Date)
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "dark",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "content" },
                                              slot: "content",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getContent_Leave_When(
                                                      scope.row,
                                                      date.Date
                                                    )
                                                  )
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getContent_Leave_nature(
                                                      scope.row,
                                                      date.Date
                                                    )
                                                  )
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getContent_Leave_Why(
                                                      scope.row,
                                                      date.Date
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm.getContent_Leave_Time(
                                            scope.row,
                                            date.Date
                                          ) === "起始天"
                                            ? _c(
                                                "div",
                                                { staticClass: "Leave_Start" },
                                                [
                                                  _c("div", {
                                                    staticClass: "EventBar",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.getContent_Leave_Time(
                                            scope.row,
                                            date.Date
                                          ) === "结束天"
                                            ? _c(
                                                "div",
                                                { staticClass: "Leave_End" },
                                                [
                                                  _c("div", {
                                                    staticClass: "EventBar",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.getContent_Leave_Time(
                                            scope.row,
                                            date.Date
                                          ) === "进行中"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "Leave_Between",
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass: "EventBar",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.getContent_Leave_Time(
                                            scope.row,
                                            date.Date
                                          ) === "起始结束同一天"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "Leave_StartEnd",
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass: "EventBar",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.hasLeave(scope.row, date.Date)
                                ? _c("div", { staticClass: "Business_None" }, [
                                    _c("div", { staticClass: "EventBar" }),
                                  ])
                                : _vm._e(),
                              _vm.hasOvertime(scope.row, date.Date)
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "dark",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "content" },
                                              slot: "content",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getContent_Overtime_When(
                                                      scope.row,
                                                      date.Date
                                                    )
                                                  )
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getContent_Overtime_Where(
                                                      scope.row,
                                                      date.Date
                                                    )
                                                  )
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getContent_Overtime_Why(
                                                      scope.row,
                                                      date.Date
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm.getContent_Overtime_Time(
                                            scope.row,
                                            date.Date
                                          ) === "起始天"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "Overtime_Start",
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass: "EventBar",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.getContent_Overtime_Time(
                                            scope.row,
                                            date.Date
                                          ) === "结束天"
                                            ? _c(
                                                "div",
                                                { staticClass: "Overtime_End" },
                                                [
                                                  _c("div", {
                                                    staticClass: "EventBar",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.getContent_Overtime_Time(
                                            scope.row,
                                            date.Date
                                          ) === "进行中"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "Overtime_Between",
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass: "EventBar",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.getContent_Overtime_Time(
                                            scope.row,
                                            date.Date
                                          ) === "起始结束同一天"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "Overtime_StartEnd",
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass: "EventBar",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.hasOvertime(scope.row, date.Date)
                                ? _c("div", { staticClass: "Business_None" }, [
                                    _c("div", { staticClass: "EventBar" }),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "日期操作", visible: _vm.CalendaringOperation },
          on: {
            "update:visible": function ($event) {
              _vm.CalendaringOperation = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-position": "left", "label-width": "100px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择日期" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "dates",
                      placeholder: "选择一个或多个日期",
                      "value-format": "timestamp",
                    },
                    model: {
                      value: _vm.CalendaringOperationdata.Date,
                      callback: function ($$v) {
                        _vm.$set(_vm.CalendaringOperationdata, "Date", $$v)
                      },
                      expression: "CalendaringOperationdata.Date",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "具体变化" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { "margin-right": "30px" },
                      attrs: { placeholder: "要怎么变" },
                      model: {
                        value: _vm.CalendaringOperationdata.Isholiday,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.CalendaringOperationdata,
                            "Isholiday",
                            $$v
                          )
                        },
                        expression: "CalendaringOperationdata.Isholiday",
                      },
                    },
                    _vm._l(_vm.DateOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                  _vm.CalendaringOperationdata.Isholiday === "true"
                    ? _c(
                        "el-select",
                        {
                          attrs: { placeholder: "因为什么" },
                          model: {
                            value:
                              _vm.CalendaringOperationdata.HolidayAttribute,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.CalendaringOperationdata,
                                "HolidayAttribute",
                                $$v
                              )
                            },
                            expression:
                              "CalendaringOperationdata.HolidayAttribute",
                          },
                        },
                        _vm._l(_vm.DateOptions_Holiday, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.CalendaringOperationdata.Isholiday === "false"
                    ? _c(
                        "el-select",
                        {
                          attrs: { placeholder: "因为什么" },
                          model: {
                            value:
                              _vm.CalendaringOperationdata.HolidayAttribute,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.CalendaringOperationdata,
                                "HolidayAttribute",
                                $$v
                              )
                            },
                            expression:
                              "CalendaringOperationdata.HolidayAttribute",
                          },
                        },
                        _vm._l(_vm.DateOptions_Work, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-button",
                { on: { click: _vm.UpdateCalendaringHolidays } },
                [_vm._v(" 确定 ")]
              ),
              _c(
                "el-button",
                { on: { click: _vm.ReturnCalendaringOperation } },
                [_vm._v(" 取消 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }