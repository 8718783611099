var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-container",
        { staticStyle: { padding: "20px" } },
        [
          _c(
            "el-header",
            { staticStyle: { "margin-bottom": "20px" } },
            [
              _c("el-card", [
                _c(
                  "span",
                  { staticStyle: { display: "inline-block" } },
                  [
                    _vm.filter.Status == "all"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                _vm.filter.Status = "open"
                                _vm.filerChange()
                              },
                            },
                          },
                          [_vm._v("显示全部")]
                        )
                      : _vm._e(),
                    _vm.filter.Status == "open"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                _vm.filter.Status = "close"
                                _vm.filerChange()
                              },
                            },
                          },
                          [_vm._v("显示未完成")]
                        )
                      : _vm._e(),
                    _vm.filter.Status == "close"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                _vm.filter.Status = "all"
                                _vm.filerChange()
                              },
                            },
                          },
                          [_vm._v("显示已完成")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    staticStyle: {
                      display: "inline-block",
                      "margin-left": "10px",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "关键字筛选" },
                      on: { change: _vm.filerChange },
                      model: {
                        value: _vm.filter.key,
                        callback: function ($$v) {
                          _vm.$set(_vm.filter, "key", $$v)
                        },
                        expression: "filter.key",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-main",
            [
              _c(
                "el-card",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.IssueList },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "supportNumber",
                          label: "工单号",
                          width: "180",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "modelname",
                          label: "故障模块",
                          width: "180",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "productSeriNum",
                          label: "序列号",
                          width: "180",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "descript",
                          label: "故障描述",
                          width: "180",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "username",
                          label: "提交人",
                          width: "180",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "usercompany",
                          label: "公司",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "usercompany",
                          label: "状态",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.Status == "open"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "warning" } },
                                      [_vm._v(" 未完成 ")]
                                    )
                                  : _vm._e(),
                                scope.row.Status == "close"
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "success" } },
                                      [_vm._v(" 已完成 ")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "createdAt",
                          label: "创建日期",
                          width: "180",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      new Date(
                                        scope.row.createdAt
                                      ).toLocaleString()
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "180", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editIssue(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("查看")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-footer",
            [
              _c(
                "el-card",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-around",
                  },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      total: _vm.total,
                      "page-size": _vm.filter.PageNum,
                    },
                    on: { "current-change": _vm.PageChange },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "IssueDialog",
          attrs: {
            title: _vm.SelectList.Status == "open" ? "编辑" : "查看",
            visible: _vm.EdittableViasiable,
            width: "80%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.EdittableViasiable = $event
            },
          },
        },
        [
          _c(
            "el-descriptions",
            { attrs: { title: "用户信息" } },
            [
              _c("el-descriptions-item", { attrs: { label: "模块" } }, [
                _vm._v(_vm._s(_vm.SelectList.modelname)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "手机号" } }, [
                _vm._v(_vm._s(_vm.SelectList.userphone)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "提交人" } }, [
                _vm._v(_vm._s(_vm.SelectList.username)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "提交人" } }, [
                _vm._v(_vm._s(_vm.SelectList.username)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "公司" } }, [
                _vm._v(_vm._s(_vm.SelectList.usercompany)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "设备序列号" } }, [
                _vm._v(_vm._s(_vm.SelectList.productSeriNum)),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "工单状态" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": "open",
                      "inactive-value": "close",
                      "active-text": "未完成",
                      "inactive-text": "已结束",
                    },
                    model: {
                      value: _vm.SelectList.Status,
                      callback: function ($$v) {
                        _vm.$set(_vm.SelectList, "Status", $$v)
                      },
                      expression: "SelectList.Status",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-card", { staticStyle: { "border-radius": "10px" } }, [
            _c("h3", [_vm._v("故障简述")]),
            _vm._v(" " + _vm._s(_vm.SelectList.Tittle) + " "),
          ]),
          _c(
            "el-card",
            { staticStyle: { "border-radius": "10px", "margin-top": "20px" } },
            [
              _c("h3", [_vm._v("详细信息")]),
              _vm._v(" " + _vm._s(_vm.SelectList.descript) + " "),
            ]
          ),
          _c(
            "el-card",
            { staticStyle: { "border-radius": "10px", "margin-top": "20px" } },
            [
              _c("h3", [_vm._v("视频和图片")]),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-content": "center",
                    "justify-content": "flex-start",
                    "align-items": "center",
                    "justify-items": "stretch",
                    "flex-direction": "row",
                    "flex-wrap": "wrap",
                  },
                },
                _vm._l(_vm.SelectList.files, function (item) {
                  return _c(
                    "el-card",
                    { key: item.name, staticStyle: { margin: "10px" } },
                    [
                      item.type == "image"
                        ? _c("el-image", {
                            staticStyle: { height: "100px" },
                            attrs: {
                              src:
                                "https://wx.sigmatek-oa.cn/issue/" +
                                _vm.SelectList._id +
                                "/" +
                                item.name,
                              "preview-src-list": [
                                "https://wx.sigmatek-oa.cn/issue/" +
                                  _vm.SelectList._id +
                                  "/" +
                                  item.name,
                              ],
                            },
                          })
                        : _c("video", {
                            staticStyle: { width: "100px" },
                            attrs: {
                              src:
                                "https://wx.sigmatek-oa.cn/issue/" +
                                _vm.SelectList._id +
                                "/" +
                                item.name,
                              controls: "",
                            },
                          }),
                    ],
                    1
                  )
                }),
                1
              ),
            ]
          ),
          _c(
            "el-card",
            { staticStyle: { "border-radius": "10px", "margin-top": "20px" } },
            [
              _c("h3", [_vm._v("解决方案")]),
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请输入内容",
                  autosize: { minRows: 5 },
                },
                model: {
                  value: _vm.SelectList.solution,
                  callback: function ($$v) {
                    _vm.$set(_vm.SelectList, "solution", $$v)
                  },
                  expression: "SelectList.solution",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "flex", "justify-content": "flex-end" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "20px",
                    "margin-bottom": "20px",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.EdittableViasiable = false
                        },
                      },
                    },
                    [_vm._v(" 关闭 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.updateIssue()
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }