import request from '@/utils/request'
export function createpackingandinvoice(data) {
    return request({
      url: '/Api/packingandinvoice/createpackingandinvoice',
      method: 'post',
      data
    })
  }
export function getpackingandinvoiceList(data) {
    return request({
      url: '/Api/packingandinvoice/getpackingandinvoiceList',
      method: 'post',
      data
    })
  }
export function updatepackingandinvoice(data) {
    return request({
      url: '/Api/packingandinvoice/updatepackingandinvoice',
      method: 'post',
      data
    })
  }

  export function deletepackingandinvoice(data) {
    return request({
      url: '/Api/packingandinvoice/deletepackingandinvoice',
      method: 'post',
      data
    })
  }
  export function getpackingandinvoiceByARTICLEInfo(data) {
    return request({
      url: '/Api/packingandinvoice/getpackingandinvoiceByARTICLEInfo',
      method: 'post',
      data
    })
  }
  export function Generateinvoice(data) {
    return request({
      url: '/Api/packingandinvoice/Generateinvoice',
      method: 'post',
      data,
      responseType: 'blob'
    })
  }
  export function Generateparking(data) {
    return request({
      url: '/Api/packingandinvoice/Generateparking',
      method: 'post',
      data,
      responseType: 'blob'
    })
  }
  export function GenerateUPSExportInvoice(data) {
    return request({
      url: '/Api/packingandinvoice/GenerateUPSExportInvoice',
      method: 'post',
      data,
      responseType: 'blob'
    })
  }
  export function GenerateUPSPackList(data) {
    return request({
      url: '/Api/packingandinvoice/GenerateUPSPackList',
      method: 'post',
      data,
      responseType: 'blob'
    })
  }
  export function GenerateInvoice_SA(data) {
    return request({
      url: '/Api/packingandinvoice/GenerateInvoice_SA',
      method: 'post',
      data,
      responseType: 'blob'
    })
  }
export function GenerateInvoice_SA_1(data) {
    return request({
      url: '/Api/packingandinvoice/GenerateInvoice_SA_1',
      method: 'post',
      data,
      responseType: 'blob'
    })
  }
  export function GeneratePackList_SA(data) {
    return request({
      url: '/Api/packingandinvoice/GeneratePackList_SA',
      method: 'post',
      data,
      responseType: 'blob'
    })
  }
  export function GetinvoiceCount() {
    return request({
      url: '/Api/packingandinvoice/GetinvoiceCount',
      method: 'get',
    })
  }

