import {  Message, MessageBox } from "element-ui";
import { getName } from '@/utils/auth'
import {createborrowapply,getborrowapply}from "@/api/borrowapply";
import{getcarlist,updatecarinfo} from "@/api/car"
const state = {
    detilvisiable:false,
    addapplyvisiable:false,
    applylist:[],
    datasukeda:[],
    datatuan:[],
    datagl8:[],
    newapply:{
        time:null,
        people:null,
        car:null,
        destination:null,
        project:null,
        state:"未开始借用",
    },
    rules:{
        time: [{ required: true, message: '请输入时间', trigger: 'blur' }],
        car:  [{ required: true, message: '请选择车辆', trigger: 'blur' }],
        destination:[{required: true, message: '请输入目的地', trigger: 'blur' }],
    },
    carlist:[],
    listQuery:{
        filters:null,
        sort:null,
     }, 
    usinglist:[],
    returnlist:[],
    waittinglist:[],
    userlist:[],
    selectday:null,   
}
const mutations={
    showdetil:state=>{ state.detilvisiable=true;},
    showaddapply:state=>{ state.addapplyvisiable=true;},
    closedetil:state=>{state.detilvisiable=false},
    closeaddapply:state=>{ state.addapplyvisiable=false;},
    clearnewapply:(state)=>{
        state.newapply={
        time:null,
        people:null,
        car:null,
        destination:null,
        project:null,
        days:null,
        state:"未开始借用"
    }},
    cleardata:state=>{
        state.datasukeda=[];
        state.datatuan=[];
        state.datagl8=[];
    },
    seletdaychange:(state,payload)=>{
        state.selectday = payload;
    }
}
const actions={
    async submitapply(){
        state.newapply.people = getName();
        const { data } = await createborrowapply(state.newapply);
        if (data) {
        Message({
            message: "创建成功",
            type: "success",
            duration: 5 * 1000,
        });
}
    },
    async getapplylist(context,payload){
        state.listQuery.filters="({time:{$regex:'"+payload+"'}})";
        const{data} = await getborrowapply(state.listQuery);
        state.applylist = data.list;
    },
    async getcarlist(context){
        const{data} = await getcarlist();
        state.carlist = data.list;
    },
    async getlists(context){
        var name = getName()
        var listQuery={};
        state.waittinglist=[],
        state.usinglist=[],
        state.returnlist=[],
        listQuery.filters="({people:'"+name+"'})";
        const{data} = await getborrowapply(listQuery);
        state.userlist = data.list;
        state.userlist.forEach(apply=>{
          if(apply.state=="等待归还")
          {state.returnlist.push(apply);}
          if(apply.state=="未开始借用")
          {state.waittinglist.push(apply);}
          if(apply.state=="借用中")
          {state.usinglist.push(apply);}
        })   
      },
}

export default {state,mutations,actions }