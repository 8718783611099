import request from '@/utils/request'


export function createborrowapply(data) {
    return request({
      url: '/Api/borrowapply/createborrowapply',
      method: 'post',
      data
    })
  }
  export function getborrowapply(params) {
    return request({
        url: '/Api/borrowapply/borrowapplyInfo',
        method: 'get',
        params
    })
  }
  export function updateborrowapply(data) {
    return request({
      url: '/Api/borrowapply/updateborrowapply',
      method: 'post',
      data
    })
  }
  export function deleteapplyinfo(id) {
    return request({
      url: '/Api/borrowapply/deleteapply',
      method: 'delete',
      params: id
    })
  }