<template>
  <div>
    <div v-if="type == 'comment'">
      <table border="1" cellspacing="0" id="repair-list">
        <tr
          height="30"
          v-for="(item, index) in commentAct.commentText"
          :key="index"
        >
          <td class="delete" v-on:click="remove(index)">{{ index + 1 }}</td>
          <td>
            <el-input
              type="text"
              v-model="item.data"
              class="edit-input"
              size="small"
            />
          </td>
        </tr>
        <tr height="30">
          <td class="create" v-on:click="creatNew" width="40">...</td>
          <td width="860"></td>
        </tr>
      </table>
      <span slot="footer" class="dialog-footer">
        <el-button class="button" type="primary" v-on:click="saveComment"
          >保存</el-button
        >
      </span>
    </div>
    <div v-if="type == 'ups'">
      <table>
        <tr>
          <td>
            <p>送出方式</p>
            <el-select v-model="upsAct.sendType">
              <el-option
                v-for="item in upsSend"
                :key="item.jobId"
                :label="item.sendType"
                :value="item.sendType"
              />
            </el-select>
            <!-- <el-input type="text" v-model="upsAct.sendType"  class="bottom-input" size="small" /> -->
          </td>
          <td>
            <p>送出时间</p>
            <el-input
              type="date"
              v-model="upsAct.sendDate"
              class="bottom-input"
              size="small"
            />
          </td>
          <td>
            <p>返回方式</p>
            <el-select v-model="upsAct.backType">
              <el-option
                v-for="item in upsBack"
                :key="item.jobId"
                :label="item.sendType"
                :value="item.sendType"
              />
            </el-select>
            <!-- <el-input type="text" v-model="upsAct.backType"  class="bottom-input" size="small" /> -->
          </td>
          <td>
            <p>返回时间</p>
            <el-input
              type="date"
              v-model="upsAct.backDate"
              class="bottom-input"
              size="small"
            />
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <p>ups单号</p>
            <el-input
              type="text"
              v-model="upsAct.upsOrder"
              class="bottom-input"
              size="small"
            />
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <p>备注</p>
            <el-input
              type="text"
              v-model="upsAct.comment"
              class="bottom-input"
              size="small"
            />
          </td>
        </tr>
      </table>
      <span slot="footer" class="dialog-footer">
        <el-button class="button" type="primary" v-on:click="saveUps"
          >保存</el-button
        >
      </span>
    </div>
    <div v-if="type == 'extra'">
      <el-upload
        action="''"
        multiple
        list-type="file-card"
        accept=".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PBG, .GIF, .pdf"
        :before-upload="beforeUpload"
        :file-list="fileList"
        :http-request="submitList"
      >
        <span
          ><i class="el-icon-plus" style="font-size: 14px"></i> 添加文件</span
        >
      </el-upload>
      <el-table
        v-loading="listLoading"
        :data="list"
        class="marginTop"
        element-loading-text="Loading"
        border
        fit
        highlight-current-row
        @row-click="annexPreview"
      >
        <el-table-column align="center" prop="created_at" label="文件列表">
          <template slot-scope="scope">
            <span>{{ filenamereplace(scope.row.fileName) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="250">
          <template slot-scope="scope">
            <el-button v-on:click="downloadFile(scope.row)">下载</el-button>
            <el-button v-on:click="deleteFile(scope.row)" type="gray"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-dialog :visible.sync="showDelete" title="删除">
     
              <h4>确定删除</h4>
            
                <span slot="footer" class="dialog-footer">
                
                  <el-button type="primary" v-on:click="deleteInfo">确定</el-button>
                  <el-button type="normal" v-on:click="showDelete=false">取消</el-button>
                </span>
              </el-dialog> -->
    </div>
    <div v-if="type == 'supplier'">
      
    </div>
  </div>
</template>

<script>
import { MessageBox, Message } from "element-ui";
import { fetchList, updateArticle, uploadExtra } from "@/api/article";
import { getList, download, deleteFile } from "@/api/table";
export default {
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: "success",
        draft: "info",
        deleted: "danger",
      };
      return statusMap[status];
    },
  },
  props: {
    type: {
      type: String,
      default: "CN",
    },
    comment: {
      type: Object,
      default: {
        id: null,
        commentText: [
          {
            data: null,
          },
        ],
      },
    },
    ups: {
      type: Object,
      default: {
        sendType: null,
        sendDate: null,
        backType: null,
        backDate: null,
        upsOrder: null,
        comment: null,
      },
    },
  },
  data() {
    return {
      list: null,
      addArr: [],
      upsSend: [
        {
          sendType: null,
        },
        {
          sendType: "ups",
        },
        {
          sendType: "人员携带",
        },
        {
          sendType: "大件",
        },
      ],
      upsBack: [
        {
          sendType: null,
        },
        {
          sendType: "商品",
        },
        {
          sendType: "人员携带",
        },
        {
          sendType: "大件",
        },
      ],
      listLoading: false,
      fileList: [],
      fileInfo: null,
      showDelete: false,
      routeProjectId: null,
      listQuery: {
        page: 1,
        limit: 5,
        type: this.type,
        sort: "+id",
      },
      routeFloorId: null,
      addFileName: null,
      loading: false,
      commentAct: this.comment,
      upsAct: this.ups,
    };
  },
  watch: {
    "comment.id": {
      handler(newName, oldName) {
        this.fileList = [];
        this.getPathList();
        this.commentAct = this.comment;
        this.upsAct = this.ups;
      },
      ups: {
        compare(newName, oldName) {
          //  this.fileList = [];
          //  this.getPathList();
          var a = newName;
        },
      },
    },
  },
  created() {
    this.getPathList();
  },
  methods: {
    async getPathList() {
      this.listLoading = true;
      this.listQuery = {
        page: 1,
        limit: 5,
        sort: "+id",
        filters: "({_id :'" + this.comment.id + "'})",
      };
      this.list = [];
      const { data } = await getList(this.listQuery);
      for (var i = 0; i < data.list[0].appendix.length; i++) {
        let obj = {
          fileName: null,
          path: null,
        };
        this.list.push(obj);
        var Business = data.list[0].appendix[i].split("\\");

        this.list[i].fileName = Business[Business.length - 1];
        this.list[i].path = data.list[0].appendix[i];
      }
      this.listLoading = false;
    },
    async deleteFile(file) {
      this.fileInfo = file;
      const { data } = await deleteFile({
        id: this.commentAct.id,
        data: this.fileInfo.path,
      });
      this.getPathList();
      //  this.showDelete = false;
    },
    // deleteFile(file){
    //    this.showDelete = true;
    //    this.fileInfo = file ;

    // },

    filenamereplace(data) {
      const inputStr = data;
      const pattern = /^file\/[^/]+\//;
      const outputStr = inputStr.replace(pattern, '');
      return outputStr;
    },

    remove(index) {
      this.commentAct.commentText.splice(index, 1);
    },
    async downloadFile(file) {
      const data = await download({
        path: file.path,
        filename: file.fileName,
      });
      var url = window.URL.createObjectURL(data);

      // blob.type = "application/pdf";
      var a = document.createElement("a");
      a.href = url;
      a.download = file.fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    },
    
    async annexPreview(file,column) {
      if (column.label == "文件列表") {
        var path = file.path;
        var filename = file.fileName;
        const data = await download({
          path: path,
          filename: filename,
        });
        if (
          filename.split(".").pop() == "jpg" ||
          filename.split(".").pop() == "jpeg" ||
          filename.split(".").pop() == "png" ||
          filename.split(".").pop() == "gif" ||
          filename.split(".").pop() == "bmp" ||
          filename.split(".")[1] == "JPG" ||
          filename.split(".")[1] == "JPEG" ||
          filename.split(".")[1] == "PBG" ||
          filename.split(".")[1] == "GIF"
        ) {
          let blob = new Blob([data], { type: "image/jpg" });
          var windowURL = window.URL;
          var url = windowURL.createObjectURL(blob);
          window.open(url);
        }
        if (filename.split(".").pop() == "log") {
          let blob = new Blob([data], { type: "text/plain" });
          var windowURL = window.URL;
          var url = windowURL.createObjectURL(blob);
          window.open(url);
        }
        if (filename.split(".").pop() == "pdf") {
          let blob = new Blob([data], { type: "application/pdf" });
          var windowURL = window.URL;
          var url = windowURL.createObjectURL(blob);
          window.open(url);
        }
      }
    },
    
    async submitList(param) {
      const formData = new FormData();
      formData.append("ModleName", param.file); // 要提交给后台的文件
      formData.append("projectFolder", this.commentAct.id); // 这个接口必要的项目id
      formData.append("subFolder", this.routeFloorId);

      // for(var i=0;i<this.addArr.length;i++){
      //     formData.append('fileUpload',this.addArr[i]);
      // }
      const { data } = await uploadExtra(formData, {
        path: this.commentAct.id,
      });
      this.getPathList();
      // let config = {
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //     'Authorization': this.token
      //   }
      // };
      // this.axios.post(apidate.uploadEnclosure,formData,config)
      //   .then((response) => {
      //       if(response.data.info=="success"){this.$message({
      //               type: 'success',
      //               message: '附件上传成功!'
      //           });
      //       }
      //   })
    },
    async saveUps() {
      if (this.comment.id) {
        const updateData = {
          id: this.comment.id,
          data: {
            ups: this.upsAct,
          },
        };
        const { data } = await updateArticle(updateData);
        if (data) {
          // this.showComment = false;
          Message({
            message: "保存成功",
            type: "success",
            duration: 5 * 1000,
          });
          this.$emit("saveExtras");
        }
      }
    },
    async saveComment() {
      if (this.comment.id) {
        const updateData = {
          id: this.commentAct.id,
          data: {
            Comment: this.commentAct.commentText,
          },
        };
        const { data } = await updateArticle(updateData);
        if (data) {
          // this.showComment = false;
          Message({
            message: "保存成功",
            type: "success",
            duration: 5 * 1000,
          });
          this.$emit("saveExtras");
        }
      }
    },
    resetAdd() {
      this.addArr = [];
    },
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);

      const isLt2M = file.size / 1024 / 1024 < 10; //这里做文件大小限制

      if (!isLt2M) {
        this.$message({
          message: "上传文件大小不能超过 10MB!",
          type: "warning",
        });
      }
      return isLt2M;
    },

    creatNew() {
      if (this.commentAct.commentText.length < 10) {
        this.commentAct.commentText.push({
          data: null,
        });
      }
    },
  },
};
</script>
<style scoped>
.delete:hover {
  background-image: url("../../../../Img/delete.jpg");
  background-repeat: no-repeat;
  color: transparent;
  background-size: cover;
  cursor: pointer;
}
.marginTop {
  margin-top: 30px;
}
.inline {
  width: 7px;
}
.button {
  margin-top: 20px;
  float: right;
}
.create:hover {
  background-image: url("../../../../Img/create.jpg");
  background-repeat: no-repeat;
  color: transparent;
  background-size: cover;
  cursor: pointer;
}
</style>