var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "OutBox" }, [
    _c(
      "div",
      { staticClass: "HeadButtonArea" },
      [
        _c("el-button", {
          directives: [
            {
              name: "print",
              rawName: "v-print",
              value: _vm.printObj,
              expression: "printObj",
            },
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
          attrs: { id: "print" },
        }),
        _c("el-button", { on: { click: _vm.beforePrint } }, [_vm._v("打印")]),
        _c("el-button", { on: { click: _vm.save } }, [_vm._v("保存")]),
        _c(
          "el-popover",
          {
            attrs: { placement: "top", width: "160" },
            model: {
              value: _vm.DeleteConfirmVisiable,
              callback: function ($$v) {
                _vm.DeleteConfirmVisiable = $$v
              },
              expression: "DeleteConfirmVisiable",
            },
          },
          [
            _c("p", [_vm._v("确认删除？")]),
            _c(
              "div",
              { staticStyle: { "text-align": "right", margin: "0" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "text" },
                    on: {
                      click: function ($event) {
                        _vm.DeleteConfirmVisiable = false
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: {
                      click: function ($event) {
                        ;(_vm.DeleteConfirmVisiable = false), _vm.deliteunit()
                      },
                    },
                  },
                  [_vm._v("确定")]
                ),
              ],
              1
            ),
            _c(
              "el-button",
              { attrs: { slot: "reference" }, slot: "reference" },
              [_vm._v("删除")]
            ),
          ],
          1
        ),
        _c(
          "router-link",
          {
            attrs: {
              to: {
                path: "shippingList",
              },
            },
          },
          [
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { type: "button" },
              },
              [_vm._v("返回")]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { attrs: { id: "printArea" } }, [
      _vm._m(0),
      _c("div", { staticClass: "SplitLine" }),
      _c("div", { staticClass: "Body" }, [
        _c("div", { staticClass: "BodyHead" }, [
          _c("div", { staticClass: "Unit" }, [
            _vm._m(1),
            _c(
              "div",
              {
                staticClass: "NumberInpunt1 HeadNumberInpunt",
                staticStyle: { width: "150px" },
              },
              [
                _c("el-input", {
                  model: {
                    value: _vm.HeadInof.NoteNr,
                    callback: function ($$v) {
                      _vm.$set(_vm.HeadInof, "NoteNr", $$v)
                    },
                    expression: "HeadInof.NoteNr",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "Unit" }, [
            _vm._m(2),
            _c(
              "div",
              {
                staticClass: "NumberInpunt1 HeadNumberInpunt",
                staticStyle: { width: "110px" },
              },
              [
                _c("el-input", {
                  model: {
                    value: _vm.HeadInof.ExpressNr,
                    callback: function ($$v) {
                      _vm.$set(_vm.HeadInof, "ExpressNr", $$v)
                    },
                    expression: "HeadInof.ExpressNr",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "Unit" }, [
            _vm._m(3),
            _c(
              "div",
              {
                staticClass: "NumberInpunt1 HeadNumberInpunt",
                staticStyle: { width: "100px" },
              },
              [
                _c("el-input", {
                  model: {
                    value: _vm.HeadInof.DateNr,
                    callback: function ($$v) {
                      _vm.$set(_vm.HeadInof, "DateNr", $$v)
                    },
                    expression: "HeadInof.DateNr",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "Company", staticStyle: { width: "62%" } }, [
          _c("div", { staticClass: "CompanyTittle" }, [
            _c("p", [_vm._v("采购商:")]),
            _vm._v(
              " " +
                _vm._s(
                  _vm.PurchAserInfo.Name ? _vm.PurchAserInfo.Name : "等待选择"
                ) +
                " "
            ),
          ]),
          _c("div", { staticClass: "Info1" }, [
            _c(
              "p",
              { staticStyle: { width: "100%", "text-align": "center" } },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.PurchAserInfo.Address
                        ? _vm.PurchAserInfo.Address
                        : "收货地址"
                    ) +
                    " "
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "Info1" }, [
            _c(
              "p",
              {
                staticStyle: {
                  width: "45%",
                  "text-align": "center",
                  "margin-right": "5%",
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.PurchAserInfo.People
                        ? _vm.PurchAserInfo.People
                        : "收货人"
                    ) +
                    " "
                ),
              ]
            ),
            _c("p", { staticStyle: { width: "50%" } }, [
              _vm._v("T:" + _vm._s(_vm.PurchAserInfo.Telephone)),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "Company", staticStyle: { width: "38%" } }, [
          _c("div", { staticClass: "CompanyTittle" }, [
            _c("p", [_vm._v("供应商:")]),
            _vm._v(" " + _vm._s(_vm.SupplierInfo.Name) + " "),
          ]),
          _c("div", { staticClass: "Info1" }, [
            _c(
              "p",
              { staticStyle: { width: "100%", "text-align": "center" } },
              [_vm._v(" " + _vm._s(_vm.SupplierInfo.Address) + " ")]
            ),
          ]),
          _c("div", { staticClass: "Info1" }, [
            _c("p", { staticStyle: { width: "45%", "margin-right": "5%" } }, [
              _vm._v(" T:" + _vm._s(_vm.SupplierInfo.Telephone) + " "),
            ]),
            _c("p", { staticStyle: { width: "50%" } }, [
              _vm._v("F:" + _vm._s(_vm.SupplierInfo.F)),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "DeliveryInfo" },
          [
            _c("div", [
              _c("p", { staticClass: "InfoTittle" }, [
                _vm._v("发货信息 DeliveryInfo"),
              ]),
              _c(
                "span",
                { ref: "noprint", staticClass: "addbutton" },
                [
                  _c("el-button", {
                    attrs: { icon: "el-icon-plus", circle: "" },
                    on: { click: _vm.addLine },
                  }),
                  _c("el-button", {
                    attrs: { icon: "el-icon-close", circle: "" },
                    on: { click: _vm.DeleteLine },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                ref: "DelieveryTable",
                attrs: {
                  data: _vm.DeliveryInfolist,
                  border: "",
                  "highlight-current-row": "",
                  id: "DelieveryTable",
                  "span-method": _vm.MergeCells,
                  "header-row-style": {
                    color: "#000",
                    "font-size": "13px",
                    "font-weight": 400,
                  },
                  "cell-class-name": "maintable",
                  "cell-style": {
                    "font-size": "10px",
                    color: "#000",
                    padding: "1px 0",
                  },
                },
                on: { "current-change": _vm.TableSelectedItemChange },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "Pos",
                    align: "center",
                    label: "Pos",
                    "min-width": "45px",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "Module",
                    label: "模块 \n Moodule",
                    align: "center",
                    "min-width": "85px",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "ArticleNr",
                    label: "订货号 \n ArticleNr",
                    align: "center",
                    "min-width": "90px",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "CustomId",
                    label: "物料号 \n CustomId",
                    align: "center",
                    "min-width": "90px",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "SerialNr",
                    label: "序列号 \n SerialNr",
                    align: "center",
                    "min-width": "85px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            { staticClass: "SerinalInput" },
                            [
                              _c("el-input", {
                                attrs: { type: "text" },
                                on: {
                                  change: function ($event) {
                                    return _vm.SerialNrChange(
                                      scope.row.SerialNr,
                                      scope.$index
                                    )
                                  },
                                },
                                model: {
                                  value: scope.row.SerialNr,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "SerialNr", $$v)
                                  },
                                  expression: "scope.row.SerialNr",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "reSerialNr",
                    label: "新序列号 \n reSerial",
                    align: "center",
                    "min-width": "85px",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "PartNr",
                    label: "零件号 \n PartNr",
                    align: "center",
                    "min-width": "85px",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "reportNr",
                    label: "退货号 \n reportNr",
                    align: "center",
                    "min-width": "85px",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "Notes",
                    label: "备注 \n Notes",
                    align: "center",
                    "min-width": "95px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            { staticClass: "SerinalInput" },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 1 },
                                },
                                model: {
                                  value: scope.row.Notes,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "Notes", $$v)
                                  },
                                  expression: "scope.row.Notes",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "Remark" }, [
          _c("p", { staticClass: "InfoTittle" }, [_vm._v("备注 Remark")]),
          _c(
            "div",
            { staticClass: "RemarkBody", attrs: { id: "Remark" } },
            [
              _vm._v(" 兹有我公司快递于" + _vm._s(_vm.RemarkTime) + ","),
              _vm._v("快递模块共"),
              _c("el-input", {
                staticClass: "remarkinput",
                model: {
                  value: _vm.RemarkComponent,
                  callback: function ($$v) {
                    _vm.RemarkComponent = $$v
                  },
                  expression: "RemarkComponent",
                },
              }),
              _vm._v(
                "块(维修返回品),为确保我公司货物安全及时到达贵公司,请收到货物即日五日内将此函签回传我司,确认您已收到。如五日内"
              ),
              _vm._v(
                "我公司未收到贵司的回传传真,会向快递公司查询签收人并自动确认已经收到。五日后再告知我公司未收到，我公司将不负任何责任，请贵司自行解决。特此告知！请予配合，谢谢！ "
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "bottomInfo" }, [
        _c("div", { staticClass: "relative" }, [
          _c("div", { staticClass: "area_right" }, [
            _c("span", { staticClass: "title-right" }, [
              _vm._v("客户签章 Signature: "),
            ]),
            _c(
              "div",
              { staticClass: "writeInput" },
              [
                _c("el-input", {
                  staticClass: "bottom-input",
                  attrs: { type: "text", size: "small" },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "bottomInfo" }, [
        _c("div", { staticClass: "relative" }, [
          _c("div", { staticClass: "area_right top" }, [
            _c("span", { staticClass: "title-right" }, [
              _vm._v("客户签章日期 Date: "),
            ]),
            _c(
              "div",
              { staticClass: "writeInput" },
              [
                _c("el-input", {
                  staticClass: "bottom-input",
                  attrs: { type: "text", size: "small" },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Tittle" }, [
      _c("h2", [
        _vm._v("发货清单"),
        _c("span", { staticClass: "italic" }, [_vm._v("Delivery Note")]),
      ]),
      _c("img", { attrs: { src: require("../../../Img/log.jpg") } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Word" }, [
      _c("span", [_vm._v("单号")]),
      _c("span", [_vm._v("Note Nr.")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Word" }, [
      _c("span", [_vm._v("快递单号")]),
      _c("span", [_vm._v("Express Nr.")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Word" }, [
      _c("span", [_vm._v("日期")]),
      _c("span", [_vm._v("Date")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }