import request from '@/utils/request'

export function createfaultclass(data) {
  return request({
    url: '/Api/faultclass/createfaultclass',
    method: 'post',
    data
  })
}
export function getfaultclasslist(params) {
  return request({
    url: '/Api/faultclass/getfaultclasslist',
    method: 'post',
    params
  })
}
export function update(data) {
  return request({
    url: '/Api/faultclass/update',
    method: 'post',
    data
  })
}
export function  deletefaultclass(id) {
  return request({
    url: '/Api/faultclass/delete',
    method: 'delete',
    params: id
  })
}