<template>
  <div>
    <el-button @click="onTouchAdd" type="primary" style="margin-top: 10px;margin-left: 10px;">新建物料</el-button>
    <input type="file" ref="fileInput" style="display: none" />
    
    <el-table :data="hardwardList" style="width: 100%" :default-sort="{prop: 'description', order: 'descending'}">
      <el-table-column prop="name" label="物料名" align="center" sortable>
      </el-table-column>
      <el-table-column prop="description" label="描述" align="center" sortable>
      </el-table-column>
      <el-table-column label="Error数量" align="center">
        <template slot-scope="scope">
          {{ scope.row.errorList.length }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="onTouchSelect(scope.row)">编辑</el-button>
          <el-popconfirm title="这是一段内容确定删除吗？" @confirm="onTouchDelete(scope.row)">
            <el-button size="mini" type="danger" slot="reference" style="margin-left: 20px">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    
    <el-dialog title="编辑" :visible.sync="editDialogVisiable" width="80%">

        <el-form label-position="left" label-width="100px">
          <el-form-item label="模块">
            <el-input 
              style="width: 400px;" 
              v-model="selecthardward.name" 
            />
          </el-form-item>
          <el-form-item label="描述">
            <el-input 
              style="width: 400px;" 
              v-model="selecthardward.description" 
            />
          </el-form-item>
          <el-form-item label="标签选择">
            <el-tag
              :key="tag"
              v-for="tag in selecthardward.details"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)">
              {{tag}}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput">
              + New Tag
            </el-button>
          </el-form-item>
        </el-form>
        
        <el-form label-position="left" label-width="200px">
          <el-form-item label="可见公司(不选择则全可见)">
             <el-checkbox-group 
               v-model="selecthardward.visiable" 
               @change ="CalcDepartmentList"
             >
               <el-checkbox 
                 v-for="item in companylist" 
                 :label="item.companyCode" 
                 :key="item.companyCode"
               >
                 {{ item.name }}
               </el-checkbox>
             </el-checkbox-group>
          </el-form-item>
        </el-form>
        
      <el-descriptions title="硬件信息" id="hardwardinfo">

        <!--<el-descriptions-item label="模块">-->
        <!--  <el-input v-model="selecthardward.name" />-->
        <!--</el-descriptions-item>-->
        
        <!--<el-descriptions-item label="描述">-->
        <!--  <el-input v-model="selecthardward.description" />-->
        <!--</el-descriptions-item>-->
        
        <!--<el-descriptions-item label="可见公司(不选择则全可见)">-->
        <!--  <el-checkbox-group v-model="selecthardward.visiable" @change ="CalcDepartmentList">-->
        <!--    <el-checkbox v-for="item in companylist" :label="item.companyCode" :key="item.companyCode">{{ item.name }}-->
        <!--    </el-checkbox>-->
        <!--  </el-checkbox-group>-->
        <!--</el-descriptions-item>-->
        

        <el-descriptions-item label="设备图片">
          <el-card>
            <el-image style="width: 100px; height: 100px" :src="`https://wx.sigmatek-oa.cn/product/${selecthardward.name
              }.svg?timestamp=${new Date().getTime()}`" fit="fit"></el-image>
          </el-card>
          <div style="margin-left: 20px">
            <el-button @click="updateImage">上传图片</el-button>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="产品文档">
          <el-card>
            <iframe :src="`https://wx.sigmatek-oa.cn/product/${selecthardward.name}.pdf`" width="100%" height="500px"
              v-if="selecthardward.HaveDocument"></iframe>
            <div v-else>暂无文档</div>
          </el-card>
          <div style="margin-left: 20px">
            <el-button @click="updatepdf">上传文档</el-button>
          </div>
        </el-descriptions-item>
      </el-descriptions>
      <el-table :data="selecthardward.errorList" style="width: 100%">
        <el-table-column label="描述" align="center" min-width="200px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.descript" />
          </template>
        </el-table-column>
        <el-table-column label="icon" align="center"  min-width="200px">
          <template slot-scope="scope">
            <!-- <i :class="'icon icon-' + scope.row.icon"></i> -->
            <el-select v-model="scope.row.icon" placeholder="请选择" class="Iocnselecter">
              <template #prefix>
                <i :class="'icon icon-' + scope.row.icon" style="font-size: 20px; color: black" />
              </template>
              <el-option v-for="item in iconList" :key="item" :label="renderOption(item)" :value="item"
                popper-class="IconSelectPoper">
                <i :class="'icon icon-' + item"></i>
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="可见部门" align="center"  min-width="200px"
          v-if="selecthardward.visiable && selecthardward.visiable.length > 0">
          <template slot-scope="scope">
            <el-checkbox-group v-model="scope.row.visiableDepartment">
              <el-checkbox v-for="item in DepartmentList" :label="item"></el-checkbox>
            </el-checkbox-group>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center"  min-width="80px">
          <template slot="header">
            <el-button type="primary" size="mini" @click="AddError">
              添加
            </el-button>
          </template>
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="deleteErrorList(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisiable = false">取 消</el-button>
        <el-button type="primary" @click="updateHardware">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getAllProducts,
  getWxCompanys,
  updateProductImage,
  IfHaveDocument,
  updateWxProduct,
  updateProductPdf,
  deleteWxProduct,
  createWxProduct,
} from "@/api/WxIssue";
export default {
  data() {
    return {
      hardwardList: [],
      selecthardward: {},
      editDialogVisiable: false,
      companylist: [],
      iconList: [],
      DepartmentList: [],
      
      inputVisible: false,
      inputValue: ''
     };
  },
  created() {
    this.GetAllProducts();
    this.loadCSSFile("../../assets/fonts/style.css");
  },
  methods: {
      
    handleClose(tag) {
      this.selecthardward.details.splice(this.selecthardward.details.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.selecthardward.details.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },      
      
      
    GetAllProducts() {
      getAllProducts()
        .then((res) => {
          this.hardwardList = res.data;
         // console.log(this.hardwardList);
        })
        .catch((err) => {
          console.log(err);
        });
      getWxCompanys()
        .then((res) => {
          this.companylist = this.companylist = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onTouchAdd() {
      createWxProduct()
        .then((res) => {
          this.$message({
            type: "success",
            message: "添加成功",
          });
          this.GetAllProducts();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updatepdf() {
      const fileInput = this.$refs.fileInput;
      fileInput.click(); // 触发文件选择对话框
      fileInput.onchange = () => {
        const file = fileInput.files[0];
        updateProductPdf(this.selecthardward.name, file)
          .then((res) => {
            this.$message({
              type: "success",
              message: "更新成功,刷新后查看",
            });
          })
          .catch((err) => {
            console.log(err);
            this.$message({
              type: "error",
              message: "更新失败",
            });
          });
      };
    },
    updateImage() {
      const fileInput = this.$refs.fileInput;

      fileInput.click(); // 触发文件选择对话框

      fileInput.onchange = () => {
        const file = fileInput.files[0];
        updateProductImage(this.selecthardward.name, file)
          .then((res) => {
            this.$message({
              type: "success",
              message: "更新成功,刷新后查看",
            });
          })
          .catch((err) => {
            console.log(err);
            this.$message({
              type: "error",
              message: "更新失败",
            });
          });
      };
    },
    renderOption(item) {
      return `<i class="icon icon-${item}"></i>`;
    },
    CalcDepartmentList() {
      this.DepartmentList = [];
      let T_companylist = this.selecthardward.visiable;
      for (let company of T_companylist) {
        let t = this.companylist.filter(item => item.companyCode == company);
        if (t) {
          t = t[0];
          for (let d of t.DepartmentList) {
            this.DepartmentList.push(`${t.companyCode}-${d}`);
          }
        }
      };
    },
    async onTouchSelect(input) {
      this.selecthardward = JSON.parse(JSON.stringify(input));
      this.CalcDepartmentList();
      let result = await IfHaveDocument({ name: this.selecthardward.name });
      this.selecthardward.HaveDocument = result.data ? 1 : 0;
      this.editDialogVisiable = true;
    },
    onTouchDelete(input) {
      deleteWxProduct(input).then((res) => {
        this.$message({
          type: "success",
          message: "删除成功",
        });
        this.GetAllProducts();
      });
    },
    AddError() {
      this.selecthardward.errorList.push({
        descript: "",
        icon: "",
      });
    },
    updateHardware() {
      updateWxProduct(this.selecthardward)
        .then((res) => {
          this.$message({
            type: "success",
            message: "更新成功",
          });
          this.editDialogVisiable = false;
          this.GetAllProducts();
        })
        .catch((err) => {
          console.log(err);
          this.$message({
            type: "error",
            message: "更新失败",
          });
          this.editDialogVisiable = false;
          this.GetAllProducts();
        });
    },
    loadCSSFile(url) {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = url;
      document.head.appendChild(link);
      link.onload = () => {
        this.parseCSSRules();
      };
    },
    deleteErrorList(row) {
      this.selecthardward.errorList = this.selecthardward.errorList.filter(
        (item) => {
          return item.descript !== row.descript;
        }
      );
    },
    parseCSSRules() {
      const styleSheets = document.styleSheets;
      for (let i = 0; i < styleSheets.length; i++) {
        const styleSheet = styleSheets[i];
        const rules = styleSheet.cssRules || styleSheet.rules;
        if (rules) {
          for (let j = 0; j < rules.length; j++) {
            const rule = rules[j];
            if (rule.selectorText && rule.selectorText.startsWith(".icon-")) {
              const className = rule.selectorText
                .replace(".icon-", "")
                .replace("::before", "");
              this.iconList.push(className);
            }
          }
        }
      }
    },
  },
};
</script>

<style>
#hardwardinfo .el-descriptions-item__container {
  align-items: center;
}

#hardwardinfo .el-descriptions-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

#hardwardinfo .el-descriptions-row>.el-descriptions-item {
  margin-left: 20px;
}

.Iocnselecter .el-input__inner {
  color: transparent;
  font-size: 0px;
  padding: 20px;
  padding-left: 35px;
  padding-right: 35px;
}

.Iocnselecter .el-input__prefix {
  top: 9px;
  left: 16px;
}

.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>