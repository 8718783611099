var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-container" },
    [
      _c(
        "div",
        [
          _c(
            "el-card",
            { staticClass: "TitleBox" },
            [
              _c("h5", { staticClass: "TitleBox_title" }, [_vm._v("用户管理")]),
              _c(
                "el-row",
                { staticClass: "TitleBox_body" },
                [
                  _c(
                    "el-col",
                    { staticClass: "TitleBox_bodytext", attrs: { span: 12 } },
                    [_c("p", [_vm._v("用户名：" + _vm._s(_vm.name))])]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "TitleBox_bodytext", attrs: { span: 12 } },
                    [_c("p", [_vm._v("用户权限：" + _vm._s(_vm.depatment))])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "CalendarNotice" },
            [
              _c(
                "el-col",
                { staticStyle: { height: "100%" }, attrs: { span: 18 } },
                [
                  _c(
                    "el-card",
                    { staticClass: "Calendar" },
                    [
                      _c("el-calendar", {
                        scopedSlots: _vm._u([
                          {
                            key: "dateCell",
                            fn: function (ref) {
                              var data = ref.data
                              return [
                                _c("div", { staticClass: "center" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        data.day.split("-").slice(2).join("-")
                                      ) +
                                      " "
                                  ),
                                ]),
                                _vm._l(
                                  _vm.Personal_Calendar_Collection,
                                  function (item, index) {
                                    return _c("div", { key: index }, [
                                      data.day == _vm.ToYYYYMMDD(item.Date)
                                        ? _c("div", [
                                            item.Occur_Business.length > 0
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          effect: "dark",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            attrs: {
                                                              slot: "content",
                                                            },
                                                            slot: "content",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.getContent_Business_When(
                                                                    item
                                                                  )
                                                                )
                                                            ),
                                                            _c("br"),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.getContent_Business_Where(
                                                                    item
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        item.Occur_Business[0]
                                                          .isAllDay
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "Business_Between",
                                                              },
                                                              [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "EventBar",
                                                                }),
                                                              ]
                                                            )
                                                          : item
                                                              .Occur_Business[0]
                                                              .LeaveTimeE &&
                                                            item
                                                              .Occur_Business[0]
                                                              .LeaveTimeS
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "Business_StartEnd",
                                                              },
                                                              [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "EventBar",
                                                                }),
                                                              ]
                                                            )
                                                          : item
                                                              .Occur_Business[0]
                                                              .LeaveTimeS
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "Business_Start",
                                                              },
                                                              [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "EventBar",
                                                                }),
                                                              ]
                                                            )
                                                          : item
                                                              .Occur_Business[0]
                                                              .LeaveTimeE
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "Business_End",
                                                              },
                                                              [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "EventBar",
                                                                }),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.Occur_Business.length === 0
                                              ? _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "Business_None",
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticClass: "EventBar",
                                                      }),
                                                    ]
                                                  ),
                                                ])
                                              : _vm._e(),
                                            item.Occur_Leave.length > 0
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          effect: "dark",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            attrs: {
                                                              slot: "content",
                                                            },
                                                            slot: "content",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.getContent_Leave_When(
                                                                    item
                                                                  )
                                                                )
                                                            ),
                                                            _c("br"),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.getContent_Leave_nature(
                                                                    item
                                                                  )
                                                                )
                                                            ),
                                                            _c("br"),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.getContent_Leave_Why(
                                                                    item
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        item.Occur_Leave[0]
                                                          .isAllDay
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "Leave_Between",
                                                              },
                                                              [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "EventBar",
                                                                }),
                                                              ]
                                                            )
                                                          : item.Occur_Leave[0]
                                                              .LeaveTimeS &&
                                                            item.Occur_Leave[0]
                                                              .LeaveTimeE
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "Leave_StartEnd",
                                                              },
                                                              [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "EventBar",
                                                                }),
                                                              ]
                                                            )
                                                          : item.Occur_Leave[0]
                                                              .LeaveTimeS
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "Leave_Start",
                                                              },
                                                              [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "EventBar",
                                                                }),
                                                              ]
                                                            )
                                                          : item.Occur_Leave[0]
                                                              .LeaveTimeE
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "Leave_End",
                                                              },
                                                              [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "EventBar",
                                                                }),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.Occur_Leave.length === 0
                                              ? _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "Business_None",
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticClass: "EventBar",
                                                      }),
                                                    ]
                                                  ),
                                                ])
                                              : _vm._e(),
                                            item.Occur_Overtime.length > 0
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          effect: "dark",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            attrs: {
                                                              slot: "content",
                                                            },
                                                            slot: "content",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.getContent_Overtime_When(
                                                                    item
                                                                  )
                                                                )
                                                            ),
                                                            _c("br"),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.getContent_Overtime_Where(
                                                                    item
                                                                  )
                                                                )
                                                            ),
                                                            _c("br"),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.getContent_Overtime_Why(
                                                                    item
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        item.Occur_Overtime[0]
                                                          .LeaveTimeS &&
                                                        item.Occur_Overtime[0]
                                                          .LeaveTimeE
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "Overtime_StartEnd",
                                                              },
                                                              [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "EventBar",
                                                                }),
                                                              ]
                                                            )
                                                          : item
                                                              .Occur_Overtime[0]
                                                              .LeaveTimeS
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "Overtime_Start",
                                                              },
                                                              [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "EventBar",
                                                                }),
                                                              ]
                                                            )
                                                          : item
                                                              .Occur_Overtime[0]
                                                              .LeaveTimeE
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "Overtime_End",
                                                              },
                                                              [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "EventBar",
                                                                }),
                                                              ]
                                                            )
                                                          : item
                                                              .Occur_Overtime[0]
                                                              .isAllDay
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "Overtime_Between",
                                                              },
                                                              [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "EventBar",
                                                                }),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ])
                                  }
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.MainCalendar,
                          callback: function ($$v) {
                            _vm.MainCalendar = $$v
                          },
                          expression: "MainCalendar",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { height: "100%" }, attrs: { span: 6 } },
                [
                  _c(
                    "div",
                    { staticClass: "Operate" },
                    [
                      _c(
                        "el-card",
                        { staticClass: "OperateCard" },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { "background-color": "lightblue" },
                              attrs: { size: "small", plain: "" },
                              on: { click: _vm.CreatBusinessReport },
                            },
                            [_vm._v(" 出差报备 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "background-color": "lightgreen" },
                              attrs: { size: "small", plain: "" },
                              on: { click: _vm.CreatLeaveReport },
                            },
                            [_vm._v(" 请假报备 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "background-color": "lightgray" },
                              attrs: { size: "small", plain: "" },
                              on: { click: _vm.CreatOvertimeReport },
                            },
                            [_vm._v(" 加班报备 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { float: "right" },
                              attrs: { size: "small", type: "success" },
                            },
                            [_vm._v(" 审核 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      staticStyle: { height: "100%" },
                    },
                    [
                      _c("el-card", { staticClass: "NoticeMini" }, [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [
                            _c("span", [_vm._v("通知信息")]),
                            _c(
                              "div",
                              { staticStyle: { float: "right" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { padding: "3px 0" },
                                    attrs: { type: "text" },
                                    on: { click: _vm.ChangeandWithdraw },
                                  },
                                  [_vm._v("修改/撤回")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { padding: "3px 0" },
                                    attrs: { type: "text" },
                                  },
                                  [_vm._v("展开")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        !_vm.Personal_Event_Collection.length
                          ? _c("div", { staticClass: "NoNotification" }, [
                              _c("img", {
                                staticStyle: { opacity: "0.3" },
                                attrs: {
                                  src: require("../dashboard/icon/NoNotification.png"),
                                },
                              }),
                            ])
                          : _vm._e(),
                        _vm.Personal_Event_Collection.length
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.Personal_Event_Collection.slice(0, 4),
                                function (One_Event, One_Event_Number) {
                                  return _c(
                                    "el-card",
                                    {
                                      key: One_Event_Number,
                                      staticClass: "NoticeInfo",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "header" },
                                          slot: "header",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "15px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.ToYYYYMMDD(
                                                      One_Event.LeaveTimeS
                                                    ) +
                                                      " " +
                                                      _vm.ToHHMM(
                                                        One_Event.LeaveTimeS
                                                      )
                                                  ) +
                                                  " ~ " +
                                                  _vm._s(
                                                    _vm.ToYYYYMMDD(
                                                      One_Event.LeaveTimeE
                                                    ) +
                                                      " " +
                                                      _vm.ToHHMM(
                                                        One_Event.LeaveTimeE
                                                      )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticStyle: { float: "right" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getStatus(
                                                    One_Event_Number
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("div", [
                                        _c(
                                          "ul",
                                          {
                                            staticStyle: {
                                              margin: "10px 0px 10px 0px",
                                            },
                                          },
                                          [
                                            One_Event.event === "请假"
                                              ? _c("li", [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          One_Event.event
                                                        ) +
                                                        "(" +
                                                        _vm._s(
                                                          One_Event.Occur_Leave
                                                            .nature
                                                        ) +
                                                        ")"
                                                    ),
                                                  ]),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        float: "right",
                                                        "padding-right": "20px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.TimeResolved(
                                                              One_Event
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              : _vm._e(),
                                            One_Event.event === "出差"
                                              ? _c("li", [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(One_Event.event)
                                                    ),
                                                  ]),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        float: "right",
                                                        "padding-right": "20px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.TimeResolved(
                                                              One_Event
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              : _vm._e(),
                                            One_Event.event === "加班"
                                              ? _c("li", [
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(One_Event.event)
                                                    ),
                                                  ]),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        float: "right",
                                                        "padding-right": "20px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.TimeResolved(
                                                              One_Event
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _c(
                                              "ul",
                                              {
                                                staticStyle: {
                                                  "margin-top": "10px",
                                                },
                                              },
                                              [
                                                One_Event.event === "出差"
                                                  ? _c("li", [
                                                      _c("span", [
                                                        _vm._v(
                                                          " 地点：" +
                                                            _vm._s(
                                                              One_Event
                                                                .Occur_Business
                                                                .Where
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ])
                                                  : _vm._e(),
                                                One_Event.event === "请假"
                                                  ? _c("li", [
                                                      _c("span", [
                                                        _vm._v(
                                                          " 原因：" +
                                                            _vm._s(
                                                              One_Event
                                                                .Occur_Leave.Why
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ])
                                                  : _vm._e(),
                                                One_Event.event === "加班"
                                                  ? _c("li", [
                                                      _c("span", [
                                                        _vm._v(
                                                          " 地点：" +
                                                            _vm._s(
                                                              One_Event
                                                                .Occur_Overtime
                                                                .Where
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ])
                                                  : _vm._e(),
                                                One_Event.event === "加班"
                                                  ? _c("li", [
                                                      _c("span", [
                                                        _vm._v(
                                                          " 原因：" +
                                                            _vm._s(
                                                              One_Event
                                                                .Occur_Overtime
                                                                .Why
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("el-divider", {
                                        staticClass: "card-footer-divider",
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "card-footer" },
                                        [
                                          _c("span", [_vm._v(" 意见 ")]),
                                          _c(
                                            "div",
                                            { staticClass: "Opinions" },
                                            [
                                              _c(
                                                "el-avatar",
                                                { attrs: { size: 30 } },
                                                [_vm._v(" 顾 ")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "padding-left": "10px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        One_Event.Report
                                                          .BossOpinions
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          !(
                                            _vm.userinfo.manager !== "顾瑞祥" ||
                                            _vm.userinfo.manager === null ||
                                            _vm.userinfo.hasOwnProperty(
                                              "manager"
                                            )
                                          )
                                            ? _c(
                                                "div",
                                                { staticClass: "Opinions" },
                                                [
                                                  _c(
                                                    "el-avatar",
                                                    { attrs: { size: 30 } },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.userinfo.manager
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "10px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            One_Event.Report
                                                              .ManagerOpinions
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "出差报备",
            visible: _vm.BusinessTripReport,
            width: "40%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.BusinessTripReport = $event
            },
            close: _vm.CancelReport,
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "130px", "label-position": "left" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "出差时间" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "80%" },
                    attrs: {
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "timestamp",
                      "default-time": ["09:00:00", "17:30:00"],
                      "picker-options": _vm.pickerOptions,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.calculateDays("出差")
                      },
                    },
                    model: {
                      value: _vm.datavalue,
                      callback: function ($$v) {
                        _vm.datavalue = $$v
                      },
                      expression: "datavalue",
                    },
                  }),
                ],
                1
              ),
              _vm.datavalue
                ? _c(
                    "el-form-item",
                    { staticClass: "TimeStatistics", attrs: { label: "" } },
                    [
                      _c("div", [
                        _vm._v(
                          "计算出出差时间为" +
                            _vm._s(_vm.getBusinessTimeInterval_Day) +
                            "天，"
                        ),
                      ]),
                      _c("div", [
                        _vm._v(
                          "其中有" +
                            _vm._s(_vm.getBusinessTimeInterval_Holiday) +
                            "天节假日"
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "出差地点" } },
                [
                  _c("el-input", {
                    staticClass: "edit-input",
                    attrs: {
                      list: "browsers",
                      type: "text",
                      placeholder: "支持模糊搜索，找不到可以手输",
                      "trigger-on-focus": false,
                    },
                    model: {
                      value: _vm.Temporary_Occur.Occur_Business.Where,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.Temporary_Occur.Occur_Business,
                          "Where",
                          $$v
                        )
                      },
                      expression: "Temporary_Occur.Occur_Business.Where",
                    },
                  }),
                  _c(
                    "datalist",
                    { attrs: { id: "browsers" } },
                    _vm._l(_vm.customerInfo, function (item) {
                      return _c("option", [
                        _vm._v(" " + _vm._s(item["company"]) + " "),
                      ])
                    }),
                    0
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "绑定项目（可选）" } },
                [
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { filterable: "", placeholder: "请选择" },
                        model: {
                          value: _vm.Temporary_Occur.Occur_Business.BindProject,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.Temporary_Occur.Occur_Business,
                              "BindProject",
                              $$v
                            )
                          },
                          expression:
                            "Temporary_Occur.Occur_Business.BindProject",
                        },
                      },
                      _vm._l(_vm.Projects, function (item) {
                        return _c(
                          "el-option",
                          {
                            key: item.value,
                            attrs: { label: item.label, value: item.id },
                          },
                          [
                            _c("span", { staticStyle: { float: "left" } }, [
                              _vm._v(" " + _vm._s(item.label) + " "),
                            ]),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  float: "right",
                                  color: "#8492a6",
                                  "font-size": "13px",
                                },
                              },
                              [_vm._v(" " + _vm._s(item.value) + " ")]
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否通报" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.Temporary_Occur.ShouldReport,
                      callback: function ($$v) {
                        _vm.$set(_vm.Temporary_Occur, "ShouldReport", $$v)
                      },
                      expression: "Temporary_Occur.ShouldReport",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "flex", "justify-content": "flex-end" } },
            [
              _c("el-button", { on: { click: _vm.CancelReport } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.ConfirmReport_Business },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "请假报备",
            visible: _vm.LeaveReport,
            width: "40%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.LeaveReport = $event
            },
            close: _vm.CancelReport,
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "130px", "label-position": "left" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "请假时间" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "80%" },
                    attrs: {
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "timestamp",
                      "default-time": ["09:00:00", "17:30:00"],
                      "picker-options": _vm.pickerOptions,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.calculateDays("请假")
                      },
                    },
                    model: {
                      value: _vm.datavalue,
                      callback: function ($$v) {
                        _vm.datavalue = $$v
                      },
                      expression: "datavalue",
                    },
                  }),
                ],
                1
              ),
              _vm.datavalue
                ? _c(
                    "el-form-item",
                    { staticClass: "TimeStatistics", attrs: { label: "" } },
                    [
                      _c("div", [
                        _vm._v(
                          "计算出请假时间为" +
                            _vm._s(_vm.getLeaveTimeInterval_Hour) +
                            "小时，"
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "请假原因" } },
                [
                  _c("el-input", {
                    staticClass: "edit-input",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.Temporary_Occur.Occur_Leave.Why,
                      callback: function ($$v) {
                        _vm.$set(_vm.Temporary_Occur.Occur_Leave, "Why", $$v)
                      },
                      expression: "Temporary_Occur.Occur_Leave.Why",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "请假类型" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.Temporary_Occur.Occur_Leave.nature,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.Temporary_Occur.Occur_Leave,
                            "nature",
                            $$v
                          )
                        },
                        expression: "Temporary_Occur.Occur_Leave.nature",
                      },
                    },
                    _vm._l(_vm.LeaveNatureOptions, function (group) {
                      return _c(
                        "el-option-group",
                        { key: group.label, attrs: { label: group.label } },
                        _vm._l(group.options, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.value, value: item.value },
                          })
                        }),
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "flex", "justify-content": "flex-end" } },
            [
              _c("el-button", { on: { click: _vm.CancelReport } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.ConfirmReport_Leave },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "加班报备",
            visible: _vm.OvertimeReport,
            width: "40%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.OvertimeReport = $event
            },
            close: _vm.CancelReport,
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "130px", "label-position": "left" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "加班时间" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "80%" },
                    attrs: {
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "timestamp",
                      "default-time": ["09:00:00", "17:30:00"],
                      "picker-options": _vm.pickerOptions,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.calculateDays("加班")
                      },
                    },
                    model: {
                      value: _vm.datavalue,
                      callback: function ($$v) {
                        _vm.datavalue = $$v
                      },
                      expression: "datavalue",
                    },
                  }),
                ],
                1
              ),
              _vm.datavalue
                ? _c(
                    "el-form-item",
                    { staticClass: "TimeStatistics", attrs: { label: "" } },
                    [_c("div", [_vm._v(_vm._s(_vm.getOvertimeTimeInterval))])]
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "加班地点" } },
                [
                  _c("el-input", {
                    staticClass: "edit-input",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.Temporary_Occur.Occur_Overtime.Where,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.Temporary_Occur.Occur_Overtime,
                          "Where",
                          $$v
                        )
                      },
                      expression: "Temporary_Occur.Occur_Overtime.Where",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "加班原因" } },
                [
                  _c("el-input", {
                    staticClass: "edit-input",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.Temporary_Occur.Occur_Overtime.Why,
                      callback: function ($$v) {
                        _vm.$set(_vm.Temporary_Occur.Occur_Overtime, "Why", $$v)
                      },
                      expression: "Temporary_Occur.Occur_Overtime.Why",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "flex", "justify-content": "flex-end" } },
            [
              _c("el-button", { on: { click: _vm.CancelReport } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.ConfirmReport_Overtime },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "Change_Dialog",
          attrs: {
            title: "修改/撤回",
            visible: _vm.ChangeDialog,
            width: "90%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.ChangeDialog = $event
            },
            close: function ($event) {
              _vm.CancelReport.then(_vm.fetchData())
            },
          },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 14 } },
            [
              _c("el-card", { staticClass: "Choose_Change" }, [
                _c(
                  "div",
                  {
                    staticClass: "Choose_Change_Header",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "row",
                          "align-items": "center",
                        },
                      },
                      [
                        _c("span", [_vm._v(" 选择修改条目 ")]),
                        _vm.userinfo.token === "Admin-token" ||
                        _vm.userinfo.token_Sigmatek === "Admin-token"
                          ? _c(
                              "div",
                              { staticStyle: { "margin-left": "50px" } },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "管理员权限",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c("el-switch", {
                                      attrs: {
                                        "active-color": "#13ce66",
                                        "inactive-color": "#ff4949",
                                      },
                                      model: {
                                        value:
                                          _vm.RapidScreen_AdministratorPrivileges,
                                        callback: function ($$v) {
                                          _vm.RapidScreen_AdministratorPrivileges =
                                            $$v
                                        },
                                        expression:
                                          "RapidScreen_AdministratorPrivileges",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.RapidScreen_AdministratorPrivileges
                          ? _c(
                              "div",
                              { staticStyle: { "margin-left": "10px" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "110px" },
                                    attrs: {
                                      placeholder: "请选择",
                                      size: "mini",
                                    },
                                    on: { change: _vm.SetRapidScreenListQuery },
                                    model: {
                                      value: _vm.RapidScreen_Name,
                                      callback: function ($$v) {
                                        _vm.RapidScreen_Name = $$v
                                      },
                                      expression: "RapidScreen_Name",
                                    },
                                  },
                                  _vm._l(_vm.Userlist, function (item) {
                                    return _c("el-option", {
                                      key: item.JobNumber,
                                      attrs: {
                                        label: item.username,
                                        value: item.username,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      [
                        _c("el-date-picker", {
                          staticStyle: {
                            "margin-right": "10px",
                            width: "110px",
                          },
                          attrs: {
                            type: "month",
                            placeholder: "选择月",
                            clearable: false,
                            size: "mini",
                          },
                          on: { change: _vm.SetRapidScreenListQuery },
                          model: {
                            value: _vm.RapidScreen_Month,
                            callback: function ($$v) {
                              _vm.RapidScreen_Month = $$v
                            },
                            expression: "RapidScreen_Month",
                          },
                        }),
                        _c(
                          "el-radio-group",
                          {
                            staticStyle: { "margin-right": "10px" },
                            attrs: { size: "mini" },
                            on: { change: _vm.SetRapidScreenListQuery },
                            model: {
                              value: _vm.RapidScreen_Event,
                              callback: function ($$v) {
                                _vm.RapidScreen_Event = $$v
                              },
                              expression: "RapidScreen_Event",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: "全部" } }),
                            _c("el-radio-button", { attrs: { label: "出差" } }),
                            _c("el-radio-button", { attrs: { label: "请假" } }),
                            _c("el-radio-button", { attrs: { label: "加班" } }),
                          ],
                          1
                        ),
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "mini" },
                            on: { change: _vm.SetRapidScreenListQuery },
                            model: {
                              value: _vm.RapidScreen_Revise,
                              callback: function ($$v) {
                                _vm.RapidScreen_Revise = $$v
                              },
                              expression: "RapidScreen_Revise",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: "全部" } }),
                            _c("el-radio-button", {
                              attrs: { label: "仅可修改" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading_RapidScreenTable,
                        expression: "listLoading_RapidScreenTable",
                      },
                    ],
                  },
                  [
                    [
                      _c(
                        "el-table",
                        {
                          ref: "RapidScreenTable",
                          attrs: {
                            data: _vm.RapidScreen_Table,
                            "default-sort": {
                              prop: "CreatTime",
                              order: "descending",
                            },
                            border: "",
                            fit: "",
                            "highlight-current-row": "",
                            height: "610px",
                          },
                          on: { "current-change": _vm.SelectionEvent },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "创建时间",
                              prop: "CreatTime",
                              sortable: "",
                              "min-width": "15%",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.ToYYMMDD(scope.row.CreatTime) +
                                            " " +
                                            _vm.ToHHMM(scope.row.CreatTime)
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "开始时间", "min-width": "15%" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.ToYYMMDD(scope.row.LeaveTimeS) +
                                            " " +
                                            _vm.ToHHMM(scope.row.LeaveTimeS)
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "持续时间", "min-width": "10%" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.RapidScreenAnalysis_Duration(
                                            scope.row
                                          )
                                        ) +
                                        " "
                                    ),
                                    scope.row.event === "出差"
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "font-size": "12px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.RapidScreenAnalysis_DurationWeekend(
                                                      scope.row
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "结束时间", "min-width": "15%" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.ToYYMMDD(scope.row.LeaveTimeE) +
                                            " " +
                                            _vm.ToHHMM(scope.row.LeaveTimeE)
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "事件", "min-width": "10%" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(" " + _vm._s(scope.row.event) + " "),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "详细", "min-width": "35%" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.event === "出差" ||
                                    scope.row.event === "加班"
                                      ? [
                                          _vm._v(
                                            " 地点：" +
                                              _vm._s(
                                                _vm.RapidScreenAnalysis_Where(
                                                  scope.row
                                                )
                                              ) +
                                              " "
                                          ),
                                          _c("br"),
                                        ]
                                      : _vm._e(),
                                    scope.row.event === "请假" ||
                                    scope.row.event === "加班"
                                      ? [
                                          _vm._v(
                                            " 原因：" +
                                              _vm._s(
                                                _vm.RapidScreenAnalysis_Why(
                                                  scope.row
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c("el-card", { staticClass: "Choose_Detailed" }, [
                _c(
                  "div",
                  {
                    staticClass: "Choose_Detailed_Header",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_c("span", [_vm._v(" 修改详细内容 ")])]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      height: "100%",
                      display: "flex",
                      "flex-direction": "column",
                      "flex-wrap": "nowrap",
                      "justify-content": "space-between",
                    },
                  },
                  [
                    _c("div", [
                      _vm.RapidScreen_NotSelected
                        ? _c("div", [
                            _c(
                              "ul",
                              {
                                staticStyle: {
                                  "line-height": "40px",
                                  "font-size": "20px",
                                },
                              },
                              [
                                _c(
                                  "h5",
                                  { staticStyle: { "font-size": "30px" } },
                                  [_vm._v("使用方法")]
                                ),
                                _c("li", [
                                  _vm._v("首先选中左边需要更改的条目"),
                                ]),
                                _c("li", [_vm._v("然后在此处进行修改")]),
                                _c("li", [_vm._v("最后点击下方的确定按钮")]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      !_vm.RapidScreen_NotSelected
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form",
                                {
                                  attrs: {
                                    "label-width": "120px",
                                    "label-position": "right",
                                    inline: true,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: " 事件" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "120px" },
                                          model: {
                                            value:
                                              _vm.RapidScreen_Table_SelectedInfo
                                                .event,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.RapidScreen_Table_SelectedInfo,
                                                "event",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "RapidScreen_Table_SelectedInfo.event",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "出差",
                                              value: "出差",
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "请假",
                                              value: "请假",
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "加班",
                                              value: "加班",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.RapidScreen_Table_SelectedInfo.event ===
                                  "请假"
                                    ? _c(
                                        "el-form-item",
                                        { attrs: { label: "请假类型" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "120px" },
                                              model: {
                                                value:
                                                  _vm
                                                    .RapidScreen_Table_SelectedInfo
                                                    .Occur_Leave.nature,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm
                                                      .RapidScreen_Table_SelectedInfo
                                                      .Occur_Leave,
                                                    "nature",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "RapidScreen_Table_SelectedInfo.Occur_Leave.nature",
                                              },
                                            },
                                            _vm._l(
                                              _vm.LeaveNatureOptions,
                                              function (group) {
                                                return _c(
                                                  "el-option-group",
                                                  {
                                                    key: group.label,
                                                    attrs: {
                                                      label: group.label,
                                                    },
                                                  },
                                                  _vm._l(
                                                    group.options,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.value,
                                                        attrs: {
                                                          label: item.value,
                                                          value: item.value,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm.RapidScreen_Table_SelectedInfo.event ===
                              "出差"
                                ? _c(
                                    "el-form",
                                    {
                                      attrs: {
                                        "label-width": "120px",
                                        "label-position": "right",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "出差开始时间" } },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              type: "datetime",
                                              "value-format": "timestamp",
                                              placeholder: "选择日期时间",
                                              "default-time": "9:00:00",
                                              "picker-options":
                                                _vm.pickerOptions,
                                            },
                                            on: {
                                              change:
                                                _vm.RapidScreen_CalculateDays,
                                            },
                                            model: {
                                              value:
                                                _vm
                                                  .RapidScreen_Table_SelectedInfo
                                                  .LeaveTimeS,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.RapidScreen_Table_SelectedInfo,
                                                  "LeaveTimeS",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "RapidScreen_Table_SelectedInfo.LeaveTimeS",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "出差结束时间" } },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              type: "datetime",
                                              "value-format": "timestamp",
                                              placeholder: "选择日期时间",
                                              "default-time": "17:30:00",
                                              "picker-options":
                                                _vm.pickerOptions,
                                            },
                                            on: {
                                              change:
                                                _vm.RapidScreen_CalculateDays,
                                            },
                                            model: {
                                              value:
                                                _vm
                                                  .RapidScreen_Table_SelectedInfo
                                                  .LeaveTimeE,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.RapidScreen_Table_SelectedInfo,
                                                  "LeaveTimeE",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "RapidScreen_Table_SelectedInfo.LeaveTimeE",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "TimeStatistics",
                                          attrs: { label: "" },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "计算出出差时间为" +
                                                _vm._s(
                                                  _vm.RapidScreen_getBusinessTimeInterval_Day
                                                ) +
                                                "天，"
                                            ),
                                          ]),
                                          _c("div", [
                                            _vm._v(
                                              "其中有" +
                                                _vm._s(
                                                  _vm.RapidScreen_getBusinessTimeInterval_Holiday
                                                ) +
                                                "天节假日"
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "出差地点" } },
                                        [
                                          _c("el-input", {
                                            staticClass: "edit-input",
                                            attrs: {
                                              list: "browsers",
                                              type: "text",
                                              placeholder:
                                                "支持模糊搜索，找不到可以手输",
                                              "trigger-on-focus": false,
                                            },
                                            model: {
                                              value:
                                                _vm
                                                  .RapidScreen_Table_SelectedInfo
                                                  .Occur_Business.Where,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm
                                                    .RapidScreen_Table_SelectedInfo
                                                    .Occur_Business,
                                                  "Where",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "RapidScreen_Table_SelectedInfo.Occur_Business.Where",
                                            },
                                          }),
                                          _c(
                                            "datalist",
                                            { attrs: { id: "browsers" } },
                                            _vm._l(
                                              _vm.customerInfo,
                                              function (item) {
                                                return _c("option", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item["company"]) +
                                                      " "
                                                  ),
                                                ])
                                              }
                                            ),
                                            0
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "绑定项目(可选)" } },
                                        [
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  filterable: "",
                                                  placeholder: "请选择",
                                                },
                                                model: {
                                                  value:
                                                    _vm
                                                      .RapidScreen_Table_SelectedInfo
                                                      .Occur_Business
                                                      .BindProject,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm
                                                        .RapidScreen_Table_SelectedInfo
                                                        .Occur_Business,
                                                      "BindProject",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "RapidScreen_Table_SelectedInfo.Occur_Business.BindProject",
                                                },
                                              },
                                              _vm._l(
                                                _vm.Projects,
                                                function (item) {
                                                  return _c(
                                                    "el-option",
                                                    {
                                                      key: item.value,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.id,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            float: "left",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.label
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            float: "right",
                                                            color: "#8492a6",
                                                            "font-size": "13px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.value
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                        ],
                                        2
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "是否通报" } },
                                        [
                                          _c("el-switch", {
                                            model: {
                                              value:
                                                _vm
                                                  .RapidScreen_Table_SelectedInfo
                                                  .ShouldReport,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.RapidScreen_Table_SelectedInfo,
                                                  "ShouldReport",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "RapidScreen_Table_SelectedInfo.ShouldReport",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.RapidScreen_Table_SelectedInfo.event ===
                              "请假"
                                ? _c(
                                    "el-form",
                                    {
                                      attrs: {
                                        "label-width": "120px",
                                        "label-position": "right",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "请假开始时间" } },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              type: "datetime",
                                              "value-format": "timestamp",
                                              placeholder: "选择日期时间",
                                              "default-time": "9:00:00",
                                              "picker-options":
                                                _vm.pickerOptions,
                                            },
                                            on: {
                                              change:
                                                _vm.RapidScreen_CalculateDays,
                                            },
                                            model: {
                                              value:
                                                _vm
                                                  .RapidScreen_Table_SelectedInfo
                                                  .LeaveTimeS,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.RapidScreen_Table_SelectedInfo,
                                                  "LeaveTimeS",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "RapidScreen_Table_SelectedInfo.LeaveTimeS",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "请假结束时间" } },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              type: "datetime",
                                              "value-format": "timestamp",
                                              placeholder: "选择日期时间",
                                              "default-time": "17:30:00",
                                              "picker-options":
                                                _vm.pickerOptions,
                                            },
                                            on: {
                                              change:
                                                _vm.RapidScreen_CalculateDays,
                                            },
                                            model: {
                                              value:
                                                _vm
                                                  .RapidScreen_Table_SelectedInfo
                                                  .LeaveTimeE,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.RapidScreen_Table_SelectedInfo,
                                                  "LeaveTimeE",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "RapidScreen_Table_SelectedInfo.LeaveTimeE",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "TimeStatistics",
                                          attrs: { label: "" },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "计算出请假时间为" +
                                                _vm._s(
                                                  _vm.RapidScreen_getLeaveTimeInterval_Hour
                                                ) +
                                                "小时，"
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "请假原因" } },
                                        [
                                          _c("el-input", {
                                            staticClass: "edit-input",
                                            attrs: { type: "text" },
                                            model: {
                                              value:
                                                _vm
                                                  .RapidScreen_Table_SelectedInfo
                                                  .Occur_Leave.Why,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm
                                                    .RapidScreen_Table_SelectedInfo
                                                    .Occur_Leave,
                                                  "Why",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "RapidScreen_Table_SelectedInfo.Occur_Leave.Why",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.RapidScreen_Table_SelectedInfo.event ===
                              "加班"
                                ? _c(
                                    "el-form",
                                    {
                                      attrs: {
                                        "label-width": "120px",
                                        "label-position": "right",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "加班开始时间" } },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              type: "datetime",
                                              "value-format": "timestamp",
                                              placeholder: "选择日期时间",
                                              "default-time": "9:00:00",
                                              "picker-options":
                                                _vm.pickerOptions,
                                            },
                                            on: {
                                              change:
                                                _vm.RapidScreen_CalculateDays,
                                            },
                                            model: {
                                              value:
                                                _vm
                                                  .RapidScreen_Table_SelectedInfo
                                                  .LeaveTimeS,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.RapidScreen_Table_SelectedInfo,
                                                  "LeaveTimeS",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "RapidScreen_Table_SelectedInfo.LeaveTimeS",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "加班结束时间" } },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              type: "datetime",
                                              "value-format": "timestamp",
                                              placeholder: "选择日期时间",
                                              "default-time": "17:30:00",
                                              "picker-options":
                                                _vm.pickerOptions,
                                            },
                                            on: {
                                              change:
                                                _vm.RapidScreen_CalculateDays,
                                            },
                                            model: {
                                              value:
                                                _vm
                                                  .RapidScreen_Table_SelectedInfo
                                                  .LeaveTimeE,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.RapidScreen_Table_SelectedInfo,
                                                  "LeaveTimeE",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "RapidScreen_Table_SelectedInfo.LeaveTimeE",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "TimeStatistics",
                                          attrs: { label: "" },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.RapidScreen_getOvertimeTimeInterval
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "加班地点" } },
                                        [
                                          _c("el-input", {
                                            staticClass: "edit-input",
                                            attrs: { type: "text" },
                                            model: {
                                              value:
                                                _vm
                                                  .RapidScreen_Table_SelectedInfo
                                                  .Occur_Overtime.Where,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm
                                                    .RapidScreen_Table_SelectedInfo
                                                    .Occur_Overtime,
                                                  "Where",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "RapidScreen_Table_SelectedInfo.Occur_Overtime.Where",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "加班原因" } },
                                        [
                                          _c("el-input", {
                                            staticClass: "edit-input",
                                            attrs: { type: "text" },
                                            model: {
                                              value:
                                                _vm
                                                  .RapidScreen_Table_SelectedInfo
                                                  .Occur_Overtime.Why,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm
                                                    .RapidScreen_Table_SelectedInfo
                                                    .Occur_Overtime,
                                                  "Why",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "RapidScreen_Table_SelectedInfo.Occur_Overtime.Why",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    !_vm.RapidScreen_NotSelected
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "flex-end",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { on: { click: _vm.RapidScreen_CancelReport } },
                                [_vm._v("取 消")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.RapidScreen_ConfirmReport },
                                },
                                [_vm._v("确 定")]
                              ),
                              _c(
                                "el-popconfirm",
                                {
                                  attrs: { title: "确定撤回吗？" },
                                  on: { confirm: _vm.RapidScreen_DeleteReport },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: {
                                        slot: "reference",
                                        type: "warning",
                                      },
                                      slot: "reference",
                                    },
                                    [_vm._v("撤 回")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }