<template>
    <div>
    <el-dialog
    :title="Tittle"
    :visible.sync="$store.state.label.addlablevisiable"
    width="40%">
        <el-form ref="Newlable" :model="Newlable" label-width="80px" :rules="rules">
            <el-form-item label="标签名称" prop="name">
                 <el-input v-model="Newlable.name"></el-input>
            </el-form-item>
            <el-form-item label="标签类型" prop="type" >
                 <el-select v-model="Newlable.type" placeholder="请选择"> <el-option v-for="item in $store.state.label.type" :key="item.value" :label="item.label" :value="item.value"> </el-option> </el-select> 
            </el-form-item>
             <el-form-item label="具体描述">
                 <el-input  type="textarea" v-model="Newlable.description"></el-input>
            </el-form-item>
        </el-form>
            <span slot="footer" class="dialog-footer" v-if="!editlabel">
                <el-button type="primary" @click="addlabel()">确 定</el-button>
            </span>
            <span slot="footer" class="dialog-footer" v-if="editlabel">
                <el-button type="primary" @click="changelabel()">修 改</el-button>
            </span>
    </el-dialog>
    </div>
</template>
<script>

import {  Message, } from "element-ui";
import {getlabelList}  from "@/api/label"
export default {
    computed:{
        Newlable(){ return this.$store.state.label.Newlable},
        Tittle(){return this.$store.state.label.title},
        editlabel(){return this.$store.state.label.editlabel}
        },
    methods:{
        async addlabel(){
            if(this.Newlable.name==null||this.Newlable.name==""||this.Newlable.type==null||this.Newlable.type=="")
            {
                Message({
                message: "创建失败，部分选项不能为空",
                type: "error",
                duration: 5 * 1000,});
            }
            else
            {    
               const{items} = await getlabelList({page: 1,limit: 1,filters:"({name:'"+this.Newlable.name+"'})"});
               if(items.list.length!=0)
               {
                Message({
                message: "创建失败，标签重复",
                type: "error",
                duration: 5 * 1000,});
               }
               else{
                    this.$store.dispatch("addlabel").then(
                val=>{
                    if(val==true)
                    {
                Message({
                message: "创建成功",
                type: "success",
                duration: 5 * 1000,});
                this.$store.commit('closeaddlabel');
                this.$store.dispatch("getlabelList");
                    }
                })
               }
            }
        },
        changelabel(){
        this.$store.dispatch("updatelabelList",this.Newlable);
        this.$store.commit("closeaddlabel");
        }
    },
    data(){
        return{
            rules:{
                name:[
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
                type:[
                    { required: true, message: '请选择型号', trigger: 'blur' }
                ],
            }
        }
    }
}
</script>
<style scoped>
div>>>.el-form-item__label{
    vertical-align: middle;
}
div>>>.el-input{
    display:inline-block;
    width: 70%;
}
div>>>.el-switch__core{
    margin-left: 30px;
}
</style>