<template>
  <div class="OutBox">
    <div class="HeadButtonArea">
      <el-button
        id="print_LYJBXD"
        v-print="printObj_LYJBXD"
        v-show="false"
      ></el-button>
      <el-button
        id="print_CCBTTJD"
        v-print="printObj_CCBTTJD"
        v-show="false"
      ></el-button>

      <el-button @click="beforePrint">打印</el-button>
      <el-button @click="SaveForm">保存</el-button>
      <!-- <el-button @click="asdfghjkl">调试</el-button> -->
      <router-link
        :to="{
          path: 'Project',
          query: { projectID: task.projectID,actTask : task },
        }"
      >
        <el-button style="margin-left: 10px;margin-right: 10px" type="button">返回</el-button>
      </router-link>

      <!-- <el-button style="margin-left: 10px" @click="OpenNewRulesWindow">
        帮助
      </el-button> -->

      <el-button
        v-if="radio == '零用金报销单'"
        :loading="downloadLoading"
        type="primary"
        @click="ExcelDownload"
      >
        导出excel
        <i class="el-icon-document"></i>
      </el-button>
    </div>

    <div style="margin-left: 10px; margin-top: 10px">
      <el-radio-group v-model="radio">
        <el-radio-button label="零用金报销单"></el-radio-button>
        <el-radio-button label="出差补贴统计单"></el-radio-button>
      </el-radio-group>
    </div>

    <!-- <el-dialog
      title="帮助"
      :visible.sync="NewRulesWindow"
      width="50%"
      :close-on-click-modal="false"
    >
    </el-dialog> -->

    <div style="display: flex;">
      <div id="printArea_LYJBXD" v-if="radio == '零用金报销单'">
        <div id="DataArea">
          <div class="Tittle">
            <el-container style="height: 100%">
              <el-aside width="50%" class="TitleText">零用金报销单</el-aside>
              <el-container>
                <el-header height="80%" class="TitleImage">
                  <img src="../../../Img/log.jpg" />
                </el-header>
                <el-footer height="20%" class="TitleEdition">
                  {{ ReimbursementDocumentVersionNumber }}
                </el-footer>
              </el-container>
            </el-container>
          </div>

          <div class="Split_Line"></div>

          <div class="Date">
            <el-container style="height: 100%">
              <el-aside width="60%" class="DateInfo">
                <div class="ReportDate">
                  <div class="ReportDate_Text">报表日期</div>
                  <div class="ReportDate_Date">
                    <div v-if="!PrintStatus" class="ReportDate_Date_Write">
                      <el-date-picker
                        v-model="ReportContentInfo.ReportDate"
                        size="mini"
                        type="date"
                        placeholder="选择日期"
                        value-format="timestamp"
                        @change="ChangeReportDate"
                      >
                      </el-date-picker>
                    </div>
                    <div v-if="PrintStatus" class="ReportDate_Date_Print">
                      {{ ReportContentInfo.ReportDate_Watch }}
                    </div>
                  </div>
                </div>

                <div class="ExpenseCode">
                  <div class="ExpenseCode_Text">费用代码</div>
                  <div class="ExpenseCode_Date">
                    <div v-if="!PrintStatus" class="ExpenseCode_Date_Write">
                      <el-input
                        v-model="ReportContentInfo.ExpenseCode"
                        placeholder="请输入内容"
                        size="mini"
                      >
                      </el-input>
                    </div>
                    <div v-if="PrintStatus" class="ExpenseCode_Date_Print">
                      {{ ReportContentInfo.ExpenseCode }}
                    </div>
                  </div>
                </div>

                <div class="SettlementDate">
                  <div class="SettlementDate_Text">结算日期</div>
                  <div class="SettlementDate_Date">
                    <div v-if="!PrintStatus" class="SettlementDate_Date_Write">
                      <el-input
                        v-model="this.ReportContentInfo.SettlementDateS_Watch"
                        placeholder=""
                        size="mini"
                        :disabled="true"
                      >
                      </el-input>
                    </div>
                    <div v-if="PrintStatus" class="SettlementDate_Date_Print">
                      {{ ReportContentInfo.SettlementDateS_Watch }}
                    </div>
                  </div>
                  <div class="SettlementDate_Text_to">到</div>
                  <div class="SettlementDate_Date">
                    <div v-if="!PrintStatus" class="SettlementDate_Date_Write">
                      <el-input
                        v-model="this.ReportContentInfo.SettlementDateE_Watch"
                        placeholder=""
                        size="mini"
                        :disabled="true"
                      >
                      </el-input>
                    </div>
                    <div v-if="PrintStatus" class="SettlementDate_Date_Print">
                      {{ ReportContentInfo.SettlementDateE_Watch }}
                    </div>
                  </div>
                  <div v-if="!PrintStatus" class="SettlementDate_icon">
                    <el-tooltip
                      content="结算日期关联经费明细中的日期，无需自己填写"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </div>
                </div>
              </el-aside>

              <el-container>
                <el-header height="33.3%" class="Sigmatek_Name">
                  {{ SigmatekInfo.Name }}
                </el-header>
                <el-main height="66.6%" class="Sigmatek_Info">
                  <p class="One_Sigmatek_Info">{{ SigmatekInfo.Address }}</p>
                  <p class="One_Sigmatek_Info">
                    T.{{ SigmatekInfo.Telephone }}/F.{{ SigmatekInfo.F }}
                  </p>
                  <p class="One_Sigmatek_Info">{{ SigmatekInfo.web }}</p>
                  <p class="One_Sigmatek_Info">{{ SigmatekInfo.Email }}</p>
                </el-main>
              </el-container>
            </el-container>
          </div>

          <div class="Info">
            <div class="Info_RelevantInfo">
              <div class="Info_RelevantInfo_line">
                <div class="Info_RelevantInfo_Text">员工姓名</div>
                <div v-if="!PrintStatus" class="Info_RelevantInfo_Write_Short">
                  <el-input
                    v-model="ReportContentInfo.RelevantInfo.EmployeeName"
                    placeholder=""
                    size="mini"
                  >
                  </el-input>
                </div>
                <div v-if="PrintStatus" class="Info_RelevantInfo_Print_Short">
                  {{ ReportContentInfo.RelevantInfo.EmployeeName }}
                </div>
                <div class="Info_ReimbursementInstructions_Interval"></div>
                <div class="Info_RelevantInfo_Text">所任职务</div>
                <div v-if="!PrintStatus" class="Info_RelevantInfo_Write_Long">
                  <el-input
                    v-model="ReportContentInfo.RelevantInfo.Position"
                    placeholder=""
                    size="mini"
                  >
                  </el-input>
                </div>
                <div v-if="PrintStatus" class="Info_RelevantInfo_Print_Long">
                  {{ ReportContentInfo.RelevantInfo.Position }}
                </div>
              </div>

              <div class="Info_RelevantInfo_line">
                <div class="Info_RelevantInfo_Text">所在部门</div>
                <div v-if="!PrintStatus" class="Info_RelevantInfo_Write_Short">
                  <el-input
                    v-model="ReportContentInfo.RelevantInfo.Department"
                    placeholder=""
                    size="mini"
                  >
                  </el-input>
                </div>
                <div v-if="PrintStatus" class="Info_RelevantInfo_Print_Short">
                  {{ ReportContentInfo.RelevantInfo.Department }}
                </div>
                <div class="Info_ReimbursementInstructions_Interval"></div>
                <div class="Info_RelevantInfo_Text">电子邮件</div>
                <div v-if="!PrintStatus" class="Info_RelevantInfo_Write_Long">
                  <el-input
                    v-model="ReportContentInfo.RelevantInfo.Email"
                    placeholder=""
                    size="mini"
                  >
                  </el-input>
                </div>
                <div v-if="PrintStatus" class="Info_RelevantInfo_Print_Long">
                  {{ ReportContentInfo.RelevantInfo.Email }}
                </div>
              </div>

              <div class="Info_RelevantInfo_line">
                <div class="Info_RelevantInfo_Text">部门经理</div>
                <div v-if="!PrintStatus" class="Info_RelevantInfo_Write_Short">
                  <el-input
                    v-model="ReportContentInfo.RelevantInfo.Manager"
                    placeholder=""
                    size="mini"
                  >
                  </el-input>
                </div>
                <div v-if="PrintStatus" class="Info_RelevantInfo_Print_Short">
                  {{ ReportContentInfo.RelevantInfo.Manager }}
                </div>
                <div class="Info_ReimbursementInstructions_Interval"></div>
                <div class="Info_RelevantInfo_Text">经理邮件</div>
                <div v-if="!PrintStatus" class="Info_RelevantInfo_Write_Long">
                  <el-input
                    v-model="ReportContentInfo.RelevantInfo.ManagerEmail"
                    placeholder=""
                    size="mini"
                  >
                  </el-input>
                </div>
                <div v-if="PrintStatus" class="Info_RelevantInfo_Print_Long">
                  {{ ReportContentInfo.RelevantInfo.ManagerEmail }}
                </div>
              </div>
            </div>

            <div class="Info_ReimbursementInstructions">
              <div class="Info_ReimbursementInstructions_Text">报销说明</div>
              <div
                v-if="!PrintStatus"
                class="Info_ReimbursementInstructions_Write"
              >
                <el-input
                  size="mini"
                  placeholder="请输入内容"
                  v-model="ReportContentInfo.ReimbursementInstructions"
                >
                </el-input>
              </div>
              <div
                v-if="PrintStatus"
                class="Info_ReimbursementInstructions_Print"
              >
                {{ ReportContentInfo.ReimbursementInstructions }}
              </div>
            </div>
          </div>

          <div class="Money">
            <div class="Money_Tittle">
              <div>
                经费明细
                <span v-if="!PrintStatus" class="Money_Tittle_Button">
                  <el-tooltip
                    content="增加一行"
                    :open-delay="1000"
                    placement="top"
                    effect="light"
                  >
                    <el-button
                      icon="el-icon-plus"
                      size="mini"
                      circle
                      @click="addLine"
                    />
                  </el-tooltip>
                </span>
              </div>
              <div>
                <span style="font-style: italic; font-size: 17px; color: black;" >
                  {{ ReportContentInfo.ArchiveLocation.Items_ProjectCode }}
                </span>
              </div>

            </div>

            <div v-if="!PrintStatus" class="Money_Details_Write">
              <el-table
                :data="ReportContentInfo.ReimbursementDetails"
                highlight-current-row
                ref="ReimbursementDetails"
                id="ReimbursementDetails"
                v-loading="loading"
                @current-change="onRowSelect"
                :header-cell-style="{
                  height: '5mm',
                  fontsize: '3.5mm',
                  fontWeight: 'bold',
                  backgroundColor: '#EBF0F9',
                  color: '#000000',
                }"
                cell-class-name="maintable"
                :cell-style="{
                  'font-size': '10px',
                  color: '#000',
                }"
              >
                <el-table-column
                  prop="Date"
                  :label="'日期'"
                  align="center"
                  min-width="30mm"
                >
                  <template slot-scope="scope">
                    <el-date-picker
                      v-model="scope.row.Date"
                      prefix-icon="null"
                      class="table_Date"
                      size="mini"
                      type="date"
                      placeholder="选择日期"
                      @change="DataChange(scope.row)"
                      value-format="timestamp"
                    >
                    </el-date-picker>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="Type"
                  :label="'科目'"
                  align="center"
                  min-width="24mm"
                >
                  <template slot-scope="scope">
                    <el-tooltip
                      effect="light"
                      content="与类别绑定，无需自己填写"
                      placement="top"
                    >
                      <el-input
                        v-model="scope.row.Type"
                        class="table_Type"
                        placeholder=""
                        size="mini"
                        :disabled="true"
                      >
                      </el-input>
                    </el-tooltip>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="Explain"
                  :label="'说明'"
                  align="center"
                  min-width="65mm"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.Explain"
                      class="table_Explain"
                      placeholder=""
                      size="mini"
                    >
                    </el-input>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="Classification"
                  :label="'类别'"
                  align="center"
                  min-width="30mm"
                >
                  <template slot-scope="scope">
                    <el-select
                      v-model="scope.row.Classification"
                      size="mini"
                      placeholder=""
                      clearable
                      @change="ClassificationChange(scope.row)"
                    >
                      <el-option
                        v-for="item in ReimbursementCategory"
                        :key="item.id"
                        :label="item.label"
                        :value="item.label"
                      >
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="Cost"
                  :label="'费用'"
                  align="center"
                  min-width="26mm"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.Cost"
                      class="table_Cost"
                      placeholder=""
                      size="mini"
                      dir="rtl"
                      @change="CostChange(scope.row)"
                    >
                    </el-input>
                  </template>
                </el-table-column>

                <el-table-column
                  prop=""
                  :label="''"
                  align="center"
                  min-width="10mm"
                >
                  <template slot-scope="scope">
                    <el-popover
                      placement="right"
                      trigger="click"
                      style="min-width: 15mm"
                      popper-class="Editpopper"
                      v-model="scope.row.toolvisiable"
                    >
                      <div class="EditButtonGroup">
                        <el-button
                          class="EditButton"
                          type="danger"
                          @click="remove(scope.$index)"
                        >
                          删除此行
                        </el-button>
                        <el-button
                          class="EditButton"
                          @click="MoveDown(scope.$index)"
                        >
                          下移此行
                        </el-button>
                        <el-button
                          class="EditButton"
                          @click="addRowBefore(scope.$index)"
                        >
                          在上方插入
                        </el-button>
                        <el-button
                          class="EditButton"
                          @click="addRowAfter(scope.$index)"
                        >
                          在下方插入
                        </el-button>
                      </div>
                      <el-button
                        v-show="scope.row === selectedRow"
                        slot="reference"
                        icon="el-icon-menu"
                        type="primary"
                        class="ButtonGroup_Menu"
                      />
                    </el-popover>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div v-if="PrintStatus" class="Money_Details_Print">
              <el-table
                :data="ReportContentInfo.ReimbursementDetails"
                ref="ReimbursementDetails"
                id="ReimbursementDetails"
                v-loading="loading"
                @current-change="onRowSelect"
                :header-row-class-name="Money_Details_Header_Print"
                cell-class-name="maintable"
                :cell-style="{
                  'font-size': '10px',
                  color: '#000',
                }"
              >
                <el-table-column
                  prop="Date"
                  :label="'日期'"
                  align="center"
                  min-width="30mm"
                >
                  <template slot-scope="scope">
                    <p class="printcommon">{{ scope.row.Date_Watch }}</p>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="Type"
                  :label="'科目'"
                  align="center"
                  min-width="24mm"
                >
                  <template slot-scope="scope">
                    <p class="printcommon">{{ scope.row.Type }}</p>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="Explain"
                  :label="'说明'"
                  align="left"
                  header-align="center"
                  min-width="50mm"
                >
                  <template slot-scope="scope">
                    <p class="printcommon">{{ scope.row.Explain }}</p>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="Classification"
                  :label="'类别'"
                  align="left"
                  header-align="center"
                  min-width="30mm"
                >
                  <template slot-scope="scope">
                    <p class="printcommon">{{ scope.row.Classification }}</p>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="Cost"
                  :label="'费用'"
                  align="right"
                  header-align="center"
                  min-width="26mm"
                >
                  <template slot-scope="scope">
                    <p class="printcommon">{{ scope.row.Cost_Watch }}</p>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div class="Money_Aggregation">
              <div class="Money_Aggregation_Line">
                <div class="Money_Aggregation_Text">小计</div>
                <div v-if="!PrintStatus" class="Money_Aggregation_Data_Write">
                  <el-input
                    v-model="ReportContentInfo.CostAggregation.Subtotal"
                    placeholder=""
                    size="mini"
                    dir="rtl"
                    :disabled="true"
                  >
                  </el-input>
                </div>
                <div
                  v-if="PrintStatus"
                  class="Money_Aggregation_Data_Subtotal_Print"
                >
                  {{ ReportContentInfo.CostAggregation.Subtotal_Watch }}
                </div>
              </div>

              <div class="Money_Aggregation_Line">
                <div class="Money_Aggregation_Text">预支款</div>
                <div v-if="!PrintStatus" class="Money_Aggregation_Data_Write">
                  <el-input
                    v-model="ReportContentInfo.CostAggregation.Advance"
                    placeholder=""
                    size="mini"
                    dir="rtl"
                    @change="AdvanceWatchChange"
                  >
                  </el-input>
                </div>
                <div v-if="PrintStatus" class="Money_Aggregation_Data_Print">
                  {{ ReportContentInfo.CostAggregation.Advance_Watch }}
                </div>
              </div>

              <!-- <div class="Money_Aggregation_Line">
                <div class="Money_Aggregation_Text">出差补贴</div>
                <div v-if="!PrintStatus" class="Money_Aggregation_Data_Write">
                  <el-input
                    v-model="ReportContentInfo.CostAggregation.TravelAllowance"
                    placeholder=""
                    size="mini"
                    dir="rtl"
                    @change="TravelAllowanceChange()"
                  >
                  </el-input>
                </div>
                <div v-if="PrintStatus" class="Money_Aggregation_Data_Print">
                  {{ ReportContentInfo.CostAggregation.TravelAllowance_Watch }}
                </div>
              </div> -->

              <div class="Money_Aggregation_Line">
                <div class="Money_Aggregation_Text">报销合计</div>
                <div v-if="!PrintStatus" class="Money_Aggregation_Data_Write">
                  <el-input
                    v-model="ReportContentInfo.CostAggregation.TotalReimbursement"
                    placeholder=""
                    size="mini"
                    :disabled="true"
                    dir="rtl"
                  >
                  </el-input>
                </div>
                <div
                  v-if="PrintStatus"
                  class="Money_Aggregation_Data_TotalReimbursement_Print"
                >
                  {{ ReportContentInfo.CostAggregation.TotalReimbursement_Watch }}
                </div>
              </div>
            </div>
          </div>

          <div class="Sign">
            <div class="Sign_remarks">
              <div class="Sign_remarks_Text">备注</div>
              <div class="Sign_remarks_Input">
                <div v-if="!PrintStatus" class="Sign_remarks_Input_Write">
                  <el-input
                    type="textarea"
                    autosize
                    placeholder="请输入内容"
                    v-model="ReportContentInfo.SignText"
                  >
                  </el-input>
                </div>
                <div v-if="PrintStatus" class="Sign_remarks_Input_Print">
                  {{ ReportContentInfo.SignText }}
                </div>
              </div>
            </div>

            <div class="Sign_name">
              <div class="One_Sign_name">
                <div class="Sign_name_Text">员工</div>
                <div class="Sign_name_Sign"></div>
              </div>

              <div class="One_Sign_name">
                <div class="Sign_name_Text">复核</div>
                <div class="Sign_name_Sign"></div>
              </div>

              <div class="One_Sign_name">
                <div class="Sign_name_Text">财务</div>
                <div class="Sign_name_Sign"></div>
              </div>

              <div class="One_Sign_name">
                <div class="Sign_name_Text">审批</div>
                <div class="Sign_name_Sign"></div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="DataFooter">
          宁波西格玛泰克自动化设备有限公司Ningbo Sigmatek Automation Co.,Ltd.{{ currentDate }} - {{ currentTime }} - Page:1/1
        </div> -->
      </div>

      <div id="printArea_CCBTTJD" v-if="radio == '出差补贴统计单'">
        <div id="CCBTTJD_DataArea">
          <div class="CCBTTJD_Tittle">
            <div class="CCBTTJD_Tittle_Logo">
              <img src="../../../Img/log.jpg" />
            </div>
            <div class="CCBTTJD_Tittle_ExpenseCode">
              <div class="CCBTTJD_Tittle_ExpenseCode_Left">
                <div class="CCBTTJD_Tittle_ExpenseCode_Text">报销单号：</div>
                <div class="CCBTTJD_Tittle_ExpenseCode_ExpenseCode">
                  {{ ReportContentInfo.ExpenseCode }}
                </div>
              </div>
              <div v-if="!PrintStatus" class="CCBTTJD_Tittle_ExpenseCode_Right">
                <div style="width: 110px;">
                  日期快速选择：
                </div>
                <div class="CCBTTJD_Tittle_ExpenseCode_QuickDateSelection">
                  <el-date-picker
                    size="mini"
                    type="dates"
                    v-model="QuickDateSelection"
                    value-format="timestamp"
                    :clearable="false"
                    @change="AssignDatesToData"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="CCBTTJD_Date">
            <el-table
              :data="ReportContentInfo.TravelSubsidyStatistics"
              border
              class="CCBTTJD_Date_Table"
              ref="TravelSubsidyStatistics"
              id="TravelSubsidyStatistics"
              v-loading="loading"
              :header-row-class-name="CCBTTJD_Date_Header_Print"
              cell-class-name="maintable"
              :cell-style="{
                height: '7mm',
                'font-size': '12px',
                padding: '0px',
                color: '#000',
                borderColor:'#C0C0C0',
              }"
            >
              <el-table-column
                prop="Date"
                :label="'日期'"
                align="center"
                width="105px"
              >
                <template slot-scope="scope">
                  <div v-if="!PrintStatus">
                    <!-- 因为bug太多，单独日期自选功能现已关闭 -->
                    <!-- <el-date-picker
                      v-model="scope.row.Date"
                      prefix-icon="null"
                      class="CCBTTJD_Date_Data_Input_Write"
                      size="mini"
                      type="date"
                      placeholder="选择日期"
                      value-format="timestamp"
                      :clearable="true"
                      @change="CCBTTJD_DataChange(scope.row)"
                      :picker-options="pickerOptions"
                    >
                    </el-date-picker> --> 
                    {{ scope.row.Date ? timestampToYMD(scope.row.Date) : null }}
                  </div>
                  <div v-if="PrintStatus" class="CCBTTJD_Date_Data_Print">
                    {{ scope.row.Date_Watch }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                prop="week"
                :label="'星期'"
                align="center"
                width="90px"
              >
                <template slot-scope="scope">
                  {{ scope.row.week }}
                </template>
              </el-table-column>

              <el-table-column
                prop="Businesslocation"
                :label="'出差地'"
                align="center"
                width="105px"
              >
                <template slot-scope="scope">
                  <div v-if="scope.row.hasDate">
                    <div v-if="!PrintStatus">
                      <el-select
                        v-model="scope.row.Businesslocation"
                        size="mini"
                        class="CCBTTJD_Date_Businesslocation_Input_Write"
                        placeholder=""
                        @change="BusinesslocationChange(scope.row)"
                        @input="$forceUpdate()"
                      >
                        <el-option
                          v-for="item in LocalOrNot"
                          :key="item.id"
                          :label="item.label"
                          :value="item.label"
                        >
                        </el-option>
                      </el-select>
                    </div>
                    <div v-if="PrintStatus">
                      {{ scope.row.Businesslocation }}
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                prop="AccommodationOrNot"
                :label="'住宿'"
                align="center"
                width="58px"
              >
                <template slot-scope="scope">
                  <div v-if="scope.row.isField">
                    <div v-if="!PrintStatus">
                      <el-select
                        v-model="scope.row.AccommodationOrNot"
                        size="mini"
                        class="CCBTTJD_Date_AccommodationOrNot_Input_Write"
                        placeholder=""
                        @change="AccommodationOrNotChange(scope.row)"
                        @input="$forceUpdate()"
                      >
                        <el-option
                          v-for="item in YesOrNot"
                          :key="item.id"
                          :label="item.label"
                          :value="item.label"
                        >
                        </el-option>
                      </el-select>
                    </div>
                    <div v-if="PrintStatus">
                      {{ scope.row.AccommodationOrNot }}
                    </div>
                  </div>

                  <div v-if="!scope.row.isField">
                    <div v-if="!PrintStatus" style="text-align: left ; margin-left: 5px;">
                      {{ scope.row.AccommodationOrNot }}
                    </div>
                    <div v-if="PrintStatus">
                      {{ scope.row.AccommodationOrNot }}
                    </div>
                  </div> 

                </template>
              </el-table-column>

              <el-table-column
                prop="TravelAllowance"
                :label="'补助'"
                align="right"
                header-align="center"
                width="98px"
              >
                <template slot-scope="scope">
                  <div v-if="!PrintStatus">
                    {{ scope.row.TravelAllowance }}
                  </div>
                  <div v-if="PrintStatus">
                    {{ scope.row.TravelAllowance_Watch }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                prop="AssignToReimbursement"
                type="number"
                :label="'报销'"
                align="right"
                header-align="center"
                width="98px"
              >
                <template slot-scope="scope">
                  <div v-if="scope.row.BaoXiaoJiSuan">
                    <div v-if="!PrintStatus">
                      <el-input
                        v-model="scope.row.AssignToReimbursement" 
                        class="CCBTTJD_Date_AssignToReimbursement_Input_Write"
                        placeholder=""
                        size="mini"
                        @change="AssignToReimbursementChange(scope.row)"
                        @input="$forceUpdate()"
                      />
                    </div>
                  </div>
                  <div v-if="PrintStatus">
                    {{ scope.row.AssignToReimbursement_Watch }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                prop="AssignTosalary"
                type="number"
                :label="'工资'"
                align="right"
                header-align="center"
                width="98px"
              >
                <template slot-scope="scope">
                  <div v-if="scope.row.BaoXiaoJiSuan">
                    <div v-if="!PrintStatus">
                      <el-input
                        v-model="scope.row.AssignTosalary"
                        class="CCBTTJD_Date_AssignTosalary_Input_Write"
                        placeholder=""
                        size="mini"
                        @change="ReimbursementAssignment(scope.row)"
                        @input="$forceUpdate()"
                      />
                    </div>
                  </div>
                  <div v-if="PrintStatus">
                    {{ scope.row.AssignTosalary_Watch }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div class="CCBTTJD_Subtotal">
            <table class="CCBTTJD_Subtotal_Table" border="1" ref="normalTable">
              <tr>
                <td colspan="4"></td>
                <td style="width: 98px;">小计</td>
                <td style="width: 98px;">
                  <div v-if="!PrintStatus">
                    {{ ReportContentInfo.TravelSubsidySubtotal_AssignToReimbursement }}
                  </div>
                  <div v-if="PrintStatus">
                    {{ ReportContentInfo.TravelSubsidySubtotal_AssignToReimbursement_Watch }}
                  </div>
                </td>
                <td style="width: 98px;">
                  <div v-if="!PrintStatus">
                    {{ ReportContentInfo.TravelSubsidySubtotal_AssignTosalary }}
                  </div>
                  <div v-if="PrintStatus">
                    {{ ReportContentInfo.TravelSubsidySubtotal_AssignTosalary_Watch }}
                  </div>
                </td>
              </tr>
            </table>
          </div>

        </div>
      </div>

      <div id="NewRoles">
        <el-collapse v-model="activeName" accordion>

          <el-collapse-item name="1">
            <template slot="title">
              <el-tag size="mini" style="margin-right: 5px;">
                Top
              </el-tag> 
              报销步骤
            </template>
            <div>
              <img
                src="../ProjectTemplate/ReimbursementSteps/BaoXiaoStepOnline.jpg"
                style="height: 380px; width: 100%; object-fit: contain"
              />
              <el-link
                href="https://www.ilovepdf.com/zh-cn"
                type="primary"
                target="_blank"
                >线上pdf编辑网址
              </el-link>
              <img
                src="../ProjectTemplate/ReimbursementSteps/BaoXiaoStepOffline.jpg"
                style="height: 380px; width: 100%; object-fit: contain"
              />
            </div>
          </el-collapse-item>

          <el-collapse-item name="2">
            <template slot="title">
              <el-tag size="mini" style="margin-right: 5px;">
                New
              </el-tag> 
              经费明细-类别 各项目解释
            </template>
            <div style="padding-left: 50px;">
              <span style="line-height: 3;">
                报销类别可能会有争议点的部分在下方列出
              </span>

              <el-table
                :data="ReimbursementRoles_Travel"
                border
                style="width: 100%">
                <el-table-column
                  prop="Type"
                  label="类别（路费相关）"
                  width="180">
                </el-table-column>
                <el-table-column
                  prop="Describe"
                  label="解释">
                </el-table-column>
              </el-table>

              <el-table
                :data="ReimbursementRoles_Other"
                border
                style="width: 100%;margin-top: 10px;">
                <el-table-column
                  prop="Type"
                  label="类别（其他）"
                  width="180">
                </el-table-column>
                <el-table-column
                  prop="Describe"
                  label="解释">
                </el-table-column>
              </el-table>
              
            </div>
          </el-collapse-item>
          
          <el-collapse-item title="出差补贴填写" name="3">
            <div style="padding-left: 50px;">
              <img
                src="../ProjectTemplate/NewRules/出差报销填写.jpg"
                style="height: 200px; width: 100%; padding-left: 50px; object-fit: contain"
              />
              <span style="line-height: 3;">
                1."出差补贴"这一栏以作为单独一项，合并在了经费明细中，需要和其他费用一起申报。<br/>
                2.要将"出差补贴"这一项至于经费明细的第一行或者最后一行。<br/> 
                3.如果是在第一行，日期需要填写出差第一天；如果是最后一行，日期需要填写出差最后一天。
              </span>
            </div>
          </el-collapse-item>

        </el-collapse>
      </div>
    </div>

  </div>
</template>

<script>
var DisabledDateUsing = []; //日期禁用选项，已弃用
import { Loading } from "element-ui";
import { getName } from "@/utils/auth";
import { getInfobyname } from "@/api/user";
import {
  getTasklist,
  getProjectlist,
  GetReimbursement,
  UpdateReimbursement,
  CreateReimbursement,
} from "@/api/technology";
const ww = require("chinese-workday");
const isWorkday = ww.isWorkday;
const isHoliday = ww.isHoliday;
const getFestival = ww.getFestival;
const isAddtionalWorkday = ww.isAddtionalWorkday;

export default {
  name: "shippingUnit",
  data() {
    return {
      printObj_LYJBXD: {
        id: "printArea_LYJBXD",
        beforeOpenCallback(vue) {
          vue.loadingInstance.close();
        },
      },
      printObj_CCBTTJD: {
        id: "printArea_CCBTTJD",
        beforeOpenCallback(vue) {
          vue.loadingInstance.close();
        },
      },
      // pickerOptions: {
      //   disabledDate(time) {
      //     const Condition_1 = time.getTime() > Date.now();
      //     const Condition_2 = DisabledDateUsing.includes(time.getTime());
      //     return Condition_1 || Condition_2;
      //   },
      // },    
          

      PrintStatus: false,
      ReimbursementDocumentVersionNumber: "V2022.01", //报销单版本号
      SigmatekInfo: {
        Name: "宁波西格玛泰克自动化设备有限公司",
        Address: "宁波市江东区惊驾路555号A栋805室",
        Telephone: "+86 574 8775 3085",
        F: "+86 574 8775 3065",
        web: "www.sigmatek-automation.cn",
        Email: "office@sigmatek-automation.cn",
      }, //公司信息


      ReportContentInfo: {
        _id: null, //报表id记录
        ArchiveLocation: {
          //表格资料部分
          Items_id: null, //项目id
          Items_ProjectCode: null, //项目代码id
          Assignment_id: null, //任务id
          PersonnelInvolved: null, //任务关联人员
          FillingPersonnel: null, //填写人员
          CreationTime: null, //创建时间
          Destination: null, //目的地
        },
        ReportDate: null, //报表日期
        ReportDate_Watch: null,
        ExpenseCode: null, //费用代码
        SettlementDateS: null, //结算日期（前）
        SettlementDateS_Watch: null,
        SettlementDateE: null, //结算日期（后）
        SettlementDateE_Watch: null,
        RelevantInfo: {
          //人员信息部分
          EmployeeName: null, //员工名称
          Position: null, //员工所任职务
          Department: null, //员工所在部门
          Email: null, //员工电子邮件
          Manager: null, //部门经理
          ManagerEmail: null, //部门经理邮件
        },
        ReimbursementInstructions: null, //报销说明
        ReimbursementDetails: [
          {
            //经费明细部分
            Date: null, //日期
            Type: null, //科目
            Explain: null, //说明
            Classification: null, //类别
            Cost: null, //费用
            toolvisiable: false, //行选中指示符
              Date_Watch: null,
              Cost_Watch: null,
          },
        ],
        CostAggregation: {
          //合计部分
          Subtotal: null, //小计
          Advance: null, //预支款
          // TravelAllowance: null,
          TotalReimbursement: null, //报销合计
            Subtotal_Watch: null,
            Advance_Watch: null,
            // TravelAllowance_Watch: null,
            TotalReimbursement_Watch: null,
        },
        SignText: null, //备注信息
        TravelSubsidyStatistics: [
          {
            Date: null, //日期
            week: null, //星期
              hasDate: false,
            Businesslocation: null, //出差地
              isField: false, //判断是否在外地
            AccommodationOrNot: null, //是否住宿
              BaoXiaoJiSuan: false, //报销计算指示符
            TravelAllowance: null, //出差补贴
            AssignToReimbursement: null, //分配至报销
            AssignTosalary: null, //分配至工资
              Date_Watch: null,
              TravelAllowance_Watch: null, 
              AssignToReimbursement_Watch: null, 
              AssignTosalary_Watch: null, 
          },
        ],
        TravelSubsidySubtotal_AssignToReimbursement: null, //分配至报销合计
        TravelSubsidySubtotal_AssignTosalary: null, //分配至工资合计
          TravelSubsidySubtotal_AssignToReimbursement_Watch: null,
          TravelSubsidySubtotal_AssignTosalary_Watch: null,
      }, //出差零用金报销单(_Watch为打印效果（String）不会写入数据库，非_Watch为真实数据（用以后续筛选）)

      QuickDateSelection : [], //快速日期选择

      listQuery: {
        page: 0,
        limit: 0,
        sort: null,
        filters: {},
      },

      selectedRow: null,
      ReimbursementCategory: [
        { id: "1",  label: "S销售费用", Type: "NIL" },
        { id: "2",  label: "S福利费", Type: "Z66010103" },
        { id: "3",  label: "S维修费", Type: "Z66010404" },
        { id: "4",  label: "S电话费", Type: "Z66010405" },
        { id: "5",  label: "S办公文具费", Type: "Z66010406" },
        { id: "6",  label: "S住宿费", Type: "Z66010501" },
        { id: "7",  label: "S出差补贴", Type: "Z66010502" },
        { id: "8",  label: "S机票费", Type: "Z66010503" },
        { id: "9",  label: "S出租车费", Type: "Z66010504" },
        { id: "10", label: "S路桥费用", Type: "Z66010506" },
        { id: "11", label: "S其他交通费", Type: "Z66010507" },
        { id: "12", label: "S停车费", Type: "Z66010508" },
        { id: "13", label: "S交通费9%", Type: "Z66010509" },
        { id: "14", label: "S交通费3%", Type: "Z66010510" },
        { id: "15", label: "S服务费", Type: "Z66010901" },
        { id: "16", label: "S咨询费", Type: "Z66010902" },
        { id: "17", label: "S保险费", Type: "Z66010903" },
        { id: "18", label: "S运杂费", Type: "Z66010910" },
        { id: "19", label: "S其他", Type: "Z66010912" },
        { id: "20", label: "S业务招待费", Type: "Z66011001" },
        { id: "21", label: "S汽车费用", Type: "Z66011101" },
        { id: "22", label: "M管理费用", Type: "NIL" },
        { id: "23", label: "M交通费9%", Type: "Z66010509" },
        { id: "24", label: "M交通费3%", Type: "Z66010510" },
        { id: "25", label: "M福利费", Type: "Z66020103" },
        { id: "26", label: "M维修费", Type: "Z66020404" },
        { id: "27", label: "M电话费", Type: "Z66020405" },
        { id: "28", label: "M办公文具费", Type: "Z66020406" },
        { id: "29", label: "M住宿费", Type: "Z66020501" },
        { id: "30", label: "M出租车费", Type: "Z66020504" },
        { id: "31", label: "M路桥费用", Type: "Z66020506" },
        { id: "32", label: "M其他交通费", Type: "Z66020507" },
        { id: "33", label: "M业务招待费", Type: "Z66020601" },
        { id: "34", label: "M其他", Type: "Z660211" },
        { id: "35", label: "M培训费", Type: "Z660219" },
      ],

      ReimbursementRoles_Travel: [
        {Type: "费",    Describe: "国际机票行程（国内-国外来回，国外-国外来回）"},
        {Type: "出租车费",  Describe: "出租车机打发票"},
        {Type: "交通费9%",  Describe: "国内行程飞机票(行程单、电子发票发票显示9%）、火车票"},
        {Type: "交通费3%",  Describe: "大巴、滴滴、地铁等电子发票(电子发票票面显示3%税率)"},
        {Type: "其他交通费", Describe: "地铁、公交等手撕发票，飞机票（电子发票显示不征税项目）；滴滴、代驾等（电子发票票面显示免税）"},
        {Type: "汽车费用",  Describe:  "油费，汽车维修保养保险等以汽车为主体产生的费用"},
        {Type: "停车费",    Describe:  "停车费"},
        {Type: "运杂费",    Describe: "运费、快递费"},
        {Type: "路桥费用",  Describe: "过路费（包含手撕发票及电子发票）"}
      ],
      ReimbursementRoles_Other: [
        {Type: "福利费",    Describe: "员工福利"},
        {Type: "维修费",    Describe: "维修"},
        {Type: "电话费",    Describe: "电话费"},
        {Type: "办公文具费", Describe: "办公用品"},
        {Type: "住宿费",    Describe: "住宿"},
        {Type: "出差补贴",  Describe:  "出差补贴"},
        {Type: "服务费",    Describe:  "服务费"},
        {Type: "咨询费",    Describe: "咨询费"},
        {Type: "保险费",    Describe: "以为人身/货物为主体产生的保险费"},
        {Type: "其它",    Describe: "其他无法明确区分的费用"},
        {Type: "业务招待费",    Describe: "招待客户产生费用，例：餐费等"}
      ],

      userinfo: null, //读取到的的用户信息
      managerinfo: null, //读取到的的用户的部门经理的信息

      isNew: false, //判断是否是新的任务（未保存的表）
      NoYours: true, //该任务是否有当前人员的（未保存的表）

      downloadLoading: false, //Excel导出标志位
      autoWidth: false,
      bookType: "xlsx",
      // NewRulesWindow: false,
      activeName: 1,
      radio: "零用金报销单",
      Money_Details_Header_Print: "Money_Details_Header_Print", //打印用css特殊格式
      CCBTTJD_Date_Header_Print: "CCBTTJD_Date_Header_Print", //打印用css特殊格式
      LocalOrNot: [
        { id: "1", label: "本地半天" },
        { id: "2", label: "本地一天" },
        { id: "3", label: "外地" },
        { id: "4", label: "国外" },
      ],
      YesOrNot: [
        { id: "1", label: "家" },
        { id: "2", label: "酒店" },
        { id: "3", label: "工厂" },
      ],
      loading: false,
      task : {},
    };      
  },

  created() {
    if (this.$route.query.taskID) {
      this._id = this.$route.query.taskID;
    }
    this.getTask();
    this.GetForm(); // 进来后开始获取表单
    this.AdvanceWatchChange();
    // this.TravelAllowanceWatchChange();
    this.SubtotalWatchChange();
    this.TotalReimbursementWatchChange();
    let name = getName();
    this.ReadJobNumber(name);
  },

  computed: {
    currentDate: function () {
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}/${month}/${day}`;
    },
    currentTime() {
      const date = new Date();
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    },
  },

  watch: {
    'ReportContentInfo.TravelSubsidyStatistics': {
      handler(newVal, oldVal) {
        let sum_ATR = 0;
        let sum_ATS = 0;
        newVal.forEach((item) => {
          sum_ATR += Number(item.AssignToReimbursement) || 0;
          sum_ATS += Number(item.AssignTosalary) || 0;
        });
        this.ReportContentInfo.TravelSubsidySubtotal_AssignToReimbursement = sum_ATR;
        this.ReportContentInfo.TravelSubsidySubtotal_AssignTosalary = sum_ATS;
      },
      deep: true,
    },

  },

  methods: {
    async GetForm() {
      let loadingInstance = Loading.service({ fullscreen: true });
      let RXfilters =
        "({'ArchiveLocation.Assignment_id' :'" +
        this.$route.query.taskID +
        "' })";
      const {data} = await GetReimbursement({
        filters: RXfilters,
      });
    //  console.log(data);
    //  console.log(this.$route);
      if (data.list.length) {
        for (let i = 0 ; i < data.list.length ; i++) {
       //   console.log(data.list[i].ArchiveLocation.FillingPersonnel);
        //  console.log(getName());
          if (data.list[i].ArchiveLocation.FillingPersonnel === getName()) {
            this.ReportContentInfo = data.list[i];
            this.WriteSettlementDateES_W(); //开始的时候对SettlementDateE_Watch和SettlementDateS_Watch进行赋值
            this.LoadTravelSubsidyStatistics(); //每次载入，需要对ReportContentInfo.TravelSubsidyStatistics结构进行规划
            this.LoadQuickDateSelection();
            // this.AssignmentNull(); //将“出差补贴统计单”赋值多个空行，用于呈现形势
            this.NoYours = false;
          } 
          if ( (this.NoYours == true) && (i == data.list.length - 1) ) {
            this.isNew = true;
            this.Subtotal_TotalReimbursement_AssignZero();
            let name = getName();
            this.RelevantInfo_AutoFill(name);
            this.FormInformation_AutoFill(this.$route.query.taskID);
            // this.AssignmentNull(); //将“出差补贴统计单”赋值多个空行，用于呈现形势
          }
        }
      } else {
        this.isNew = true;
        this.Subtotal_TotalReimbursement_AssignZero();
        let name = getName();
        this.RelevantInfo_AutoFill(name);
        this.FormInformation_AutoFill(this.$route.query.taskID);
        // this.AssignmentNull(); //将“出差补贴统计单”赋值多个空行，用于呈现形势
      }
      loadingInstance.close();
    }, //加载表格

    async getItems() {
      this.listQuery.filters = "({$or:[{'_id':'" + this.task.projectID + "'}]})"
      const { data } = await getProjectlist(this.listQuery);
      this.ReportContentInfo.ArchiveLocation.Items_id = data.list[0].name;
      this.ReportContentInfo.ArchiveLocation.Items_ProjectCode = data.list[0].ProjectCode;
    },

    async getTask(){
      const { data } = await getTasklist({
        filters :"({_id :'"+this._id + "' })"
      });
      this.task = data.list[0];
      this.getItems()
    },

    async CreateForm() {
      const { data } = await CreateReimbursement({
        id: this.$route.query.taskID,
      });
      this.ReportContentInfo._id = data;
      if (data) {
        this.$message({
          message: "创建成功",
          type: "success",
        });
      } else {
        this.$message({
          message: "创建失败",
          type: "error",
        });
      }
    }, //创建表格

    async SaveForm() {
      // this.ClearAssignmentNull(); //保存前将“出差补贴统计单”多个空行剔除
      if (this.isNew) {
        this.CreateForm();
        this.isNew = false;
        setTimeout(() => {
          this.SaveForm();
        }, 1000);
      }
      const { data } = await UpdateReimbursement({
        id: this.ReportContentInfo._id,
        data: this.ReportContentInfo,
      });
      if (data) {
        this.$message({
          message: "保存成功",
          type: "success",
        });
      }
      // this.AssignmentNull(); //将“出差补贴统计单”赋值多个空行，用于呈现形势
    }, //保存表格修改

    Subtotal_TotalReimbursement_AssignZero() {
      this.ReportContentInfo.CostAggregation.Subtotal = 0;
      this.ReportContentInfo.CostAggregation.Advance = 0;
      // this.ReportContentInfo.CostAggregation.TravelAllowance = 0;
      this.ReportContentInfo.CostAggregation.TotalReimbursement = 0;
    }, //小计与出差合计赋0，只用做created
    async RelevantInfo_AutoFill(name) {
      const { data } = await getInfobyname(name);
      this.userinfo = data.list[0];
      let ManagerName = this.userinfo.manager;
      let res = await getInfobyname(ManagerName);
      this.managerinfo = res.data.list[0];
      this.ReportContentInfo.RelevantInfo.EmployeeName = this.userinfo.username;
      this.ReportContentInfo.RelevantInfo.Position = "技术工程师";
      this.ReportContentInfo.RelevantInfo.Department = "技术部";
      this.ReportContentInfo.RelevantInfo.Email =
        this.userinfo.email + "@sigmatek-automation.cn";
      this.ReportContentInfo.RelevantInfo.Manager = this.userinfo.manager;
      this.ReportContentInfo.RelevantInfo.ManagerEmail =
        this.managerinfo.email + "@sigmatek-automation.cn";
    }, //基础信息自动填写，只用做created
    async ReadJobNumber(name) {
      const { data } = await getInfobyname(name);
      this.userinfo = data.list[0];
     // console.log(this.userinfo);
    }, //读取个人信息
    FormInformation_AutoFill(Assignment_id) {
      this.ReportContentInfo.ArchiveLocation.Assignment_id = Assignment_id;
      this.ReportContentInfo.ArchiveLocation.FillingPersonnel = getName();
    }, //表格信息填写，只用做created

    addLine() {
      this.ReportContentInfo.ReimbursementDetails.push({
        Date: null,
        Type: null,
        Explain: null,
        Classification: null,
        Cost: null,
        toolvisiable: false,
        Cost_Watch: null,
      });
    }, //增加明细行
    remove(index) {
      this.ReportContentInfo.ReimbursementDetails.splice(index, 1);
      this.Subtotal_Calculation();
      this.FindMinMaxDates();
      this.ReportContentInfo.ReimbursementDetails[index].toolvisiable = false;
    }, //删除明细行
    MoveDown(index) {
      if (index < this.ReportContentInfo.ReimbursementDetails.length - 1) {
        const temp = this.ReportContentInfo.ReimbursementDetails[index];
        this.ReportContentInfo.ReimbursementDetails.splice(index, 1);
        this.ReportContentInfo.ReimbursementDetails.splice(index + 1, 0, temp);
      }
      this.ReportContentInfo.ReimbursementDetails[index].toolvisiable = false;
    }, //明细行下移
    addRowBefore(index) {
      const emptyRow = {
        Date: null,
        Type: null,
        Explain: null,
        Classification: null,
        Cost: null,
        toolvisiable: false,
        Cost_Watch: null,
      };
      this.ReportContentInfo.ReimbursementDetails.splice(index, 0, emptyRow);
      this.ReportContentInfo.ReimbursementDetails[index].toolvisiable = false;
    }, //在上方插入新的明细行
    addRowAfter(index) {
      const emptyRow = {
        Date: null,
        Type: null,
        Explain: null,
        Classification: null,
        Cost: null,
        toolvisiable: false,
        Cost_Watch: null,
      };
      this.ReportContentInfo.ReimbursementDetails.splice(
        index + 1,
        0,
        emptyRow
      );
    }, //在下方插入新的明细行
    onRowSelect(row) {
      this.selectedRow = row;
    }, //选择行记录
    FindMinMaxDates() {
      this.ReportContentInfo.SettlementDateS = Number.MAX_SAFE_INTEGER;
      this.ReportContentInfo.SettlementDateE = 0;
      for (
        let i = 0;
        i < this.ReportContentInfo.ReimbursementDetails.length;
        i++
      ) {
        const detail = this.ReportContentInfo.ReimbursementDetails[i];
       // console.log(detail);
        if (!detail.Date) {
          continue;
        } // 跳过Date为null的记录
        if (
          !this.ReportContentInfo.SettlementDateS ||
          detail.Date < this.ReportContentInfo.SettlementDateS
        ) {
          this.ReportContentInfo.SettlementDateS = detail.Date;
        } // 如果当前日期比最小日期早，更新最小日期
        if (
          !this.ReportContentInfo.SettlementDateE ||
          detail.Date > this.ReportContentInfo.SettlementDateE
        ) {
          this.ReportContentInfo.SettlementDateE = detail.Date;
        } // 如果当前日期比最大日期晚，更新最大日期
      }
      this.ReportContentInfo.SettlementDateS_Watch = this.timestampToYMD(
        this.ReportContentInfo.SettlementDateS
      );
      this.ReportContentInfo.SettlementDateE_Watch = this.timestampToYMD(
        this.ReportContentInfo.SettlementDateE
      );
      this.$forceUpdate();

    }, //结算日期计算
    ChangeReportDate() {
      this.ReportContentInfo.ReportDate_Watch = this.timestampToYMD(
        this.ReportContentInfo.ReportDate
      );
    }, //报表日期计算
    timestampToYMD(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      // 将月份和日期转换为两位数
      const formattedMonth = month.toString().padStart(2, "0");
      const formattedDay = day.toString().padStart(2, "0");
      return `${year}-${formattedMonth}-${formattedDay}`;
    }, //时间戳转年月日（yyyy-mm-dd）
    Subtotal_Calculation() {
      this.ReportContentInfo.CostAggregation.Subtotal = 0;
      for (
        let i = 0;
        i < this.ReportContentInfo.ReimbursementDetails.length;
        i++
      ) {
        const detail = this.ReportContentInfo.ReimbursementDetails[i];
        if (!detail.Cost) {
          continue;
        } // 跳过Date为null的记录
        this.ReportContentInfo.CostAggregation.Subtotal =
          this.ReportContentInfo.CostAggregation.Subtotal +
          parseFloat(detail.Cost);
        this.ReportContentInfo.CostAggregation.Subtotal = parseFloat(
          this.ReportContentInfo.CostAggregation.Subtotal.toFixed(2)
        );
      }
      this.SubtotalWatchChange();
      this.TotalReimbursement_Calculation();
    }, //小计计算
    TotalReimbursement_Calculation() {
      this.ReportContentInfo.CostAggregation.TotalReimbursement = 0;
      this.ReportContentInfo.CostAggregation.TotalReimbursement =
        this.ReportContentInfo.CostAggregation.Subtotal;
      // + parseFloat(this.ReportContentInfo.CostAggregation.TravelAllowance);
      this.ReportContentInfo.CostAggregation.TotalReimbursement = parseFloat(
        this.ReportContentInfo.CostAggregation.TotalReimbursement.toFixed(2)
      );
      this.TotalReimbursementWatchChange();
    }, //报销合计计算

    ClassificationChange(row) {
      const categoryMap = {
        S出差补贴: "Z66010502",
        S销售费用: "NIL",
        S福利费: "Z66010103",
        S维修费: "Z66010404",
        S电话费: "Z66010405",
        S办公文具费: "Z66010406",
        S住宿费: "Z66010501",
        S机票费: "Z66010503",
        S出租车费: "Z66010504",
        S路桥费用: "Z66010506",
        S其他交通费: "Z66010507",
        S停车费: "Z66010508",
        "S交通费9%": "Z66010509",
        "S交通费3%": "Z66010510",
        S服务费: "Z66010901",
        S咨询费: "Z66010902",
        S保险费: "Z66010903",
        S运杂费: "Z66010910",
        S其他: "Z66010912",
        S业务招待费: "Z66011001",
        S汽车费用: "Z66011101",
        M管理费用: "NIL",
        "M交通费9%": "Z66010509",
        "M交通费3%": "Z66010510",
        M福利费: "Z66020103",
        M维修费: "Z66020404",
        M电话费: "Z66020405",
        M办公文具费: "Z66020406",
        M住宿费: "Z66020501",
        M出租车费: "Z66020504",
        M路桥费用: "Z66020506",
        M其他交通费: "Z66020507",
        M业务招待费: "Z66020601",
        M其他: "Z660211",
        M培训费: "Z660219",
      };
      if (row.Classification === "") {
        row.Type = "";
      } else {
        if (row.Classification === "S出差补贴") {
          row.Type = categoryMap[row.Classification]
          row.Explain = "出差补贴" 
          row.Cost = ( this.ReportContentInfo.TravelSubsidySubtotal_AssignToReimbursement ?
                       this.ReportContentInfo.TravelSubsidySubtotal_AssignToReimbursement :
                       0) 
          this.CostChange(row);          
        } else {
          row.Type = categoryMap[row.Classification] || "";
        }
      }
      //console.log(this.ReportContentInfo);
    }, //科目自动赋予


    formatNumber(num) {
      if (typeof num !== "number") {
        num = parseFloat(num);
      }
      if (isNaN(num)) {
        return "";
      }
      return num.toFixed(2);
    }, //小数位不足两位自动补充并输出

    DataWatchChange(row) {
      row.Date_Watch = this.timestampToYMD(row.Date);
    }, //改变“经费明细”的日期时同时改变他的watch
    CostWatehChange(row) {
      row.Cost_Watch = this.formatNumber(row.Cost);
    }, //改变“费用”时同时改变他的watch
    AdvanceWatchChange() {
      this.ReportContentInfo.CostAggregation.Advance_Watch = this.formatNumber(
        this.ReportContentInfo.CostAggregation.Advance
      );
    }, //改变“预支款”时同时改变他的watch
    // TravelAllowanceWatchChange() {
    //   this.ReportContentInfo.CostAggregation.TravelAllowance_Watch =
    //     this.formatNumber(this.ReportContentInfo.CostAggregation.TravelAllowance);
    // }, //改变出差补贴”时同时改变他的watch
    SubtotalWatchChange() {
      this.ReportContentInfo.CostAggregation.Subtotal_Watch = this.formatNumber(
        this.ReportContentInfo.CostAggregation.Subtotal
      );
    }, //改变“小计”的同时改变他的watch
    TotalReimbursementWatchChange() {
      this.ReportContentInfo.CostAggregation.TotalReimbursement_Watch =
        this.formatNumber(
          this.ReportContentInfo.CostAggregation.TotalReimbursement
        );
    }, //改变“报销合计”的同时改变他的watch

    DataChange(row) {
      this.FindMinMaxDates();
      this.DataWatchChange(row);
    }, //改变日期是连续调用，集合
    CostChange(row) {
      this.CostWatehChange(row);
      this.Subtotal_Calculation();
    }, //改变费用是连续调用，集合
    // TravelAllowanceChange() {
    //   // this.TravelAllowanceWatchChange();
    //   this.TotalReimbursement_Calculation();
    // }, //改变出差补贴是连续调用，集合

    WriteSettlementDateES_W() {
      this.ReportContentInfo.SettlementDateS_Watch = this.timestampToYMD(
        this.ReportContentInfo.SettlementDateS
      );
      this.ReportContentInfo.SettlementDateE_Watch = this.timestampToYMD(
        this.ReportContentInfo.SettlementDateE
      );
      //console.log(this.ReportContentInfo);
    }, //需要在开始的时候对SettlementDateE_Watch和SettlementDateS_Watch进行赋值

    WatchIf0_00() {
      for (
        let i = 0;
        i < this.ReportContentInfo.ReimbursementDetails.length;
        i++
      ) {
        if (
          this.ReportContentInfo.ReimbursementDetails[i].Cost === 0 ||
          this.ReportContentInfo.ReimbursementDetails[i].Cost === "" ||
          this.ReportContentInfo.ReimbursementDetails[i].Cost === null
        ) {
          this.ReportContentInfo.ReimbursementDetails[i].Cost_Watch = "0.00";
        }
      }
      if (this.ReportContentInfo.CostAggregation.Subtotal == 0) {
        this.ReportContentInfo.CostAggregation.Subtotal_Watch = "0.00";
      }
      if (this.ReportContentInfo.CostAggregation.Advance == 0) {
        this.ReportContentInfo.CostAggregation.Advance_Watch = "0.00";
      }
      // if (this.ReportContentInfo.CostAggregation.TravelAllowance == 0) {
      //   this.ReportContentInfo.CostAggregation.TravelAllowance_Watch = "0.00"
      // };
      if (this.ReportContentInfo.CostAggregation.TotalReimbursement == 0) {
        this.ReportContentInfo.CostAggregation.TotalReimbursement_Watch =
          "0.00";
      }

      for (
        let i = 0;
        i < this.ReportContentInfo.TravelSubsidyStatistics.length;
        i++
      ) {
        if (this.ReportContentInfo.TravelSubsidyStatistics[i].TravelAllowance == 0) {
          this.ReportContentInfo.TravelSubsidyStatistics[i].TravelAllowance_Watch =
            "0.00";
        }

        if (this.ReportContentInfo.TravelSubsidyStatistics[i].AssignToReimbursement == 0) {
          this.ReportContentInfo.TravelSubsidyStatistics[i].AssignToReimbursement_Watch =
            "0.00";
        }

        if (this.ReportContentInfo.TravelSubsidyStatistics[i].AssignTosalary == 0) {
          this.ReportContentInfo.TravelSubsidyStatistics[i].AssignTosalary_Watch =
            "0.00";
        }
      }

      if (this.ReportContentInfo.TravelSubsidySubtotal_AssignToReimbursement == 0) {
        this.ReportContentInfo.TravelSubsidySubtotal_AssignToReimbursement_Watch =
          "0.00";
      }

      if (this.ReportContentInfo.TravelSubsidySubtotal_AssignTosalary == 0) {
        this.ReportContentInfo.TravelSubsidySubtotal_AssignTosalary_Watch =
          "0.00";
      }
      
    }, //判断_Watch是否是0.00

    WatchPrintWrite() {
      this.ReportContentInfo.ReportDate ?
        this.ReportContentInfo.ReportDate_Watch 
          = this.timestampToYMD( this.ReportContentInfo.ReportDate ) :
        null ;

      this.ReportContentInfo.SettlementDateS ?
        this.ReportContentInfo.SettlementDateS_Watch 
          = this.timestampToYMD( this.ReportContentInfo.SettlementDateS ) : 
        null ;

      this.ReportContentInfo.SettlementDateE ?
        this.ReportContentInfo.SettlementDateE_Watch 
          = this.timestampToYMD( this.ReportContentInfo.SettlementDateE ) :
        null ;

      for (
        let i = 0;
        i < this.ReportContentInfo.ReimbursementDetails.length;
        i++
      ) {

      this.ReportContentInfo.ReimbursementDetails[i].Date ?
        this.ReportContentInfo.ReimbursementDetails[i].Date_Watch =
          this.timestampToYMD(this.ReportContentInfo.ReimbursementDetails[i].Date) :
        null ;

        this.ReportContentInfo.ReimbursementDetails[i].Cost_Watch =
          this.formatNumber(
            this.ReportContentInfo.ReimbursementDetails[i].Cost
          );
      }

      this.ReportContentInfo.CostAggregation.Subtotal_Watch = this.formatNumber(
        this.ReportContentInfo.CostAggregation.Subtotal
      );
      this.ReportContentInfo.CostAggregation.Advance_Watch = this.formatNumber(
        this.ReportContentInfo.CostAggregation.Advance
      );
      // this.ReportContentInfo.CostAggregation.TravelAllowance_Watch = this.formatNumber(this.ReportContentInfo.CostAggregation.TravelAllowance);
      this.ReportContentInfo.CostAggregation.TotalReimbursement_Watch = this.formatNumber(
        this.ReportContentInfo.CostAggregation.TotalReimbursement
      );

      for (
        let i = 0;
        i < this.ReportContentInfo.TravelSubsidyStatistics.length;
        i++
      ) {

        if (this.ReportContentInfo.TravelSubsidyStatistics[i].Date) 
          {
            this.ReportContentInfo.TravelSubsidyStatistics[i].Date_Watch =
              this.timestampToYMD(
                this.ReportContentInfo.TravelSubsidyStatistics[i].Date
              );
          }

        if (this.ReportContentInfo.TravelSubsidyStatistics[i].TravelAllowance) 
          {
            this.ReportContentInfo.TravelSubsidyStatistics[i].TravelAllowance_Watch = 
              this.formatNumber(
                this.ReportContentInfo.TravelSubsidyStatistics[i].TravelAllowance
              );
          }

        if (this.ReportContentInfo.TravelSubsidyStatistics[i].AssignToReimbursement) 
          {
            this.ReportContentInfo.TravelSubsidyStatistics[i].AssignToReimbursement_Watch = 
              this.formatNumber(
                this.ReportContentInfo.TravelSubsidyStatistics[i].AssignToReimbursement
              );
          }

        if (this.ReportContentInfo.TravelSubsidyStatistics[i].AssignTosalary) 
          {
            this.ReportContentInfo.TravelSubsidyStatistics[i].AssignTosalary_Watch = 
              this.formatNumber(
                this.ReportContentInfo.TravelSubsidyStatistics[i].AssignTosalary
              );
          }
        
      }

      this.ReportContentInfo.TravelSubsidySubtotal_AssignToReimbursement_Watch = 
        this.formatNumber(
          this.ReportContentInfo.TravelSubsidySubtotal_AssignToReimbursement
        );
      this.ReportContentInfo.TravelSubsidySubtotal_AssignTosalary_Watch = 
        this.formatNumber(
          this.ReportContentInfo.TravelSubsidySubtotal_AssignTosalary
        );

      this.WatchIf0_00();
    }, //打印前统一再改一次，双保险,应用场景为用户载入且没有操作就进行打印

    beforePrint() {
      this.WatchPrintWrite();
      this.loadingInstance = Loading.service({ fullscreen: true });
      this.PrintStatus = true;
      this.SelectRow = 0;
      this.$forceUpdate();
      if (this.radio == "零用金报销单") {
        let button = document.getElementById("print_LYJBXD");
        setTimeout(() => {
          button.click();
        }, 1500);
      } //判断当前选中的表格（零用金报销单）
      if (this.radio == "出差补贴统计单") {
        let button = document.getElementById("print_CCBTTJD");
        setTimeout(() => {
          button.click();
        }, 1500);
      } //判断当前选中的表格（出差补贴统计单）
      setTimeout(() => {
        this.PrintStatus = false;
      }, 5000);
    }, //打印前准备

    ExcelDownload() {
      this.downloadLoading = true;
      this.WatchPrintWrite();
      // let name = getName();
      // this.ReadJobNumber(name);
      this.ExcelList = [];
      for (
        var i = 0;
        i < this.ReportContentInfo.ReimbursementDetails.length;
        i++
      ) {
        let obj = {
          ReportDate_Watch: null,
          ExpenseCode: null,
          Items_ProjectCode: null,
          SettlementDateS_Watch: null,
          SettlementDateE_Watch: null,
          RelevantInfo: {
            Jobnumber: null,
            EmployeeName: null,
            Position: null,
            Department: null,
            Email: null,
            Manager: null,
            ManagerEmail: null,
          },
          ReimbursementInstructions: null,
          ReimbursementDetails: {
            Type: null,
            Explain: null,
            Classification: null,
            Cost: null,
            Date_Watch: null,
          },
          CostAggregation: {
            Subtotal: null,
            Advance: null,
            TotalReimbursement: null,
          },
          SignText: null,
        };

        obj.ReportDate_Watch = this.formatDate(
          this.ReportContentInfo.ReportDate_Watch
        );
        obj.ExpenseCode = this.ReportContentInfo.ExpenseCode;
        obj.Items_ProjectCode = this.ReportContentInfo.ArchiveLocation.Items_ProjectCode;
        obj.SettlementDateS_Watch = this.formatDate(
          this.ReportContentInfo.SettlementDateS_Watch
        );
        obj.SettlementDateE_Watch = this.formatDate(
          this.ReportContentInfo.SettlementDateE_Watch
        );

        obj.RelevantInfo.Jobnumber =
          this.userinfo.JobNumber;
        obj.RelevantInfo.EmployeeName =
          this.ReportContentInfo.RelevantInfo.EmployeeName;
        obj.RelevantInfo.Position =
          this.ReportContentInfo.RelevantInfo.Position;
        obj.RelevantInfo.Department =
          this.ReportContentInfo.RelevantInfo.Department;
        obj.RelevantInfo.Email = this.ReportContentInfo.RelevantInfo.Email;
        obj.RelevantInfo.Manager = this.ReportContentInfo.RelevantInfo.Manager;
        obj.RelevantInfo.ManagerEmail =
          this.ReportContentInfo.RelevantInfo.ManagerEmail;

        obj.ReimbursementInstructions =
          this.ReportContentInfo.ReimbursementInstructions;

        obj.ReimbursementDetails.Type =
          this.ReportContentInfo.ReimbursementDetails[i].Type;
        obj.ReimbursementDetails.Explain =
          this.ReportContentInfo.ReimbursementDetails[i].Explain;
        obj.ReimbursementDetails.Classification =
          this.ReportContentInfo.ReimbursementDetails[i].Classification;
        obj.ReimbursementDetails.Cost =
          this.ReportContentInfo.ReimbursementDetails[i].Cost;
        obj.ReimbursementDetails.Date_Watch = this.formatDate(
          this.ReportContentInfo.ReimbursementDetails[i].Date_Watch
        );

        obj.CostAggregation.Subtotal =
          this.ReportContentInfo.CostAggregation.Subtotal;
        obj.CostAggregation.Advance =
          this.ReportContentInfo.CostAggregation.Advance;
        obj.CostAggregation.TotalReimbursement =
          this.ReportContentInfo.CostAggregation.TotalReimbursement;

        obj.SignText = this.ReportContentInfo.SignText;

        this.ExcelList.push(obj);
      }
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "报销日期 (exp:expenseReport/exp:reportDate)",
          "经费代码 (exp:expenseReport/exp:expenseCode)",
          "项目代码 (exp:expenseReport/exp:ProjectCodeItems)",
          "结算开始日期 (exp:expenseReport/exp:startDate)",
          "结算截止日期 (exp:expenseReport/exp:endDate)",
          "工号 (exp:expenseReport/exp:employee/exp:jobnumber)",
          "姓名 (exp:expenseReport/exp:employee/exp:name/exp:singleName)",
          "职务 (exp:expenseReport/exp:employee/exp:jobTitle)",
          "部门 (exp:expenseReport/exp:employee/exp:department)",
          "电子邮件地址 (exp:expenseReport/exp:employee/exp:emailAddressPrimary).",
          "姓名 (exp:expenseReport/exp:manager/exp:name/exp:singleName)",
          "电子邮件地址 (exp:expenseReport/exp:manager/exp:emailAddressPrimary)",
          "报销说明 (exp:expenseReport/exp:purpose)",
          "日期 (exp:expenseReport/exp:items/exp:item/exp:date)",
          "Code (exp:expenseReport/exp:items/exp:item/my:Code)",
          "说明 (exp:expenseReport/exp:items/exp:item/exp:description)",
          "类别 (exp:expenseReport/exp:items/exp:item/exp:category)",
          "费用 (exp:expenseReport/exp:items/exp:item/exp:totalAmount)",
          "小计 (exp:expenseReport/exp:subTotal)",
          "预支款 (exp:expenseReport/exp:cashAdvance)",
          "报销合计 (exp:expenseReport/exp:total)",
          "备注节 (exp:expenseReport/exp:notes)",
        ];
        const filterVal = [
          "ReportDate_Watch",
          "ExpenseCode",
          "Items_ProjectCode",
          "SettlementDateS_Watch",
          "SettlementDateE_Watch",
          "RelevantInfo.Jobnumber",
          "RelevantInfo.EmployeeName",
          "RelevantInfo.Position",
          "RelevantInfo.Department",
          "RelevantInfo.Email",
          "RelevantInfo.Manager",
          "RelevantInfo.ManagerEmail",
          "ReimbursementInstructions",
          "ReimbursementDetails.Date_Watch",
          "ReimbursementDetails.Type",
          "ReimbursementDetails.Explain",
          "ReimbursementDetails.Classification",
          "ReimbursementDetails.Cost",
          "CostAggregation.Subtotal",
          "CostAggregation.Advance",
          "CostAggregation.TotalReimbursement",
          "SignText",
        ];
        const list = this.ExcelList;
        const data = this.formatJson(filterVal, list);
       // console.log(this.userinfo);
        let filename =
          "BX" +
          this.userinfo.JobNumber +
          "_" +
          this.ReportContentInfo.ExpenseCode;
      //  console.log(filename);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: filename,
          autoWidth: this.autoWidth,
          bookType: this.bookType,
        });
        this.downloadLoading = false;
      });
    }, //零用金报销单数据导出成excel主函数

    formatJson(filterVal, jsonData) {
      return jsonData.map((v, index) =>
        filterVal.map((j) => {
          if (j === "id") {
            return index + 1;
          } else if (j.toString().indexOf(".") != -1) {
            let a = j.split(".");
            if (a[0] == "componentsInfomation" || a[0] == "Comment") {
              var string = "";
              var num = 13;
              for (var i = 0; i < v[a[0]].length; i++) {
                if (v[a[0]][i][a[1]] !== null) {
                  string += v[a[0]][i][a[1]];
                  string += ",";
                }
              }
              return string.substring(0, string.length - 1);
            } else {
              return v[a[0]][a[1]];
            }
          } else {
            return v[j];
          }
        })
      );
    }, //导出excel用

    formatDate(dateStr) {
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${year}/${month}/${day}`;
    }, //导出excel需要改变日期显示形式

    // OpenNewRulesWindow() {
    //   this.NewRulesWindow = true;
    // }, //打开“新的规则”按钮的窗口




/////////////////////////////////出差补贴统计单——表格计算函数////////////////////////////////////
    AssignDatesToData() {
      this.QuickDateSelection = this.QuickDateSelection.filter(date => date > 0);
      DisabledDateUsing = this.QuickDateSelection;

      let sortedDates = this.QuickDateSelection.sort().sort((a, b) => a - b);// 先将日期从小到大排序
      let sortedDatesLength = sortedDates.length;
      let originalDatesLength = this.ReportContentInfo.TravelSubsidyStatistics.length;

      const TheSame = []; //存储相同的部分
      const TheElse = []; //存储不同的部分

      for (let i = 0; i < sortedDatesLength ; i++) {
        const sortedDate = sortedDates[i];
        const filteredList = this.ReportContentInfo.TravelSubsidyStatistics.filter(obj => obj.Date === sortedDate);
        if(filteredList.length== 0){//
          TheElse.push(sortedDate);
        }
        else{
          TheSame.push(filteredList[0]);
        }
      } //遍历数据并存储

      const newTravelSubsidyStatistics = TheElse.map((timestamp) => ({
        Date: timestamp,
        week: null,
        Businesslocation: null,
        AccommodationOrNot: null,
        BaoXiaoJiSuan: false,
        TravelAllowance: null,
        AssignToReimbursement: null,
        AssignTosalary: null,
        isField: false,
        hasDate: false,
      }));

      const mergedTravelSubsidyStatistics = [...TheSame, ...newTravelSubsidyStatistics];
      mergedTravelSubsidyStatistics.sort((a, b) => a.Date - b.Date); //两者合并为新的数据并排序

      this.ReportContentInfo.TravelSubsidyStatistics = [];
      // this.AssignmentNull(); //先清空原有数据，并补齐空行

      for (let k = 0; k < mergedTravelSubsidyStatistics.length ; k++) {
        this.ReportContentInfo.TravelSubsidyStatistics[k] = 
          mergedTravelSubsidyStatistics[k];
        this.ReportContentInfo.TravelSubsidyStatistics[k].hasDate = true ;
        this.WeeklyCalculation(this.ReportContentInfo.TravelSubsidyStatistics[k]) ;//补上星期
        this.TravelSubsidyStatisticsStateChange(this.ReportContentInfo.TravelSubsidyStatistics[k]);
        // this.TravelSubsidyDataChange() //加行判断
      }

      // for (let k = 0; k < this.ReportContentInfo.TravelSubsidyStatistics.length ; k++) {
      //   if (this.ReportContentInfo.TravelSubsidyStatistics[k].Date &&
      //       this.ReportContentInfo.TravelSubsidyStatistics[k].week == null) {
      //     this.WeeklyCalculation(this.ReportContentInfo.TravelSubsidyStatistics[k]);
      //     this.TravelSubsidyStatisticsStateChange(this.ReportContentInfo.TravelSubsidyStatistics[k]);
      //   }
      // } //如果是新出现的数据，将进行‘单项日期更变反应集合’


    }, //快捷日期选择

    IndividualChangeDate() {
      let NewList = []
      for (let k = 0; k < this.ReportContentInfo.TravelSubsidyStatistics.length ; k++) {
        if (this.ReportContentInfo.TravelSubsidyStatistics[k].Date) {
          NewList.push(this.ReportContentInfo.TravelSubsidyStatistics[k].Date);
        }
      }
      this.QuickDateSelection = NewList;
      DisabledDateUsing = this.QuickDateSelection;
    },//单独更变日期,反向控制'快捷日期选择',以做到同步

    
    // CCBTTJD_DataChange(row) {
    //   this.IndividualChangeDate();
    //   this.WeeklyCalculation(row);
    //   this.TravelSubsidyStatisticsStateChange(row);
    // }, //出差补贴统计单,单项日期更变反应集合

    BusinesslocationChange(row) {
      if (row.Businesslocation === "") {
        row.Businesslocation = null;
      }

      if (row.Businesslocation === "本地半天" || row.Businesslocation === "本地一天" || row.Businesslocation === "") {
        row.isField = false;
        row.AccommodationOrNot = "家";
      } else {
        row.isField = true;
        row.AccommodationOrNot = "酒店";
      }

      this.TravelSubsidyStatisticsStateChange(row);
      this.Sum_ATR_ATS()
    }, //出差补贴统计单,单项出差地更变反应集合

    AccommodationOrNotChange(row) {
      // if (row.AccommodationOrNot === "") {
      //   row.AccommodationOrNot = null;
      // }
      this.TravelSubsidyStatisticsStateChange(row);
      this.Sum_ATR_ATS()
    }, //出差补贴统计单,单项是否住宿更变反应集合


    AssignToReimbursementChange(row) {
      if (row.AssignToReimbursement === "") {
        row.AssignToReimbursement = 0;
        row.AssignTosalary = row.TravelAllowance;
      }
      this.SalaryAssignment(row);
      this.Sum_ATR_ATS();
      this.$forceUpdate();
     }, //出差补贴统计单，分配至报销更变，触发反应集合

    AssignTosalaryChange(row) {
      if (row.AssignTosalary === "") {
        row.AssignTosalary = 0;
        row.AssignToReimbursement = row.TravelAllowance;
      }
      this.ReimbursementAssignment(row);
      this.Sum_ATR_ATS();
      this.$forceUpdate();
    }, //出差补贴统计单，分配至工资更变，触发反应集合

    WeeklyCalculation(row) {
      if (row.Date === "" || row.Date === "0" || row.Date === null) {
        row.week = null;
      } else {
        const dayOfWeek = new Date(row.Date).getDay();
        const dayOfWeekMap = {
          0: "星期日",
          1: "星期一",
          2: "星期二",
          3: "星期三",
          4: "星期四",
          5: "星期五",
          6: "星期六",
        };
        row.week = dayOfWeekMap[dayOfWeek];
      }
      this.$forceUpdate();
    }, //星期计算

    Sum_ATR_ATS() {
      let sum_ATR = 0;
      let sum_ATS = 0;
      for (let i = 0 ; i < this.ReportContentInfo.TravelSubsidyStatistics.length ; i++) {
        if (this.ReportContentInfo.TravelSubsidyStatistics[i].AssignToReimbursement) {
          sum_ATR += Number(this.ReportContentInfo.TravelSubsidyStatistics[i].AssignToReimbursement);
        }
        if (this.ReportContentInfo.TravelSubsidyStatistics[i].AssignTosalary) {
          sum_ATS += Number(this.ReportContentInfo.TravelSubsidyStatistics[i].AssignTosalary);
        }
      }
      this.ReportContentInfo.TravelSubsidySubtotal_AssignToReimbursement = sum_ATR;
      this.ReportContentInfo.TravelSubsidySubtotal_AssignTosalary = sum_ATS;
    //  console.log(sum_ATR)
     // console.log(sum_ATS)
    }, //用以在一些情况下，watch抽风，手动触发的方案

    TravelSubsidyStatisticsStateChange(row) {
      if (
        row.Date &&
        row.week &&
        row.Businesslocation 
      ) {
        if (row.BaoXiaoJiSuan) {
          let oldTravelAllowance;
          oldTravelAllowance = row.TravelAllowance;
          row.TravelAllowance = this.SubsidyCalculation(row);
          if (oldTravelAllowance != row.TravelAllowance) {
            row.AssignToReimbursement = row.TravelAllowance;
            row.AssignTosalary = 0;
          }
        } else {
          row.BaoXiaoJiSuan = true;
          row.TravelAllowance = this.SubsidyCalculation(row);
          row.AssignToReimbursement = row.TravelAllowance;
          row.AssignTosalary = 0;
        }
      } else {
        row.BaoXiaoJiSuan = false;
        row.TravelAllowance = null;
        row.AssignToReimbursement = null;
        row.AssignTosalary = null;
      }
    }, //ReportContentInfo.TravelSubsidyStatistics.BaoXiaoJiSuan状态位的计算

    SubsidyCalculation(row) {
      let cost;
      let Holiday;
      Holiday = isHoliday(this.timestampToYMD(row.Date));
      if (Holiday) {
        if (row.Businesslocation === "本地半天") {
          cost = 120;
        } else if (row.Businesslocation === "本地一天") {
          cost = 150;
        } else if (row.Businesslocation === "外地") {
          cost = 280;
        } else if (row.Businesslocation === "国外") {
          cost = 500;
        }
      } else {
        if (row.Businesslocation === "本地半天") {
          cost = 80;
        } else if (row.Businesslocation === "本地一天") {
          cost = 120;
        } else if (row.Businesslocation === "外地") {
          cost = 185;
        } else if (row.Businesslocation === "国外") {
          cost = 500;
        }
      }
      if (row.isField == true) {
        if (row.AccommodationOrNot === "工厂") {
          cost += 100;
        }
      }
      return cost;
    }, //出差补助计算公式!!!!!!!!!!!!!!!!!

    SalaryAssignment(row) {
      if (row.TravelAllowance != null) {
        if (row.AssignToReimbursement > row.TravelAllowance) {
          row.AssignToReimbursement = row.TravelAllowance;
          row.AssignTosalary = 0;
        } else if (row.AssignToReimbursement < 0) {
          row.AssignToReimbursement = 0;
          row.AssignTosalary = row.TravelAllowance;
        } else {
          row.AssignTosalary = row.TravelAllowance - row.AssignToReimbursement;
        }
      }
    }, //（一般是“报销”发生变化时触发），工资配额发生变化
    ReimbursementAssignment(row) {
      if (row.TravelAllowance != null) {
        if (row.AssignTosalary > row.TravelAllowance) {
          row.AssignTosalary = row.TravelAllowance;
          row.AssignToReimbursement = 0;
        } else if (row.AssignTosalary < 0) {
          row.AssignTosalary = 0;
          row.AssignToReimbursement = row.TravelAllowance;
        } else {
          row.AssignToReimbursement = row.TravelAllowance - row.AssignTosalary;
        }
      }
    }, //（一般是“工资”发生变化时触发），报销配额发生变化

    LoadTravelSubsidyStatistics() {
      for (
        let i = 0;
        i < this.ReportContentInfo.TravelSubsidyStatistics.length;
        i++
      ) {
        if (
          this.ReportContentInfo.TravelSubsidyStatistics[i].Date &&
          this.ReportContentInfo.TravelSubsidyStatistics[i].week 
        ) {
          this.ReportContentInfo.TravelSubsidyStatistics[i].hasDate = true;
        }

        
        if (this.ReportContentInfo.TravelSubsidyStatistics[i].Businesslocation === "本地半天" || 
            this.ReportContentInfo.TravelSubsidyStatistics[i].Businesslocation === "本地一天" ||
            this.ReportContentInfo.TravelSubsidyStatistics[i].Businesslocation === "" ||
            this.ReportContentInfo.TravelSubsidyStatistics[i].Businesslocation == null ||
            this.ReportContentInfo.TravelSubsidyStatistics[i].Businesslocation == undefined ) 
        {
          this.ReportContentInfo.TravelSubsidyStatistics[i].isField = false;
        } else {
          this.ReportContentInfo.TravelSubsidyStatistics[i].isField = true;
        }

        if (
          this.ReportContentInfo.TravelSubsidyStatistics[i].Date &&
          this.ReportContentInfo.TravelSubsidyStatistics[i].week &&
          this.ReportContentInfo.TravelSubsidyStatistics[i].Businesslocation 
        ) {
          this.ReportContentInfo.TravelSubsidyStatistics[i].BaoXiaoJiSuan = true;
        }

        if (!(this.ReportContentInfo.TravelSubsidyStatistics[i].Date &&
              this.ReportContentInfo.TravelSubsidyStatistics[i].week &&
              this.ReportContentInfo.TravelSubsidyStatistics[i].Businesslocation)
           ) {
          this.ReportContentInfo.TravelSubsidyStatistics[i].BaoXiaoJiSuan = false;
          this.ReportContentInfo.TravelSubsidyStatistics[i].TravelAllowance = null;
          this.ReportContentInfo.TravelSubsidyStatistics[i].AssignToReimbursement = null;
          this.ReportContentInfo.TravelSubsidyStatistics[i].AssignTosalary = null;
        }

        if (
          this.ReportContentInfo.TravelSubsidyStatistics[i].Date === null &&
          this.ReportContentInfo.TravelSubsidyStatistics[i].week === null &&
          this.ReportContentInfo.TravelSubsidyStatistics[i].Businesslocation === null 
        ) {
          break;
        }
      }
    }, //每次载入，需要对ReportContentInfo.TravelSubsidyStatistics结构进行规划
    LoadQuickDateSelection() {
      for (let i = 0 ; i < this.ReportContentInfo.TravelSubsidyStatistics.length ; i++) {
        this.QuickDateSelection[i] = this.ReportContentInfo.TravelSubsidyStatistics[i].Date
      }
      DisabledDateUsing = this.QuickDateSelection;
    }, //每次载入，需要对QuickDateSelection进行赋值


    // TravelSubsidyDataChange() {
    //   let JiShu;
    //   JiShu = this.ReportContentInfo.TravelSubsidyStatistics.filter(item => {
    //     return item.Date || item.week || item.Businesslocation || item.AccommodationOrNot;
    //   });
    //   if (JiShu.length >= 12) {
    //     while (
    //       this.ReportContentInfo.TravelSubsidyStatistics.length - JiShu.length <
    //       3
    //     ) {
    //       this.ReportContentInfo.TravelSubsidyStatistics.push(
    //         Object.assign({})
    //       );
    //     }
    //   }
    // }, //行数在编辑超过12行后自行增加

    // AssignmentNull() {

    //   if (this.ReportContentInfo.TravelSubsidyStatistics.length < 12) {
    //     do {
    //       this.ReportContentInfo.TravelSubsidyStatistics.push(
    //         Object.assign({})
    //       );
    //     } while (this.ReportContentInfo.TravelSubsidyStatistics.length < 15);
    //   } else {
    //     // for (let i = 0; i < 3; i++) {
    //     //   this.ReportContentInfo.TravelSubsidyStatistics.push(
    //     //     Object.assign({})
    //     //   );
    //     // }
    //   }

    //   console.log(this.ReportContentInfo);
    // }, //将“出差补贴统计单”自动补齐至15项，这会在保存的时候剔除

    // ClearAssignmentNull() {
    //   this.ReportContentInfo.TravelSubsidyStatistics = this.ReportContentInfo.TravelSubsidyStatistics.filter(item => {
    //     return item.Date || item.week || item.Businesslocation || item.AccommodationOrNot;
    //   });
    // }, //保存时，将添加的空行剔除

///////////////////////////////////////////////////////////////////////////////////////////

    asdfghjkl() {
     // console.log(this.ReportContentInfo);
    },
  },
};
</script>


<style scoped >
* {
  font-family: "Arial";
}
@media print {
  #printArea_LYJBXD {
    background-color: transparent;
    page-break-inside: avoid;
  }
  #printArea_CCBTTJD {
    background-color: transparent;
    page-break-inside: avoid;
  }
}

#printArea_LYJBXD {
  position: relative;
  width: 210mm;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#DataArea {
  position: relative;
  margin-top: 14mm;
  margin-left: 20mm;
  margin-right: 15mm;
}
.Tittle {
  position: relative;
  height: 21mm;
}
.TitleText {
  position: relative;
  font-size: 8mm;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.TitleImage {
  padding: 0mm;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.TitleEdition {
  padding: 0mm;
  font-size: 2.7mm;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
}

.Split_Line {
  height: 1mm;
  -webkit-print-color-adjust: exact !important; /* Safari 和 Chrome */
  color-adjust: exact !important; /* Firefox */
  background-color: rgb(81, 125, 191);
}

.Date {
  position: relative;
  height: 33mm;
}
.DateInfo {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}
.ReportDate {
  position: relative;
  width: 46mm;
  height: 8mm;
  font-size: 3.5mm;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.ReportDate_Text {
  width: 20mm;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ReportDate_Date {
  width: 26mm;
  display: flex;
  align-items: center;
}
.ReportDate_Date_Write {
}
.ReportDate_Date_Print {
  width: 26mm;
  height: 4mm;
  border-bottom: 1px solid rgb(192, 192, 192);
  display: flex;
  align-items: flex-end;
}
.ExpenseCode {
  position: relative;
  width: 60mm;
  height: 8mm;
  font-size: 3.5mm;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ExpenseCode_Text {
  width: 20mm;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ExpenseCode_Date {
  width: 40mm;
  display: flex;
  align-items: center;
}
.ExpenseCode_Date_Write {
}
.ExpenseCode_Date_Print {
  width: 26mm;
  height: 4mm;
  border-bottom: 1px solid rgb(192, 192, 192);
  display: flex;
  align-items: flex-end;
}
.SettlementDate {
  position: relative;
  width: 87mm;
  height: 8mm;
  font-size: 3.5mm;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.SettlementDate_Text {
  position: relative;
  width: 20mm;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.SettlementDate_Text_to {
  position: relative;
  width: 8mm;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.SettlementDate_icon {
  position: relative;
  width: 6mm;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.SettlementDate_Date {
  position: relative;
  width: 26mm;
  display: flex;
  align-items: center;
}
.SettlementDate_Date_Write {
}
.SettlementDate_Date_Print {
  width: 26mm;
  height: 4mm;
  border-bottom: 1px solid rgb(192, 192, 192);
  display: flex;
  align-items: flex-end;
}
.Sigmatek_Name {
  font-size: 4mm;
  padding: 0mm;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.Sigmatek_Info {
  font-size: 3mm;
  padding: 0mm;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-content: flex-start;
}
.One_Sigmatek_Info {
  margin: 0mm;
  height: 5mm;
  line-height: 5mm;
}

.Info {
  position: relative;
  height: 36mm;
}
.Info_RelevantInfo {
  height: 24mm;
}
.Info_RelevantInfo_line {
  height: 8mm;
  font-size: 3.5mm;
  position: relative;
  display: flex;
  align-items: center;
}
.Info_RelevantInfo_Text {
  position: relative;
  width: 20mm;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Info_RelevantInfo_Write_Short {
  position: relative;
  width: 26mm;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Info_RelevantInfo_Print_Short {
  position: relative;
  width: 26mm;
  height: 4mm;
  border-bottom: 1px solid rgb(192, 192, 192);
  display: flex;
  align-items: flex-end;
}
.Info_ReimbursementInstructions_Interval {
  position: relative;
  width: 8mm;
}
.Info_RelevantInfo_Write_Long {
  position: relative;
  width: 101mm;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Info_RelevantInfo_Print_Long {
  position: relative;
  width: 101mm;
  height: 4mm;
  border-bottom: 1px solid rgb(192, 192, 192);
  display: flex;
  align-items: flex-end;
}
.Info_ReimbursementInstructions {
  position: relative;
  height: 12mm;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Info_ReimbursementInstructions_Text {
  position: relative;
  width: 20mm;
  font-size: 3.5mm;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Info_ReimbursementInstructions_Write {
  position: relative;
  width: 155mm;
}
.Info_ReimbursementInstructions_Print {
  position: relative;
  width: 155mm;
  height: 4mm;
  font-size: 3.5mm;
  border-bottom: 1px solid rgb(192, 192, 192);
  display: flex;
  align-items: flex-end;
}

.Money {
  position: relative;
}
.Money_Tittle {
  position: relative;
  height: 8mm;
  font-size: 4.7mm;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.Money_Tittle_Button {
  padding-left: 20px;
}
.Money_Details_Write {
  width: 185mm;
}
.Money_Details_Write >>> .el-table .cell {
  padding: 0mm 1mm 0mm 1mm;
}
.Money_Details_Write >>> .el-table .el-table__cell {
  padding: 1mm 0mm;
}
.Money_Details_Print {
}
.Money_Details_Print >>> .el-table .cell {
  padding: 0mm 1mm 0mm 1mm;
}
.Money_Details_Print >>> .el-table .el-table__cell {
  padding: 1mm 0mm;
}
.Money_Details_Print >>> .el-table .el-table__row > td {
  border-bottom-color: rgb(192, 192, 192);
}
.Money_Details_Print >>> .el-table::before {
  -webkit-print-color-adjust: exact !important; /* Safari 和 Chrome */
  color-adjust: exact !important; /* Firefox */
  background-color: rgb(192, 192, 192);
}

.printcommon {
  font-size: 3.5mm;
  margin: 0mm;
}
.table_Date >>> .el-input__inner {
  padding: 0mm 1mm 0mm 1mm;
  width: 28mm;
}
.table_Type >>> .el-input__inner {
  padding: 0mm 1mm 0mm 1mm;
}
.table_Explain >>> .el-input__inner {
  padding: 0mm 1mm 0mm 1mm;
}
.table_Cost >>> .el-input__inner {
  padding: 0mm 1mm 0mm 1mm;
}
.EditButtonGroup {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
}
.EditButton {
  padding: 9px;
  font-size: 9px;
}
.EditButtonGroup >>> .el-button + .el-button {
  margin: 1mm 0mm 0mm 0mm;
}
.ButtonGroup_Menu {
  padding: 6px 6px;
}

.Money_Aggregation {
  height: 32mm;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-end;
}
.Money_Aggregation_Line {
  position: relative;
  height: 8mm;
  width: 54mm;
  font-size: 3.5mm;
  display: flex;
}
.Money_Aggregation_Text {
  position: relative;
  width: 30mm;
  padding-right: 2mm;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Money_Aggregation_Data_Write {
  position: relative;
  width: 26mm;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0mm 0.5mm 0mm 0.5mm;
}
.Money_Aggregation_Data_Write >>> .el-input__inner {
  padding: 0mm 1mm 0mm 1mm;
}
.Money_Aggregation_Data_Print {
  position: relative;
  width: 26mm;
  padding-right: 1mm;
  border-bottom: 1px solid rgb(192, 192, 192);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Money_Aggregation_Data_Subtotal_Print {
  position: relative;
  width: 26mm;
  -webkit-print-color-adjust: exact !important; /* Safari 和 Chrome */
  color-adjust: exact !important; /* Firefox */
  background-color: rgb(242, 242, 242);
  padding-right: 1mm;
  border-bottom: 1px solid rgb(192, 192, 192);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Money_Aggregation_Data_TotalReimbursement_Print {
  position: relative;
  width: 26mm;
  -webkit-print-color-adjust: exact !important; /* Safari 和 Chrome */
  color-adjust: exact !important; /* Firefox */
  background-color: rgb(203, 216, 235);
  padding-right: 1mm;
  border-bottom: 1px solid rgb(192, 192, 192);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Sign {
  position: relative;
}
.Sign_remarks {
  position: relative;
}
.Sign_remarks_Text {
  position: relative;
  height: 5mm;
  font-size: 4mm;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Sign_remarks_Input_Write {
  margin-top: 1mm;
}
.Sign_remarks_Input_Print {
  border: none;
  white-space: pre-line;
  line-height: 1.5;
  border-bottom: 1px solid rgb(192, 192, 192);
  min-height: 1.5em; /* 1.5 倍的行高 */
}
.Sign_remarks_Input_Print::before {
  content: "";
}

.Sign_name {
  margin-top: 5mm;
  margin-bottom: 20px;
  height: 20mm;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.One_Sign_name {
  width: 30mm;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Sign_name_Text {
  height: 10mm;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Sign_name_Sign {
  height: 10mm;
  width: 30mm;
  border-bottom: 1px solid rgb(192, 192, 192);
}

.DataFooter {
  position: relative;
  height: 10mm;
  font-size: 3.5mm;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#printArea_CCBTTJD {
  position: relative;
  width: 210mm;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#CCBTTJD_DataArea {
  position: relative;
  margin-left: 20mm;
  margin-right: 17mm;
}
.CCBTTJD_Tittle {
  position: relative;
  height: 40mm;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
}
.CCBTTJD_Tittle_Logo {
  display: flex;
  flex-direction: row-reverse;
  height: 16mm;
  padding-top: 5mm;
  padding-bottom: 3mm;
}
.CCBTTJD_Tittle_ExpenseCode {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 10mm;
  font-size: 4mm;
}
.CCBTTJD_Tittle_ExpenseCode_Left{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}
.CCBTTJD_Tittle_ExpenseCode_Text {
  width: 25mm;
}
.CCBTTJD_Tittle_ExpenseCode_ExpenseCode {
  width: 56mm;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-bottom: 1px solid black;
}
.CCBTTJD_Tittle_ExpenseCode_Right{
  margin-left: 50px;
  display: flex;
  align-items: center;
}
.CCBTTJD_Tittle_ExpenseCode_QuickDateSelection >>> .el-input__inner {
  padding: 0mm 9mm 0mm 0mm;
  width: 9mm;
}
/* .CCBTTJD_Tittle_ExpenseCode_QuickDateSelection >>> .el-date-editor.el-input {
  width: 35px;
} */


.CCBTTJD_Date {
  position: relative;
}
.CCBTTJD_Date >>> .el-table .cell {
  padding: 0mm 1mm 0mm 1mm;
}
.CCBTTJD_Date_Table{
  width: 653px;
}
/* .CCBTTJD_Date_Data_Input_Write >>> .el-input__inner {
  padding: 0mm 1mm 0mm 1mm;
  width: 25mm;
  height: 6mm;
}
.CCBTTJD_Date_Data_Input_Write >>> .el-input__icon {
  margin-left: -260px;
  line-height: 24px;
} */
.CCBTTJD_Date_Businesslocation_Input_Write >>> .el-input__inner {
  padding: 0mm 1mm 0mm 1mm;
  width: 24mm;
  height: 6mm;
}
.CCBTTJD_Date_Businesslocation_Input_Write >>> .el-input__icon {
  line-height: 24px;
}
.CCBTTJD_Date_AccommodationOrNot_Input_Write >>> .el-input__inner {
  padding: 0mm 1mm 0mm 1mm;
  width: 15mm;
  height: 6mm;
}
.CCBTTJD_Date_AccommodationOrNot_Input_Write >>> .el-input__icon {
  line-height: 24px;
}
.CCBTTJD_Date_AccommodationOrNot_Input_Write >>> .el-input__suffix {
  line-height: 24px;
  right: -3px;
}
.CCBTTJD_Date_AssignToReimbursement_Input_Write >>> .el-input__inner {
  padding: 0mm 1mm 0mm 1mm;
  width: 24mm;
  height: 6mm;
}
.CCBTTJD_Date_AssignTosalary_Input_Write >>> .el-input__inner {
  padding: 0mm 1mm 0mm 1mm;
  width: 24mm;
  height: 6mm;
}
.CCBTTJD_Date >>> .el-table::before {
  -webkit-print-color-adjust: exact !important; /* Safari 和 Chrome */
  color-adjust: exact !important; /* Firefox */
  background-color: rgb(192, 192, 192) !important;
}
.CCBTTJD_Date >>> .el-table::after {
  -webkit-print-color-adjust: exact !important; /* Safari 和 Chrome */
  color-adjust: exact !important; /* Firefox */
  background-color: rgb(192, 192, 192) !important;
}
.CCBTTJD_Date >>> .el-table {
  -webkit-print-color-adjust: exact !important; /* Safari 和 Chrome */
  color-adjust: exact !important; /* Firefox */
  border-left: 1px solid rgb(192, 192, 192) !important;
}

.CCBTTJD_Subtotal {

}
.CCBTTJD_Subtotal_Table {
  width: 653px;
  margin-top: -1px ; 
  margin-bottom: 20px ; 
  height: 7mm ;
  font-size: 12px ;
  border-collapse: collapse;
  border-spacing: 0;
  border-color: rgb(192, 192, 192);

}
.CCBTTJD_Subtotal_Table td {
  border: 1px solid rgb(192, 192, 192); /* 单元格边框 */
  border-left: 1px solid rgb(192, 192, 192) !important;

}
.CCBTTJD_Subtotal_Table td:nth-child(6) {
  text-align: right;
  -webkit-print-color-adjust: exact !important; /* Safari 和 Chrome */
  color-adjust: exact !important; /* Firefox */
  border-right: 1px solid rgb(255, 255, 255) !important;
  background-color: rgb(192, 192, 192) !important;
}
.CCBTTJD_Subtotal_Table td:nth-child(7) {
  text-align: right;
  -webkit-print-color-adjust: exact !important; /* Safari 和 Chrome */
  color-adjust: exact !important; /* Firefox */
  background-color: rgb(192, 192, 192) !important;
}


#NewRoles {
  width: 802px;
  padding-left: 50px;
}

</style>

<style>
.Editpopper {
  min-width: 0px !important;
}

.Money_Details_Header_Print th {
  -webkit-print-color-adjust: exact !important; /* Safari 和 Chrome */
  color-adjust: exact !important; /* Firefox */
  height: 5mm !important;
  font-size: 3.5mm !important;
  font-weight: bold !important;
  color: #000000 !important;
  background-color: #c0c0c0 !important;
}
.CCBTTJD_Date_Header_Print th {
  -webkit-print-color-adjust: exact !important; /* Safari 和 Chrome */
  color-adjust: exact !important; /* Firefox */
  height: 7mm !important;
  padding: 0px !important;
  font-size: 13px !important;
  font-weight: normal !important;
  color: #000000 !important;
  background-color: #c0c0c0 !important;
}
</style>
