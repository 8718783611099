<template>
  <div>
    <div id="buttongroup">
      <el-button type="primary" @click="CreatePcn">创建Pcn</el-button>
    </div>
    <el-table :data="pcnlist" style="width: 100%">
      <el-table-column label="产品" width="180" align="center">
        <template slot-scope="scope">
          {{ getListName(scope.row.products) }}
        </template>
      </el-table-column>
      <el-table-column prop="version" label="版本" width="180" align="center">
      </el-table-column>
      <el-table-column label="预计生效时间" align="center">
        <template slot-scope="scope">
          {{ new Date(scope.row.planTime).toLocaleString() }}
        </template>
      </el-table-column>
      <el-table-column prop="creater" label="创建人" align="center">
      </el-table-column>
      <el-table-column label="创建时间" align="center">
        <template slot-scope="scope">
          {{ new Date(scope.row.CreationTime).toLocaleString() }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button size="mini" @click="watchinfo(scope.row)">{{ scope.row.lock == 1 ? "查看" : "编辑" }}</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)" :disabled="scope.row.lock == 1"
            >删除</el-button
          >
          <el-button size="mini" @click="generatePdf(scope.row)"
            >生成PDF</el-button
          >
          <el-button size="mini" @click="lock(scope.row)" :disabled="scope.row.lock == 1"
            >锁定</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div>
      <el-dialog :visible.sync="editvisiable" width="50%">
        <el-descriptions title="物料变更通知书" border :column="1">
          <el-descriptions-item label="版本号">
            <el-checkbox-group v-model="selectpcn.products">
              <el-checkbox
                border
                v-for="item in allproducts"
                :key="item.id"
                :label="item.name"
                :disabled="selectpcn.lock"
            /></el-checkbox-group>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions border :column="2">
          <el-descriptions-item label="版本号（变更后）">
            <el-input v-model="selectpcn.version"  :disabled="selectpcn.lock"></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="计划生效时间：">
            <el-date-picker
              v-model="selectpcn.planTime"
              type="month"
              value-format="timestamp"
              placeholder="选择日期"
              :disabled="selectpcn.lock"
            >
            </el-date-picker>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions border :column="1">
          <el-descriptions-item label="变更说明">
            <el-input
              v-model="selectpcn.ChangeReason"
              type="textarea"
              :autosize="{ minRows: 1 }"
              :disabled="selectpcn.lock"
            ></el-input>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions
          border
          :column="2"
          v-for="item in selectpcn.ChangeInner"
        >
          <el-descriptions-item label="修改前">
            <el-input
              v-model="item.before.describe"
              placeholder="请输入描述"
              :disabled="selectpcn.lock == 1"
            ></el-input>
            <div class="center" v-if="!item.before.picpath">
              <el-upload
                class="upload-demo"
                drag
                multiple
                action=""
                :http-request="submitPic"
                :data="item.before"
                accept=".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PBG, .GIF, .pdf"
                v-if="!selectpcn.lock"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将文件拖到此处，或<em>点击上传</em>
                </div>
                <div class="el-upload__tip" slot="tip">
                  只能上传jpg/png文件，且不超过500kb
                </div>
              </el-upload>
            </div>
            <div class="center" v-else>
              <img
                :src="
                  PicObj[
                    item.before.picpath.split('/')[
                      item.before.picpath.split('/').length - 1
                    ]
                  ]
                "
                style="max-width: 200px"
              />
              <div style="margin-top: 5px" v-if="!selectpcn.lock">
                <el-button @click="deleimage(item.before)" >删除图片</el-button>
                <el-button @click="delerow(item)">删除此行</el-button>
              </div>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="修改后">
            <el-input
              v-model="item.after.describe"
              placeholder="请输入描述"
              :disabled="selectpcn.lock == 1"
            ></el-input>
            <div class="center" v-if="!item.after.picpath">
              <el-upload
                class="upload-demo"
                drag
                multiple
                action="''"
                :http-request="submitPic"
                :data="item.after"
                accept=".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PBG, .GIF, .pdf"
                v-if="!selectpcn.lock"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将文件拖到此处，或<em>点击上传</em>
                </div>
                <div class="el-upload__tip" slot="tip">
                  只能上传jpg/png文件，且不超过500kb
                </div>
              </el-upload>
            </div>
            <div class="center" v-else>
              <img
                :src="
                  PicObj[
                    item.after.picpath.split('/')[
                      item.after.picpath.split('/').length - 1
                    ]
                  ]
                "
                style="max-width: 200px"
              />
              <div style="margin-top: 5px" v-if="!selectpcn.lock">
                <el-button @click="deleimage(item.after)" >删除图片</el-button>
                <el-button @click="delerow(item)">删除此行</el-button>
              </div>
            </div>
          </el-descriptions-item>
        </el-descriptions>
        <span slot="footer" class="dialog-footer" v-if="!selectpcn.lock">
          <el-button @click="addChangeInner">添加变更说明</el-button>
          <el-button @click="editvisiable = false">取 消</el-button>
          <el-button type="primary" @click="updatePcn">确 定</el-button>
        </span>
        <span v-else  slot="footer" class="dialog-footer" >
          <el-button @click="editvisiable = false">返回</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getPcnList,
  CreatPcn,
  updatePcn,
  deletePcn,
  GeneratePdf,
} from "@/api/pcn";
import { uploadExtra, download, deleteFile } from "@/api/fileTransfer";
import { getName } from "@/utils/auth";
import { GetAllProducts } from "@/api/mesproductinfo";
export default {
  data() {
    return {
      filter: {
        limit: 10,
        page: 1,
      },
      total: 0,
      pcnlist: [],
      editvisiable: false,
      selectpcn: {},
      allproducts: [],
      PicObj: {},
    };
  },
  mounted() {
    this.getdata();
    this.GetAllProducts();
  },
  methods: {
    deleimage(obj) {
      //因为东西会很少所以就不考虑删除服务器的文件了直接filepath置空就行
      //console.log(obj);
      obj.picpath = "";
    },
    delerow(obj) {
      this.selectpcn.ChangeInner.filter((item) => {
        if (item == obj) {
          //删除obj
          this.selectpcn.ChangeInner.splice(
            this.selectpcn.ChangeInner.indexOf(obj),
            1
          );
        }
      });
    },
    GetAllProducts() {
      GetAllProducts().then((res) => {
        this.allproducts = res.data.list;
      });
    },
    getdata() {
      this.$loading();
      getPcnList(this.filter).then((res) => {
        this.total = res.data.total;
        this.pcnlist = res.data;
        this.$loading().close();
      });
    },
    getListName(products) {
      let res = "";
      products.forEach((element) => {
        res += element + " ";
      });
      res = res.substring(0, res.length - 1);
      return res;
    },
    CreatePcn() {
      let name = getName();
      CreatPcn({
        creater: name,
      }).then((res) => {
        this.getdata();
      });
    },
    handleDelete(pcn) {
      deletePcn({ _id: pcn._id }).then((res) => {
        this.getdata();
      });
    },
    async getsrc(url) {
      if (!url) {
        return;
      }
      let data = await download({
        filename: url.split("/")[url.split("/").length - 1],
        path: url,
      });
      const blob = new Blob([data]);
      let url1 = URL.createObjectURL(blob);
      this.PicObj[url.split("/")[url.split("/").length - 1]] = url1;
      //vue强制刷新
      this.$forceUpdate();
    },
    async watchinfo(pcn) {
      //深拷贝pcn
      this.selectpcn = JSON.parse(JSON.stringify(pcn));
      this.editvisiable = true;
      this.selectpcn.ChangeInner.forEach((item) => {
        this.getsrc(item.before.picpath);
        this.getsrc(item.after.picpath);
      });
    },
    addChangeInner() {
      this.selectpcn.ChangeInner.push({
        before: {
          describe: "",
          picpath: "",
        },
        after: {
          describe: "",
          picpath: "",
        },
      });
    },
    updatePcn() {
      updatePcn(this.selectpcn).then((res) => {
        this.editvisiable = false;
        this.getdata();
      });
    },
    generatePdf(data) {
      GeneratePdf(data)
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => {
          const blob = new Blob([arrayBuffer], {
            type: "application/octet-stream",
          });

          // 创建一个下载链接
          const url = URL.createObjectURL(blob);

          // 创建一个隐藏的 <a> 标签
          const link = document.createElement("a");
          link.href = url;
          let time = new Date().toDateString();
          link.download = `Pcn_${time}.pdf`;
          // 模拟点击下载链接
          link.click();

          // 清理释放资源
          URL.revokeObjectURL(url);
        });
    },
        lock(pcn) {
      //1.弹窗提问是否锁定
      this.$confirm("是否锁定?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        //2.锁定
        pcn.lock = 1;
        updatePcn(pcn).then((res) => {
          this.getdata();
        })
      })
    },
    async submitPic(param) {
      //要先设定version
      let obj = param.data;
    //  console.log(param);
      const formData = new FormData();
      formData.append("ModleName", param.file); // 要提交给后台的文件
      formData.append("projectFolder", this.selectpcn._id); // 这个接口必要的项目id
      formData.append("subFolder", "pics");
      const { data } = await uploadExtra(formData, {
        path: this.selectpcn._id,
      });
      if (data == "success") {
        let url = "file/" + this.selectpcn._id + "/" + param.file.name;
        obj.picpath = url;
        //把图片转base64
        this.$message({
          message: "上传成功",
          type: "success",
        });
        this.selectpcn.ChangeInner.forEach((item) => {
          this.getsrc(item.before.picpath);
          this.getsrc(item.after.picpath);
        });
      } else {
        this.$message({
          message: "上传失败",
          type: "warning",
        });
      }
    },
  },
};
</script>

<style>
#buttongroup {
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}
.center {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  align-content: center;
  flex-direction: column;
}
</style>