import request from '@/utils/request'
export function createlabelinfo(data) {
    return request({
      url: '/Api/label/createlabelinfo',
      method: 'post',
      data
    })
  }
  export function getlabelList(params) {
    return request({
        url: '/Api/label/getlabelList',
        method: 'get',
        params
    })
  }
  export function updatelabelList(data) {
    return request({
      url: '/Api/label/updatelabelList',
      method: 'post',
      data
    })
  }
  export function deletelabel(id) {
    return request({
      url: '/Api/label/deletelabel',
      method: 'delete',
      params: id
    })}