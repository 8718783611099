<template>
  <div class="outbox">
    <div class="head">
      <el-button type="primary" @click="creatShipping">新建</el-button>
      <el-input
        class="SearchInput"
        v-model="SearchNr"
        placeholder="按照序列号搜索"
        @change="SearchChange"
      ></el-input>
    </div>
    <div class="table">
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        v-loading="loading"
      >
        <el-table-column
          prop="date"
          label="日期"
          style="width: 15%"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="NoteNr"
          label="单号"
          style="width: 15%"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="customer"
          label="采购商"
          style="width: 40%"
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" style="width: 30%" align="center">
          <template slot-scope="scope">
            <el-button @click="editunit(scope.$index)">编辑</el-button>
            <el-popover
              placement="top"
              width="160"
              v-model="scope.row.DeleteConfirmVisiable"
            >
              <p>确认删除？</p>
              <div style="text-align: right; margin: 0">
                <el-button
                  size="mini"
                  type="text"
                  @click="scope.row.DeleteConfirmVisiable = false"
                  >取消</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  @click="
                    (scope.row.DeleteConfirmVisiable = false),
                      deliteunit(scope.row._id)
                  "
                  >确定</el-button
                >
              </div>
              <el-button slot="reference">删除</el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  createshipping,
  getshippinglist,
  deleteShipping,
} from "@/api/shipping";
export default {
  name: "shippingTable",
  data() {
    return {
      tableData: [],
      Data: [],
      listQuery: {
        sort: "+id",
        page: 1,
        limit: 10,
        filters: null,
      },
      loading: false,
      total: 0,
      SearchNr: null,
    };
  },
  created() {
    this.getlist();
  },
  methods: {
    editunit(input) {
      this.$router.push({
        name: "shippingUnit",
        params: { shippingObj: this.Data[input] },
      });
    },
    async creatShipping() {
      const { items } = await createshipping({
        DeliveryInfolist: [
          {
            Pos: 1,
            Module: null,
            ArticleNr: null,
            CustomId: null,
            Quantity: null,
            SerialNr: null,
            PartNr: null,
            reportNr: null,
            reSerialNr: null,
          },
        ],
        HeadInof: {
          NoteNr: null,
          ExpressNr: null,
          DateNr: null,
        },
        PurchAserInfo: {
          Name: null,
          Address: null,
          Telephone: null,
          People: null,
        },
      });
      this.getlist();
    },
    async getlist() {
      this.loading = true;
      const { data } = await getshippinglist({
        page: this.listQuery.page,
        limit: this.listQuery.limit,
        filters: this.listQuery.filters,
      });
      this.tableData = [];
      this.Data = data.list;
      this.total = data.total;
      data.list.forEach((item) => {
        this.tableData.push({
          date: item.HeadInof.DateNr,
          NoteNr: item.HeadInof.NoteNr,
          customer: item.PurchAserInfo.Name,
          _id: item._id,
          DeleteConfirmVisiable: false,
        });
      });
      this.loading = false;
    },
    async deliteunit(id) {
      const data = await deleteShipping({ id: id });
      if (data) {
        this.$message({
          showClose: true,
          message: "删除成功",
          type: "success",
        });
      }
      this.getlist();
    },
    handleCurrentChange(page) {
      this.listQuery.page = page;
      this.getlist();
    },
    SearchChange(input) {
      if (input) {
        // this.listQuery.filters = "({'DeliveryInfolist':{$elemMatch:{SerialNr:'"+input+"'}}})"
        this.listQuery.filters =
          "({'DeliveryInfolist':{$elemMatch:{SerialNr:{ $regex:/^.*" +
          input +
          ".*$/,$options: 'i'}}}})";
      } else {
        this.listQuery.filters = null;
      }

      this.getlist();
    },
  },
};
</script>

<style>
.head {
  margin-left: 2.5%;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 95%;
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.table {
  margin-left: 2.5%;
  width: 95%;
}
.el-popover__reference-wrapper {
  margin-left: 10px;
}
.pagination {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.SearchInput {
  display: inline-block;
  width: 230px;
  margin-left: 30px;
}
</style>