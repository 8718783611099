<template>
  <div>
    <el-container style="padding: 20px">
      <el-header style="margin-bottom: 20px"
        ><el-card>
          <span style="display: inline-block">
            <el-button
              type="primary"
              @click="
                filter.Status = 'open';
                filerChange();
              "
              v-if="filter.Status == 'all'"
              >显示全部</el-button
            >
            <el-button
              type="primary"
              @click="
                filter.Status = 'close';
                filerChange();
              "
              v-if="filter.Status == 'open'"
              >显示未完成</el-button
            >
            <el-button
              type="primary"
              @click="
                filter.Status = 'all';
                filerChange();
              "
              v-if="filter.Status == 'close'"
              >显示已完成</el-button
            >
          </span>
          <span style="display: inline-block; margin-left: 10px">
            <el-input
              placeholder="关键字筛选"
              v-model="filter.key"
              @change="filerChange"
            />
          </span> </el-card
      ></el-header>
      <el-main
        ><el-card>
          <el-table :data="IssueList" style="width: 100%" v-loading="loading">
            <el-table-column
              prop="supportNumber"
              label="工单号"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="modelname"
              label="故障模块"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="productSeriNum"
              label="序列号"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="descript"
              label="故障描述"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="username"
              label="提交人"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="usercompany" label="公司" align="center">
            </el-table-column>
            <el-table-column prop="usercompany" label="状态" align="center">
              <template slot-scope="scope">
                <el-tag type="warning" v-if="scope.row.Status == 'open'">
                  未完成
                </el-tag>
                <el-tag type="success" v-if="scope.row.Status == 'close'">
                  已完成
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="createdAt"
              label="创建日期"
              width="180"
              align="center"
            >
              <template slot-scope="scope">
                {{ new Date(scope.row.createdAt).toLocaleString() }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="180" align="center">
              <template slot-scope="scope">
                <el-button size="small" @click="editIssue(scope.row)"
                  >查看</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-main>
      <el-footer>
        <el-card style="display: flex; justify-content: space-around">
          <el-pagination
            @current-change="PageChange"
            :total="total"
            :page-size="filter.PageNum"
          />
        </el-card>
      </el-footer>
    </el-container>
    <el-dialog
      :title="SelectList.Status == 'open' ? '编辑' : '查看'"
      :visible.sync="EdittableViasiable"
      width="80%"
      center
      class="IssueDialog"
    >
      <el-descriptions title="用户信息">
        <el-descriptions-item label="模块">{{
          SelectList.modelname
        }}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{
          SelectList.userphone
        }}</el-descriptions-item>
        <el-descriptions-item label="提交人">{{
          SelectList.username
        }}</el-descriptions-item>
        <el-descriptions-item label="提交人">{{
          SelectList.username
        }}</el-descriptions-item>
        <el-descriptions-item label="公司">{{
          SelectList.usercompany
        }}</el-descriptions-item>
        <el-descriptions-item label="设备序列号">{{
          SelectList.productSeriNum
        }}</el-descriptions-item>
        <el-descriptions-item label="工单状态">
          <el-switch
            v-model="SelectList.Status"
            active-value="open"
            inactive-value="close"
            active-text="未完成"
            inactive-text="已结束"
          >
          </el-switch>
        </el-descriptions-item>
      </el-descriptions>
      <el-card style="border-radius: 10px">
        <h3>故障简述</h3>
        {{ SelectList.Tittle }}
      </el-card>
      <el-card style="border-radius: 10px; margin-top: 20px">
        <h3>详细信息</h3>
        {{ SelectList.descript }}
      </el-card>
      <el-card style="border-radius: 10px; margin-top: 20px">
        <h3>视频和图片</h3>
        <div
          style="
            display: flex;
            align-content: center;
            justify-content: flex-start;
            align-items: center;
            justify-items: stretch;
            flex-direction: row;
            flex-wrap: wrap;
          "
        >
          <el-card
            v-for="item in SelectList.files"
            :key="item.name"
            style="margin: 10px"
          >
            <el-image
              style="height: 100px"
              :src="`https://wx.sigmatek-oa.cn/issue/${SelectList._id}/${item.name}`"
              :preview-src-list="[
                `https://wx.sigmatek-oa.cn/issue/${SelectList._id}/${item.name}`,
              ]"
              v-if="item.type == 'image'"
            >
            </el-image>
            <video
              :src="`https://wx.sigmatek-oa.cn/issue/${SelectList._id}/${item.name}`"
              controls
              style="width: 100px"
              v-else
            ></video>
          </el-card>
        </div>
      </el-card>
      <el-card style="border-radius: 10px; margin-top: 20px">
        <h3>解决方案</h3>
        <el-input
          type="textarea"
          placeholder="请输入内容"
          v-model="SelectList.solution"
          :autosize="{ minRows: 5 }"
        >
        </el-input>
      </el-card>
      <div style="display: flex; justify-content: flex-end">
        <div style="margin-top: 20px; margin-bottom: 20px">
          <el-button type="primary" @click="EdittableViasiable = false">
            关闭
          </el-button>
          <el-button type="primary" @click="updateIssue()">保存</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAllErrorSubmitCount,
  getAllErrorSubmit,
  updateSubmit,
} from "@/api/WxIssue";
export default {
  data() {
    return {
      filter: {
        Status: "all",
        key: "",
        page: 1,
        PageNum: 10,
      },
      total: 10,
      loading: false,
      EdittableViasiable: false,
      checkList: [],
      SelectList: {},
      IssueList: [
        {
          supportNumber: "",
          Tittle: "",
          username: "",
          usercompany: "",
          userphone: "",
          modelname: "",
          productSeriNum: "",
          descript: "",
          Status: "",
          solution: "",
          createdAt: "",
        },
      ],
    };
  },
  methods: {
    PageChange(val) {
      this.filter.page = val;
      this.getdata();
    },
    filerChange() {
      this.filter.page = 1;
      getAllErrorSubmitCount({ filter: this.filter }).then((res) => {
        this.total = res.data;
        this.getdata();
      });
    },
    updateIssue() {
    //  console.log(this.SelectList);
      updateSubmit(this.SelectList).then((res) => {
      //  console.log(res);
        this.EdittableViasiable = false;
      });
    },
    getdata() {
      this.loading = true;
      getAllErrorSubmit({ filter: this.filter })
        .then((res) => {
          this.IssueList = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    editIssue(e) {
      //深拷贝
      this.SelectList = e;
      this.EdittableViasiable = true;
    },
  },
  created() {
    this.filerChange();
  },
};
</script>

<style>
.IssueDialog h3 {
  margin-top: 5px;
}
</style>
