var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "inline-block" } },
    [
      _c("label", { staticClass: "radio-label" }, [_vm._v("Book Type: ")]),
      _c(
        "el-select",
        {
          staticStyle: { width: "120px" },
          model: {
            value: _vm.bookType,
            callback: function ($$v) {
              _vm.bookType = $$v
            },
            expression: "bookType",
          },
        },
        _vm._l(_vm.options, function (item) {
          return _c("el-option", {
            key: item,
            attrs: { label: item, value: item },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }