<template>
  <div class="dashboard-container">
    <div>
      <el-card class="TitleBox">
        <h5 class="TitleBox_title" >用户管理</h5>
        <el-row class="TitleBox_body">
          <el-col :span="12" class="TitleBox_bodytext"> <p>用户名：{{ name }}</p>        </el-col>
          <el-col :span="12" class="TitleBox_bodytext"> <p>用户权限：{{ depatment }}</p> </el-col>
        </el-row>
      </el-card>

      <el-row class="CalendarNotice">
        <el-col :span="18" style="height: 100%;">
          <el-card class="Calendar">
            <el-calendar v-model="MainCalendar">
              <template slot="dateCell" slot-scope="{ data }">
                <div class="center">
                  {{ data.day.split("-").slice(2).join("-") }}
                </div>
                <div v-for="(item, index) in Personal_Calendar_Collection" :key="index">
                  <div v-if="data.day == ToYYYYMMDD(item.Date)">

                    <div v-if="item.Occur_Business.length > 0" >
                      <el-tooltip 
                        effect="dark" 
                        placement="top"
                      >
                        <div slot="content">
                          {{getContent_Business_When(item)}}<br/>
                          {{getContent_Business_Where(item)}}
                        </div>                     
                        <div v-if="item.Occur_Business[0].isAllDay" class="Business_Between"><div class="EventBar"/></div>
                        <div v-else-if="(item.Occur_Business[0].LeaveTimeE)&&(item.Occur_Business[0].LeaveTimeS)" class="Business_StartEnd"><div class="EventBar"/></div>
                        <div v-else-if="item.Occur_Business[0].LeaveTimeS" class="Business_Start"><div class="EventBar"/></div>
                        <div v-else-if="item.Occur_Business[0].LeaveTimeE" class="Business_End"><div class="EventBar"/></div>
                      </el-tooltip>
                    </div>

                    <div v-if="(item.Occur_Business.length === 0)">
                      <div class="Business_None"><div class="EventBar"/></div>
                    </div>

                    <div v-if="item.Occur_Leave.length > 0" >
                      <el-tooltip 
                        effect="dark" 
                        placement="top"
                      > 
                        <div slot="content">
                          {{getContent_Leave_When(item)}}<br/>
                          {{getContent_Leave_nature(item)}}<br/>
                          {{getContent_Leave_Why(item)}}
                        </div>                     
                        <div v-if="item.Occur_Leave[0].isAllDay" class="Leave_Between"><div class="EventBar"/></div>
                        <div v-else-if="(item.Occur_Leave[0].LeaveTimeS)&&(item.Occur_Leave[0].LeaveTimeE)" class="Leave_StartEnd"><div class="EventBar"/></div>
                        <div v-else-if="item.Occur_Leave[0].LeaveTimeS" class="Leave_Start"><div class="EventBar"/></div>
                        <div v-else-if="item.Occur_Leave[0].LeaveTimeE" class="Leave_End"><div class="EventBar"/></div>
                      </el-tooltip>
                    </div>

                    <div v-if="(item.Occur_Leave.length === 0)">
                      <div class="Business_None"><div class="EventBar"/></div>
                    </div>

                    <div v-if="item.Occur_Overtime.length > 0">
                      <el-tooltip 
                        effect="dark"
                        placement="top"
                      > 
                        <div slot="content">
                          {{getContent_Overtime_When(item)}}<br/>
                          {{getContent_Overtime_Where(item)}}<br/>
                          {{getContent_Overtime_Why(item)}}
                        </div>                     
                        <div v-if="(item.Occur_Overtime[0].LeaveTimeS)&&(item.Occur_Overtime[0].LeaveTimeE)" class="Overtime_StartEnd"><div class="EventBar"/></div>
                        <div v-else-if="item.Occur_Overtime[0].LeaveTimeS" class="Overtime_Start"><div class="EventBar"/></div>
                        <div v-else-if="item.Occur_Overtime[0].LeaveTimeE" class="Overtime_End"><div class="EventBar"/></div>
                        <div v-else-if="item.Occur_Overtime[0].isAllDay" class="Overtime_Between"><div class="EventBar"/></div>
                      </el-tooltip>
                    </div>

                  </div>
                </div>
              </template>
            </el-calendar>
          </el-card>
        </el-col>

        <el-col :span="6" style="height: 100%;">
          <div class="Operate">
            <el-card class="OperateCard">
              <el-button size="small" style="background-color: lightblue;" plain @click="CreatBusinessReport">
                出差报备
              </el-button>
              <el-button size="small" style="background-color: lightgreen;" plain @click="CreatLeaveReport">
                请假报备
              </el-button>
              <el-button size="small" style="background-color: lightgray ;" plain @click="CreatOvertimeReport">
                加班报备
              </el-button>
              <el-button size="small" type="success" style="float: right;">
                审核
              </el-button>
            </el-card>
          </div>

          <div v-loading="listLoading" style="height: 100%;">
            <!-- 快捷跳转，工程部才会显示 -->
            <!-- 可能会出现bug，暂缓上线 -->
            <!-- <el-card class="QuickJump">              
              <div slot="header">
                <span>快捷跳转</span>
              </div>

              <template>
                <el-table
                  :data="RecentList"
                  style="width: 100%"
                  :header-cell-style="{
                    height: '5mm',
                    fontsize: '3.5mm',
                    fontWeight: 'bold',
                    backgroundColor: '#EBF0F9',
                    color: '#000000',
                  }"
                  class="QuickJumpTable"
                  @cell-click="GotoProject"
                >
                  <el-table-column prop="name" label="项目名" min-width="100">
                  </el-table-column>
                  <el-table-column
                    prop="ProjectCode"
                    label="项目代码"
                    min-width="40"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="remaining"
                    label="未完成"
                    min-width="40"
                  >
                  </el-table-column>
                </el-table>
              </template>
            </el-card> -->

            <el-card class="NoticeMini" >
              <div slot="header">
                <span>通知信息</span>
                <div style="float: right;">
                  <el-button style="padding: 3px 0" type="text" @click="ChangeandWithdraw">修改/撤回</el-button>
                  <el-button style="padding: 3px 0" type="text">展开</el-button>
                </div>
              </div>

              <div v-if="!Personal_Event_Collection.length" class="NoNotification">
                <img
                  style="opacity: 0.3"
                  src="../dashboard/icon/NoNotification.png"
                />
              </div>

              <div v-if="Personal_Event_Collection.length">
                <el-card 
                  v-for="(One_Event, One_Event_Number) in Personal_Event_Collection.slice(0, 4)" 
                  :key="One_Event_Number"
                  class="NoticeInfo"
                >
                  <div slot="header">
                    <span style="font-size: 15px;">
                      {{ ToYYYYMMDD(One_Event.LeaveTimeS) + ' ' + ToHHMM(One_Event.LeaveTimeS) }} ~ 
                      {{ ToYYYYMMDD(One_Event.LeaveTimeE) + ' ' + ToHHMM(One_Event.LeaveTimeE) }}
                    </span>
                    <span style="float: right">{{ getStatus(One_Event_Number) }}</span>
                  </div>

                  <div>
                    <ul style="margin: 10px 0px 10px 0px;">
                      <li v-if="One_Event.event === '请假'">
                        <span> {{ One_Event.event }}({{ One_Event.Occur_Leave.nature }})</span>
                        <span style="float: right ; padding-right: 20px;"> {{ TimeResolved(One_Event)}}</span>
                      </li>
                      <li v-if="One_Event.event === '出差'">
                        <span> {{ One_Event.event }}</span>
                        <span style="float: right ; padding-right: 20px;"> {{ TimeResolved(One_Event)}}</span>
                      </li>
                      <li v-if="One_Event.event === '加班'">
                        <span> {{ One_Event.event }}</span>
                        <span style="float: right ; padding-right: 20px;"> {{ TimeResolved(One_Event)}}</span>
                      </li>
                      <ul style="margin-top: 10px;">
                        <li v-if="One_Event.event === '出差'"><span> 地点：{{ One_Event.Occur_Business.Where }} </span></li>
                        <li v-if="One_Event.event === '请假'"><span> 原因：{{ One_Event.Occur_Leave.Why }} </span></li>
                        <li v-if="One_Event.event === '加班'"><span> 地点：{{ One_Event.Occur_Overtime.Where }} </span></li>
                        <li v-if="One_Event.event === '加班'"><span> 原因：{{ One_Event.Occur_Overtime.Why }} </span></li>
                      </ul>
                    </ul>
                  </div>

                  <el-divider class="card-footer-divider"/>

                  <div class="card-footer">
                    <span> 意见 </span>
                    <div class="Opinions">
                      <el-avatar :size="30" > 顾 </el-avatar>
                      <span style="padding-left: 10px;"> {{ One_Event.Report.BossOpinions }} </span>
                    </div>
                    <div 
                      v-if="!((userinfo.manager !== '顾瑞祥')||
                              (userinfo.manager === null)||
                              (userinfo.hasOwnProperty('manager')))"
                      class="Opinions">
                      <el-avatar :size="30"> {{userinfo.manager}} </el-avatar>
                      <span style="padding-left: 10px;"> {{ One_Event.Report.ManagerOpinions }} </span>
                    </div>

                  </div>
                </el-card>
              </div>

            </el-card>

          </div>
        </el-col>
      </el-row>
    </div>

    <!-- 出差报备弹窗 -->
    <el-dialog 
      title="出差报备" 
      :visible.sync="BusinessTripReport" 
      width="40%" 
      :close-on-click-modal="false"
      @close="CancelReport"
    >
      <el-form label-width="130px" label-position="left">
        <el-form-item label="出差时间">
          <el-date-picker
            v-model="datavalue"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 80%"
            value-format="timestamp"
            @change="calculateDays('出差')"
            :default-time="['09:00:00', '17:30:00']"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="" v-if="datavalue" class="TimeStatistics">
          <div>计算出出差时间为{{ getBusinessTimeInterval_Day }}天，</div>
          <div>其中有{{ getBusinessTimeInterval_Holiday }}天节假日</div>
        </el-form-item>

        <el-form-item label="出差地点">
          <el-input
            list="browsers"
            type="text"
            v-model="Temporary_Occur.Occur_Business.Where"
            class="edit-input"
            placeholder="支持模糊搜索，找不到可以手输"
            :trigger-on-focus="false"
          />
          <datalist id="browsers">
            <option v-for="item in customerInfo">
              {{ item["company"] }}
            </option>
          </datalist>
        </el-form-item>

        <el-form-item label="绑定项目（可选）">
          <template>
            <el-select
              v-model="Temporary_Occur.Occur_Business.BindProject"
              filterable
              placeholder="请选择"
              style="width: 100%;"
            >
              <el-option
                v-for="item in Projects"
                :key="item.value"
                :label="item.label"
                :value="item.id"
              >
                <span style="float: left"> {{ item.label }} </span>
                <span style="float: right; color: #8492a6; font-size: 13px">
                  {{ item.value }}
                </span>
              </el-option>
            </el-select>
          </template>
        </el-form-item>

        <el-form-item label="是否通报">
          <el-switch v-model="Temporary_Occur.ShouldReport"></el-switch>
        </el-form-item>
      </el-form>
      <div style="display: flex;justify-content: flex-end;">
        <el-button @click="CancelReport">取 消</el-button>
        <el-button type="primary" @click="ConfirmReport_Business">确 定</el-button>
      </div>
    </el-dialog>



    <!-- 请假报备弹窗 -->
    <el-dialog 
      title="请假报备" 
      :visible.sync="LeaveReport" 
      width="40%" 
      :close-on-click-modal="false"
      @close="CancelReport"
    >
      <el-form label-width="130px" label-position="left">

        <el-form-item label="请假时间">
          <el-date-picker
            v-model="datavalue"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 80%"
            value-format="timestamp"
            @change="calculateDays('请假')"
            :default-time="['09:00:00', '17:30:00']"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="" v-if="datavalue" class="TimeStatistics">
          <div>计算出请假时间为{{ getLeaveTimeInterval_Hour }}小时，</div>
        </el-form-item>

        <el-form-item label="请假原因">
          <el-input
            type="text"
            v-model="Temporary_Occur.Occur_Leave.Why"
            class="edit-input"
          />
        </el-form-item>

        <el-form-item label="请假类型">
          <el-select v-model="Temporary_Occur.Occur_Leave.nature" placeholder="请选择">
            <el-option-group
              v-for="group in LeaveNatureOptions"
              :key="group.label"
              :label="group.label">
              <el-option
                v-for="item in group.options"
                :key="item.value"
                :label="item.value"
                :value="item.value">
              </el-option>
            </el-option-group>          
          </el-select>
        </el-form-item>

      </el-form>
      <div style="display: flex;justify-content: flex-end;">
        <el-button @click="CancelReport">取 消</el-button>
        <el-button type="primary" @click="ConfirmReport_Leave">确 定</el-button>
      </div>
    </el-dialog>



    <!-- 加班报备弹窗 -->
    <el-dialog 
      title="加班报备" 
      :visible.sync="OvertimeReport" 
      width="40%" 
      :close-on-click-modal="false"
      @close="CancelReport"
    >
      <el-form label-width="130px" label-position="left">
        <el-form-item label="加班时间">
          <el-date-picker
            v-model="datavalue"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 80%"
            value-format="timestamp"
            @change="calculateDays('加班')"
            :default-time="['09:00:00', '17:30:00']"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="" v-if="datavalue" class="TimeStatistics">
          <div>{{ getOvertimeTimeInterval }}</div>
        </el-form-item>

        <el-form-item label="加班地点">
          <el-input
            type="text"
            v-model="Temporary_Occur.Occur_Overtime.Where"
            class="edit-input"
          />
        </el-form-item>

        <el-form-item label="加班原因">
          <el-input
            type="text"
            v-model="Temporary_Occur.Occur_Overtime.Why"
            class="edit-input"
          />
        </el-form-item>

      </el-form>
      <div style="display: flex;justify-content: flex-end;">
        <el-button @click="CancelReport">取 消</el-button>
        <el-button type="primary" @click="ConfirmReport_Overtime">确 定</el-button>
      </div>
    </el-dialog>



    <!-- 修改弹窗 -->
    <el-dialog 
      title="修改/撤回" 
      :visible.sync="ChangeDialog" 
      width="90%" 
      :close-on-click-modal="false"     
      class="Change_Dialog"
      @close="CancelReport.then(fetchData())"
    >
      <el-col :span="14">
        <el-card class="Choose_Change" >

          <div slot="header" class="Choose_Change_Header">
            <div style="display: flex;
                        flex-direction: row;
                        align-items: center;"
            >
              <span>
                选择修改条目
              </span>

              <div 
                v-if="userinfo.token === 'Admin-token' || userinfo.token_Sigmatek === 'Admin-token'"
                style="margin-left: 50px;"
              >
                <el-tooltip class="item" effect="dark" content="管理员权限" placement="top">
                  <el-switch
                    v-model="RapidScreen_AdministratorPrivileges"
                    active-color="#13ce66"
                    inactive-color="#ff4949">
                  </el-switch>
                </el-tooltip>
              </div>

              <div 
                v-if="RapidScreen_AdministratorPrivileges"
                style="margin-left: 10px;"
              >
                <el-select 
                  v-model="RapidScreen_Name" 
                  placeholder="请选择"
                  size = "mini"
                  style="width: 110px;"
                  @change="SetRapidScreenListQuery"
                >
                  <el-option
                    v-for="item in Userlist"
                    :key="item.JobNumber"
                    :label="item.username"
                    :value="item.username">
                  </el-option>
                </el-select>
              </div>
            </div>
            
            <div>
              <el-date-picker
                v-model="RapidScreen_Month"
                type="month"
                placeholder="选择月"
                :clearable="false"
                size = "mini"
                @change="SetRapidScreenListQuery"
                style="margin-right: 10px; width: 110px;"
              />

              <el-radio-group 
                v-model="RapidScreen_Event" 
                size="mini"
                @change="SetRapidScreenListQuery"
                style="margin-right: 10px;"
              >
                <el-radio-button label="全部"></el-radio-button>
                <el-radio-button label="出差"></el-radio-button>
                <el-radio-button label="请假"></el-radio-button>
                <el-radio-button label="加班"></el-radio-button>
              </el-radio-group>

              <el-radio-group 
                v-model="RapidScreen_Revise" 
                size="mini"
                @change="SetRapidScreenListQuery"
              >
                <el-radio-button label="全部"></el-radio-button>
                <el-radio-button label="仅可修改"></el-radio-button>
              </el-radio-group>
            </div>
          </div>

          <div v-loading="listLoading_RapidScreenTable">
            <template>
              <el-table
                :data="RapidScreen_Table"
                ref="RapidScreenTable"
                :default-sort = "{prop: 'CreatTime', order: 'descending'}"
                border
                fit
                highlight-current-row
                @current-change="SelectionEvent"
                height="610px"
              >

                <el-table-column label="创建时间" prop="CreatTime" sortable min-width="15%">
                  <template slot-scope="scope">
                    {{ ToYYMMDD(scope.row.CreatTime) + ' ' + ToHHMM(scope.row.CreatTime) }}
                  </template>
                </el-table-column>

                <el-table-column label="开始时间" min-width="15%">
                  <template slot-scope="scope">
                    {{ ToYYMMDD(scope.row.LeaveTimeS) + ' ' + ToHHMM(scope.row.LeaveTimeS) }}
                  </template>
                </el-table-column>

                <el-table-column label="持续时间" min-width="10%">
                  <template slot-scope="scope" >
                    {{ RapidScreenAnalysis_Duration(scope.row) }}
                    <template v-if="scope.row.event === '出差'">
                      <div style="font-size: 12px">
                        {{ RapidScreenAnalysis_DurationWeekend(scope.row) }}
                      </div>
                    </template>
                  </template>
                </el-table-column>

                <el-table-column label="结束时间" min-width="15%">
                  <template slot-scope="scope">
                    {{ ToYYMMDD(scope.row.LeaveTimeE) + ' ' + ToHHMM(scope.row.LeaveTimeE) }}
                  </template>
                </el-table-column>

                <el-table-column label="事件" min-width="10%">
                  <template slot-scope="scope">
                    {{ scope.row.event }}
                  </template>
                </el-table-column>

                <el-table-column label="详细" min-width="35%">
                  <template slot-scope="scope">
                    <template v-if="scope.row.event === '出差' || scope.row.event === '加班'">
                      地点：{{ RapidScreenAnalysis_Where(scope.row) }} <br>
                    </template>
                    <template v-if="scope.row.event === '请假' || scope.row.event === '加班'">
                      原因：{{ RapidScreenAnalysis_Why(scope.row) }}
                    </template>
                  </template>
                </el-table-column>
              
              </el-table>
              
            </template>
          </div>
        </el-card>
      </el-col>

      <el-col :span="10">
        <el-card class="Choose_Detailed">
          <div slot="header" class="Choose_Detailed_Header">
            <span>
              修改详细内容
            </span>
          </div>

          <div style="height: 100%;
                      display: flex;
                      flex-direction: column;
                      flex-wrap: nowrap;
                      justify-content: space-between;"
          >
            <div>
              <div v-if="RapidScreen_NotSelected">
                <ul style="line-height: 40px;font-size: 20px;">
                  <h5 style="font-size: 30px;">使用方法</h5>
                  <li>首先选中左边需要更改的条目</li>
                  <li>然后在此处进行修改</li>
                  <li>最后点击下方的确定按钮</li>
                </ul>
              </div>

              <div v-if="!RapidScreen_NotSelected">
                <el-form 
                  label-width="120px" 
                  label-position="right"
                  :inline="true"
                >
                  <el-form-item label=" 事件">
                    <el-select 
                      v-model="RapidScreen_Table_SelectedInfo.event" 
                      style="width: 120px;"
                    >
                      <el-option label="出差" value="出差"></el-option>
                      <el-option label="请假" value="请假"></el-option> 
                      <el-option label="加班" value="加班"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item 
                    v-if="RapidScreen_Table_SelectedInfo.event === '请假'" 
                    label="请假类型"
                  >
                    <el-select 
                      v-model="RapidScreen_Table_SelectedInfo.Occur_Leave.nature" 
                      style="width: 120px;"
                    >
                      <el-option-group
                        v-for="group in LeaveNatureOptions"
                        :key="group.label"
                        :label="group.label">
                        <el-option
                          v-for="item in group.options"
                          :key="item.value"
                          :label="item.value"
                          :value="item.value">
                        </el-option>
                      </el-option-group>          
                    </el-select>
                  </el-form-item>
                </el-form>

                <el-form 
                  label-width="120px" 
                  label-position="right"
                  v-if="RapidScreen_Table_SelectedInfo.event === '出差'"
                >
                  <el-form-item label="出差开始时间">
                    <el-date-picker
                      v-model="RapidScreen_Table_SelectedInfo.LeaveTimeS"
                      type="datetime"
                      value-format="timestamp"
                      placeholder="选择日期时间"
                      default-time="9:00:00"
                      :picker-options="pickerOptions"
                      @change="RapidScreen_CalculateDays"
                    >
                    </el-date-picker>
                  </el-form-item>

                  <el-form-item label="出差结束时间">
                    <el-date-picker
                      v-model="RapidScreen_Table_SelectedInfo.LeaveTimeE"
                      type="datetime"
                      value-format="timestamp"
                      placeholder="选择日期时间"
                      default-time="17:30:00"
                      :picker-options="pickerOptions"
                      @change="RapidScreen_CalculateDays"
                    >
                    </el-date-picker>
                  </el-form-item>

                  <el-form-item label="" class="TimeStatistics">
                    <div>计算出出差时间为{{ RapidScreen_getBusinessTimeInterval_Day }}天，</div>
                    <div>其中有{{ RapidScreen_getBusinessTimeInterval_Holiday }}天节假日</div>
                  </el-form-item>

                  <el-form-item label="出差地点">
                    <el-input
                      list="browsers"
                      type="text"
                      v-model="RapidScreen_Table_SelectedInfo.Occur_Business.Where"
                      class="edit-input"
                      placeholder="支持模糊搜索，找不到可以手输"
                      :trigger-on-focus="false"
                    />
                    <datalist id="browsers">
                      <option v-for="item in customerInfo">
                        {{ item["company"] }}
                      </option>
                    </datalist>
                  </el-form-item>

                  <el-form-item label="绑定项目(可选)">
                    <template>
                      <el-select
                        v-model="RapidScreen_Table_SelectedInfo.Occur_Business.BindProject"
                        filterable
                        placeholder="请选择"
                        style="width: 100%;"
                      >
                        <el-option
                          v-for="item in Projects"
                          :key="item.value"
                          :label="item.label"
                          :value="item.id"
                        >
                          <span style="float: left"> {{ item.label }} </span>
                          <span style="float: right; color: #8492a6; font-size: 13px">
                            {{ item.value }}
                          </span>
                        </el-option>
                      </el-select>
                    </template>
                  </el-form-item>

                  <el-form-item label="是否通报">
                    <el-switch 
                      v-model="RapidScreen_Table_SelectedInfo.ShouldReport"
                    />
                  </el-form-item>
                </el-form>
                
                <el-form 
                  label-width="120px" 
                  label-position="right"
                  v-if="RapidScreen_Table_SelectedInfo.event === '请假'"
                >
                  <el-form-item label="请假开始时间">
                    <el-date-picker
                      v-model="RapidScreen_Table_SelectedInfo.LeaveTimeS"
                      type="datetime"
                      value-format="timestamp"
                      placeholder="选择日期时间"
                      default-time="9:00:00"
                      :picker-options="pickerOptions"
                      @change="RapidScreen_CalculateDays"
                    >
                    </el-date-picker>
                  </el-form-item>

                  <el-form-item label="请假结束时间">
                    <el-date-picker
                      v-model="RapidScreen_Table_SelectedInfo.LeaveTimeE"
                      type="datetime"
                      value-format="timestamp"
                      placeholder="选择日期时间"
                      default-time="17:30:00"
                      :picker-options="pickerOptions"
                      @change="RapidScreen_CalculateDays"
                    >
                    </el-date-picker>
                  </el-form-item>

                  <el-form-item label="" class="TimeStatistics">
                    <div>计算出请假时间为{{ RapidScreen_getLeaveTimeInterval_Hour }}小时，</div>
                  </el-form-item>

                  <el-form-item label="请假原因">
                    <el-input
                      type="text"
                      v-model="RapidScreen_Table_SelectedInfo.Occur_Leave.Why"
                      class="edit-input"
                    />
                  </el-form-item>
                </el-form>
                
                <el-form 
                  label-width="120px" 
                  label-position="right"
                  v-if="RapidScreen_Table_SelectedInfo.event === '加班'"
                >
                  <el-form-item label="加班开始时间">
                    <el-date-picker
                      v-model="RapidScreen_Table_SelectedInfo.LeaveTimeS"
                      type="datetime"
                      value-format="timestamp"
                      placeholder="选择日期时间"
                      default-time="9:00:00"
                      :picker-options="pickerOptions"
                      @change="RapidScreen_CalculateDays"
                    >
                    </el-date-picker>
                  </el-form-item>

                  <el-form-item label="加班结束时间">
                    <el-date-picker
                      v-model="RapidScreen_Table_SelectedInfo.LeaveTimeE"
                      type="datetime"
                      value-format="timestamp"
                      placeholder="选择日期时间"
                      default-time="17:30:00"
                      :picker-options="pickerOptions"
                      @change="RapidScreen_CalculateDays"
                    >
                    </el-date-picker>
                  </el-form-item>

                  <el-form-item label="" class="TimeStatistics">
                    <div>{{ RapidScreen_getOvertimeTimeInterval }}</div>
                  </el-form-item>

                  <el-form-item label="加班地点">
                    <el-input
                      type="text"
                      v-model="RapidScreen_Table_SelectedInfo.Occur_Overtime.Where"
                      class="edit-input"
                    />
                  </el-form-item>

                  <el-form-item label="加班原因">
                    <el-input
                      type="text"
                      v-model="RapidScreen_Table_SelectedInfo.Occur_Overtime.Why"
                      class="edit-input"
                    />
                  </el-form-item>
                </el-form>
              </div>
            </div>

            <div v-if="!RapidScreen_NotSelected">
              <div style="display: flex;justify-content: flex-end;">
                <el-button @click="RapidScreen_CancelReport">取 消</el-button>
                <el-button type="primary" @click="RapidScreen_ConfirmReport">确 定</el-button>
                <el-popconfirm title="确定撤回吗？" @confirm="RapidScreen_DeleteReport">
                  <el-button type="warning" slot="reference" style="margin-left: 10px;">撤 回</el-button>
                </el-popconfirm>
              </div>
            </div>

          </div>


        </el-card>
      </el-col>

    </el-dialog>

  </div>
</template>

<script>
import { getName, getToken } from "@/utils/auth";
import { getUserList } from "@/api/user";
import { getProjectlist, getTasklist } from "@/api/technology";
import { getCustomerList } from "@/api/article";
import { 
  CreatTemporaryOccur,
  UpdateTemporaryOccur,
  GetTemporaryOccur,

  ChangeEvent,
  DeleteEvent,

  CreateCalendarScheme,
  UpdateCalendarScheme,
  GetCalendarScheme,
} from "@/api/reporting";
import { getInfobyname } from "@/api/user";
import { update } from '@/api/faultclass';

const ww = require("chinese-workday");
const isHoliday = ww.isHoliday;

export default {
  name: "Dashboard",
  data() {
    return {
      BusinessTripReport: false,
      LeaveReport: false,
      OvertimeReport: false,
      ChangeDialog: false,

      name: getName(),
      userinfo: {},
      Userlist: [],
      // task: {},

      MainCalendar: new Date(),
      recentProjects: [],

      listLoading: true,
      listLoading_RapidScreenTable: true,

      list: [],                                 //排序过后快捷跳转的项目列表
      Projects: [{                              //出差报备窗口，绑定项目部分，选项
        value: null,
        label: null,
        id: null,
      }],

      RecentList: [],
      listQuery: {
        page: 0,
        limit: 0,
        sort: null,
        filters: {},
      },
      EventListQuery_filters: {filters: {}},
      CalendarListQuery_filters: {filters: {}},
      EventListQuery_Change_filters: {filters: {}},


      RapidScreen_AdministratorPrivileges: false,   //管理员权限开关，用以管理员修改其他人条目
      RapidScreen_Name : getName(),
      RapidScreen_Event : "全部",
      RapidScreen_Month : new Date(),
      RapidScreen_Revise : "全部",
      RapidScreen_Table: [],                        //用于修改时筛选出的个人事件表格
      RapidScreen_Table_filters: {filters: {}},     //筛选条件

      RapidScreen_NotSelected: true,                //修改条目第一次选中的指示器
      RapidScreen_Table_SelectedInfo: {},         //修改条目选中信息


      customerInfo: [{
        company: null,
        fax: null,
        add: null,
        WarrantyDays: null,
        contacts:[{
          Name: null,
          Tel: null,
          SeparateAddress: null,
        }]
      }],
      
      datavalue: null,
      pickerOptions: {
        format: 'HH:mm'
      },

      LeaveNatureOptions:[
        {
          label: '常用',
          options:[
                    {value: '事假'},
                    {value: '年假'},
                    {value: '病假'}
                  ]
        },
        {
          label: '其他',
          options:[
                    {value: '临时假'},
                    {value: '婚假'},
                    {value: '丧假'},
                    {value: '产假'},
                    {value: '哺乳假'},
                    {value: '陪产假'},
                    {value: '育儿假'},
                  ]
        },
      ],

      CalendarScheme: {                   //日期结构
        _id: null,                          //事件id
        Date: null,                         //日期
        Isholiday: false,                   //是否是节假日
        Occur_Leave: [{                      //当天请假数据
          Personnel: null,                  //人员
          nature: null,                     //性质，事假病假年假
          Why: null,                        //原因
          isAllDay: false,                  //是否是全天
          LeaveTimeS: null,                 //如果不是全天，则设置开始时间
          LeaveTimeE: null,                 //如果不是全天，则设置结束时间
          Report: {
            isReport: false,                //是否需要上报
            BossOpinions: null,             //顾总意见
            ManagerOpinions: null,          //项目经理意见
            Passed: false,                  //是否同意
          },
        }],
        Occur_Business: [{                  //当天出差数据
          Personnel: null,                  //人员
          Where: null,                      //去哪
          BindProject: null,                //绑定项目
          isAllDay: false,                  //是否是全天
          LeaveTimeS: null,                 //如果不是全天，则设置开始时间
          LeaveTimeE: null,                 //如果不是全天，则设置结束时间
          Report: {
            isReport: false,                //是否需要上报
            BossOpinions: null,             //顾总意见
            ManagerOpinions: null,          //项目经理意见
            Passed: false,                  //是否同意
          },
        }],
        Occur_Overtime: [{                   //当天加班数据
          Personnel: null,                  //人员
          Where: null,                      //哪里加班
          Why: null,                        //原因
          LeaveTimeS: null,                 //则设置开始时间
          LeaveTimeE: null,                 //则设置结束时间
          Report: {
            isReport: false,                //是否需要上报
            BossOpinions: null,            //顾总意见
            ManagerOpinions: null,         //项目经理意见
            Passed: false,                  //是否同意
          },
        }],
      },

      Temporary_Occur: {                  //事件结构
        _id: null,                 //事件id
        Personnel: null,           //人员
        event: null,               //性质（出差或请假）
        Occur_Leave: {             //请假
          nature: null,             //性质，事假病假年假
          Why: null,                //原因
          TimeInterval: null,       //时间间隔
        },
        Occur_Business: {          //出差
          Where: null,              //去哪
          BindProject: null,        //绑定项目
          TimeInterval: null,       //时间间隔
        },
        Occur_Overtime:{           //加班
          Where: null,              //哪里加班
          Why: null,                //原因
          TimeInterval: null,       //时间间隔
        },
        CreatTime: null,           //创建时间
        LeaveTimeS: null,          //开始时间
        LeaveTimeE: null,          //结束时间
        ShouldReport: false,       //是否上报
        CanModify : true,          //是否可修改
        Report: {
          BossOpinions: null,     //顾总意见
          ManagerOpinions: null,  //项目经理意见
          Passed: false,           //是否同意
        },
      },

      Personal_Event_Collection: [],      //个人事件集合
      Personal_Calendar_Collection: [],   //个人日期集合
    };
  },
  async created() {
    let res = await getInfobyname(this.name);
      if (res.data.list[0].token === "supplier") {
        this.$router.push("/product/ProductData")
      };
    this.RapidScreen_NotSelected = true;
    this.getuserinfo();
    this.getUser();
    this.GetRecentProjects();
    this.getCustomerInfo();
    this.SetListQuery();
  },

  watch: {},

  computed: {
    depatment() {
     // console.log(this.userinfo)
      if (this.userinfo == null) return;
      if (this.userinfo.token == "Admin-token")       return "管理员";
      if (this.userinfo.token == "repair")            return "维修部";
      if (this.userinfo.token == "technology")        return "技术部";
      if (this.userinfo.token == "logistics")         return "物流部";
      if (this.userinfo.token == "Administrative")    return "行政部";

      if (this.userinfo.token == "supplier")          return "供应商";
    },

    getCurrentTimestamp() {   //获取当前时间的时间戳
      return new Date().getTime();
    },

    //计算出出差/请假/加班时间段---------------------------------------
    getBusinessTimeInterval_Day() {
      if(this.Temporary_Occur.Occur_Business.TimeInterval) {
        const regex = /(\d+(\.\d+)?)[dh]_(\d+(\.\d+)?)/;
        const match = this.Temporary_Occur.Occur_Business.TimeInterval.match(regex);
        if (match) {
          const firstNumber = match[1];
          return firstNumber ;
        } else {
          return 0;
        }
      }
    },

    getBusinessTimeInterval_Holiday() {
      if(this.Temporary_Occur.Occur_Business.TimeInterval) {
        const regex = /(\d+(\.\d+)?)[dh]_(\d+(\.\d+)?)/;
        const match = this.Temporary_Occur.Occur_Business.TimeInterval.match(regex);
        if (match) {
          const firstNumber = match[3];
          return firstNumber ;
        } else {
          return 0;
        }
      }
    },

    getLeaveTimeInterval_Hour() {
      if(this.Temporary_Occur.Occur_Leave.TimeInterval) {
        const regex = /(\d+(\.\d+)?)/;
        const match = this.Temporary_Occur.Occur_Leave.TimeInterval.match(regex);
        if (match) {
          return match[0];
        }
      }
    },

    getOvertimeTimeInterval() {
      if(this.Temporary_Occur.Occur_Overtime.TimeInterval === 'error') {
        return "计算到多个日期加班之间存在非节假日，创建无效";
      }
      if(this.Temporary_Occur.Occur_Overtime.TimeInterval) {
        const regex = /(\d+(\.\d+)?)/;
        const match = this.Temporary_Occur.Occur_Overtime.TimeInterval.match(regex);
        if (match) {
          const number = match[0];
          const suffix = this.Temporary_Occur.Occur_Overtime.TimeInterval.slice(-1); // 提取字符串的最后一个字符作为后缀
          if (suffix === 'h') {
            return "计算出加班时间为 " + number + " 小时";
          } else if (suffix === 'd') {
            return "计算出加班时间为 " + number + " 天";
          } else {
            return "计算错误";
          }
        } else {
          return "计算错误";
        }
      }
    },


    //计算出出差/请假/加班时间段，修改/撤回专用--------------------------
    RapidScreen_getBusinessTimeInterval_Day() {
      if(this.RapidScreen_Table_SelectedInfo.Occur_Business.TimeInterval) {
        const regex = /(\d+(\.\d+)?)[dh]_(\d+(\.\d+)?)/;
        const match = this.RapidScreen_Table_SelectedInfo.Occur_Business.TimeInterval.match(regex);
        if (match) {
          const firstNumber = match[1];
          return firstNumber ;
        } else {
          return 0;
        }
      }
    },

    RapidScreen_getBusinessTimeInterval_Holiday() {
      if(this.RapidScreen_Table_SelectedInfo.Occur_Business.TimeInterval) {
        const regex = /(\d+(\.\d+)?)[dh]_(\d+(\.\d+)?)/;
        const match = this.RapidScreen_Table_SelectedInfo.Occur_Business.TimeInterval.match(regex);
        if (match) {
          const firstNumber = match[3];
          return firstNumber ;
        } else {
          return 0;
        }
      }
    },

    RapidScreen_getLeaveTimeInterval_Hour() {
      if(this.RapidScreen_Table_SelectedInfo.Occur_Leave.TimeInterval) {
        const regex = /(\d+(\.\d+)?)/;
        const match = this.RapidScreen_Table_SelectedInfo.Occur_Leave.TimeInterval.match(regex);
        if (match) {
          return match[0];
        }
      }
    },

    RapidScreen_getOvertimeTimeInterval() {
      if(this.RapidScreen_Table_SelectedInfo.Occur_Overtime.TimeInterval === 'error') {
        return "计算到多个日期加班之间存在非节假日，创建无效";
      }
      if(this.RapidScreen_Table_SelectedInfo.Occur_Overtime.TimeInterval) {
        const regex = /(\d+(\.\d+)?)/;
        const match = this.RapidScreen_Table_SelectedInfo.Occur_Overtime.TimeInterval.match(regex);
        if (match) {
          const number = match[0];
          const suffix = this.RapidScreen_Table_SelectedInfo.Occur_Overtime.TimeInterval.slice(-1); // 提取字符串的最后一个字符作为后缀
          if (suffix === 'h') {
            return "计算出加班时间为 " + number + " 小时";
          } else if (suffix === 'd') {
            return "计算出加班时间为 " + number + " 天";
          } else {
            return "计算错误";
          }
        } else {
          return "计算错误";
        }
      }
    },
  },

  methods: {
    async getuserinfo() {
      this.userinfo.token = getToken();
      //console.log(this.userinfo);
      // this.userinfo.manager = "张"
    },
    async getUser() {
      const { data } = await getUserList();
      this.Userlist = data.list;
      //console.log(this.Userlist);
      const username = this.name;
      this.userinfo = this.findUserByUsername(this.Userlist, username)
      //console.log(this.userinfo)
    },
    async getCustomerInfo() {
      const { data } = await getCustomerList();
      this.customerInfo = data.list;
    },
    findUserByUsername(userList, username) {
      return userList.find(user => user.username === username);
    },

    SetListQuery() {                                      //通知信息里筛选出事件卡片,日历中筛选出事件
      this.EventListQuery_filters.filters  = "({$and:["
      this.EventListQuery_filters.filters += "{'Personnel':'" + this.name + "'},";
      let NowTime = this.getCurrentTimestamp;
      let ThreeDaysAgo = NowTime - 3 * 24 * 60 * 60 * 1000;
      this.EventListQuery_filters.filters += "{'LeaveTimeE':{$gte:" + ThreeDaysAgo + "}}";
      this.EventListQuery_filters.filters += "]})";
      // console.log(this.EventListQuery_filters.filters);

      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0); // 将时、分、秒、毫秒部分设置为零
      const currentYear = currentDate.getFullYear();
      const lastYearDate = new Date(currentYear - 1, currentDate.getMonth(), currentDate.getDate());
      const nextYearDate = new Date(currentYear + 1, currentDate.getMonth(), currentDate.getDate());
      const lastYearTimestamp = lastYearDate.getTime();
      const nextYearTimestamp = nextYearDate.getTime();

      this.CalendarListQuery_filters.filters  = "({$and:["
        this.CalendarListQuery_filters.filters += "{$or:[";
        this.CalendarListQuery_filters.filters += "{'Occur_Leave.Personnel':'" + this.name + "'},";
        this.CalendarListQuery_filters.filters += "{'Occur_Business.Personnel':'" + this.name + "'},";
        this.CalendarListQuery_filters.filters += "{'Occur_Overtime.Personnel':'" + this.name + "'}";
        this.CalendarListQuery_filters.filters += "]}";
      this.CalendarListQuery_filters.filters += ",{'Date':{$gte:" + lastYearTimestamp + "}}";
      this.CalendarListQuery_filters.filters += ",{'Date':{$lt:"  + nextYearTimestamp + "}}";
      this.CalendarListQuery_filters.filters += "]})";
      //console.log(this.CalendarListQuery_filters.filters);
      this.fetchData();
    },

    ToHHMM(timestamp) {                                   //时间戳转时分
      const date = new Date(timestamp);
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      // return `${hours}:${minutes}:${seconds}`;
      return `${hours}:${minutes}`;
    },
    ToYYYYMMDD(timestamp) {                               //时间戳转年月日
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    ToYYMMDD(timestamp) {                                 //时间戳转年月日2
      const date = new Date(timestamp);
      const year = date.getFullYear().toString().slice(-2);
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}/${month}/${day}`;
    },

    Cuttimestamp(timestamp) {                             //截取时间戳,取年月日部分时间戳
      const date = new Date(timestamp); // 创建一个 Date 对象
      date.setHours(0, 0, 0, 0); // 将时间部分设置为零
      const newTimestamp = date.getTime(); // 获取更新后的时间戳
      return newTimestamp;
    },           
    DayofWorkingTime(timestamp) {                         //获得当天上班时间的时间戳
      const date = new Date(timestamp);
      date.setHours(9);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      const timestampAtWorkingTime = date.getTime();
      return timestampAtWorkingTime;
    },
    DayofClosingTime(timestamp) {                         //获得当天下班时间的时间戳
      const date = new Date(timestamp);
      date.setHours(17);
      date.setMinutes(30);
      date.setSeconds(0);
      date.setMilliseconds(0);
      const timestampAtClosingTime = date.getTime();
      return timestampAtClosingTime;
    },
    DayofDayStart(timestamp) {                            //获得当天零点时间的时间戳
      const date = new Date(timestamp);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      const timestampAtClosingTime = date.getTime();
      return timestampAtClosingTime;
    },
    DayofDayMiddle(timestamp) {                           //获得当天中午时间的时间戳
      const date = new Date(timestamp);
      date.setHours(12);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      const timestampAtClosingTime = date.getTime();
      return timestampAtClosingTime;
    },
    DayofDayEnd(timestamp) {                              //获得当天24点时间的时间戳
      const date = new Date(timestamp);
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);
      date.setMilliseconds(0);
      const timestampAtClosingTime = date.getTime();
      return timestampAtClosingTime;
    },

    roundDown(hours) {                                    //小时向下取整，包含.5档位
      const integerPart = Math.floor(hours);
      const decimalPart = hours - integerPart;
      if (decimalPart >= 0.5) {
        return integerPart + 0.5;
      } else {
        return integerPart;
      }
    },
    
    getStatus(One_Event_Number) {                         //当前时间与报备时间对比与显示
      const NowTime = this.getCurrentTimestamp;
      if (NowTime < this.Personal_Event_Collection[One_Event_Number].LeaveTimeS) {
        return '计划中';
      } else if (NowTime < this.Personal_Event_Collection[One_Event_Number].LeaveTimeE) {
        return '正在进行';
      } else if (NowTime < this.Personal_Event_Collection[One_Event_Number].LeaveTimeE + 3 * 24 * 60 * 60 * 1000) {
        return '已结束';
      }
    },
    TimeResolved(One_Event) {
      if (One_Event.event === '加班') {
        const regex = /(\d+(\.\d+)?)/;
        const match = One_Event.Occur_Overtime.TimeInterval.match(regex);
        if (match) {
          const number = match[0];
          const suffix = One_Event.Occur_Overtime.TimeInterval.slice(-1); // 提取字符串的最后一个字符作为后缀
          if (suffix === 'h') {
            return number + " 小时";
          } else if (suffix === 'd') {
            return number + " 天";
          }
        }
      } else if (One_Event.event === '出差') {
        const regex = /(\d+(\.\d+)?)[dh]_(\d+(\.\d+)?)/;
        const match = One_Event.Occur_Business.TimeInterval.match(regex);
        if (match) {
          const number1 = match[1];
          const number2 = match[3];
          return number1 + " 天（含" + number2 + "天节假日）";
        }
      } else if (One_Event.event === '请假') {
        const regex = /(\d+(\.\d+)?)/;
        const match = One_Event.Occur_Leave.TimeInterval.match(regex);
        if (match) {
          const number = match[0];
          return number + " 小时";
        }
      }
    },

    async fetchData() {                                   //刷新显示
      this.listLoading = true;
      this.Personal_Event_Collection = [];
      this.Personal_Calendar_Collection = [];

      const { EventData } = await GetTemporaryOccur(this.EventListQuery_filters);
      for (var i = 0; i < EventData.length; i++) {

        let EventObj = {
          _id: null,                 //事件id
          Personnel: null,           //人员
          event: null,               //性质（出差或请假）
          Occur_Leave: {             //请假
            nature: null,             //性质，事假病假年假
            Why: null,                //原因
            TimeInterval: null,       //时间间隔
          },
          Occur_Business: {          //出差
            Where: null,              //去哪
            BindProject: null,        //绑定项目
            TimeInterval: null,       //时间间隔
          },
          Occur_Overtime:{           //加班
            Where: null,              //哪里加班
            Why: null,                //原因
            TimeInterval: null,       //时间间隔
          },
          CreatTime: null,           //创建时间
          LeaveTimeS: null,          //开始时间
          LeaveTimeE: null,          //结束时间
          ShouldReport: false,       //是否上报
          CanModify : true,          //是否可修改
          Report: {
            BossOpinions: null,      //顾总意见
            ManagerOpinions: null,   //项目经理意见
            Passed: false,           //是否同意
          },
        };

        this.Personal_Event_Collection.push(EventObj);
        Object.assign(this.Personal_Event_Collection[i], EventData[i]);
      };
     // console.log(this.Personal_Event_Collection);

      const { CalendarData } = await GetCalendarScheme(this.CalendarListQuery_filters);
     // console.log(CalendarData);
      for (var i = 0; i < CalendarData.length; i++) {

        let CalendarObj = {                 //日期结构
          _id: null,                          //事件id
          Date: null,                         //日期
          Isholiday: false,                   //是否是节假日
          Occur_Business: [
            // {                  //当天出差数据
            //   Personnel: null,                  //人员
            //   Where: null,                      //去哪
            //   BindProject: null,                //绑定项目
            //   isAllDay: false,                  //是否是全天
            //   LeaveTimeS: null,                 //如果不是全天，则设置开始时间
            //   LeaveTimeE: null,                 //如果不是全天，则设置结束时间
            //   Report: {
            //     isReport: false,                //是否需要上报
            //     BossOpinions: null,            //顾总意见
            //     ManagerOpinions: null,         //项目经理意见
            //     Passed: false,                  //是否同意
            //   },
            // }
          ],
          Occur_Leave: [
            // {                      //当天请假数据
            //   Personnel: null,                  //人员
            //   nature: null,                     //性质，事假病假年假
            //   Why: null,                        //原因
            //   isAllDay: false,                  //是否是全天
            //   LeaveTimeS: null,                 //如果不是全天，则设置开始时间
            //   LeaveTimeE: null,                 //如果不是全天，则设置结束时间
            //   Report: {
            //     isReport: false,                //是否需要上报
            //     BossOpinions: null,            //顾总意见
            //     ManagerOpinions: null,         //项目经理意见
            //     Passed: false,                  //是否同意
            //   },
            // }
          ],
          Occur_Overtime: [
            // {                   //当天加班数据
            //   Personnel: null,                  //人员
            //   Where: null,                      //哪里加班
            //   Why: null,                        //原因
            //   LeaveTimeS: null,                 //则设置开始时间
            //   LeaveTimeE: null,                 //则设置结束时间
            //   Report: {
            //     isReport: false,                //是否需要上报
            //     BossOpinions: null,            //顾总意见
            //     ManagerOpinions: null,         //项目经理意见
            //     Passed: false,                  //是否同意
            //   },
            // }
          ],
        };

        for (var j = 0; j < CalendarData[i].Occur_Business.length; j++) {
          if (CalendarData[i].Occur_Business[j].Personnel === this.name) {
            CalendarObj.Occur_Business.push(CalendarData[i].Occur_Business[j]);
          }
        }

        for (var j = 0; j < CalendarData[i].Occur_Leave.length; j++) {
          if (CalendarData[i].Occur_Leave[j].Personnel === this.name) {
            CalendarObj.Occur_Leave.push(CalendarData[i].Occur_Leave[j]);
          }
        }

        for (var j = 0; j < CalendarData[i].Occur_Overtime.length; j++) {
          if (CalendarData[i].Occur_Overtime[j].Personnel === this.name) {
            CalendarObj.Occur_Overtime.push(CalendarData[i].Occur_Overtime[j]);
          }
        }

        this.Personal_Calendar_Collection.push(CalendarObj);
        let newCalendarObj = Object.assign({}, CalendarData[i]);
        delete newCalendarObj.Occur_Business;
        delete newCalendarObj.Occur_Leave;
        delete newCalendarObj.Occur_Overtime;

        Object.assign(this.Personal_Calendar_Collection[i], newCalendarObj, CalendarObj);
      };
      // console.log(this.Personal_Calendar_Collection);

      setTimeout(() => {
        this.listLoading = false;
      }, 0.5 * 1000);
    },

    isDateHoliday(date) {                                 // 调用isHoliday库函数并返回结果
      return isHoliday(date);                             
    },
    calculateDays(event) {
        const startTimestamp = this.datavalue[0];
        const endTimestamp = this.datavalue[1];

        const startDate = new Date(startTimestamp);
        const endDate = new Date(endTimestamp);

        let startTimeWorkingTime = this.DayofWorkingTime(startTimestamp);
        let startTimeClosingTime = this.DayofClosingTime(startTimestamp);
        let startTimeDayStart  = this.DayofDayStart(startTimestamp);
        let startTimeDayMiddle = this.DayofDayMiddle(startTimestamp);
        let startTimeDayEnd    = this.DayofDayEnd(startTimestamp);

        let endTimeWorkingTime = this.DayofWorkingTime(endTimestamp);
        let endTimeClosingTime = this.DayofClosingTime(endTimestamp);
        let endTimeDayStart  = this.DayofDayStart(endTimestamp);
        let endTimeDayMiddle = this.DayofDayMiddle(endTimestamp);
        let endTimeDayEnd    = this.DayofDayEnd(endTimestamp);


        if (event === '出差') {                             //请假按天计算,计算逻辑
          let Day = 0;
          let Holiday = 0;
          let days = Math.floor((endTimestamp - startTimestamp) / (24 * 60 * 60 * 1000));
          if (days < 1) {
            if (startDate.getHours() > endDate.getHours()) {
              Day += 1;
              if (this.isDateHoliday(this.ToYYYYMMDD(startTimestamp))) {
                Holiday += 0.5;
              }
              if (this.isDateHoliday(this.ToYYYYMMDD(endTimestamp))) {
                Holiday += 0.5;
              }
            } else {
              if (((startTimestamp <= startTimeDayMiddle)  && (endTimestamp >= endTimeClosingTime)) || 
                  ((startTimestamp <= startTimeWorkingTime)  && (endTimestamp >= endTimeDayMiddle)) || 
                  ((endTimestamp - startTimestamp) >= (5.5 * 60 * 60 * 1000)) ) {
                Day += 1;
                if (this.isDateHoliday(this.ToYYYYMMDD(startTimestamp))) {
                  Holiday += 1;
                }
              } else {
                Day += 0.5;
                if (this.isDateHoliday(this.ToYYYYMMDD(startTimestamp))) {
                  Holiday += 0.5;
                }
              }
            }
          } else {
            if (startDate.getHours() < 12) { 
              Day += 1; 
              if (this.isDateHoliday(this.ToYYYYMMDD(startTimestamp))) {
                Holiday += 1;
              }
            } else { 
              Day += 0.5; 
              if (this.isDateHoliday(this.ToYYYYMMDD(startTimestamp))) {
                Holiday += 0.5;
              }
            }

            if (endDate.getHours() > 12) { 
              Day += 1; 
              if (this.isDateHoliday(this.ToYYYYMMDD(endTimestamp))) {
                Holiday += 1;
              }
            } else { 
              Day += 0.5; 
              if (this.isDateHoliday(this.ToYYYYMMDD(endTimestamp))) {
                Holiday += 0.5;
              }
            }

            if (startDate.getHours() > endDate.getHours()) {
              Day += days;
            } else {
              Day += (days - 1);
            }
            const dates = []; // 存储日期的数组

            // 循环遍历每一天，将日期以 'yyyy-mm-dd' 格式添加到数组中
            for (let date = this.Cuttimestamp(startTimestamp); date <= this.Cuttimestamp(endTimestamp); date += 86400000) {
              const formattedDate = this.ToYYYYMMDD(date); // 将日期转换为 'yyyy-mm-dd' 格式
              dates.push(formattedDate); // 将日期添加到数组中
            }
            dates.shift(); // 移除起始日期
            dates.pop(); // 移除结束日期
            for (const date of dates) {
              if (this.isDateHoliday(date)) {
                Holiday += 1; // 如果日期是节假日，增加节假日计数
              }
            }
          }
          this.Temporary_Occur.Occur_Business.TimeInterval = Day + 'd_';
          this.Temporary_Occur.Occur_Business.TimeInterval += Holiday + 'd';
          
        } else if (event === '请假') {                      //请假按照小时计算逻辑
          let Hours = 0;
          let days = Math.floor((endTimestamp - startTimestamp) / (24 * 60 * 60 * 1000));
          let startTimeWorkingTime = this.DayofWorkingTime(startTimestamp);
          let startTimeClosingTime = this.DayofClosingTime(startTimestamp);
          let endTimeWorkingTime = this.DayofWorkingTime(endTimestamp);
          let endTimeClosingTime = this.DayofClosingTime(endTimestamp);
          if (days < 1) {
            if (startDate.getHours() > endDate.getHours()) {
              if (startTimeClosingTime > startTimestamp) {
                Hours += this.roundDown((startTimeClosingTime - startTimestamp)/(60 * 60 * 1000));
              }
              if (endTimestamp > endTimeWorkingTime) {
                Hours += this.roundDown((endTimestamp - endTimeWorkingTime)/(60 * 60 * 1000));
              }
            } else {
              if ((endTimestamp > endTimeWorkingTime) && 
                  (endTimestamp < endTimeClosingTime) && 
                  (startTimestamp > startTimeWorkingTime) && 
                  (startTimestamp < startTimeClosingTime)) {
                Hours += this.roundDown((endTimestamp - startTimestamp)/(60 * 60 * 1000));
              }else if ((endTimestamp >= endTimeClosingTime) && 
                        (startTimestamp <= startTimeWorkingTime)) {
                Hours += 8;
              }else if ((startTimestamp >= startTimeClosingTime) ||
                        (endTimestamp <=  endTimeWorkingTime)) {
                Hours += 0;
              }else if (endTimestamp >= endTimeClosingTime) {
                Hours += this.roundDown((endTimeClosingTime - startTimestamp)/(60 * 60 * 1000));
              }else if (startTimestamp <= startTimeWorkingTime) {
                Hours += this.roundDown((endTimestamp - startTimeWorkingTime)/(60 * 60 * 1000));
              }
            }
          } else {
            if ((startTimeClosingTime > startTimestamp) &&
                (startTimeWorkingTime < startTimestamp)) {
              Hours += this.roundDown((startTimeClosingTime - startTimestamp)/(60 * 60 * 1000));
            }else if ((startTimeClosingTime <= startTimestamp)) {
              Hours += 0;
            }else if ((startTimeWorkingTime >= startTimestamp)) {
              Hours += 8;
            }

            if ((endTimeClosingTime > endTimestamp) &&
                (endTimeWorkingTime < endTimestamp)) {
              Hours += this.roundDown((endTimestamp - endTimeWorkingTime)/(60 * 60 * 1000));
            }else if ((endTimeClosingTime <= endTimestamp)) {
              Hours += 8;
            }else if ((endTimeWorkingTime >= endTimestamp)) {
              Hours += 0;
            }

            if (startDate.getHours() > endDate.getHours()) {
              Hours += days * 8;
            } else {
              Hours += (days - 1) * 8;
            }
          }
          this.Temporary_Occur.Occur_Leave.TimeInterval = Hours + 'h';
          
        } else if (event === '加班') {

          let Hours = 0;
          let Day = 0;
          let days = Math.floor((endTimestamp - startTimestamp) / (24 * 60 * 60 * 1000));

          //工作日加班
          if ((days < 1) &&
              (startTimeDayStart == endTimeDayStart) &&
              (this.isDateHoliday(this.ToYYYYMMDD(startTimestamp)) === false)) {
            if ((endTimestamp <= endTimeWorkingTime)||(startTimestamp >= startTimeClosingTime)) {
              Hours += this.roundDown((endTimestamp - startTimestamp)/(60 * 60 * 1000));
            } else if ((startTimestamp <= startTimeWorkingTime)&&(endTimestamp >= endTimeClosingTime)) {
              Hours += this.roundDown((endTimeWorkingTime - startTimestamp)/(60 * 60 * 1000));
              Hours += this.roundDown((endTimestamp - endTimeClosingTime)/(60 * 60 * 1000));
            } else if ((endTimestamp >= endTimeWorkingTime)&&(startTimestamp <= endTimeWorkingTime)) {
              Hours += this.roundDown((endTimeWorkingTime - startTimestamp)/(60 * 60 * 1000));
            } else if ((startTimestamp <= endTimeClosingTime)&&(endTimestamp >= endTimeClosingTime)) {
              Hours += this.roundDown((endTimestamp - endTimeClosingTime)/(60 * 60 * 1000));
            } else {
              Hours += 0;
            }
          }   //当日加班

          if ((days < 1) &&
              (endTimeDayStart > startTimeDayStart) &&
              (this.isDateHoliday(this.ToYYYYMMDD(startTimestamp)) === false) &&
              (this.isDateHoliday(this.ToYYYYMMDD(endTimestamp)) === false)) {
            Hours += this.roundDown((endTimestamp - startTimestamp)/(60 * 60 * 1000));
          }   //加班了一个通宵，前后两天都是工作日

          //节假日加班
          if ((startTimeDayStart == endTimeDayStart) &&
              (this.isDateHoliday(this.ToYYYYMMDD(startTimestamp)) === true)) {
            if ((endTimestamp - startTimestamp) < (8 * 60 * 60 * 1000)) {
              Day += 0.5;
            } else {
              Day += 1;
            }
          }   //一天节假日加班

          if (((endTimeDayStart - startTimeDayStart)/(24 * 60 * 60 * 1000) >=1) &&
              (this.isDateHoliday(this.ToYYYYMMDD(startTimestamp)) === true) &&
              (this.isDateHoliday(this.ToYYYYMMDD(endTimestamp)) === true)) {
            const dates = []; // 存储日期的数组
            // 循环遍历每一天，将日期以 'yyyy-mm-dd' 格式添加到数组中
            for (let date = this.Cuttimestamp(startTimestamp); date <= this.Cuttimestamp(endTimestamp); date += 86400000) {
              const formattedDate = this.ToYYYYMMDD(date); // 将日期转换为 'yyyy-mm-dd' 格式
              dates.push(formattedDate); // 将日期添加到数组中
            }
            dates.shift(); // 移除起始日期
            dates.pop();   // 移除结束日期
            for (const date of dates) {
              if (!this.Temporary_Occur.Occur_Overtime.TimeIntervalthis.isDateHoliday(date)) {
                this.Temporary_Occur.Occur_Overtime.TimeInterval = "error"
              }
            }
            if (this.Temporary_Occur.Occur_Overtime.TimeInterval != "error") {
              if (startTimestamp > startTimeDayMiddle) {
                Day += 0.5;
              } else {
                Day += 1;
              }
              if (endTimestamp < endTimeDayMiddle) {
                Day += 0.5;
              } else {
                Day += 1;
              }
              for (const date of dates) {
                Day += 1;
              }
            }
          }   //多日连续节假日加班，中间不能含有工作日

          if (Hours != 0) {
            this.Temporary_Occur.Occur_Overtime.TimeInterval = Hours + 'h';
          }
          if (Day != 0) {
            this.Temporary_Occur.Occur_Overtime.TimeInterval = Day + 'd';
          }
        }
    },

    async GetRecentProjects() {                          //获取自身创建项目并按未完成数量排序
      var userName = await getName();
      this.listQuery.filters = "({$or:[";
      this.listQuery.filters += "{";
      this.listQuery.filters += "'creater':'" + userName + "'},";
      this.listQuery.filters = this.listQuery.filters + "{'member':{$in:[";
      this.listQuery.filters = this.listQuery.filters + "'" + userName + "'";
      this.listQuery.filters = this.listQuery.filters + "]}}";
      this.listQuery.filters += "]})";
      const { data } = await getProjectlist(this.listQuery);
      this.list = data.list;
      this.BindSelection();
     // console.log(this.list)
      this.list.sort((a, b) => b.remaining - a.remaining);
      this.RecentList = this.list.slice(0, 3);
     // console.log(this.RecentList);
    },

    BindSelection() {                                    //分配项目，选项搭建
      this.list.forEach((item) => {
        const newProject = {};
        newProject.value = item.ProjectCode;
        newProject.label = item.name;
        newProject.id = item._id;
        this.Projects.push(newProject);
      });    
    },

    // GotoProject(row) {                                //快捷跳转，动作部分
    //   this.getTask(row._id).then(() => {
    //     this.$router.push({
    //       path: "technologyPage/Project",
    //       query: { projectID: this.task.projectID, actTask: this.task },
    //     });
    //   });
    // },

    // async getTask(id) {
    //   const { data } = await getTasklist({
    //     filters: "({projectID :'" + id + "' })",
    //   });
    //   this.task = data.list[0];
    // },

    CreatBusinessReport() {                              //创建出差，打开窗口时自填写部分
      this.BusinessTripReport = true;
      this.Temporary_Occur.Personnel = this.name;
      this.Temporary_Occur.event = "出差"
    },
    CreatLeaveReport() {                                 //创建请假，打开窗口时自填写部分
      this.LeaveReport = true;
      this.Temporary_Occur.Personnel = this.name;
      this.Temporary_Occur.event = "请假"
    },
    CreatOvertimeReport() {                              //创建加班，打开窗口时自填写部分
      this.OvertimeReport = true;
      this.Temporary_Occur.Personnel = this.name;
      this.Temporary_Occur.event = "加班"
    },

    ConfirmReport_Business() {                           //创建出差条目，判断出差输入是否正确，并且保存填写信息
      //console.log(this.Temporary_Occur)
      if (this.datavalue === null) {
        this.$message.error('时间格式错误，数据无效')
      } else if (this.Temporary_Occur.Occur_Business.Where == null || 
                 this.Temporary_Occur.Occur_Business.Where == undefined || 
                 this.Temporary_Occur.Occur_Business.Where == "") {
        this.$message.error('出差地点错误，数据无效')
      } else {
        if (this.Temporary_Occur.ShouldReport === true) {
          this.Temporary_Occur.Report.BossOpinions = '未读';
          this.Temporary_Occur.Report.ManagerOpinions = '未读';
          this.Temporary_Occur.Report.Passed = false;
        }
        this.Temporary_Occur.CreatTime = this.getCurrentTimestamp;
        this.Temporary_Occur.LeaveTimeS = this.datavalue[0];
        this.Temporary_Occur.LeaveTimeE = this.datavalue[1];
        this.Temporary_Occur.CanModify = true;
        this.CreatTemporaryOccur(this.Temporary_Occur).then(() => {
          this.UpdateTemporaryOccur(this.Temporary_Occur).then(() => {
            this.CreateCalendarScheme(this.Temporary_Occur).then(() => {
              this.CancelReport();
              this.fetchData();
            });
          });
        })
      }
    },
    ConfirmReport_Leave() {                              //创建请假条目，判断请假输入是否正确，并且保存填写信息
      if (this.datavalue == null) {
        this.$message.error('时间格式错误，数据无效')
      } else if (this.Temporary_Occur.Occur_Leave.Why == null || 
                 this.Temporary_Occur.Occur_Leave.Why == undefined || 
                 this.Temporary_Occur.Occur_Leave.Why == "") {
        this.$message.error('请填写请假原因')
      } else if (this.Temporary_Occur.Occur_Leave.nature == null) {
        this.$message.error('请选择请假类型')
      } else {
        this.Temporary_Occur.ShouldReport = true;
          this.Temporary_Occur.Report.BossOpinions = '未读';
          this.Temporary_Occur.Report.ManagerOpinions = '未读';
          this.Temporary_Occur.Report.Passed = false;
        this.Temporary_Occur.CreatTime = this.getCurrentTimestamp;
        this.Temporary_Occur.LeaveTimeS = this.datavalue[0];
        this.Temporary_Occur.LeaveTimeE = this.datavalue[1];
        this.Temporary_Occur.CanModify = true;
        this.CreatTemporaryOccur(this.Temporary_Occur).then(() => {
          this.UpdateTemporaryOccur(this.Temporary_Occur).then(() => {
            this.CreateCalendarScheme(this.Temporary_Occur).then(() => {
              this.CancelReport();
              this.fetchData();
            });
          });
        })
      }
    },
    ConfirmReport_Overtime() {                           //创建请假条目，判断请假输入是否正确，并且保存填写信息
      if (this.datavalue == null) {
        this.$message.error('时间格式错误，数据无效')
      } else if (this.Temporary_Occur.Occur_Overtime.Where == null || 
                 this.Temporary_Occur.Occur_Overtime.Where == undefined || 
                 this.Temporary_Occur.Occur_Overtime.Where == "") {
        this.$message.error('请填写加班地点')
      } else if (this.Temporary_Occur.Occur_Overtime.Why == null || 
                 this.Temporary_Occur.Occur_Overtime.Why == undefined || 
                 this.Temporary_Occur.Occur_Overtime.Why == "") {
        this.$message.error('请填写加班原因')
      } else {
        this.Temporary_Occur.ShouldReport = true;
          this.Temporary_Occur.Report.BossOpinions = '未读';
          this.Temporary_Occur.Report.ManagerOpinions = '未读';
          this.Temporary_Occur.Report.Passed = false;
        this.Temporary_Occur.CreatTime = this.getCurrentTimestamp;
        this.Temporary_Occur.LeaveTimeS = this.datavalue[0];
        this.Temporary_Occur.LeaveTimeE = this.datavalue[1];
        this.Temporary_Occur.CanModify = true;
        this.CreatTemporaryOccur(this.Temporary_Occur).then(() => {
          this.UpdateTemporaryOccur(this.Temporary_Occur).then(() => {
            this.CreateCalendarScheme(this.Temporary_Occur).then(() => {
              this.CancelReport();
              this.fetchData();
            });
          });
        })
      }
    },


    async CreatTemporaryOccur(Temporary_Occur) {         //创建条目
      // console.log(Temporary_Occur)
      const { data } = await CreatTemporaryOccur({
        data : Temporary_Occur,
      });
      if (data) {
        this.Temporary_Occur._id = data;
      }
    },
    async UpdateTemporaryOccur(Temporary_Occur) {        //更新条目
      const { data } = await UpdateTemporaryOccur({
        data: Temporary_Occur,
      });
    },
    async CreateCalendarScheme(Temporary_Occur) {        //同步日历事件
      const { data } = await CreateCalendarScheme({
        data: Temporary_Occur,
      });
    },
    CancelReport() {                                     //出差/请假/加班取消创建，清空输入
      const newData = {
        Personnel: null,
        event: null,
        Occur_Leave: {
          nature: null,
          Why: null,
          TimeInterval: null,
        },
        Occur_Business: {
          Where: null,
          BindProject: null,
          TimeInterval: null,
        },
        Occur_Overtime:{           
          Where: null,              
          Why: null,                
          TimeInterval: null,       
        },
        CreatTime: null,          
        LeaveTimeS: null,
        LeaveTimeE: null,
        ShouldReport: false,
        CanModify : true,
        Report: {
          BossOpinions: null,
          ManagerOpinions: null,
          Passed: false,
        },
      };
      this.Temporary_Occur = newData;
      this.datavalue = null;
      this.BusinessTripReport = false;
      this.LeaveReport = false;
      this.OvertimeReport = false;

      this.EventListQuery_Change_filters.filters = 
        "({$and:[{'Personnel':'" + this.name + "'},{'LeaveTimeE':{$gte:" 
      this.EventListQuery_filters.filters  = "({$and:["
      this.EventListQuery_filters.filters += "{'Personnel':'" + this.name + "'},";
      let NowTime = this.getCurrentTimestamp;
      let ThreeDaysAgo = NowTime - 3 * 24 * 60 * 60 * 1000;
      this.EventListQuery_filters.filters += "{'LeaveTimeE':{$gte:" + ThreeDaysAgo + "}}";
      this.EventListQuery_filters.filters += "]})";
    },


    //--修改/撤回窗口函数集合------------------------------------------------------------------------------
    ChangeandWithdraw() {                                //打开修改窗口时的操作
      this.RapidScreen_AdministratorPrivileges = false ,
      this.RapidScreen_Name = this.userinfo.username ,
      this.RapidScreen_Table = [],
      this.RapidScreen_Event = "全部",
      this.RapidScreen_Month = new Date(),
      this.RapidScreen_Revise = "全部",
      this.RapidScreen_NotSelected = true,            
      this.RapidScreen_Table_SelectedInfo = {}, 

      this.SetRapidScreenListQuery(),      
      this.ChangeDialog = true;
    },
    SetRapidScreenListQuery() {                          //修改窗口改变筛选条件，
      this.RapidScreen_NotSelected = true,            
      this.RapidScreen_Table_SelectedInfo = {}, 

      this.RapidScreen_Month.setDate(1);
      this.RapidScreen_Month.setHours(0, 0, 0, 0);
      let firstDayTimestamp = this.RapidScreen_Month.getTime();
      let lastDay = new Date(this.RapidScreen_Month.getFullYear(), this.RapidScreen_Month.getMonth() + 1, 0);
      lastDay.setHours(23, 59, 59, 0);
      let lastDayTimestamp = lastDay.getTime();

      this.RapidScreen_Table_filters.filters  = "({$and:["
      this.RapidScreen_Table_filters.filters += "{'Personnel':'" + this.RapidScreen_Name + "'},";
      this.RapidScreen_Table_filters.filters += "({$and:[{'LeaveTimeE':{$gte:" + firstDayTimestamp + "}},{'LeaveTimeS':{$lt:" + lastDayTimestamp + "}}]}),";
        // 筛选条件:LeaveTimeE设定为大于这个月一号但是LeaveTimeS小于等于这个月最后一天
      if (this.RapidScreen_Event === '全部') {
      } else {
        this.RapidScreen_Table_filters.filters += "{'event':'" + this.RapidScreen_Event + "'},";
      }
      this.RapidScreen_Table_filters.filters += "]})";
     // console.log(this.RapidScreen_Table_filters.filters)

      this.fetchData_Table()
    },
    async fetchData_Table() {                            //修改窗口刷新显示
      this.listLoading_RapidScreenTable = true;
      this.RapidScreen_Table = [];

      const { EventData } = await GetTemporaryOccur(this.RapidScreen_Table_filters);
      for (var i = 0; i < EventData.length; i++) {
        let EventObj = {
          _id: null,                 //事件id
          Personnel: null,           //人员
          event: null,               //性质（出差或请假）
          Occur_Leave: {             //请假
            nature: null,             //性质，事假病假年假
            Why: null,                //原因
            TimeInterval: null,       //时间间隔
          },
          Occur_Business: {          //出差
            Where: null,              //去哪
            BindProject: null,        //绑定项目
            TimeInterval: null,       //时间间隔
          },
          Occur_Overtime:{           //加班
            Where: null,              //哪里加班
            Why: null,                //原因
            TimeInterval: null,       //时间间隔
          },
          CreatTime: null,           //创建时间
          LeaveTimeS: null,          //开始时间
          LeaveTimeE: null,          //结束时间
          ShouldReport: false,       //是否上报
          CanModify : true,          //是否可修改
          Report: {
            BossOpinions: null,      //顾总意见
            ManagerOpinions: null,   //项目经理意见
            Passed: false,           //是否同意
          },
        };
        this.RapidScreen_Table.push(EventObj);
        Object.assign(this.RapidScreen_Table[i], EventData[i]);
      };

      setTimeout(() => {
        this.listLoading_RapidScreenTable = false;
      }, 0.5 * 1000);
    },

    RapidScreenAnalysis_Duration(Obj) {                  //修改窗口表格内数据展现形式
      if (Obj.event === "出差") {
        const regex = /(\d+(\.\d+)?)[dh]_(\d+(\.\d+)?)/;
        const match = Obj.Occur_Business.TimeInterval.match(regex);
        if (match) {
          const number = match[1];
          return number + " 天";
        }
      } else {
        return this.TimeResolved(Obj);       
      }
    },
    RapidScreenAnalysis_DurationWeekend(Obj) {
      const regex = /(\d+(\.\d+)?)[dh]_(\d+(\.\d+)?)/;
      const match = Obj.Occur_Business.TimeInterval.match(regex);
      if (match) {
        const number = match[3];
        if (number != '0') {
          return "(含" + number + "天节假日)";
        }
      }
    },
    RapidScreenAnalysis_Where(Obj) {
      if (Obj.event === "出差") {
        return Obj.Occur_Business.Where;
      } else if (Obj.event === "加班") {
        return Obj.Occur_Overtime.Where;
      }
    },
    RapidScreenAnalysis_Why(Obj) {
      if (Obj.event === "请假") {
        return Obj.Occur_Leave.Why;
      } else if (Obj.event === "加班") {
        return Obj.Occur_Overtime.Why;
      }
    },

    SelectionEvent(val) {                                   //选中表格内条目，用以修改其详细内容
      this.RapidScreen_NotSelected = false;
      if (val === null) {                                //选中状态下改变筛选条件会失焦，导致val=null
        this.RapidScreen_Table_SelectedInfo = {};
        this.RapidScreen_NotSelected = true;
        return;
      }
      this.RapidScreen_Table_SelectedInfo = JSON.parse(JSON.stringify(val));
      //console.log(this.RapidScreen_Table_SelectedInfo)
    },
    RapidScreen_CalculateDays() {                           //计算时间间隔，修改特供版
        const startTimestamp = this.RapidScreen_Table_SelectedInfo.LeaveTimeS;
        const endTimestamp = this.RapidScreen_Table_SelectedInfo.LeaveTimeE;

        const startDate = new Date(startTimestamp);
        const endDate = new Date(endTimestamp);

        let startTimeWorkingTime = this.DayofWorkingTime(startTimestamp);
        let startTimeClosingTime = this.DayofClosingTime(startTimestamp);
        let startTimeDayStart  = this.DayofDayStart(startTimestamp);
        let startTimeDayMiddle = this.DayofDayMiddle(startTimestamp);
        let startTimeDayEnd    = this.DayofDayEnd(startTimestamp);

        let endTimeWorkingTime = this.DayofWorkingTime(endTimestamp);
        let endTimeClosingTime = this.DayofClosingTime(endTimestamp);
        let endTimeDayStart  = this.DayofDayStart(endTimestamp);
        let endTimeDayMiddle = this.DayofDayMiddle(endTimestamp);
        let endTimeDayEnd    = this.DayofDayEnd(endTimestamp);


        if (this.RapidScreen_Table_SelectedInfo.event === '出差') {                             //请假按天计算,计算逻辑
          let Day = 0;
          let Holiday = 0;
          let days = Math.floor((endTimestamp - startTimestamp) / (24 * 60 * 60 * 1000));
          if (days < 1) {
            if (startDate.getHours() > endDate.getHours()) {
              Day += 1;
              if (this.isDateHoliday(this.ToYYYYMMDD(startTimestamp))) {
                Holiday += 0.5;
              }
              if (this.isDateHoliday(this.ToYYYYMMDD(endTimestamp))) {
                Holiday += 0.5;
              }
            } else {
              if (((startTimestamp <= startTimeDayMiddle)  && (endTimestamp >= endTimeClosingTime)) || 
                  ((startTimestamp <= startTimeWorkingTime)  && (endTimestamp >= endTimeDayMiddle)) || 
                  ((endTimestamp - startTimestamp) >= (5.5 * 60 * 60 * 1000)) ) {
                Day += 1;
                if (this.isDateHoliday(this.ToYYYYMMDD(startTimestamp))) {
                  Holiday += 1;
                }
              } else {
                Day += 0.5;
                if (this.isDateHoliday(this.ToYYYYMMDD(startTimestamp))) {
                  Holiday += 0.5;
                }
              }
            }
          } else {
            if (startTimestamp <= startTimeDayMiddle) { 
              Day += 1; 
              if (this.isDateHoliday(this.ToYYYYMMDD(startTimestamp))) {
                Holiday += 1;
              }
            } else { 
              Day += 0.5; 
              if (this.isDateHoliday(this.ToYYYYMMDD(startTimestamp))) {
                Holiday += 0.5;
              }
            }

            if (endTimestamp >= endTimeDayMiddle) { 
              Day += 1; 
              if (this.isDateHoliday(this.ToYYYYMMDD(endTimestamp))) {
                Holiday += 1;
              }
            } else { 
              Day += 0.5; 
              if (this.isDateHoliday(this.ToYYYYMMDD(endTimestamp))) {
                Holiday += 0.5;
              }
            }
            
            if (startDate.getHours() > endDate.getHours()) {
              Day += days;
            } else {
              Day += (days - 1);
            }
            const dates = []; // 存储日期的数组

            // 循环遍历每一天，将日期以 'yyyy-mm-dd' 格式添加到数组中
            for (let date = this.Cuttimestamp(startTimestamp); date <= this.Cuttimestamp(endTimestamp); date += 86400000) {
              const formattedDate = this.ToYYYYMMDD(date); // 将日期转换为 'yyyy-mm-dd' 格式
              dates.push(formattedDate); // 将日期添加到数组中
            }
            dates.shift(); // 移除起始日期
            dates.pop(); // 移除结束日期
            for (const date of dates) {
              if (this.isDateHoliday(date)) {
                Holiday += 1; // 如果日期是节假日，增加节假日计数
              }
            }
          }
          this.RapidScreen_Table_SelectedInfo.Occur_Business.TimeInterval = null;
          this.RapidScreen_Table_SelectedInfo.Occur_Business.TimeInterval = Day + 'd_';
          this.RapidScreen_Table_SelectedInfo.Occur_Business.TimeInterval += Holiday + 'd';
          
        } else if (this.RapidScreen_Table_SelectedInfo.event === '请假') {                      //请假按照小时计算逻辑
          let Hours = 0;
          let days = Math.floor((endTimestamp - startTimestamp) / (24 * 60 * 60 * 1000));
          let startTimeWorkingTime = this.DayofWorkingTime(startTimestamp);
          let startTimeClosingTime = this.DayofClosingTime(startTimestamp);
          let endTimeWorkingTime = this.DayofWorkingTime(endTimestamp);
          let endTimeClosingTime = this.DayofClosingTime(endTimestamp);
          if (days < 1) {
            if (startDate.getHours() > endDate.getHours()) {
              if (startTimeClosingTime > startTimestamp) {
                Hours += this.roundDown((startTimeClosingTime - startTimestamp)/(60 * 60 * 1000));
              }
              if (endTimestamp > endTimeWorkingTime) {
                Hours += this.roundDown((endTimestamp - endTimeWorkingTime)/(60 * 60 * 1000));
              }
            } else {
              if ((endTimestamp > endTimeWorkingTime) && 
                  (endTimestamp < endTimeClosingTime) && 
                  (startTimestamp > startTimeWorkingTime) && 
                  (startTimestamp < startTimeClosingTime)) {
                Hours += this.roundDown((endTimestamp - startTimestamp)/(60 * 60 * 1000));
              }else if ((endTimestamp >= endTimeClosingTime) && 
                        (startTimestamp <= startTimeWorkingTime)) {
                Hours += 8;
              }else if ((startTimestamp >= startTimeClosingTime) ||
                        (endTimestamp <=  endTimeWorkingTime)) {
                Hours += 0;
              }else if (endTimestamp >= endTimeClosingTime) {
                Hours += this.roundDown((endTimeClosingTime - startTimestamp)/(60 * 60 * 1000));
              }else if (startTimestamp <= startTimeWorkingTime) {
                Hours += this.roundDown((endTimestamp - startTimeWorkingTime)/(60 * 60 * 1000));
              }
            }
          } else {
            if ((startTimeClosingTime > startTimestamp) &&
                (startTimeWorkingTime < startTimestamp)) {
              Hours += this.roundDown((startTimeClosingTime - startTimestamp)/(60 * 60 * 1000));
            }else if ((startTimeClosingTime <= startTimestamp)) {
              Hours += 0;
            }else if ((startTimeWorkingTime >= startTimestamp)) {
              Hours += 8;
            }

            if ((endTimeClosingTime > endTimestamp) &&
                (endTimeWorkingTime < endTimestamp)) {
              Hours += this.roundDown((endTimestamp - endTimeWorkingTime)/(60 * 60 * 1000));
            }else if ((endTimeClosingTime <= endTimestamp)) {
              Hours += 8;
            }else if ((endTimeWorkingTime >= endTimestamp)) {
              Hours += 0;
            }

            if (startDate.getHours() > endDate.getHours()) {
              Hours += days * 8;
            } else {
              Hours += (days - 1) * 8;
            }
          }
          this.RapidScreen_Table_SelectedInfo.Occur_Leave.TimeInterval = null;
          this.RapidScreen_Table_SelectedInfo.Occur_Leave.TimeInterval = Hours + 'h';
          
        } else if (this.RapidScreen_Table_SelectedInfo.event === '加班') {

          let Hours = 0;
          let Day = 0;
          let days = Math.floor((endTimestamp - startTimestamp) / (24 * 60 * 60 * 1000));

          //工作日加班
          if ((days < 1) &&
              (startTimeDayStart == endTimeDayStart) &&
              (this.isDateHoliday(this.ToYYYYMMDD(startTimestamp)) === false)) {
            if ((endTimestamp <= endTimeWorkingTime)||(startTimestamp >= startTimeClosingTime)) {
              Hours += this.roundDown((endTimestamp - startTimestamp)/(60 * 60 * 1000));
            } else if ((startTimestamp <= startTimeWorkingTime)&&(endTimestamp >= endTimeClosingTime)) {
              Hours += this.roundDown((endTimeWorkingTime - startTimestamp)/(60 * 60 * 1000));
              Hours += this.roundDown((endTimestamp - endTimeClosingTime)/(60 * 60 * 1000));
            } else if ((endTimestamp >= endTimeWorkingTime)&&(startTimestamp <= endTimeWorkingTime)) {
              Hours += this.roundDown((endTimeWorkingTime - startTimestamp)/(60 * 60 * 1000));
            } else if ((startTimestamp <= endTimeClosingTime)&&(endTimestamp >= endTimeClosingTime)) {
              Hours += this.roundDown((endTimestamp - endTimeClosingTime)/(60 * 60 * 1000));
            } else {
              Hours += 0;
            }
          }   //当日加班

          if ((days < 1) &&
              (endTimeDayStart > startTimeDayStart) &&
              (this.isDateHoliday(this.ToYYYYMMDD(startTimestamp)) === false) &&
              (this.isDateHoliday(this.ToYYYYMMDD(endTimestamp)) === false)) {
            Hours += this.roundDown((endTimestamp - startTimestamp)/(60 * 60 * 1000));
          }   //加班了一个通宵，前后两天都是工作日

          //节假日加班
          if ((startTimeDayStart == endTimeDayStart) &&
              (this.isDateHoliday(this.ToYYYYMMDD(startTimestamp)) === true)) {
            if ((endTimestamp - startTimestamp) < (8 * 60 * 60 * 1000)) {
              Day += 0.5;
            } else {
              Day += 1;
            }
          }   //一天节假日加班

          if (((endTimeDayStart - startTimeDayStart)/(24 * 60 * 60 * 1000) >=1) &&
              (this.isDateHoliday(this.ToYYYYMMDD(startTimestamp)) === true) &&
              (this.isDateHoliday(this.ToYYYYMMDD(endTimestamp)) === true)) {
            const dates = []; // 存储日期的数组
            // 循环遍历每一天，将日期以 'yyyy-mm-dd' 格式添加到数组中
            for (let date = this.Cuttimestamp(startTimestamp); date <= this.Cuttimestamp(endTimestamp); date += 86400000) {
              const formattedDate = this.ToYYYYMMDD(date); // 将日期转换为 'yyyy-mm-dd' 格式
              dates.push(formattedDate); // 将日期添加到数组中
            }
            dates.shift(); // 移除起始日期
            dates.pop();   // 移除结束日期
            for (const date of dates) {
              if (!this.Temporary_Occur.Occur_Overtime.TimeIntervalthis.isDateHoliday(date)) {
                this.Temporary_Occur.Occur_Overtime.TimeInterval = "error"
              }
            }
            if (this.Temporary_Occur.Occur_Overtime.TimeInterval != "error") {
              if (startTimestamp > startTimeDayMiddle) {
                Day += 0.5;
              } else {
                Day += 1;
              }
              if (endTimestamp < endTimeDayMiddle) {
                Day += 0.5;
              } else {
                Day += 1;
              }
              for (const date of dates) {
                Day += 1;
              }
            }
          }   //多日连续节假日加班，中间不能含有工作日

          this.RapidScreen_Table_SelectedInfo.Occur_Overtime.TimeInterval = null;
          if (Hours != 0) {
            this.RapidScreen_Table_SelectedInfo.Occur_Overtime.TimeInterval = Hours + 'h';
          }
          if (Day != 0) {
            this.RapidScreen_Table_SelectedInfo.Occur_Overtime.TimeInterval = Day + 'd';
          }
        }
    },

    RapidScreen_CancelReport(row) {                         //放弃修改，取消选中
      this.$refs.RapidScreenTable.setCurrentRow(row);
      this.SelectionEvent(null);
    },
    RapidScreen_ConfirmReport() {                           //确认修改，对数据进行验证
     // console.log(this.RapidScreen_Table_SelectedInfo)
      if (this.RapidScreen_Table_SelectedInfo.LeaveTimeS === null || 
          this.RapidScreen_Table_SelectedInfo.LeaveTimeS === undefined || 
          this.RapidScreen_Table_SelectedInfo.LeaveTimeS === "") {
        this.$message.error('开始时间错误，数据无效')
      };
      if (this.RapidScreen_Table_SelectedInfo.LeaveTimeE === null || 
          this.RapidScreen_Table_SelectedInfo.LeaveTimeE === undefined || 
          this.RapidScreen_Table_SelectedInfo.LeaveTimeE === "") {
        this.$message.error('结束时间错误，数据无效')
      };
      if (this.RapidScreen_Table_SelectedInfo.LeaveTimeS > this.RapidScreen_Table_SelectedInfo.LeaveTimeE) {
        this.$message.error('开始时间不能大于结束时间，数据无效')
      }
      if (this.RapidScreen_Table_SelectedInfo.event === "出差") {
        if (this.RapidScreen_Table_SelectedInfo.Occur_Business.Where == null || 
            this.RapidScreen_Table_SelectedInfo.Occur_Business.Where == undefined || 
            this.RapidScreen_Table_SelectedInfo.Occur_Business.Where == "") {
          this.$message.error('出差地点填写错误，数据无效')
        }
      }
      if (this.RapidScreen_Table_SelectedInfo.event === "请假") {
        if (this.RapidScreen_Table_SelectedInfo.Occur_Leave.Why == null || 
            this.RapidScreen_Table_SelectedInfo.Occur_Leave.Why == undefined || 
            this.RapidScreen_Table_SelectedInfo.Occur_Leave.Why == "") {
          this.$message.error('请假原因填写错误，数据无效')
        }
      }
      if (this.RapidScreen_Table_SelectedInfo.event === "加班") {
        if (this.RapidScreen_Table_SelectedInfo.Occur_Overtime.Where == null || 
            this.RapidScreen_Table_SelectedInfo.Occur_Overtime.Where == undefined || 
            this.RapidScreen_Table_SelectedInfo.Occur_Overtime.Where == "") {
          this.$message.error('加班地点填写错误，数据无效')
        }
        if (this.RapidScreen_Table_SelectedInfo.Occur_Overtime.Why == null || 
            this.RapidScreen_Table_SelectedInfo.Occur_Overtime.Why == undefined || 
            this.RapidScreen_Table_SelectedInfo.Occur_Overtime.Why == "") {
          this.$message.error('加班原因填写错误，数据无效')
        }
      }
      this.ChangeTemporaryOccur(this.RapidScreen_Table_SelectedInfo).then(() => {
        this.RapidScreen_CancelReport();
        this.fetchData_Table();
      })
    },
    RapidScreen_DeleteReport() {                            //删除选中条目
      this.DeleteTemporaryOccur(this.RapidScreen_Table_SelectedInfo).then(() => {
        this.RapidScreen_CancelReport();
        this.fetchData_Table();
      })
    },

    async ChangeTemporaryOccur(RapidScreen_Table_SelectedInfo) {      //修改条目
      const { data } = await ChangeEvent({
        data: RapidScreen_Table_SelectedInfo,
      });
    },
    async DeleteTemporaryOccur(RapidScreen_Table_SelectedInfo) {      //删除条目
      const { data } = await DeleteEvent({
        data: RapidScreen_Table_SelectedInfo,
      });
    },


    //  日历显示，事件条内容打印------------------------------------------------------------------------------
    getContent_Business_When(item) {
      if ( item.Occur_Business[0].LeaveTimeS && 
           item.Occur_Business[0].LeaveTimeE ) 
      {
        return (
          "出差时间：" + this.ToHHMM(item.Occur_Business[0].LeaveTimeS) + "~" +  this.ToHHMM(item.Occur_Business[0].LeaveTimeE)
        );
      } else if (item.Occur_Business[0].LeaveTimeS) {
        return "出差时间：" + this.ToHHMM(item.Occur_Business[0].LeaveTimeS) + "~ 当日结束";
      } else if (item.Occur_Business[0].LeaveTimeE) {
        return "出差时间：当日开始 ~ " + this.ToHHMM(item.Occur_Business[0].LeaveTimeE);
      } else if (item.Occur_Business[0].isAllDay) {
        return "出差时间：一整天 ";
      }
    },
    getContent_Business_Where(item) {
      return "出差地点：" + item.Occur_Business[0].Where;
    },
    getContent_Leave_When(item) {
      if ( item.Occur_Leave[0].LeaveTimeS && 
           item.Occur_Leave[0].LeaveTimeE ) 
      {
        return (
          "请假时间：" + this.ToHHMM(item.Occur_Leave[0].LeaveTimeS) + "~" +  this.ToHHMM(item.Occur_Leave[0].LeaveTimeE)
        );
      } else if (item.Occur_Leave[0].LeaveTimeS) {
        return "请假时间：" + this.ToHHMM(item.Occur_Leave[0].LeaveTimeS) + "~ 当日结束";
      } else if (item.Occur_Leave[0].LeaveTimeE) {
        return "请假时间：当日开始 ~ " + this.ToHHMM(item.Occur_Leave[0].LeaveTimeE);
      } else if (item.Occur_Leave[0].isAllDay) {
        return "请假时间：一整天 ";
      }
    },
    getContent_Leave_nature(item) {
      return "请假类型：" + item.Occur_Leave[0].nature;
    },    
    getContent_Leave_Why(item) {
      return "请假原因：" + item.Occur_Leave[0].Why;
    },
    getContent_Overtime_When(item) {
      if ( item.Occur_Overtime[0].LeaveTimeS && 
           item.Occur_Overtime[0].LeaveTimeE ) 
      {
        return (
          "加班时间：" + this.ToHHMM(item.Occur_Overtime[0].LeaveTimeS) + "~" +  this.ToHHMM(item.Occur_Overtime[0].LeaveTimeE)
        );
      } else if (item.Occur_Overtime[0].LeaveTimeS) {
        return "加班时间：" + this.ToHHMM(item.Occur_Overtime[0].LeaveTimeS) + "~ 当日结束";
      } else if (item.Occur_Overtime[0].LeaveTimeE) {
        return "加班时间：当日开始 ~ " + this.ToHHMM(item.Occur_Overtime[0].LeaveTimeE);
      } else if (item.Occur_Overtime[0].isAllDay) {
        return "加班时间：一整天 ";
      }
    },    
    getContent_Overtime_Where(item) {
      return "加班地点：" + item.Occur_Overtime[0].Where
    },
    getContent_Overtime_Why(item) {
      return "加班原因：" + item.Occur_Overtime[0].Why
    },

  },
};
</script>

<style scoped>
.TitleBox >>> .el-card__body {
  height: 12vh;
  padding: 0px 20px 0px 20px;
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.TitleBox_title {
  display: flex;
    flex-direction: row;
    align-items: center;
  font-size: 18px;
  margin: 5px 0px 5px 0px;
  height: 50%;
}
.TitleBox_body{
  width: 100%;
  height: 50%;
  display: flex;
    flex-direction: row;
    align-items: center;
}
p {
  margin: 0px;
}

.TimeStatistics{
  margin-bottom: 0px; 
  margin-top:-22px;
}
.TimeStatistics >>> .el-form-item__content{
  display: flex;
  align-items: center;
}

.CalendarNotice {
  margin-top: 10px;
  height: 81vh;
  
}
.Calendar {
  margin-right: 10px;
  height: 100%;
}
.Calendar >>> .el-calendar-table .el-calendar-day {
  height: 100px;
}
.Operate {
  height: 10%;
  display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.Operate >>> .el-card__body {
  padding: 0px 10px 0px 10px;
}
.OperateCard {
  height: 90%;
  width: 100%;
  display: flex;
    align-items: center;
}
/* .QuickJump {
  height: 200px;
  margin-bottom: 10px;
} */
/* .QuickJump >>> .el-card__header {
  padding: 10px 20px;
}
.QuickJump >>> .el-card__body {
  padding: 0px;
}
.QuickJump >>> .el-table__cell {
  padding: 6px 0px;
}
.QuickJump >>> .el-table .cell {
  text-align: center;
}
.QuickJumpTable {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
  padding-bottom: 10px;
} */

.NoticeMini >>> .el-card__header {
  padding: 10px 20px;
}
.NoticeMini {
  height: 90%;
}
.NoNotification {
  display: flex;
  justify-content: center;
  align-items: center;
}
.NoticeMini >>> .el-card__body{
  padding: 10px;
}
.NoticeInfo{
  margin-bottom: 10px;
}
.NoticeInfo >>> .el-card__body{
  padding: 0px;
}
.card-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px 5px 20px;
}
.Opinions {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.card-footer-divider.el-divider--horizontal{
  margin: 0px;
}
.EventBar {
  margin-left: -8px;
  margin-right: -8px;
  margin-top: 10px;
  height: 10px;
}
.Business_Start .EventBar {
  margin-left: 0px;
  background-color: lightblue;
  border-radius: 5px 0 0 5px;
}
.Business_Between .EventBar {
  background-color: lightblue;
}
.Business_End .EventBar {
  margin-right: 0px;
  background-color: lightblue;
  border-radius: 0 5px 5px 0;
}
.Business_None .EventBar {
  background-color: rgba(0, 0, 0, 0);
}
.Business_StartEnd .EventBar {
  margin-left: 0px;
  margin-right: 0px;
  background-color: lightblue;
  border-radius: 5px 5px 5px 5px;
}

.Leave_Start .EventBar {
  margin-left: 0px;
  background-color: lightgreen;
  border-radius: 5px 0 0 5px;
}
.Leave_Between .EventBar {
  background-color: lightgreen;
}
.Leave_End .EventBar {
  margin-right: 0px;
  background-color: lightgreen;
  border-radius: 0 5px 5px 0;
}
.Leave_StartEnd .EventBar {
  margin-left: 0px;
  margin-right: 0px;
  background-color: lightgreen;
  border-radius: 5px 5px 5px 5px;
}

.Overtime_Start .EventBar {
  margin-left: 0px;
  background-color: lightgray;
  border-radius: 5px 0 0 5px;
}
.Overtime_Between .EventBar {
  background-color: lightgray;
}
.Overtime_End .EventBar {
  margin-right: 0px;
  background-color: lightgray;
  border-radius: 0 5px 5px 0;
}
.Overtime_StartEnd .EventBar {
  margin-left: 0px;
  margin-right: 0px;
  background-color: lightgray;
  border-radius: 5px 5px 5px 5px;
}
.Change_Dialog >>> .el-dialog__body   {
  padding: 10px;
  height: 700px;
}
.Choose_Change {
  height: 680px;
}
.Choose_Change >>> .el-card__header   {
  padding: 10px 20px;
}
.Choose_Change >>> .el-card__body{
  padding: 10px;
}
.Choose_Change_Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Choose_Detailed {
  margin-left: 10px;
  height: 680px;
}
.Choose_Detailed >>>.el-card__header {
  padding: 10px 20px;
  height: 50px;
  display: flex;
  align-items: center;
}
.Choose_Detailed >>> .el-card__body {
  height: 93%;
}
</style>
