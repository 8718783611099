var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "head" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.showdialog } },
            [_vm._v("新建")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "Main" }, [
        _c(
          "div",
          { staticClass: "tablediv" },
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _vm.TableInfo },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "name",
                    label: "厂商名称",
                    width: "180",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "address",
                    label: "地址",
                    width: "180",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: { prop: "phone", label: "联系电话", align: "center" },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "identifierStr",
                    label: "英文标识符",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: { label: "操作" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEdit(scope.$index, scope.row)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加生产商",
            visible: _vm.dislogvisial,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dislogvisial = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogloading,
                  expression: "dialogloading",
                },
              ],
              attrs: {
                model: _vm.ShowInfo,
                "label-width": "120px",
                rules: _vm.addrules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "厂商名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ShowInfo.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ShowInfo, "name", $$v)
                      },
                      expression: "ShowInfo.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "英文标识符", prop: "identifierStr" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ShowInfo.identifierStr,
                      callback: function ($$v) {
                        _vm.$set(_vm.ShowInfo, "identifierStr", $$v)
                      },
                      expression: "ShowInfo.identifierStr",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "地址" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ShowInfo.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.ShowInfo, "address", $$v)
                      },
                      expression: "ShowInfo.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系电话" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ShowInfo.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.ShowInfo, "phone", $$v)
                      },
                      expression: "ShowInfo.phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.creativePlant()
                        },
                      },
                    },
                    [_vm._v("立即创建")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dislogvisial = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改信息",
            visible: _vm.ChangeInfo,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.ChangeInfo = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogloading,
                  expression: "dialogloading",
                },
              ],
              attrs: { model: _vm.ShowInfo, "label-width": "120px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "厂商名称" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.ShowInfo.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ShowInfo, "name", $$v)
                      },
                      expression: "ShowInfo.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "英文标识符" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.ShowInfo.identifierStr,
                      callback: function ($$v) {
                        _vm.$set(_vm.ShowInfo, "identifierStr", $$v)
                      },
                      expression: "ShowInfo.identifierStr",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "地址" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ShowInfo.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.ShowInfo, "address", $$v)
                      },
                      expression: "ShowInfo.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系电话" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ShowInfo.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.ShowInfo, "phone", $$v)
                      },
                      expression: "ShowInfo.phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmUpdate()
                        },
                      },
                    },
                    [_vm._v("修改")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.ChangeInfo = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }