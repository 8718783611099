<template>
  <div class="OutBox">
    <div class="HeadButtonArea">
      <el-button id="print" v-print="printObj" v-show="false"></el-button>
      <el-button @click="beforePrint">打印</el-button>
      <el-button @click="save">保存</el-button>   
      <router-link
      :to="{
        path: 'Project',
        query: { projectID: task.projectID,actTask : task },
    
      }"
    >
      <el-button style="margin-left:10px" type="button">返回</el-button>
    </router-link>
    </div>
    <div id="printArea">
      <div class="Tittle">
        <h2>工作报告<span class="italic">Job Report</span></h2>
        <img src="../../../Img/log.jpg" />
      </div>
      <div class="SplitLine"></div>
      <div class="Body">
        <div class="BodyHead">
          <div class="col">
            <div class="Unit">

              <div class="Word"><span>姓名</span><span>Name</span></div>

              <div 
                v-if="!PrintState"
                class="NumberInpunt1 HeadNumberInpunt" 
                style="width: 39mm"
              >
                <el-input disabled v-model="task.finisher"></el-input>
              </div>
              <div 
                v-if="PrintState"
                class="NumberInpunt1 HeadNumberInpunt" 
                style="width: 39mm"
              >
                <el-input disabled v-model="Temporary_finisher"></el-input>
              </div>

            </div>
            <div class="Unit">
              <div class="Word"><span>地点</span><span>Place of Action</span></div>
              <div class="NumberInpunt1 HeadNumberInpunt" style="width: 25mm">
                <el-input v-model="task.Place"></el-input>
            </div>
            
          </div>
          </div>
          <div class="col">
            <div class="Unit">
              <div class="Word"><span>客户</span><span>Customer</span></div>
              <div class="NumberInpunt1 HeadNumberInpunt" style="width: 82mm">
                <el-input list="browsers" v-model="task.customer" ></el-input>
                <datalist id="browsers">
                  <option v-for="item in customerInfo">
                    {{ item["company"]  }}
                  </option>
                </datalist>
              </div>
            </div>
            <div class="Unit">

              <div class="Word"><span>合作者</span><span>Cooperator</span></div>

              <div 
                v-if="!PrintState"
                class="NumberInpunt1 HeadNumberInpunt" 
                style="width: 77mm"
              >
                <el-input disabled v-model="Worker"></el-input>
              </div>
              <div 
                v-if="PrintState"
                class="NumberInpunt1 HeadNumberInpunt" 
                style="width: 77mm"
              >
                <el-input disabled v-model="Temporary_Worker"></el-input>
              </div>

            </div>
       
          </div>
        </div>
      
        </div>
        <div class="log" >
          <p class="InfoTittle">日志 Activities</p>
          <div class="logTable" id="DelieveryTable">
          <el-table
           
           ref="singleTable"
            highlight-current-row
            :data="task.step"
            v-loading="loading"
            cell-class-name="textTop"
            :show-header="false"
            :header-row-style="{
              color: '#000',
              'font-size': '13px',
              'font-weight': 400,
            }"
          
           
          >
            <!-- style="max-width: 20.8cm" -->
            <el-table-column
              type="index"
             
              align="center"
              :border="false"
              width="50">
            </el-table-column>
           
          
      
            <el-table-column
              prop="name"
              width="160"
             
              :border="false"
              align="center"
              min-width="85px"
            ></el-table-column>
            <el-table-column
              prop="log"
              :border="false"
              align="left"
              min-width="85px"
            ></el-table-column>
          
          </el-table>
        </div>
      </div>
        <div class="Result" >
          <p class="InfoTittle">总结 Result</p>
          <div class="resultInput" id="Result">
            <el-input :autosize="{ minRows: 1 }" ref="result1" resize ="none" @keyup.native.enter.delete="resizeHeight" type="textarea" class="edit-input" v-if="task.comment !=='' && task.comment !==null " disabled  v-model="task.comment"></el-input>
            <el-input :autosize="{ minRows: 2 }" ref="result2" resize ="none" @keyup.native.enter.delete="resizeHeight" type="textarea" class="edit-input"  v-model="task.reportResult"></el-input>
          </div>
        </div>
        
     <!-- <div class="bottomInfo">
       
       <div class="relative">
         
         <div class="area_right ">
           <span class="title-right">创建日期 Date: </span>
           <div class="writeInput">
             <el-date-picker
             v-model="task.createTime"
             class="my-date-picker"
             disabled
             align="right"
             type="date"
             format="yyyy 年 MM 月 dd 日"
             value-format="timestamp"
         >
         </el-date-picker>
        
           </div>
         </div>
       </div>
     </div> -->

      <div class="bottomInfo">
       
        <div class="relative">
          
          <div class="area_right top">
            <span class="title-right">报告日期 Date: </span>
            <div class="writeInput">
              <el-date-picker
              v-model="task.finishTime"
              class="my-date-picker"
              disabled
              align="right"
              type="date"
              format="yyyy 年 MM 月 dd 日"
              value-format="timestamp"
          >
          </el-date-picker>
              <!-- <el-input type="text" disabled v-model="task.finishTime" class="bottom-input" size="small" /> -->
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Loading } from "element-ui";
import { getCustomerList } from "@/api/article";
import { getName } from "@/utils/auth";
import { MessageBox, Message } from "element-ui";

import {
  getTasklist,
  updateTaskList,
} from "@/api/technology";
export default {
  name: "shippingUnit",
  data() {
    return {
      printObj: {
        id: "printArea",
        beforeOpenCallback(vue) {
          vue.loadingInstance.close();
        },
        closeCallback(vue) {
          // vue.$refs.noprint.style.visibility = "visible";
           let table = document.getElementById("DelieveryTable");
           table.style.width = "100%";
          let Result = document.getElementById("Result");
          Result.style.width = "100%";
        },
      },
      task : {},
      Worker:null,

      Temporary_finisher: null,
      Temporary_Worker: null,
      PrintState: false,

      SelectRow: 0,
      UserName : getName(),
      _id: null,
      customerInfo:null,
      loading: false,
      DeleteConfirmVisiable: false,
      pickerOptions: {
        clearIcon: false,  /* 隐藏清空图标 */
        prefixIcon: '',  /* 设置前缀图标为空 */
        inputClass: 'my-date-picker',  /* 自定义输入框的 class */
        editable: false,  /* 禁止手动输入 */
        popupStyle: { border: 'none' },  /* 隐藏边框 */
      },
    
    };
  },
  created() {
    if (this.$route.query.taskID) {
      // this.DeliveryInfolist = this.$route.params.shippingObj.DeliveryInfolist;
      // this.HeadInof = this.$route.params.shippingObj.HeadInof;
      // this.PurchAserInfo = this.$route.params.shippingObj.PurchAserInfo;
      this._id = this.$route.query.taskID;
    }
  
    this.getTask();
   
    this.getCustomerInfo();
    
  },
  watch: {
   
  },
  methods: {
   async save(){
    const {data} = await updateTaskList({
        id: this.task._id,
        data: this.task,
       })
       if (data) {
      
        Message({
          message: "保存成功",
          type: "success",
          duration: 5 * 1000,
        });
      }
    },
    async getTask(){
      let loadingInstance = Loading.service({ fullscreen: true });
      const { data } = await getTasklist({
        filters :"({_id :'"+this._id + "' })"
      });
      this.task = data.list[0];
      
      this.Worker=this.getWorker();
      loadingInstance.close();
    },
    Resort() {
      this.DeliveryInfolist.sort(this.SortFun);
      //POS重新给值
      for (var i = 0; i < this.DeliveryInfolist.length; i++) {
        this.DeliveryInfolist[i].Pos = i + 1;
      }
      //清除选中行
      this.SelectRow = 0;
      this.$refs.DelieveryTable.setCurrentRow();
    },
    resize() {
      if (this.$refs.result1){
        this.$refs.result1.$refs.textarea.style.height =
        this.$refs.result1.$refs.textarea.scrollHeight + "px";
      }
      if (this.$refs.result2){
        this.$refs.result2.$refs.textarea.style.height =
        this.$refs.result2.$refs.textarea.scrollHeight + "px";
  
      }
       
    },
    resizeHeight(e) {
      var elem = e.currentTarget.children[0];
      elem.style.height = "0";
      elem.scrollTop = "0";
      const { scrollHeight } = elem;
      if (scrollHeight > elem.offsetHeight) {
        elem.style.height = `${scrollHeight}px`;
      }
    },
    SortFun(a, b) {
      if ("reportNr" in a && "reportNr" in b) {
        if (a.reportNr != b.reportNr) return a.reportNr < b.reportNr ? 1 : -1;
        else if (a.Module != b.Module) return a.Module < b.Module ? 1 : -1;
      } else {
        if ("reportNr" in a) return 1;
        else if (a.Module != b.Module) return a.Module < b.Module ? 1 : -1;
      }
    },
    getWorker(){
      let worker ='' ;
       for (let i = 0 ; i < this.task.worker.length ; i++){
         if (this.task.worker[i] !== this.task.finisher){
          if (worker !== ''){
            worker +=","
          }
          worker += this.task.worker[i] ;
         }
       }
       this.CompleteJudg();
       return worker;
    },
    async getCustomerInfo() {
      const { data } = await getCustomerList();
      this.customerInfo = data.list;
    },
    
    CompleteJudg() {
      if (this.task.finisher == null) {
        this.Temporary_finisher = null ;
      } else {
        this.Temporary_finisher = getName();
        let worker ='' ;
        for (let i = 0 ; i < this.task.worker.length ; i++){
          if (this.task.worker[i] !== this.Temporary_finisher){
            if (worker !== ''){
              worker +=","
            }
            worker += this.task.worker[i] ;
          }
        }
        this.Temporary_Worker = worker;
      }
     // console.log(this.Temporary_Worker);
     // console.log(this.Temporary_finisher);
    },
  
  
    beforePrint() {
      this.PrintState = true;
      this.loadingInstance = Loading.service({ fullscreen: true });
      let table = document.getElementById("DelieveryTable");
      table.style.width = "20.2cm";
      let Result = document.getElementById("Result");
     Result.style.width = "20.2cm";
   // this.$refs.noprint.style.visibility = "hidden";
     this.SelectRow = 0;
     this.resize();
   // this.$refs.DelieveryTable.setCurrentRow();
    this.$forceUpdate();
      let button = document.getElementById("print");
      setTimeout(() => {
        button.click();
        this.PrintState = false;
      }, 1300);
    },
  },
};
</script>

<style scoped >
* {
  font-family: "Arial";
}
.Body {
  margin-bottom: 5px;
  margin-left: 4mm;
  width: calc(100% - 20px);
}
.log{
  margin-left: 2mm;
}
.BodyHead {
  background-color: rgb(235, 240, 249);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  -webkit-print-color-adjust: exact !important; /* Safari 和 Chrome */
  color-adjust: exact !important; /* Firefox */
}
.HeadButtonArea {
  margin-top: 10px;
  margin-left: 10px;
}
.PrintArea {
  position: relative;
}
.Tittle {
  position: relative;
  left: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 5%;
}
.Tittle h2 {
  display: inline-block;
}
.italic {
  font-style: italic;
  font-size: 5mm;
}
.SplitLine {
  margin-left: 4mm;
  -webkit-print-color-adjust: exact !important; /* Safari 和 Chrome */
  color-adjust: exact !important; /* Firefox */
  width: calc(100% - 5mm);
  height: 2mm;
  background-color: rgb(81, 125, 191);
}
.Word {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  display: inline-block;
  padding-right: 10px;
  font-size: 14px !important;
}
.Company {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}
.edit-input >>> .el-textarea__inner {

  border: 0px;


  
  color: #000;

  font-size: 14px;
}
.edit-input >>> .el-input__suffix {
  display: none;
}
.CompanyTittle {
  margin-left: 15px;
  font-size: 14px;
  font-weight: bold;
  border: none !important;
}
.CompanyTittle p {
  color: rgb(60, 109, 183);
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.textTop >>> .cell{
  vertical-align: top !important;
}
.Info1 {
  height: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: 12px;
}
.Info1 p {
  display: inline-block;
  padding-bottom: 5px;
  border-bottom: rgb(192, 192, 192) solid 1px;
}
.CompanyTittle >>> .el-input__inner {
  color: #000;
  font-size: 16px;
  font-weight: bold;
  border: none !important;
}
.NumberInpunt {
  display: inline-block;
  padding-right: 0px;
}
.NumberInpunt >>> .el-input__inner {
  height: 35px;
  padding: 0 !important;
}
.NumberInpunt1 {
  display: inline-block;
}
.HeadNumberInpunt >>> .el-input__inner {
  padding-right: 0px !important;
  padding-left: 0px !important;
  font-size: 14px;
  height: 18px !important;
  text-align: center;
}
.NumberInpunt1 >>> .el-input__inner {
  height: 35px;
  border-left: none;
  border-top: none;
  border-right: none;
  border-radius: 0px;
  border-bottom-width: 2px;
  border-bottom-color: rgb(192, 192, 192);
  background-color: rgba(0, 0, 0, 0);
  color: #000;
}
#printArea{
  padding-bottom: 300px;
}
.logTable{
  margin-left: 10px;
  border: 1px solid black;
  margin-right: 10px;
  padding: 1px;
}
.resultInput{
  padding: 10px 5px;
  border: 1px solid black;
  line-height: 20px;
  white-space:normal;
  font-size: 12px;
}
.resultInput >>> .el-input__inner{
  border: none;
  color: #000;
}
.InfoTittle {
  color: rgb(60, 109, 183);
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  margin-top: 10px;
}
.NumberInpunt2 {
  display: inline-block;
}
.NumberInpunt2 >>> .el-input__inner {
  height: 35px;
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom: none;
  background-color: rgba(0, 0, 0, 0);
}
.SerinalInput >>> .el-input__inner {
  border: none;
  text-align: center;
  margin: 0;
  padding: 0;
  height:30px;
  width: 100%;
  color: #000;
  padding: 3px 0 !important;
  font-size: 10px;
}
.addbutton {
  padding-left: 20px;
}
.DeliveryInfo {
  margin-top: 15px;
}
.Result{
   margin-left: 2mm;
}
.ResultBody {
  padding: 10px 5px;
  border: 1px solid black;
  line-height: 20px;
  white-space:normal;
  font-size: 12px;
}
#Result{
  margin-left: 2.5mm;
}
.ResultBody >>>.el-input__inner{
  font-size: 12px;
}
.el-popover__reference-wrapper {
  margin-left: 10px;
}
/deep/ .el-table .cell {
  white-space: pre-line;
  padding-right: 5px;
  padding-left: 5px;
}
#DelieveryTable {
 
  margin-left: 2.5mm;
}
#DelieveryTable>>>.cell{
  line-height: 20px;
}


.el-table::before {
  height: 0;
}
.remarkinput{
  display: inline-block !important;
  width: 50px;
  border:none;
  border-bottom: 1px solid #000;
}
.remarkinput >>>.el-input__inner{
  border:none !important;
  height: 20px;
  padding: 0;
  text-align: center;
  color:#000
}
@page {
  size: 20.5cm 29.7cm;
  margin: 0;
}
@media print {
}
.bottomInfo {
  margin-top: 2.2mm;
  font-size: 2.5mm;
  margin-bottom: 0.1mm;
  margin-left: 2mm;
}
.el-table{
  color:#000
}
.my-date-picker >>> .el-input__inner {
  border-left: none;  /* 隐藏边框 */
  border-top: none;  /* 隐藏边框 */
  color:#000;
  border-right: none;  /* 隐藏边框 */
  padding-left: 0;  /* 通过设置 padding-left 值来调整组件的布局 */
}
.my-date-picker >>> .el-input__icon {
  display: none;  /* 隐藏图标 */
}
.sizesmall {
  height: 4mm;
}
.inlineblock {
  display: inline-block;
}
.check-input {
  background-color: black;
  position: relative;
  top: 1mm;
  left: 1mm;
}
.bottomInfo {
  margin-top: 2.2mm;
  font-size: 2.5mm;
  margin-bottom: 0.1mm;
}
.area_right {
  position: absolute;
  left: 3mm;
  top: 0px;
  /* float: right; */
  margin-top:30px;
  margin-bottom: 10px;
}
.title-right {
  width: 30mm;
  font-size: 15px;
  display: inline-block;
}
.writeInput {
  display: inline-block;
  width: 30mm;
  height: 5.5mm;
  border-bottom: 0.5px solid #000;
}
.bottom-input >>> .el-input__inner {
  padding-right: 100px;
  border: 0;
  height: 4mm;
  padding: 0;
  margin: 0px;
  font-size: 2.5mm;
  color: #000;
}
.relative {
  position: relative;
}
.top{
  top:12mm;
  /* margin-top:10px; */
}
</style>
