import request from '@/utils/request'
export function GetAllProducts(data) {
    return request({
      url: '/Api/mesproductinfo/GetAllProducts',
      method: 'post',
      data
    })
  }
  export function getGroup(query) {
    return request({
      url: '/Api/mesproductinfo/getGroup',
      method: 'get',
      params: query
    })
  }
  export function CreativeProduct(data) {
    return request({
      url: '/Api/mesproductinfo/CreativeProduct',
      method: 'post',
      data
    })
  }
  export function UpdateHw(data) {
    return request({
      url: '/Api/mesproductinfo/UpdateHw',
      method: 'post',
      data
    })
  }
  export function Updatebom(data) {
    return request({
      url: '/Api/mesproductinfo/Updatebom',
      method: 'post',
      data
    })
  }
  export function creativePlant(data) {
    return request({
      url: '/Api/mesplant/creativePlant',
      method: 'post',
      data
    })
  }
  export function GetAllPlant(data) {
    return request({
      url: '/Api/mesplant/GetAllPlant',
      method: 'post',
      data
    })
  }
  export function updateProductinfo(data) {
    return request({
      url: '/Api/mesproductinfo/updateProductinfo',
      method: 'post',
      data
    })
  }

  export function UpdatePlant(data) {
    return request({
      url: '/Api/mesplant/UpdatePlant',
      method: 'post',
      data
    })
  }

