var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        { staticClass: "mycard" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.onCreativeCompany },
            },
            [_vm._v(" 新建公司 ")]
          ),
        ],
        1
      ),
      _c("el-card", { staticClass: "mycard cloumn" }, [
        _c(
          "div",
          [
            _c(
              "el-tabs",
              {
                attrs: { type: "card" },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _c("el-tab-pane", {
                  attrs: { label: "公司配置", name: "company" },
                }),
                _c("el-tab-pane", {
                  attrs: { label: "人员管理", name: "person" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeName == "company",
                expression: "activeName == 'company'",
              },
            ],
            attrs: { id: "companySetting" },
          },
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _vm.companyList },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "name",
                    label: "公司名称",
                    "min-width": "180",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "companyCode",
                    label: "认证字符",
                    "min-width": "180",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: { label: "部门分类", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.DepartmentList)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "操作", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.OnAddDepartment(scope.row)
                                },
                              },
                            },
                            [_vm._v("添加部门")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeName == "person",
                expression: "activeName == 'person'",
              },
            ],
            attrs: { id: "personSetting" },
          },
          [
            _c(
              "el-table",
              { staticStyle: { width: "100%" }, attrs: { data: _vm.UserList } },
              [
                _c("el-table-column", {
                  attrs: { label: "姓名", "min-width": "180", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row.Name
                                  ? scope.row.Name
                                  : scope.row.RegInfo.Name
                              )
                            ),
                          ]),
                          !scope.row.Name
                            ? _c(
                                "el-tag",
                                { staticStyle: { "margin-left": "20px" } },
                                [_vm._v("申请中")]
                              )
                            : _vm._e(),
                          scope.row.isAdmin
                            ? _c(
                                "el-tag",
                                {
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: { type: "success" },
                                },
                                [_vm._v("admin")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "公司", "min-width": "180", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row.Name
                                  ? scope.row.companyCode
                                  : scope.row.RegInfo.companyCode
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "部门", "min-width": "180", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row.Name
                                  ? scope.row.Department
                                  : scope.row.RegInfo.Department
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "电话", "min-width": "180", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row.Name
                                  ? scope.row.Phone
                                  : scope.row.RegInfo.Phone
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "操作", "min-width": "180", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          !scope.row.Name
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.OnConfirmReg(
                                            scope.row,
                                            "true"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("同意")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "danger", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.OnConfirmReg(
                                            scope.row,
                                            "false"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("拒绝")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          scope.row.Name
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.ChangeDepartment(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("部门迁移")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "danger", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.DeleteAUser(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.addCompanyDialogVisible, title: "添加客户" },
          on: {
            "update:visible": function ($event) {
              _vm.addCompanyDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.NewCompany },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "输入名称" },
                    model: {
                      value: _vm.NewCompany.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.NewCompany, "name", $$v)
                      },
                      expression: "NewCompany.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "认证字符" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "输入认证字符" },
                    model: {
                      value: _vm.NewCompany.companyCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.NewCompany, "companyCode", $$v)
                      },
                      expression: "NewCompany.companyCode",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addCompanyDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.OnConfirmAddCompany },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "部门迁移",
            visible: _vm.DepartmentChangeVisiable,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.DepartmentChangeVisiable = $event
            },
          },
        },
        [
          _c("h4", [_vm._v("选择部门")]),
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.SelectUser.Department,
                callback: function ($$v) {
                  _vm.$set(_vm.SelectUser, "Department", $$v)
                },
                expression: "SelectUser.Department",
              },
            },
            _vm._l(_vm.selectCompany.DepartmentList, function (item) {
              return _c("el-option", {
                key: item,
                attrs: { label: item, value: item },
              })
            }),
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.DepartmentChangeVisiable = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.ComfirmUpdateDepartment },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }