var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "pull-right right_10px top_10px" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.addSuit } },
          [_vm._v("添加")]
        ),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.suits,
              "default-sort": {
                prop: "SigmatekOrderNumber",
                order: "ascending",
              },
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "index",
                label: "序号",
                width: "50",
                align: "left",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "suitName", label: "套装名称", align: "left" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "suitName_English",
                label: "英文名称",
                align: "left",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "orderNumber",
                label: "客户订货号",
                width: "180",
                align: "left",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "SigmatekOrderNumber",
                label: "Sigmatek订货号",
                width: "180",
                align: "left",
              },
            }),
            _c("el-table-column", {
              attrs: { label: "操作", width: "180" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.editSuit(scope.row)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "danger" },
                          on: {
                            click: function ($event) {
                              return _vm.DeleteSuit(scope.row._id)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "el-dialog",
          {
            attrs: {
              title: "添加套装",
              visible: _vm.addSuitVisialbe,
              width: "50%",
            },
            on: {
              "update:visible": function ($event) {
                _vm.addSuitVisialbe = $event
              },
            },
          },
          [
            _c(
              "el-form",
              {
                ref: "addSuitForm",
                attrs: { model: _vm.SuitInfo, "label-width": "150px" },
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "suitName",
                      label: "套装名",
                      rules: [
                        {
                          required: true,
                          message: "请输入套装名",
                          trigger: "blur",
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.SuitInfo.suitName,
                        callback: function ($$v) {
                          _vm.$set(_vm.SuitInfo, "suitName", $$v)
                        },
                        expression: "SuitInfo.suitName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "suitName_English",
                      label: "英文套装名",
                      rules: [
                        {
                          required: true,
                          message: "请输入套装名",
                          trigger: "blur",
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.SuitInfo.suitName_English,
                        callback: function ($$v) {
                          _vm.$set(_vm.SuitInfo, "suitName_English", $$v)
                        },
                        expression: "SuitInfo.suitName_English",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "orderNumber",
                      label: "客户订货号",
                      rules: [
                        {
                          required: true,
                          message: "请输入订货号",
                          trigger: "blur",
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.SuitInfo.orderNumber,
                        callback: function ($$v) {
                          _vm.$set(_vm.SuitInfo, "orderNumber", $$v)
                        },
                        expression: "SuitInfo.orderNumber",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "SigmatekOrderNumber",
                      label: "Sigmatek订货号",
                      rules: [
                        {
                          required: true,
                          message: "请输入订货号",
                          trigger: "blur",
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.SuitInfo.SigmatekOrderNumber,
                        callback: function ($$v) {
                          _vm.$set(_vm.SuitInfo, "SigmatekOrderNumber", $$v)
                        },
                        expression: "SuitInfo.SigmatekOrderNumber",
                      },
                    }),
                  ],
                  1
                ),
                _vm._l(_vm.SuitInfo.products, function (product, index) {
                  return _c(
                    "el-form-item",
                    {
                      key: index,
                      attrs: { label: "产品" + index, prop: product.productid },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: product.productid,
                            callback: function ($$v) {
                              _vm.$set(product, "productid", $$v)
                            },
                            expression: "product.productid",
                          },
                        },
                        _vm._l(_vm.Allproducts, function (item) {
                          return _c("el-option", {
                            key: item._id,
                            attrs: { label: item.name, value: item._id },
                          })
                        }),
                        1
                      ),
                      _c(
                        "span",
                        { staticClass: "countinputer" },
                        [
                          _c("el-input", {
                            model: {
                              value: product.count,
                              callback: function ($$v) {
                                _vm.$set(product, "count", $$v)
                              },
                              expression: "product.count",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.deleteProduct(index)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.addSuitVisialbe = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _c("el-button", { on: { click: _vm.addproduct } }, [
                  _vm._v("新增产品"),
                ]),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.submitForm()
                      },
                    },
                  },
                  [_vm._v("保 存")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }