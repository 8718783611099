import request from '@/utils/request'

export function createshipping(data) {
  return request({
    url: '/Api/shipping/createshipping',
    method: 'post',
    data
  })
}
export function getshippinglist(params) {
  return request({
    url: '/Api/shipping/getshippinglist',
    method: 'post',
    params
  })
}
export function update(data) {
  return request({
    url: '/Api/shipping/update',
    method: 'post',
    data
  })
}
export function deleteShipping(id) {
  return request({
    url: '/Api/shipping/delete',
    method: 'delete',
    params: id
  })
}