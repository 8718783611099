var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "outbox" }, [
    _c(
      "div",
      { staticClass: "head" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.creatShipping } },
          [_vm._v("新建")]
        ),
        _c("el-input", {
          staticClass: "SearchInput",
          attrs: { placeholder: "按照序列号搜索" },
          on: { change: _vm.SearchChange },
          model: {
            value: _vm.SearchNr,
            callback: function ($$v) {
              _vm.SearchNr = $$v
            },
            expression: "SearchNr",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "table" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: { data: _vm.tableData, border: "" },
          },
          [
            _c("el-table-column", {
              staticStyle: { width: "15%" },
              attrs: { prop: "date", label: "日期", align: "center" },
            }),
            _c("el-table-column", {
              staticStyle: { width: "15%" },
              attrs: { prop: "NoteNr", label: "单号", align: "center" },
            }),
            _c("el-table-column", {
              staticStyle: { width: "40%" },
              attrs: { prop: "customer", label: "采购商", align: "center" },
            }),
            _c("el-table-column", {
              staticStyle: { width: "30%" },
              attrs: { label: "操作", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.editunit(scope.$index)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                      _c(
                        "el-popover",
                        {
                          attrs: { placement: "top", width: "160" },
                          model: {
                            value: scope.row.DeleteConfirmVisiable,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "DeleteConfirmVisiable", $$v)
                            },
                            expression: "scope.row.DeleteConfirmVisiable",
                          },
                        },
                        [
                          _c("p", [_vm._v("确认删除？")]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "right",
                                margin: "0",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "text" },
                                  on: {
                                    click: function ($event) {
                                      scope.row.DeleteConfirmVisiable = false
                                    },
                                  },
                                },
                                [_vm._v("取消")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      ;(scope.row.DeleteConfirmVisiable = false),
                                        _vm.deliteunit(scope.row._id)
                                    },
                                  },
                                },
                                [_vm._v("确定")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-button",
                            { attrs: { slot: "reference" }, slot: "reference" },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "pagination" },
      [
        _c("el-pagination", {
          attrs: {
            background: "",
            layout: "prev, pager, next",
            total: _vm.total,
          },
          on: { "current-change": _vm.handleCurrentChange },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }