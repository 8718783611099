var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.type == "comment"
      ? _c("div", [
          _c(
            "table",
            { attrs: { border: "1", cellspacing: "0", id: "repair-list" } },
            [
              _vm._l(_vm.commentAct.commentText, function (item, index) {
                return _c("tr", { key: index, attrs: { height: "30" } }, [
                  _c(
                    "td",
                    {
                      staticClass: "delete",
                      on: {
                        click: function ($event) {
                          return _vm.remove(index)
                        },
                      },
                    },
                    [_vm._v(_vm._s(index + 1))]
                  ),
                  _c(
                    "td",
                    [
                      _c("el-input", {
                        staticClass: "edit-input",
                        attrs: { type: "text", size: "small" },
                        model: {
                          value: item.data,
                          callback: function ($$v) {
                            _vm.$set(item, "data", $$v)
                          },
                          expression: "item.data",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              }),
              _c("tr", { attrs: { height: "30" } }, [
                _c(
                  "td",
                  {
                    staticClass: "create",
                    attrs: { width: "40" },
                    on: { click: _vm.creatNew },
                  },
                  [_vm._v("...")]
                ),
                _c("td", { attrs: { width: "860" } }),
              ]),
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "button",
                  attrs: { type: "primary" },
                  on: { click: _vm.saveComment },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.type == "ups"
      ? _c("div", [
          _c("table", [
            _c("tr", [
              _c(
                "td",
                [
                  _c("p", [_vm._v("送出方式")]),
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.upsAct.sendType,
                        callback: function ($$v) {
                          _vm.$set(_vm.upsAct, "sendType", $$v)
                        },
                        expression: "upsAct.sendType",
                      },
                    },
                    _vm._l(_vm.upsSend, function (item) {
                      return _c("el-option", {
                        key: item.jobId,
                        attrs: { label: item.sendType, value: item.sendType },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "td",
                [
                  _c("p", [_vm._v("送出时间")]),
                  _c("el-input", {
                    staticClass: "bottom-input",
                    attrs: { type: "date", size: "small" },
                    model: {
                      value: _vm.upsAct.sendDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.upsAct, "sendDate", $$v)
                      },
                      expression: "upsAct.sendDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "td",
                [
                  _c("p", [_vm._v("返回方式")]),
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.upsAct.backType,
                        callback: function ($$v) {
                          _vm.$set(_vm.upsAct, "backType", $$v)
                        },
                        expression: "upsAct.backType",
                      },
                    },
                    _vm._l(_vm.upsBack, function (item) {
                      return _c("el-option", {
                        key: item.jobId,
                        attrs: { label: item.sendType, value: item.sendType },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "td",
                [
                  _c("p", [_vm._v("返回时间")]),
                  _c("el-input", {
                    staticClass: "bottom-input",
                    attrs: { type: "date", size: "small" },
                    model: {
                      value: _vm.upsAct.backDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.upsAct, "backDate", $$v)
                      },
                      expression: "upsAct.backDate",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c(
                "td",
                { attrs: { colspan: "4" } },
                [
                  _c("p", [_vm._v("ups单号")]),
                  _c("el-input", {
                    staticClass: "bottom-input",
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.upsAct.upsOrder,
                      callback: function ($$v) {
                        _vm.$set(_vm.upsAct, "upsOrder", $$v)
                      },
                      expression: "upsAct.upsOrder",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c(
                "td",
                { attrs: { colspan: "4" } },
                [
                  _c("p", [_vm._v("备注")]),
                  _c("el-input", {
                    staticClass: "bottom-input",
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.upsAct.comment,
                      callback: function ($$v) {
                        _vm.$set(_vm.upsAct, "comment", $$v)
                      },
                      expression: "upsAct.comment",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "button",
                  attrs: { type: "primary" },
                  on: { click: _vm.saveUps },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.type == "extra"
      ? _c(
          "div",
          [
            _c(
              "el-upload",
              {
                attrs: {
                  action: "''",
                  multiple: "",
                  "list-type": "file-card",
                  accept:
                    ".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PBG, .GIF, .pdf",
                  "before-upload": _vm.beforeUpload,
                  "file-list": _vm.fileList,
                  "http-request": _vm.submitList,
                },
              },
              [
                _c("span", [
                  _c("i", {
                    staticClass: "el-icon-plus",
                    staticStyle: { "font-size": "14px" },
                  }),
                  _vm._v(" 添加文件"),
                ]),
              ]
            ),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                staticClass: "marginTop",
                attrs: {
                  data: _vm.list,
                  "element-loading-text": "Loading",
                  border: "",
                  fit: "",
                  "highlight-current-row": "",
                },
                on: { "row-click": _vm.annexPreview },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "created_at",
                    label: "文件列表",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.filenamereplace(scope.row.fileName))
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    4200282507
                  ),
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "操作", width: "250" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadFile(scope.row)
                                  },
                                },
                              },
                              [_vm._v("下载")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "gray" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteFile(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1424521140
                  ),
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.type == "supplier" ? _c("div") : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }