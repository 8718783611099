<template>
  <div class="app-container">
    <el-button type="primary" v-on:click="showInfo(null)">新建</el-button>

    <el-autocomplete
      style="margin-left: 20px; width: 400px"
      v-model="SearchInput"
      :fetch-suggestions="fetchSuggestions"
      placeholder="输入公司名或负责人进行搜索"
      :trigger-on-focus="false"
      @select="fetchData"
      @change="fetchData"
    ></el-autocomplete>

    <el-dialog
      :visible.sync="showCustomerInfo"
      @close="ExitAndDontSave"
      width="1200px"
      title="公司信息"
    >
      <el-form label-width="130px" label-position="left">
        <el-form-item label="公司">
          <el-input v-model="customerInfo.company"></el-input>
        </el-form-item>
        <el-form-item label="公司英文">
          <el-input v-model="customerInfo.companyEn"></el-input>
        </el-form-item>
        <el-form-item label="公司地址">
          <el-input v-model="customerInfo.add"></el-input>
        </el-form-item>
        <el-form-item label="传真">
          <el-input v-model="customerInfo.fax"></el-input>
        </el-form-item>
        <el-form-item label="保修月数(单位/月)">
          <el-input v-model="customerInfo.WarrantyDays"></el-input>
        </el-form-item>
        <el-form-item label="单号后缀代号">
          <el-input v-model="customerInfo.OrderNumberAbbr"></el-input>
        </el-form-item>
      </el-form>
      <el-form label-width="130px" label-position="top">
        <el-form-item label="负责人信息">
          <el-table :data="customerInfo.contacts" style="width: 100%">
            <el-table-column label="负责人" width="150" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.Name"></el-input>
              </template>
            </el-table-column>

            <el-table-column label="电话" width="200" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.Tel"></el-input>
              </template>
            </el-table-column>

            <el-table-column label="单独地址" width="500" align="center">
              <template slot-scope="scope">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 1 }"
                  v-model="scope.row.SeparateAddress"
                ></el-input>
              </template>
            </el-table-column>

            <el-table-column label="备注" width="200" align="center">
              <template slot-scope="scope">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 1 }"
                  v-model="scope.row.Notes"
                ></el-input>
              </template>
            </el-table-column>

            <el-table-column label="删除" min-width="50" align="center">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  size="small"
                  circle
                  @click="remove(scope.$index)"
                  icon="el-icon-close"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="buttonadd">
            <el-button
              type="primary"
              icon="el-icon-plus"
              circle
              @click="creatNew"
            ></el-button>
          </div>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-on:click="PreferencesCheck">保存</el-button>
        <el-button type="normal" v-on:click="ExitAndDontSave">取消</el-button>
      </span>
    </el-dialog>

    <el-table
      v-loading="listLoading"
      :data="list_AfterSearch"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      style="margin-top: 10px"
      class="ContainerTable"
    >
      <el-table-column type="expand" width="50">
        <template slot-scope="props">
          <el-table
            :data="props.row.contacts"
            style="
              width: calc(100% - 295px);
              margin-left: 295px;
              border-left: 1px solid #ebeef5;
            "
          >
            <el-table-column label="设为首选" width="55" align="center">
              <template slot-scope="scope">
                <div @click="PreferencesChange(scope)">
                  <i v-if="!scope.row.Preferences" class="el-icon-star-off" />
                  <i v-else class="el-icon-star-on" />
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="Name"
              label="负责人"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="Tel" label="电话" width="180" align="center">
            </el-table-column>
            <el-table-column
              prop="SeparateAddress"
              label="单独地址/收货地址"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="Notes"
              label="备注"
              width="400"
              align="center"
            >
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>

      <el-table-column label="公司" width="300">
        <template slot-scope="scope">
          {{ scope.row.company }}
        </template>
      </el-table-column>

      <el-table-column label="公司英文" width="300">
        <template slot-scope="scope">
          {{ scope.row.companyEn }}
        </template>
      </el-table-column>

      <el-table-column align="center" prop="created_at" label="公司地址">
        <template slot-scope="scope">
          <span>{{ scope.row.add }}</span>
        </template>
      </el-table-column>

      <el-table-column label="保修月数" align="center" width="55">
        <template slot-scope="scope">
          {{ scope.row.WarrantyDays }}
        </template>
      </el-table-column>

      <el-table-column label="传真" align="center" width="150">
        <template slot-scope="scope">
          {{ scope.row.fax }}
        </template>
      </el-table-column>

      <el-table-column align="center" label="操作" width="195">
        <template slot-scope="scope">
          <el-button v-on:click="showInfo(scope.row)" type="button"
            >编辑</el-button
          >
          <el-button v-on:click="showDeleteInfo(scope.row)" type="button"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="showDelete" title="删除">
      <h4>确定删除</h4>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-on:click="deleteInfo">确定</el-button>
        <el-button type="normal" v-on:click="showDelete = false"
          >取消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getList } from "@/api/table";
import { MessageBox, Message } from "element-ui";
import Pagination from "@/components/Pagination";
import {
  fetchList,
  fetchArticle,
  fetchPv,
  createArticle,
  updateArticle,
  deleteArticle,
  getCustomerList,
  createCustomerList,
  updateCustomerList,
  deleteCustomerList,
} from "@/api/article";
export default {
  components: { Pagination },
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: "success",
        draft: "gray",
        deleted: "danger",
      };
      return statusMap[status];
    },
  },
  data() {
    return {
      list: [
        {
          company: null,
          fax: null,
          add: null,
          WarrantyDays: null,
          contacts: [
            {
              Name: null,
              Tel: null,
              SeparateAddress: null,
              Notes: null,
              Preferences: false,
            },
          ],
          WarrantyDays: null,
          OrderNumberAbbr: null,
        },
      ],

      list_AfterSearch: [
        {
          company: null,
          fax: null,
          add: null,
          WarrantyDays: null,
          contacts: [
            {
              Name: null,
              Tel: null,
              SeparateAddress: null,
              Notes: null,
              Preferences: false,
            },
          ],
          WarrantyDays: null,
          OrderNumberAbbr: null,
        },
      ],

      SearchInput: null,
      showCustomerInfo: false,
      showDelete: false,

      customerInfo: {
        company: null,
        fax: null,
        add: null,
        WarrantyDays: null,
        contacts: [
          {
            Name: null,
            Tel: null,
            SeparateAddress: null,
            Notes: null,
            Preferences: false,
          },
        ],
        WarrantyDays: null,
        OrderNumberAbbr: null,
      },

      listLoading: true,
      total: 40,
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      this.listLoading = true;
      const { data } = await getCustomerList();
      this.list = data.list;

      if (this.SearchInput === null || this.SearchInput === "") {
        this.list_AfterSearch = this.list;
      } else {
        //(this.SearchInput);
        const queryString = this.SearchInput.toLowerCase().trim();
        this.list_AfterSearch = this.list.filter((info) => {
          return (
            (info.company &&
              info.company.toLowerCase().includes(queryString)) ||
            info.contacts.some((contact) => {
              return (
                contact.Name && contact.Name.toLowerCase().includes(queryString)
              );
            })
          );
        });
      }

      setTimeout(() => {
        this.listLoading = false;
      }, 0.5 * 1000);
    },

    showInfo(data) {
      this.showCustomerInfo = true;
      if (data == null) {
        this.customerInfo = {
          company: null,
          fax: null,
          add: null,
          WarrantyDays: null,
          contacts: [
            {
              Name: null,
              Tel: null,
              SeparateAddress: null,
              Notes: null,
              Preferences: false,
            },
          ],
          WarrantyDays: null,
          OrderNumberAbbr: null,
        };
      } else {
        this.customerInfo = data;
      }
    },

    async saveInfo() {
      if (this.customerInfo._id == null) {
        const { data } = await createCustomerList(this.customerInfo);
        if (data) {
          Message({
            message: "创建成功",
            type: "success",
            duration: 5 * 1000,
          });
        }
      } else {
        const { data } = await updateCustomerList({
          id: this.customerInfo._id,
          data: this.customerInfo,
        });
      }
      this.showCustomerInfo = false;
      this.fetchData();
    },

    showDeleteInfo(data) {
      this.customerInfo._id = data._id;
      this.showDelete = true;
    },

    async deleteInfo() {
      const { data } = await deleteCustomerList({ id: this.customerInfo._id });
      //   var a =data ;
      if (data) {
        Message({
          message: "删除成功",
          type: "success",
          duration: 5 * 1000,
        });
      }

      this.showDelete = false;
      this.fetchData();
    },
    ExitAndDontSave() {
      this.showCustomerInfo = false;
      this.fetchData();
    }, //取消编辑函数（不保存+刷新数据+关闭窗口）

    remove(index) {
      this.customerInfo.contacts.splice(index, 1);
    }, //编辑界面删除负责人
    creatNew() {
      this.customerInfo.contacts.push({
        Name: null,
        Tel: null,
        SeparateAddress: null,
        Notes: null,
        Preferences: false,
      });
    }, //编辑界面新增负责人

    async PreferencesChange(row) {
      // 如果点击了旧的 Preferences，则不会有反应
      if (row.row.Preferences) {
        return;
      } else {
        const foundCustomerInfo = this.list.find((info) => {
          return info.contacts.some((contact) => contact.Name === row.row.Name);
        });
        foundCustomerInfo.contacts.forEach((contact) => {
          contact.Preferences = contact.Name === row.row.Name;
        });
        const { data } = await updateCustomerList({
          id: foundCustomerInfo._id,
          data: {
            company: foundCustomerInfo.company,
            companyEn: foundCustomerInfo.companyEn,
            add: foundCustomerInfo.add,
            WarrantyDays: foundCustomerInfo.WarrantyDays,
            OrderNumberAbbr: foundCustomerInfo.OrderNumberAbbr,
            contacts: foundCustomerInfo.contacts,
          },
        });
      }
    }, //更改首选负责人

    PreferencesCheck() {
      var allFalse = this.customerInfo.contacts.every(function(contact) {
        return contact.Preferences === false;
      });
      if (allFalse) {
        this.customerInfo.contacts[0].Preferences = true;
      }
      this.saveInfo();
    },

    fetchSuggestions(queryString, callback) {
      const suggestions = [];
      // 根据用户输入的 queryString 进行匹配
      const matchedCustomerInfo = this.list.filter((info) => {
        return (
          info.company &&
          info.company.toLowerCase().includes(queryString.toLowerCase())
        );
      });
      const matchedContacts = this.list.reduce((acc, info) => {
        const matched = info.contacts.filter((contact) => {
          return (
            contact.Name &&
            contact.Name.toLowerCase().includes(queryString.toLowerCase())
          );
        });
        return acc.concat(matched);
      }, []);
      // 将匹配的结果添加到建议列表中
      matchedCustomerInfo.forEach((info) => {
        suggestions.push({ value: info.company, type: "company" });
      });
      matchedContacts.forEach((contact) => {
        suggestions.push({ value: contact.Name, type: "contact" });
      });
      // 将建议列表传递给回调函数
      callback(suggestions);
    },
  },
};
</script>
<style scoped>
span {
  display: inline-block;
}
td >>> .el-input__inner {
  width: 400px;
}
#filterSelect {
  position: absolute;
  top: 20px;
  left: 30px;
  width: 80%;
  height: 800px;
  background: white;
}
.app-container {
  position: relative;
}
p {
  margin-top: 7px;
  margin-bottom: 7px;
}
.ContainerTable >>> .el-table__cell.el-table__expanded-cell {
  padding-top: 0px !important;
}
.buttonadd {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 10px;
}
</style>
