import request from '@/utils/request'
export function download(params) {
    return request({
      url: '/Api/file/download',
      method: 'get',
      params,
      responseType: "blob"
    })
  }
  export function deleteFile(data) {
    return request({
      url: '/Api/file/deleteFile',
      method: 'delete',
      params: data
    })
  }
  export function uploadExtra(data, id) {
    return request({
      url: '/Api/file/upload',
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: data,
      params: id
    })
  }
