import request from "@/utils/request";

export function GetProductOrderSize(data) {
  return request({
    url: "/Api/Mes/GetProductOrderSize",
    method: "post",
    data
  });
}
export function GetProductOrders(data) {
  return request({
    url: "/Api/Mes/GetProductOrders",
    method: "post",
    data
  });
}
export function CreateProductOrder(data) {
  return request({
    url: "/Api/Mes/CreateProductOrder",
    method: "post",
    data
  });
}

export function getTotalDoneNumber(data) {
  return request({
    url: "/Api/Mes/getTotalDoneNumber",
    method: "post",
    data
  });
}
export function getTypeGroup(data) {
  return request({
    url: "/Api/Mes/getTypeGroup",
    method: "post",
    data
  });
}
export function UpdateProductOrder(data) {
  return request({
    url: "/Api/Mes/UpdateProductOrder",
    method: "post",
    data
  });
}
export function DeleteProductOrder(id) {
  return request({
    url: "/Api/Mes/DeleteProductOrder",
    method: "delete",
    params: id
  });
}
export function GetProductOrderNumbyId(data) {
  return request({
    url: "/Api/Mes/GetProductOrderNumbyId",
    method: "post",
    data
  });
}
export function bulkSave(data) {
  return request({
    url: "/Api/Mes/bulkSave",
    method: "post",
    data
  });
}

export function getMaterialList(params) {
  return request({
    url: '/Api/Mes/MaterialInfo',
    method: 'get',
    params
  })
}
export function getMaterialListByid(params) {
  return request({
    url: '/Api/Mes/getMaterialListByid',
    method: 'post',
    params
  })
}
export function getMaterialListBynumber(params) {
  return request({
    url: '/Api/Mes/getMaterialListBynumber',
    method: 'post',
    params
  })
}

export function createMaterialList(data) {
  return request({
    url: '/Api/Mes/createMaterialInfo',
    method: 'post',
    data
  })
}
export function updateMaterialList(data) {
  return request({
    url: '/Api/Mes/updateMaterialInfo',
    method: 'post',
    data
  })
}
export function deleteMaterialList(id) {
  return request({
    url: '/Api/Mes/deleteMaterialInfo',
    method: 'delete',
    params: id
  })
}

export function GetStorageOrderSize(data) {
  return request({
    url: "/Api/Mes/GetStorageOrderSize",
    method: "post",
    data
  });
}
export function GetStorageOrders(data) {
  return request({
    url: "/Api/Mes/GetStorageOrders",
    method: "post",
    data
  });
}
export function CreateStorageOrder(data) {
  return request({
    url: "/Api/Mes/CreateStorageOrder",
    method: "post",
    data
  });
}
export function UpdateStorageOrder(data) {
  return request({
    url: "/Api/Mes/UpdateStorageOrder",
    method: "post",
    data
  });
}
export function DeleteStorageOrder(id) {
  return request({
    url: "/Api/Mes/DeleteStorageOrder",
    method: "delete",
    params: id
  });
}

export function GetStorageOrderNumbyId(data) {
  return request({
    url: "/Api/Mes/GetStorageOrderNumbyId",
    method: "post",
    data
  });
}

export function GetMeslistSize(data) {
  return request({
    url: "/Api/Mes/GetMeslistSize",
    method: "post",
    data
  });
}

export function GetMeslist(data) {
  return request({
    url: "/Api/Mes/GetMeslist",
    method: "post",
    data
  });
}

export function UpdateMeslist(data) {
  return request({
    url: "/Api/Mes/UpdateMeslist",
    method: "post",
    data
  });
}

export function DeleteMeslist(id) {
  return request({
    url: "/Api/Mes/DeleteMeslist",
    method: "delete",
    params: id
  });
}

export function GetProductSize(data) {
  return request({
    url: "/Api/Mes/GetProductSize",
    method: "post",
    data
  });
}
export function GetProducts(data) {
  return request({
    url: "/Api/Mes/GetProducts",
    method: "post",
    data
  });
}
export function GetImages(data) {
  return request({
    url: "/Api/Mes/GetImages",
    method: "post",
    data
  });
}
export function Outstorage(data) {
  return request({
    url: "/Api/Mes/Outstorage",
    method: "post",
    data
  });
}


export function GetOverviewData(data) {
  return request({
    url: "/Api/Mes/GetOverviewData",
    method: "post",
    data
  });
}
export function PositioningOrders(data) {
  return request({
    url: "/Api/Mes/PositioningOrders",
    method: "post",
    data
  });
}
export function GetCountBySerialNumber(data) {
  return request({
    url: '/Api/Mes/GetCountBySerialNumber',
    method: 'post',
    data
  })
}

export function GetMesproductBySerialNumber(data) {
  return request({
    url: '/Api/Mes/GetMesproductBySerialNumber',
    method: 'post',
    data
  })
}
export function UpdateMesproductId(data) {
  return request({
    url: '/Api/Mes/UpdateMesproductId',
    method: 'post',
    data
  })
}












