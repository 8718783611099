var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "项目名称修改",
            visible: _vm.changeProjectNamevisialbe,
            width: "30%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.changeProjectNamevisialbe = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "margin-left": "5px" } }, [
            _vm._v("修改名称："),
          ]),
          _c("el-input", {
            staticStyle: { "margin-top": "10px", "margin-bottom": "10px" },
            model: {
              value: _vm.editProject.name,
              callback: function ($$v) {
                _vm.$set(_vm.editProject, "name", $$v)
              },
              expression: "editProject.name",
            },
          }),
          _c("div", { staticStyle: { "margin-left": "5px" } }, [
            _vm._v("项目代码："),
          ]),
          _c("el-input", {
            staticStyle: { "margin-top": "10px", "margin-bottom": "10px" },
            model: {
              value: _vm.editProject.ProjectCode,
              callback: function ($$v) {
                _vm.$set(_vm.editProject, "ProjectCode", $$v)
              },
              expression: "editProject.ProjectCode",
            },
          }),
          _c(
            "div",
            { staticStyle: { "margin-left": "5px", "margin-bottom": "10px" } },
            [_vm._v("修改备注：")]
          ),
          _c("el-input", {
            attrs: {
              type: "textarea",
              autosize: { minRows: 2 },
              placeholder: "请输入内容",
            },
            model: {
              value: _vm.editProject.ProjectRemarks,
              callback: function ($$v) {
                _vm.$set(_vm.editProject, "ProjectRemarks", $$v)
              },
              expression: "editProject.ProjectRemarks",
            },
          }),
          _c("div", [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                staticStyle: { "margin-top": "10px" },
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "div",
                  { staticClass: "buttongroup" },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.changeProjectNamevisialbe = false
                          },
                        },
                      },
                      [_vm._v(" 取 消 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.confirmchange },
                      },
                      [_vm._v(" 确 定 ")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "项目删除",
            visible: _vm.deleteProjectvisialbe,
            width: "30%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.deleteProjectvisialbe = $event
            },
          },
        },
        [
          _vm.isOwner()
            ? _c("div", { staticClass: "notes" }, [_vm._v("删除项目")])
            : _vm._e(),
          _vm.isMember()
            ? _c("div", { staticClass: "notes" }, [_vm._v("退出项目")])
            : _vm._e(),
          _c("div", [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "div",
                  { staticClass: "buttongroup" },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.deleteProjectvisialbe = false
                          },
                        },
                      },
                      [_vm._v(" 取 消 ")]
                    ),
                    _vm.isOwner()
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: { click: _vm.confirmDelete },
                          },
                          [_vm._v(" 确 定 ")]
                        )
                      : _vm._e(),
                    _vm.isMember()
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: { click: _vm.confirmQuit },
                          },
                          [_vm._v(" 确 定 ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "项目共享",
            visible: _vm.memberInfovisialbe,
            width: "30%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.memberInfovisialbe = $event
            },
          },
        },
        [
          _c("div", { staticClass: "notes" }, [_vm._v("添加成员")]),
          _c(
            "el-select",
            {
              staticStyle: { width: "80%", "margin-top": "20px" },
              attrs: { placeholder: "分配给" },
              model: {
                value: _vm.newUser,
                callback: function ($$v) {
                  _vm.newUser = $$v
                },
                expression: "newUser",
              },
            },
            [
              _c("template", { slot: "prefix" }, [
                _c(
                  "span",
                  {
                    staticStyle: {
                      padding: "5px",
                      "line-height": "33px",
                      "font-size": "18px",
                      color: "#409eff",
                    },
                  },
                  [_c("i", { staticClass: "el-icon-user-solid" })]
                ),
              ]),
              _vm._l(_vm.Userlist, function (item) {
                return _c(
                  "el-option",
                  { key: item.username, attrs: { value: item.username } },
                  [
                    _c("span", { staticStyle: { float: "left" } }, [
                      _c("i", { staticClass: "el-icon-user-solid" }),
                      _vm._v(_vm._s(item.username)),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
          _c("el-button", {
            staticStyle: { width: "19%" },
            attrs: { icon: "el-icon-circle-plus" },
            on: { click: _vm.addUser },
          }),
          _c(
            "div",
            { staticClass: "notes", staticStyle: { "margin-top": "10px" } },
            [_vm._v("列表成员")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              attrs: {
                data: _vm.editProject.member,
                height: "300",
                "element-loading-text": "Loading",
                border: false,
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { width: "310", align: "left" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-avatar",
                          {
                            staticStyle: {
                              "font-size": "12px",
                              "background-color": "#409eff",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.editProject.creater) + " ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-avatar",
                          {
                            staticStyle: {
                              "font-size": "12px",
                              "background-color": "cornflowerblue",
                            },
                          },
                          [_vm._v(" " + _vm._s(scope.row) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "el-table-column",
                { attrs: { label: "所有者", width: "99", align: "center" } },
                [_vm._v(" 参与者 ")]
              ),
            ],
            1
          ),
          _c("div", [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "div",
                  { staticClass: "buttongroup" },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.memberInfovisialbe = false
                          },
                        },
                      },
                      [_vm._v(" 取 消 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.confirmMemberchange },
                      },
                      [_vm._v(" 确 定 ")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "附件",
            visible: _vm.appendixInfovisialbe,
            width: "50%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.appendixInfovisialbe = $event
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              attrs: {
                action: "''",
                multiple: "",
                accept:
                  ".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PBG, .GIF, .pdf, .lds",
                "http-request": _vm.ProjectsubmitList,
                "show-file-list": false,
              },
            },
            [
              _c("el-button", { attrs: { icon: "el-icon-paperclip" } }, [
                _vm._v("添加附件"),
              ]),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "marginTop",
              staticStyle: { width: "100%", "margin-top": "10px" },
              attrs: {
                data: _vm.actProject.file,
                "element-loading-text": "Loading",
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
              on: { "row-click": _vm.projectannexPreview },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "文件列表" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "操作", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-button", {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-download",
                            size: "mini",
                            circle: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.downloadProjectFile(scope.row)
                            },
                          },
                        }),
                        _c("el-button", {
                          attrs: {
                            type: "danger",
                            icon: "el-icon-delete",
                            size: "mini",
                            circle: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteProjectFile(scope.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "步骤删除",
            visible: _vm.deleteStepvisialbe,
            width: "30%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.deleteStepvisialbe = $event
            },
          },
        },
        [
          _c("div", { staticClass: "notes" }, [_vm._v("确定删除")]),
          _c("div", [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "div",
                  { staticClass: "buttongroup" },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.deleteStepvisialbe = false
                          },
                        },
                      },
                      [_vm._v(" 取 消 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.confirmDeleteStep },
                      },
                      [_vm._v(" 确 定 ")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "步骤编辑",
            visible: _vm.stepDetailvisialbe,
            width: "30%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.stepDetailvisialbe = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editStep",
              attrs: { model: _vm.editStep, "label-width": "80px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "步骤名称" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.editStep.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.editStep, "name", $$v)
                      },
                      expression: "editStep.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "截止时间" } },
                [
                  _c("el-date-picker", {
                    class: _vm.getSetDateStyle(_vm.editStep.endTime),
                    staticStyle: { width: "100%", "margin-top": "10px" },
                    attrs: {
                      align: "right",
                      type: "date",
                      placeholder: "添加截止日期",
                      format: "yyyy 年 MM 月 dd 日 到期",
                      "value-format": "timestamp",
                    },
                    model: {
                      value: _vm.editStep.endTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.editStep, "endTime", $$v)
                      },
                      expression: "editStep.endTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "日志" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%", "margin-top": "20px" },
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 2 },
                      placeholder: "请输入日志",
                    },
                    model: {
                      value: _vm.editStep.log,
                      callback: function ($$v) {
                        _vm.$set(_vm.editStep, "log", $$v)
                      },
                      expression: "editStep.log",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "div",
                  { staticClass: "buttongroup" },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.stepDetailvisialbe = false
                          },
                        },
                      },
                      [_vm._v(" 取 消 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.confirmEditStep },
                      },
                      [_vm._v(" 确 定 ")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "任务删除",
            visible: _vm.deleteTaskVisble,
            width: "30%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.deleteTaskVisble = $event
            },
          },
        },
        [
          _c("div", { staticClass: "notes" }, [_vm._v("确定删除")]),
          _c("div", [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "div",
                  { staticClass: "buttongroup" },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.deleteTaskVisble = false
                          },
                        },
                      },
                      [_vm._v(" 取 消 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.confirmDeleteTask },
                      },
                      [_vm._v(" 确 定 ")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "出差记录",
            visible: _vm.TravelReport,
            width: "80%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.TravelReport = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "buttongroup" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.NewInfo(null)
                    },
                  },
                },
                [_vm._v("新建行程")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.SelectReportVisible,
            title: "选择报表",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.SelectReportVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "p",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "padding-bottom": "15px",
                    "font-size": "20px",
                  },
                },
                [_vm._v("请选择需要填写或打印的报表类型")]
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          _vm._g(
                            {
                              staticClass: "card-button",
                              on: {
                                click: function ($event) {
                                  return _vm.SelectReportClick("button1")
                                },
                              },
                            },
                            _vm.$listeners
                          ),
                          [
                            _c("el-card", [
                              _c("img", {
                                staticStyle: {
                                  height: "380px",
                                  width: "100%",
                                  "object-fit": "contain",
                                },
                                attrs: {
                                  src: require("../../icons/LYJBXD.jpg"),
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    padding: "15px",
                                    "font-size": "20px",
                                    display: "flex",
                                    "justify-content": "center",
                                  },
                                },
                                [_vm._v(" 零用金报销单 ")]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          _vm._g(
                            {
                              staticClass: "card-button",
                              on: {
                                click: function ($event) {
                                  return _vm.SelectReportClick("button2")
                                },
                              },
                            },
                            _vm.$listeners
                          ),
                          [
                            _c("el-card", [
                              _c("img", {
                                staticStyle: {
                                  height: "380px",
                                  width: "100%",
                                  "object-fit": "contain",
                                },
                                attrs: { src: require("../../icons/GZBG.jpg") },
                              }),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    padding: "15px",
                                    "font-size": "20px",
                                    display: "flex",
                                    "justify-content": "center",
                                  },
                                },
                                [_vm._v(" 工作报告 ")]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c("el-col", { attrs: { span: 8 - (_vm.taskItemWidth / 7) * 2 } }, [
            _c(
              "div",
              { staticClass: "grid-content" },
              [
                _c("el-button", { on: { click: _vm.creatList } }, [
                  _vm._v("新建项目"),
                ]),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.list,
                      height: "750",
                      "element-loading-text": "Loading",
                      border: false,
                      fit: "",
                      "highlight-current-row": "",
                    },
                    on: { "cell-click": _vm.getProject },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "name",
                        label: "项目名称",
                        width: "210",
                        align: "left",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.taskItemWidth == 0
                                ? _c(
                                    "span",
                                    { staticStyle: { "font-size": "15px" } },
                                    [_vm._v(" " + _vm._s(scope.row.name) + " ")]
                                  )
                                : _vm._e(),
                              !(_vm.taskItemWidth == 0)
                                ? _c("span", [
                                    _vm._v(" " + _vm._s(scope.row.name) + " "),
                                  ])
                                : _vm._e(),
                              scope.row.ProjectCode
                                ? _c(
                                    "el-tag",
                                    {
                                      staticStyle: { "margin-left": "5px" },
                                      attrs: { size: "mini" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(scope.row.ProjectCode) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm.taskItemWidth == 0
                      ? _c("el-table-column", {
                          attrs: { width: "20", align: "center" },
                        })
                      : _vm._e(),
                    _vm.taskItemWidth == 0
                      ? _c("el-table-column", {
                          attrs: { width: "48", align: "center" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-button", {
                                      attrs: {
                                        type: "primary",
                                        icon: "el-icon-edit",
                                        size: "mini",
                                        circle: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showChangeProjectName(
                                            scope.$index,
                                            scope.row
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2344959728
                          ),
                        })
                      : _vm._e(),
                    _vm.taskItemWidth == 0
                      ? _c("el-table-column", {
                          attrs: { width: "48", align: "center" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-button", {
                                      attrs: {
                                        type: "danger",
                                        icon: "el-icon-delete",
                                        size: "mini",
                                        circle: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showDeleteProject(
                                            scope.$index,
                                            scope.row
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2258710618
                          ),
                        })
                      : _vm._e(),
                    _vm.taskItemWidth == 0
                      ? _c("el-table-column", {
                          attrs: { width: "48", align: "center" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-badge",
                                      {
                                        staticStyle: {
                                          "margin-top": "10px",
                                          "margin-bottom": "10px",
                                        },
                                        attrs: {
                                          value: _vm.memberLength(
                                            scope.row.member
                                          ),
                                          type: "warning",
                                        },
                                      },
                                      [
                                        _c("el-button", {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                            icon: "el-icon-s-custom",
                                            circle: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showMemberInfo(
                                                scope.$index,
                                                scope.row
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1324990953
                          ),
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: {
                        prop: "remaining",
                        label: "待处理",
                        align: "center",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: 15 - (_vm.taskItemWidth / 7) * 5 } }, [
            _c(
              "div",
              { staticClass: "grid-content bg-purple" },
              [
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c(
                    "div",
                    [
                      _c(
                        "el-container",
                        [
                          _c(
                            "el-header",
                            {
                              staticClass: "Tilteheader",
                              attrs: { height: "40px" },
                            },
                            [_vm._v(" " + _vm._s(_vm.actProject.name) + " ")]
                          ),
                          _c(
                            "el-popover",
                            {
                              staticClass: "item",
                              attrs: {
                                width: "200",
                                trigger: "hover",
                                content: _vm.actProject.ProjectRemarks,
                                placement: "top-start",
                              },
                            },
                            [
                              _c(
                                "el-footer",
                                {
                                  staticClass: "Tiltefooter",
                                  attrs: { slot: "reference", height: "20px" },
                                  slot: "reference",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.actProject.ProjectRemarks) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          margin: "10px",
                          display: "inline-block",
                          position: "relative",
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-edit",
                            size: "samll",
                            circle: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.showChangeProjectName(
                                null,
                                _vm.actProject
                              )
                            },
                          },
                        }),
                        _c("el-button", {
                          attrs: {
                            type: "danger",
                            icon: "el-icon-delete",
                            size: "samll",
                            circle: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.showDeleteProject(null, _vm.actProject)
                            },
                          },
                        }),
                        _c(
                          "el-badge",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              value: _vm.memberLength(_vm.actProject.member),
                              type: "warning",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                size: "samll",
                                icon: "el-icon-s-custom",
                                circle: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.showMemberInfo(
                                    null,
                                    _vm.actProject
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-badge",
                          {
                            staticStyle: { "margin-left": "12px" },
                            attrs: {
                              value: _vm.memberLength(_vm.actProject.file),
                              type: "warning",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                size: "samll",
                                icon: "el-icon-paperclip",
                                circle: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.showAppendixInfo(
                                    null,
                                    _vm.actProject
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.TasklistLoading,
                        expression: "TasklistLoading",
                      },
                    ],
                    staticStyle: { width: "100%", "margin-top": "10px" },
                    attrs: {
                      data: _vm.Tasklist,
                      "element-loading-text": "Loading",
                      height: "650",
                      "show-header": false,
                      border: false,
                      fit: "",
                      "highlight-current-row": "",
                    },
                    on: { "cell-click": _vm.getTaskItem },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "expand", width: "28" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _c(
                                "el-table",
                                {
                                  attrs: {
                                    data: props.row.step,
                                    "show-header": false,
                                    "max-height": "480",
                                    border: false,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { width: "30 " },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      type: "expand",
                                      width: "30 ",
                                      align: "right",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (props) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "40px",
                                                  },
                                                },
                                                [
                                                  _c("h3", [_vm._v("日志")]),
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "margin-top": "10px",
                                                    },
                                                    attrs: {
                                                      type: "textarea",
                                                      disabled: "",
                                                      autosize: {
                                                        minRows: 2,
                                                        maxRows: 20,
                                                      },
                                                    },
                                                    model: {
                                                      value: props.row.log,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          props.row,
                                                          "log",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "props.row.log",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: { width: "50 " },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "dark",
                                                    content: "切换完成状态",
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  _c("el-button", {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          scope.row.status ===
                                                          false,
                                                        expression:
                                                          "scope.row.status === false",
                                                      },
                                                    ],
                                                    attrs: { circle: "" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.changeStepStatusInlist(
                                                          scope.row,
                                                          props.row
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c("i", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: scope.row.status,
                                                    expression:
                                                      "scope.row.status",
                                                  },
                                                ],
                                                class: _vm.TaskStatusIcon(
                                                  scope.row.status
                                                ),
                                                staticStyle: {
                                                  "font-size": "27px",
                                                  color: "#409eff",
                                                },
                                                attrs: {
                                                  slot: "prepend",
                                                  size: "mini",
                                                  circle: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changeStepStatusInlist(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                                slot: "prepend",
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.getFatherTask(
                                                        props.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticStyle: {
                                                        margin: "1px",
                                                        "font-size": "16px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            scope.row.name
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.getFatherTask(
                                                        props.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  scope.row.endTime > 0
                                                    ? _c(
                                                        "span",
                                                        {
                                                          style:
                                                            _vm.getDateStyle(
                                                              scope.row.endTime,
                                                              scope.row.status
                                                            ),
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getDateTxt(
                                                                  scope.row
                                                                    .endTime
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  scope.row.log !== undefined &&
                                                  scope.row.log !== null &&
                                                  scope.row.log !== ""
                                                    ? _c(
                                                        "span",
                                                        { staticClass: "red" },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-tickets",
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: { width: "50", align: "center" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-dropdown",
                                                { attrs: { trigger: "click" } },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "primary",
                                                        size: "mini",
                                                        circle: "",
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-arrow-down el-icon-menu",
                                                      }),
                                                    ]
                                                  ),
                                                  _c(
                                                    "el-dropdown-menu",
                                                    {
                                                      attrs: {
                                                        slot: "dropdown",
                                                      },
                                                      slot: "dropdown",
                                                    },
                                                    [
                                                      _c(
                                                        "el-dropdown-item",
                                                        [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "5px",
                                                              },
                                                              attrs: {
                                                                type: "primary",
                                                                icon: "el-icon-edit",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.showstepDetail(
                                                                      scope.$index,
                                                                      scope.row,
                                                                      props.row
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("编辑")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-dropdown-item",
                                                        [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "5px",
                                                              },
                                                              attrs: {
                                                                type: "danger",
                                                                icon: "el-icon-delete",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.showDeleteStep(
                                                                      scope.$index,
                                                                      scope.row,
                                                                      props.row
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("删除步骤")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { width: "48", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "切换完成状态",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: scope.row.status === false,
                                        expression:
                                          "scope.row.status === false",
                                      },
                                    ],
                                    attrs: { circle: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeTaskStatus(scope.row)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: scope.row.status,
                                    expression: "scope.row.status",
                                  },
                                ],
                                class: _vm.TaskStatusIcon(scope.row.status),
                                staticStyle: {
                                  "font-size": "27px",
                                  color: "#409eff",
                                },
                                attrs: {
                                  slot: "prepend",
                                  size: "mini",
                                  circle: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeTaskStatus(scope.row)
                                  },
                                },
                                slot: "prepend",
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { width: "70", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getShortDate(scope.row.createTime)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "name", align: "left" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      margin: "1px",
                                      "font-size": "20px",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(scope.row.name) + " ")]
                                ),
                              ]),
                              _c("div", [
                                scope.row.step.length > 0
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "darkgray",
                                          "margin-right": "10px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.getStepTxt(scope.row)) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                scope.row.endTime > 0
                                  ? _c(
                                      "span",
                                      {
                                        style: _vm.getDateStyle(
                                          scope.row.endTime,
                                          scope.row.status
                                        ),
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getDateTxt(scope.row.endTime)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                scope.row.file !== undefined &&
                                scope.row.file.length > 0
                                  ? _c("span", { staticClass: "blue" }, [
                                      _c("i", {
                                        staticClass: "el-icon-paperclip",
                                      }),
                                    ])
                                  : _vm._e(),
                                scope.row.comment !== undefined &&
                                scope.row.comment !== null &&
                                scope.row.comment !== ""
                                  ? _c("span", { staticClass: "red" }, [
                                      _c("i", {
                                        staticClass: "el-icon-tickets",
                                      }),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { width: "62", align: "left" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.worker !== null &&
                              scope.row.worker.length > 0
                                ? _c(
                                    "el-avatar",
                                    {
                                      staticStyle: {
                                        "font-size": "10px",
                                        "background-color": "cornflowerblue",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(scope.row.worker[0]) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { width: "48", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("i", {
                                class: _vm.TaskPriorityIcon(scope.row.priority),
                                staticStyle: {
                                  "font-size": "18px",
                                  color: "#409eff",
                                },
                                attrs: {
                                  slot: "prepend",
                                  size: "mini",
                                  circle: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changePriority(scope.row)
                                  },
                                },
                                slot: "prepend",
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c(
                  "el-input",
                  {
                    staticClass: "edit-input",
                    staticStyle: { "margin-top": "10px" },
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.newTask,
                      callback: function ($$v) {
                        _vm.newTask = $$v
                      },
                      expression: "newTask",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          slot: "prepend",
                          icon: _vm.finshTaskIcon(_vm.showFinshTask),
                        },
                        on: { click: _vm.changeShowFinshTask },
                        slot: "prepend",
                      },
                      [_vm._v(_vm._s("已完成" + _vm.taskFinshCounter) + " ")]
                    ),
                    _c("el-button", {
                      attrs: { slot: "append", icon: "el-icon-circle-plus" },
                      on: { click: _vm.creatTask },
                      slot: "append",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: _vm.taskItemWidth } }, [
            _c(
              "div",
              { staticClass: "grid-content", staticStyle: { height: "450" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "frame",
                    staticStyle: { "margin-bottom": "10px" },
                  },
                  [
                    _c(
                      "el-row",
                      {
                        staticStyle: { "margin-bottom": "0px", padding: "7px" },
                      },
                      [
                        _c("el-col", { attrs: { span: 19 } }, [
                          _vm.actTask.status == false
                            ? _c(
                                "p",
                                { staticStyle: { display: "inline-block" } },
                                [
                                  _vm._v(
                                    " 由" +
                                      _vm._s(_vm.actTask.creater) +
                                      "创建于" +
                                      _vm._s(
                                        _vm.getDate(_vm.actTask.createTime)
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.actTask.status == true
                            ? _c(
                                "p",
                                { staticStyle: { display: "inline-block" } },
                                [
                                  _vm._v(
                                    " 由" +
                                      _vm._s(_vm.actTask.finisher) +
                                      "完成于" +
                                      _vm._s(
                                        _vm.getDate(_vm.actTask.finishTime)
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _c(
                          "el-col",
                          { attrs: { span: 5 } },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                size: "small",
                                icon: "el-icon-tickets",
                                circle: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.SelectReport(_vm.actTask._id)
                                },
                              },
                            }),
                            _c("el-button", {
                              attrs: {
                                type: "danger",
                                size: "small",
                                icon: "el-icon-delete",
                                circle: "",
                              },
                              on: { click: _vm.showdeleteTaskVisble },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "frame" },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.stepLoading,
                            expression: "stepLoading",
                          },
                        ],
                        ref: "table",
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.actStep,
                          "element-loading-text": "Loading",
                          border: false,
                          "row-key": "id",
                          fit: "",
                          "highlight-current-row": "",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { width: "45", align: "right" },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "切换完成状态",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.actTask.status === false,
                                            expression:
                                              "actTask.status === false",
                                          },
                                        ],
                                        staticStyle: {
                                          "margin-left": "1px",
                                          "margin-bottom": "7px",
                                        },
                                        attrs: { size: "small", circle: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeTaskStatus(
                                              _vm.actTask
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.actTask.status,
                                        expression: "actTask.status",
                                      },
                                    ],
                                    class: _vm.TaskStatusIcon(
                                      _vm.actTask.status
                                    ),
                                    staticStyle: {
                                      "font-size": "24px",
                                      color: "#409eff",
                                    },
                                    attrs: {
                                      slot: "prepend",
                                      size: "mini",
                                      circle: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeTaskStatus(_vm.actTask)
                                      },
                                    },
                                    slot: "prepend",
                                  }),
                                ]
                              },
                            },
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      staticStyle: { "margin-left": "15px" },
                                      attrs: {
                                        effect: "dark",
                                        content: "切换完成状态",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: scope.row.status === false,
                                            expression:
                                              "scope.row.status === false",
                                          },
                                        ],
                                        staticStyle: {
                                          "margin-left": "1px",
                                          "margin-bottom": "7px",
                                        },
                                        attrs: { size: "mini", circle: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeStepStatus(
                                              scope.row
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: scope.row.status,
                                        expression: "scope.row.status",
                                      },
                                    ],
                                    class: _vm.TaskStatusIcon(scope.row.status),
                                    staticStyle: {
                                      "font-size": "18px",
                                      color: "#409eff",
                                      "margin-left": "15px",
                                    },
                                    attrs: {
                                      slot: "prepend",
                                      size: "mini",
                                      circle: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeStepStatus(scope.row)
                                      },
                                    },
                                    slot: "prepend",
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: { align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    staticStyle: { "font-size": "20px" },
                                    on: { change: _vm.taskNameChange },
                                    model: {
                                      value: _vm.actTask.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.actTask, "name", $$v)
                                      },
                                      expression: "actTask.name",
                                    },
                                  }),
                                ]
                              },
                            },
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    staticStyle: { "padding-left": "15px" },
                                    on: { change: _vm.stepNameChange },
                                    model: {
                                      value: scope.row.name,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "name", $$v)
                                      },
                                      expression: "scope.row.name",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: { width: "50", align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "重要标记",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        staticStyle: {
                                          "font-size": "18px",
                                          color: "#409eff",
                                        },
                                        attrs: {
                                          size: "mini",
                                          circle: "",
                                          icon: _vm.TaskPriorityIcon(
                                            _vm.actTask.priority
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changePriority(
                                              _vm.actTask
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-dropdown",
                                    { attrs: { trigger: "click" } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                            circle: "",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-arrow-down el-icon-menu",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "el-dropdown-menu",
                                        {
                                          attrs: { slot: "dropdown" },
                                          slot: "dropdown",
                                        },
                                        [
                                          _c(
                                            "el-dropdown-item",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "5px",
                                                  },
                                                  attrs: {
                                                    type: "primary",
                                                    icon: "el-icon-edit",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showstepDetail(
                                                        scope.$index,
                                                        scope.row,
                                                        _vm.actTask
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("编辑")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-dropdown-item",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "5px",
                                                  },
                                                  attrs: {
                                                    type: "danger",
                                                    icon: "el-icon-delete",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showDeleteStep(
                                                        scope.$index,
                                                        scope.row,
                                                        _vm.actTask
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除步骤")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-input",
                      {
                        staticClass: "edit-input",
                        staticStyle: { "margin-top": "3px" },
                        attrs: {
                          type: "text",
                          placeholder: "添加步骤",
                          size: "small",
                        },
                        model: {
                          value: _vm.newStep,
                          callback: function ($$v) {
                            _vm.newStep = $$v
                          },
                          expression: "newStep",
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            slot: "prepend",
                            icon: "el-icon-circle-plus",
                          },
                          on: { click: _vm.creatStep },
                          slot: "prepend",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%", "margin-top": "10px" },
                    attrs: { multiple: "", placeholder: "分配给" },
                    on: { change: _vm.workerChange },
                    model: {
                      value: _vm.actTask.worker,
                      callback: function ($$v) {
                        _vm.$set(_vm.actTask, "worker", $$v)
                      },
                      expression: "actTask.worker",
                    },
                  },
                  [
                    _c("template", { slot: "prefix" }, [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            padding: "5px",
                            "line-height": "33px",
                            "font-size": "18px",
                            color: "#409eff",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-user-solid" })]
                      ),
                    ]),
                    _c(
                      "el-option",
                      {
                        key: _vm.actProject.creater,
                        attrs: { value: _vm.actProject.creater },
                      },
                      [
                        _c("span", { staticStyle: { float: "left" } }, [
                          _c("i", { staticClass: "el-icon-user-solid" }),
                          _vm._v(_vm._s(_vm.actProject.creater)),
                        ]),
                      ]
                    ),
                    _vm._l(_vm.actProject.member, function (item) {
                      return _c(
                        "el-option",
                        { key: item, attrs: { value: item } },
                        [
                          _c("span", { staticStyle: { float: "left" } }, [
                            _c("i", { staticClass: "el-icon-user-solid" }),
                            _vm._v(_vm._s(item)),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _c("el-date-picker", {
                  class: _vm.getSetDateStyle(_vm.actTask.endTime),
                  staticStyle: { width: "100%", "margin-top": "10px" },
                  attrs: {
                    align: "right",
                    type: "date",
                    placeholder: "添加截止日期",
                    format: "yyyy 年 MM 月 dd 日 到期",
                    "value-format": "timestamp",
                  },
                  on: { change: _vm.endTimeChange },
                  model: {
                    value: _vm.actTask.endTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.actTask, "endTime", $$v)
                    },
                    expression: "actTask.endTime",
                  },
                }),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                    staticClass: "marginTop",
                    staticStyle: { width: "100%", "margin-top": "20px" },
                    attrs: {
                      data: _vm.actTask.file,
                      "element-loading-text": "Loading",
                      border: "",
                      fit: "",
                      "highlight-current-row": "",
                    },
                    on: { "row-click": _vm.annexPreview },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { align: "center", label: "文件列表" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row))])]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { align: "center", label: "操作", width: "110" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-button", {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-download",
                                  size: "mini",
                                  circle: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadFile(scope.row)
                                  },
                                },
                              }),
                              _c("el-button", {
                                attrs: {
                                  type: "danger",
                                  icon: "el-icon-delete",
                                  size: "mini",
                                  circle: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteFile(scope.row)
                                  },
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c(
                  "el-upload",
                  {
                    attrs: {
                      action: "''",
                      multiple: "",
                      accept:
                        ".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PBG, .GIF, .pdf, .lds",
                      "http-request": _vm.submitList,
                      "show-file-list": false,
                    },
                  },
                  [
                    _c("el-button", { attrs: { icon: "el-icon-paperclip" } }, [
                      _vm._v("添加附件"),
                    ]),
                  ],
                  1
                ),
                _c("el-input", {
                  staticStyle: { width: "100%", "margin-top": "20px" },
                  attrs: {
                    type: "textarea",
                    autosize: { minRows: 2, maxRows: 4 },
                    placeholder: "请输入备注",
                  },
                  on: { change: _vm.commentChange },
                  model: {
                    value: _vm.actTask.comment,
                    callback: function ($$v) {
                      _vm.$set(_vm.actTask, "comment", $$v)
                    },
                    expression: "actTask.comment",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }