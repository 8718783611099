<template>
  <div>

    <div class="Main">
     <div v-if="showCompanySelect()" style="margin-top:20px;margin-left: 20px;">
      <h3>选择生产公司:</h3>
      <el-select v-model="companyChoose" @change="getdata" placeholder="请选择">
        <el-option v-for="item in companyList" :key="item.identifierStr" :label="item.name" :value="item.identifierStr">
        </el-option>
      </el-select>
     </div>
      <div class="tablediv">
        <el-table :data="MaterialList" style="width: 100%" v-loading="listLoading" :default-sort="{prop: 'number', order: 'ascending'}">
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-table style="width: 100%" :data="props.row.operateHistory" :default-sort="{prop: 'InDate', order: 'descending'}">

                <el-table-column prop="Operater" label="操作人" width="380" align="center">
                </el-table-column>
                <el-table-column label="操作" align="center">
                  <template slot-scope="scope">
                    <P> {{ getOperate(scope.row.warehousingNum, scope.row.scrapNum) }}</P>

                  </template>
                </el-table-column>

                <el-table-column label="时间" width="300" align="center">
                  <template slot-scope="scope">
                    <P> {{ getDate(scope.row.InDate) }}</P>

                  </template>
                </el-table-column>
              </el-table>

            </template>
          </el-table-column>
          <el-table-column prop="MaterialName" label="物料名称" width="180" align="left">
          </el-table-column>
          <el-table-column prop="number" label="订货号" width="180" align="left">
          </el-table-column>
          <el-table-column prop="total" label="入库数" align="center">
          </el-table-column>
          <el-table-column prop="scrap" label="报废数" align="center">
          </el-table-column>
          <el-table-column prop="consume" label="已消耗" align="center">
          </el-table-column>
          <el-table-column prop="preConsume" label="预计消耗" align="center">
          </el-table-column>
          <el-table-column prop="remaining" label="剩余" align="center">
            <template slot-scope="scope">
              <P :class="getTxtColor(scope.row.remaining, scope.row.preConsume)"> {{ scope.row.remaining }}</P>

            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="300">
            <template slot-scope="scope">
              <el-button size="mini" @click="inStorage(scope.$index, scope.row)">入库</el-button>
              <el-button size="mini" @click="reportingLoss(scope.$index, scope.row)">报损</el-button>
              <el-button size="mini" @click="getOrder(scope.$index, scope.row)">相关订单</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog title="报损" :visible.sync="ChangeBadInfo" width="30%" center>
      <div style="margin-left: 5px; margin-bottom: 10px">报损：</div>
      <el-input v-model="ShowBadInfo.num" type=“number” placeholder="请输入数量" min=0></el-input>
      <div style="margin-top: 10px; display: flex; justify-content: flex-end">
        <el-button size="mini" type="primary" @click="confirmBad">确定</el-button>
        <el-button size="mini" type="primary" @click="ChangeBadInfo = false">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog title="新增入库" :visible.sync="ChangeInInfo" width="30%" center>
      <div style="margin-left: 5px; margin-bottom: 10px">入库：</div>
      <el-input v-model="ShowInInfo.num" type=“number” placeholder="请输入数量" min=0></el-input>
      <div style="margin-top: 10px; display: flex; justify-content: flex-end">
        <el-button size="mini" type="primary" @click="confirmIn">确定</el-button>
        <el-button size="mini" type="primary" @click="ChangeInInfo = false">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog title="订单列表" :visible.sync="showOrder" width="60%" center>
      <el-table :data="orderList">
        <el-table-column align="center" label="ID" width="95">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="订货号">
          <template slot-scope="scope">
            {{ scope.row.orderNum }}
          </template>
        </el-table-column>

        <el-table-column label="创建时间" width="300" align="center">
          <template slot-scope="scope">
            <P> {{ getDate(scope.row.CreativeTime) }}</P>

          </template>

        </el-table-column>
        <el-table-column align="center" label="客户">
          <template slot-scope="scope">
            {{ scope.row.CustomerId }}
          </template>
        </el-table-column>

      </el-table>
    </el-dialog>
  </div>
</template>     
<script>
import { GetAllProducts, creativePlant, GetAllPlant, UpdatePlant, getGroup } from "@/api/mesproductinfo";

import { getCompanyCode, getToken } from "@/utils/auth";
import
{
  getMaterialListByid,
  getMaterialListBynumber,

} from "@/api/mes";
import { getName } from "@/utils/auth";
import { getTypeGroup, getTotalDoneNumber, GetProductOrders } from "@/api/mes";
export default {
  data()
  {
    return {
      token:"",
      TableInfo: [
        {
          name: "",
          identifierStr: "",
          address: "",
          phone: "",
        },
      ],
      ChangeBadInfo: false,
      listLoading: false,
      CompanyInfo: null,
      MaterialList: [],
      companyList: [],
      orderList: [],
      companyChoose: '',
      ChangeInInfo: false,
      dislogvisial: false,
      dialogloading: false,
      showOrder: false,
      consumeList: {},
      ShowInInfo: {
        _id: "",
        num: Number

      },
      ShowBadInfo: {
        _id: "",
        num: null

      },
      addrules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        identifierStr: [{ required: true, message: "请输入英文标识符", trigger: "change" }],
      },
    };
  },
  created()
  {
    this.getdata();
  },
  methods: {
    showdialog()
    {
      this.ShowInfo = {
        name: "",
        identifierStr: "",
        address: "",
        phone: "",
      };
      this.dislogvisial = true;
    },
    async getdata()
    {
      this.token = getToken();
      //console.log(this.token)
      this.listLoading = true;
      let code = "";
      code = getCompanyCode();
      let CompanyInfo = await (await GetAllPlant({ companyCode: code }));

      this.companyList = CompanyInfo.data.list;
      if (this.companyChoose == '') {
        if (code == undefined || code == "undefined") {
          code = "HaoFang";
        }
      } else {
        code = this.companyChoose;
      }
      let tmp = CompanyInfo.data.list.filter(item=>{
        return item.identifierStr==code
      })
      this.CompanyInfo = tmp[0]
      const typeGroup = await getTypeGroup({ Affiliation: code });
      let typeGroupArray = [];
      let typeGroupDoneArray = [];
      for (let i = 0; i < typeGroup.data.list.length; i++) {
        let doneNum = await getTotalDoneNumber({
          type: [typeGroup.data.list[i]._id],
          code: code
        });
        if (doneNum.data.list.length > 0) {

          typeGroupDoneArray.push({
            type: typeGroup.data.list[i]._id,
            doneNum: doneNum.data.list[0].totalDoneNumber,
            preDoneNum: doneNum.data.list[0].totalNumber - doneNum.data.list[0].totalDoneNumber

          });
        } else {
          typeGroupDoneArray.push({
            type: typeGroup.data.list[i]._id,
            doneNum: 0,
            preDoneNum: 0
          });
        }
        typeGroupArray.push(typeGroup.data.list[i]._id);
      }
      if (typeGroupArray.length == 0) {
        this.MaterialList = [];
        this.listLoading = false;
        return;
      }
      const { data } = await getGroup({ list: typeGroupArray });
      const list = data.list;
      let MaterialDoneArray = {};
      for (let i = 0; i < typeGroupDoneArray.length; i++) {
        let x = i;
        let product = await GetAllProducts({ filters: "({'name':'" + typeGroupDoneArray[x].type + "'})" });//"({'name':'" + typeGroupDoneArray[x].type + "'})"
        for (let j = 0; j < product.data.list[0].bom.length; j++) {
          if (MaterialDoneArray[product.data.list[0].bom[j].id] == undefined) {
            MaterialDoneArray[product.data.list[0].bom[j].id] = {};
            // MaterialDoneArray[product.data.list[0].bom[j].id]['doneNum'] = 0;
            MaterialDoneArray[product.data.list[0].bom[j].id]['preDoneNum'] = 0;
          }

          //    MaterialDoneArray[product.data.list[0].bom[j].id]['doneNum'] +=product.data.list[0].bom[j].num* typeGroupDoneArray[x].doneNum ;
          MaterialDoneArray[product.data.list[0].bom[j].id]['preDoneNum'] += product.data.list[0].bom[j].num * typeGroupDoneArray[x].preDoneNum;
        }
      }
      let bomList = {};
      for (let i = 0; i < list.length; i++) {
        let bom = await getMaterialListByid({ id: list[i]._id });
        bomList[list[i]._id] = bom.data;
      }
      let MaterialInventory = this.CompanyInfo.MaterialInventory;
      if (MaterialInventory) {
        for (let k = 0; k < this.CompanyInfo.MaterialInventory.length; k++) {
          if (bomList[MaterialInventory[k].id]) {
            bomList[MaterialInventory[k].id]['total'] = MaterialInventory[k].total;
            bomList[MaterialInventory[k].id]['scrap'] = MaterialInventory[k].scrap;
            bomList[MaterialInventory[k].id]['used'] = MaterialInventory[k].used;
            bomList[MaterialInventory[k].id]['operateHistory'] = MaterialInventory[k].operateHistory;
          }

        }
      }
      this.MaterialList = [];
      for (let item in bomList) {
        this.MaterialList.push({
          bomID: item,
          MaterialName: bomList[item].Id,
          number: bomList[item].number,
          total: bomList[item].total,
          scrap: bomList[item].scrap,
          operateHistory: bomList[item].operateHistory,
          consume: bomList[item].used,
          preConsume: MaterialDoneArray[item]['preDoneNum'],
          remaining: bomList[item].total - bomList[item].scrap - bomList[item].used,

        });
      }

      this.listLoading = false;
    },
    getTxtColor(remaining, preConsume)
    {
      if (remaining < preConsume) {
        return "dangerColor";
      } else {
        return "";
      }
    },
    creativePlant(formName)
    {
      if (
        this.ShowInfo.name == "" ||
        this.ShowInfo.identifierStr == ""
      ) {
        return;
      } else {
        this.dialogloading = true;
        creativePlant(this.ShowInfo)
          .then((res) =>
          {
            this.$message.success("创建成功");
            this.dislogvisial = false;
            this.dialogloading = false;
            this.getdata();
          })
          .catch((err) =>
          {
            this.$message.error(err);
            this.dialogloading = false;
          });
      }
    },
    inStorage(index, row)
    {

      this.ShowInInfo._id = row.bomID;
      this.ShowInInfo.num = null;
      this.ChangeInInfo = true;
    },
    showCompanySelect()
    {
      return this.token == "Admin-token"
    },
    async confirmIn()
    {
      if (parseInt(this.ShowInInfo.num) < 0) {
        this.$message.error("数值小于0");
        return;
      }
      let MaterialInventory = this.CompanyInfo.MaterialInventory;
      if (MaterialInventory == undefined) {
        MaterialInventory = [];
      }
      if (MaterialInventory.some(MaterialInventory => MaterialInventory.id === this.ShowInInfo._id)) {
        for (let i = 0; i < MaterialInventory.length; i++) {
          if (MaterialInventory[i].id === this.ShowInInfo._id) {
            MaterialInventory[i].total += parseInt(this.ShowInInfo.num);
            MaterialInventory[i].operateHistory.unshift({
              warehousingNum: parseInt(this.ShowInInfo.num),
              InDate: new Date().valueOf(),
              Operater: getName(),
              scrapNum: 0,
            });
          }
        }
      } else {
        MaterialInventory.push({
          id: this.ShowInInfo._id,
          total: this.ShowInInfo.num,
          scrap: 0,
          operateHistory: [{
            warehousingNum: parseInt(this.ShowInInfo.num),
            InDate: new Date().valueOf(),
            Operater: getName(),
            scrapNum: 0,
          }]
        });
      }

      await UpdatePlant({
        _id: this.CompanyInfo._id,
        MaterialInventory: MaterialInventory,

      }).then(res =>
      {
        this.$message.success("更新成功");

        this.getdata();
      }).catch(err =>
      {
        this.$message.error(err);
      });
      this.ChangeInInfo = false;
    },
    async getOrder(index, row)
    {
      let bom = row;
      let code = "";
      code = getCompanyCode();
      if (this.companyChoose == '') {
        if (code == undefined) {
          code = "HaoFang";
        }
      } else {
        code = this.companyChoose;
      }
      let product = await GetAllProducts({
        filters: "({'bom':{$elemMatch: {'id':'" + bom.bomID + "'}}})"
      });
      let filtersOrder = "({$or:[";
      for (let i = 0; i < product.data.list.length; i++) {
        filtersOrder += "{'Affiliation':'" + code + "', 'Product':{$elemMatch: {'Type':'" + product.data.list[i].name + "'}}}";
        if (i !== product.data.list.length - 1) {
          filtersOrder += ",";
        }
      }
      filtersOrder += "]})";
      let data = await GetProductOrders({
        filters: filtersOrder
      });

      this.orderList = data.list;
      this.showOrder = true;
    },
    getOperate(inNum, outNum)
    {
      if (inNum > outNum) {
        return "入库:" + inNum;
      } else {
        return "报损:" + outNum;
      }
    },
    getDate(timestamp)
    {
      let date = new Date(timestamp),
        y = date.getFullYear(),
        m =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1,
        d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return y + "年" + m + "月" + d + "日";
    },
    async confirmBad()
    {
      let code = "";
      code = getCompanyCode();
      if (parseInt(this.ShowBadInfo.num) < 0) {
        this.$message.error("数值小于0");
        return;
      }
      let CompanyInfo = await GetAllPlant({ companyCode: code });
      let MaterialInventory = CompanyInfo.data.list[0].MaterialInventory;
      if (MaterialInventory == undefined) {
        MaterialInventory = [];
      }
      if (MaterialInventory.some(MaterialInventory => MaterialInventory.id === this.ShowBadInfo._id)) {
        for (let i = 0; i < MaterialInventory.length; i++) {
          if (MaterialInventory[i].id === this.ShowBadInfo._id) {
            MaterialInventory[i].scrap += parseInt(this.ShowBadInfo.num);
            MaterialInventory[i].operateHistory.unshift({
              scrapNum: parseInt(this.ShowBadInfo.num),
              InDate: new Date().valueOf(),
              Operater: getName(),
              warehousingNum: 0,
            });
          }
        }
      } else {
        MaterialInventory.push({
          id: this.ShowBadInfo._id,
          total: 0,
          scrap: this.ShowBadInfo.num,
          operateHistory: [{
            scrapNum: parseInt(this.ShowBadInfo.num),
            InDate: new Date().valueOf(),
            Operater: getName(),
            warehousingNum: 0,
          }]
        });
      }
      await UpdatePlant({
        _id: this.CompanyInfo._id,
        MaterialInventory: MaterialInventory
      }).then(res =>
      {
        this.$message.success("更新成功");
        this.ChangeBadInfo = false;
        this.getdata();
      }).catch(err =>
      {
        this.$message.error(err);
      });
      this.ChangeBadInfo = false;
    },
    reportingLoss(index, row)
    {
      this.ShowBadInfo._id = row.bomID;
      this.ShowBadInfo.num = null;
      this.ChangeBadInfo = true;
    },
    confirmUpdate()
    {
      UpdatePlant(this.ShowInfo).then(res =>
      {
        this.$message.success("更新成功");
        this.ChangeInfo = false;
        this.getdata();
      }).catch(err =>
      {
        this.$message.error(err);
      });
    },

  },
};
</script>
<style scoped>
.head {
  margin-left: 10px;
  margin-top: 10px;
}

div>>>.Fuzzy_search {
  margin-left: 10px;
  margin-right: 5px;
  display: inline-block;
  width: 300px;
  margin-right: 10px;
}

.dangerColor {
  color: red !important;
}

.tablediv {
  margin-top: 20px;
  width: 100%;
}

#newhw {
  margin-bottom: 10px;
}

#newhwbutton {
  margin-top: 10px;
}
</style>