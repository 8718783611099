import request from "@/utils/request";

export function CreatTemporaryOccur(data) {
  return request({
    url: '/Api/calendar/CreatTemporaryOccur',
    method: 'post',
    data
  })
}

export function GetTemporaryOccur(data) {
  return request({
    url: '/Api/calendar/GetTemporaryOccur',
    method: 'post',
    data
  })
}

export function UpdateTemporaryOccur(data) {
  return request({
    url: '/Api/calendar/UpdateTemporaryOccur',
    method: 'post',
    data
  })
}

export function CreateCalendarScheme(data) {
  return request({
    url: '/Api/calendar/CreateCalendarScheme',
    method: 'post',
    data
  })
}



export function UpdateCalendarScheme(data) {
  return request({
    url: '/Api/calendar/UpdateCalendarScheme',
    method: 'post',
    data
  })
}

export function GetCalendarScheme(data) {
  return request({
    url: '/Api/calendar/GetCalendarScheme',
    method: 'post',
    data
  })
}

export function UpdateCalendaringHolidays(data) {
  return request({
    url: '/Api/calendar/UpdateCalendaringHolidays',
    method: 'post',
    data
  })
}



export function ChangeEvent(data) {
  return request({
    url: '/Api/calendar/ChangeEvent',
    method: 'post',
    data
  })
}
export function DeleteEvent(data) {
  return request({
    url: '/Api/calendar/DeleteEvent',
    method: 'post',
    data
  })
}