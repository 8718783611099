var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { attrs: { id: "table" } },
        [
          _c(
            "el-badge",
            {
              staticStyle: { "margin-left": "30px", "margin-top": "5px" },
              attrs: { value: _vm.unreguser.length, type: "warning" },
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.showunreg } },
                [_vm._v("新用户申请")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.wholeUserlist },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand", width: "28", "show-header": false },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: props.row.userlist,
                              "default-sort": {
                                prop: "JobNumber",
                                order: "ascending",
                              },
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                sortable: "",
                                prop: "username",
                                label: "姓名",
                                align: "center",
                                width: "180",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                sortable: "",
                                prop: "JobNumber",
                                label: "工号",
                                align: "center",
                                width: "180",
                              },
                            }),
                            props.row.isSigmatek
                              ? _c("el-table-column", {
                                  attrs: {
                                    label: "邮箱",
                                    align: "center",
                                    "min-width": "180",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  scope.row.email +
                                                    "@sigmatek-automation.cn"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : _vm._e(),
                            props.row.isSigmatek
                              ? _c("el-table-column", {
                                  attrs: {
                                    sortable: "",
                                    prop: "manager",
                                    label: "主管",
                                    align: "center",
                                    "min-width": "180",
                                  },
                                })
                              : _vm._e(),
                            props.row.isSigmatek == false
                              ? _c("el-table-column", {
                                  attrs: {
                                    sortable: "",
                                    prop: "company",
                                    label: "公司",
                                    align: "center",
                                    "min-width": "180",
                                  },
                                })
                              : _vm._e(),
                            props.row.isSigmatek
                              ? _c("el-table-column", {
                                  attrs: {
                                    sortable: "",
                                    label: "权限",
                                    align: "center",
                                    "min-width": "180",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.depatment(
                                                    scope.row.token_Sigmatek
                                                  )
                                                ) +
                                                " "
                                            ),
                                            scope.row.token === "Admin-token"
                                              ? _c("i", {
                                                  staticClass:
                                                    "el-icon-s-custom",
                                                })
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : _vm._e(),
                            _c("el-table-column", {
                              attrs: {
                                label: "使用状态",
                                align: "center",
                                "min-width": "180",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getstatus(scope.row.token)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "操作",
                                align: "center",
                                width: "180",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeuser(scope.row)
                                              },
                                            },
                                          },
                                          [_vm._v("修改信息")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "part" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticStyle: { "font-size": "20px" } }, [
                          _vm._v(_vm._s(scope.row.part) + " "),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改信息",
            visible: _vm.changeinfovisialbe,
            width: "40%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.changeinfovisialbe = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-position": "left" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "修改名称：", "label-width": "100px" } },
                [
                  _c("el-input", {
                    staticClass: "nameinput",
                    attrs: { placeholder: "请输入内容" },
                    model: {
                      value: _vm.selectinfo.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.selectinfo, "username", $$v)
                      },
                      expression: "selectinfo.username",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "修改工号：", "label-width": "100px" } },
                [
                  _c("el-input", {
                    staticClass: "nameinput",
                    attrs: { placeholder: "请输入内容" },
                    on: { change: _vm.selectinfoJN },
                    model: {
                      value: _vm.selectinfo.JobNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.selectinfo, "JobNumber", $$v)
                      },
                      expression: "selectinfo.JobNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 14 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "修改部门为：",
                            "label-width": "100px",
                          },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { id: "partmentselect" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.selectinfo.token_Sigmatek,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectinfo,
                                        "token_Sigmatek",
                                        $$v
                                      )
                                    },
                                    expression: "selectinfo.token_Sigmatek",
                                  },
                                },
                                _vm._l(_vm.tokenlist, function (item) {
                                  return _c("el-option", {
                                    key: item.token,
                                    attrs: {
                                      label: _vm.depatment(item.token),
                                      value: item.token,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "设为管理员" } },
                        [
                          _c("el-switch", {
                            on: { change: _vm.Refresh },
                            model: {
                              value: _vm.selectinfo.isAdmin,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectinfo, "isAdmin", $$v)
                              },
                              expression: "selectinfo.isAdmin",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.selectinfo.token !== "supplier"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "直属领导：",
                            "label-width": "100px",
                          },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { id: "partmentselect" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.selectinfo.manager,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.selectinfo, "manager", $$v)
                                    },
                                    expression: "selectinfo.manager",
                                  },
                                },
                                _vm._l(_vm.userlist, function (item) {
                                  return _c("el-option", {
                                    key: item.username,
                                    attrs: {
                                      label: item.username,
                                      value: item.username,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "修改邮箱为：",
                            "label-width": "100px",
                          },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { id: "partmentselect" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { placeholder: "请输入内容" },
                                  model: {
                                    value: _vm.selectinfo.email,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.selectinfo, "email", $$v)
                                    },
                                    expression: "selectinfo.email",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("@sigmatek-automation.cn"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectinfo.token === "supplier"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "所属公司：",
                            "label-width": "100px",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              on: { change: _vm.getCompanyName },
                              model: {
                                value: _vm.selectinfo.companycode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.selectinfo, "companycode", $$v)
                                },
                                expression: "selectinfo.companycode",
                              },
                            },
                            _vm._l(_vm.companylist, function (item) {
                              return _c("el-option", {
                                key: item.identifierStr,
                                attrs: {
                                  label: item.name,
                                  value: item.identifierStr,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "div",
                { staticClass: "buttongroup" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.changeinfovisialbe = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.confirmchange },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "注册申请",
            visible: _vm.unregvisiual,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.unregvisiual = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-position": "left", "label-width": "120px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "微信昵称" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.selectunreguser._id,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectunreguser, "_id", $$v)
                        },
                        expression: "selectunreguser._id",
                      },
                    },
                    _vm._l(_vm.unreguser, function (item) {
                      return _c("el-option", {
                        key: item.username,
                        attrs: { label: item.username, value: item._id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form",
                { attrs: { inline: true } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%", "max-width": "322px" },
                      attrs: { label: "设置用户名", "label-width": "120px" },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.selectunreguser.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.selectunreguser, "name", $$v)
                          },
                          expression: "selectunreguser.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%", "max-width": "322px" },
                      attrs: { label: "设置工号", "label-width": "80px" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100px" },
                        on: { change: _vm.selectunreguserJN },
                        model: {
                          value: _vm.selectunreguser.JobNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.selectunreguser, "JobNumber", $$v)
                          },
                          expression: "selectunreguser.JobNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form",
                { attrs: { inline: true } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "设置部门", "label-width": "120px" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.selectunreguser.token_Sigmatek,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.selectunreguser,
                                "token_Sigmatek",
                                $$v
                              )
                            },
                            expression: "selectunreguser.token_Sigmatek",
                          },
                        },
                        _vm._l(_vm.tokenlist, function (item) {
                          return _c("el-option", {
                            key: item.token,
                            attrs: {
                              label: _vm.depatment(item.token),
                              value: item.token,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "设为管理员" } },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.selectunreguser.isAdmin,
                          callback: function ($$v) {
                            _vm.$set(_vm.selectunreguser, "isAdmin", $$v)
                          },
                          expression: "selectunreguser.isAdmin",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.selectunreguser.token == "supplier"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "所属公司" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.getCompanyNameForCreate },
                          model: {
                            value: _vm.selectunreguser.companycode,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectunreguser, "companycode", $$v)
                            },
                            expression: "selectunreguser.companycode",
                          },
                        },
                        _vm._l(_vm.companylist, function (item) {
                          return _c("el-option", {
                            key: item.identifierStr,
                            attrs: {
                              label: item.name,
                              value: item.identifierStr,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectunreguser.token !== "supplier"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "直属领导" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.selectunreguser.manager,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectunreguser, "manager", $$v)
                            },
                            expression: "selectunreguser.manager",
                          },
                        },
                        _vm._l(_vm.userlist, function (item) {
                          return _c("el-option", {
                            key: item.username,
                            attrs: {
                              label: item.username,
                              value: item.username,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectunreguser.token !== "supplier"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "设置邮箱" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "请输入内容" },
                          model: {
                            value: _vm.selectunreguser.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectunreguser, "email", $$v)
                            },
                            expression: "selectunreguser.email",
                          },
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _vm._v("@sigmatek-automation.cn"),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("div", [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "div",
                  { staticClass: "buttongroup" },
                  [
                    _c(
                      "el-popconfirm",
                      {
                        attrs: { title: "确认删除？" },
                        on: { confirm: _vm.rejectnewuser },
                      },
                      [
                        _c(
                          "el-button",
                          { attrs: { slot: "reference" }, slot: "reference" },
                          [_vm._v("拒 绝")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary" },
                        on: { click: _vm.confirmNewuser },
                      },
                      [_vm._v("确 定")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }