<template>
  <div>
    <div>
      <el-button v-on:click="updateForm" style="margin-top: 10px; margin-left: 20px;">保存</el-button>
      <router-link
        :to="{
          path: 'table_Supplier',
          query: {
            filters: listQuery, // orderNum : this.searchData.orderNo
          },
        }"
      >
        <el-button type="button" style="margin-top: 10px; margin-left: 10px;">返回</el-button>
      </router-link>

      <el-button 
        style="margin-top: 10px; margin-left: 10px;"
        v-if="!FormDialog.SupplierRelated.Supplier_Complete"
        v-on:click="ConfirmCompletion(FormDialog.SupplierRelated.Supplier_Complete)"
      >
        确认完成
      </el-button>
      <el-button 
        style="margin-top: 10px; margin-left: 10px;"
        v-if="FormDialog.SupplierRelated.Supplier_Complete"
        type="primary"
        v-on:click="ConfirmCompletion(FormDialog.SupplierRelated.Supplier_Complete)"
      >
        已完成
      </el-button>

    </div>

    <div id="printTest">
      <!-- <img class="log" src="../../../Img/log.jpg" /> -->
      <h2>维修报告<span class="italic">Repairment</span></h2>

      <h6>产品信息| Product Information:</h6>
      <table border="1" cellspacing="0">
        <tr>
          <td class="clomun1">序列号| Serail No.</td>
          <td class="clomun2">
            <el-input
              type="text"
              v-model="FormDialog.productInfo.SN"
              :disabled="true"
              class="edit-input"
              size="small"
            />
          </td>
          <td class="clomun3">产品名称| Module</td>
          <td class="clomun4">
            <el-input
              type="text"
              v-model="FormDialog.productInfo.module"
              :disabled="true"
              class="edit-input"
              size="small"
            />
          </td>
          <td class="clomun5">产品订货号| Articl No.</td>
          <td class="clomun6">
            <el-input
              type="text"
              v-model="FormDialog.productInfo.articl"
              :disabled="true"
              class="edit-input"
              size="small"
            />
          </td>
        </tr>

        <tr>
          <td>硬件版本| Hardware.</td>
          <td>
            <el-input
              type="text"
              v-model="FormDialog.productInfo.hardware"
              :disabled="true"
              class="edit-input"
              size="small"
            />
          </td>
          <td>软件版本| Software.</td>
          <td>
            <el-input
              type="text"
              v-model="FormDialog.productInfo.software"
              :disabled="true"
              class="edit-input"
              size="small"
            />
          </td>
          <td>返修次数| Return Times.</td>
          <td>
            <el-input
              type="text"
              v-model="FormDialog.times"
              :disabled="true"
              class="edit-input"
              size="small"
            />
          </td>
        </tr>

        <tr>
          <td>退货订单号| Report No.</td>
          <td>
            <el-input
              type="text"
              v-model="FormDialog.productInfo.report"
              :disabled="true"
              class="edit-input"
              size="small"
            />
          </td>
          <td>订货号| Parts No.</td>
          <td>
            <el-input
              type="text"
              v-model="FormDialog.productInfo.parts"
              :disabled="true"
              class="edit-input"
              size="small"
            />
          </td>
          <td>零件编号| PO No.</td>
          <td>
            <el-input
              type="text"
              v-model="FormDialog.productInfo.po"
              :disabled="true"
              class="edit-input"
              size="small"
            />
          </td>
        </tr>

        <tr>
          <td>返修日期| Failure Date</td>
          <td>
            <el-input
              type="date"
              v-model="FormDialog.productInfo.return"
              :disabled="true"
              class="edit-input date"
              size="small"
            />
          </td>
          <td>发货日期| Delivery Date</td>
          <td>
            <el-input
              type="date"
              v-model="FormDialog.productInfo.delivery"
              :disabled="true"
              class="edit-input date"
              size="small"
            />
          </td>
          <td>质保到期日| Warranty Date</td>
          <td>
            <el-input
              type="date"
              v-model="FormDialog.productInfo.warranty"
              :disabled="true"
              class="edit-input date"
              size="small"
            />
          </td>
        </tr>
      </table>

      <h6 style="display:inline-block">故障信息| Failure Description:</h6>
      <table border="1" cellspacing="0">
        <tr>
          <td class="Failure">
            <el-input
              ref="failure"
              type="textarea"
              v-model="FormDialog.failureDescription"
              :disabled="true"
              class="edit-input1 edit-input"
              size="small"
            />
          </td>
        </tr>
      </table>

      <h6>客户留言| Customer Message:</h6>
      <div>
        <table border="1" cellspacing="0">
          <tr>
            <td class="Failure">
              <el-input
                type="textarea"
                v-model="FormDialog.SupplierRelated.Sigmatek_MessageToSupplier"
                :disabled="true"
                class="edit-input2 edit-input"
                size="small"
              />
            </td>
          </tr>
        </table>
      </div>

      <el-divider content-position="left" class="divider">
        需填写部分
      </el-divider>

      <h6 style="display:inline-block">维修信息| Analysis:</h6>
      <table border="1" cellspacing="0">
        <tr>
          <td class="Analysis">
            <el-input
              ref="analysis"
              type="textarea"
              v-model="FormDialog.analysis"
              class="edit-input2 edit-input"
              size="small"
            />
          </td>
        </tr>
      </table>

      <h6>更换部件信息| Components Information:</h6>

      <table 
        border="1" 
        cellspacing="0" 
        id="repair-list"
      >
        <tr>
          <td class="clomun11">No.</td>
          <td class="clomun12">物料名称 <br> Parts</td>
          <td class="clomun3"> 订货号 <br> Articl No.</td>
          <td class="clomun4"> 故障物料序列号 <br> Defect Part No.</td>
          <td class="clomun15">新物料序列号 <br> New part No.</td>
          <td class="clomun17">数量 <br> QTY.</td>
          <td class="clomun16">单价 <br> Price</td>
          <td class="clomun16">合价 <br> Price</td>
        </tr>

        <tr v-for="(item, index) in FormDialog.SuppliercomponentsInfomation" :key="index">
          <td class="delete" v-on:click="remove(index)">{{ index + 1 }}</td>
          <td>
            <el-input
              type="textarea"
              autosize
              v-model="item.name"
              class="edit-input"
              size="small"
            />
          </td>
          <td>
            <el-input
              type="text"
              v-on:change="onComponentsChange(index)"
              v-model="item.number"
              class="edit-input"
              size="small"
            />
          </td>
          <td>
            <el-input
              type="text"
              v-model="item.badNr"
              class="edit-input"
              size="small"
            />
          </td>
          <td>
            <el-input
              type="text"
              v-model="item.newNr"
              class="edit-input"
              size="small"
            />
          </td>
          <td>
            <el-input
              type="text"
              v-model="item.count"
              class="edit-input input-right"
              size="small"
            />
          </td>
          <td>
            <el-input
              @blur="caluete(item)"
              type="text"
              v-model="item.price"
              class="edit-input input-right"
              size="small"
            />
          </td>
          <td style="text-align:right">
            {{(item.price ? parseFloat(item.price)*parseInt(item.count):0).toFixed(2)}}
          </td>
        </tr>

        <tr>
          <td class="create" v-on:click="creatNew"></td>

          <td colspan="2" ref="Quote">
            <p style="display: inline-block">快递单号:</p>
            <input
              class="Reportinput"
              v-model="FormDialog.SupplierRelated.Supplier_TrackingNumber"
            />
          </td>

          <td colspan="2" ref="reportNumber">
            <p style="display: inline-block;margin-right: 10px;">快递发出时间:</p>
            <el-date-picker
              class="edit-input date ReportDate"
              style="width: 70%;"
              v-model="FormDialog.SupplierRelated.Supplier_DeliveryDate"
              :clearable="false"
              type="date"
              size="small"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="timestamp">
            </el-date-picker>
          </td>

          <td colspan="2">合计（含税）<br> Total Price(INC.VAT.)</td>
          <td class="text-right">
            <el-input
              type="text"
              v-model="totalPrice"
              class="edit-input input-right"
              size="small"
            />
          </td>
        </tr>
      </table>

    </div>
  </div>
</template>

<script>
  import { Loading } from "element-ui";
  import {
    updateArticle,
    fetchArticle,
  } from "@/api/article";
  import { MessageBox, Message } from "element-ui";

  export default {
    data() {
      return {
        listQuery: null,
        FormDialog: {
          id: null,
          customerInfo: {
            company: null,
            contactor: null,
            tel: null,
            fax: null,
            add: null,
            WarrantyDays: null,
          },
          productInfo: {
            SN: null,
            module: null,
            articl: null,
            hardware: null,
            software: null,
            report: null,
            parts: null,
            po: null,
            return: null,
            delivery: null,
            warranty: null,

            Quote: null,
            reportNumber: null,
          },
          failureDescription: null,
          faultclass: null,
          faultSecondclass:null,
          analysis: null,
          componentsInfomation: [
            {
              name: null,
              count: null,
              number: null,
              badNr: null,
              newNr: null,
              price: null,
            },
          ],
          SuppliercomponentsInfomation: [
            {
              name: null,
              count: null,
              number: null,
              badNr: null,
              newNr: null,
              price: null,
            },
          ],
          back: false,
          SupplierRelated: {
            Sigmatek_MessageToSupplier: null,
            Supplier_Complete: null,
            Supplier_TotalPrice: null,
            Supplier_TrackingNumber: null,
            Supplier_DeliveryDate: null,
          },
          times: null,
          contactor: "null",
          Date: null,
          sigmatekReport: null,
          ups: {
            sendType: null,
            sendDate: null,
            backType: null,
            backDate: null,
          },
        },
      };
    },

    mounted: function () {
      this.listQuery = this.$route.query.filters;
      this.id = this.$route.query.form_infoid;
      this.GetForm();
    },

    // created() {
    //   this.GetForm();
    // },

    computed: {
      totalPrice: function () {
        let total = 0;
        let haveo = false;
        // console.log(this.FormDialog)
        for (let i = 0; i < this.FormDialog.SuppliercomponentsInfomation.length; i++) {
          if (
            (this.FormDialog.SuppliercomponentsInfomation[i].price !== "") &
            (this.FormDialog.SuppliercomponentsInfomation[i].price !== null) &
            (this.FormDialog.SuppliercomponentsInfomation[i].price !== undefined)
          ) {
            total += parseFloat(this.FormDialog.SuppliercomponentsInfomation[i].price) * this.FormDialog.SuppliercomponentsInfomation[i].count;
            if (this.FormDialog.SuppliercomponentsInfomation[i].price.indexOf("€") != -1) {
              haveo = true;
            }
          }
        };

        this.FormDialog.SupplierRelated.Supplier_TotalPrice = total;
        if (haveo) {
          return total.toFixed(2) + "€";
        } else {
          return total.toFixed(2);
        };
      },
    },

    methods: {
      async GetForm() {
        if (!this.id) {
          this.id = "";
        }
        let loadingInstance = Loading.service({ fullscreen: true });

        const { data } = await fetchArticle(this.id);
        Object.assign(this.FormDialog, data);

        loadingInstance.close();
      }, //加载表格

      async updateForm() {
        const updateData = {
          id: this.FormDialog._id,
          data: this.FormDialog,
        };
        const data = await updateArticle(updateData);
        if (data) {
          this.id = data.data;
          Message({
            message: "保存成功",
            type: "success",
            duration: 5 * 1000,
          });
        }
      },

      caluete(item) {
       // console.log(item.price)
        if (item.price) {
          if (item.price.indexOf("€") != -1) {
            item.price = parseFloat(item.price).toFixed(2) + "€";
          } else item.price = parseFloat(item.price).toFixed(2);
        }
      },      

      creatNew() {
        if (this.FormDialog.SuppliercomponentsInfomation.length < 10) {
          this.FormDialog.SuppliercomponentsInfomation.push({
            name: null,
            number: null,
            badNr: null,
            newNr: null,
            count: 1,
            price: null,
          });
        }
      },
      remove(index) {
        this.FormDialog.SuppliercomponentsInfomation.splice(index, 1);
      },

      ConfirmCompletion(state) {
        if (state === undefined){
          state = false;
        }
        if (state) {
          this.FormDialog.SupplierRelated.Supplier_Complete = false;
        } else {
          this.FormDialog.SupplierRelated.Supplier_Complete = true;
        }
      }, //确认完成按钮
    }
  }
</script>


<style scoped >
.edit-input >>> .el-input__inner {
  padding-right: 100px;
  border: 0px;
  height: 3.5mm;
  padding: 0;
  width: 98%;
  color: #000;
  background-color: white;
  font-size: 2.5mm;
}
.edit-input >>> .el-textarea__inner {
  padding-right: 100px;
  overflow: hidden !important;
  border: 0px;
  resize: none;
  padding: 0;
  width: 98%;
  min-height: 16mm;
  font-size: 2.5mm;
  background-color: white;
  color: #000;
}
.left_space {
  margin-left: 4mm;
}
.edit-input1 >>> .el-textarea__inner {
  min-height: 17mm !important;
  /* height: 15mm; */
  overflow: hidden;
}
.noPadding {
  padding: 0;
  width: 100%;
}
.delete:hover {
  background-image: url("../../../Img/delete.jpg");
  background-repeat: no-repeat;
  color: transparent;
  background-size: cover;
  cursor: pointer;
}
.create:hover {
  background-image: url("../../../Img/create.jpg");
  background-repeat: no-repeat;
  color: transparent;
  background-size: cover;
  cursor: pointer;
}
.delete:hover button {
  color: transparent;
}
.date >>> .el-input__inner::-webkit-inner-spin-button {
  display: none;
}
.date >>> .el-input__inner::-webkit-clear-button {
  display: none;
}
.date >>> .el-input__inner::-webkit-calendar-picker-indicator {
  width: 2mm;
  padding: 0px;
  margin: 0;
}
h2 {
  margin-top: 2mm;
  font-size: 6mm;
}
.transparent:visited {
  border: 0;
}
.transparent:focus {
  border: 0;
}
.transparent:active {
  border: 0;
}
.log {
  position: relative;
  width: 40mm;
  height: 4mm;
  left: 125mm;
  top: 10mm;
}
.bottomArea {
  position: absolute;
  bottom: 2mm;
}
.noBorder {
  border: 0 !important;
}
.text_right {
  text-align: right;
}
.sizesmall {
  height: 4mm;
}
.check-input {
  background-color: black;
  position: relative;
  top: 1mm;
  left: 1mm;
}

.title {
  width: 20mm;
  display: inline-block;
}
.title-right {
  width: 30mm;
  display: inline-block;
}
.relative {
  position: relative;
}
.edit-input2 >>> .el-textarea__inner {
  border: 0;
  min-height: 20mm !important;
  overflow: hidden;
  height: auto;
  color: #000;
}
.edit-input3 >>> .el-textarea__inner {
  border: 0;
  min-height: 6mm !important;
  margin-top: 3mm;
  overflow: hidden;
  height: auto;
  color: #000;
}
.bottom-input >>> .el-input__inner {
  padding-right: 100px;
  border: 0;
  height: 4mm;
  padding: 0;
  margin: 0px;
  font-size: 2.5mm;
  color: #000;
}
.app-container {
  height: 500mm;
}
.inlineblock {
  display: inline-block;
}
.timesInput {
  display: inline-block;
  width: 4mm;

  border-bottom: 1px solid #000;
}
.area_right {
  position: absolute;
  right: 6mm;
  top: 0px;
}
.writeInput {
  display: inline-block;
  width: 20mm;
  height: 5.5mm;
  border-bottom: 0.5px solid #000;
}
.bottomInfo {
  margin-top: 2.2mm;
  font-size: 2.5mm;
  margin-bottom: 0.1mm;
}
table {
  border-right: 0.5px solid #000;
  border-bottom: 0.5px solid #000;
  width: 166mm;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
td {
  word-break: break-all;
  font-size: 2.3mm;
}
.clomun1 {
  width: 28mm;
}
.clomun11 {
  width: 5mm;
}
.clomun12 {
  width: 43mm;
}
.clomun15 {
  width: 40mm;
}
.clomun16 {
  width: 14mm;
}
.clomun2 {
  width: 20mm;
}
.clomun3 {
  width: 28mm;
}
.clomun4 {
  width: 40mm;
}
.clomun5 {
  width: 32mm;
}
.clomun6 {
  width: 23mm;
}
.Failure {
  vertical-align: top;
  min-height: 16mm;
}
.Analysis {
  vertical-align: top;
  /* min-height: 24mm; */
}
.inline {
  display: inline-block;
  width: 7px;
}
.grayfont {
  color: gray;
}
.Notice {
  height: 12mm;
  vertical-align: top;
}
.Notice p {
  margin: 1px;
}
.Notice td {
  padding: 0px;
}
#printTest {
  position: relative;
  top: 100mm;
  left: 75mm;
  padding: 0.5mm;
  margin-right: 400px;
  margin-bottom: 0px;
  width: 173mm;
  height: 242mm;
  transform: scale(1.8);
}
.italic {
  font-style: italic;
  font-size: 5mm;
}
tr {
  height: 4mm;
}
.filter >>> .el-dialog {
  width: 80%;
}
td {
  height: 4mm;
  border-left: 0.5px solid #000;
  border-top: 0.5px solid #000;
}
.transparent {
  background-color: transparent;
}
h6 {
  font-weight: bold;
  margin-top: 2.5mm;
  margin-bottom: 0.6mm;
}

.text-right {
  font-family: sans-serif;
  font-size: 2.5mm;
  text-align: right;
  padding-right: 0.6mm;
  color: #000;
}
.input-right >>> .el-input__inner {
  text-align: end;
  color: #000;
}
.Reportinput {
  width: 70%;
  border: none;
  outline: medium;
  display: inline-block;
  background-color: white
}
.ReportDate >>> .el-icon-date:before {
    content: none;
}
.Quoteinput {
  width: 40%;
  border: none;
  outline: medium;
  display: inline-block;
}
.clomun33 {
  width: 50mm;
}
.faultclass >>> .el-input__inner{
  font-size: 12px;
  /* margin-left: 10px; */
}
.faultclass{
  margin-left: 10px;
  /* margin-right: 10px; */
}
.divider >>> .el-divider__text {
  padding: 0 5px;
  font-size: 10px;
}
.divider{
  margin: 15px 0 5px 0;
  width: 97%;
}

.Revertstyle{
  display: inline-block;
  margin-top: 3px;
  margin-bottom: 3px;
}
.clomun17 {
  width: 15mm;
}
.Revertinput{

}
</style>
