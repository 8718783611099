<template>
  <div class="app-container">
    <el-dialog
      title="项目名称修改"
      :visible.sync="changeProjectNamevisialbe"
      width="30%"
      :close-on-click-modal="false"
    >
      <div style="margin-left: 5px">修改名称：</div>
        <el-input
          style="margin-top: 10px; margin-bottom: 10px"
          v-model="editProject.name"
        />
  
      <div style="margin-left: 5px">项目代码：</div>
        <el-input
          style="margin-top: 10px; margin-bottom: 10px"
          v-model="editProject.ProjectCode"
        />

      <div style="margin-left: 5px; margin-bottom: 10px">修改备注：</div>
        <el-input
          type="textarea"
          :autosize="{ minRows: 2 }"
          placeholder="请输入内容"
          v-model="editProject.ProjectRemarks"
        >
      </el-input>

      <div>
        <span slot="footer" class="dialog-footer" style="margin-top: 10px">
          <div class="buttongroup">
            <el-button @click="changeProjectNamevisialbe = false">
              取 消
            </el-button>
            <el-button type="primary" @click="confirmchange"> 确 定 </el-button>
          </div>
        </span>
      </div>
    </el-dialog>

    <el-dialog
      title="项目删除"
      :visible.sync="deleteProjectvisialbe"
      width="30%"
      :close-on-click-modal="false"
    >
      <div v-if="isOwner()" class="notes">删除项目</div>
      <div v-if="isMember()" class="notes">退出项目</div>

      <div>
        <span slot="footer" class="dialog-footer">
          <div class="buttongroup">
            <el-button @click="deleteProjectvisialbe = false">
              取 消
            </el-button>
            <el-button v-if="isOwner()" type="danger" @click="confirmDelete">
              确 定
            </el-button>
            <el-button v-if="isMember()" type="danger" @click="confirmQuit">
              确 定
            </el-button>
          </div>
        </span>
      </div>
    </el-dialog>

    <el-dialog
      title="项目共享"
      :visible.sync="memberInfovisialbe"
      width="30%"
      :close-on-click-modal="false"
    >
      <div class="notes">添加成员</div>
      <!-- <el-input list="browsers" style="margin-top: 10px;margin-bottom: 50px" type="text" v-model="newUser"
          class="edit-input" size="small">
          <el-button @click="addUser" slot="append" icon="el-icon-circle-plus"></el-button>
        </el-input>
        <datalist id="browsers">
          <option v-for="item in Userlist">
            {{ item["username"] }}
          </option>
        </datalist> -->
      <el-select
        style="width: 80%; margin-top: 20px"
        v-model="newUser"
        placeholder="分配给"
      >
        <template slot="prefix">
          <span
            style="
              padding: 5px;
              line-height: 33px;
              font-size: 18px;
              color: #409eff;
            "
          >
            <i class="el-icon-user-solid"></i>
          </span>
        </template>

        <el-option
          v-for="item in Userlist"
          :key="item.username"
          :value="item.username"
        >
          <span style="float: left">
            <i class="el-icon-user-solid"></i>{{ item.username }}</span
          >
        </el-option>
      </el-select>
      <el-button
        style="width: 19%"
        @click="addUser"
        icon="el-icon-circle-plus"
      ></el-button>
      <div style="margin-top: 10px" class="notes">列表成员</div>
      <el-table
        v-loading="listLoading"
        :data="editProject.member"
        height="300"
        element-loading-text="Loading"
        :border="false"
        fit
        highlight-current-row
      >
        <el-table-column width="310" align="left">
          <template slot="header" slot-scope="scope">
            <el-avatar style="font-size: 12px; background-color: #409eff">
              {{ editProject.creater }}
            </el-avatar>
          </template>
          <template slot-scope="scope">
            <el-avatar
              style="font-size: 12px; background-color: cornflowerblue"
            >
              {{ scope.row }}
            </el-avatar>
          </template>
          <!-- <el-input></el-input> -->
        </el-table-column>

        <el-table-column label="所有者" width="99" align="center">
          参与者
        </el-table-column>
      </el-table>
      <div>
        <span slot="footer" class="dialog-footer">
          <div class="buttongroup">
            <el-button @click="memberInfovisialbe = false"> 取 消 </el-button>
            <el-button type="primary" @click="confirmMemberchange">
              确 定
            </el-button>
          </div>
        </span>
      </div>
    </el-dialog>

    <el-dialog
      title="附件"
      :visible.sync="appendixInfovisialbe"
      width="50%"
      :close-on-click-modal="false"
    >
      <el-upload
        action="''"
        multiple
        accept=".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PBG, .GIF, .pdf, .lds"
        :http-request="ProjectsubmitList"
        :show-file-list="false"
      >
        <el-button icon="el-icon-paperclip">添加附件</el-button>
      </el-upload>

      <el-table
        v-loading="listLoading"
        style="width: 100%; margin-top: 10px"
        :data="actProject.file"
        class="marginTop"
        element-loading-text="Loading"
        border
        fit
        highlight-current-row
        @row-click="projectannexPreview"
      >
        <el-table-column align="center" label="文件列表">
          <template slot-scope="scope">
            <span>{{ scope.row }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-download"
              size="mini"
              circle
              v-on:click="downloadProjectFile(scope.row)"
            >
            </el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              circle
              v-on:click="deleteProjectFile(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      title="步骤删除"
      :visible.sync="deleteStepvisialbe"
      width="30%"
      :close-on-click-modal="false"
    >
      <div class="notes">确定删除</div>

      <div>
        <span slot="footer" class="dialog-footer">
          <div class="buttongroup">
            <el-button @click="deleteStepvisialbe = false"> 取 消 </el-button>
            <el-button type="primary" @click="confirmDeleteStep">
              确 定
            </el-button>
          </div>
        </span>
      </div>
    </el-dialog>

    <el-dialog
      title="步骤编辑"
      :visible.sync="stepDetailvisialbe"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form ref="editStep" :model="editStep" label-width="80px">
        <el-form-item label="步骤名称">
          <el-input v-model="editStep.name"> </el-input>
        </el-form-item>
        <el-form-item label="截止时间">
          <el-date-picker
            v-model="editStep.endTime"
            align="right"
            type="date"
            style="width: 100%; margin-top: 10px"
            :class="getSetDateStyle(editStep.endTime)"
            placeholder="添加截止日期"
            format="yyyy 年 MM 月 dd 日 到期"
            value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="日志">
          <el-input
            type="textarea"
            style="width: 100%; margin-top: 20px"
            :autosize="{ minRows: 2 }"
            placeholder="请输入日志"
            v-model="editStep.log"
          >
          </el-input>
        </el-form-item>
      </el-form>

      <div>
        <span slot="footer" class="dialog-footer">
          <div class="buttongroup">
            <el-button @click="stepDetailvisialbe = false"> 取 消 </el-button>
            <el-button type="primary" @click="confirmEditStep">
              确 定
            </el-button>
          </div>
          
        </span>
      </div>
    </el-dialog>

    <el-dialog
      title="任务删除"
      :visible.sync="deleteTaskVisble"
      width="30%"
      :close-on-click-modal="false"
    >
      <div class="notes">确定删除</div>
      <div>
        <span slot="footer" class="dialog-footer">
          <div class="buttongroup">
            <el-button @click="deleteTaskVisble = false"> 取 消 </el-button>
            <el-button type="primary" @click="confirmDeleteTask">
              确 定
            </el-button>
          </div>
        </span>
      </div>
    </el-dialog>

    <el-dialog
      title="出差记录"
      :visible.sync="TravelReport"
      width="80%"
      :close-on-click-modal="false"
    >
      <div class="buttongroup">
        <el-button type="primary" v-on:click="NewInfo(null)"
          >新建行程</el-button
        >
      </div>
      <!-- <div>
        <el-table
          :data="list"
          element-loading-text="Loading"
          border
          fit
          highlight-current-row
        >
          <el-table-column align="center" label="ID" width="95">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>

          <el-table-column align="center" label="报表日期">
            <template slot-scope="scope">
              {{ scope.row. }}
            </template>
          </el-table-column>

          <el-table-column align="center" label="费用代码">
            <template slot-scope="scope">
              {{ scope.row. }}
            </template>
          </el-table-column>

          <el-table-column align="center" label="报销说明">
            <template slot-scope="scope">
              {{ scope.row. }}
            </template>
          </el-table-column>

          <el-table-column align="center" label="">
            <template slot-scope="scope">
              {{ scope.row. }}
            </template>
          </el-table-column>

          <el-table-column align="center" label="">
            <template slot-scope="scope">
              {{ scope.row. }}
            </template>
          </el-table-column>

          <el-table-column align="center" label="操作" width="255">
            <template slot-scope="scope">
              <div>
                <el-button v-on:click="" type="button">
                  编辑
                </el-button>
                <el-button v-on:click="" type="button">
                  删除
                </el-button>
              </div>
            </template>
          </el-table-column>

        </el-table>
      </div> -->
    </el-dialog>

    <el-dialog 
      :visible.sync="SelectReportVisible" 
      title="选择报表" 
      :close-on-click-modal="false"
    >
      <div>
        <p style="display: flex ; justify-content: center ; padding-bottom : 15px ;font-size: 20px">请选择需要填写或打印的报表类型</p>
        
        <el-row>
          <el-col :span="12">
            <div>
              <el-button 
                class="card-button"  
                v-on="$listeners"
                @click="SelectReportClick('button1')"
              >
                <el-card>
                  <img src="../../icons/LYJBXD.jpg" style="height: 380px ; width: 100%; object-fit: contain">
                  <div style="padding: 15px ; font-size: 20px;display: flex ; justify-content: center">
                    零用金报销单
                  </div>
                </el-card>
              </el-button>
            </div>
          </el-col>
          
          <el-col :span="12">
            <div>
              <el-button 
                class="card-button"  
                v-on="$listeners"
                @click="SelectReportClick('button2')"
              >
                <el-card>
                  <img src="../../icons/GZBG.jpg" style="height: 380px ; width: 100%; object-fit: contain">
                  <div style="padding: 15px ; font-size: 20px;display: flex ; justify-content: center">
                    工作报告
                  </div>
                </el-card>
              </el-button>
            </div>
          </el-col>

        </el-row>
      </div>
    </el-dialog>

    <el-row :gutter="10">
      <el-col :span="8 - (taskItemWidth / 7) * 2">
        <div class="grid-content">
          <el-button v-on:click="creatList">新建项目</el-button>

          <el-table
            v-loading="listLoading"
            style="width: 100%"
            @cell-click="getProject"
            :data="list"
            height="750"
            element-loading-text="Loading"
            :border="false"
            fit
            highlight-current-row
          >
            <el-table-column
              prop="name"
              label="项目名称"
              width="210"
              align="left"
            >
              <template slot-scope="scope">
                  <span v-if="taskItemWidth == 0" style="font-size:15px;">
                    {{ scope.row.name }}
                  </span>
                  <span v-if="!(taskItemWidth == 0)">
                    {{ scope.row.name }}
                  </span>
                  <el-tag v-if="scope.row.ProjectCode" size="mini" style="margin-left:5px;">
                    {{ scope.row.ProjectCode }}
                  </el-tag>
              </template>
            </el-table-column>

            <el-table-column
              v-if="taskItemWidth == 0"
              width="20"
              align="center"
            >
            </el-table-column>

            <el-table-column
              v-if="taskItemWidth == 0"
              width="48"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  size="mini"
                  @click="showChangeProjectName(scope.$index, scope.row)"
                  circle
                ></el-button>
              </template>
              <!-- <el-input></el-input> -->
            </el-table-column>

            <el-table-column
              v-if="taskItemWidth == 0"
              width="48"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  @click="showDeleteProject(scope.$index, scope.row)"
                  circle
                ></el-button>
              </template>
              <!-- <el-input></el-input> -->
            </el-table-column>

            <el-table-column
              v-if="taskItemWidth == 0"
              width="48"
              align="center"
            >
              <template slot-scope="scope">
                <el-badge
                  style="margin-top: 10px; margin-bottom: 10px"
                  :value="memberLength(scope.row.member)"
                  type="warning"
                >
                  <el-button
                    type="primary"
                    size="mini"
                    icon="el-icon-s-custom"
                    @click="showMemberInfo(scope.$index, scope.row)"
                    circle
                  >
                  </el-button>
                </el-badge>
              </template>
            </el-table-column>

            <el-table-column prop="remaining" label="待处理" align="center">
            </el-table-column>
          </el-table>
        </div>
      </el-col>
      <el-col :span="15 - (taskItemWidth / 7) * 5">
        <div class="grid-content bg-purple">
          <div style="display: flex">
            <div>
              <el-container>
                <el-header class="Tilteheader" height="40px">
                  {{ actProject.name }}
                </el-header>
                  <el-popover
                          class="item"
                        
                          width="200"
                          trigger="hover"
                          :content="actProject.ProjectRemarks"
                          placement="top-start"
                        >
                    <el-footer slot="reference" class="Tiltefooter" height="20px">
                      {{ actProject.ProjectRemarks }}
                  </el-footer>
               </el-popover>
              </el-container>
            </div>

            <div>
              <span
                style="margin: 10px; display: inline-block; position: relative"
              >
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  size="samll"
                  @click="showChangeProjectName(null, actProject)"
                  circle
                >
                </el-button>

                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="samll"
                  @click="showDeleteProject(null, actProject)"
                  circle
                ></el-button>

                <el-badge
                  style="margin-left: 10px"
                  :value="memberLength(actProject.member)"
                  type="warning"
                >
                  <el-button
                    type="primary"
                    size="samll"
                    icon="el-icon-s-custom"
                    @click="showMemberInfo(null, actProject)"
                    circle
                  ></el-button>
                </el-badge>

                <el-badge
                  style="margin-left: 12px"
                  :value="memberLength(actProject.file)"
                  type="warning"
                >
                  <el-button
                    type="primary"
                    size="samll"
                    icon="el-icon-paperclip"
                    @click="showAppendixInfo(null, actProject)"
                    circle
                  ></el-button>
                </el-badge>
              </span>
            </div>
          </div>

          <el-table
            v-loading="TasklistLoading"
            :data="Tasklist"
            element-loading-text="Loading"
            height="650"
            :show-header="false"
            @cell-click="getTaskItem"
            :border="false"
            style="width: 100%; margin-top: 10px"
            fit
            highlight-current-row
          >
            <el-table-column type="expand" width="28">
              <template slot-scope="props">
                <el-table
                  :data="props.row.step"
                  :show-header="false"
                  max-height="480"
                  :border="false"
                >
                  <el-table-column width="30 "> </el-table-column>
                  <el-table-column type="expand" width="30 " align="right">
                    <template slot-scope="props">
                      <div style="margin-left: 40px">
                        <h3>日志</h3>

                        <el-input
                          type="textarea"
                          disabled
                          style="width: 100%; margin-top: 10px"
                          :autosize="{ minRows: 2, maxRows: 20 }"
                          v-model="props.row.log"
                        >
                        </el-input>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column width="50 ">
                    <template slot-scope="scope">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="切换完成状态"
                        placement="top"
                      >
                        <el-button
                          v-show="scope.row.status === false"
                          @click="changeStepStatusInlist(scope.row, props.row)"
                          circle
                        >
                        </el-button>
                      </el-tooltip>
                      <i
                        v-show="scope.row.status"
                        style="font-size: 27px; color: #409eff"
                        @click="changeStepStatusInlist(scope.row)"
                        size="mini"
                        circle
                        slot="prepend"
                        :class="TaskStatusIcon(scope.row.status)"
                      ></i>
                    </template>
                  </el-table-column>
                  <el-table-column>
                    <template slot-scope="scope">
                      <div @click="getFatherTask(props.row)">
                        <p style="margin: 1px; font-size: 16px">
                          {{ scope.row.name }}
                        </p>
                      </div>
                      <div @click="getFatherTask(props.row)">
                        <span
                          :style="
                            getDateStyle(scope.row.endTime, scope.row.status)
                          "
                          v-if="scope.row.endTime > 0"
                        >
                          {{ getDateTxt(scope.row.endTime) }}
                        </span>

                        <span
                          v-if="
                            scope.row.log !== undefined &&
                            scope.row.log !== null &&
                            scope.row.log !== ''
                          "
                          class="red"
                        >
                          <i class="el-icon-tickets" />
                        </span>
                        <!-- <span v-if="checkUps(scope.row)" class="yellow">
                            <i class="el-icon-s-promotion" />
                          </span> -->
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column width="50" align="center">
                    <template slot-scope="scope">
                      <el-dropdown trigger="click">
                        <el-button type="primary" size="mini" circle>
                          <i class="el-icon-arrow-down el-icon-menu"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            ><el-button
                              style="margin-top: 5px"
                              type="primary"
                              icon="el-icon-edit"
                              @click="
                                showstepDetail(
                                  scope.$index,
                                  scope.row,
                                  props.row
                                )
                              "
                              >编辑</el-button
                            ></el-dropdown-item
                          >
                          <el-dropdown-item>
                            <el-button
                              type="danger"
                              style="margin-top: 5px"
                              icon="el-icon-delete"
                              @click="
                                showDeleteStep(
                                  scope.$index,
                                  scope.row,
                                  props.row
                                )
                              "
                              >删除步骤</el-button
                            >
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column width="62" align="left">
                    <template slot-scope="scope">
                      <el-button style="margin-top: 5px;" size="mini" type="primary" icon="el-icon-edit"
                          @click="showstepDetail(scope.$index, scope.row)" circle></el-button>
                    </template>
                  </el-table-column> -->
                </el-table>
              </template>
            </el-table-column>
            <el-table-column width="48" align="center">
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="切换完成状态"
                  placement="top"
                >
                  <el-button
                    v-show="scope.row.status === false"
                    @click="changeTaskStatus(scope.row)"
                    circle
                  >
                  </el-button>
                </el-tooltip>
                <i
                  v-show="scope.row.status"
                  style="font-size: 27px; color: #409eff"
                  @click="changeTaskStatus(scope.row)"
                  size="mini"
                  circle
                  slot="prepend"
                  :class="TaskStatusIcon(scope.row.status)"
                ></i>
              </template>
            </el-table-column>

            <el-table-column width="70" align="center">
              <template slot-scope="scope">
                {{ getShortDate(scope.row.createTime) }}
              </template>
            </el-table-column>

            <el-table-column prop="name" align="left">
              <template slot-scope="scope">
                <div>
                  <p style="margin: 1px; font-size: 20px">
                    {{ scope.row.name }}
                  </p>
                </div>
                <div>
                  <span
                    style="color: darkgray; margin-right: 10px"
                    v-if="scope.row.step.length > 0"
                  >
                    {{ getStepTxt(scope.row) }}
                  </span>
                  <span
                    :style="getDateStyle(scope.row.endTime, scope.row.status)"
                    v-if="scope.row.endTime > 0"
                  >
                    {{ getDateTxt(scope.row.endTime) }}
                  </span>
                  <span
                    v-if="
                      scope.row.file !== undefined && scope.row.file.length > 0
                    "
                    class="blue"
                  >
                    <i class="el-icon-paperclip" />
                  </span>
                  <span
                    v-if="
                      scope.row.comment !== undefined &&
                      scope.row.comment !== null &&
                      scope.row.comment !== ''
                    "
                    class="red"
                  >
                    <i class="el-icon-tickets" />
                  </span>
                  <!-- <span v-if="checkUps(scope.row)" class="yellow">
                        <i class="el-icon-s-promotion" />
                      </span> -->
                </div>
              </template>
            </el-table-column>

            <el-table-column width="62" align="left">
              <template slot-scope="scope">
                <el-avatar
                  v-if="
                    scope.row.worker !== null && scope.row.worker.length > 0
                  "
                  style="font-size: 10px; background-color: cornflowerblue"
                >
                  {{ scope.row.worker[0] }}
                </el-avatar>
              </template>
            </el-table-column>
            <el-table-column width="48" align="center">
              <template slot-scope="scope">
                <i
                  style="font-size: 18px; color: #409eff"
                  @click="changePriority(scope.row)"
                  size="mini"
                  circle
                  slot="prepend"
                  :class="TaskPriorityIcon(scope.row.priority)"
                ></i>
              </template>
            </el-table-column>
          </el-table>
          <el-input
            style="margin-top: 10px"
            type="text"
            v-model="newTask"
            class="edit-input"
            size="small"
          >
            <el-button
              @click="changeShowFinshTask"
              slot="prepend"
              :icon="finshTaskIcon(showFinshTask)"
              >{{ "已完成" + taskFinshCounter }}
            </el-button>
            <el-button
              @click="creatTask"
              slot="append"
              icon="el-icon-circle-plus"
            ></el-button>
          </el-input>
        </div>
      </el-col>
      <el-col :span="taskItemWidth">
        <div class="grid-content" style="height: 450">
          <div class="frame" style="margin-bottom: 10px">
            <el-row style="margin-bottom: 0px; padding: 7px">
              <el-col :span="19">
                <p style="display: inline-block" v-if="actTask.status == false">
                  由{{ actTask.creater }}创建于{{ getDate(actTask.createTime) }}
                </p>
                <p style="display: inline-block" v-if="actTask.status == true">
                  由{{ actTask.finisher }}完成于{{
                    getDate(actTask.finishTime)
                  }}
                </p>
              </el-col>
              <el-col :span="5">
                <!-- <el-badge :value="12" class="item">
                  <el-button type="primary" @click="TravelReportDisplay" size="small"
                    icon="el-icon-s-cooperation" circle/>  
                </el-badge> -->

                <el-button
                  type="primary"
                  @click="SelectReport(actTask._id)"
                  size="small"
                  icon="el-icon-tickets"
                  circle
                />
                <el-button
                  type="danger"
                  @click="showdeleteTaskVisble"
                  size="small"
                  icon="el-icon-delete"
                  circle
                />
              </el-col>
            </el-row>
          </div>

          <div class="frame">
    
            <el-table
              v-loading="stepLoading"
              :data="actStep"
            
               ref="table"
          
              element-loading-text="Loading"
              :border="false"
              row-key="id"
              style="width: 100%"
              fit
              highlight-current-row
            >
       
              <el-table-column width="45" align="right">
                <template slot="header" slot-scope="scope">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="切换完成状态"
                    placement="top"
                  >
                    <el-button
                      size="small"
                      style="margin-left: 1px; margin-bottom: 7px"
                      v-show="actTask.status === false"
                      @click="changeTaskStatus(actTask)"
                      circle
                    >
                    </el-button>
                  </el-tooltip>
                  <i
                    v-show="actTask.status"
                    style="font-size: 24px; color: #409eff"
                    @click="changeTaskStatus(actTask)"
                    size="mini"
                    circle
                    slot="prepend"
                    :class="TaskStatusIcon(actTask.status)"
                  ></i>
                </template>

                <template slot-scope="scope">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="切换完成状态"
                    placement="top"
                    style="margin-left: 15px"
                  >
                    <el-button
                      style="margin-left: 1px; margin-bottom: 7px"
                      v-show="scope.row.status === false"
                      @click="changeStepStatus(scope.row)"
                      size="mini"
                      circle
                    >
                    </el-button>
                  </el-tooltip>

                  <i
                    v-show="scope.row.status"
                    style="font-size: 18px; color: #409eff; margin-left: 15px"
                    @click="changeStepStatus(scope.row)"
                    size="mini"
                    circle
                    slot="prepend"
                    :class="TaskStatusIcon(scope.row.status)"
                  ></i>
                </template>
              </el-table-column>

              <el-table-column align="center">
                <template slot="header" slot-scope="scope">
                  <el-input
                    @change="taskNameChange"
                    v-model="actTask.name"
                    style="font-size: 20px"
                  >
                  </el-input>
                </template>

                <template slot-scope="scope">
                  <el-input @change="stepNameChange" v-model="scope.row.name" style="padding-left: 15px">
                  </el-input>
                </template>
              </el-table-column>

              <el-table-column width="50" align="center">
                <template slot="header" slot-scope="scope">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="重要标记"
                    placement="top"
                  >
                    <el-button
                      style="font-size: 18px; color: #409eff"
                      @click="changePriority(actTask)"
                      size="mini"
                      circle
                      :icon="TaskPriorityIcon(actTask.priority)"
                    >
                    </el-button>
                  </el-tooltip>
                </template>
                <template slot-scope="scope">
                  <el-dropdown trigger="click">
                    <el-button type="primary" size="mini" circle>
                      <i class="el-icon-arrow-down el-icon-menu"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        ><el-button
                          style="margin-top: 5px"
                          type="primary"
                          icon="el-icon-edit"
                          @click="
                            showstepDetail(scope.$index, scope.row, actTask)
                          "
                          >编辑</el-button
                        ></el-dropdown-item
                      >
                      <el-dropdown-item>
                        <el-button
                          type="danger"
                          style="margin-top: 5px"
                          icon="el-icon-delete"
                          @click="
                            showDeleteStep(scope.$index, scope.row, actTask)
                          "
                          >删除步骤</el-button
                        >
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                 
                </template>
              </el-table-column>
          
            </el-table>
        
            <el-input
              style="margin-top: 3px"
              type="text"
              placeholder="添加步骤"
              v-model="newStep"
              class="edit-input"
              size="small"
            >
              <el-button
                @click="creatStep"
                slot="prepend"
                icon="el-icon-circle-plus"
              ></el-button>
            </el-input>
          </div>

          <el-select
            style="width: 100%; margin-top: 10px"
        
            multiple
            v-model="actTask.worker"
            @change="workerChange"
            placeholder="分配给"
          >
            <template slot="prefix">
              <span
                style="
                  padding: 5px;
                  line-height: 33px;
                  font-size: 18px;
                  color: #409eff;
                "
              >
                <i class="el-icon-user-solid"></i>
              </span>
            </template>
            <el-option :key="actProject.creater" :value="actProject.creater">
              <span style="float: left">
                <i class="el-icon-user-solid"></i>{{ actProject.creater }}</span
              >
            </el-option>
            <el-option
              v-for="item in actProject.member"
              :key="item"
              :value="item"
            >
              <span style="float: left"
                ><i class="el-icon-user-solid"></i>{{ item }}</span
              >
            </el-option>
          </el-select>

          <el-date-picker
            v-model="actTask.endTime"
            align="right"
            type="date"
            style="width: 100%; margin-top: 10px"
            :class="getSetDateStyle(actTask.endTime)"
            placeholder="添加截止日期"
            @change="endTimeChange"
            format="yyyy 年 MM 月 dd 日 到期"
            value-format="timestamp"
          >
          </el-date-picker>

          <el-table
            v-loading="listLoading"
            style="width: 100%; margin-top: 20px"
            :data="actTask.file"
            class="marginTop"
            element-loading-text="Loading"
            border
            fit
            highlight-current-row
            @row-click="annexPreview"
          >
            <el-table-column align="center" label="文件列表" >
              <template slot-scope="scope">
                <span>{{ scope.row }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="110">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  icon="el-icon-download"
                  size="mini"
                  circle
                  v-on:click="downloadFile(scope.row)"
                >
                </el-button>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  circle
                  v-on:click="deleteFile(scope.row)"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-upload
            action="''"
            multiple
            accept=".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PBG, .GIF, .pdf, .lds"
            :http-request="submitList"
            :show-file-list="false"
          >
            <el-button icon="el-icon-paperclip">添加附件</el-button>
          </el-upload>
          <el-input
            type="textarea"
            style="width: 100%; margin-top: 20px"
            :autosize="{ minRows: 2, maxRows: 4 }"
            @change="commentChange"
            placeholder="请输入备注"
            v-model="actTask.comment"
          >
          </el-input>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Sortable from 'sortablejs';

import { MessageBox, Message, Icon } from "element-ui";
import { uploadExtra, download, deleteFile } from "@/api/fileTransfer";
import {
  getProjectlist,
  createProjectlist,
  deleteProjectList,
  updateProjectList,
  getTasklist,
  createTasklist,
  deleteTaskList,
  updateTaskList,
  CreateReportContent,
  UpdateReportContent,
  DeleteReportContent,
} from "@/api/technology";
import { getName } from "@/utils/auth";
import { getUserList } from "@/api/user";

import { exit } from "process";

export default {
    filters: {
        statusFilter(status) {
            const statusMap = {
                published: "success",
                draft: "gray",
                deleted: "danger",
            };
            return statusMap[status];
        },
    },

    data() {
        return {
            filterSN: null,
            _id: null,
            list: [],
            editProject: {},
            actProject: {},
      actTask: {},
      actStep:[],
      activeName: "first",
      newStep: null,
      editTask: null,
      tableRowKey: 'id',
      dragOptions: {
        animation: 150,
      },
            deleteTaskVisble: false,
            newTask: null,
            stepDetailvisialbe: false,
            editStep: {},
            deleteStepvisialbe: null,
            taskItemWidth: 0,
            deleteProjectvisialbe: false,
            memberInfovisialbe: false,
            appendixInfovisialbe: false,
            changeProjectNamevisialbe: false,
            Tasklist: [],
            tableData: [],
            taskFinshCounter: null,
            showFinshTask: true,
            taskName: null,
            Userlist: [],
            newUser: null,
            tableHeader: [],
            deleteStepIndex: null,
            waitFinshCounter: null,
            listLoading: false,
            TasklistLoading: false,
      TravelReport: false,
      stepLoading :false,
      total: null,
      actProjectID : null,
      totalTask: null,
      listQuery: {
                page: 0,
                limit: 0,
                sort: null,
                filters: {},
            },
            taskListQuery: {
                page: 0,
                limit: 0,
                sort: "({status:" + 1 + ",_id:" + -1 + "})",
                filters: {},
            },
            ReportContentInfo: {
                _id: null,
                ArchiveLocation: {
                    Items_id: null,
                    Assignment_id: null,
                    PersonnelInvolved: null,
                    CreationTime: null,
                },
                ReportDate: null,
                ExpenseCode: null,
                SettlementDateS: null,
                SettlementDateE: null,
                RelevantInfo: {
                    EmployeeName: null,
                    Position: null,
                    Department: null,
                    Email: null,
                    Manager: null,
                    ManagerEmail: null,
                },
                ReimbursementInstructions: null,
                ReimbursementDetails: [
                    {
                        Date: null,
                        Type: null,
                        Explain: null,
                        Classification: null,
                        Cost: null,
                    },
                ],
                CostAggregation: {
                    Subtotal: null,
                    Advance: null,
                    TravelAllowance: null,
                    TotalReimbursement: null,
                },
            },
            SelectReportVisible: false,
            selectedTaskId: null, //actTask._id暂存
        };
    },
    watch: {
        activeName(val) {
            this.$router.push(`${this.$route.path}?tab=${val}`);
        },
        filterSN(val) {
            this.filterbySN();
        },
    },
    mounted: function () {
      this.changeStepRow();
    },
    created() {
        //  this.fetchData()
        //  const tab = this.$route.query.tab
        //if (tab) {
    if (this.$route.query.projectID) {
      // this.DeliveryInfolist = this.$route.params.shippingObj.DeliveryInfolist;
      // this.HeadInof = this.$route.params.shippingObj.HeadInof;
      // this.PurchAserInfo = this.$route.params.shippingObj.PurchAserInfo;
      this.actProjectID = this.$route.query.projectID;
      if (typeof this.$route.query.actTask === 'object' && this.$route.query.actTask !== null) {
       this.actTask = this.$route.query.actTask;
       this.actStep = this.actTask.step ;
      }
    }
    this.fetchData();
    this.getUser();
    //  }
    },
    computed: {

    },

    methods: {
        async getUser() {
            const { data } = await getUserList();
            this.Userlist = data.list;
        },
        endTimeChange() {
            updateTaskList({
                id: this.actTask._id,
                data: {
                    endTime: this.actTask.endTime,
                },
            });
        },
    async changeStepRow(){
      const tableBody = this.$refs.table.$el.querySelector('.el-table__body-wrapper tbody');
      const el = this ;
      const sortable = Sortable.create(tableBody, {
        animation: 150,
        onEnd: async evt => {
        // console.log(el.actTask.step);
          el.stepLoading = true;
          const step =[];
          for (let i = 0 ; i< el.actStep.length;i++){
            step.push(el.actStep[i])
          }
          const movedItem =step.splice(evt.oldIndex, 1)[0];
          step.splice(evt.newIndex, 0, movedItem);
          el.actStep = [] ;
         //console.log(el.actTask.step);
          updateTaskList({
            id: el.actTask._id,
            data: {
            step: step,
            },
            
          });
   
      setTimeout(() => {
            //console.log(el.actStep);
            el.actStep =step;
      
          el.$nextTick(() => {
            // 在下一个 UI 更新周期中更新组件
            el.$refs.table.$forceUpdate()
            el.stepLoading = false;
            el.fetchTaskData();
          });
        }, 100);
      
        },onUpdate: () => {
        // 在 Sortable.js 的 onUpdate 回调函数中强制更新 el-table 组件
       
      }
      });
    },
    NewInfo(data) {
            (this.ReportContentInfo = {
                ArchiveLocation: {
                    Items_id: null,
                    Assignment_id: null,
                    PersonnelInvolved: null,
                    CreationTime: null,
                },
                ReportDate: null,
                ExpenseCode: null,
                SettlementDateS: null,
                SettlementDateE: null,
                RelevantInfo: {
                    EmployeeName: null,
                    Position: null,
                    Department: null,
                    Email: null,
                    Manager: null,
                    ManagerEmail: null,
                },
                ReimbursementInstructions: null,
                ReimbursementDetails: [
                    {
                        Date: null,
                        Type: null,
                        Explain: null,
                        Classification: null,
                        Cost: null,
                    },
                ],
                CostAggregation: {
                    Subtotal: null,
                    Advance: null,
                    TravelAllowance: null,
                    TotalReimbursement: null,
                },
            }),
               // console.log(this.ReportContentInfo);
            this.ReportContentInfo.ArchiveLocation.CreationTime =
                new Date().valueOf();
            // this.ReportContentInfo.ArchiveLocation.PersonnelInvolved =
            this.saveInfo();
        },
        SelectReport(input) {
          this.SelectReportVisible = true;
          this.selectedTaskId = input;
        },
        SelectReportClick(buttonName){
          if (buttonName === "button1") {
            this.PettyCashReimbursementForm(this.selectedTaskId)
          }else{
            this.generalReport(this.selectedTaskId)
          }
        },
        generalReport(input) {
            this.$router.push({
                name: "projectReportUnit",
                query: { taskID: input },
            });
        },
        PettyCashReimbursementForm(input) {
            this.$router.push({
                name: "ProjectTemplate",
                query: { taskID: input },
            });
        },
        async saveInfo() {
            if (this.ReportContentInfo._id == null) {
                const { data } = await CreateReportContent(this.ReportContentInfo);
                if (data) {
                    Message({
                        message: "创建成功",
                        type: "success",
                        duration: 5 * 1000,
                    });
                }
            }
            else {
                const { data } = await UpdateReportContent({
                    id: this.ReportContentInfo._id,
                    data: {
                        ReportDate: this.ReportContentInfo.ReportDate,
                        ExpenseCode: this.ReportContentInfo.ExpenseCode,
                        SettlementDateS: this.ReportContentInfo.SettlementDateS,
                        SettlementDateE: this.ReportContentInfo.SettlementDateE,
                        RelevantInfo: this.ReportContentInfo.RelevantInfo,
                        ReimbursementInstructions: this.ReportContentInfo.ReimbursementInstructions,
                        ReimbursementDetails: this.ReportContentInfo.ReimbursementDetails,
                        CostAggregation: this.ReportContentInfo.CostAggregation,
                    },
                });
            }
            // this.ReturnAndRefresh();
        },
        confirmEditStep() {
            updateTaskList({
                id: this.editTask._id,
                data: {
                    step: this.editTask.step,
                },
            });
            this.stepDetailvisialbe = false;
        },
    getId(row){
    // console.log(row)
    },
    getDateTxt(timestamp) {
      let date = new Date(timestamp),
        y = date.getFullYear(),
        m =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1,
        d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return y + "年" + m + "月" + d + "日 到期";
    },
    getDate(timestamp) {
      let date = new Date(timestamp),
        y = date.getFullYear(),
        m =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1,
        d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return y + "年" + m + "月" + d + "日";
    },
    getShortDate(timestamp) {
      let date = new Date(timestamp),
        y = date.getFullYear(),
        m =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1,
        d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return y + "年" + m + "-" + d;
    },
    getDateStyle(timeStamp, status = false) {
      let now = new Date().valueOf();
      if (now > timeStamp && status == false) {
        return "margin-right :10px ;" + "color:red;";
      } else {
        return "margin-right :10px ;" + "color:darkgray;";
      }
    },
    getSetDateStyle(timeStamp) {
      let now = new Date().valueOf();
      if (now > timeStamp) {
        return "dangerColor";
      } else {
        return "";
      }
    },

    workerChange() {
      updateTaskList({
        id: this.actTask._id,
        data: {
          worker: this.actTask.worker,
        },
      });
    },
        getStepTxt(row) {
            let total = row.step.length;
            let finshStep = 0;
            for (var i = 0; i < total; i++) {
                if (row.step[i].status == true) {
                    finshStep += 1;
        }
      }
      return "第" + finshStep + "步,共" + total + "步";
    },

    async deleteFile(file) {
      var path = "file\\" + this.actTask._id + "\\" + file;
      const { data } = await deleteFile({
        id: this.actTask.id,
        data: path,
      });
      let i = this.actTask.file.indexOf(file);
      this.actTask.file.splice(i, 1);
      updateTaskList({
        id: this.actTask._id,
        data: {
          file: this.actTask.file,
                },
            });
            //  this.showDelete = false;
        },
        async deleteProjectFile(file) {
            var path = "file\\" + this.actProject._id + "\\" + file;
            const { data } = await deleteFile({
                id: this.actProject.id,
                data: path,
            });
            let i = this.actProject.file.indexOf(file);
            this.actProject.file.splice(i, 1);
            updateProjectList({
                id: this.actProject._id,
                data: {
          file: this.actProject.file,
        },
      });
      //  this.showDelete = false;
    },

    async submitList(param) {
      var isreaper = false;
      for (var i = 0; i < this.actTask.file.length; i++) {
        if (this.actTask.file[i] == param.file.name) {
          isreaper = true;
          break;
        }
      }
      if (!isreaper) {
        const formData = new FormData();
        formData.append("ModleName", param.file); // 要提交给后台的文件
                formData.append("projectFolder", this.actTask._id); // 这个接口必要的项目id
                formData.append("subFolder", null);
                const { data1 } = await uploadExtra(formData, {
                    path: this.actTask._id,
                });
                this.actTask.file.push(param.file.name);
                updateTaskList({
                    id: this.actTask._id,
                    data: {
                        file: this.actTask.file,
                    },
                });
            }
        },
        async ProjectsubmitList(param) {
            var isreaper = false;
            for (var i = 0; i < this.actProject.file.length; i++) {
                if (this.actProject.file[i] == param.file.name) {
                    isreaper = true;
                    break;
                }
            }
            if (!isreaper) {
                const formData = new FormData();
                formData.append("ModleName", param.file); // 要提交给后台的文件
                formData.append("projectFolder", this.actProject._id); // 这个接口必要的项目id
                formData.append("subFolder", null);
                const { data1 } = await uploadExtra(formData, {
                    path: this.actProject._id,
                });
                this.actProject.file.push(param.file.name);
                updateProjectList({
                    id: this.actProject._id,
          data: {
            file: this.actProject.file,
          },
        });
      }
    },

    async downloadFile(file) {
      var path = "file\\" + this.actTask._id + "\\" + file;
      var filename = file;
      const data = await download({
        // "file\60f52112f7ee0b0d28e1d882\bottomimg.bmp"
        path: path,
        filename: filename,
      });
            var url = window.URL.createObjectURL(data);
            var a = document.createElement("a");
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
        },
        async downloadProjectFile(file) {
            var path = "file\\" + this.actProject._id + "\\" + file;
            var filename = file;
            const data = await download({
                // "file\60f52112f7ee0b0d28e1d882\bottomimg.bmp"
                path: path,
                filename: filename,
            });
            var url = window.URL.createObjectURL(data);
      var a = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
    },

    async annexPreview(row, column, event) {
      if (column.label == "文件列表") {
        var path = "file\\" + this.actTask._id + "\\" + row;
        var filename = row;
        const data = await download({
          // "file\60f52112f7ee0b0d28e1d882\bottomimg.bmp"
          path: path,
          filename: filename,
        });
        if (
          row.split(".").pop() == "jpg" ||
          row.split(".").pop() == "jpeg" ||
          row.split(".").pop() == "png" ||
          row.split(".").pop() == "gif" ||
          row.split(".").pop() == "bmp" ||
          row.split(".")[1] == "JPG" ||
          row.split(".")[1] == "JPEG" ||
          row.split(".")[1] == "PBG" ||
          row.split(".")[1] == "GIF"
        ) {
          let blob = new Blob([data], { type: "image/jpg" });
          var windowURL = window.URL;
          var url = windowURL.createObjectURL(blob);
          window.open(url);
        }
        if (row.split(".").pop() == "log") {
          let blob = new Blob([data], { type: "text/plain" });
          var windowURL = window.URL;
          var url = windowURL.createObjectURL(blob);
          window.open(url);
        }
        if (row.split(".").pop() == "pdf") {
          let blob = new Blob([data], { type: "application/pdf" });
                    var windowURL = window.URL;
                    var url = windowURL.createObjectURL(blob);
                    window.open(url);
                }
      }
    },

    async projectannexPreview(row, column, event) {
      if (column.label == "文件列表") {
        var path = "file\\" + this.actProject._id + "\\" + row;
        var filename = row;
        const data = await download({
          // "file\60f52112f7ee0b0d28e1d882\bottomimg.bmp"
          path: path,
          filename: filename,
        });
        if (
          row.split(".").pop() == "jpg" ||
          row.split(".").pop() == "jpeg" ||
          row.split(".").pop() == "png" ||
          row.split(".").pop() == "gif" ||
          row.split(".").pop() == "bmp" ||
          row.split(".")[1] == "JPG" ||
          row.split(".")[1] == "JPEG" ||
          row.split(".")[1] == "PBG" ||
          row.split(".")[1] == "GIF"
        ) {
          let blob = new Blob([data], { type: "image/jpg" });
          var windowURL = window.URL;
          var url = windowURL.createObjectURL(blob);
          window.open(url);
        }
        if (row.split(".").pop() == "log") {
          let blob = new Blob([data], { type: "text/plain" });
          var windowURL = window.URL;
          var url = windowURL.createObjectURL(blob);
          window.open(url);
        }
        if (row.split(".").pop() == "pdf") {
          let blob = new Blob([data], { type: "application/pdf" });
          var windowURL = window.URL;
                    var url = windowURL.createObjectURL(blob);
                    window.open(url);
                }
            }
        },
    commentChange() {
      updateTaskList({
        id: this.actTask._id,
        data: {
          comment: this.actTask.comment,
        },
      });
    },
    changeShowFinshTask() {
      if (this.showFinshTask) {
        this.showFinshTask = false;
      } else {
        this.showFinshTask = true;
      }
      this.fetchTaskData();
    },

    finshTaskIcon(showFinshTask) {
      if (showFinshTask) {
        return "el-icon-arrow-up";
      } else {
        return "el-icon-arrow-right";
      }
    },
    TaskPriorityIcon(priority) {
      if (priority) {
        return "el-icon-star-on";
      } else {
        return "el-icon-star-off";
      }
    },
    TaskStatusIcon(status) {
      if (status) {
        return "el-icon-success";
      } else {
        return;
      }
    },
    async fetchData() {
      this.listLoading = true;
      var userName = await getName();

      this.listQuery.filters = "({$or:[";

      this.listQuery.filters += "{";
      this.listQuery.filters += "'creater':'" + userName + "'},";

      this.listQuery.filters = this.listQuery.filters + "{'member':{$in:[";

      this.listQuery.filters = this.listQuery.filters + "'" + userName + "'";

      this.listQuery.filters = this.listQuery.filters + "]}}";

      this.listQuery.filters += "]})";
     // console.log(this.listQuery.filters);
      const { data } = await getProjectlist(this.listQuery);
      this.list = data.list;
     // console.log(this.list);
      
      // this.bubbleSort(this.list); //按照项目代码进行排序

      if (this.list.length > 0) {
        this.total = data.total;
        let projectID =this.actProjectID;
        if (projectID){
          for (let i =0;i<this.list.length ;i++){
            if (this.list[i]._id == projectID){
              this.actProject = this.list[i];
              this.actProjectID = null;
            }
          }
        }else{
          this.actProject = this.list[0];
        }

        this.fetchTaskData();
        this.listLoading = false;
      }
      else{
        this.listLoading = false;
      }
      // for (var i = 0; i < this.list.length;i++){
      //   if (this.list[i].member){
      //     this.list[i]["memberLength"] = this.list[i].member.length;
      //   }else{
      //     this.list[i]["memberLength"] = 0;
      //   }

      // }
    },

    // bubbleSort(list) {
    //   var len = list.length;
    //   for (var i = 0; i < len - 1; i++) {
    //     for (var j = 0; j < len - 1 - i; j++) {
    //       if (list[j].ProjectCode > list[j + 1].ProjectCode) {
    //         var temp = list[j + 1];
    //         list[j + 1] = list[j];
    //         list[j] = temp;
    //       }
    //     }
    //   }
    //   this.list = list;
    // },

    commentStepChange() {
      updateTaskList({
        id: this.actTask._id,
                data: {
                    step: this.actTask.step,
                },
            });
        },
    async fetchTaskData() {
      this.TasklistLoading = true;
      if (this.showFinshTask) {
        this.taskListQuery.filters =
          "({projectID:'" + this.actProject._id + "'})";
      } else {
        this.taskListQuery.filters =
          "({projectID:'" + this.actProject._id + "',status:'" + "0'})";
      }

      const { data } = await getTasklist(this.taskListQuery);
      this.Tasklist = data.list;
      this.totalTask = data.total;
      this.waitFinshCounter = data.waitFinshCounter;
      this.taskFinshCounter = data.total - data.waitFinshCounter;
      this.actProject.remaining = this.waitFinshCounter;
      
      updateProjectList({
        id: this.actProject._id,
        data: {
          remaining: this.actProject.remaining,
        },
      });
      // this.actTask = {};
      // this.taskItemWidth = 0;
      if (this.actTask !== {}) {
        for (var i = 0; i < data.list.length; i++) {
          if (data.list[i]._id == this.actTask._id) {
           
            this.actTask = data.list[i];
            this.actStep = this.actTask.step;
            this.taskItemWidth = 7;
            exit;
          }
        }
      }

      setTimeout(() => {
        this.TasklistLoading = false;
      }, 0.5 * 1000);
    },
    addUser() {
      if (
        this.editProject.member.indexOf(this.newUser) < 0 &&
        this.editProject.creater !== this.newUser &&
        this.newUser !== null
      ) {
        this.editProject.member.push(this.newUser);
      }
    },

    taskNameChange(value) {
      updateTaskList({
        id: this.actTask._id,
        data: {
          name: this.actTask.name,
        },
      });
    },
    stepNameChange(){
      updateTaskList({
        id: this.actTask._id,
        data: {
          step: this.actTask.step,
        },
      });
    },
    changePriority(row) {
      if (row.priority) {
        row.priority = false;
      } else {
        row.priority = true;
      }
      updateTaskList({
        id: row._id,
        data: {
          priority: row.priority,
        },
            });
        },
    async changeTaskStatus(row) {
      let finishTime = new Date().valueOf();
      if (row.status) {
        row.status = false;
      } else {
        row.status = true;
      }
      await updateTaskList({
        id: row._id,
        data: {
          status: row.status,
          finishTime: finishTime,
          finisher: getName(),
        },
      });

      this.fetchTaskData();
    },
    async changeStepStatus(row) {
      if (row.status) {
        row.status = false;
      } else {
        row.status = true;
      }
      await updateTaskList({
        id: this.actTask._id,
        data: {
                    step: this.actTask.step,
                },
            });
        },
    async changeStepStatusInlist(row, editTask) {
      if (row.status) {
        row.status = false;
      } else {
        row.status = true;
      }
      await updateTaskList({
        id: editTask._id,
        data: {
          step: editTask.step,
        },
      });
    },
    memberLength(member) {
      let memberLength;
      if (member) {
        memberLength = member.length;
      } else {
                memberLength = 0;
            }
            return memberLength;
        },
        showChangeProjectName(index, row) {
            this.changeProjectNamevisialbe = true;
            this.editProject = row;
        },
        confirmDeleteStep() {
            this.editTask.step.splice(this.deleteStepIndex, 1);
            updateTaskList({
                id: this.editTask._id,
                data: {
                    step: this.editTask.step,
                },
            });
            this.deleteStepvisialbe = false;
        },
        showdeleteTaskVisble() {
            this.deleteTaskVisble = true;
        },
        TravelReportDisplay() {
            this.TravelReport = true;
        },
        async confirmDeleteTask() {
            await deleteTaskList({
                id: this.actTask._id,
            });
            this.fetchTaskData();
            this.actTask = {};
            this.taskItemWidth = 0;
            this.deleteTaskVisble = false;
            this.TravelReport = false;
        },
        showDeleteStep(index, row, editTask) {
            this.deleteStepvisialbe = true;
            this.deleteStepIndex = index;
            this.editTask = editTask;
    },
    isOwner() {
      if (this.editProject.creater == getName()) {
        return true;
      } else {
        return false;
      }
    },
    isMember() {
      if (this.editProject.creater == getName()) {
        return false;
      } else {
        if (
          this.editProject.member &&
          this.editProject.member.indexOf(getName()) >= 0
        ) {
          return true;
        }
      }
    },
    showstepDetail(index, row, editTask) {
      this.stepDetailvisialbe = true;
      this.editStep = row;
      this.editTask = editTask;
    },
    showDeleteProject(index, row) {
      this.deleteProjectvisialbe = true;
            this.editProject = row;
        },
        showMemberInfo(index, row) {
            this.memberInfovisialbe = true;
            this.editProject = row;
        },
        showAppendixInfo(index, row) {
            this.appendixInfovisialbe = true;
            this.editProject = row;
        },
        async confirmDelete() {
            // await deleteProjectList({
            //   id: this.editProject._id
            // });
            await updateProjectList({
                id: this.editProject._id,
                data: {
                    creater: null,
                    member: [],
                },
            });
            this.deleteProjectvisialbe = false;
            this.fetchData();
        },
        async confirmMemberchange() {
            await updateProjectList({
                id: this.editProject._id,
                data: {
                    member: this.editProject.member,
                },
      });
      this.memberInfovisialbe = false;
      this.fetchData();
    },

    async confirmQuit() {
      let member = this.editProject.member;
      var userName = await getName();
      let i = member.indexOf(userName);
      this.editProject.member.splice(i, 1);
      //console.log(this.editProject.member);
      await updateProjectList({
        id: this.editProject._id,
                data: {
                    member: this.editProject.member,
                },
            });
            this.deleteProjectvisialbe = false;
            this.fetchData();
        },
        async confirmchange() {
            await updateProjectList({
                id: this.editProject._id,
                data: {
                    name: this.editProject.name,
                    ProjectCode: this.editProject.ProjectCode,
                    ProjectRemarks: this.editProject.ProjectRemarks,
                },
            });
            this.fetchData();
            this.changeProjectNamevisialbe = false;
        },
    getFatherTask(row) {
      if (this.actTask._id == row._id) {
        this.actTask = {};
        this.taskItemWidth = 0;
      } else {
        this.actTask = row;
        this.actStep = this.actTask.step;
        this.taskItemWidth = 7;
      }
    },
    getTaskItem(row, column, cell, ev) {
      if (column.property !== "name") {
                return;
            }
            if (this.actTask._id == row._id) {
        this.actTask = {};
        this.taskItemWidth = 0;
      } else {
        this.actTask = row;
        this.actStep =this.actTask.step;
        this.taskItemWidth = 7;
      }
    },
    async getProject(row, column, cell, ev) {
      if (column.property == "name" || column.property == "remaining") {
        this.actProject = row;
        let el = this;
        await this.fetchTaskData();
        this.taskItemWidth = 0;
        this.actTask = {};
            }
        },
        async creatList() {
            const { data } = await createProjectlist({
                name: "无标题",
                creater: getName(),
                ProjectRemarks: null,
                member: [],
                remaining: 0,
            });
            this.fetchData();
        },
        async creatTask() {
            let projectID = this.actProject._id;
            let el = this;
            if (this.newTask == null) {
                this.newTask = "无标题";
            }
            const { data } = await createTasklist({
                name: this.newTask,
                projectID: projectID,
                createTime: new Date().valueOf(),
                endTime: null,
                creater: getName(),
                finishTime: null,
                worker: [],
                finisher: null,
                file: [],
                priority: false,
                step: [],
                comment: null,
                status: false,
            });
            this.newTask = null;
            await this.fetchTaskData();
            // if (el.remaining !== this.actProject.remaining) {
            //   this.actProject.remaining = this.totalTask;
            //   updateProjectList({
            //     id: this.actProject._id,
            //     data: {
            //       remaining: this.actProject.remaining
            //     }
            //   })
            // }
        },
        async creatStep() {
            if (this.newStep == null) {
                this.newStep = "无标题";
            }
            this.actTask.step.push({
                name: this.newStep,
                status: false,
                endTime: null,
                log: null,
            });
            updateTaskList({
                id: this.actTask._id,
                data: {
                    step: this.actTask.step,
                },
            });
            this.newStep = null;
        },
    },
    components: { Icon }
};
</script>

<style scoped>
span {
  display: inline-block;
}

#filterSelect {
  position: absolute;
  top: 20px;
  left: 30px;
  width: 80%;
  height: 800px;
  background: white;
}

.filter >>> .el-dialog {
  width: 80%;
}

.app-container {
  position: relative;
}

p {
  margin-top: 7px;
  margin-bottom: 7px;
}

.yellow {
  background-color: yellow;
  border-radius: 5px;
}

.red {
  background-color: rgba(243, 129, 158, 0.863);
  border-radius: 5px;
}

.blue {
  background-color: rgb(155, 155, 219);
  border-radius: 5px;
}

.delete:hover {
  background-image: url("../../../Img/delete.jpg");
  background-repeat: no-repeat;
  color: transparent;
  background-size: cover;
  cursor: pointer;
}
.el-tooltip__popper {
  white-space: pre-wrap;
}
.inline {
  width: 7px;
}

.dangerColor >>> .el-input__inner {
  color: red !important;
}

.create:hover {
  background-image: url("../../../Img/create.jpg");
  background-repeat: no-repeat;
  color: transparent;
  background-size: cover;
  cursor: pointer;
}

.container {
  position: relative;
  height: auto;
}

div >>> .filterbysn {
  display: inline-block;
  width: 150px;
  margin-left: 10px;
}

.LeftArea {
  width: 30%;
  display: inline-block;
  height: 100%;
  margin-bottom: auto;
  position: absolute;
}

.taskArea {
  left: 31%;
  width: 68%;
  display: inline-block;
  height: 650px;
  margin-bottom: auto;
  position: absolute;
  background-color: rgba(182, 188, 194, 0.703);
  padding: 10px;
  border-radius: 5px;
}

.el-row {
  margin-bottom: 20px;
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  padding: 10px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.frame {
  margin: 1px;
  border: 1px solid #d8dfe7;
  padding: 3px;
  position: relative;
  border-radius: 5px;
}

.notes {
  margin-bottom: 20px;
  margin-left: 5px;
}

.item {
  margin-right: 20px;
}

.buttongroup {
  margin-bottom: 10px;
}

.Tilteheader {
  font-size: 30px;
  font-weight: 700;
}

.Tiltefooter {
  height: 20px;
  font-size: 15px;
  max-width: 200px;
  overflow: hidden; /*内容会被修剪，并且其余内容是不可见的*/
  text-overflow: ellipsis; /*显示省略符号来代表被修剪的文本。*/
  white-space: nowrap; /*文本不换行*/
}

.card-button {
  width: 100%;
  height: 100%;
  text-align: left;
  border: none;
}
</style>