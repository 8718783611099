var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "el-button",
          {
            staticStyle: { "margin-top": "10px", "margin-left": "20px" },
            on: { click: _vm.updateForm },
          },
          [_vm._v("保存")]
        ),
        _c(
          "router-link",
          {
            attrs: {
              to: {
                path: "table_Supplier",
                query: {
                  filters: _vm.listQuery, // orderNum : this.searchData.orderNo
                },
              },
            },
          },
          [
            _c(
              "el-button",
              {
                staticStyle: { "margin-top": "10px", "margin-left": "10px" },
                attrs: { type: "button" },
              },
              [_vm._v("返回")]
            ),
          ],
          1
        ),
        !_vm.FormDialog.SupplierRelated.Supplier_Complete
          ? _c(
              "el-button",
              {
                staticStyle: { "margin-top": "10px", "margin-left": "10px" },
                on: {
                  click: function ($event) {
                    return _vm.ConfirmCompletion(
                      _vm.FormDialog.SupplierRelated.Supplier_Complete
                    )
                  },
                },
              },
              [_vm._v(" 确认完成 ")]
            )
          : _vm._e(),
        _vm.FormDialog.SupplierRelated.Supplier_Complete
          ? _c(
              "el-button",
              {
                staticStyle: { "margin-top": "10px", "margin-left": "10px" },
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.ConfirmCompletion(
                      _vm.FormDialog.SupplierRelated.Supplier_Complete
                    )
                  },
                },
              },
              [_vm._v(" 已完成 ")]
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { attrs: { id: "printTest" } },
      [
        _vm._m(0),
        _c("h6", [_vm._v("产品信息| Product Information:")]),
        _c("table", { attrs: { border: "1", cellspacing: "0" } }, [
          _c("tr", [
            _c("td", { staticClass: "clomun1" }, [
              _vm._v("序列号| Serail No."),
            ]),
            _c(
              "td",
              { staticClass: "clomun2" },
              [
                _c("el-input", {
                  staticClass: "edit-input",
                  attrs: { type: "text", disabled: true, size: "small" },
                  model: {
                    value: _vm.FormDialog.productInfo.SN,
                    callback: function ($$v) {
                      _vm.$set(_vm.FormDialog.productInfo, "SN", $$v)
                    },
                    expression: "FormDialog.productInfo.SN",
                  },
                }),
              ],
              1
            ),
            _c("td", { staticClass: "clomun3" }, [_vm._v("产品名称| Module")]),
            _c(
              "td",
              { staticClass: "clomun4" },
              [
                _c("el-input", {
                  staticClass: "edit-input",
                  attrs: { type: "text", disabled: true, size: "small" },
                  model: {
                    value: _vm.FormDialog.productInfo.module,
                    callback: function ($$v) {
                      _vm.$set(_vm.FormDialog.productInfo, "module", $$v)
                    },
                    expression: "FormDialog.productInfo.module",
                  },
                }),
              ],
              1
            ),
            _c("td", { staticClass: "clomun5" }, [
              _vm._v("产品订货号| Articl No."),
            ]),
            _c(
              "td",
              { staticClass: "clomun6" },
              [
                _c("el-input", {
                  staticClass: "edit-input",
                  attrs: { type: "text", disabled: true, size: "small" },
                  model: {
                    value: _vm.FormDialog.productInfo.articl,
                    callback: function ($$v) {
                      _vm.$set(_vm.FormDialog.productInfo, "articl", $$v)
                    },
                    expression: "FormDialog.productInfo.articl",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("tr", [
            _c("td", [_vm._v("硬件版本| Hardware.")]),
            _c(
              "td",
              [
                _c("el-input", {
                  staticClass: "edit-input",
                  attrs: { type: "text", disabled: true, size: "small" },
                  model: {
                    value: _vm.FormDialog.productInfo.hardware,
                    callback: function ($$v) {
                      _vm.$set(_vm.FormDialog.productInfo, "hardware", $$v)
                    },
                    expression: "FormDialog.productInfo.hardware",
                  },
                }),
              ],
              1
            ),
            _c("td", [_vm._v("软件版本| Software.")]),
            _c(
              "td",
              [
                _c("el-input", {
                  staticClass: "edit-input",
                  attrs: { type: "text", disabled: true, size: "small" },
                  model: {
                    value: _vm.FormDialog.productInfo.software,
                    callback: function ($$v) {
                      _vm.$set(_vm.FormDialog.productInfo, "software", $$v)
                    },
                    expression: "FormDialog.productInfo.software",
                  },
                }),
              ],
              1
            ),
            _c("td", [_vm._v("返修次数| Return Times.")]),
            _c(
              "td",
              [
                _c("el-input", {
                  staticClass: "edit-input",
                  attrs: { type: "text", disabled: true, size: "small" },
                  model: {
                    value: _vm.FormDialog.times,
                    callback: function ($$v) {
                      _vm.$set(_vm.FormDialog, "times", $$v)
                    },
                    expression: "FormDialog.times",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("tr", [
            _c("td", [_vm._v("退货订单号| Report No.")]),
            _c(
              "td",
              [
                _c("el-input", {
                  staticClass: "edit-input",
                  attrs: { type: "text", disabled: true, size: "small" },
                  model: {
                    value: _vm.FormDialog.productInfo.report,
                    callback: function ($$v) {
                      _vm.$set(_vm.FormDialog.productInfo, "report", $$v)
                    },
                    expression: "FormDialog.productInfo.report",
                  },
                }),
              ],
              1
            ),
            _c("td", [_vm._v("订货号| Parts No.")]),
            _c(
              "td",
              [
                _c("el-input", {
                  staticClass: "edit-input",
                  attrs: { type: "text", disabled: true, size: "small" },
                  model: {
                    value: _vm.FormDialog.productInfo.parts,
                    callback: function ($$v) {
                      _vm.$set(_vm.FormDialog.productInfo, "parts", $$v)
                    },
                    expression: "FormDialog.productInfo.parts",
                  },
                }),
              ],
              1
            ),
            _c("td", [_vm._v("零件编号| PO No.")]),
            _c(
              "td",
              [
                _c("el-input", {
                  staticClass: "edit-input",
                  attrs: { type: "text", disabled: true, size: "small" },
                  model: {
                    value: _vm.FormDialog.productInfo.po,
                    callback: function ($$v) {
                      _vm.$set(_vm.FormDialog.productInfo, "po", $$v)
                    },
                    expression: "FormDialog.productInfo.po",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("tr", [
            _c("td", [_vm._v("返修日期| Failure Date")]),
            _c(
              "td",
              [
                _c("el-input", {
                  staticClass: "edit-input date",
                  attrs: { type: "date", disabled: true, size: "small" },
                  model: {
                    value: _vm.FormDialog.productInfo.return,
                    callback: function ($$v) {
                      _vm.$set(_vm.FormDialog.productInfo, "return", $$v)
                    },
                    expression: "FormDialog.productInfo.return",
                  },
                }),
              ],
              1
            ),
            _c("td", [_vm._v("发货日期| Delivery Date")]),
            _c(
              "td",
              [
                _c("el-input", {
                  staticClass: "edit-input date",
                  attrs: { type: "date", disabled: true, size: "small" },
                  model: {
                    value: _vm.FormDialog.productInfo.delivery,
                    callback: function ($$v) {
                      _vm.$set(_vm.FormDialog.productInfo, "delivery", $$v)
                    },
                    expression: "FormDialog.productInfo.delivery",
                  },
                }),
              ],
              1
            ),
            _c("td", [_vm._v("质保到期日| Warranty Date")]),
            _c(
              "td",
              [
                _c("el-input", {
                  staticClass: "edit-input date",
                  attrs: { type: "date", disabled: true, size: "small" },
                  model: {
                    value: _vm.FormDialog.productInfo.warranty,
                    callback: function ($$v) {
                      _vm.$set(_vm.FormDialog.productInfo, "warranty", $$v)
                    },
                    expression: "FormDialog.productInfo.warranty",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("h6", { staticStyle: { display: "inline-block" } }, [
          _vm._v("故障信息| Failure Description:"),
        ]),
        _c("table", { attrs: { border: "1", cellspacing: "0" } }, [
          _c("tr", [
            _c(
              "td",
              { staticClass: "Failure" },
              [
                _c("el-input", {
                  ref: "failure",
                  staticClass: "edit-input1 edit-input",
                  attrs: { type: "textarea", disabled: true, size: "small" },
                  model: {
                    value: _vm.FormDialog.failureDescription,
                    callback: function ($$v) {
                      _vm.$set(_vm.FormDialog, "failureDescription", $$v)
                    },
                    expression: "FormDialog.failureDescription",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("h6", [_vm._v("客户留言| Customer Message:")]),
        _c("div", [
          _c("table", { attrs: { border: "1", cellspacing: "0" } }, [
            _c("tr", [
              _c(
                "td",
                { staticClass: "Failure" },
                [
                  _c("el-input", {
                    staticClass: "edit-input2 edit-input",
                    attrs: { type: "textarea", disabled: true, size: "small" },
                    model: {
                      value:
                        _vm.FormDialog.SupplierRelated
                          .Sigmatek_MessageToSupplier,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.FormDialog.SupplierRelated,
                          "Sigmatek_MessageToSupplier",
                          $$v
                        )
                      },
                      expression:
                        "FormDialog.SupplierRelated.Sigmatek_MessageToSupplier",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _c(
          "el-divider",
          { staticClass: "divider", attrs: { "content-position": "left" } },
          [_vm._v(" 需填写部分 ")]
        ),
        _c("h6", { staticStyle: { display: "inline-block" } }, [
          _vm._v("维修信息| Analysis:"),
        ]),
        _c("table", { attrs: { border: "1", cellspacing: "0" } }, [
          _c("tr", [
            _c(
              "td",
              { staticClass: "Analysis" },
              [
                _c("el-input", {
                  ref: "analysis",
                  staticClass: "edit-input2 edit-input",
                  attrs: { type: "textarea", size: "small" },
                  model: {
                    value: _vm.FormDialog.analysis,
                    callback: function ($$v) {
                      _vm.$set(_vm.FormDialog, "analysis", $$v)
                    },
                    expression: "FormDialog.analysis",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("h6", [_vm._v("更换部件信息| Components Information:")]),
        _c(
          "table",
          { attrs: { border: "1", cellspacing: "0", id: "repair-list" } },
          [
            _vm._m(1),
            _vm._l(
              _vm.FormDialog.SuppliercomponentsInfomation,
              function (item, index) {
                return _c("tr", { key: index }, [
                  _c(
                    "td",
                    {
                      staticClass: "delete",
                      on: {
                        click: function ($event) {
                          return _vm.remove(index)
                        },
                      },
                    },
                    [_vm._v(_vm._s(index + 1))]
                  ),
                  _c(
                    "td",
                    [
                      _c("el-input", {
                        staticClass: "edit-input",
                        attrs: {
                          type: "textarea",
                          autosize: "",
                          size: "small",
                        },
                        model: {
                          value: item.name,
                          callback: function ($$v) {
                            _vm.$set(item, "name", $$v)
                          },
                          expression: "item.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c("el-input", {
                        staticClass: "edit-input",
                        attrs: { type: "text", size: "small" },
                        on: {
                          change: function ($event) {
                            return _vm.onComponentsChange(index)
                          },
                        },
                        model: {
                          value: item.number,
                          callback: function ($$v) {
                            _vm.$set(item, "number", $$v)
                          },
                          expression: "item.number",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c("el-input", {
                        staticClass: "edit-input",
                        attrs: { type: "text", size: "small" },
                        model: {
                          value: item.badNr,
                          callback: function ($$v) {
                            _vm.$set(item, "badNr", $$v)
                          },
                          expression: "item.badNr",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c("el-input", {
                        staticClass: "edit-input",
                        attrs: { type: "text", size: "small" },
                        model: {
                          value: item.newNr,
                          callback: function ($$v) {
                            _vm.$set(item, "newNr", $$v)
                          },
                          expression: "item.newNr",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c("el-input", {
                        staticClass: "edit-input input-right",
                        attrs: { type: "text", size: "small" },
                        model: {
                          value: item.count,
                          callback: function ($$v) {
                            _vm.$set(item, "count", $$v)
                          },
                          expression: "item.count",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c("el-input", {
                        staticClass: "edit-input input-right",
                        attrs: { type: "text", size: "small" },
                        on: {
                          blur: function ($event) {
                            return _vm.caluete(item)
                          },
                        },
                        model: {
                          value: item.price,
                          callback: function ($$v) {
                            _vm.$set(item, "price", $$v)
                          },
                          expression: "item.price",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("td", { staticStyle: { "text-align": "right" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          (item.price
                            ? parseFloat(item.price) * parseInt(item.count)
                            : 0
                          ).toFixed(2)
                        ) +
                        " "
                    ),
                  ]),
                ])
              }
            ),
            _c("tr", [
              _c("td", { staticClass: "create", on: { click: _vm.creatNew } }),
              _c("td", { ref: "Quote", attrs: { colspan: "2" } }, [
                _c("p", { staticStyle: { display: "inline-block" } }, [
                  _vm._v("快递单号:"),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value:
                        _vm.FormDialog.SupplierRelated.Supplier_TrackingNumber,
                      expression:
                        "FormDialog.SupplierRelated.Supplier_TrackingNumber",
                    },
                  ],
                  staticClass: "Reportinput",
                  domProps: {
                    value:
                      _vm.FormDialog.SupplierRelated.Supplier_TrackingNumber,
                  },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.FormDialog.SupplierRelated,
                        "Supplier_TrackingNumber",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c(
                "td",
                { ref: "reportNumber", attrs: { colspan: "2" } },
                [
                  _c(
                    "p",
                    {
                      staticStyle: {
                        display: "inline-block",
                        "margin-right": "10px",
                      },
                    },
                    [_vm._v("快递发出时间:")]
                  ),
                  _c("el-date-picker", {
                    staticClass: "edit-input date ReportDate",
                    staticStyle: { width: "70%" },
                    attrs: {
                      clearable: false,
                      type: "date",
                      size: "small",
                      placeholder: "选择日期",
                      format: "yyyy 年 MM 月 dd 日",
                      "value-format": "timestamp",
                    },
                    model: {
                      value:
                        _vm.FormDialog.SupplierRelated.Supplier_DeliveryDate,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.FormDialog.SupplierRelated,
                          "Supplier_DeliveryDate",
                          $$v
                        )
                      },
                      expression:
                        "FormDialog.SupplierRelated.Supplier_DeliveryDate",
                    },
                  }),
                ],
                1
              ),
              _vm._m(2),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("el-input", {
                    staticClass: "edit-input input-right",
                    attrs: { type: "text", size: "small" },
                    model: {
                      value: _vm.totalPrice,
                      callback: function ($$v) {
                        _vm.totalPrice = $$v
                      },
                      expression: "totalPrice",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _vm._v("维修报告"),
      _c("span", { staticClass: "italic" }, [_vm._v("Repairment")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { staticClass: "clomun11" }, [_vm._v("No.")]),
      _c("td", { staticClass: "clomun12" }, [
        _vm._v("物料名称 "),
        _c("br"),
        _vm._v(" Parts"),
      ]),
      _c("td", { staticClass: "clomun3" }, [
        _vm._v(" 订货号 "),
        _c("br"),
        _vm._v(" Articl No."),
      ]),
      _c("td", { staticClass: "clomun4" }, [
        _vm._v(" 故障物料序列号 "),
        _c("br"),
        _vm._v(" Defect Part No."),
      ]),
      _c("td", { staticClass: "clomun15" }, [
        _vm._v("新物料序列号 "),
        _c("br"),
        _vm._v(" New part No."),
      ]),
      _c("td", { staticClass: "clomun17" }, [
        _vm._v("数量 "),
        _c("br"),
        _vm._v(" QTY."),
      ]),
      _c("td", { staticClass: "clomun16" }, [
        _vm._v("单价 "),
        _c("br"),
        _vm._v(" Price"),
      ]),
      _c("td", { staticClass: "clomun16" }, [
        _vm._v("合价 "),
        _c("br"),
        _vm._v(" Price"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { colspan: "2" } }, [
      _vm._v("合计（含税）"),
      _c("br"),
      _vm._v(" Total Price(INC.VAT.)"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }