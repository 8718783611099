<template>
    <div class="app-container">
        <div class="headbutton">
            <!-- <el-button type="primary" @click="getSummaryInfo">刷新</el-button> -->
            <el-button type="primary" @click="createSummaryInfo">新建</el-button>
            <el-input v-model="Search" placeholder="通过货单号搜索" class="Fuzzy_search"></el-input>
            <el-button type="primary" v-on:click="FuzzySearch">搜索</el-button>
        </div>

        <div class="table">
          <el-table :data="list" style="width: 100%" border>
          <el-table-column prop="Number" align="center" label="ID" min-width="95">
            <template slot-scope="scope">
              {{scope.$index+ (getlist.page - 1)*getlist.limit+1}}
            </template>
          </el-table-column>
            <el-table-column prop="Number" align="center" label="订货号" min-width="180">
              <template slot-scope="scope">
                {{scope.row.Number}}
              </template>
            </el-table-column>
            <el-table-column prop="Produce.length" align="center" label="产品" min-width="180">
              <template slot-scope="scope">
                {{scope.row.Name}}
              </template>
            </el-table-column>
            <el-table-column prop="Problem" align="center" label="问题" min-width="180">
              <template  slot-scope="scope">
                <el-button type="button" v-on:click="showInfo(scope.row)" >查看详情</el-button>
                <el-button type="button" v-on:click="deleteRow(scope.row)">删除</el-button>
              </template>
              
            </el-table-column>
          </el-table>
        </div>

        <el-dialog :visible.sync="showOrderInfo" title="故障信息" @close="closeInfo">
          <h4 style="margin-left: 10px;">模块</h4>
          <el-input v-model="oderInfo.Name" placeholder="请输入模块"></el-input>
          <h4 style="margin-left: 10px;display: inline-block" >订货号</h4>
          <el-button type="primary"
                size="small"
                class="autogetbutton"
                @click="showAddProduce"
                >添加问题</el-button>
          <el-input v-if="IsaddProduce" style="margin-left:10px;display: inline-block;width: 250px;" v-model="newProduce" placeholder="请输入问题"></el-input>
          <el-button v-if="IsaddProduce" type="primary"
                size="small"
                class="autogetbutton"
                round
                @click="addProduce"
                >确定</el-button>
          <el-button v-if="IsaddProduce" type="primary"
                size="small"
                class="autogetbutton"
                round
                @click="cancelAdd"
                >取消</el-button>
          <el-input v-model="oderInfo.Number" placeholder="请输入货号"></el-input>
          <div v-for="(value,name) of oderInfo.Problem" :key="name">
            <div style="position: relative">
              <h4 style="margin-left: 10px;display: inline-block;margin-right: 20px;">{{name}}</h4>
              <el-button type="primary"
                size="small"
                icon="el-icon-plus"
                circle
                style="margin-right:10px;"
                @click="addProblem(name)"
                ></el-button>
              <el-popconfirm title="请确认删除" @confirm="deleteProblem(name)" >
                <el-button
                  slot="reference"
                  type="danger" icon="el-icon-delete" circle
                  size="small"
                 ></el-button>
              </el-popconfirm>
            </div>
              <el-card shadow="hover" class="classcard">
                <div id="cardclass" v-for="(item,indexs) in value" :key="indexs">
                  <el-input v-model="value[indexs]" placeholder="请输入问题"></el-input>
                  <el-popconfirm title="请确认删除" @confirm="deleteclass(name,indexs)" >
                    <el-button
                      slot="reference"
                      type="danger"
                      icon="el-icon-delete"
                      circle 
                      size="small"
                 
                    ></el-button>
                  </el-popconfirm>
                </div>
                
              </el-card>
          </div>
          <span slot="footer" class="dialog-footer">
          <el-button @click="hideOderInfo ">取 消</el-button>
          <el-button type="primary" @click="updateList" >确 定</el-button
        >
      </span>
        </el-dialog>
        <pagination v-show="total>0" :total="total" :page.sync="getlist.page" :limit.sync="getlist.limit" @pagination="getSummaryInfo" />
    </div>
</template>
<script>
import { getsummarylist, createsummarylist, deletesummary, updatesummary} from "@/api/summary";
import { Message, MessageBox } from "element-ui";
import Pagination from "@/components/Pagination";

export default {
  components:{ Pagination },
  data() {
    return {
      list: null,
      oderInfo: {
        Number:null,
        Name:null,
        Produce:null,
        Problem:null
      },
      showOrderInfo: false,

      getlist: {
        page: 1,
        limit: 10,
        sort: 'null',
        filters :{}
      },
      total: 40,
      newProduce:null,
      IsaddProduce:false,
      Search:null,
    }
  },
  watch: {
    Search(val) {
      if (val == null || val == '') {
        this.getlist.filters = {};
        this.getSummaryInfo();
      }
    }
  },
  mounted(){
    this.$nextTick(function () {
    // 仅在整个视图都被渲染之后才会运行的代码
    this.getSummaryInfo();
  })
  },
    methods: {
        async getSummaryInfo(){
            const {data} = await getsummarylist(this.getlist);
            this.list = data.list;
            this.total = data.total;
        },
        async createSummaryInfo() {
          const data = {
            Number:null,
            Name:"",
            Produce:[],
            Problem:{}
          }
          const info = await createsummarylist(data);
          if(info) {
            Message({
              message: "保存成功",
              type: "success",
              duration: 5 * 1000,
            });
          }

          this.getSummaryInfo();
        },
        showInfo(data) {
          this.showOrderInfo = true;
          if(data == null){
            this.oderInfo = {
              Number:null,
              Name:null,
              Produce:[],
              Problem:{}
            }

          }else {
            this.oderInfo = data;

           // console.log(this.Problems)
          }
        },
        hideOderInfo() {
          this.showOrderInfo = false;
          this.getSummaryInfo();
        },
        showAddProduce() {
          this.IsaddProduce = true;
        },
        cancelAdd(){
          this.IsaddProduce = false;

        },
        addProduce(){
          if(this.oderInfo.Problem == undefined) {
            this.oderInfo["Problem"] = {};
          }
          if(this.oderInfo.Problem.hasOwnProperty(this.newProduce) == false && this.newProduce !== null && this.newProduce !== ''){
            // this.oderInfo.Problem[this.newProduce]=["请输入描述"];
            this.$set(this.oderInfo.Problem,this.newProduce,['']);
            this.Problems = this.oderInfo.Problem;
            this.oderInfo.Produce.push(this.newProduce);
            this.newProduce = null;
            //console.log(this.oderInfo);
          }else if(this.oderInfo.Problem.hasOwnProperty(this.newProduce) == true){
            this.$message({
              showClose:true,
              message:"已存在此产品！请勿重复操作！",
              type:"warning"
            })
          }else if(this.newProduce == null || this.newProduce == ''){
            this.$message({
              showClose:true,
              message:"请输入产品名！",
              type:"warning"
            })
          }
        },
        closeInfo(){
          this.newProduce = null;
          this.IsaddProduce = false;
          this.getSummaryInfo();
        },
        addProblem(name) {
          let ProblemArr = this.oderInfo.Problem[name];
          ProblemArr.push(''),
          // this.ProblemInfo.Problem[name].push("");
          this.$set(this.oderInfo.Problem,name,ProblemArr);
          // console.log(this.ProblemInfo);
          this.$forceUpdate();
        },
        deleteRow(data) {
          this.$confirm(`是否删除${ data.Name}?`).then(async()=> {
            const info = await deletesummary({id: data._id});
            if(info) {
              this.$message({
                showClose:true,
                message:"删除成功",
                type:"success"
              })
            };
            this.getSummaryInfo();
          })
        },
        async updateList() {
          const updateData = {
            id: this.oderInfo._id,
            data:{
              Name: this.oderInfo.Name,
              Number: this.oderInfo.Number,
              Produce: this.oderInfo.Produce,
              Problem: this.oderInfo.Problem
            }
            
          }
          const {data} = await updatesummary(updateData);
          if(data) {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.getSummaryInfo();
            this.showOrderInfo = false;
          }
        },
        deleteclass(name,index){
          this.oderInfo.Problem[name].splice(index,1);
          this.$forceUpdate();
        },
        deleteProblem(name) {
          delete this.oderInfo.Problem[name];
          this.$forceUpdate(); //强制刷新显示，防止因嵌套过多出现数据修改但是界面未显示的情况
        },
        FuzzySearch(){
          if(this.Search == null) {
            this.getlist.filters={};
          }else{
            this.getlist.filters = 
              "({'Number':{$regex:'" + this.Search + "'}})"
          }
          this.getSummaryInfo();
        },
        // cancelSearch(){
        //   if(this.Search == null || this.Search == '') {
        //     this.getlist.filters = {};
        //     this.getSummaryInfo();
        //   }

        // }
    }
}
</script>

<style scoped>
.headbutton {
  margin-top: 20px;
  margin-left: 2.5%;
}
.table {
  margin-top: 20px;
  width: 95%;
  margin-left: 2.5%;
}

td {
  min-width: 120px;
}
.app-container {
  position: relative;
}
.inline {
  display: inline;
}
.autogetbutton {
  display: inline-block;
  margin-left: 10px;
}
.classcard {
  margin-top: 5px;
}
.classcard >>> .el-card__body {
  padding: 0px;
}
#cardclass >>> .el-input__inner {
  border-bottom: 1;
  border-left: 0;
  border-right: 0;
  border-top: 0;
}
#cardclass >>> .el-input {
  display: inline-block;
  width: calc(100% - 60px);
}
div >>> .Fuzzy_search {
  margin-left: 10px;
  margin-right: 5px;
  display: inline-block;
  width: 300px;
}
</style>