var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "生产总览", name: "first" } },
            [
              _c(
                "el-row",
                { staticClass: "Line-Overview", attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-card",
                        { staticClass: "box-card-Overview" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c(
                                  "div",
                                  { staticClass: "box-card-Overview-unit" },
                                  [
                                    _c("el-statistic", {
                                      attrs: {
                                        "group-separator": ",",
                                        precision: 0,
                                        value: _vm.StatisticalInfo_text.value1,
                                        title: _vm.StatisticalInfo_text.title1,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c(
                                  "div",
                                  { staticClass: "box-card-Overview-unit" },
                                  [
                                    _c("el-statistic", {
                                      attrs: {
                                        "group-separator": ",",
                                        precision: 0,
                                        value: _vm.StatisticalInfo_text.value2,
                                        title: _vm.StatisticalInfo_text.title2,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c(
                                  "div",
                                  { staticClass: "box-card-Overview-unit" },
                                  [
                                    _c("el-statistic", {
                                      attrs: {
                                        "group-separator": ",",
                                        precision: 0,
                                        value: _vm.StatisticalInfo_text.value3,
                                        title: _vm.StatisticalInfo_text.title3,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c(
                                  "div",
                                  { staticClass: "box-card-Overview-unit" },
                                  [
                                    _c("el-statistic", {
                                      attrs: {
                                        "group-separator": ",",
                                        precision: 0,
                                        value: _vm.StatisticalInfo_text.value4,
                                        title: _vm.StatisticalInfo_text.title4,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-card",
                        { staticClass: "box-card-Progress" },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.The_First,
                                  expression: "The_First",
                                },
                              ],
                              staticStyle: { "font-size": "40px" },
                            },
                            [_vm._v(" 单击选中表格中的一行数据即可生成图表 ")]
                          ),
                          _c(
                            "el-row",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.The_First,
                                  expression: "!The_First",
                                },
                              ],
                            },
                            [
                              _c("el-col", { attrs: { span: 12 } }, [
                                !_vm.OrdersDataOK_PD || !_vm.OrdersDataOK_PP
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "25px",
                                          margin: "70px",
                                        },
                                      },
                                      [_vm._v(" 订单无内容 ")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.OrdersDataOK_PD &&
                                          _vm.OrdersDataOK_PP,
                                        expression:
                                          "OrdersDataOK_PD && OrdersDataOK_PP",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 20 } },
                                      [
                                        _c("el-col", { attrs: { span: 12 } }, [
                                          _c("div", {
                                            ref: "echart_Pie_ProductionProgress",
                                            attrs: {
                                              id: "echart_Pie_ProductionProgress",
                                            },
                                          }),
                                        ]),
                                        _c("el-col", { attrs: { span: 12 } }, [
                                          _c("div", {
                                            ref: "echart_Pie_ProductDistribution",
                                            attrs: {
                                              id: "echart_Pie_ProductDistribution",
                                            },
                                          }),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 11 } }, [
                                !_vm.OrdersDataOK_L
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "25px",
                                          margin: "70px",
                                        },
                                      },
                                      [_vm._v(" 订单内产品信息数据缺失 ")]
                                    )
                                  : _vm._e(),
                                _c("div", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.OrdersDataOK_L,
                                      expression: "OrdersDataOK_L",
                                    },
                                  ],
                                  ref: "echart_Line_QuantityAndDate",
                                  attrs: { id: "echart_Line_QuantityAndDate" },
                                }),
                              ]),
                              _c("el-col", { attrs: { span: 1 } }, [
                                _vm.OrdersDataOK_L &&
                                _vm.OrdersDataOK_PD &&
                                _vm.OrdersDataOK_PP
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-button", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.Draw_Lock,
                                              expression: "Draw_Lock",
                                            },
                                          ],
                                          staticClass: "ChartButton",
                                          attrs: {
                                            type: "primary",
                                            icon: "el-icon-lock",
                                            size: "mini",
                                          },
                                          on: { click: _vm.Lock_Chart },
                                        }),
                                        _c("el-button", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.Draw_Lock,
                                              expression: "!Draw_Lock",
                                            },
                                          ],
                                          staticClass: "ChartButton",
                                          attrs: {
                                            icon: "el-icon-unlock",
                                            size: "mini",
                                          },
                                          on: { click: _vm.Lock_Chart },
                                        }),
                                        _c("br"),
                                        _c("el-button", {
                                          staticClass: "ChartButton",
                                          attrs: {
                                            icon: "el-icon-zoom-in",
                                            size: "mini",
                                          },
                                          on: { click: _vm.show_Detailed },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _c(
                "el-row",
                { staticClass: "Line-Orders", attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-card",
                        { staticClass: "box-card-ProductOrder" },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [
                              _c("span", [_vm._v("生产订单速览")]),
                              _c(
                                "el-radio-group",
                                {
                                  staticClass: "POSODisplay",
                                  attrs: { size: "small" },
                                  model: {
                                    value: _vm.PO_Display,
                                    callback: function ($$v) {
                                      _vm.PO_Display = $$v
                                    },
                                    expression: "PO_Display",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "All" } },
                                    [_vm._v(" 全部 ")]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "Doing" } },
                                    [_vm._v(" 正在进行 ")]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "Marked" } },
                                    [_vm._v(" 标记的 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-table",
                            {
                              attrs: {
                                data: _vm.PO_list,
                                "element-loading-text": "Loading",
                                border: "",
                                fit: "",
                                "highlight-current-row": "",
                                "max-height": "510",
                              },
                              on: { "current-change": _vm.SelectedOrders },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { align: "center", label: "订单号" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " " + _vm._s(scope.row.orderNum) + " "
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { align: "center", label: "创建时间" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.POSO_InfoTransformation(
                                                scope.row,
                                                1
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { align: "center", label: "状态" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.POSO_InfoTransformation(
                                                scope.row,
                                                2
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { align: "center", label: "生产方" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getplantname(
                                                scope.row.Affiliation
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { align: "center", label: "客户" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(scope.row.CustomerId) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { align: "center", label: "操作" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm.fasten(scope.row)
                                          ? _c("div", [
                                              _vm._v("不支持在此处操作"),
                                            ])
                                          : _vm._e(),
                                        !_vm.fasten(scope.row)
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.showPOInfo(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 查看 ")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "mypagination" }, [
                            _c(
                              "div",
                              { staticClass: "centerpagination" },
                              [
                                _c("el-pagination", {
                                  attrs: {
                                    layout: "prev, pager, next",
                                    total: _vm.PO_listSize,
                                    "page-size": 6,
                                    "current-page": _vm.PO_nowPage,
                                  },
                                  on: { "current-change": _vm.PO_pagechange },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "el-dialog",
                            {
                              attrs: {
                                visible: _vm.showProductOrdersInfo,
                                "close-on-click-modal": false,
                                title: "订单信息",
                              },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.showProductOrdersInfo = $event
                                },
                              },
                            },
                            [
                              _c(
                                "el-form",
                                {
                                  attrs: {
                                    model: _vm.ProductOrdersInfo,
                                    "label-width": "100px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "订单号" } },
                                    [
                                      _c("el-input", {
                                        staticClass: "display",
                                        attrs: { disabled: true },
                                        model: {
                                          value: _vm.ProductOrdersInfo.orderNum,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ProductOrdersInfo,
                                              "orderNum",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ProductOrdersInfo.orderNum",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form",
                                {
                                  attrs: {
                                    inline: true,
                                    model: _vm.ProductOrdersInfo,
                                    "label-width": "100px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "创建时间" } },
                                    [
                                      _c("el-input", {
                                        staticClass: "display",
                                        attrs: { disabled: true },
                                        model: {
                                          value: _vm.timestampToTime_PO,
                                          callback: function ($$v) {
                                            _vm.timestampToTime_PO = $$v
                                          },
                                          expression: "timestampToTime_PO",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "状态" } },
                                    [
                                      _c("el-input", {
                                        staticClass: "display",
                                        attrs: { disabled: true },
                                        model: {
                                          value: _vm.InfoTransformation_PO,
                                          callback: function ($$v) {
                                            _vm.InfoTransformation_PO = $$v
                                          },
                                          expression: "InfoTransformation_PO",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form",
                                {
                                  attrs: {
                                    model: _vm.ProductOrdersInfo,
                                    "label-width": "100px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "生产方" } },
                                    [
                                      _c("el-input", {
                                        staticClass: "display",
                                        attrs: { disabled: true },
                                        model: {
                                          value:
                                            _vm.ProductOrdersInfo.Affiliation,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ProductOrdersInfo,
                                              "Affiliation",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ProductOrdersInfo.Affiliation",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "客户" } },
                                    [
                                      _c("el-input", {
                                        staticClass: "display",
                                        attrs: { disabled: true },
                                        model: {
                                          value:
                                            _vm.ProductOrdersInfo.CustomerId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ProductOrdersInfo,
                                              "CustomerId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ProductOrdersInfo.CustomerId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form",
                                {
                                  attrs: {
                                    model: _vm.ProductOrdersInfo,
                                    "label-width": "100px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "详细订单信息" } },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            data: _vm.ProductOrdersInfo.Product,
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              type: "index",
                                              width: "50",
                                              label: "No",
                                              align: "center",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "型号",
                                              "min-width": "100",
                                              align: "center",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticClass: "display",
                                                        attrs: {
                                                          placeholder:
                                                            "请选择型号",
                                                          disabled: true,
                                                          filterable: "",
                                                        },
                                                        model: {
                                                          value: scope.row.Type,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "Type",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.Type",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.ProdutInfo,
                                                        function (item) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: item,
                                                              attrs: {
                                                                label: item,
                                                                value: item,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "生产数量",
                                              "min-width": "100",
                                              align: "center",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "centerinput",
                                                      attrs: {
                                                        type: "text",
                                                        size: "small",
                                                        disabled: true,
                                                      },
                                                      model: {
                                                        value: scope.row.Number,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "Number",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.Number",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "完成数量",
                                              "min-width": "100",
                                              align: "center",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "centerinput",
                                                      attrs: {
                                                        type: "text",
                                                        size: "small",
                                                        disabled: true,
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row.doneNumber,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "doneNumber",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.doneNumber",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "dialog-footer",
                                  attrs: { slot: "footer" },
                                  slot: "footer",
                                },
                                [
                                  _vm.ProductOrdersInfo.Attention
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            icon: "el-icon-star-on",
                                          },
                                          on: { click: _vm.MarkOrNot_PO },
                                        },
                                        [_vm._v(" 已标记 ")]
                                      )
                                    : _vm._e(),
                                  !_vm.ProductOrdersInfo.Attention
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "normal",
                                            icon: "el-icon-star-off",
                                          },
                                          on: { click: _vm.MarkOrNot_PO },
                                        },
                                        [_vm._v(" 点击标记 ")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "normal" },
                                      on: { click: _vm.ExitAndDontSave },
                                    },
                                    [_vm._v(" 返回 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-card",
                        { staticClass: "box-card-StorageOrder" },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [
                              _c("span", [_vm._v("出库订单速览")]),
                              _c(
                                "el-radio-group",
                                {
                                  staticClass: "POSODisplay",
                                  attrs: { size: "small" },
                                  model: {
                                    value: _vm.SO_Display,
                                    callback: function ($$v) {
                                      _vm.SO_Display = $$v
                                    },
                                    expression: "SO_Display",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "All" } },
                                    [_vm._v(" 全部 ")]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "Doing" } },
                                    [_vm._v(" 正在进行 ")]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "Marked" } },
                                    [_vm._v(" 标记的 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-table",
                            {
                              attrs: {
                                data: _vm.SO_list,
                                "element-loading-text": "Loading",
                                border: "",
                                fit: "",
                                "highlight-current-row": "",
                                "max-height": "510",
                              },
                              on: { "current-change": _vm.SelectedOrders },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { align: "center", label: "订单号" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " " + _vm._s(scope.row.orderNum) + " "
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { align: "center", label: "创建时间" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.POSO_InfoTransformation(
                                                scope.row,
                                                1
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { align: "center", label: "状态" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.POSO_InfoTransformation(
                                                scope.row,
                                                2
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { align: "center", label: "生产方" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getplantname(
                                                scope.row.Affiliation
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { align: "center", label: "客户" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(scope.row.CustomerId) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { align: "center", label: "操作" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm.fasten(scope.row)
                                          ? _c("div", [
                                              _vm._v("不支持在此处操作"),
                                            ])
                                          : _vm._e(),
                                        !_vm.fasten(scope.row)
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.showSOInfo(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 查看 ")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "mypagination" }, [
                            _c(
                              "div",
                              { staticClass: "centerpagination" },
                              [
                                _c("el-pagination", {
                                  attrs: {
                                    layout: "prev, pager, next",
                                    total: _vm.SO_listSize,
                                    "page-size": 6,
                                    "current-page": _vm.SO_nowPage,
                                  },
                                  on: { "current-change": _vm.SO_pagechange },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "el-dialog",
                            {
                              attrs: {
                                visible: _vm.showStorageOrdersInfo,
                                "close-on-click-modal": false,
                                title: "订单信息",
                              },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.showStorageOrdersInfo = $event
                                },
                              },
                            },
                            [
                              _c(
                                "el-form",
                                {
                                  attrs: {
                                    model: _vm.StorageOrdersInfo,
                                    "label-width": "100px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "订单号" } },
                                    [
                                      _c("el-input", {
                                        staticClass: "display",
                                        attrs: { disabled: true },
                                        model: {
                                          value: _vm.StorageOrdersInfo.orderNum,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.StorageOrdersInfo,
                                              "orderNum",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "StorageOrdersInfo.orderNum",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form",
                                {
                                  attrs: {
                                    inline: true,
                                    model: _vm.StorageOrdersInfo,
                                    "label-width": "100px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "创建时间" } },
                                    [
                                      _c("el-input", {
                                        staticClass: "display",
                                        attrs: { disabled: true },
                                        model: {
                                          value: _vm.timestampToTime_SO,
                                          callback: function ($$v) {
                                            _vm.timestampToTime_SO = $$v
                                          },
                                          expression: "timestampToTime_SO",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "状态" } },
                                    [
                                      _c("el-input", {
                                        staticClass: "display",
                                        attrs: { disabled: true },
                                        model: {
                                          value: _vm.InfoTransformation_SO,
                                          callback: function ($$v) {
                                            _vm.InfoTransformation_SO = $$v
                                          },
                                          expression: "InfoTransformation_SO",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form",
                                {
                                  attrs: {
                                    model: _vm.StorageOrdersInfo,
                                    "label-width": "100px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "生产方" } },
                                    [
                                      _c("el-input", {
                                        staticClass: "display",
                                        attrs: { disabled: true },
                                        model: {
                                          value:
                                            _vm.StorageOrdersInfo.Affiliation,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.StorageOrdersInfo,
                                              "Affiliation",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "StorageOrdersInfo.Affiliation",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "客户" } },
                                    [
                                      _c("el-input", {
                                        staticClass: "display",
                                        attrs: { disabled: true },
                                        model: {
                                          value:
                                            _vm.StorageOrdersInfo.CustomerId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.StorageOrdersInfo,
                                              "CustomerId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "StorageOrdersInfo.CustomerId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form",
                                {
                                  attrs: {
                                    model: _vm.StorageOrdersInfo,
                                    "label-width": "100px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "详细订单信息" } },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            data: _vm.StorageOrdersInfo.Product,
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              type: "index",
                                              width: "50",
                                              label: "No",
                                              align: "center",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "型号",
                                              "min-width": "100",
                                              align: "center",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticClass: "display",
                                                        attrs: {
                                                          placeholder:
                                                            "请选择型号",
                                                          disabled: true,
                                                          filterable: "",
                                                        },
                                                        model: {
                                                          value: scope.row.Type,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "Type",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.Type",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.ProdutInfo,
                                                        function (item) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: item,
                                                              attrs: {
                                                                label: item,
                                                                value: item,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "出库数量",
                                              "min-width": "100",
                                              align: "center",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "centerinput",
                                                      attrs: {
                                                        type: "text",
                                                        size: "small",
                                                        disabled: true,
                                                      },
                                                      model: {
                                                        value: scope.row.Number,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "Number",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.Number",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "已出数量",
                                              "min-width": "100",
                                              align: "center",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("el-input", {
                                                      staticClass:
                                                        "centerinput",
                                                      attrs: {
                                                        type: "text",
                                                        size: "small",
                                                        disabled: true,
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row.doneNumber,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "doneNumber",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.doneNumber",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "dialog-footer",
                                  attrs: { slot: "footer" },
                                  slot: "footer",
                                },
                                [
                                  _vm.StorageOrdersInfo.Attention
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            icon: "el-icon-star-on",
                                          },
                                          on: { click: _vm.MarkOrNot_SO },
                                        },
                                        [_vm._v(" 已标记 ")]
                                      )
                                    : _vm._e(),
                                  !_vm.StorageOrdersInfo.Attention
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "normal",
                                            icon: "el-icon-star-off",
                                          },
                                          on: { click: _vm.MarkOrNot_SO },
                                        },
                                        [_vm._v(" 点击标记 ")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "normal" },
                                      on: { click: _vm.ExitAndDontSave },
                                    },
                                    [_vm._v(" 返回 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Mac地址配置", name: "second" } },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "20px" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      model: {
                        value: _vm.MesCertifyDisplay,
                        callback: function ($$v) {
                          _vm.MesCertifyDisplay = $$v
                        },
                        expression: "MesCertifyDisplay",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "All" } }, [
                        _vm._v(" 显示全部 "),
                      ]),
                      _c("el-radio-button", { attrs: { label: "Certified" } }, [
                        _vm._v(" 显示已授权 "),
                      ]),
                      _c("el-radio-button", { attrs: { label: "unCertify" } }, [
                        _vm._v(" 显示待授权 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.Meslist,
                    "element-loading-text": "Loading",
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { align: "center", label: "Mac地址" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_vm._v(" " + _vm._s(scope.row.Mac) + " ")]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "上次在线时间" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.MesInfoTransformation(scope.row, 1)
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.MesInfoTransformation(scope.row, 2)
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "是否授权" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.MesInfoTransformation(scope.row, 3)
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "生产方" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getplantname(scope.row.Affiliation)
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "操作", width: "255" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            !_vm.ReadCertify(scope.row)
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showBeCertify(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 确认授权 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showDeleteInfo(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 拒绝授权 ")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.ReadCertify(scope.row) ? _c("div") : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "el-dialog",
                {
                  attrs: { visible: _vm.showDelete, title: "拒绝授权" },
                  on: {
                    "update:visible": function ($event) {
                      _vm.showDelete = $event
                    },
                  },
                },
                [
                  _c("h4", [_vm._v("确定拒绝授权？")]),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.deleteInfo },
                        },
                        [_vm._v("确定")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "normal" },
                          on: {
                            click: function ($event) {
                              _vm.showDelete = false
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-dialog",
                {
                  attrs: { visible: _vm.showCertified, title: "给予授权" },
                  on: {
                    "update:visible": function ($event) {
                      _vm.showCertified = $event
                    },
                  },
                },
                [
                  _c("h4", [_vm._v("请于授权前指定该mac地址的生产方")]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择生产方",
                            filterable: "",
                          },
                          model: {
                            value: _vm.MesInfo.Affiliation,
                            callback: function ($$v) {
                              _vm.$set(_vm.MesInfo, "Affiliation", $$v)
                            },
                            expression: "MesInfo.Affiliation",
                          },
                        },
                        _vm._l(_vm.PlantInfo, function (item) {
                          return _c("el-option", {
                            key: item.name,
                            attrs: {
                              label: item.name,
                              value: item.identifierStr,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c("h4", [_vm._v("确定给予授权？")]),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.ChangeCertify },
                        },
                        [_vm._v("确定")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "normal" },
                          on: {
                            click: function ($event) {
                              _vm.showCertified = false
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "详细",
            visible: _vm.showDetailed,
            "close-on-click-modal": false,
            width: "90%",
            top: "5%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDetailed = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { height: "700px" } },
            [
              _c(
                "el-tabs",
                {
                  attrs: { "tab-position": "right" },
                  on: { "tab-click": _vm.show_Detailed },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "生产状况" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", {
                              ref: "echart_Pie_ProductionProgress_Large",
                              attrs: {
                                id: "echart_Pie_ProductionProgress_Large",
                              },
                            }),
                          ]),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", {
                              ref: "echart_Pie_ProductDistribution_Large",
                              attrs: {
                                id: "echart_Pie_ProductDistribution_Large",
                              },
                            }),
                          ]),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("div", {
                              ref: "echart_Bar_ProductionStatus_Large",
                              attrs: {
                                id: "echart_Bar_ProductionStatus_Large",
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-tab-pane", { attrs: { label: "生产日期分布" } }, [
                    _c("div", {
                      ref: "echart_Line_QuantityAndDate_Large",
                      attrs: { id: "echart_Line_QuantityAndDate_Large" },
                    }),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }