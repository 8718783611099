import request from '@/utils/request'

export function getbugList(params) {
    return request({
        url: '/Api/buglist/bugInfo',
        method: 'get',
        params
    })
  }
  export function createbugList(data) {
    return request({
      url: '/Api/buglist/createbugInfo',
      method: 'post',
      data
    })
  }
  export function deletebugList(id) {
    return request({
      url: '/Api/buglist/deletebugInfo',
      method: 'delete',
      params: id
    })
  }
  export function updatebugList(data) {
    return request({
      url: '/Api/buglist/updatebugInfo',
      method: 'post',
      data
    })
  }
  export function getLastNum() {
    return request({
      url: '/Api/buglist/getLastNum',
      method: 'get',
    })
  }

  