var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "headbutton" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.createSummaryInfo },
            },
            [_vm._v("新建")]
          ),
          _c("el-input", {
            staticClass: "Fuzzy_search",
            attrs: { placeholder: "通过货单号搜索" },
            model: {
              value: _vm.Search,
              callback: function ($$v) {
                _vm.Search = $$v
              },
              expression: "Search",
            },
          }),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.FuzzySearch } },
            [_vm._v("搜索")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.list, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "Number",
                  align: "center",
                  label: "ID",
                  "min-width": "95",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.$index +
                                (_vm.getlist.page - 1) * _vm.getlist.limit +
                                1
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "Number",
                  align: "center",
                  label: "订货号",
                  "min-width": "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.row.Number) + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "Produce.length",
                  align: "center",
                  label: "产品",
                  "min-width": "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.row.Name) + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "Problem",
                  align: "center",
                  label: "问题",
                  "min-width": "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.showInfo(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看详情")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteRow(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showOrderInfo, title: "故障信息" },
          on: {
            "update:visible": function ($event) {
              _vm.showOrderInfo = $event
            },
            close: _vm.closeInfo,
          },
        },
        [
          _c("h4", { staticStyle: { "margin-left": "10px" } }, [
            _vm._v("模块"),
          ]),
          _c("el-input", {
            attrs: { placeholder: "请输入模块" },
            model: {
              value: _vm.oderInfo.Name,
              callback: function ($$v) {
                _vm.$set(_vm.oderInfo, "Name", $$v)
              },
              expression: "oderInfo.Name",
            },
          }),
          _c(
            "h4",
            { staticStyle: { "margin-left": "10px", display: "inline-block" } },
            [_vm._v("订货号")]
          ),
          _c(
            "el-button",
            {
              staticClass: "autogetbutton",
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.showAddProduce },
            },
            [_vm._v("添加问题")]
          ),
          _vm.IsaddProduce
            ? _c("el-input", {
                staticStyle: {
                  "margin-left": "10px",
                  display: "inline-block",
                  width: "250px",
                },
                attrs: { placeholder: "请输入问题" },
                model: {
                  value: _vm.newProduce,
                  callback: function ($$v) {
                    _vm.newProduce = $$v
                  },
                  expression: "newProduce",
                },
              })
            : _vm._e(),
          _vm.IsaddProduce
            ? _c(
                "el-button",
                {
                  staticClass: "autogetbutton",
                  attrs: { type: "primary", size: "small", round: "" },
                  on: { click: _vm.addProduce },
                },
                [_vm._v("确定")]
              )
            : _vm._e(),
          _vm.IsaddProduce
            ? _c(
                "el-button",
                {
                  staticClass: "autogetbutton",
                  attrs: { type: "primary", size: "small", round: "" },
                  on: { click: _vm.cancelAdd },
                },
                [_vm._v("取消")]
              )
            : _vm._e(),
          _c("el-input", {
            attrs: { placeholder: "请输入货号" },
            model: {
              value: _vm.oderInfo.Number,
              callback: function ($$v) {
                _vm.$set(_vm.oderInfo, "Number", $$v)
              },
              expression: "oderInfo.Number",
            },
          }),
          _vm._l(_vm.oderInfo.Problem, function (value, name) {
            return _c(
              "div",
              { key: name },
              [
                _c(
                  "div",
                  { staticStyle: { position: "relative" } },
                  [
                    _c(
                      "h4",
                      {
                        staticStyle: {
                          "margin-left": "10px",
                          display: "inline-block",
                          "margin-right": "20px",
                        },
                      },
                      [_vm._v(_vm._s(name))]
                    ),
                    _c("el-button", {
                      staticStyle: { "margin-right": "10px" },
                      attrs: {
                        type: "primary",
                        size: "small",
                        icon: "el-icon-plus",
                        circle: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addProblem(name)
                        },
                      },
                    }),
                    _c(
                      "el-popconfirm",
                      {
                        attrs: { title: "请确认删除" },
                        on: {
                          confirm: function ($event) {
                            return _vm.deleteProblem(name)
                          },
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            slot: "reference",
                            type: "danger",
                            icon: "el-icon-delete",
                            circle: "",
                            size: "small",
                          },
                          slot: "reference",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-card",
                  { staticClass: "classcard", attrs: { shadow: "hover" } },
                  _vm._l(value, function (item, indexs) {
                    return _c(
                      "div",
                      { key: indexs, attrs: { id: "cardclass" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入问题" },
                          model: {
                            value: value[indexs],
                            callback: function ($$v) {
                              _vm.$set(value, indexs, $$v)
                            },
                            expression: "value[indexs]",
                          },
                        }),
                        _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "请确认删除" },
                            on: {
                              confirm: function ($event) {
                                return _vm.deleteclass(name, indexs)
                              },
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "reference",
                                type: "danger",
                                icon: "el-icon-delete",
                                circle: "",
                                size: "small",
                              },
                              slot: "reference",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ],
              1
            )
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.hideOderInfo } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.updateList } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.getlist.page,
          limit: _vm.getlist.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.getlist, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.getlist, "limit", $event)
          },
          pagination: _vm.getSummaryInfo,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }