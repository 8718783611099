<template>
  <div>
    <div class="button_group underline">
      <el-input
        class="inline _250px"
        @change="SeachChange()"
        placeholder="序列号搜索"
        prefix-icon="el-icon-search"
        v-model="filter.invoiceNumber"
      >
      </el-input>
      <el-button type="primary" @click="CreateForm">新建</el-button>
    </div>
    <div id="table" v-loading="vLoading" key="tableKey">
      <el-table :data="packingandinvoiceList" style="width: 100%">
        <el-table-column prop="invoiceNumber" label="单号" align="center">
        </el-table-column>
        <el-table-column prop="Desc" label="备注" align="center">
        </el-table-column>
        <el-table-column prop="CreativeTime" label="创建时间" align="center">
          <template slot-scope="scope">
            <span>{{ convertToDate(scope.row.CreativeTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="UPSNumber" label="UPS单号" align="center">
        </el-table-column>
        <el-table-column
          prop="UPSSignforTime"
          label="UPS签收时间"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.UPSSignforTime">{{
              convertToDateYMD(scope.row.UPSSignforTime)
            }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click.native.prevent="EditForm(scope.row)" size="small">
              编辑
            </el-button>
            <el-button
              @click.native.prevent="download(scope.row)"
              size="small"
            >
              下载
            </el-button>
            <el-button
              @click.native.prevent="deleteFrom(scope.row._id)"
              size="small"
              :disabled="
                !(scope.row.UPSNumber == '' || scope.row.UPSNumber == null)
              "
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="center">
        <el-pagination
          layout="prev, pager, next"
          :total="totalcount"
          sizes="10"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      title="编辑表单"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="1200px"
    >
      <el-form label-position="left" label-width="100px" :model="SelectForm">
        <el-form-item label="单号" prop="invoiceNumber">
          <el-input
            style="width: 100%"
            v-model="SelectForm.invoiceNumber"
          ></el-input>
        </el-form-item>
        <el-form-item label="UPS单号" prop="UPSNumber">
          <el-input
            style="width: 100%"
            v-model="SelectForm.UPSNumber"
          ></el-input>
        </el-form-item>
        <el-form-item label="UPS签收时间" prop="UPSSignforTime">
          <el-date-picker
            v-model="SelectForm.UPSSignforTime"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <el-form :model="SelectForm">
        <el-form-item label="产品" prop="Products">
          <el-button
            style="float: right"
            type="primary"
            @click="AddItemToSelect"
          >
            添加一行
          </el-button>
          <el-table :data="SelectForm.Products" style="width: 100%">
            <el-table-column
              prop="ARTICLE"
              label="订货号"
              width="180"
              align="left"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.ARTICLE"
                  @change="ARTICLEChange(scope.row)"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="Number"
              label="数量"
              width="180"
              align="center"
            >
              <template slot-scope="scope">
                <el-input v-model="scope.row.Number"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="名称" align="center">
            </el-table-column>
            <el-table-column prop="parts" label="型号" align="center">
            </el-table-column>
            <el-table-column prop="price" label="价格" align="center">
            </el-table-column>
            <el-table-column prop="weight" label="毛重" align="center">
            </el-table-column>
            <el-table-column prop="netweight" label="净重" align="center">
            </el-table-column>
            <el-table-column prop="HScode" label="HsCode" align="center">
            </el-table-column>
            <el-table-column prop="EngName" label="英文名" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center" min-width="200px">
              <template slot-scope="scope">
                <!-- <el-input v-model="scope.row.Number"></el-input> -->
                <el-button
                  @click.native.prevent="deleteProduct(scope.row)"
                  size="small"
                >
                  删除
                </el-button>
                <el-button
                  @click.native.prevent="ARTICLEChange(scope.row)"
                  size="small"
                >
                  刷新属性
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- <div class="right">
            <el-button type="primary" @click="AddItemToSelect"
              >添加一行</el-button
            >
          </div> -->
        </el-form-item>
      </el-form>

      <el-form label-position="left" label-width="130px" :model="SelectForm">
        <el-form-item label="UPS模板信息">
          <el-form-item label="SAP单号" prop="SAPNumber">
            <el-input
              style="width: 100%"
              v-model="SelectForm.SAPNumber"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="Sigmatek单号"
            prop="SigmatekNumber"
            style="margin-top: 10px"
          >
            <el-input
              style="width: 100%"
              v-model="SelectForm.SigmatekNumber"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="UPS收货人"
            prop="UPSRecipient"
            style="margin-top: 10px"
          >
            <el-input
              style="width: 100%"
              v-model="SelectForm.UPSRecipient"
            ></el-input>
          </el-form-item>
          <el-form-item label="Volume" prop="Volume" style="margin-top: 10px">
            <el-input
              style="width: 100%"
              v-model="SelectForm.Volume"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="TotalPackage"
            prop="Volume"
            style="margin-top: 10px"
          >
            <el-input
              style="width: 100%"
              v-model="SelectForm.TotalPackage"
            ></el-input>
          </el-form-item>
        </el-form-item>

        <el-form-item label="备注" prop="Desc">
          <el-input
            style="width: 100%"
            v-model="SelectForm.Desc"
            type="textarea"
            :autosize="{ minRows: 2 }"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="SaveForm">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="FormTypeSelectVisible" width="30%">
      <el-checkbox-group v-model="checkList">
        <el-checkbox label="维修发货单和发票"></el-checkbox>
        <el-checkbox label="UPS发货单和发票"></el-checkbox>
        <el-checkbox label="SA发货单和发票"></el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="Generateword">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Message } from "element-ui";
import {
  createpackingandinvoice,
  getpackingandinvoiceList,
  updatepackingandinvoice,
  GetinvoiceCount,
  deletepackingandinvoice,
  getpackingandinvoiceByARTICLEInfo,
  Generateinvoice,
  Generateparking,
  GenerateUPSExportInvoice,
  GenerateUPSPackList,
  GenerateInvoice_SA,
  GeneratePackList_SA,
  GenerateInvoice_SA_1,
} from "@/api/packingSlipsAndInvoices";
export default {
  name: "PackingSlipsAndInvoices",
  data() {
    return {
      tableKey:1,
      //物料表单，用于存储查询结果，减少查询次数
      ProductMap: new Map(),
      totalcount: 0,
      //基本查询组件
      filter: {
        page: 1,
        limit: 10,
        invoiceNumber: "",
      },
      SelectForm: {
        id: null,
        CreativeTime: 0,
        invoiceNumber: "",
        UPSNumber: "",
        UPSSignforTime: 0,
        UPSRecipient: "",
        SAPNumber: "",
        SigmatekNumber: "",
        Desc: "",
        Products: [],
        TotalPackage: 0,
        Volume: 0,
      },
      FormTypeSelectVisible: false,
      //List
      packingandinvoiceList: [],
      vLoading: false,
      dialogFormVisible: false,
      checkList: [],
    };
  },
  mounted() {
    this.GetTotalCount();
    this.getData();
  },
  methods: {
    /** @获取总数 */
    GetTotalCount() {
      GetinvoiceCount()
        .then((res) => {
          this.totalcount = res.count;
        })
        .catch((err) => {
         // console.log(err);
        });
    },
    handleCurrentChange(val) {
      this.filter.page = val;
      this.getData();
    },
    getData() {
      this.vLoading = true;
      getpackingandinvoiceList(this.filter)
        .then((res) => {
          this.vLoading = false;
          this.packingandinvoiceList = res.data;
        })
        .catch((err) => {
        //  console.log(err);
        });
    },
    download(form) {
      this.SelectForm = form;
      this.FormTypeSelectVisible = true;
    },
    convertToDate(creativeTime) {
      // 使用Date构造函数将CreativeTime转换为Date对象
      const dateObj = new Date(creativeTime);
      //   格式化为如下形式：2023-7-3-12:00:00
      const formattedDate = `${dateObj.getFullYear()}-${
        dateObj.getMonth() + 1
      }-${dateObj.getDate()}-${dateObj.getHours()}:${dateObj.getMinutes()}:${dateObj.getSeconds()}`;
      return formattedDate;
    },
    convertToDateYMD(creativeTime) {
      // 使用Date构造函数将CreativeTime转换为Date对象
      const dateObj = new Date(creativeTime);
      const formattedDate = `${dateObj.getFullYear()}-${
        dateObj.getMonth() + 1
      }-${dateObj.getDate()}`;
      return formattedDate;
    },
    deleteFrom(index) {
      this.$confirm("此操作将永久删除是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deletepackingandinvoice({ id: index }).then(() => {
          Message({
            message: "删除成功",
            type: "success",
            duration: 5 * 1000,
          });
          this.totalcount--;
          this.getData();
        });
      });
    },
    EditForm(row) {
      this.dialogFormVisible = true;
      //console.log(row);
      //深拷贝
      this.SelectForm = JSON.parse(JSON.stringify(row));
    },
    AddItemToSelect() {
      this.SelectForm.Products.push({
        ARTICLE: "",
        Number: 1,
        name: "",
        parts: "",
        price: 0,
        weight: 0,
        netweight: 0,
        HScode: "",
        EngName:""
      });
    },
    ARTICLEChange(val) {
      let ARTICLE = val.ARTICLE;
      this.GetARTICLEInfo(ARTICLE)
        .then((res) => {
          //console.log(res);
          //改一下key统一一下
          val.price = res.price;
          val.weight = res.weight;
          val.HScode = res.HScode;
          val.netweight = res.netweight;
          val.name = res.Id;
          val.parts = res.parts;
          val.EngName = res.EngName;
          this.tableKey += 1
           this.SelectForm.Products =  JSON.parse(JSON.stringify(this.SelectForm.Products));
          // console.log(this.$refs.table)
        })
        .catch((err) => {
          Message({
            message: "没找到物料",
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    GetARTICLEInfo(ARTICLE) {
      return new Promise((resolve, reject) => {
        if (this.ProductMap.has(ARTICLE)) {
          resolve(this.ProductMap.get(ARTICLE));
        } else {
          //查询数据库
          getpackingandinvoiceByARTICLEInfo({ ARTICLEInfo: ARTICLE })
            .then((res) => {
              this.ProductMap.set(ARTICLE, res.data);
              resolve(res.data);
            })
            .catch((err) => {
           //   console.log(err);
              reject(err);
            });
        }
      });
    },
    deleteProduct(row) {
      let index = this.SelectForm.Products.indexOf(row);
      this.SelectForm.Products.splice(index, 1);
    },
    SaveForm() {
      updatepackingandinvoice(this.SelectForm)
        .then((res) => {
          Message({
            message: "更新成功",
            type: "success",
            duration: 5 * 1000,
          });
          this.dialogFormVisible = false;
          this.getData();
        })
        .catch((err) => {
         // console.log(err);
        });
    },
    /** @新建一个表单 */
    CreateForm() {
      createpackingandinvoice()
        .then(() => {
          this.GetTotalCount();
          this.getData();
        })
        .catch((err) => {
         // console.log(err);
        });
    },
    Generateword() {
      let val = this.SelectForm;
      this.checkList.forEach((element) => {
        if (element == "维修发货单和发票") {
          Generateinvoice({ _id: val._id })
            .then((response) => response.arrayBuffer())
            .then((arrayBuffer) => {
           //   console.log(arrayBuffer);
              // 将 arrayBuffer 转换为 blob 对象
              const blob = new Blob([arrayBuffer], {
                type: "application/octet-stream",
              });

              // 创建一个下载链接
              const url = URL.createObjectURL(blob);

              // 创建一个隐藏的 <a> 标签
              const link = document.createElement("a");
              link.href = url;
              link.download = val.invoiceNumber + " InvoiceCommercial.pdf";

              // 模拟点击下载链接
              link.click();

              // 清理释放资源
              URL.revokeObjectURL(url);
               Generateparking({ _id: val._id})
            .then((response) => response.arrayBuffer())
            .then((arrayBuffer) => {
              // 将 arrayBuffer 转换为 blob 对象
              const blob = new Blob([arrayBuffer], {
                type: "application/octet-stream",
              });

              // 创建一个下载链接
              const url = URL.createObjectURL(blob);

              // 创建一个隐藏的 <a> 标签
              const link = document.createElement("a");
              link.href = url;
              link.download = val.invoiceNumber + " Packlinglist.pdf";
              // 模拟点击下载链接
              link.click();

              // 清理释放资源
              URL.revokeObjectURL(url);
            });
            });
         
        }
        if (element == "UPS发货单和发票") {
          GenerateUPSPackList({ _id: val._id })
            .then((response) => response.arrayBuffer())
            .then((arrayBuffer) => {
              const blob = new Blob([arrayBuffer], {
                type: "application/octet-stream",
              });

              // 创建一个下载链接
              const url = URL.createObjectURL(blob);

              // 创建一个隐藏的 <a> 标签
              const link = document.createElement("a");
              link.href = url;
              link.download = val.invoiceNumber + " UPS-Export List.pdf";
              // 模拟点击下载链接
              link.click();

              // 清理释放资源
              URL.revokeObjectURL(url);
               GenerateUPSExportInvoice({ _id:  val._id})
            .then((response) => response.arrayBuffer())
            .then((arrayBuffer) => {
              // 将 arrayBuffer 转换为 blob 对象
              const blob = new Blob([arrayBuffer], {
                type: "application/octet-stream",
              });

              // 创建一个下载链接
              const url = URL.createObjectURL(blob);

              // 创建一个隐藏的 <a> 标签
              const link = document.createElement("a");
              link.href = url;
              link.download = val.invoiceNumber + " UPS-Export Invoice.pdf";
              // 模拟点击下载链接
              link.click();

              // 清理释放资源
              URL.revokeObjectURL(url);
            });
            });
         
        }
        if (element == "SA发货单和发票") {
          GenerateInvoice_SA({ _id:  val._id })
            .then((response) => response.arrayBuffer())
            .then((arrayBuffer) => {
              const blob = new Blob([arrayBuffer], {
                type: "application/octet-stream",
              });

              // 创建一个下载链接
              const url = URL.createObjectURL(blob);

              // 创建一个隐藏的 <a> 标签
              const link = document.createElement("a");
              link.href = url;
              link.download = val.invoiceNumber + " Commercial Invoice_SA.pdf";
              // 模拟点击下载链接
              link.click();

              // 清理释放资源
              URL.revokeObjectURL(url);
               GenerateInvoice_SA_1({ _id:  val._id })
            .then((response) => response.arrayBuffer())
            .then((arrayBuffer) => {
              const blob = new Blob([arrayBuffer], {
                type: "application/octet-stream",
              });

              // 创建一个下载链接
              const url = URL.createObjectURL(blob);

              // 创建一个隐藏的 <a> 标签
              const link = document.createElement("a");
              link.href = url;
              link.download = val.invoiceNumber + " Invoice_SA.pdf";
              // 模拟点击下载链接
              link.click();

              // 清理释放资源
              URL.revokeObjectURL(url);
                 GeneratePackList_SA({ _id:   val._id })
            .then((response) => response.arrayBuffer())
            .then((arrayBuffer) => {
              const blob = new Blob([arrayBuffer], {
                type: "application/octet-stream",
              });

              // 创建一个下载链接
              const url = URL.createObjectURL(blob);

              // 创建一个隐藏的 <a> 标签
              const link = document.createElement("a");
              link.href = url;
              link.download = val.invoiceNumber + " Packlinglist_SA.pdf";
              // 模拟点击下载链接
              link.click();

              // 清理释放资源
              URL.revokeObjectURL(url);
            });
            });
            });
           
       
            
          
        }
      });
    },
    SeachChange(val) {
      this.filter.page = 1;
      this.getData();
    },
  },
};
</script>

<style scoped>
.center {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.button_group {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px;
}
.underline {
  border-bottom: 1px solid #dcdfe6;
}
.button_group .el-button,
.button_group .el-input {
  margin-right: 20px;
}
.inline {
  display: inline-block;
}
._250px {
  width: 250px;
}
</style>