import Vue from 'vue'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router'
import './assets/fonts/style.css'; // 引入你的 style.css 文件
import '@/icons' // icon
import '@/permission' // permission control
import Print from 'vue-print-nb'
import locale from 'element-ui/lib/locale/lang/zh-CN'
import VueCookies from 'vue-cookies';

Vue.use(Print);
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
if (process.env.NODE_ENV === 'production') {
  const { mockXHR } = require('../mock')
  mockXHR()
}

// set ElementUI lang to EN

Vue.use(ElementUI, { locale })
// 如果想要中文版 element-ui，按如下方式声明
//Vue.use(ElementUI)
Vue.use(VueCookies);

Vue.config.productionTip = false




new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
