var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("addlabel"),
      _c(
        "el-button",
        {
          staticClass: "addlable",
          on: {
            click: function ($event) {
              return _vm.Showaddlable()
            },
          },
        },
        [_c("svg-icon", { attrs: { "icon-class": "add" } })],
        1
      ),
      _c("el-input", {
        staticClass: "Fuzzy_search",
        attrs: { placeholder: "搜索" },
        model: {
          value: _vm.Search,
          callback: function ($$v) {
            _vm.Search = $$v
          },
          expression: "Search",
        },
      }),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.FuzzySearch } },
        [_vm._v("搜索")]
      ),
      _c(
        "div",
        { staticClass: "cardlist" },
        _vm._l(_vm.list, function (label, index) {
          return _c(
            "el-card",
            {
              key: index,
              class: _vm.classselect(label.type),
              attrs: { shadow: "hover" },
            },
            [
              _c("h2", [_vm._v(_vm._s(label.name))]),
              _c("p", [_vm._v("类型：" + _vm._s(label.type))]),
              _c("p", { staticClass: "description" }, [
                _vm._v(_vm._s(label.description) + " "),
              ]),
              _c(
                "el-popconfirm",
                {
                  attrs: {
                    "confirm-button-text": "删除",
                    icon: "el-icon-info",
                    "icon-color": "red",
                    title: "确认删除",
                  },
                  on: {
                    confirm: function ($event) {
                      return _vm.deletelabel(label)
                    },
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "deletelable",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    },
                    [_c("svg-icon", { attrs: { "icon-class": "delete" } })],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: " editlabel",
                  on: {
                    click: function ($event) {
                      return _vm.showeditlabel(label)
                    },
                  },
                },
                [_c("svg-icon", { attrs: { "icon-class": "edit" } })],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              "page-size": 10,
              layout: "prev, pager, next, jumper",
              total: _vm.total,
              "current-page": _vm.listQuery.page,
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }