import request from '@/utils/request'

export function createsuit(data) {
  return request({
    url: '/Api/suit/createsuit',
    method: 'post',
    data
  })
}

export function updatesuit(data) {
    return request({
      url: '/Api/suit/updatesuit',
      method: 'post',
      data
    })
  }
  
  export function getsuitlist(data) {
    return request({
      url: '/Api/suit/getsuitlist',
      method: 'post',
      data
    })
  }
  
  export function deletesuit(data) {
    return request({
      url: '/Api/suit/deletesuit',
      method: 'post',
      data
    })
  }
  

  export function getsuitdetail(data) {
    return request({
      url: '/Api/suit/getsuitdetail',
      method: 'post',
      data
    })
  }
  