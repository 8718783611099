var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.showInfo(null)
            },
          },
        },
        [_vm._v("新建")]
      ),
      _c("el-autocomplete", {
        staticStyle: { "margin-left": "20px", width: "400px" },
        attrs: {
          "fetch-suggestions": _vm.fetchSuggestions,
          placeholder: "输入公司名或负责人进行搜索",
          "trigger-on-focus": false,
        },
        on: { select: _vm.fetchData, change: _vm.fetchData },
        model: {
          value: _vm.SearchInput,
          callback: function ($$v) {
            _vm.SearchInput = $$v
          },
          expression: "SearchInput",
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showCustomerInfo,
            width: "1200px",
            title: "公司信息",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showCustomerInfo = $event
            },
            close: _vm.ExitAndDontSave,
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "130px", "label-position": "left" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "公司" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.customerInfo.company,
                      callback: function ($$v) {
                        _vm.$set(_vm.customerInfo, "company", $$v)
                      },
                      expression: "customerInfo.company",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "公司英文" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.customerInfo.companyEn,
                      callback: function ($$v) {
                        _vm.$set(_vm.customerInfo, "companyEn", $$v)
                      },
                      expression: "customerInfo.companyEn",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "公司地址" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.customerInfo.add,
                      callback: function ($$v) {
                        _vm.$set(_vm.customerInfo, "add", $$v)
                      },
                      expression: "customerInfo.add",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "传真" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.customerInfo.fax,
                      callback: function ($$v) {
                        _vm.$set(_vm.customerInfo, "fax", $$v)
                      },
                      expression: "customerInfo.fax",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "保修月数(单位/月)" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.customerInfo.WarrantyDays,
                      callback: function ($$v) {
                        _vm.$set(_vm.customerInfo, "WarrantyDays", $$v)
                      },
                      expression: "customerInfo.WarrantyDays",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "单号后缀代号" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.customerInfo.OrderNumberAbbr,
                      callback: function ($$v) {
                        _vm.$set(_vm.customerInfo, "OrderNumberAbbr", $$v)
                      },
                      expression: "customerInfo.OrderNumberAbbr",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form",
            { attrs: { "label-width": "130px", "label-position": "top" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "负责人信息" } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.customerInfo.contacts },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "负责人",
                          width: "150",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  model: {
                                    value: scope.row.Name,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "Name", $$v)
                                    },
                                    expression: "scope.row.Name",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "电话", width: "200", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  model: {
                                    value: scope.row.Tel,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "Tel", $$v)
                                    },
                                    expression: "scope.row.Tel",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单独地址",
                          width: "500",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    autosize: { minRows: 1 },
                                  },
                                  model: {
                                    value: scope.row.SeparateAddress,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "SeparateAddress",
                                        $$v
                                      )
                                    },
                                    expression: "scope.row.SeparateAddress",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "备注", width: "200", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    autosize: { minRows: 1 },
                                  },
                                  model: {
                                    value: scope.row.Notes,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "Notes", $$v)
                                    },
                                    expression: "scope.row.Notes",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "删除",
                          "min-width": "50",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-button", {
                                  attrs: {
                                    type: "danger",
                                    size: "small",
                                    circle: "",
                                    icon: "el-icon-close",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.remove(scope.$index)
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "buttonadd" },
                    [
                      _c("el-button", {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-plus",
                          circle: "",
                        },
                        on: { click: _vm.creatNew },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.PreferencesCheck },
                },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "normal" },
                  on: { click: _vm.ExitAndDontSave },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "ContainerTable",
          staticStyle: { "margin-top": "10px" },
          attrs: {
            data: _vm.list_AfterSearch,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand", width: "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "el-table",
                      {
                        staticStyle: {
                          width: "calc(100% - 295px)",
                          "margin-left": "295px",
                          "border-left": "1px solid #ebeef5",
                        },
                        attrs: { data: props.row.contacts },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "设为首选",
                            width: "55",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.PreferencesChange(scope)
                                          },
                                        },
                                      },
                                      [
                                        !scope.row.Preferences
                                          ? _c("i", {
                                              staticClass: "el-icon-star-off",
                                            })
                                          : _c("i", {
                                              staticClass: "el-icon-star-on",
                                            }),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "Name",
                            label: "负责人",
                            width: "120",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "Tel",
                            label: "电话",
                            width: "180",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "SeparateAddress",
                            label: "单独地址/收货地址",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "Notes",
                            label: "备注",
                            width: "400",
                            align: "center",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "公司", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.company) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "公司英文", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.companyEn) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "created_at", label: "公司地址" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.add))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "保修月数", align: "center", width: "55" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.WarrantyDays) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "传真", align: "center", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.fax) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "195" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.showInfo(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.showDeleteInfo(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showDelete, title: "删除" },
          on: {
            "update:visible": function ($event) {
              _vm.showDelete = $event
            },
          },
        },
        [
          _c("h4", [_vm._v("确定删除")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.deleteInfo } },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "normal" },
                  on: {
                    click: function ($event) {
                      _vm.showDelete = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }