// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../Img/delete.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../Img/create.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.delete[data-v-344d3dff]:hover {\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  background-repeat: no-repeat;\r\n  color: transparent;\r\n  background-size: cover;\r\n  cursor: pointer;\n}\n.marginTop[data-v-344d3dff] {\r\n  margin-top: 30px;\n}\n.inline[data-v-344d3dff] {\r\n  width: 7px;\n}\n.button[data-v-344d3dff] {\r\n  margin-top: 20px;\r\n  float: right;\n}\n.create[data-v-344d3dff]:hover {\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\r\n  background-repeat: no-repeat;\r\n  color: transparent;\r\n  background-size: cover;\r\n  cursor: pointer;\n}\r\n", ""]);
// Exports
module.exports = exports;
