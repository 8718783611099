var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "Main" }, [
        _vm.showCompanySelect()
          ? _c(
              "div",
              { staticStyle: { "margin-top": "20px", "margin-left": "20px" } },
              [
                _c("h3", [_vm._v("选择生产公司:")]),
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择" },
                    on: { change: _vm.getdata },
                    model: {
                      value: _vm.companyChoose,
                      callback: function ($$v) {
                        _vm.companyChoose = $$v
                      },
                      expression: "companyChoose",
                    },
                  },
                  _vm._l(_vm.companyList, function (item) {
                    return _c("el-option", {
                      key: item.identifierStr,
                      attrs: { label: item.name, value: item.identifierStr },
                    })
                  }),
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "tablediv" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.MaterialList,
                  "default-sort": { prop: "number", order: "ascending" },
                },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "expand" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: props.row.operateHistory,
                                "default-sort": {
                                  prop: "InDate",
                                  order: "descending",
                                },
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "Operater",
                                  label: "操作人",
                                  width: "380",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { label: "操作", align: "center" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("P", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getOperate(
                                                    scope.row.warehousingNum,
                                                    scope.row.scrapNum
                                                  )
                                                )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "时间",
                                  width: "300",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("P", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getDate(scope.row.InDate)
                                                )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "MaterialName",
                    label: "物料名称",
                    width: "180",
                    align: "left",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "number",
                    label: "订货号",
                    width: "180",
                    align: "left",
                  },
                }),
                _c("el-table-column", {
                  attrs: { prop: "total", label: "入库数", align: "center" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "scrap", label: "报废数", align: "center" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "consume", label: "已消耗", align: "center" },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "preConsume",
                    label: "预计消耗",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: { prop: "remaining", label: "剩余", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "P",
                            {
                              class: _vm.getTxtColor(
                                scope.row.remaining,
                                scope.row.preConsume
                              ),
                            },
                            [_vm._v(" " + _vm._s(scope.row.remaining))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "操作", align: "center", width: "300" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.inStorage(scope.$index, scope.row)
                                },
                              },
                            },
                            [_vm._v("入库")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.reportingLoss(
                                    scope.$index,
                                    scope.row
                                  )
                                },
                              },
                            },
                            [_vm._v("报损")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.getOrder(scope.$index, scope.row)
                                },
                              },
                            },
                            [_vm._v("相关订单")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "报损",
            visible: _vm.ChangeBadInfo,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.ChangeBadInfo = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-left": "5px", "margin-bottom": "10px" } },
            [_vm._v("报损：")]
          ),
          _c("el-input", {
            attrs: { type: "“number”", placeholder: "请输入数量", min: "0" },
            model: {
              value: _vm.ShowBadInfo.num,
              callback: function ($$v) {
                _vm.$set(_vm.ShowBadInfo, "num", $$v)
              },
              expression: "ShowBadInfo.num",
            },
          }),
          _c(
            "div",
            {
              staticStyle: {
                "margin-top": "10px",
                display: "flex",
                "justify-content": "flex-end",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.confirmBad },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.ChangeBadInfo = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增入库",
            visible: _vm.ChangeInInfo,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.ChangeInInfo = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-left": "5px", "margin-bottom": "10px" } },
            [_vm._v("入库：")]
          ),
          _c("el-input", {
            attrs: { type: "“number”", placeholder: "请输入数量", min: "0" },
            model: {
              value: _vm.ShowInInfo.num,
              callback: function ($$v) {
                _vm.$set(_vm.ShowInInfo, "num", $$v)
              },
              expression: "ShowInInfo.num",
            },
          }),
          _c(
            "div",
            {
              staticStyle: {
                "margin-top": "10px",
                display: "flex",
                "justify-content": "flex-end",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.confirmIn },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.ChangeInInfo = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "订单列表",
            visible: _vm.showOrder,
            width: "60%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showOrder = $event
            },
          },
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.orderList } },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "ID", width: "95" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "订货号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.row.orderNum) + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "创建时间", width: "300", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("P", [
                          _vm._v(
                            " " + _vm._s(_vm.getDate(scope.row.CreativeTime))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "客户" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.row.CustomerId) + " ")]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }