import request from '@/utils/request'
export function CreatPcn(data) {
    return request({
      url: '/Api/Pcn/CreatPcn',
      method: 'post',
      data
    })
  }
export function getPcnList(data) {
    return request({
      url: '/Api/Pcn/getPcnList',
      method: 'post',
      data
    })
  }
export function updatePcn(data) {
    return request({
      url: '/Api/Pcn/updatePcn',
      method: 'post',
      data
    })
  }

export function deletePcn(data) {
    return request({
      url: '/Api/Pcn/deletePcn',
      method: 'post',
      data
    })
  }
  export function getimagefile(data){
    return request({
      url: '/Api/Pcn/getimagefile',
      method: 'post',
      data
    })
  }
  export function GeneratePdf(data) {
    return request({
      url: '/Api/Pcn/GeneratePdf',
      method: 'post',
      data,
      responseType: 'blob'
    })
  }

