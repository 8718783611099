<template>
  <div class="app-container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="生产总览" name="first">
        <el-row :gutter="20" class="Line-Overview">
          <el-col :span="8">
            <el-card class="box-card-Overview">
              <el-row :gutter="20">
                <el-col :span="12"
                  ><div class="box-card-Overview-unit">
                    <el-statistic
                      group-separator=","
                      :precision="0"
                      :value="StatisticalInfo_text.value1"
                      :title="StatisticalInfo_text.title1"
                    /></div
                ></el-col>
                <el-col :span="12"
                  ><div class="box-card-Overview-unit">
                    <el-statistic
                      group-separator=","
                      :precision="0"
                      :value="StatisticalInfo_text.value2"
                      :title="StatisticalInfo_text.title2"
                    /></div
                ></el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12"
                  ><div class="box-card-Overview-unit">
                    <el-statistic
                      group-separator=","
                      :precision="0"
                      :value="StatisticalInfo_text.value3"
                      :title="StatisticalInfo_text.title3"
                    /></div
                ></el-col>
                <el-col :span="12"
                  ><div class="box-card-Overview-unit">
                    <el-statistic
                      group-separator=","
                      :precision="0"
                      :value="StatisticalInfo_text.value4"
                      :title="StatisticalInfo_text.title4"
                    /></div
                ></el-col>
              </el-row>
            </el-card>
          </el-col>

          <el-col :span="16">
            <el-card class="box-card-Progress">
              <div v-show="The_First" style="font-size: 40px">
                单击选中表格中的一行数据即可生成图表
              </div>
              <el-row v-show="!The_First">
                <el-col :span="12">
                  <div
                    v-if="!OrdersDataOK_PD || !OrdersDataOK_PP"
                    style="font-size: 25px; margin: 70px"
                  >
                    订单无内容
                  </div>
                  <div v-show="OrdersDataOK_PD && OrdersDataOK_PP">
                    <el-row :gutter="20">
                      <el-col :span="12">
                        <div
                          ref="echart_Pie_ProductionProgress"
                          id="echart_Pie_ProductionProgress"
                        />
                      </el-col>

                      <el-col :span="12">
                        <div
                          ref="echart_Pie_ProductDistribution"
                          id="echart_Pie_ProductDistribution"
                        />
                      </el-col>
                    </el-row>
                  </div>
                </el-col>

                <el-col :span="11">
                  <div
                    v-if="!OrdersDataOK_L"
                    style="font-size: 25px; margin: 70px"
                  >
                    订单内产品信息数据缺失
                  </div>
                  <div
                    v-show="OrdersDataOK_L"
                    ref="echart_Line_QuantityAndDate"
                    id="echart_Line_QuantityAndDate"
                  />
                </el-col>

                <el-col :span="1">
                  <div
                    v-if="OrdersDataOK_L && OrdersDataOK_PD && OrdersDataOK_PP"
                  >
                    <el-button
                      v-show="Draw_Lock"
                      type="primary"
                      icon="el-icon-lock"
                      v-on:click="Lock_Chart"
                      size="mini"
                      class="ChartButton"
                    />
                    <el-button
                      v-show="!Draw_Lock"
                      icon="el-icon-unlock"
                      v-on:click="Lock_Chart"
                      size="mini"
                      class="ChartButton"
                    />
                    <br />
                    <el-button
                      icon="el-icon-zoom-in"
                      v-on:click="show_Detailed"
                      size="mini"
                      class="ChartButton"
                    />
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>

        <br />

        <el-row :gutter="20" class="Line-Orders">
          <el-col :span="12">
            <el-card class="box-card-ProductOrder">
              <!-- 卡片头部 -->
              <div slot="header">
                <span>生产订单速览</span>
                <el-radio-group
                  v-model="PO_Display"
                  size="small"
                  class="POSODisplay"
                >
                  <el-radio-button label="All"> 全部 </el-radio-button>
                  <el-radio-button label="Doing"> 正在进行 </el-radio-button>
                  <el-radio-button label="Marked"> 标记的 </el-radio-button>
                </el-radio-group>
              </div>

              <!-- 表格数据部分 -->
              <el-table
                :data="PO_list"
                element-loading-text="Loading"
                border
                fit
                highlight-current-row
                @current-change="SelectedOrders"
                max-height="510"
              >
                <el-table-column align="center" label="订单号">
                  <template slot-scope="scope">
                    {{ scope.row.orderNum }}
                  </template>
                </el-table-column>

                <el-table-column align="center" label="创建时间">
                  <template slot-scope="scope">
                    {{ POSO_InfoTransformation(scope.row, 1) }}
                  </template>
                </el-table-column>

                <el-table-column align="center" label="状态">
                  <template slot-scope="scope">
                    {{ POSO_InfoTransformation(scope.row, 2) }}
                  </template>
                </el-table-column>

                <el-table-column align="center" label="生产方">
                  <template slot-scope="scope">
                    {{ getplantname(scope.row.Affiliation) }}
                  </template>
                </el-table-column>

                <el-table-column align="center" label="客户">
                  <template slot-scope="scope">
                    {{ scope.row.CustomerId }}
                  </template>
                </el-table-column>

                <el-table-column align="center" label="操作">
                  <template slot-scope="scope">
                    <div v-if="fasten(scope.row)">不支持在此处操作</div>
                    <div v-if="!fasten(scope.row)">
                      <el-button
                        v-on:click="showPOInfo(scope.row)"
                        type="button"
                      >
                        查看
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>

              <!-- 底部导航栏 -->
              <div class="mypagination">
                <div class="centerpagination">
                  <el-pagination
                    layout="prev, pager, next"
                    @current-change="PO_pagechange"
                    :total="PO_listSize"
                    :page-size="6"
                    :current-page="PO_nowPage"
                  >
                  </el-pagination>
                </div>
              </div>

              <!-- 查看订单详细窗口 -->
              <el-dialog
                :visible.sync="showProductOrdersInfo"
                :close-on-click-modal="false"
                title="订单信息"
              >
                <el-form :model="ProductOrdersInfo" label-width="100px">
                  <el-form-item label="订单号">
                    <el-input
                      class="display"
                      :disabled="true"
                      v-model="ProductOrdersInfo.orderNum"
                    />
                  </el-form-item>
                </el-form>

                <el-form
                  :inline="true"
                  :model="ProductOrdersInfo"
                  label-width="100px"
                >
                  <el-form-item label="创建时间">
                    <el-input
                      class="display"
                      :disabled="true"
                      v-model="timestampToTime_PO"
                    />
                  </el-form-item>

                  <el-form-item label="状态">
                    <el-input
                      class="display"
                      :disabled="true"
                      v-model="InfoTransformation_PO"
                    />
                  </el-form-item>
                </el-form>

                <el-form :model="ProductOrdersInfo" label-width="100px">
                  <el-form-item label="生产方">
                    <el-input
                      class="display"
                      :disabled="true"
                      v-model="ProductOrdersInfo.Affiliation"
                    />
                    <!-- <el-select
                                            v-model="ProductOrdersInfo.Affiliation"
                                            placeholder="请选择生产方"
                                            :disabled="showProductOrdersInfo_Read"
                                            class="display"
                                            filterable
                                        >
                                            <el-option
                                                v-for="item in PlantInfo"
                                                :key="item.name"
                                                :label="item.name"
                                                :value="item.identifierStr"
                                            >
                                            </el-option>
                                        </el-select> -->
                  </el-form-item>

                  <el-form-item label="客户">
                    <el-input
                      class="display"
                      :disabled="true"
                      v-model="ProductOrdersInfo.CustomerId"
                    />
                  </el-form-item>
                </el-form>

                <el-form :model="ProductOrdersInfo" label-width="100px">
                  <el-form-item label="详细订单信息">
                    <el-table
                      :data="ProductOrdersInfo.Product"
                      style="width: 100%"
                    >
                      <el-table-column
                        type="index"
                        width="50"
                        label="No"
                        align="center"
                      />

                      <el-table-column
                        label="型号"
                        min-width="100"
                        align="center"
                      >
                        <template slot-scope="scope">
                          <el-select
                            v-model="scope.row.Type"
                            placeholder="请选择型号"
                            :disabled="true"
                            class="display"
                            filterable
                          >
                            <el-option
                              v-for="item in ProdutInfo"
                              :key="item"
                              :label="item"
                              :value="item"
                            >
                            </el-option>
                          </el-select>
                        </template>
                      </el-table-column>

                      <el-table-column
                        label="生产数量"
                        min-width="100"
                        align="center"
                      >
                        <template slot-scope="scope">
                          <el-input
                            type="text"
                            v-model="scope.row.Number"
                            size="small"
                            class="centerinput"
                            :disabled="true"
                          />
                        </template>
                      </el-table-column>

                      <el-table-column
                        label="完成数量"
                        min-width="100"
                        align="center"
                      >
                        <template slot-scope="scope">
                          <el-input
                            type="text"
                            v-model="scope.row.doneNumber"
                            size="small"
                            class="centerinput"
                            :disabled="true"
                          />
                        </template>
                      </el-table-column>

                      <!-- <el-table-column label="删除此行" min-width="50" align="center">
                                                <template slot-scope="scope">
                                                    <el-button
                                                        type="danger"
                                                        size="small"
                                                        circle
                                                        icon="el-icon-close"
                                                        @click="remove(scope.$index)"
                                                        :disabled="showProductOrdersInfo_Read"
                                                    ></el-button>
                                                </template>
                                            </el-table-column> -->
                    </el-table>
                    <!-- <div class="buttonadd">
                                            <el-button
                                            type="primary"
                                            icon="el-icon-plus"
                                            circle
                                            :disabled="showProductOrdersInfo_Read"
                                            v-on:click="creatNew"
                                            ></el-button>
                                        </div> -->
                  </el-form-item>
                </el-form>

                <span slot="footer" class="dialog-footer">
                  <el-button
                    type="primary"
                    v-on:click="MarkOrNot_PO"
                    icon="el-icon-star-on"
                    v-if="ProductOrdersInfo.Attention"
                  >
                    已标记
                  </el-button>
                  <el-button
                    type="normal"
                    v-on:click="MarkOrNot_PO"
                    icon="el-icon-star-off"
                    v-if="!ProductOrdersInfo.Attention"
                  >
                    点击标记
                  </el-button>
                  <el-button type="normal" v-on:click="ExitAndDontSave">
                    返回
                  </el-button>
                </span>
              </el-dialog>
            </el-card>
          </el-col>

          <el-col :span="12">
            <el-card class="box-card-StorageOrder">
              <!-- 卡片头部 -->
              <div slot="header">
                <span>出库订单速览</span>
                <el-radio-group
                  v-model="SO_Display"
                  size="small"
                  class="POSODisplay"
                >
                  <el-radio-button label="All"> 全部 </el-radio-button>
                  <el-radio-button label="Doing"> 正在进行 </el-radio-button>
                  <el-radio-button label="Marked"> 标记的 </el-radio-button>
                </el-radio-group>
              </div>

              <!-- 表格数据部分 -->
              <el-table
                :data="SO_list"
                element-loading-text="Loading"
                border
                fit
                highlight-current-row
                @current-change="SelectedOrders"
                max-height="510"
              >
                <el-table-column align="center" label="订单号">
                  <template slot-scope="scope">
                    {{ scope.row.orderNum }}
                  </template>
                </el-table-column>

                <el-table-column align="center" label="创建时间">
                  <template slot-scope="scope">
                    {{ POSO_InfoTransformation(scope.row, 1) }}
                  </template>
                </el-table-column>

                <el-table-column align="center" label="状态">
                  <template slot-scope="scope">
                    {{ POSO_InfoTransformation(scope.row, 2) }}
                  </template>
                </el-table-column>

                <el-table-column align="center" label="生产方">
                  <template slot-scope="scope">
                    {{ getplantname(scope.row.Affiliation) }}
                  </template>
                </el-table-column>

                <el-table-column align="center" label="客户">
                  <template slot-scope="scope">
                    {{ scope.row.CustomerId }}
                  </template>
                </el-table-column>

                <el-table-column align="center" label="操作">
                  <template slot-scope="scope">
                    <div v-if="fasten(scope.row)">不支持在此处操作</div>
                    <div v-if="!fasten(scope.row)">
                      <el-button
                        v-on:click="showSOInfo(scope.row)"
                        type="button"
                      >
                        查看
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>

              <!-- 底部导航栏 -->
              <div class="mypagination">
                <div class="centerpagination">
                  <el-pagination
                    layout="prev, pager, next"
                    @current-change="SO_pagechange"
                    :total="SO_listSize"
                    :page-size="6"
                    :current-page="SO_nowPage"
                  >
                  </el-pagination>
                </div>
              </div>

              <!-- 查看订单详细窗口 -->
              <el-dialog
                :visible.sync="showStorageOrdersInfo"
                :close-on-click-modal="false"
                title="订单信息"
              >
                <el-form :model="StorageOrdersInfo" label-width="100px">
                  <el-form-item label="订单号">
                    <el-input
                      class="display"
                      :disabled="true"
                      v-model="StorageOrdersInfo.orderNum"
                    />
                  </el-form-item>
                </el-form>

                <el-form
                  :inline="true"
                  :model="StorageOrdersInfo"
                  label-width="100px"
                >
                  <el-form-item label="创建时间">
                    <el-input
                      class="display"
                      :disabled="true"
                      v-model="timestampToTime_SO"
                    />
                  </el-form-item>

                  <el-form-item label="状态">
                    <el-input
                      class="display"
                      :disabled="true"
                      v-model="InfoTransformation_SO"
                    />
                  </el-form-item>
                </el-form>

                <el-form :model="StorageOrdersInfo" label-width="100px">
                  <el-form-item label="生产方">
                    <el-input
                      class="display"
                      :disabled="true"
                      v-model="StorageOrdersInfo.Affiliation"
                    />
                    <!-- <el-select
                                            v-model="ProductOrdersInfo.Affiliation"
                                            placeholder="请选择生产方"
                                            :disabled="showProductOrdersInfo_Read"
                                            class="display"
                                            filterable
                                        >
                                            <el-option
                                                v-for="item in PlantInfo"
                                                :key="item.name"
                                                :label="item.name"
                                                :value="item.identifierStr"
                                            >
                                            </el-option>
                                        </el-select> -->
                  </el-form-item>

                  <el-form-item label="客户">
                    <el-input
                      class="display"
                      :disabled="true"
                      v-model="StorageOrdersInfo.CustomerId"
                    />
                  </el-form-item>
                </el-form>

                <el-form :model="StorageOrdersInfo" label-width="100px">
                  <el-form-item label="详细订单信息">
                    <el-table
                      :data="StorageOrdersInfo.Product"
                      style="width: 100%"
                    >
                      <el-table-column
                        type="index"
                        width="50"
                        label="No"
                        align="center"
                      />

                      <el-table-column
                        label="型号"
                        min-width="100"
                        align="center"
                      >
                        <template slot-scope="scope">
                          <el-select
                            v-model="scope.row.Type"
                            placeholder="请选择型号"
                            :disabled="true"
                            class="display"
                            filterable
                          >
                            <el-option
                              v-for="item in ProdutInfo"
                              :key="item"
                              :label="item"
                              :value="item"
                            >
                            </el-option>
                          </el-select>
                        </template>
                      </el-table-column>

                      <el-table-column
                        label="出库数量"
                        min-width="100"
                        align="center"
                      >
                        <template slot-scope="scope">
                          <el-input
                            type="text"
                            v-model="scope.row.Number"
                            size="small"
                            class="centerinput"
                            :disabled="true"
                          />
                        </template>
                      </el-table-column>

                      <el-table-column
                        label="已出数量"
                        min-width="100"
                        align="center"
                      >
                        <template slot-scope="scope">
                          <el-input
                            type="text"
                            v-model="scope.row.doneNumber"
                            size="small"
                            class="centerinput"
                            :disabled="true"
                          />
                        </template>
                      </el-table-column>

                      <!-- <el-table-column label="删除此行" min-width="50" align="center">
                                                <template slot-scope="scope">
                                                    <el-button
                                                        type="danger"
                                                        size="small"
                                                        circle
                                                        icon="el-icon-close"
                                                        @click="remove(scope.$index)"
                                                        :disabled="showProductOrdersInfo_Read"
                                                    ></el-button>
                                                </template>
                                            </el-table-column> -->
                    </el-table>
                    <!-- <div class="buttonadd">
                                            <el-button
                                            type="primary"
                                            icon="el-icon-plus"
                                            circle
                                            :disabled="showProductOrdersInfo_Read"
                                            v-on:click="creatNew"
                                            ></el-button>
                                        </div> -->
                  </el-form-item>
                </el-form>

                <span slot="footer" class="dialog-footer">
                  <el-button
                    type="primary"
                    v-on:click="MarkOrNot_SO"
                    icon="el-icon-star-on"
                    v-if="StorageOrdersInfo.Attention"
                  >
                    已标记
                  </el-button>
                  <el-button
                    type="normal"
                    v-on:click="MarkOrNot_SO"
                    icon="el-icon-star-off"
                    v-if="!StorageOrdersInfo.Attention"
                  >
                    点击标记
                  </el-button>
                  <el-button type="normal" v-on:click="ExitAndDontSave">
                    返回
                  </el-button>
                </span>
              </el-dialog>
            </el-card>
          </el-col>
        </el-row>
      </el-tab-pane>

      <el-tab-pane label="Mac地址配置" name="second">
        <div style="margin-bottom: 20px">
          <el-radio-group v-model="MesCertifyDisplay" size="small">
            <el-radio-button label="All"> 显示全部 </el-radio-button>
            <el-radio-button label="Certified"> 显示已授权 </el-radio-button>
            <el-radio-button label="unCertify"> 显示待授权 </el-radio-button>
          </el-radio-group>
        </div>

        <!-- Mac地址的显示的表格 -->
        <el-table
          :data="Meslist"
          element-loading-text="Loading"
          border
          fit
          highlight-current-row
        >
          <el-table-column align="center" label="Mac地址">
            <template slot-scope="scope">
              {{ scope.row.Mac }}
            </template>
          </el-table-column>

          <el-table-column align="center" label="上次在线时间">
            <template slot-scope="scope">
              {{ MesInfoTransformation(scope.row, 1) }}
            </template>
          </el-table-column>

          <el-table-column align="center" label="状态">
            <template slot-scope="scope">
              {{ MesInfoTransformation(scope.row, 2) }}
            </template>
          </el-table-column>

          <el-table-column align="center" label="是否授权">
            <template slot-scope="scope">
              {{ MesInfoTransformation(scope.row, 3) }}
            </template>
          </el-table-column>

          <el-table-column align="center" label="生产方">
            <template slot-scope="scope">
              {{ getplantname(scope.row.Affiliation) }}
            </template>
          </el-table-column>

          <el-table-column align="center" label="操作" width="255">
            <template slot-scope="scope">
              <div v-if="!ReadCertify(scope.row)">
                <!-- <el-button v-on:click="ChangeCertify(scope.row)" type="button">
                        确认授权
                        </el-button> -->
                <el-button v-on:click="showBeCertify(scope.row)" type="button">
                  确认授权
                </el-button>
                <el-button v-on:click="showDeleteInfo(scope.row)" type="button">
                  拒绝授权
                </el-button>
              </div>
              <div v-if="ReadCertify(scope.row)">
                <!-- <el-button v-on:click="showCancelCertify(scope.row)" type="button">
                           取消授权
                        </el-button> -->
              </div>
            </template>
          </el-table-column>
        </el-table>

        <!-- 二次确认窗口 -->
        <el-dialog :visible.sync="showDelete" title="拒绝授权">
          <h4>确定拒绝授权？</h4>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" v-on:click="deleteInfo">确定</el-button>
            <el-button type="normal" v-on:click="showDelete = false"
              >取消</el-button
            >
          </span>
        </el-dialog>
        <el-dialog :visible.sync="showCertified" title="给予授权">
          <h4>请于授权前指定该mac地址的生产方</h4>
          <div>
            <el-select
              v-model="MesInfo.Affiliation"
              placeholder="请选择生产方"
              filterable
            >
              <el-option
                v-for="item in PlantInfo"
                :key="item.name"
                :label="item.name"
                :value="item.identifierStr"
              >
              </el-option>
            </el-select>
          </div>
          <h4>确定给予授权？</h4>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" v-on:click="ChangeCertify"
              >确定</el-button
            >
            <el-button type="normal" v-on:click="showCertified = false"
              >取消</el-button
            >
          </span>
        </el-dialog>
        <!-- <el-dialog :visible.sync="showunCertify" title="撤销授权">
                    <h4>确定撤销授权？</h4>
                    <span slot="footer" class="dialog-footer">
                        <el-button type="primary" v-on:click="ChangeCertify">确定</el-button>
                        <el-button type="normal" v-on:click="showunCertify = false">取消</el-button>
                    </span>
                </el-dialog> -->
      </el-tab-pane>
    </el-tabs>

    <el-dialog 
      title="详细" 
      :visible.sync="showDetailed" 
      :close-on-click-modal="false"
      width="90%"
      top="5%"
    >
      <div style="height:700px">
        <el-tabs tab-position="right" @tab-click="show_Detailed" >

          <el-tab-pane label="生产状况">
            <el-row :gutter="20" >
              <el-col :span="8">
                <div
                  ref="echart_Pie_ProductionProgress_Large"
                  id="echart_Pie_ProductionProgress_Large"
                />
              </el-col>
              <el-col :span="8">
                <div
                  ref="echart_Pie_ProductDistribution_Large"
                  id="echart_Pie_ProductDistribution_Large"
                />
              </el-col>
              <el-col :span="8">
                <div
                  ref="echart_Bar_ProductionStatus_Large"
                  id="echart_Bar_ProductionStatus_Large"
                />
              </el-col>
            </el-row>
          </el-tab-pane>

          <el-tab-pane label="生产日期分布">
            <div
              ref="echart_Line_QuantityAndDate_Large"
              id="echart_Line_QuantityAndDate_Large"
            />
          </el-tab-pane>

        </el-tabs>      
      </div>
    </el-dialog>

  </div>
</template>

<script>
var echarts = require("echarts");

import { Message } from "element-ui";
import {
  GetMeslistSize,
  GetMeslist,
  UpdateMeslist,
  DeleteMeslist,
  GetProductOrderSize,
  GetProductOrders,
  UpdateProductOrder,
  GetStorageOrderSize,
  GetStorageOrders,
  UpdateStorageOrder,
} from "@/api/mes"; //表格调用
import { GetOverviewData, PositioningOrders } from "@/api/mes"; //画图调用

import { GetAllPlant } from "@/api/mesproductinfo";
import { GetAllProducts } from "@/api/mesproductinfo";
import { exportDefaultSpecifier } from "@babel/types";

export default {
  data() {
    return {
      // 生产订单相关
      PO_listLoading: true,
      PO_list: [],
      PO_listQuery: {
        start: 0,
        limit: 6,
        filters: {},
      },
      PO_listSize: 0,
      PO_nowPage: 0,

      // 出库订单相关
      SO_listLoading: true,
      SO_list: [],
      SO_listQuery: {
        start: 0,
        limit: 6,
        filters: {},
      },
      SO_listSize: 0,
      SO_nowPage: 0,

      // Mes相关
      MeslistLoading: true,
      Meslist: [],
      MeslistQuery: {
        start: 0,
        limit: 30,
        filters: {},
      },
      MeslistSize: 0,
      MesnowPage: 0,

      activeName: "first",

      MesCertifyDisplay: "Certified",
      PO_Display: "Doing",
      SO_Display: "Doing",

      showDelete: false, //删除确认窗口开关指示器
      showCertified: false, //授权确认窗口开关指示器
      // showunCertify: false, //撤销授权确认窗口开关指示器
      showProductOrdersInfo: false, //查看生产订单详细窗口开关指示器
      showStorageOrdersInfo: false, //查看出库订单详细窗口开关指示器

      MesInfo: {
        _id: null,
        Doingorder: null,
        LastProductTime: null,
        Mac: null,
        Offlinetime: null,
        OnlineTime: null,
        Status: null,
        Certification: null,
        Affiliation: null,
      }, //mac地址信息

      ProductOrdersInfo: {
        _id: null,
        CreativeTime: null,
        finshedTime: null,
        CustomerId: null,
        Status: null,
        index: null,
        orderNum: null,
        Affiliation: null,
        Attention: null,
        Product: [
          {
            Number: null,
            Type: null,
            doneNumber: null,
          },
        ],
      }, //生产订单信息

      StorageOrdersInfo: {
        _id: null,
        CreativeTime: null,
        finshedTime: null,
        CustomerId: null,
        Status: null,
        index: null,
        orderNum: null,
        Affiliation: null,
        Attention: null,
        Product: [
          {
            Number: null,
            Type: null,
            doneNumber: null,
          },
        ],
      }, //出库订单信息

      SelectedOrdersInfo: {
        _id: null,
        CreativeTime: null,
        finshedTime: null,
        CustomerId: null,
        Status: null,
        index: null,
        orderNum: null,
        Affiliation: null,
        Attention: null,
        Product: [
          {
            Number: null,
            Type: null,
            doneNumber: null,
          },
        ],
      }, //选择的订单的信息（画图用）

      StatisticalInfo_text: {
        title1: "生产中的订单",
        value1: null,
        title2: "出库中的订单",
        value2: null,
        title3: "今日新增设备",
        value3: null,
        title4: "今日出库设备",
        value4: null,
      }, //处理完的信息（左边固定的四个）

      DataProssing_Draw_Info: {
        PS_judg: null, //生产/出库订单判断
        PS_Status: null, //订单状态判断
        PD_Pie: {
          Doing: null,
          Done: null,
          Detailed: {},
        }, //生产/出库进度饼图数据
        PP_Pie: {
          Type: [
            {
              Type: null,
              Number: null,
              Done: null,
              Doing: null,
            },
          ],
          Detailed: {},
        }, //产品分布饼图数据
        QD_Line: {
          Type: [
            {
              Time: null,
              value: null,
            },
          ],
          Detailed: {},
        }, //生产/出库数量-日期折线图数据
      }, //处理完的信息（右边三幅画图,大图）

      Draw_Lock: false, //表格固定
      showDetailed: false, //查看图标详细窗口开关指示器
      The_First: true, //刚进入没有选择数据用以画图
      OrdersDataOK_PP: true, //饼图（生产进度）内容正常状态
      OrdersDataOK_PD: true, //饼图（产品分布）内容正常状态
      OrdersDataOK_L: true, //折线图内容正常状态

      Line_chart: null, //折线图
      Pie_PP_chart: null, //饼图（生产进度）
      Pie_PD_chart: null, //饼图（产品分布）

      Line_chart_Large: null, //折线大图
      Pie_PP_chart_Large: null, //大饼图（生产进度）
      Pie_PD_chart_Large: null, //大饼图（产品分布）
      Bar_PS_chart_Large: null, //大柱状图（生产状况）

      PlantInfo: [],
      ProdutInfo: [],
    };
  },

  watch: {
    MesCertifyDisplay() {
      this.CertifyDisplayChange();
    }, //Mes快速筛选(全部/已授权/待授权)，值的监控

    PO_Display() {
      this.PO_DisplayChange();
    }, //生产订单快速筛选(全部/正在进行/标记的)，值的监控

    SO_Display() {
      this.SO_DisplayChange();
    }, //出库订单快速筛选(全部/正在进行/标记的)，值的监控
  },

  updated() {
    if(this.Line_chart)
    {
      this.Line_chart.resize();
    }
    if(this.Pie_PP_chart){
      this.Pie_PP_chart.resize();
    }
    if(this.Pie_PD_chart){
      this.Pie_PD_chart.resize();
    }
    
  },

  created() {
    this.MeslistLoading = false;
    this.The_First = true;
    this.getMesSize();
    this.getPOSize();
    this.getSOSize();
    this.GetPlantInfo();
    this.GetProductInfo();
    this.CertifyDisplayChange();
    this.PO_DisplayChange();
    this.SO_DisplayChange();

    this.GetStatisticalInfo();
  },

  computed: {
    timestampToTime_PO: function () {
      var timestamp = this.ProductOrdersInfo.CreativeTime;
      var date = new Date(timestamp); // 参数为毫秒
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes() + ":";
      var s = date.getSeconds();
      return Y + M + D + h + m + s;
    }, //生产订单查看模式专用，时间转换

    InfoTransformation_PO: function () {
      if (this.ProductOrdersInfo.Status == "NotPublished") {
        return "尚未发布";
      }
      if (this.ProductOrdersInfo.Status == "Doing") {
        return "正在进行";
      }
      if (this.ProductOrdersInfo.Status == "Done") {
        return "已完成";
      }
    }, //生产订单查看模式专用，状态转换

    timestampToTime_SO: function () {
      var timestamp = this.StorageOrdersInfo.CreativeTime;
      var date = new Date(timestamp); // 参数为毫秒
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes() + ":";
      var s = date.getSeconds();
      return Y + M + D + h + m + s;
    }, //出库订单查看模式专用，时间转换

    InfoTransformation_SO: function () {
      if (this.StorageOrdersInfo.Status == "NotPublished") {
        return "尚未发布";
      }
      if (this.StorageOrdersInfo.Status == "Doing") {
        return "正在进行";
      }
      if (this.StorageOrdersInfo.Status == "Done") {
        return "已完成";
      }
    }, //出库订单查看模式专用，状态转换

    GetTodayTimeStamp: function () {
      var date = new Date(); // 参数为毫秒
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate();
      return Y + M + D;
    }, //获取今日年月日时间戳
  },

  methods: {
    getplantname(input) {
      for (let item of this.PlantInfo) {
        if (item.identifierStr == input) {
          return item.name;
        }
      }
      return "";
    }, //生产方显示

    async GetPlantInfo() {
      const { data } = await GetAllPlant();
      this.PlantInfo = data.list;
    }, //获取生产方（需预设）信息
    async GetProductInfo() {
      const { data } = await GetAllProducts();
      data.list.map((item) => {
        this.ProdutInfo.push(item.name);
      });
    }, //获取生产产品（需预设）数据

    async getMesSize() {
      this.MeslistLoading = true;
      const { count } = await GetMeslistSize(this.MeslistQuery);
      this.MeslistSize = count;
      this.fetchMesData();
    }, //获取mes数据数量
    async getPOSize() {
      this.PO_listLoading = true;
      const { count } = await GetProductOrderSize(this.PO_listQuery);
      this.PO_listSize = count;
      this.fetchPOData();
    }, //获取生产订单数据数量
    async getSOSize() {
      this.SO_listLoading = true;
      const { count } = await GetStorageOrderSize(this.SO_listQuery);
      this.SO_listSize = count;
      this.fetchSOData();
    }, //获取出库订单数据数量

    async fetchMesData() {
      this.MeslistLoading = true;
      const { count, list } = await GetMeslist(this.MeslistQuery);
      this.Meslist = list;
      setTimeout(() => {
        this.MeslistLoading = false;
      }, 0.5 * 1000);
    }, //获取mes数据
    async fetchPOData() {
      this.PO_listLoading = true;
      const { count, list } = await GetProductOrders(this.PO_listQuery);
      this.PO_list = list;
      setTimeout(() => {
        this.PO_listLoading = false;
      }, 0.5 * 1000);
    }, //获取生产订单数据
    async fetchSOData() {
      this.SO_listLoading = true;
      const { count, list } = await GetStorageOrders(this.SO_listQuery);
      this.SO_list = list;
      setTimeout(() => {
        this.SO_listLoading = false;
      }, 0.5 * 1000);
    }, //获取出库订单数据

    handleClick(tab, event) {
      //console.log(tab, event);
    }, //切换标签

    MesInfoTransformation(data1, data2) {
      switch (data2) {
        case 1: //时间戳转换
          return this.timestampToTime(data1.OnlineTime);
          break;
        case 2: //状态转换
          if (data1.Status == "Online") {
            return "在线";
          }
          if (data1.Status == "Offline") {
            return "离线";
          }
          break;
        case 3: //授权状态转换
          if (data1.Certification == true) {
            return "已授权";
          }
          if (data1.Certification == false) {
            return "未授权";
          }
      }
    }, //数据转换（Mes）
    POSO_InfoTransformation(data1, data2) {
      switch (data2) {
        case 1: //时间戳转换
          return this.timestampToTime(data1.CreativeTime);
          break;
        case 2: //状态转换
          if (data1.Status == "NotPublished") {
            return "尚未发布";
          }
          if (data1.Status == "Doing") {
            return "正在进行";
          }
          if (data1.Status == "Done") {
            return "已完成";
          }
          break;
      }
    }, //数据转换（生产/出库订单）

    timestampToTime(timestamp) {
      var date = new Date(timestamp); // 参数为毫秒
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes() + ":";
      var s = date.getSeconds();
      return Y + M + D + h + m + s;
    }, //时间戳转换成时间

    ReadCertify(data) {
      if (data.Certification == true) {
        return 1;
      }
      if (data.Certification == false) {
        return 0;
      }
    }, //判断Mac地址是否授权
    fasten(data) {
      if (data.Status == "NotPublished") {
        return 1;
      }
      if (data.Status == "Doing" || data.Status == "Done") {
        return 0;
      }
    }, //判断生产/出库订单是否发布（速览界面）

    async ChangeCertify() {
      if (this.MesInfo.Certification == false) {
        this.MesInfo.Certification = true;
      } else {
        this.MesInfo.Certification = false;
      }
      const { data } = await UpdateMeslist({
        id: this.MesInfo._id,
        data: {
          Certification: this.MesInfo.Certification,
        },
      });
      this.showCertified = false;
      this.showunCertify = false;
    }, //修改Mac地址的授权

    async deleteInfo() {
      const { data } = await DeleteMeslist({
        id: this.MesInfo._id,
      });
      if (data) {
        Message({
          message: "删除成功",
          type: "success",
          duration: 5 * 1000,
        });
      }
      this.showDelete = false;
      this.getMesSize();
    }, //拒绝授权

    showDeleteInfo(data) {
      this.MesInfo = data;
      this.showDelete = true;
    }, //弹出“确认拒绝授权”窗口
    showBeCertify(data) {
      this.MesInfo = data;
      this.showCertified = true;
    }, //弹出“确认给予授权”窗口
    // showCancelCertify(data) {
    //     this.MesInfo = data;
    //     this.showunCertify = true;
    // }, //弹出“确认撤销授权”窗口

    PO_pagechange(input) {
      this.PO_listQuery.start = (Number(input) - 1) * this.PO_listQuery.limit;
      this.fetchPOData();
    }, //《生产订单速览》翻页
    SO_pagechange(input) {
      this.SO_listQuery.start = (Number(input) - 1) * this.SO_listQuery.limit;
      this.fetchSOData();
    }, //《出库订单速览》翻页

    showPOInfo(data) {
      this.showProductOrdersInfo = true;
      this.ProductOrdersInfo = data;
    }, //查看生产订单

    async MarkOrNot_PO() {
      if (this.ProductOrdersInfo.Attention == false) {
        this.ProductOrdersInfo.Attention = true;
        const { data } = await UpdateProductOrder({
          id: this.ProductOrdersInfo._id,
          data: {
            Attention: this.ProductOrdersInfo.Attention,
          },
        });
      } else {
        this.ProductOrdersInfo.Attention = false;
        const { data } = await UpdateProductOrder({
          id: this.ProductOrdersInfo._id,
          data: {
            Attention: this.ProductOrdersInfo.Attention,
          },
        });
      }
    }, //是否标记生产订单

    async ExitAndDontSave() {
      this.showProductOrdersInfo = false;
      this.showStorageOrdersInfo = false;
      this.getPOSize();
      this.getSOSize();
    }, //关闭订单查看窗口（不保存+刷新数据+关闭窗口）

    showSOInfo(data) {
      this.showStorageOrdersInfo = true;
      this.StorageOrdersInfo = data;
    }, //查看出库订单

    async MarkOrNot_SO() {
      if (this.StorageOrdersInfo.Attention == false) {
        this.StorageOrdersInfo.Attention = true;
        const { data } = await UpdateStorageOrder({
          id: this.StorageOrdersInfo._id,
          data: {
            Attention: this.StorageOrdersInfo.Attention,
          },
        });
      } else {
        this.StorageOrdersInfo.Attention = false;
        const { data } = await UpdateStorageOrder({
          id: this.StorageOrdersInfo._id,
          data: {
            Attention: this.StorageOrdersInfo.Attention,
          },
        });
      }
    }, //是否标记出库订单

    CertifyDisplayChange() {
      this.MeslistQuery.filters = {};
      if (this.MesCertifyDisplay == "All") {
        this.MeslistQuery.filters = {};
      } else {
        this.MeslistQuery.filters = "({$and:[";
        if (this.MesCertifyDisplay == "Certified") {
          this.MeslistQuery.filters += "{'Certification':'" + true + "'},";
        }
        if (this.MesCertifyDisplay == "unCertify") {
          this.MeslistQuery.filters += "{'Certification':'" + false + "'},";
        }
        this.MeslistQuery.filters += "]})";
      }
      this.getMesSize();
    }, //mes简易查询(全部/已授权/待授权)
    PO_DisplayChange() {
      this.PO_listQuery.filters = {};
      if (this.PO_Display == "All") {
        this.PO_listQuery.filters = {};
      } else {
        this.PO_listQuery.filters = "({$and:[";
        if (this.PO_Display == "Doing") {
          this.PO_listQuery.filters += "{'Status':'" + "Doing" + "'},";
        }
        if (this.PO_Display == "Marked") {
          this.PO_listQuery.filters += "{'Attention':'" + true + "'},";
        }
        this.PO_listQuery.filters += "]})";
      }
      this.PO_listQuery.start = 0;
      this.PO_nowPage = 0;
      this.getPOSize();
    }, //生产订单简易查询(全部/正在进行/标记的)
    SO_DisplayChange() {
      this.SO_listQuery.filters = {};
      if (this.SO_Display == "All") {
        this.SO_listQuery.filters = {};
      } else {
        this.SO_listQuery.filters = "({$and:[";
        if (this.SO_Display == "Doing") {
          this.SO_listQuery.filters += "{'Status':'" + "Doing" + "'},";
        }
        if (this.SO_Display == "Marked") {
          this.SO_listQuery.filters += "{'Attention':'" + true + "'},";
        }
        this.SO_listQuery.filters += "]})";
      }
      this.SO_listQuery.start = 0;
      this.SO_nowPage = 0;
      this.getSOSize();
    }, //出库订单简易查询(全部/正在进行/标记的)

    async GetStatisticalInfo() {
      const data = await GetOverviewData();
      // console.log(data);
      this.StatisticalInfo_text.value1 = data.OrdersInProduction;
      this.StatisticalInfo_text.value2 = data.OrdersInStorage;
      this.StatisticalInfo_text.value3 = data.NewTodayProduction;
      this.StatisticalInfo_text.value4 = data.NewTodayStorage;
    }, //获取生产产品（需预设）数据
    async SelectedOrders(val) {
      this.The_First = false;
      if (this.Draw_Lock == false) {
        this.SelectedOrdersInfo = val;
        // console.log(val);
        const { data } = await PositioningOrders({
          id: this.SelectedOrdersInfo._id,
          Status: this.SelectedOrdersInfo.Status,
        });
        this.DataProssing_Draw_Info = data;
        // console.log(this.DataProssing_Draw_Info);
        this.initCharts();
      }
    }, //定位选中的订单传给后端做这个订单的数据解析

    initCharts() {
      this.DrawLine_QuantityAndDate();
      this.DrawPie_ProductionProgress();
      this.DrawPie_ProductDistribution();
    }, //初始化表格，调用既刷新
    initCharts_dialog() {
      this.DrawLine_QuantityAndDate_Large();
      this.DrawPie_ProductionProgress_Large();
      this.DrawPie_ProductDistribution_Large();
      this.DrawBar_ProductionStatus_Large();
    },//初始化弹窗表格，调用既刷新


    DrawPie_ProductDistribution() {
      let typeData = [];
      let typeValue = [];
      for (let i = 0; i < this.DataProssing_Draw_Info.PP_Pie.Type.length; i++) {
        typeData.push({
          value: this.DataProssing_Draw_Info.PP_Pie.Type[i].Number,
          name: this.DataProssing_Draw_Info.PP_Pie.Type[i].Type,
        });
        typeValue.push(
          {
            value: this.DataProssing_Draw_Info.PP_Pie.Type[i].Done,
            name: "已完成",
            itemStyle: { color: "#FFFF00" },
          },
          {
            value: this.DataProssing_Draw_Info.PP_Pie.Type[i].Doing,
            name:
              this.DataProssing_Draw_Info.PS_judg == "ProductOrder"
                ? "待生产"
                : "待出库",
            itemStyle: { color: "#C0C0C0" },
          }
        );
      }

      let Option = {
        title: {
          text: "产品分布",
          show: true,
          textStyle: {},
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        series: [
          {
            name: "产品型号",
            type: "pie",
            radius: [0, "75%"],
            label: {
              position: "inner",
              fontSize: "12",
              color: "#fff",
            },
            labelLine: {
              show: false,
            },
            data: typeData,
          },

          {
            name: "生产进度",
            type: "pie",
            radius: ["75%", "80%"],
            emphasis: {
              disabled: false,
              scale: false,
              scaleSize: 0,
            },
            labelLine: {
              length: false,
            },
            label: {
              show: false,
            },
            tooltip: {
              show: false,
            },
            data: typeValue,
          },
        ],
        grid: {
          left: "2%",
          top: "25%",
          right: "5%",
          bottom: "10%",
          containLabel: true,
        },
      };

      this.Pie_PD_chart = echarts.init(
        this.$refs.echart_Pie_ProductDistribution
      );
      this.Pie_PD_chart.setOption(Option);
      window.addEventListener("resize", () => {
        this.Pie_PD_chart.resize();
      }); //用以v-show转大小
    }, //生产/出库产品分布饼图（小）

    DrawPie_ProductionProgress() {
      let ProgressData = [];
      this.OrdersDataOK_PP = true;
      if (
        this.DataProssing_Draw_Info.PD_Pie.Done == 0 ||
        this.DataProssing_Draw_Info.PD_Pie.Done == null
      ) {
        ProgressData = [
          {
            name: "待完成",
            value: this.DataProssing_Draw_Info.PD_Pie.Doing,
          },
        ];
        if (
          this.DataProssing_Draw_Info.PD_Pie.Doing == 0 ||
          this.DataProssing_Draw_Info.PD_Pie.Doing == null
        ) {
          ProgressData = [];
          this.OrdersDataOK_PP = false;
        }
      } else if (
        this.DataProssing_Draw_Info.PD_Pie.Doing == 0 ||
        this.DataProssing_Draw_Info.PD_Pie.Doing == null
      ) {
        if (
          this.DataProssing_Draw_Info.PD_Pie.Done == 0 ||
          this.DataProssing_Draw_Info.PD_Pie.Done == null
        ) {
          ProgressData = [];
          this.OrdersDataOK_PP = false;
        } else {
          ProgressData = [
            {
              name: "已完成",
              value: this.DataProssing_Draw_Info.PD_Pie.Done,
            },
          ];
        }
      } else {
        ProgressData = [
          {
            name: "已完成",
            value: this.DataProssing_Draw_Info.PD_Pie.Done,
          },
          {
            name: "待完成",
            value: this.DataProssing_Draw_Info.PD_Pie.Doing,
          },
        ];
      } //一个都没完成不显示‘完成部分’，全完成了则不显示‘未完成部分’，啥都没有则不显示

      let Option = {
        title: {
          text:
            this.DataProssing_Draw_Info.PS_judg == "ProductOrder"
              ? "生产进度"
              : "出库进度",
          show: true,
          textStyle: {},
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          x: "left",
          y: "bottom",
        },
        series: [
          {
            type: "pie",
            radius: [0, "80%"],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: true,
                position: "inner",
                formatter: function (params) {
                  var temp = "";
                  temp = params.value;
                  return temp;
                },
                color: "#fff",
              },
              emphasis: {
                show: false,
              },
            },
            labelLine: {
              show: false,
            },
            data: ProgressData,
          },
        ],
        grid: {
          left: "2%",
          top: "25%",
          right: "5%",
          bottom: "10%",
          containLabel: true,
        },
      };
      this.Pie_PP_chart = echarts.init(
        this.$refs.echart_Pie_ProductionProgress
      );
      this.Pie_PP_chart.setOption(Option);
      window.addEventListener("resize", () => {
        this.Pie_PP_chart.resize();
      }); //用以v-show转大小
    }, //生产/出库进度饼图（小）

    DrawLine_QuantityAndDate() {
      let StatesJudg = true;
      let TypeLength = this.DataProssing_Draw_Info.QD_Line.Type.length;
      let typeData = [];

      function timestampToTime(Time) {
        var timestamp = Time;
        var date = new Date(timestamp); // 参数为毫秒
        var Y = date.getFullYear() + "-";
        var M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
        var D = date.getDate();
        return Y + M + D;
      } //后端同款时间戳计算（年月日）函数

      function space(startTime, endTime) {
        var dateSpan, dates;
        startTime = Date.parse(startTime);
        endTime = Date.parse(endTime);
        dateSpan = endTime - startTime;
        dateSpan = Math.abs(dateSpan);
        dates = Math.floor(dateSpan / (24 * 3600 * 1000));
        return dates;
      } //计算日期间隔

      if (TypeLength != 0) {
        this.OrdersDataOK_L = true;

        if (this.DataProssing_Draw_Info.PS_Status == "Done") {
          var startTime = this.DataProssing_Draw_Info.QD_Line.Type[0].Time;
          var endTime =
            this.DataProssing_Draw_Info.QD_Line.Type[TypeLength - 1].Time;
        } else if (this.DataProssing_Draw_Info.PS_Status == "Doing") {
          var startTime = this.DataProssing_Draw_Info.QD_Line.Type[0].Time;
          var endTime = this.GetTodayTimeStamp;
        } else if (this.DataProssing_Draw_Info.PS_Status == "NotPublished") {
          StatesJudg = false;
        } //正在进行和已结束的显示区分，未发布不画图

        let AllDateNumber = space(startTime, endTime) + 2;
        let StartTimeStamp = new Date(startTime).getTime();
        let HaveValue = false;
        let TimeFlag;
        for (let i = 0; i < AllDateNumber; i++) {
          TimeFlag = timestampToTime(StartTimeStamp + i * 24 * 3600 * 1000);
          for (let j = 0; j < TypeLength; j++) {
            if (this.DataProssing_Draw_Info.QD_Line.Type[j].Time == TimeFlag) {
              typeData.push([
                this.DataProssing_Draw_Info.QD_Line.Type[j].Time,
                this.DataProssing_Draw_Info.QD_Line.Type[j].value,
              ]);
              HaveValue = true;
            }
          }

          if (HaveValue) {
            HaveValue = false;
          } else {
            typeData.push([
              timestampToTime(StartTimeStamp + i * 24 * 3600 * 1000),
              0,
            ]);
          }
        } //先将所有元素赋0,然后再将有日期记录的数据赋值过去

        if (StatesJudg) {
          let Option = {
            title: {
              text:
                this.DataProssing_Draw_Info.PS_judg == "ProductOrder"
                  ? "生产数量-日期折线图"
                  : "出库数量-日期折线图",
              show: true,
              textStyle: {},
            },

            tooltip: {
              trigger: "axis",
            },

            formatter: function (params) {
              var temp = "";
              temp =
                params[0].data[0] + "<br/>" + "生产数量：" + params[0].data[1];
              return temp;
            },

            xAxis: {
              type: "time",
              interval: 24 * 60 * 60 * 1000, // 固定x轴时间间隔 间隔24小时，也就是一天
              min: startTime, // 开始时间时间戳
              max: endTime,
              axisLabel: {
                show: true,
                showMinLabel: true,
                showMaxLabel: true,
              },
            },

            yAxis: {
              type: "value",
            },

            series: [
              {
                name: "生产数量",
                type: "line",
                lineStyle: {
                  color: "#409EFF",
                },
                data: typeData,
                // data: [
                //   ["2022-04-28", "0"],
                //   ["2022-04-29", "50"],
                //   ["2022-04-30", "200"],
                //   ["2022-05-01", "111"],
                //   ["2022-05-02", "500"],
                //   ["2022-05-03", "123"],
                //   ["2022-05-04", "333"],
                //   ["2022-05-05", "222"],
                //   ["2022-05-06", "55"],
                //   ["2022-05-07", "0"],
                // ],
              },
            ],

            grid: {
              left: "2%",
              top: "25%",
              right: "5%",
              bottom: "5%",
              containLabel: true,
            },
          };
          //console.log(Option.series)
          this.Line_chart = echarts.init(
            this.$refs.echart_Line_QuantityAndDate
          );
          this.Line_chart.setOption(Option);
          window.addEventListener("resize", () => {
            this.Line_chart.resize();
          }); //用以v-show转大小
        }

      } else {
        this.OrdersDataOK_L = false;
      }
    }, //生产/出库数量-日期折线图（小）



    DrawPie_ProductDistribution_Large() {
      let typeData = [];
      for (let i = 0; i < this.DataProssing_Draw_Info.PP_Pie.Type.length; i++) {
        typeData.push({
          value: this.DataProssing_Draw_Info.PP_Pie.Type[i].Number,
          name: this.DataProssing_Draw_Info.PP_Pie.Type[i].Type,
        });
      }

      let Option = {
        title: {
          text: "产品分布",
          show: true,
          textStyle: {},
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        series: [
          {
            name: "产品型号",
            type: "pie",
            radius: [0, "90%"],
            label: {
              position: "inner",
              fontSize: "12",
              color: "#fff",
            },
            labelLine: {
              show: false,
            },
            data: typeData,
          },
        ],
        grid: {
          left: "2%",
          top: "25%",
          right: "5%",
          bottom: "10%",
          containLabel: true,
        },
      };

      this.Pie_PD_chart_Large = echarts.init(
        this.$refs.echart_Pie_ProductDistribution_Large
      );
      this.Pie_PD_chart_Large.setOption(Option);
      this.Pie_PD_chart_Large.resize();
    }, //生产/出库产品分布饼图（大）

    DrawPie_ProductionProgress_Large() {
      let ProgressData = [];
      this.OrdersDataOK_PP = true;
      if (
        this.DataProssing_Draw_Info.PD_Pie.Done == 0 ||
        this.DataProssing_Draw_Info.PD_Pie.Done == null
      ) {
        ProgressData = [
          {
            name: "待完成",
            value: this.DataProssing_Draw_Info.PD_Pie.Doing,
          },
        ];
        if (
          this.DataProssing_Draw_Info.PD_Pie.Doing == 0 ||
          this.DataProssing_Draw_Info.PD_Pie.Doing == null
        ) {
          ProgressData = [];
          this.OrdersDataOK_PP = false;
        }
      } else if (
        this.DataProssing_Draw_Info.PD_Pie.Doing == 0 ||
        this.DataProssing_Draw_Info.PD_Pie.Doing == null
      ) {
        if (
          this.DataProssing_Draw_Info.PD_Pie.Done == 0 ||
          this.DataProssing_Draw_Info.PD_Pie.Done == null
        ) {
          ProgressData = [];
          this.OrdersDataOK_PP = false;
        } else {
          ProgressData = [
            {
              name: "已完成",
              value: this.DataProssing_Draw_Info.PD_Pie.Done,
            },
          ];
        }
      } else {
        ProgressData = [
          {
            name: "已完成",
            value: this.DataProssing_Draw_Info.PD_Pie.Done,
          },
          {
            name: "待完成",
            value: this.DataProssing_Draw_Info.PD_Pie.Doing,
          },
        ];
      } //一个都没完成不显示‘完成部分’，全完成了则不显示‘未完成部分’，啥都没有则不显示

      let Option = {
        title: {
          text:
            this.DataProssing_Draw_Info.PS_judg == "ProductOrder"
              ? "生产进度"
              : "出库进度",
          show: true,
          textStyle: {},
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          x: "left",
          y: "bottom",
        },
        series: [
          {
            type: "pie",
            radius: [0, "90%"],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: true,
                position: "inner",
                formatter: function (params) {
                  var temp = "";
                  temp = params.value;
                  return temp;
                },
                color: "#fff",
              },
              emphasis: {
                show: false,
              },
            },
            labelLine: {
              show: false,
            },
            data: ProgressData,
          },
        ],
        grid: {
          left: "2%",
          top: "25%",
          right: "5%",
          bottom: "10%",
          containLabel: true,
        },
      };
      this.Pie_PP_chart_Large = echarts.init(
        this.$refs.echart_Pie_ProductionProgress_Large
      );
      this.Pie_PP_chart_Large.setOption(Option);
      this.Pie_PP_chart_Large.resize();
    }, //生产/出库进度饼图（大）

    DrawBar_ProductionStatus_Large() {
      let typeName = [];
      let typeNumber = [];
      let typeDone = [];
      for (let i = 0; i < this.DataProssing_Draw_Info.PP_Pie.Type.length; i++) {
        typeNumber.push(
          this.DataProssing_Draw_Info.PP_Pie.Type[i].Number
        );
        typeDone.push(
          this.DataProssing_Draw_Info.PP_Pie.Type[i].Done
        );
        typeName.push(
          this.DataProssing_Draw_Info.PP_Pie.Type[i].Type,
        );
      }
      // console.log(typeName,typeNumber,typeDone);

      let Option = {
        title: {
          text: "详细进度",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          bottom: '5%',
          top: '15%',
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        xAxis: {
          type: 'category',
          data: typeName,
        },
        series: [
          {
            name: '总件数',
            type: 'bar',
            data: typeNumber,
          },
          {
            name: '完成数',
            type: 'bar',
            data: typeDone,
          }
        ]     
      };
      this.Bar_PS_chart_Large = echarts.init(
        this.$refs.echart_Bar_ProductionStatus_Large
      );
      this.Bar_PS_chart_Large.setOption(Option);
      this.Bar_PS_chart_Large.resize();
    }, //生产/出库生状况柱状图（大）

    DrawLine_QuantityAndDate_Large() {
      let StatesJudg = true;
      let TypeLength = this.DataProssing_Draw_Info.QD_Line.Type.length;
      let typeData = [];

      function timestampToTime(Time) {
        var timestamp = Time;
        var date = new Date(timestamp); // 参数为毫秒
        var Y = date.getFullYear() + "-";
        var M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
        var D = date.getDate();
        return Y + M + D;
      } //后端同款时间戳计算（年月日）函数

      function space(startTime, endTime) {
        var dateSpan, dates;
        startTime = Date.parse(startTime);
        endTime = Date.parse(endTime);
        dateSpan = endTime - startTime;
        dateSpan = Math.abs(dateSpan);
        dates = Math.floor(dateSpan / (24 * 3600 * 1000));
        return dates;
      } //计算日期间隔

      if (TypeLength != 0) {
        if (this.DataProssing_Draw_Info.PS_Status == "Done") {
          var startTime = this.DataProssing_Draw_Info.QD_Line.Type[0].Time;
          var endTime =
            this.DataProssing_Draw_Info.QD_Line.Type[TypeLength - 1].Time;
        } else if (this.DataProssing_Draw_Info.PS_Status == "Doing") {
          var startTime = this.DataProssing_Draw_Info.QD_Line.Type[0].Time;
          var endTime = this.GetTodayTimeStamp;
        } else if (this.DataProssing_Draw_Info.PS_Status == "NotPublished") {
          StatesJudg = false;
        } //正在进行和已结束的显示区分，未发布不画图
        
        let AllDateNumber = space(startTime, endTime) + 2;
        let StartTimeStamp = new Date(startTime).getTime();
        let HaveValue = false;
        let TimeFlag;
        for (let i = 0; i < AllDateNumber; i++) {
          TimeFlag = timestampToTime(StartTimeStamp + i * 24 * 3600 * 1000);
          for (let j = 0; j < TypeLength; j++) {
            if (this.DataProssing_Draw_Info.QD_Line.Type[j].Time == TimeFlag) {
              typeData.push([
                this.DataProssing_Draw_Info.QD_Line.Type[j].Time,
                this.DataProssing_Draw_Info.QD_Line.Type[j].value,
              ]);
              HaveValue = true;
            }
          }

          if (HaveValue) {
            HaveValue = false;
          } else {
            typeData.push([
              timestampToTime(StartTimeStamp + i * 24 * 3600 * 1000),
              0,
            ]);
          }
        } //先将所有元素赋0,然后再将有日期记录的数据赋值过去

        // let DataZoomSet = {
        //   type: 'slider', //缩放滑动条
        //   show: true, //开启
        //   // yAxisIndex: [0], //Y轴滑动
        //   xAxisIndex: [0],//X轴滑动
        //   start: 0, //初始化时，滑动条宽度开始标度
        //   end: 100, //初始化时，滑动条宽度结束标度
        //   filterMode: 'empty'
        // };

        if (StatesJudg) {
          let Option = {
            title: {
              text:
                this.DataProssing_Draw_Info.PS_judg == "ProductOrder"
                  ? "生产数量-日期折线图"
                  : "出库数量-日期折线图",
              show: true,
              textStyle: {},
            },

            tooltip: {
              trigger: "axis",
            },

            formatter: function (params) {
              var temp = "";
              temp =
                params[0].data[0] + "<br/>" + "生产数量：" + params[0].data[1];
              return temp;
            },

            toolbox: {
              show : true,
              itemSize: 20,
              itemGap : 10,
              feature : {
                dataZoom: {
                  yAxisIndex: 'none'
                },
                restore: {},
                saveAsImage: {},
              }
            },

            xAxis: {
              type: "time",
              name: 
                this.DataProssing_Draw_Info.PS_judg == "ProductOrder"
                  ? "生产日期"
                  : "出库日期",
              nameLocation: 'center',
              nameTextStyle:{
                lineHeight :40,
                verticalAlign :'top',
              },
              interval: 24 * 60 * 60 * 1000, // 固定x轴时间间隔 间隔24小时，也就是一天
              min: startTime, // 开始时间时间戳
              max: endTime,
              axisLabel: {
                show: true,
                showMinLabel: true,
                showMaxLabel: true,
              },
            },

            yAxis: {
              type: "value",
              name: 
                this.DataProssing_Draw_Info.PS_judg == "ProductOrder"
                  ? "生产数量"
                  : "出库数量",
              nameLocation: 'center',
              nameTextStyle:{
                lineHeight : 50,
                verticalAlign :'bottom',
              },
              axisLine: {
                show: true,
              },
            },

            // dataZoom: typeData.length >= 50 ? DataZoomSet : null ,
            
            series: [
              {
                name: "生产数量",
                type: "line",
                lineStyle: {
                  color: "#409EFF",
                },
                data: typeData,
              },
            ],

            grid: {
              left: "5%",
              top: "15%",
              right: "5%",
              bottom: "5%",
              containLabel: true,
            },
          };

          this.Line_chart_Large = echarts.init(
            this.$refs.echart_Line_QuantityAndDate_Large
          );
          this.Line_chart_Large.setOption(Option);
          this.Line_chart_Large.resize();
        }
      } 
    }, //生产/出库数量-日期折线图（大）



    show_Detailed() {
      this.showDetailed = true;
      this.$nextTick(() => {
        this.initCharts_dialog();
      });
    }, //查看大图
    Lock_Chart() {
      if (this.Draw_Lock) {
        this.Draw_Lock = false;
      } else {
        this.Draw_Lock = true;
      }
    }, //锁住图表不再切换
  },
};
</script>

<style scoped>
span {
  display: inline-block;
}

div >>> .el-card__body {
  padding: 0;
}

.app-container {
  position: relative;
  padding: 15px 15px 20px 20px;
}

.Line-Overview {
}

.Line-Orders {
}

.box-card-Overview {
  height: 180px;
}

.box-card-Overview-unit {
  height: 50%;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}

.box-card-Progress {
  height: 180px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.box-card-ProductOrder {
  height: 630px;
  position: relative;
}
.centerpagination {
  display: flex;
  justify-content: space-around;
}
.box-card-StorageOrder {
  height: 630px;
  position: relative;
}
.POSODisplay {
  float: right;
  margin-top: -5px;
}
.mypagination {
  position: absolute;
  bottom: 5px;
  width: calc(100% - 20px);
}

.display >>> .el-input__inner {
  color: #606266;
  background-color: white;
}
.centerinput >>> .el-input__inner {
  color: #606266;
  background-color: white;
  text-align: center;
}

#echart_Line_QuantityAndDate {
  width: 100%;
  height: 180px;
  z-index: 100;
}
#echart_Pie_ProductionProgress {
  width: 100%;
  height: 180px;
  z-index: 100;
}
#echart_Pie_ProductDistribution {
  width: 100%;
  height: 180px;
  z-index: 100;
}

#echart_Line_QuantityAndDate_Large {
  width: 1400px;
  height: 700px;
  z-index: 100;
}
#echart_Pie_ProductDistribution_Large {
  width: 100%;
  height: 700px;
  z-index: 100;
}
#echart_Pie_ProductionProgress_Large {
  width: 100%;
  height: 700px;
  z-index: 100;
}
#echart_Bar_ProductionStatus_Large {
  width: 100%;
  height: 700px;
  z-index: 100;
}

.ChartButton {
  margin: 10px 10px 0px 0px;
}
</style>