import request from '@/utils/request'

  export function getcarlist(params) {
    return request({
        url: '/Api/car/getcarlist',
        method: 'get',
        params
    })
  }
  export function updatecarinfo(data) {
    return request({
      url: '/Api/car/updatecarinfo',
      method: 'post',
      data
    })
  }