import { login, logout, getInfo ,loginByToken} from '@/api/user'
import { getToken, setToken, removeToken,setName,setCompanyCode,setVerifyToken,getVerifyToken } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    name: '',
    avatar: '',
    verifyToken:'',
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES:(state,roles)=>{
    state.roles = roles[0]
  },
  SET_Verifytaoken:(state,verifyToken)=>{
    state.verifyToken = verifyToken
  }
}
const actions = {
  // user login
  login({ commit }, codeinput) {
    const { code } = codeinput
    return new Promise((resolve, reject) => {
      login({ code: code }).then(response => {
        let data = response.data;
        commit('SET_NAME', data.name)
        commit('SET_TOKEN', data.token)
        commit('SET_ROLES', data.roles)
        commit('SET_Verifytaoken', data.Verifytaoken)
        setToken(data.token)
        setCompanyCode(data.companyCode)
        setName(data.name)
        setVerifyToken(data.Verifytaoken)
        resolve()
      }).catch(error => {
          // console.log(error)
        reject(error)
      })
    })
  },
  loginByToken({ commit }, token) {
    return new Promise((resolve, reject) => {
      loginByToken({ token: token }).then(response => {
        let data = response.data;
        commit('SET_NAME', data.name)
        commit('SET_TOKEN', data.token)
        commit('SET_ROLES', data.roles)
        commit('SET_Verifytaoken', data.Verifytaoken)
        setToken(data.token)
        setCompanyCode(data.companyCode)
        setName(data.name)
        setVerifyToken(data.Verifytaoken)
        resolve()
      }).catch(error => {
         // console.log(error)
        reject(error)
      })
    })
  },
  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        const { data } = response

        if (!data) {
          return reject('Verification failed, please Login again.')
        }

        const { name, avatar,roles } = data
        commit('SET_NAME', name)
        commit('SET_AVATAR', avatar)
        commit('SET_ROLES', roles)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        removeToken() // must remove  token  first
        resetRouter()
        commit('RESET_STATE')
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

