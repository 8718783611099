var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.isadmin()
        ? _c(
            "el-button",
            {
              staticStyle: { "margin-bottom": "20px" },
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.showInfo(null)
                },
              },
            },
            [_vm._v("新建")]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showComponentsInfo, title: "物料信息" },
          on: {
            "update:visible": function ($event) {
              _vm.showComponentsInfo = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.componentsInfo, "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订货号" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.componentsInfo.number,
                      callback: function ($$v) {
                        _vm.$set(_vm.componentsInfo, "number", $$v)
                      },
                      expression: "componentsInfo.number",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "物料型号" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.componentsInfo.parts,
                      callback: function ($$v) {
                        _vm.$set(_vm.componentsInfo, "parts", $$v)
                      },
                      expression: "componentsInfo.parts",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "物料名称" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.componentsInfo.Id,
                      callback: function ($$v) {
                        _vm.$set(_vm.componentsInfo, "Id", $$v)
                      },
                      expression: "componentsInfo.Id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "注释" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 2 },
                    model: {
                      value: _vm.componentsInfo.Comment,
                      callback: function ($$v) {
                        _vm.$set(_vm.componentsInfo, "Comment", $$v)
                      },
                      expression: "componentsInfo.Comment",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveInfo } },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.otherSaveInfo },
                },
                [_vm._v("另存为")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showPreblemInfo,
            title: "问题汇总",
            width: "85%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPreblemInfo = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.ProblemInfo, "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { staticClass: "formNum", attrs: { label: "订单号:" } },
                [_vm._v(" " + _vm._s(_vm.ProblemInfo.Number) + " ")]
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "formParts",
                  staticStyle: { "border-right": "1px" },
                  attrs: { label: "产品:" },
                },
                [_vm._v(" " + _vm._s(_vm.ProblemInfo.Name) + " ")]
              ),
              _c(
                "el-form-item",
                { staticClass: "formBtn" },
                [
                  _c(
                    "el-popover",
                    {
                      ref: "popover",
                      attrs: {
                        placement: "bottom",
                        title: "",
                        width: "300",
                        trigger: "click",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: {
                          "margin-bottom": "10px",
                          "margin-left": "10px",
                          display: "inline-block",
                          width: "250px",
                        },
                        attrs: { placeholder: "请输入问题" },
                        model: {
                          value: _vm.newProduce,
                          callback: function ($$v) {
                            _vm.newProduce = $$v
                          },
                          expression: "newProduce",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          staticClass: "autogetbutton",
                          attrs: { type: "primary", size: "small", round: "" },
                          on: { click: _vm.addProduce },
                        },
                        [_vm._v("确定")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "autogetbutton",
                          attrs: { type: "primary", size: "small", round: "" },
                          on: { click: _vm.cancelAdd },
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { slot: "reference", type: "primary" },
                          slot: "reference",
                        },
                        [_vm._v("增加问题")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.ProblemInfo.Problem, function (value, name) {
            return _c(
              "div",
              { key: name },
              [
                _c(
                  "div",
                  { staticStyle: { position: "relative" } },
                  [
                    _c(
                      "h4",
                      {
                        staticStyle: {
                          "margin-left": "10px",
                          display: "inline-block",
                          "margin-right": "20px",
                        },
                      },
                      [_vm._v(_vm._s(name))]
                    ),
                    _c("el-button", {
                      staticStyle: { "margin-right": "10px" },
                      attrs: {
                        type: "primary",
                        size: "small",
                        icon: "el-icon-plus",
                        circle: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addProblem(name)
                        },
                      },
                    }),
                    _c(
                      "el-popconfirm",
                      {
                        attrs: { title: "请确认删除" },
                        on: {
                          confirm: function ($event) {
                            return _vm.deleteProblem(name)
                          },
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            slot: "reference",
                            type: "danger",
                            icon: "el-icon-delete",
                            circle: "",
                            size: "small",
                          },
                          slot: "reference",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-card",
                  { staticClass: "classcard", attrs: { shadow: "hover" } },
                  _vm._l(value, function (item, indexs) {
                    return _c(
                      "div",
                      { key: indexs, attrs: { id: "cardclass" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入问题", clearable: "" },
                          model: {
                            value: value[indexs],
                            callback: function ($$v) {
                              _vm.$set(value, indexs, $$v)
                            },
                            expression: "value[indexs]",
                          },
                        }),
                        _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "请确认删除" },
                            on: {
                              confirm: function ($event) {
                                return _vm.deleteclass(name, indexs)
                              },
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "reference",
                                type: "danger",
                                icon: "el-icon-delete",
                                circle: "",
                                size: "small",
                              },
                              slot: "reference",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ],
              1
            )
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.hideOderInfo } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.updateList } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.$index +
                            (_vm.listQuery.page - 1) * _vm.listQuery.limit +
                            1
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "物料名称", align: "left" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.Id) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "物料型号", align: "left" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.parts) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "left", prop: "created_at", label: "订货号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.number))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "最新硬件版本", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.ShowHardwareHisotorDialog(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(scope.row.CurrentVersion))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "产品文档", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.showfile(scope.row)
                          },
                        },
                      },
                      [_vm._v("附件")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm.isadmin()
            ? _c("el-table-column", {
                attrs: { label: "问题", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.showProblem(scope.row)
                                },
                              },
                            },
                            [_vm._v("查看")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2552905084
                ),
              })
            : _vm._e(),
          _vm.isadmin()
            ? _c("el-table-column", {
                attrs: {
                  label: "可见供应商",
                  align: "center",
                  "min-width": "100",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-checkbox-group",
                            {
                              on: {
                                change: function ($event) {
                                  return _vm.VisiableSupplierChanged(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.VisiableSupplier,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "VisiableSupplier", $$v)
                                },
                                expression: " scope.row.VisiableSupplier",
                              },
                            },
                            _vm._l(_vm.companylist, function (item, index) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: item.id,
                                  attrs: { label: item.identifierStr },
                                },
                                [_vm._v(_vm._s(item.name))]
                              )
                            }),
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1839538195
                ),
              })
            : _vm._e(),
          _vm.isadmin()
            ? _c("el-table-column", {
                attrs: { align: "center", label: "操作", width: "200" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.showInfo(scope.row)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.showDeleteInfo(scope.row)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2121706990
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showDelete, title: "删除" },
          on: {
            "update:visible": function ($event) {
              _vm.showDelete = $event
            },
          },
        },
        [
          _c("h4", [_vm._v("确定删除")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.deleteInfo } },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "normal" },
                  on: {
                    click: function ($event) {
                      _vm.showDelete = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "硬件变更信息",
            visible: _vm.ShowHardwareHisotory,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.ShowHardwareHisotory = $event
            },
          },
        },
        [
          _c("h1", [_vm._v(_vm._s(_vm.componentsInfo.parts))]),
          _c(
            "div",
            { staticClass: "buttongroup" },
            [
              _c("el-button", {
                attrs: {
                  type: "primary",
                  icon: "el-icon-plus",
                  circle: "",
                  size: "mini",
                },
                on: { click: _vm.addhistrory },
              }),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.componentsInfo.historyrecord,
                "max-height": "600",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "版本", align: "center", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "version" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "版本号" },
                              model: {
                                value: scope.row.Version,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "Version", $$v)
                                },
                                expression: "scope.row.Version",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "发布日期", align: "center", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "time" },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "date",
                                size: "small",
                                placeholder: "选择日期时间",
                                "value-format": "timestamp",
                              },
                              model: {
                                value: scope.row.Date,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "Date", $$v)
                                },
                                expression: "scope.row.Date",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "起始序列号", align: "center", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "version" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "序列号" },
                              model: {
                                value: scope.row.serial,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "serial", $$v)
                                },
                                expression: "scope.row.serial",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "说明" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "version" },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                autosize: "",
                                placeholder: "说明",
                              },
                              model: {
                                value: scope.row.explanation,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "explanation", $$v)
                                },
                                expression: "scope.row.explanation",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.addhistrory(scope.$index)
                              },
                            },
                          },
                          [_vm._v(" 添加 ")]
                        ),
                        _vm._v(" --> "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.deletehistory(scope.$index)
                              },
                            },
                          },
                          [_vm._v(" 移除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "savebutton" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.savehardward } },
                [_vm._v("保存 ")]
              ),
            ],
            1
          ),
          _c("div", {
            staticStyle: { clear: "both", height: "0", overflow: "hidden" },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.componentsInfo.number, visible: _vm.Showannex },
          on: {
            "update:visible": function ($event) {
              _vm.Showannex = $event
            },
          },
        },
        [
          _c("p", [_vm._v("版本选择:")]),
          _c(
            "el-select",
            {
              staticStyle: { margin: "20px" },
              attrs: { placeholder: "选择版本" },
              on: { change: _vm.getFileList },
              model: {
                value: _vm.versionChoose,
                callback: function ($$v) {
                  _vm.versionChoose = $$v
                },
                expression: "versionChoose",
              },
            },
            _vm._l(_vm.componentsInfo.historyrecord, function (item) {
              return _c("el-option", {
                key: item.Version,
                attrs: { label: item.Version, value: item.Version },
              })
            }),
            1
          ),
          _c(
            "el-upload",
            {
              attrs: {
                action: "''",
                multiple: "",
                accept:
                  ".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PBG, .GIF, .pdf",
                "http-request": _vm.submitList,
                "file-list": _vm.actVersionAppendix,
                "show-file-list": true,
                "on-remove": _vm.handleRemove,
                "before-remove": _vm.beforeRemove,
                "on-preview": _vm.handlePreview,
              },
            },
            [
              _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                _vm._v("点击上传"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }