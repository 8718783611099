var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          staticStyle: { "margin-top": "10px", "margin-left": "10px" },
          attrs: { type: "primary" },
          on: { click: _vm.onTouchAdd },
        },
        [_vm._v("新建物料")]
      ),
      _c("input", {
        ref: "fileInput",
        staticStyle: { display: "none" },
        attrs: { type: "file" },
      }),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.hardwardList,
            "default-sort": { prop: "description", order: "descending" },
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: "物料名",
              align: "center",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "description",
              label: "描述",
              align: "center",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "Error数量", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.errorList.length) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.onTouchSelect(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-popconfirm",
                      {
                        attrs: { title: "这是一段内容确定删除吗？" },
                        on: {
                          confirm: function ($event) {
                            return _vm.onTouchDelete(scope.row)
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "20px" },
                            attrs: {
                              slot: "reference",
                              size: "mini",
                              type: "danger",
                            },
                            slot: "reference",
                          },
                          [_vm._v("删除")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑",
            visible: _vm.editDialogVisiable,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editDialogVisiable = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-position": "left", "label-width": "100px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "模块" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    model: {
                      value: _vm.selecthardward.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.selecthardward, "name", $$v)
                      },
                      expression: "selecthardward.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "描述" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    model: {
                      value: _vm.selecthardward.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.selecthardward, "description", $$v)
                      },
                      expression: "selecthardward.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标签选择" } },
                [
                  _vm._l(_vm.selecthardward.details, function (tag) {
                    return _c(
                      "el-tag",
                      {
                        key: tag,
                        attrs: { closable: "", "disable-transitions": false },
                        on: {
                          close: function ($event) {
                            return _vm.handleClose(tag)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(tag) + " ")]
                    )
                  }),
                  _vm.inputVisible
                    ? _c("el-input", {
                        ref: "saveTagInput",
                        staticClass: "input-new-tag",
                        attrs: { size: "small" },
                        on: { blur: _vm.handleInputConfirm },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleInputConfirm($event)
                          },
                        },
                        model: {
                          value: _vm.inputValue,
                          callback: function ($$v) {
                            _vm.inputValue = $$v
                          },
                          expression: "inputValue",
                        },
                      })
                    : _c(
                        "el-button",
                        {
                          staticClass: "button-new-tag",
                          attrs: { size: "small" },
                          on: { click: _vm.showInput },
                        },
                        [_vm._v(" + New Tag ")]
                      ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form",
            { attrs: { "label-position": "left", "label-width": "200px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "可见公司(不选择则全可见)" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      on: { change: _vm.CalcDepartmentList },
                      model: {
                        value: _vm.selecthardward.visiable,
                        callback: function ($$v) {
                          _vm.$set(_vm.selecthardward, "visiable", $$v)
                        },
                        expression: "selecthardward.visiable",
                      },
                    },
                    _vm._l(_vm.companylist, function (item) {
                      return _c(
                        "el-checkbox",
                        {
                          key: item.companyCode,
                          attrs: { label: item.companyCode },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-descriptions",
            { attrs: { title: "硬件信息", id: "hardwardinfo" } },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "设备图片" } },
                [
                  _c(
                    "el-card",
                    [
                      _c("el-image", {
                        staticStyle: { width: "100px", height: "100px" },
                        attrs: {
                          src:
                            "https://wx.sigmatek-oa.cn/product/" +
                            _vm.selecthardward.name +
                            ".svg?timestamp=" +
                            new Date().getTime(),
                          fit: "fit",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-left": "20px" } },
                    [
                      _c("el-button", { on: { click: _vm.updateImage } }, [
                        _vm._v("上传图片"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "产品文档" } },
                [
                  _c("el-card", [
                    _vm.selecthardward.HaveDocument
                      ? _c("iframe", {
                          attrs: {
                            src:
                              "https://wx.sigmatek-oa.cn/product/" +
                              _vm.selecthardward.name +
                              ".pdf",
                            width: "100%",
                            height: "500px",
                          },
                        })
                      : _c("div", [_vm._v("暂无文档")]),
                  ]),
                  _c(
                    "div",
                    { staticStyle: { "margin-left": "20px" } },
                    [
                      _c("el-button", { on: { click: _vm.updatepdf } }, [
                        _vm._v("上传文档"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.selecthardward.errorList },
            },
            [
              _c("el-table-column", {
                attrs: { label: "描述", align: "center", "min-width": "200px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          model: {
                            value: scope.row.descript,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "descript", $$v)
                            },
                            expression: "scope.row.descript",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "icon", align: "center", "min-width": "200px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            staticClass: "Iocnselecter",
                            attrs: { placeholder: "请选择" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prefix",
                                  fn: function () {
                                    return [
                                      _c("i", {
                                        class: "icon icon-" + scope.row.icon,
                                        staticStyle: {
                                          "font-size": "20px",
                                          color: "black",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: scope.row.icon,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "icon", $$v)
                              },
                              expression: "scope.row.icon",
                            },
                          },
                          _vm._l(_vm.iconList, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item,
                                attrs: {
                                  label: _vm.renderOption(item),
                                  value: item,
                                  "popper-class": "IconSelectPoper",
                                },
                              },
                              [_c("i", { class: "icon icon-" + item })]
                            )
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm.selecthardward.visiable &&
              _vm.selecthardward.visiable.length > 0
                ? _c("el-table-column", {
                    attrs: {
                      label: "可见部门",
                      align: "center",
                      "min-width": "200px",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: scope.row.visiableDepartment,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "visiableDepartment",
                                        $$v
                                      )
                                    },
                                    expression: "scope.row.visiableDepartment",
                                  },
                                },
                                _vm._l(_vm.DepartmentList, function (item) {
                                  return _c("el-checkbox", {
                                    attrs: { label: item },
                                  })
                                }),
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1497156114
                    ),
                  })
                : _vm._e(),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: "操作",
                    align: "center",
                    "min-width": "80px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteErrorList(scope.row)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.AddError },
                        },
                        [_vm._v(" 添加 ")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.editDialogVisiable = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.updateHardware },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }