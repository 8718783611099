var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "inline-block" } },
    [
      _c("label", { staticClass: "radio-label" }, [
        _vm._v("Cell Auto-Width: "),
      ]),
      _c(
        "el-radio-group",
        {
          model: {
            value: _vm.autoWidth,
            callback: function ($$v) {
              _vm.autoWidth = $$v
            },
            expression: "autoWidth",
          },
        },
        [
          _c("el-radio", { attrs: { label: true, border: "" } }, [
            _vm._v(" True "),
          ]),
          _c("el-radio", { attrs: { label: false, border: "" } }, [
            _vm._v(" False "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }