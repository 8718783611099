var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.ShowFormDialog,
            title: "填写",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.ShowFormDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "list",
              attrs: { model: _vm.FormDialog, "label-width": "100px" },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "序列号" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: true },
                            model: {
                              value: _vm.FormDialog.productInfo.SN,
                              callback: function ($$v) {
                                _vm.$set(_vm.FormDialog.productInfo, "SN", $$v)
                              },
                              expression: "FormDialog.productInfo.SN",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品名称" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: true },
                            model: {
                              value: _vm.FormDialog.productInfo.module,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.FormDialog.productInfo,
                                  "module",
                                  $$v
                                )
                              },
                              expression: "FormDialog.productInfo.module",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品订货号" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: true },
                            model: {
                              value: _vm.FormDialog.productInfo.articl,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.FormDialog.productInfo,
                                  "articl",
                                  $$v
                                )
                              },
                              expression: "FormDialog.productInfo.articl",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "故障信息" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 3 },
                      disabled: true,
                    },
                    model: {
                      value: _vm.FormDialog.failureDescription,
                      callback: function ($$v) {
                        _vm.$set(_vm.FormDialog, "failureDescription", $$v)
                      },
                      expression: "FormDialog.failureDescription",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "留言" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", autosize: "", disabled: true },
                    model: {
                      value:
                        _vm.FormDialog.SupplierRelated
                          .Sigmatek_MessageToSupplier,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.FormDialog.SupplierRelated,
                          "Sigmatek_MessageToSupplier",
                          $$v
                        )
                      },
                      expression:
                        "FormDialog.SupplierRelated.Sigmatek_MessageToSupplier",
                    },
                  }),
                ],
                1
              ),
              _c("el-divider"),
              _c(
                "el-form-item",
                { attrs: { label: "维修信息" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", autosize: { minRows: 3 } },
                    model: {
                      value: _vm.FormDialog.analysis,
                      callback: function ($$v) {
                        _vm.$set(_vm.FormDialog, "analysis", $$v)
                      },
                      expression: "FormDialog.analysis",
                    },
                  }),
                ],
                1
              ),
              _c(
                "table",
                {
                  staticStyle: { "margin-left": "30px", width: "96%" },
                  attrs: { border: "1", cellspacing: "0", id: "repair-list" },
                },
                [
                  _c("tr", [
                    _c("td", { staticStyle: { width: "5mm" } }, [
                      _vm._v("No."),
                    ]),
                    _c("td", { staticStyle: { width: "43mm" } }, [
                      _vm._v("物料名称 | Parts"),
                    ]),
                    _c("td", { staticStyle: { width: "28mm" } }, [
                      _vm._v("订货号 | Articl No."),
                    ]),
                    _c("td", { staticStyle: { width: "40mm" } }, [
                      _vm._v("故障物料序列号| Defect Part No."),
                    ]),
                    _c("td", { staticStyle: { width: "40mm" } }, [
                      _vm._v("新物料序列号 | New part No."),
                    ]),
                    _c("td", { staticStyle: { width: "14mm" } }, [
                      _vm._v("费用| Price"),
                    ]),
                  ]),
                  _vm._l(
                    _vm.FormDialog.componentsInfomation,
                    function (item, index) {
                      return _c("tr", { key: index }, [
                        _c(
                          "td",
                          {
                            staticClass: "delete",
                            on: {
                              click: function ($event) {
                                return _vm.remove(index)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(index + 1) + " ")]
                        ),
                        _c(
                          "td",
                          [
                            _c("el-input", {
                              staticClass: "edit-input",
                              attrs: {
                                type: "textarea",
                                autosize: "",
                                size: "small",
                              },
                              model: {
                                value: item.name,
                                callback: function ($$v) {
                                  _vm.$set(item, "name", $$v)
                                },
                                expression: "item.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("el-input", {
                              staticClass: "edit-input",
                              attrs: {
                                list: "componentsList",
                                type: "text",
                                size: "small",
                              },
                              model: {
                                value: item.number,
                                callback: function ($$v) {
                                  _vm.$set(item, "number", $$v)
                                },
                                expression: "item.number",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("el-input", {
                              staticClass: "edit-input",
                              attrs: { type: "text", size: "small" },
                              model: {
                                value: item.badNr,
                                callback: function ($$v) {
                                  _vm.$set(item, "badNr", $$v)
                                },
                                expression: "item.badNr",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("el-input", {
                              staticClass: "edit-input",
                              attrs: { type: "text", size: "small" },
                              model: {
                                value: item.newNr,
                                callback: function ($$v) {
                                  _vm.$set(item, "newNr", $$v)
                                },
                                expression: "item.newNr",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("el-input", {
                              staticClass: "edit-input input-right",
                              attrs: { type: "text", size: "small" },
                              on: {
                                blur: function ($event) {
                                  return _vm.caluete(item)
                                },
                              },
                              model: {
                                value: item.price_Supplier,
                                callback: function ($$v) {
                                  _vm.$set(item, "price_Supplier", $$v)
                                },
                                expression: "item.price_Supplier",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    }
                  ),
                  _c("tr", [
                    _c("td", {
                      staticClass: "create",
                      on: { click: _vm.creatNew },
                    }),
                    _c("td", { attrs: { colspan: "3" } }),
                    _c("td", [_vm._v("合计（含税）| Total Price(INC.VAT.)")]),
                    _c(
                      "td",
                      { staticClass: "text-right" },
                      [
                        _c("el-input", {
                          staticClass: "edit-input input-right",
                          attrs: { type: "text", size: "small" },
                          model: {
                            value: _vm.totalPrice,
                            callback: function ($$v) {
                              _vm.totalPrice = $$v
                            },
                            expression: "totalPrice",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { label: "顺丰单号" },
                },
                [
                  _c("el-input", {
                    model: {
                      value:
                        _vm.FormDialog.SupplierRelated.Supplier_TrackingNumber,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.FormDialog.SupplierRelated,
                          "Supplier_TrackingNumber",
                          $$v
                        )
                      },
                      expression:
                        "FormDialog.SupplierRelated.Supplier_TrackingNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "flex-end",
                  },
                },
                [
                  !_vm.FormDialog.SupplierRelated.Supplier_Complete
                    ? _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.ConfirmCompletion(
                                _vm.FormDialog.SupplierRelated.Supplier_Complete
                              )
                            },
                          },
                        },
                        [_vm._v(" 确认完成 ")]
                      )
                    : _vm._e(),
                  _vm.FormDialog.SupplierRelated.Supplier_Complete
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.ConfirmCompletion(
                                _vm.FormDialog.SupplierRelated.Supplier_Complete
                              )
                            },
                          },
                        },
                        [_vm._v(" 已完成 ")]
                      )
                    : _vm._e(),
                  _c("el-button", { on: { click: _vm.updateForm } }, [
                    _vm._v("保存"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "myTable",
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
          on: {
            ready: _vm.DateLabelRefresh,
            "after-geature-processed": _vm.DateLabelRefresh,
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", prop: "ID", label: "ID", width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.$index +
                            (_vm.listQuery.page - 1) * _vm.listQuery.limit +
                            1
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "created_at",
              label: "返修日期",
              width: "160",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.productInfo.return))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "SN", prop: "SN", width: "110", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.productInfo.SN) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Module",
              prop: "Module",
              width: "110",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.productInfo.module) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Failure", prop: "Failure", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.failureDescription) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "状态",
              prop: "State",
              width: "110",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.InfoTransformation(
                            scope.row.SupplierRelated.Supplier_Complete
                          )
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "operate",
              label: "操作",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path: "form_Supplier",
                                query: {
                                  form_infoid: scope.row._id,
                                  filters: _vm.listQuery,
                                },
                              },
                            },
                          },
                          [
                            _vm.EditColorChange(scope.row) === "normal"
                              ? _c("el-button", { attrs: { plain: "" } }, [
                                  _vm._v(" 编辑 "),
                                ])
                              : _vm._e(),
                            _vm.EditColorChange(scope.row) === "primary"
                              ? _c(
                                  "el-button",
                                  { attrs: { type: "primary", plain: "" } },
                                  [_vm._v(" 编辑 ")]
                                )
                              : _vm._e(),
                            _vm.EditColorChange(scope.row) === "danger"
                              ? _c(
                                  "el-button",
                                  { attrs: { type: "danger", plain: "" } },
                                  [_vm._v(" 编辑 ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }