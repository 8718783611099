<template>
    <div>
        <el-card class="mycard">
            <el-button type="primary" @click="onCreativeCompany">
                新建公司
            </el-button>
        </el-card>
        <el-card class="mycard cloumn">
            <div>
                <el-tabs v-model="activeName" type="card">
                    <el-tab-pane label="公司配置" name="company"></el-tab-pane>
                    <el-tab-pane label="人员管理" name="person"></el-tab-pane>
                </el-tabs>
            </div>
            <div id="companySetting" v-show="activeName == 'company'">
                <el-table :data="companyList" style="width: 100%">
                    <el-table-column prop="name" label="公司名称" min-width="180" align="center">
                    </el-table-column>
                    <el-table-column prop="companyCode" label="认证字符" min-width="180" align="center">
                    </el-table-column>
                    <el-table-column label="部门分类" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.DepartmentList }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button type="primary" @click="OnAddDepartment(scope.row)" size="mini">添加部门</el-button>
                            <!-- <el-button @click="OnDeleteCompany(scope.row)">删除</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div id="personSetting" v-show="activeName == 'person'">
                <el-table :data="UserList" style="width: 100%">
                    <el-table-column label="姓名" min-width="180" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.Name ? scope.row.Name : scope.row.RegInfo.Name }}</span>
                            <el-tag v-if="!scope.row.Name" style="margin-left:20px">申请中</el-tag>
                            <el-tag v-if="scope.row.isAdmin" style="margin-left:20px" type="success">admin</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="公司" min-width="180" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.Name ? scope.row.companyCode : scope.row.RegInfo.companyCode }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="部门" min-width="180" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.Name ? scope.row.Department : scope.row.RegInfo.Department }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="电话" min-width="180" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.Name ? scope.row.Phone : scope.row.RegInfo.Phone }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="180" align="center">
                        <template slot-scope="scope">
                            <div v-if="!scope.row.Name">
                                <el-button type="primary" size="mini"
                                    @click="OnConfirmReg(scope.row, 'true')">同意</el-button>
                                <el-button type="danger" size="mini"
                                    @click="OnConfirmReg(scope.row, 'false')">拒绝</el-button>
                            </div>
                            <div v-if="scope.row.Name">
                                <el-button type="primary" size="mini" @click="ChangeDepartment(scope.row)">部门迁移</el-button>
                                <el-button type="danger" size="mini" @click="DeleteAUser(scope.row)">删除</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
        <el-dialog :visible.sync="addCompanyDialogVisible" title="添加客户">
            <el-form :inline="true" :model="NewCompany" class="demo-form-inline">
                <el-form-item label="名称">
                    <el-input v-model="NewCompany.name" placeholder="输入名称"></el-input>
                </el-form-item>
                <el-form-item label="认证字符">
                    <el-input v-model="NewCompany.companyCode" placeholder="输入认证字符"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addCompanyDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="OnConfirmAddCompany">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="部门迁移" :visible.sync="DepartmentChangeVisiable" width="30%">
            <h4>选择部门</h4>
            <el-select v-model="SelectUser.Department" placeholder="请选择">
                <el-option v-for="item in selectCompany.DepartmentList" :key="item" :label="item" :value="item">
                </el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button @click="DepartmentChangeVisiable = false">取 消</el-button>
                <el-button type="primary" @click="ComfirmUpdateDepartment">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getWxCompanys, updateDepartment, createWxCompany, GetAllUser, UpdateRegUser, DeleteUser,UpdateDepartment } from "@/api/WxIssue";
export default {
    data() {
        return {
            DepartmentChangeVisiable: false,
            activeName: "company",
            companyList: [],
            companyEditDialog: false,
            selectCompany: {},
            addCompanyDialogVisible: false,
            NewCompany: {
                name: "",
                companyCode: "",
                DepartmentList: []
            },
            UserList: [],
            UserFilter: {
                filter: {
                    $or: [
                        { companyCode: { $exists: true, $ne: "" } },
                        { 'RegInfo.companyCode': { $exists: true, $ne: "" } }
                    ]
                },
                pageSize: 100,//TODO:用户较少，分页内容先不做
                page: 1
            },
            SelectUser:{},
            selectCompany:{},
        }
    },
    methods: {
        ChangeDepartment(input){
            this.SelectUser = JSON.parse(JSON.stringify(input));
            for(let item of this.companyList){
                if(item.companyCode == this.SelectUser.companyCode){
                    this.selectCompany = item;
                    break;
                }
            }
            this.DepartmentChangeVisiable = true;
        },
        findDuplicates(arr) {
            return arr.filter((item, index) => arr.indexOf(item) !== index);
        },
        OnAddDepartment(input) {
            this.selectCompany = JSON.parse(JSON.stringify(input));
            this.$prompt('请输入新的部门', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                if (this.selectCompany.DepartmentList.indexOf(value) != -1) {
                    this.$message({
                        type: 'error',
                        message: "有重复项"
                    });
                    return;
                }
                this.selectCompany.DepartmentList.push(value);
                updateDepartment(this.selectCompany).then(res => {
                    this.$message({
                        type: 'success',
                        message: "更新成功"
                    });
                }).catch(err => {
                    this.$message({
                        type: 'error',
                        message: "更新失败"
                    });
                }).finally(() => {
                    this.GetCompanyData();
                })
            })
        },
        GetCompanyData() {
            getWxCompanys().then(res => {
                let { data } = res;
                this.companyList = data;
            })
        },
        onCreativeCompany() {
            this.addCompanyDialogVisible = true;
            this.NewCompany = {
                name: "",
                companyCode: "",
                DepartmentList: []
            }
        },
        OnConfirmAddCompany() {
            if (!this.NewCompany.name || !this.NewCompany.companyCode) {
                this.$message({
                    type: 'error',
                    message: "数据有错误"
                });
                return;
            }
            createWxCompany(this.NewCompany).then(res => {
                this.$message({
                    type: 'success',
                    message: "创建成功"
                });
            }).catch(err => {
                this.$message({
                    type: 'error',
                    message: "创建失败"
                });
            }).finally(() => {
                this.GetCompanyData();
                this.addCompanyDialogVisible = false;
            })
        },
        GetUserData() {
            GetAllUser(this.UserFilter).then(res => {
                this.UserList = res.data;
            })
        },
        OnConfirmReg(input, accept) {
            let t = JSON.parse(JSON.stringify(input))
            t.accept = accept;
            let s = '拒绝'
            if (accept == 'true') {
                s = '接受'
            }
            this.$confirm(`是否确认${s}该申请`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                UpdateRegUser(t).then(res => {
                    this.$message({
                        type: 'success',
                        message: "更新成功"
                    });
                }).catch(err => {
                    this.$message({
                        type: 'error',
                        message: "更新失败"
                    });
                }).finally(() => {
                    this.GetUserData();
                })
            })

        },
        DeleteAUser(input) {
            let { openid } = input;
            this.$confirm(`是否确认删除`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                DeleteUser({ openid: openid }).then(res => {
                    this.$message({
                        type: 'success',
                        message: "删除成功"
                    });
                }).catch(err => {
                    this.$message({
                        type: 'error',
                        message: "删除失败"
                    });
                }).finally(() => {
                    this.GetUserData();
                })
            })

        },
        ComfirmUpdateDepartment(){
            UpdateDepartment({
                openid:this.SelectUser.openid,
                Department:this.SelectUser.Department
            }).then(res=>{
                this.$message({
                        type: 'success',
                        message: "更新成功"
                    });
            }).catch(err=>{
                this.$message({
                        type: 'error',
                        message: "更新失败"
                    });
            }).finally(()=>{
                this.GetUserData();
                this.DepartmentChangeVisiable = false;
            })
        }

    },
    created() {
        this.GetCompanyData();
        this.GetUserData();
    }

}
</script>

<style>
.mycard {
    margin: 20px;
}

.mycard .el-card__body {
    display: flex;
}

.cloumn .el-card__body {
    flex-direction: column;
}
</style>