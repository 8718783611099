var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { attrs: { id: "buttongroup" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.CreatePcn } },
            [_vm._v("创建Pcn")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        { staticStyle: { width: "100%" }, attrs: { data: _vm.pcnlist } },
        [
          _c("el-table-column", {
            attrs: { label: "产品", width: "180", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.getListName(scope.row.products)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "version",
              label: "版本",
              width: "180",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "预计生效时间", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(new Date(scope.row.planTime).toLocaleString()) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "creater", label: "创建人", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "创建时间", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          new Date(scope.row.CreationTime).toLocaleString()
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.watchinfo(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(scope.row.lock == 1 ? "查看" : "编辑"))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "danger",
                          disabled: scope.row.lock == 1,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.generatePdf(scope.row)
                          },
                        },
                      },
                      [_vm._v("生成PDF")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", disabled: scope.row.lock == 1 },
                        on: {
                          click: function ($event) {
                            return _vm.lock(scope.row)
                          },
                        },
                      },
                      [_vm._v("锁定")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: { visible: _vm.editvisiable, width: "50%" },
              on: {
                "update:visible": function ($event) {
                  _vm.editvisiable = $event
                },
              },
            },
            [
              _c(
                "el-descriptions",
                { attrs: { title: "物料变更通知书", border: "", column: 1 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "版本号" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.selectpcn.products,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectpcn, "products", $$v)
                            },
                            expression: "selectpcn.products",
                          },
                        },
                        _vm._l(_vm.allproducts, function (item) {
                          return _c("el-checkbox", {
                            key: item.id,
                            attrs: {
                              border: "",
                              label: item.name,
                              disabled: _vm.selectpcn.lock,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { border: "", column: 2 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "版本号（变更后）" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.selectpcn.lock },
                        model: {
                          value: _vm.selectpcn.version,
                          callback: function ($$v) {
                            _vm.$set(_vm.selectpcn, "version", $$v)
                          },
                          expression: "selectpcn.version",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "计划生效时间：" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "month",
                          "value-format": "timestamp",
                          placeholder: "选择日期",
                          disabled: _vm.selectpcn.lock,
                        },
                        model: {
                          value: _vm.selectpcn.planTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.selectpcn, "planTime", $$v)
                          },
                          expression: "selectpcn.planTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { attrs: { border: "", column: 1 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "变更说明" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 1 },
                          disabled: _vm.selectpcn.lock,
                        },
                        model: {
                          value: _vm.selectpcn.ChangeReason,
                          callback: function ($$v) {
                            _vm.$set(_vm.selectpcn, "ChangeReason", $$v)
                          },
                          expression: "selectpcn.ChangeReason",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(_vm.selectpcn.ChangeInner, function (item) {
                return _c(
                  "el-descriptions",
                  { attrs: { border: "", column: 2 } },
                  [
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "修改前" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入描述",
                            disabled: _vm.selectpcn.lock == 1,
                          },
                          model: {
                            value: item.before.describe,
                            callback: function ($$v) {
                              _vm.$set(item.before, "describe", $$v)
                            },
                            expression: "item.before.describe",
                          },
                        }),
                        !item.before.picpath
                          ? _c(
                              "div",
                              { staticClass: "center" },
                              [
                                !_vm.selectpcn.lock
                                  ? _c(
                                      "el-upload",
                                      {
                                        staticClass: "upload-demo",
                                        attrs: {
                                          drag: "",
                                          multiple: "",
                                          action: "",
                                          "http-request": _vm.submitPic,
                                          data: item.before,
                                          accept:
                                            ".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PBG, .GIF, .pdf",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-upload",
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "el-upload__text" },
                                          [
                                            _vm._v(" 将文件拖到此处，或"),
                                            _c("em", [_vm._v("点击上传")]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "el-upload__tip",
                                            attrs: { slot: "tip" },
                                            slot: "tip",
                                          },
                                          [
                                            _vm._v(
                                              " 只能上传jpg/png文件，且不超过500kb "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c("div", { staticClass: "center" }, [
                              _c("img", {
                                staticStyle: { "max-width": "200px" },
                                attrs: {
                                  src: _vm.PicObj[
                                    item.before.picpath.split("/")[
                                      item.before.picpath.split("/").length - 1
                                    ]
                                  ],
                                },
                              }),
                              !_vm.selectpcn.lock
                                ? _c(
                                    "div",
                                    { staticStyle: { "margin-top": "5px" } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleimage(item.before)
                                            },
                                          },
                                        },
                                        [_vm._v("删除图片")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.delerow(item)
                                            },
                                          },
                                        },
                                        [_vm._v("删除此行")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                      ],
                      1
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "修改后" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入描述",
                            disabled: _vm.selectpcn.lock == 1,
                          },
                          model: {
                            value: item.after.describe,
                            callback: function ($$v) {
                              _vm.$set(item.after, "describe", $$v)
                            },
                            expression: "item.after.describe",
                          },
                        }),
                        !item.after.picpath
                          ? _c(
                              "div",
                              { staticClass: "center" },
                              [
                                !_vm.selectpcn.lock
                                  ? _c(
                                      "el-upload",
                                      {
                                        staticClass: "upload-demo",
                                        attrs: {
                                          drag: "",
                                          multiple: "",
                                          action: "''",
                                          "http-request": _vm.submitPic,
                                          data: item.after,
                                          accept:
                                            ".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PBG, .GIF, .pdf",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-upload",
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "el-upload__text" },
                                          [
                                            _vm._v(" 将文件拖到此处，或"),
                                            _c("em", [_vm._v("点击上传")]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "el-upload__tip",
                                            attrs: { slot: "tip" },
                                            slot: "tip",
                                          },
                                          [
                                            _vm._v(
                                              " 只能上传jpg/png文件，且不超过500kb "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c("div", { staticClass: "center" }, [
                              _c("img", {
                                staticStyle: { "max-width": "200px" },
                                attrs: {
                                  src: _vm.PicObj[
                                    item.after.picpath.split("/")[
                                      item.after.picpath.split("/").length - 1
                                    ]
                                  ],
                                },
                              }),
                              !_vm.selectpcn.lock
                                ? _c(
                                    "div",
                                    { staticStyle: { "margin-top": "5px" } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleimage(item.after)
                                            },
                                          },
                                        },
                                        [_vm._v("删除图片")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.delerow(item)
                                            },
                                          },
                                        },
                                        [_vm._v("删除此行")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              !_vm.selectpcn.lock
                ? _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c("el-button", { on: { click: _vm.addChangeInner } }, [
                        _vm._v("添加变更说明"),
                      ]),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.editvisiable = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.updatePcn },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  )
                : _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.editvisiable = false
                            },
                          },
                        },
                        [_vm._v("返回")]
                      ),
                    ],
                    1
                  ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }