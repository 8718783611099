<template>
  <div class="OutBox">
    <div class="HeadButtonArea">
      <el-button id="print" v-print="printObj" v-show="false"></el-button>
      <el-button @click="beforePrint">打印</el-button>
      <el-button @click="save">保存</el-button>
      <el-popover placement="top" width="160" v-model="DeleteConfirmVisiable">
        <p>确认删除？</p>
        <div style="text-align: right; margin: 0">
          <el-button
            size="mini"
            type="text"
            @click="DeleteConfirmVisiable = false"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="(DeleteConfirmVisiable = false), deliteunit()"
            >确定</el-button
          >
        </div>
        <el-button slot="reference">删除</el-button>
        
      </el-popover>
      <router-link
      :to="{
        path: 'shippingList',
      }"
    >
      <el-button style="margin-left:10px" type="button">返回</el-button>
    </router-link>
    </div>
    <div id="printArea">
      <div class="Tittle">
        <h2>发货清单<span class="italic">Delivery Note</span></h2>
        <img src="../../../Img/log.jpg" />
      </div>
      <div class="SplitLine"></div>
      <div class="Body">
        <div class="BodyHead">
          <div class="Unit">
            <div class="Word"><span>单号</span><span>Note Nr.</span></div>
            <div class="NumberInpunt1 HeadNumberInpunt" style="width: 150px">
              <el-input v-model="HeadInof.NoteNr"></el-input>
            </div>
          </div>
          <div class="Unit">
            <div class="Word">
              <span>快递单号</span><span>Express Nr.</span>
            </div>
            <div class="NumberInpunt1 HeadNumberInpunt" style="width: 110px">
              <el-input v-model="HeadInof.ExpressNr"></el-input>
            </div>
          </div>
          <div class="Unit">
            <div class="Word"><span>日期</span><span>Date</span></div>
            <div class="NumberInpunt1 HeadNumberInpunt" style="width: 100px">
              <el-input v-model="HeadInof.DateNr"></el-input>
            </div>
          </div>
        </div>
        <div class="Company" style="width:62%">
          <div class="CompanyTittle">
            <p>采购商:</p>
            {{ PurchAserInfo.Name ? PurchAserInfo.Name : "等待选择" }}
          </div>
          <div class="Info1" >
            <p style="width: 100%; text-align: center">
              {{ PurchAserInfo.Address ? PurchAserInfo.Address : "收货地址" }}
            </p>
          </div>
          <div class="Info1">
            <p style="width: 45%; text-align: center; margin-right: 5%">
              {{ PurchAserInfo.People ? PurchAserInfo.People : "收货人" }}
            </p>
            <p style="width: 50%">T:{{ PurchAserInfo.Telephone }}</p>
          </div>
        </div>
        <div class="Company" style="width:38%">
          <div class="CompanyTittle">
            <p>供应商:</p>
            {{ SupplierInfo.Name }}
          </div>
          <div class="Info1">
            <p style="width: 100%; text-align: center">
              {{ SupplierInfo.Address }}
            </p>
          </div>
          <div class="Info1">
            <p style="width: 45%; margin-right: 5%">
              T:{{ SupplierInfo.Telephone }}
            </p>
            <p style="width: 50%">F:{{ SupplierInfo.F }}</p>
          </div>
        </div>
        <div class="DeliveryInfo">
          <div>
            <p class="InfoTittle">发货信息 DeliveryInfo</p>
            <span class="addbutton" ref="noprint"
              ><el-button
                icon="el-icon-plus"
                circle
                @click="addLine"
              ></el-button
              ><el-button
                icon="el-icon-close"
                circle
                @click="DeleteLine"
              ></el-button
            ></span>
          </div>
          <el-table
            :data="DeliveryInfolist"
            border
            highlight-current-row
            ref="DelieveryTable"
            id="DelieveryTable"
            v-loading="loading"
            :span-method="MergeCells"
            :header-row-style="{
              color: '#000',
              'font-size': '13px',
              'font-weight': 400,
            }"
            cell-class-name="maintable"
            :cell-style="{ 'font-size': '10px', color: '#000' ,'padding':'1px 0'}"
            @current-change="TableSelectedItemChange"
          >
            <!-- style="max-width: 20.8cm" -->
            <el-table-column
              prop="Pos"
              align="center"
              label="Pos"
              min-width="45px"
            ></el-table-column>
            <el-table-column
              prop="Module"
              :label="'模块 \n Moodule'"
              align="center"
              min-width="85px"
            ></el-table-column>
            <el-table-column
              prop="ArticleNr"
              :label="'订货号 \n ArticleNr'"
              align="center"
              min-width="90px"
            ></el-table-column>
            <el-table-column
              prop="CustomId"
              :label="'物料号 \n CustomId'"
              align="center"
              min-width="90px"
            ></el-table-column>
            <el-table-column
              prop="SerialNr"
              :label="'序列号 \n SerialNr'"
              align="center"
              min-width="85px"
            >
              <template slot-scope="scope">
                <span class="SerinalInput"
                  ><el-input
                    type="text"
                    v-model="scope.row.SerialNr"
                    @change="SerialNrChange(scope.row.SerialNr, scope.$index)"
                /></span>
              </template>
            </el-table-column>
            <el-table-column
              prop="reSerialNr"
              :label="'新序列号 \n reSerial'"
              align="center"
              min-width="85px"
            />
            <el-table-column
              prop="PartNr"
              :label="'零件号 \n PartNr'"
              align="center"
              min-width="85px"
            ></el-table-column>
            <el-table-column
              prop="reportNr"
              :label="'退货号 \n reportNr'"
              align="center"
              min-width="85px"
            ></el-table-column>
            <el-table-column
              prop="Notes"
              :label="'备注 \n Notes'"
              align="center"
              min-width="95px"
            >
              <template slot-scope="scope">
                <span class="SerinalInput"
                  ><el-input
                    type="textarea"
                    :autosize="{ minRows: 1 }"
                    v-model="scope.row.Notes"
                /></span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="Remark" >
          <p class="InfoTittle">备注 Remark</p>
          <div class="RemarkBody" id="Remark">
            兹有我公司快递于{{RemarkTime}},<!--

              -->快递模块共<el-input class="remarkinput" v-model="RemarkComponent" />块(维修返回品),为确保我公司货物安全及时到达贵公司,请收到货物即日五日内将此函签回传我司,确认您已收到。如五日内<!-- 
              
             -->我公司未收到贵司的回传传真,会向快递公司查询签收人并自动确认已经收到。五日后再告知我公司未收到，我公司将不负任何责任，请贵司自行解决。特此告知！请予配合，谢谢！
          </div>
        </div>
        
      </div>
      <div class="bottomInfo">
        <div class="relative">
          <div class="area_right">
            <span class="title-right">客户签章 Signature: </span>
            <div class="writeInput">
              <el-input type="text" class="bottom-input" size="small" />
            </div>
          </div>
        </div>
        
      </div>
      <div class="bottomInfo">
       
        <div class="relative">
          
          <div class="area_right top">
            <span class="title-right">客户签章日期 Date: </span>
            <div class="writeInput">
              <el-input type="text" class="bottom-input" size="small" />
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Loading } from "element-ui";
import { getCustomerList } from "@/api/article";
import { update, getshippinglist, deleteShipping } from "@/api/shipping";
import { getList } from "@/api/table";
export default {
  name: "shippingUnit",
  data() {
    return {
      printObj: {
        id: "printArea",
        beforeOpenCallback(vue) {
          vue.loadingInstance.close();
        },
        closeCallback(vue) {
          vue.$refs.noprint.style.visibility = "visible";
          let table = document.getElementById("DelieveryTable");
          table.style.width = "100%";
          let Remark = document.getElementById("Remark");
          Remark.style.width = "100%";
        },
      },
      DeliveryInfolist: [
        //快递信息
        {
          Pos: 1, //序号
          Module: null, //模块
          ArticleNr: null, //订货号
          CustomId: null, //	订单货
          Quantity: null, //数量
          SerialNr: null, //序列号
          reSerialNr: null, //返回序列号
          PartNr: null, //零件编号
          reportNr: null, //退货号
          Notes: null, //备注
        },
      ],
      HeadInof: {
        //头部信息
        NoteNr: null,
        ExpressNr: null,
        DateNr: null,
      },
      PurchAserInfo: {
        //客户信息
        Name: null,
        People: null,
        Address: null,
        Telephone: null,
      },
      SupplierInfo: {
        Name: "宁波西格玛泰克自动化设备有限公司",
        Address: "宁波市江东区惊驾路555号A栋805室",
        Telephone: "+8657487753085",
        F: "+8657487753065",
        PostCode: "315040",
        People: "顾瑞祥",
        Country: "中国CN",
      },
      SelectRow: 0,

      customerInfo: [{
        company: null,
        fax: null,
        add: null,
        WarrantyDays: null,
        OrderNumberAbbr: null,
        contacts:[{
          Name: null,
          Tel: null,
          SeparateAddress: null,
          customers: false,
        }]
      }],

      customerInfo_Old: [
        {
          company: null,
          contactor: null,
          tel: null,
          fax: null,
          add: null,
          WarrantyDays: null,
          OrderNumberAbbr: null,
        },
      ],

      componentsInfo: [
        {
          parts: null,
          number: null,
          badNr: null,
          newNr: null,
          price: null,
        },
      ],
      _id: null,
      loading: false,
      DeleteConfirmVisiable: false,
      Remark:null,
      RemarkTime:null,
      RemarkComponent:"壹",
      replaceArr : ["壹","贰","叁","肆","伍","陆","柒","捌","玖","拾",
                    "拾壹","拾贰","拾叁","拾肆","拾伍","拾陆","拾柒","拾捌","拾玖","贰拾",
                    "贰拾壹","贰拾贰","贰拾叁","贰拾肆","贰拾伍","贰拾陆","贰拾柒","贰拾捌","贰拾玖","叁拾",
                    "叁拾壹","叁拾贰","叁拾叁","叁拾肆","叁拾伍","叁拾陆","叁拾柒","叁拾捌","叁拾玖","肆拾",
                    "肆拾壹","肆拾贰","肆拾叁","肆拾肆","肆拾伍","肆拾陆","肆拾柒","肆拾捌","肆拾玖","伍拾",
                    ]
    };
  },
  created() {
    if (this.$route.params.shippingObj) {
      this.DeliveryInfolist = this.$route.params.shippingObj.DeliveryInfolist;
      this.HeadInof = this.$route.params.shippingObj.HeadInof;
      this.PurchAserInfo = this.$route.params.shippingObj.PurchAserInfo;
      this._id = this.$route.params.shippingObj._id;
    }
    this.HeadInof.DateNr = this.HeadInof.DateNr
      ? this.HeadInof.DateNr
      : new Date().toLocaleDateString("cn", { hour12: false });
    this.getCustomerInfo();
  },
  watch: {
    "PurchAserInfo.Name": {
      async handler(newvalue, oldvalue) {
        if(this.HeadInof.NoteNr != undefined)
        {
          return
        }
        let str =
          this.HeadInof.DateNr.substring(
            0,
            this.HeadInof.DateNr.lastIndexOf("/")
          ) + "/.*";
        let filters = "({'HeadInof.DateNr':{$regex:'" + str + "'}})";
        const { data } = await getshippinglist({
          page: 1,
          limit: 200,
          filters: filters,
        });
        let len = this.pad(data.total + 1, 3);
        var date = new Date();
        let year = date.getFullYear() % 100;
        let mon = this.pad(date.getMonth() + 1, 2);

        this.customerInfo.forEach((customer) => {
          customer.contacts.forEach((contact) => {
            // 创建新的对象
            const newCustomer = {
              company: customer.company,
              contactor: contact.Name,
              tel: contact.Tel,
              fax: customer.fax,
              add: contact.SeparateAddress,
              WarrantyDays: customer.WarrantyDays,
              OrderNumberAbbr: customer.OrderNumberAbbr,
            };
            // 将新的对象添加到新的数据结构中
            this.customerInfo_Old.push(newCustomer);
          });
        });

        this.customerInfo_Old.forEach((item) => {
          if (item.company == newvalue & item.contactor == this.PurchAserInfo.People) {
            // oredernum =
           // console.log(item)
            this.HeadInof.NoteNr =
              "D" + year + "." + mon + "." + len + "-" + item.OrderNumberAbbr;
          }
        });
      },
    },
    "DeliveryInfolist.length":{
      handler(newvalue,oldvalue){
        this.RemarkComponent = this.replaceArr[newvalue-1]
      }
    },
    "HeadInof.DateNr":{
      handler(newvalue){
        let arr = newvalue.split('/');
        this.RemarkTime = arr[0]+"年"+arr[1]+"月"+arr[2]+'日'
      }
    }
  },
  methods: {
    addLine() {
      this.DeliveryInfolist.push({
        Pos: this.DeliveryInfolist.length + 1,
        Module: null,
        ArticleNr: null,
        CustomId: null,
        Quantity: null,
        SerialNr: null,
        PartNr: null,
        Notes: "",
      });
    },
    DeleteLine() {
      if (this.SelectRow == 0) return;
      this.DeliveryInfolist.splice(this.SelectRow - 1, 1);
      this.Resort();
    },
    Resort() {
      this.DeliveryInfolist.sort(this.SortFun);
      //POS重新给值
      for (var i = 0; i < this.DeliveryInfolist.length; i++) {
        this.DeliveryInfolist[i].Pos = i + 1;
      }
      //清除选中行
      this.SelectRow = 0;
      this.$refs.DelieveryTable.setCurrentRow();
    },
    SortFun(a, b) {
      if ("reportNr" in a && "reportNr" in b) {
        if (a.reportNr != b.reportNr) return a.reportNr < b.reportNr ? 1 : -1;
        else if (a.Module != b.Module) return a.Module < b.Module ? 1 : -1;
      } else {
        if ("reportNr" in a) return 1;
        else if (a.Module != b.Module) return a.Module < b.Module ? 1 : -1;
      }
    },
    async SerialNrChange(newvalue, index) {
      newvalue = newvalue.replaceAll(" ", "");
      this.loading = true;
      let listQuery = {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
        filters: "({'productInfo.SN':{$regex:'" + newvalue + "'}})",
      };
      const { data } = await getList(listQuery);
      let result = data.list[0];
     // console.log(result)
      if (result == undefined) {
        this.loading = false;
        this.$message({
          showClose: true,
          message: "查询不到序列号",
          type: "error",
        });
        return;
      }
      //自动填充
      this.DeliveryInfolist[index].Module = result.productInfo.module;
      this.DeliveryInfolist[index].ArticleNr = result.productInfo.articl;
      this.DeliveryInfolist[index].CustomId = result.productInfo.parts;
      this.DeliveryInfolist[index].PartNr = result.productInfo.po;
      this.DeliveryInfolist[index].reportNr = result.productInfo.report;
      this.DeliveryInfolist[index].Quantity = 1;
      if (result.componentsInfomation.length > 0) {
        result.componentsInfomation.forEach((item) => {
          if (item.badNr == newvalue) {
            this.DeliveryInfolist[index].reSerialNr = item.newNr;
          }
        });
      }
      if (!this.PurchAserInfo.Name) {
        this.PurchAserInfo.Address = result.customerInfo.add;
        this.PurchAserInfo.F = result.customerInfo.fax;
        this.PurchAserInfo.People = result.customerInfo.contactor;
        this.PurchAserInfo.Telephone = result.customerInfo.tel;
        this.PurchAserInfo.Name = result.customerInfo.company;
      }
      this.Resort();
      this.loading = false;
    },
    TableSelectedItemChange(currentRow) {
      if (currentRow) {
        this.SelectRow = currentRow.Pos;
      }
    },
    async getCustomerInfo() {
      const { data } = await getCustomerList();

      this.customerInfo = data.list;
     // console.log(this.customerInfo)
    },
    MergeCells({ row, column, rowIndex, columnIndex }) {
      if (column.property == "reportNr") {
        if (row.reportNr != null) {
          if (row.reportNr.length > 0) {
            let arr = [];
            this.DeliveryInfolist.forEach((item, index) => {
              if (item.reportNr == row.reportNr) {
                arr.push(index);
              }
            });
            if (rowIndex == arr[0]) {
              return {
                rowspan: arr.length,
                colspan: 1,
              };
            } else {
              return {
                rowspan: 0,
                colspan: 1,
              };
            }
          }
        }
      }
    },
    async save() {
      const updateData = {
        id: this._id,
        data: {
          DeliveryInfolist: this.DeliveryInfolist,
          HeadInof: this.HeadInof,
          PurchAserInfo: this.PurchAserInfo,
        },
      };
      const { data } = await update(updateData);
      if (data) {
        this.$message({
          message: "保存成功",
          type: "success",
        });
      }
    },
    async deliteunit() {
      const data = await deleteShipping({ id: this._id });
      if (data) {
        this.$message({
          showClose: true,
          message: "删除成功",
          type: "success",
        });
      }
      this.$router.push({
        name: "shippinglist",
      });
    },
    pad(num, n) {
      var len = num.toString().length;
      while (len < n) {
        num = "0" + num;
        len++;
      }
      return num;
    },
    beforePrint() {
      this.loadingInstance = Loading.service({ fullscreen: true });
      let table = document.getElementById("DelieveryTable");
      table.style.width = "19.8cm";
      let Remark = document.getElementById("Remark");
      Remark.style.width = "19.8cm";
      this.$refs.noprint.style.visibility = "hidden";
      this.SelectRow = 0;
      this.$refs.DelieveryTable.setCurrentRow(); // 先清除当前选中行
      //备注信息直接进入打印页面会消失一部分:element的width被改变后高度没重新计算
      //往备注信息中写入一个空格后刷新界面即可
      this.$refs.DelieveryTable.$nextTick(() => {
        for(let item of this.DeliveryInfolist){
          if (!item.Notes) {
            item.Notes = " ";
          }
        }
        this.$refs.DelieveryTable.setCurrentRow(null); // 清除选中行的样式
        this.$refs.DelieveryTable.doLayout(); // 重新计算表格高度
        this.$forceUpdate();
       // console.log(this.DeliveryInfolist);
        let button = document.getElementById("print");
        setTimeout(() => {
          button.click();
        }, 1300);
      });
    },
  },
};
</script>

<style scoped >

* {
  font-family: "Arial";
}
.Body {
  margin-bottom: 5px;
  margin-left: 10px;
  width: calc(100% - 20px);
}
.BodyHead {
  background-color: rgb(235, 240, 249);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
.HeadButtonArea {
  margin-top: 10px;
  margin-left: 10px;
}
.PrintArea {
  position: relative;
}
.Tittle {
  position: relative;
  left: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 5%;
}
.Tittle h2 {
  display: inline-block;
}
.italic {
  font-style: italic;
  font-size: 5mm;
}
.SplitLine {
  margin-left: 10px;
  width: calc(100% - 20px);
  height: 10px;
  background-color: rgb(81, 125, 191);
}
.Word {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  display: inline-block;
  padding-right: 10px;
  font-size: 14px !important;
}
.Company {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}
.CompanyTittle {
  margin-left: 15px;
  font-size: 14px;
  font-weight: bold;
  border: none !important;
}
.CompanyTittle p {
  color: rgb(60, 109, 183);
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.Info1 {
  height: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: 12px;
}
.Info1 p {
  display: inline-block;
  padding-bottom: 5px;
  border-bottom: rgb(192, 192, 192) solid 1px;
}
.CompanyTittle >>> .el-input__inner {
  color: #000;
  font-size: 16px;
  font-weight: bold;
  border: none !important;
}
.NumberInpunt {
  display: inline-block;
  padding-right: 0px;
}
.NumberInpunt >>> .el-input__inner {
  height: 35px;
  padding: 0 !important;
}
.NumberInpunt1 {
  display: inline-block;
}
.HeadNumberInpunt >>> .el-input__inner {
  padding-right: 0px !important;
  padding-left: 0px !important;
  font-size: 12px;
  height: 18px !important;
  text-align: center;
}
.NumberInpunt1 >>> .el-input__inner {
  height: 35px;
  border-left: none;
  border-top: none;
  border-right: none;
  border-radius: 0px;
  border-bottom-width: 2px;
  border-bottom-color: rgb(192, 192, 192);
  background-color: rgba(0, 0, 0, 0);
  color: #000;
}
.InfoTittle {
  color: rgb(60, 109, 183);
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  margin-top: 10px;
}
.NumberInpunt2 {
  display: inline-block;
}
.NumberInpunt2 >>> .el-input__inner {
  height: 35px;
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom: none;
  background-color: rgba(0, 0, 0, 0);
}
.SerinalInput >>> .el-input__inner {
  border: none;
  text-align: center;
  margin: 0;
  padding: 0;
  height:30px;
  width: 100%;
  color: #000;
  padding: 3px 0 !important;
  font-size: 10px;
}
.SerinalInput >>> .el-textarea__inner {
  border: none;
  text-align: center;
  overflow: visible !important;
  resize: none;
  margin: 0;
  padding: 0;
  height:30px;
  width: 100%;
  color: #000;
  padding: 3px 0 !important;
  font-size: 10px;
}
.addbutton {
  padding-left: 20px;
}
.DeliveryInfo {
  margin-top: 15px;
}
.RemarkBody {
  padding: 10px 5px;
  border: 1px solid black;
  line-height: 20px;
  white-space:normal;
  font-size: 12px;
}
#Remark{
  margin-left: 2.5mm;
}
.RemarkBody >>>.el-input__inner{
  font-size: 12px;
}
.el-popover__reference-wrapper {
  margin-left: 10px;
}
/deep/ .el-table .cell {
  white-space: pre-line;
  padding-right: 5px;
  padding-left: 5px;
}
#DelieveryTable {
  border-color: #000;
  margin-left: 2.5mm;
}
#DelieveryTable>>>.cell{
  line-height: 20px;
}
#DelieveryTable::before {
  background-color: #000;
}
#DelieveryTable::after {
  background-color: #000;
  width: 0.5px;
}
/deep/ .el-table--border th,
td {
  border-bottom: 1px solid #000 !important;
  border-right: 1px solid #000;
}

/deep/ .el-table__row td {
  border-bottom: 1px solid #000 !important;
  border-right: 1px solid #000;
}

.el-table::before {
  height: 0;
}
.remarkinput{
  display: inline-block !important;
  width: 50px;
  border:none;
  border-bottom: 1px solid #000;
}
.remarkinput >>>.el-input__inner{
  border:none !important;
  height: 20px;
  padding: 0;
  text-align: center;
  color:#000
}
@page {
  size: 20.5cm 29.7cm;
  margin: 0;
}
@media print {
}
.bottomInfo {
  margin-top: 2.2mm;
  font-size: 2.5mm;
  margin-bottom: 0.1mm;
}
.sizesmall {
  height: 4mm;
}
.inlineblock {
  display: inline-block;
}
.check-input {
  background-color: black;
  position: relative;
  top: 1mm;
  left: 1mm;
}
.bottomInfo {
  margin-top: 2.2mm;
  font-size: 2.5mm;
  margin-bottom: 0.1mm;
}
.area_right {
  position: absolute;
  left: 6mm;
  top: 0px;
  /* float: right; */
  margin-top:30px;
  margin-bottom: 10px;
}
.title-right {
  width: 30mm;
  display: inline-block;
}
.writeInput {
  display: inline-block;
  width: 30mm;
  height: 5.5mm;
  border-bottom: 0.5px solid #000;
}
.bottom-input >>> .el-input__inner {
  padding-right: 100px;
  border: 0;
  height: 4mm;
  padding: 0;
  margin: 0px;
  font-size: 2.5mm;
  color: #000;
}
.relative {
  position: relative;
}
.top{
  top:12mm;
  /* margin-top:10px; */
}
</style>
<style>
::-webkit-scrollbar{
  display: none;
}
</style>