var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "wx-contianer" },
      [
        _c("wxlogin", {
          attrs: {
            appid: _vm.appid,
            scope: "snsapi_login",
            theme: "white",
            redirect_uri: "https://www.sigmatek-oa.cn/",
            href: "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6MjAwcHg7fQouaW1wb3dlckJveCAudGl0bGUge2ZvbnQtc2l6ZTogMTVweDt9Ci5pbXBvd2VyQm94IC5pbmZvIHt3aWR0aDogMjAwcHg7fQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmV9Ci5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9IAouaW1wb3dlckJveCAuc3RhdHVzIHB7CiAgICBmb250LXNpemU6IDEzcHg7Cn0=",
          },
        }),
      ],
      1
    ),
    _c("div", { attrs: { id: "dividerline" } }, [_c("el-divider")], 1),
    _vm._m(1),
    _c("div", { staticClass: "infos" }, [
      _vm._v(
        " 宁波西格玛泰克|浙江省宁波市鄞州区惊驾路 555 号 002 幢泰富 广场写字楼第 8-5单元室|0574-87753085 "
      ),
    ]),
    _vm._m(2),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "logi-container" } }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("../../../Img/logo.svg") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "sigmatekhost" } }, [
      _c(
        "a",
        {
          attrs: { href: "https://sigmatek-automation.com", target: "_blank" },
        },
        [_vm._v("www.sigmatek-automation.com")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "beian" }, [
      _c(
        "a",
        { attrs: { href: "https://beian.miit.gov.cn/", target: "_blank" } },
        [_vm._v("浙ICP备2022035919号")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }