import request from '@/utils/request'

export function getProjectlist(query) {
  return request({
    url: '/Api/technology/getProjectlist',
    method: 'get',
    params: query
  })
}

export function createProjectlist(data) {
  return request({
    url: '/Api/technology/createProjectlist',
    method: 'post',
    data
  })
}
export function deleteProjectList(query) {
  return request({
    url: '/Api/technology/deleteProjectList',
    method: 'delete',
    params: query
  })
}
export function updateProjectList(data) {
  return request({
    url: '/Api/technology/updateProjectList',
    method: 'post',
    data
  })
}
export function getTasklist(query) {
  return request({
    url: '/Api/technology/getTasklist',
    method: 'get',
    params: query
  })
}

export function createTasklist(data) {
  return request({
    url: '/Api/technology/createTasklist',
    method: 'post',
    data
  })
}
export function deleteTaskList(query) {
  return request({
    url: '/Api/technology/deleteTaskList',
    method: 'delete',
    params: query
  })
}
export function updateTaskList(data) {
  return request({
    url: '/Api/technology/updateTaskList',
    method: 'post',
    data
  })
}


export function CreateReportContent(data) {
  return request({
    url: '/Api/technology/CreateReportContent',
    method: 'post',
    data
  })
}
export function UpdateReportContent(data) {
  return request({
    url: '/Api/technology/UpdateReportContent',
    method: 'post',
    data
  })
}
export function DeleteReportContent(query) {
  return request({
    url: '/Api/technology/DeleteReportContent',
    method: 'delete',
    params: query
  })
}


export function GetReimbursement(data) {
  return request({
    url: '/Api/technology/GetReimbursement',
    method: 'post',
    data
  })
}
export function UpdateReimbursement(data) {
  return request({
    url: '/Api/technology/UpdateReimbursement',
    method: 'post',
    data
  })
}
export function CreateReimbursement(data) {
  return request({
    url: '/Api/technology/CreateReimbursement',
    method: 'post',
    data
  })
}

