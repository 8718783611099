<template>
    <div>
      <div class="head">
        <el-button type="primary" @click="showdialog">新建</el-button>
      </div>
      <div class="Main">
        <div class="tablediv">
          <el-table :data="TableInfo" style="width: 100%">
            <el-table-column
              prop="name"
              label="厂商名称"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="address"
              label="地址"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="phone"
              label="联系电话"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="identifierStr"
              label="英文标识符"
              align="center"
            >
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="handleEdit(scope.$index, scope.row)"
                  >编辑</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <el-dialog
        title="添加生产商"
        :visible.sync="dislogvisial"
        width="30%"
        center
      >
        <el-form
          :model="ShowInfo"
          label-width="120px"
          v-loading="dialogloading"
          :rules="addrules"
        >
          <el-form-item label="厂商名称" prop="name">
            <el-input v-model="ShowInfo.name"></el-input>
          </el-form-item>
          <el-form-item label="英文标识符" prop="identifierStr">
            <el-input v-model="ShowInfo.identifierStr"></el-input>
          </el-form-item>
          <el-form-item label="地址">
            <el-input v-model="ShowInfo.address"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" >
            <el-input v-model="ShowInfo.phone"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="creativePlant()"
              >立即创建</el-button
            >
            <el-button @click="dislogvisial = false">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog title="修改信息" :visible.sync="ChangeInfo" width="30%" center>
        <el-form
          :model="ShowInfo"
          label-width="120px"
          v-loading="dialogloading"
        >
          <el-form-item label="厂商名称">
            <el-input v-model="ShowInfo.name" disabled></el-input>
          </el-form-item>
          <el-form-item label="英文标识符" >
            <el-input v-model="ShowInfo.identifierStr" disabled></el-input>
          </el-form-item>
          <el-form-item label="地址">
            <el-input v-model="ShowInfo.address"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" >
            <el-input v-model="ShowInfo.phone"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="confirmUpdate()"
              >修改</el-button
            >
            <el-button @click="ChangeInfo = false">取消</el-button>
          </el-form-item>
        </el-form>
    </el-dialog>
    </div>
  </template>     
  <script>
  import { creativePlant,GetAllPlant,UpdatePlant } from "@/api/mesproductinfo";
  export default {
    data() {
      return {
        TableInfo: [
          {
            name: "",
            identifierStr: "",
            address: "",
            phone: "",
          },
        ],
        ChangeInfo:false,
        dislogvisial: false,
        dialogloading: false,
        ShowInfo: {
            _id:"",
            name: "",
            identifierStr: "",
            address: "",
            phone: "",
        },
        addrules: {
          name: [{ required: true, message: "请输入名称", trigger: "blur" }],
          identifierStr: [{ required: true, message: "请输入英文标识符", trigger: "change" }],
        },
      };
    },
    created() {
      this.getdata();
    },
    methods: {
      showdialog() {
        this.ShowInfo = {
            name: "",
            identifierStr: "",
            address: "",
            phone: "",
        };
        this.dislogvisial = true;
      },
      getdata() {
        GetAllPlant().then((res) => {
          this.TableInfo = res.data.list;
        });
      },
      creativePlant(formName) {
        if (
          this.ShowInfo.name == "" ||
          this.ShowInfo.identifierStr==""
        ) {
          return;
        } else {
          this.dialogloading = true;
          creativePlant(this.ShowInfo)
            .then((res) => {
              this.$message.success("创建成功");
              this.dislogvisial = false;
              this.dialogloading = false;
              this.getdata();
            })
            .catch((err) => {
              this.$message.error(err);
              this.dialogloading = false;
            });
        }
      },
      handleEdit(index, row) {
        this.ShowInfo.name = row.name; 
        this.ShowInfo._id = row._id;
        this.ShowInfo.identifierStr = row.identifierStr;
        this.ShowInfo.address = row.address; 
        this.ShowInfo.phone = row.phone;
        this.ChangeInfo = true;
      },
      confirmUpdate() {
        UpdatePlant(this.ShowInfo).then(res=>{
          this.$message.success("更新成功");
          this.ChangeInfo = false;
          this.getdata();
        }).catch(err=>{
          this.$message.error(err);
        })
      },
    
    },
  };
  </script>
  <style scoped>
  .head {
    margin-left: 10px;
    margin-top: 10px;
  }
  div >>> .Fuzzy_search {
    margin-left: 10px;
    margin-right: 5px;
    display: inline-block;
    width: 300px;
    margin-right: 10px;
  }
  .tablediv {
    margin-top: 20px;
    width: 100%;
  }
  #newhw {
    margin-bottom: 10px;
  }
  #newhwbutton {
    margin-top: 10px;
  }
  </style>