<template>
  <div class="login-container">
    <div id="logi-container">
      <img src="../../../Img/logo.svg" class="logo" />
    </div>
    <div class="wx-contianer">
      <wxlogin
        :appid="appid"
        scope="snsapi_login"
        :theme="'white'"
        redirect_uri="https://www.sigmatek-oa.cn/"
        href="data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6MjAwcHg7fQouaW1wb3dlckJveCAudGl0bGUge2ZvbnQtc2l6ZTogMTVweDt9Ci5pbXBvd2VyQm94IC5pbmZvIHt3aWR0aDogMjAwcHg7fQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmV9Ci5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9IAouaW1wb3dlckJveCAuc3RhdHVzIHB7CiAgICBmb250LXNpemU6IDEzcHg7Cn0="
      ></wxlogin>
    </div>
    <div id="dividerline">
      <el-divider></el-divider>
    </div>
    <div id="sigmatekhost">   <a href="https://sigmatek-automation.com" target="_blank"
        >www.sigmatek-automation.com</a
      >     </div>
    <div class="infos">
      宁波西格玛泰克|浙江省宁波市鄞州区惊驾路 555 号 002 幢泰富 广场写字楼第 8-5单元室|0574-87753085
    </div>
    <div class="beian">
      <a href="https://beian.miit.gov.cn/" target="_blank"
        >浙ICP备2022035919号</a
      >
    </div>
  </div>
</template>
<script>
import wxlogin from "vue-wxlogin";
export default {
  name: "Login",
  components: {
    wxlogin,
  },
  data() {
    return {
      loading: false,
      appid: "wxcaebc728723df10b",
      redirect_uri: "www.sigmatek-oa.cn",
    };
  },
  mounted() {
    //   console.log(this.$route)
    let geturl = window.location.href;
    let getqyinfo = geturl.split("?")[1];
    let getqys = new URLSearchParams("?" + getqyinfo);
    let code = getqys.get("code");
    console.log(code)
    if (code) {
      this.loading = true;
      this.$store
        .dispatch("user/login", { code: code })
        .then(() => {
          let gelDelURL =
            window.location.href.substring(
              0,
              window.location.href.indexOf("?")
            ) + "#/dashboard";
          location.href = gelDelURL;
           this.$router.push("/dashboard")
        })
        .catch(() => {
          this.loading = false;
          console.log("error");
        });
    }
  },
};
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

#dividerline >>> .el-divider {
  background-color: #818ca5 !important;
}
/* reset element-ui css */
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;
  // display: flex;
  // flex-direction: column;
  // flex-wrap: nowrap;
  // align-content: space-around;
  // justify-content: center;
  // align-items: center;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    display: flex;
    justify-content: space-around;
    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }
}
.logo {
  margin-bottom: 80px;
  width: 40%;
}
#logi-container {
  margin-top: 10%;
  display: flex;
  justify-content: space-around;
}
.wx-contianer {
  display: flex;
  justify-content: space-around;
}
.infos{
  text-align: center;
  width: 800px;
  position: absolute;
  color: #eee;
  bottom: 45px;
  font-size: 10px;
  right: calc(50% - 400px);
}
.beian {
  text-align: center;
  width: 400px;
  position: absolute;
  color: #eee;
  bottom: 20px;
  font-size: 10px;
  right: calc(50% - 200px);
}
#sigmatekhost{
  text-align: center;
  width: 400px;
  position: absolute;
  color: #eee;
  bottom: 100px;
  right: calc(50% - 200px);
  
  bottom: 90px;
}
#dividerline{
  width:100%;
  position: absolute;
  bottom: 48px;
}

</style>
