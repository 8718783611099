<template>
  <div class="app-container">
    <div class="buttongroup">
      <el-button v-on:click="showFilter = true" type="button">筛选</el-button>
      <el-button type="primary" v-on:click="showInfo(null)">新建</el-button>
    </div>
    <!-- 新建/编辑/查看详细窗口 -->
    <el-dialog :visible.sync="showProductOrdersInfo" :close-on-click-modal="false" title="订单信息">
      <el-form :model="ProductOrdersInfo" label-width="100px">
        <el-form-item label="订单号">
          <el-input class="display" :disabled="showProductOrdersInfo_Read" v-model="ProductOrdersInfo.orderNum" />
        </el-form-item>
        <el-form-item v-if="ProductOrdersInfo.Status !== 'Doing' && ProductOrdersInfo.Status !== 'Done'" label="计划生产时间">
          <el-date-picker v-model="ProductOrdersInfo.planTime" align="right" type="date" placeholder="添加日期" format="yyyy 年 MM 月 dd 日" value-format="timestamp">
          </el-date-picker>
        </el-form-item>
      </el-form>

      <el-form :inline="true" v-if="showProductOrdersInfo_Update || showProductOrdersInfo_Read" :model="ProductOrdersInfo" label-width="100px">
        <el-form-item label="状态">
          <el-select :disabled="true" v-model="ProductOrdersInfo.Status" v-if="!showProductOrdersInfo_Read">
            <el-option label="尚未发布" value="NotPublished"></el-option>
            <el-option label="正在进行" value="Doing"></el-option>
            <el-option label="已完成" value="Done"></el-option>
          </el-select>
          <el-input class="display" :disabled="true" v-model="InfoTransformation111" v-if="showProductOrdersInfo_Read" />
        </el-form-item>
      </el-form>

      <el-form :model="ProductOrdersInfo" label-width="100px">
        <el-form-item label="生产方">
          <el-select v-model="ProductOrdersInfo.Affiliation" placeholder="请选择生产方" :disabled="showProductOrdersInfo_Read" class="display" filterable>
            <el-option v-for="item in PlantInfo" :key="item.name" :label="item.name" :value="item.identifierStr">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户">
          <el-input class="display" list="browsers" v-on:change="onCustomerChange" :disabled="showProductOrdersInfo_Read" v-model="ProductOrdersInfo.CustomerId" />
          <datalist id="browsers">
            <option v-for="item in customerInfo">
              {{ item["company"] }}
            </option>
          </datalist>
        </el-form-item>
        <el-form-item label="客户地址">
          <el-input class="display" :disabled="showProductOrdersInfo_Read" v-model="ProductOrdersInfo.CustomerAdd" />
        </el-form-item>
      </el-form>

      <el-form :model="ProductOrdersInfo" label-width="100px">
        <el-form-item label="详细订单信息">
          <el-table :data="ProductOrdersInfo.Product" style="width: 100%">
            <el-table-column type="index" width="50" label="No" align="center">
            </el-table-column>
            <el-table-column label="型号" min-width="100" align="center">
              <template slot-scope="scope">
                <el-select v-model="scope.row.Type" placeholder="请选择型号" :disabled="showProductOrdersInfo_Read" class="display" filterable>
                  <el-option v-for="item in ProdutInfo" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="生产数量" min-width="100" align="center">
              <template slot-scope="scope">
                <el-input type="text" v-model="scope.row.Number" size="small" class="centerinput" :disabled="showProductOrdersInfo_Read" />
              </template>
            </el-table-column>
            <el-table-column label="完成数量" min-width="100" align="center" v-if="showProductOrdersInfo_Read">
              <template slot-scope="scope">
                {{ scope.row.doneNumber }}
              </template>
            </el-table-column>
            <el-table-column label="删除此行" min-width="50" align="center" v-if="!showProductOrdersInfo_Read">
              <template slot-scope="scope">
                <el-button type="danger" size="small" circle icon="el-icon-close" @click="remove(scope.$index)" :disabled="showProductOrdersInfo_Read"></el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="buttonadd">
            <el-button type="primary" icon="el-icon-plus" circle :disabled="showProductOrdersInfo_Read" v-on:click="creatNew"></el-button>
          </div>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer" v-if="!showProductOrdersInfo_Read">
        <el-button type="primary" v-on:click="saveInfo">保存</el-button>
        <el-button type="normal" v-on:click="ExitAndDontSave"> 取消 </el-button>
      </span>
      <span slot="footer" class="dialog-footer" v-if="showProductOrdersInfo_Read">
        <el-button type="primary" v-on:click="MarkOrNot" icon="el-icon-star-on" v-if="ProductOrdersInfo.Attention"> 已标记
        </el-button>
        <el-button type="normal" v-on:click="MarkOrNot" icon="el-icon-star-off" v-if="!ProductOrdersInfo.Attention"> 点击标记
        </el-button>
        <el-button type="normal" v-on:click="ExitAndDontSave"> 返回 </el-button>
      </span>
    </el-dialog>

    <!-- 筛选窗口 -->
    <el-dialog :visible.sync="showFilter" :close-on-click-modal="false" class="filter" title="筛选">
      <el-form :model="FindProductOrdersInfo" label-width="100px">
        <el-form-item label="订单号">
          <el-input type="text" v-model="FindProductOrdersInfo.orderNum" class="bottom-input" size="small" />
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="FindProductOrdersInfo" label-width="100px">
        <el-form-item label="计划生产时间">
          <div>
            <span>
              <el-date-picker v-model="FindProductOrdersInfo.planTime_dataS" type="date" placeholder="选择日期">
              </el-date-picker>
            </span>
            ~
            <span>
              <el-date-picker v-model="FindProductOrdersInfo.planTime_dataE" type="date" placeholder="选择日期">
              </el-date-picker>
            </span>
          </div>
        </el-form-item>

        <el-form-item label="状态">
          <el-select v-model="FindProductOrdersInfo.Status" clearable>
            <el-option label="尚未发布" value="NotPublished"></el-option>
            <el-option label="正在进行" value="Doing"></el-option>
            <el-option label="已完成" value="Done"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <el-form :model="FindProductOrdersInfo" label-width="100px">
        <el-form-item v-if="EndTimeShowJudg" label="完成时间">
          <span>
            <el-date-picker v-model="FindProductOrdersInfo.finshedTime_dataS" type="date" placeholder="选择日期">
            </el-date-picker>
          </span>
          ~
          <span>
            <el-date-picker v-model="FindProductOrdersInfo.finshedTime_dataE" type="date" placeholder="选择日期">
            </el-date-picker>
          </span>
        </el-form-item>

        <el-form-item label="生产方">
          <el-select v-model="FindProductOrdersInfo.Affiliation" placeholder="请选择生产方" class="display" filterable>
            <el-option v-for="item in PlantInfo" :key="item.name" :label="item.name" :value="item.identifierStr">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="客户">
          <el-input type="text" v-model="FindProductOrdersInfo.CustomerId" list="CustomerIdList" class="bottom-input" size="small" />
          <datalist id="CustomerIdList">
            <option v-for="item in customerInfo">
              {{ item["company"] }}
            </option>
          </datalist>
        </el-form-item>

        <el-form-item label="生产产品型号">
          <el-select v-model="FindProductOrdersInfo.Type" placeholder="请选择型号" class="display" filterable>
            <el-option v-for="item in ProdutInfo" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="normal" v-on:click="closefilter">返回</el-button>
        <el-button type="normal" v-on:click="clearFilter">清空</el-button>
        <el-button type="primary" v-on:click="confirmFilter">开始筛选</el-button>
      </span>
    </el-dialog>

    <!-- 订单的显示的表格 -->
    <el-table :data="list" element-loading-text="Loading" border fit highlight-current-row>
      <el-table-column align="center" label="ID" width="95">
        <template slot-scope="scope">
          {{ scope.$index + listQuery.start + 1 }}
        </template>
      </el-table-column>

      <el-table-column align="left" label="订单号">
        <template slot-scope="scope">
          {{ scope.row.orderNum }}
        </template>
      </el-table-column>

      <el-table-column align="center" label="计划生产时间">
        <template slot-scope="scope">
          {{ InfoTransformation(scope.row.planTime, 1) }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="完成时间">
        <template slot-scope="scope">
          {{ InfoTransformation(scope.row.finshedTime, 1) }}
        </template>
      </el-table-column>

      <el-table-column align="center" label="状态">
        <template slot-scope="scope">
          {{ InfoTransformation(scope.row, 2) }}
        </template>
      </el-table-column>

      <el-table-column align="center" label="生产方">
        <template slot-scope="scope">
          {{ getplantname(scope.row.Affiliation) }}
        </template>
      </el-table-column>

      <el-table-column align="center" label="客户">
        <template slot-scope="scope">
          {{ scope.row.CustomerId }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="255">
        <template slot-scope="scope">
          <div v-if="fasten(scope.row)">
            <el-button v-on:click="showInfo(scope.row)" type="button">
              编辑
            </el-button>
            <el-button v-on:click="showPublishedInfo(scope.row)" type="button">
              发布
            </el-button>
            <el-button v-on:click="showDeleteInfo(scope.row)" type="button">
              删除
            </el-button>
          </div>
          <div v-if="!fasten(scope.row)">
            <el-button v-on:click="showInfo(scope.row)" type="button">
              订单信息
            </el-button>
            <el-button v-on:click="goNotPublic(scope.row)" type="button" v-if="scope.row.Status == 'Doing'">
              停止生产
            </el-button>
            <el-button type="button" v-on:click="showlist(scope.row)">
              产品列表
            </el-button>
            <div v-show="Marked(scope.row)" class="subscript"></div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 二次确认窗口 -->
    <el-dialog :visible.sync="showDelete" title="删除">
      <h4>确定删除</h4>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-on:click="deleteInfo">确定</el-button>
        <el-button type="normal" v-on:click="showDelete = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="showPublished" title="发布">
      <h4>确定发布</h4>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-on:click="PublishInfo">确定</el-button>
        <el-button type="normal" v-on:click="showPublished = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="listvisiable" title="产品列表">
      <el-table :data="showproductlist" style="width: 100%;height:500px;overflow: auto;" v-infinite-scroll="loadmoreproduct" infinite-scroll-immediate="false" infinite-scroll-distance="1">
        <el-table-column align="center" label="ID" width="95">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="序列号">
          <template slot-scope="scope">
            {{ scope.row.SeriNum }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="入库时间">
          <template slot-scope="scope">
            {{ gettimestamps(scope.row.CreationTime) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="类型">
          <template slot-scope="scope">
            {{ scope.row.ProcudtVersion }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- 底部导航栏 -->
    <div class="mypagination">
      <el-pagination layout="prev, pager, next" @current-change="pagechange" :total="orderSize" :current-page="nowPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
import
{
  GetProductOrderSize,
  GetProductOrders,
  CreateProductOrder,
  UpdateProductOrder,
  DeleteProductOrder,
  GetProducts,
} from "@/api/mes";
import { getCustomerList } from "@/api/article";
import { GetAllPlant } from "@/api/mesproductinfo";
import { GetAllProducts } from "@/api/mesproductinfo";

export default {
  data()
  {
    return {
      nowPage: 0,
      listLoading: true,
      list: [],
      Search: null,
      orderSize: 0,
      listQuery: {
        start: 0,
        limit: 10,
        filters: {},
      },
      showFilter: false, //筛选窗口开关指示器
      showProductOrdersInfo: false, //新建，编辑，查看窗口开关指示器
      showProductOrdersInfo_Create: false, //新建模式指示器
      showProductOrdersInfo_Update: false, //编辑模式指示器
      showProductOrdersInfo_Read: false, //查看模式指示器
      showDelete: false, //删除确认窗口开关指示器
      showPublished: false, //发布确认窗口开关指示器

      ProductOrdersInfo: {
        _id: null,
        planTime: null,
        finshedTime: null,
        CustomerId: null,
        CustomerAdd: null,
        Status: null,
        index: null,
        orderNum: null,
        Affiliation: null,
        Attention: null,
        Product: [
          {
            Number: null,
            Type: null,
            doneNumber: null,
          },
        ],
      }, //产品订单信息

      FindProductOrdersInfo: {
        planTime_dataS: null,
        planTime_dataE: null,
        finshedTime_dataS: null,
        finshedTime_dataE: null,
        CustomerId: null,
        Status: null,
        orderNum: null,
        Affiliation: null,
        Type: null,
        Attention: null,
      }, //筛选订单信息

      customerInfo: [
        {
          company: null,
          contactor: null,
          tel: null,
          fax: null,
          add: null,
          WarrantyDays: null,
        },
      ], //客户信息
      PlantInfo: [],
      ProdutInfo: [],
      listvisiable: false,
      showproductlist: [],
      productQuery: {
        start: 0,
        limit: 10,
        filters: {},
      },
    };
  },

  created()
  {
    this.getSize();
    this.getCustomerInfo();
    this.GetPlantInfo();
    this.GetProductInfo();
    this.listLoading = false;
  },

  computed: {
    timestampToTime111: function ()
    {
      var timestamp = this.ProductOrdersInfo.planTime;
      var date = new Date(timestamp); // 参数为毫秒
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes() + ":";
      var s = date.getSeconds();
      return Y + M + D + h + m + s;
    }, //新建/编辑/查看模式专用，时间转换

    InfoTransformation111: function ()
    {
      if (this.ProductOrdersInfo.Status == "NotPublished") {
        return "尚未发布";
      }
      if (this.ProductOrdersInfo.Status == "Doing") {
        return "正在进行";
      }
      if (this.ProductOrdersInfo.Status == "Done") {
        return "已完成";
      }
    }, //新建/编辑/查看模式专用，状态转换

    EndTimeShowJudg: function ()
    {
      if (this.FindProductOrdersInfo.Status == "Done") {
        return 1;
      } else {
        return 0;
      }
    }, //筛选时结束时间出现判断
  },

  methods: {
    goNotPublic(infodata)
    {
    //  console.log(infodata);
      this.$confirm('暂停该项目生产, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () =>
      {
        const { data } = await UpdateProductOrder({
          id: infodata._id,
          data: {
            Status: "NotPublished"
          },
        });
        this.$message({
          type: 'success',
          message: '停止生产!'
        });
        this.getSize();
      }).catch(() =>
      {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },

    pagechange(input)
    {
      this.listQuery.start = (Number(input) - 1) * this.listQuery.limit;
      this.fetchData();
    },
    getplantname(input)
    {
      for (let item of this.PlantInfo) {
        if (item.identifierStr == input) {
          return item.name;
        }
      }
      return "";
    },
    async showlist(input)
    {
      let id = input._id;
      this.productQuery = {
        start: 0,
        limit: 50,
        filters: {
          ProductOrderid: id
        },
      };
      let resdata = await GetProducts(this.productQuery);
      this.showproductlist = resdata.list;
      this.listvisiable = true;
    },
    async loadmoreproduct()
    {
      this.productQuery.start = this.showproductlist.length;
      let resdata = await GetProducts(this.productQuery);
      resdata.list.map(item =>
      {
        this.showproductlist.push(item);
      });
    },
    async getSize()
    {
      this.listLoading = true;
      const { count } = await GetProductOrderSize(this.listQuery);
      this.orderSize = count;
      this.fetchData();
    }, //获取数据数量

    async fetchData()
    {
      this.listLoading = true;
      const { count, list } = await GetProductOrders(this.listQuery);

      this.list = list;
      setTimeout(() =>
      {
        this.listLoading = false;
      }, 0.5 * 1000);
    }, //获取订单数据

    async getCustomerInfo()
    {
      const { data } = await getCustomerList();
      this.customerInfo = data.list;
    }, //获取客户数据
    async GetPlantInfo()
    {
      const { data } = await GetAllPlant();
      this.PlantInfo = data.list;
    },
    async GetProductInfo()
    {
      const { data } = await GetAllProducts();
      data.list.map((item) =>
      {
        this.ProdutInfo.push(item.name);
      });
    }, //获取客户数据

    onCustomerChange: function ()
    {
      const customer = this.customerInfo.find(info => info.company === this.ProductOrdersInfo.CustomerId);
      if (customer) {
        this.ProductOrdersInfo.CustomerAdd = customer.add;
      }
    },

    InfoTransformation(data1, data2)
    {
      switch (data2) {
        case 1: //时间戳转换
          return this.timestampToTime(data1);
        case 2: //状态转换
          let totalcount = 0;
          data1.Product.map((item) =>
          {
            totalcount += item.Number;
          });
          let doneNumber = 0;
          data1.Product.map((item) =>
          {
            doneNumber += item.doneNumber;
          });
          if (data1.Status == "NotPublished") {
            if (doneNumber == 0) {
              return "尚未发布";
            }
            else {
              return `已暂停:${doneNumber}/${totalcount}`;
            }

          }
          if (data1.Status == "Doing") {
            return `正在进行:${doneNumber}/${totalcount}`;
          }
          if (data1.Status == "Done") {
            return "已完成";
          }
          break;
      }
    }, //数据转换

    timestampToTime(timestamp)
    {
      if (timestamp == 0 || timestamp == null || timestamp == undefined) {
        return " ";
      }
      let date = new Date(timestamp); // 参数为毫秒
      let Y = date.getFullYear() + "-";
      let M = (date.getMonth() + 1).toString().padStart(2, 0) + "-";
      let D = date.getDate().toString().padStart(2, '0');

      return Y + M + D;
    }, //时间戳转换成时间 

    showInfo(data)
    {
      this.showProductOrdersInfo = true;
      if (data == null) {
        this.ProductOrdersInfo = {
          _id: null,
          planTime: null,
          CreativeTime: null,
          finshedTime: null,
          CustomerId: null,
          CustomerAdd: null,
          Status: null,
          index: null,
          orderNum: null,
          Affiliation: null,
          Attention: null,
          Product: [
            {
              Number: null,
              Type: null,
              doneNumber: 0,
            },
          ],
        };
        this.authority(0); //新建模式的权限
      } else {
        this.ProductOrdersInfo = data;
        if (this.ProductOrdersInfo.Status == "NotPublished") {
          this.authority(1); //编辑模式的权限
        } else {
          this.authority(2); //查看模式的权限
        }
      }
    }, //新建，编辑，查看订单

    showPublishedInfo(data)
    {
      this.ProductOrdersInfo = data;
      this.showPublished = true;
    }, //弹出“发布确认”窗口

    async PublishInfo()
    {
      this.ProductOrdersInfo.Status = "Doing";
      if (this.ProductOrdersInfo.orderNum == null || this.ProductOrdersInfo.Affiliation == null || this.ProductOrdersInfo.CustomerId == null) {
        Message({
          message: "信息不全",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }
      const { data } = await UpdateProductOrder({
        id: this.ProductOrdersInfo._id,
        data: {
          Status: this.ProductOrdersInfo.Status,
        },
      });
      if (data) {
        Message({
          message: "发布成功",
          type: "success",
          duration: 5 * 1000,
        });
      }
      this.showPublished = false;
      this.getSize();
    }, //确认发布

    authority(data)
    {
      this.showProductOrdersInfo_Create = false;
      this.showProductOrdersInfo_Update = false;
      this.showProductOrdersInfo_Read = false;
      if (data == 0) {
        this.showProductOrdersInfo_Create = true; //当前处于新建模式
      }
      if (data == 1) {
        this.showProductOrdersInfo_Update = true; //当前处于编辑模式
        if (this.ProductOrdersInfo.CustomerId) {
          this.onCustomerChange();
        }
      }
      if (data == 2) {
        this.showProductOrdersInfo_Read = true; //当前处于查看模式
        if (this.ProductOrdersInfo.CustomerId) {
          this.onCustomerChange();
        }
      }
    }, //新建/编辑/查看模式判断

    creatNew()
    {
      if (this.ProductOrdersInfo.Product.length < 10) {
        this.ProductOrdersInfo.Product.push({
          Number: null,
          Type: null,
          doneNumber: 0,
        });
      }
    }, //新建"详细订单"

    remove(index)
    {
      this.ProductOrdersInfo.Product.splice(index, 1);
    }, //删除"详细订单"

    fasten(data)
    {
      if (data.Status == "NotPublished") {
        return 1;
      }
      if (data.Status == "Doing" || data.Status == "Done") {
        return 0;
      }
    }, //判断订单是否发布

    Marked(data)
    {
      if (data.Attention == true) {
        return 1;
      }
      if (data.Attention == false) {
        return 0;
      }
    }, //判断订单是否标记

    async saveInfo()
    {
      if (this.ProductOrdersInfo._id == null) {
        const { data } = await CreateProductOrder(this.ProductOrdersInfo);
        if (data) {
          Message({
            message: "创建成功",
            type: "success",
            duration: 5 * 1000,
          });
        }
      } else {
        const { data } = await UpdateProductOrder({
          id: this.ProductOrdersInfo._id,
          data: {
            finshedTime: this.ProductOrdersInfo.finshedTime,
            planTime: this.ProductOrdersInfo.planTime,
            CustomerId: this.ProductOrdersInfo.CustomerId,
            Status: this.ProductOrdersInfo.Status,
            index: this.ProductOrdersInfo.index,
            orderNum: this.ProductOrdersInfo.orderNum,
            Affiliation: this.ProductOrdersInfo.Affiliation,
            Product: this.ProductOrdersInfo.Product,
          },
        });
      }
      this.showProductOrdersInfo = false;
      this.getSize();
    }, //新建，编辑订单的保存

    async ExitAndDontSave()
    {
      this.showProductOrdersInfo = false;
      this.getSize();
    }, //新建，编辑订单的取消（不保存+刷新数据+关闭窗口）

    async MarkOrNot()
    {
      if (this.ProductOrdersInfo.Attention == false) {
        this.ProductOrdersInfo.Attention = true;
        const { data } = await UpdateProductOrder({
          id: this.ProductOrdersInfo._id,
          data: {
            Attention: this.ProductOrdersInfo.Attention,
          },
        });
      } else {
        this.ProductOrdersInfo.Attention = false;
        const { data } = await UpdateProductOrder({
          id: this.ProductOrdersInfo._id,
          data: {
            Attention: this.ProductOrdersInfo.Attention,
          },
        });
      }
    }, //是否标记订单

    showDeleteInfo(data)
    {
      this.ProductOrdersInfo._id = data._id;
      this.showDelete = true;
    }, //弹出“订单删除”窗口

    async deleteInfo()
    {
      const { data } = await DeleteProductOrder({
        id: this.ProductOrdersInfo._id,
      });
      if (data) {
        Message({
          message: "删除成功",
          type: "success",
          duration: 5 * 1000,
        });
      }
      this.showDelete = false;
      this.getSize();
    }, //确认删除

    clearFilter()
    {
      this.FindProductOrdersInfo = {
        planTime_dataS: null,
        planTime_dataE: null,
        finshedTime_dataS: null,
        finshedTime_dataE: null,
        CustomerId: null,
        Status: null,
        orderNum: null,
        Affiliation: null,
        Type: null,
      };
    }, //筛选条件清零

    closefilter()
    {
      this.showFilter = false;
      this.clearFilter();
    }, //筛选窗口关闭

    confirmFilter()
    {
      if (
        this.FindProductOrdersInfo.Affiliation != null ||
        this.FindProductOrdersInfo.planTime_dataE != null ||
        this.FindProductOrdersInfo.planTime_dataS != null ||
        this.FindProductOrdersInfo.CustomerId != null ||
        this.FindProductOrdersInfo.Status != null ||
        this.FindProductOrdersInfo.Type != null ||
        this.FindProductOrdersInfo.finshedTime_dataE != null ||
        this.FindProductOrdersInfo.finshedTime_dataS != null ||
        this.FindProductOrdersInfo.orderNum != null
      ) {
        this.listQuery.filters = "({$and:[";
        if (this.FindProductOrdersInfo.orderNum) {
          this.listQuery.filters +=
            "{'orderNum':{ $regex:/^.*" +
            this.FindProductOrdersInfo.orderNum +
            ".*$/,$options: 'i'}},";
        }

        if (this.FindProductOrdersInfo.Status) {
          this.listQuery.filters +=
            "{'Status':'" + this.FindProductOrdersInfo.Status + "'},";
        }

        if (this.FindProductOrdersInfo.Affiliation) {
          this.listQuery.filters +=
            "{'Affiliation':'" + this.FindProductOrdersInfo.Affiliation + "'},";
        }

        if (this.FindProductOrdersInfo.CustomerId) {
          this.listQuery.filters +=
            "{'CustomerId':'" + this.FindProductOrdersInfo.CustomerId + "'},";
        }

        if (this.FindProductOrdersInfo.Type) {
          // labels: { $elemMatch: { name: 'vue' }
          this.listQuery.filters +=
            "{'Product':{ $elemMatch:{'Type':'" + this.FindProductOrdersInfo.Type + "'}}},";
        }
        if (this.FindProductOrdersInfo.planTime_dataS) {
          this.listQuery.filters +=
            "{'planTime':{$gt:'" +
            Number(this.FindProductOrdersInfo.planTime_dataS) +
            "'}},";
        }
        if (this.FindProductOrdersInfo.planTime_dataE) {
          this.listQuery.filters +=
            "{'planTime':{$lt:'" +
            Number(this.FindProductOrdersInfo.planTime_dataE) +
            "'}},";
        }
        if (this.FindProductOrdersInfo.finshedTime_dataS) {
          this.listQuery.filters +=
            "{'finshedTime':{$gt:'" +
            Number(this.FindProductOrdersInfo.finshedTime_dataS) +
            "'}},";
        }
        if (this.FindProductOrdersInfo.finshedTime_dataE) {
          this.listQuery.filters +=
            "{'finshedTime':{$lt:'" +
            Number(this.FindProductOrdersInfo.finshedTime_dataE) +
            "'}},";
        }
        this.listQuery.filters += "]})";
      } else {
        this.listQuery.filters = {};
      }
      this.listQuery.start = 0;
      this.nowPage = 0;
      this.getSize();
      this.showFilter = false;
    }, //开始筛选
    gettimestamps(time)
    {
      let date = new Date(time);
      var Y = date.getFullYear() + "/";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "/";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      return Y + M + D + h + m;
    },

  },
};
</script>



<style scoped>
span {
  display: inline-block;
}

.app-container {
  position: relative;
}

div>>>.Fuzzy_search {
  margin-left: 10px;
  margin-right: 5px;
  display: inline-block;
  width: 300px;
}

tr {
  height: 4mm;
}

td {
  height: 4mm;
  border-left: 0.5px solid #000;
  border-top: 0.5px solid #000;
}

.display>>>.el-input__inner {
  color: #606266;
  background-color: white;
}

.centerinput>>>.el-input__inner {
  color: #606266;
  background-color: white;
  text-align: center;
}

.clomun1 {
  width: 20mm;
  text-align: center;
}

.clomun2 {
  width: 150mm;
  text-align: center;
}

.delete {
  text-align: center;
}

.create {
  height: 5mm;
}

.delete:hover {
  background-image: url("../../../Img/delete.jpg");
  background-repeat: no-repeat;
  color: transparent;
  background-size: cover;
  cursor: pointer;
}

.create:hover {
  background-image: url("../../../Img/create.jpg");
  background-repeat: no-repeat;
  color: transparent;
  background-size: cover;
  cursor: pointer;
}

.mypagination {
  display: flex;
  align-content: center;
  justify-content: center;
}

.buttonadd {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 10px;
}

.buttongroup {
  margin-bottom: 10px;
}

.subscript {}
</style>